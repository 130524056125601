import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import SingleContainerComponent from "../../components/container/SingleContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { formValueSelector, FieldArray } from "redux-form"; // ES6
import { universityList } from "../../actions/master-data/university_action";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";

import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";

import {
  detailPPDS,
  editPPDS,
  setIsEditFile,
} from "../../actions/manajemen-ppds/manajemen_ppds_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getPPDSPending,
  getDetailPPDS,
  getPPDSError,
  getPPDSIsEditFile,
} from "../../reducers/manajemen-ppds/manajemen_ppds_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getUniversityListPending,
  getUniversityList,
} from "../../reducers/master-data/university_reducer";
import { listCity } from "../../actions/master-data/city_action";
import {
  getCityListPending,
  getCityList
} from "../../reducers/master-data/city_reducer";
import DetailContentComponent from "../../components/detail/DetailContentComponent";

// import RenderFile from "../../components/form/FormComponent2";

class EducationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      strata: null,
      degreeOptions: {
        S1: [{ label: "dr.", value: "dr." }],
        S2: [{ label: "Sp.(OT)", value: "Sp.(OT)" }],
        S3: [{ label: "Dr.", value: "Dr." }],
        fellowship: [{ label: "K.", value: "K." }],
      },
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdaptaionChange = this.handleAdaptaionChange.bind(this);
    this.handleUniversityChange = this.handleUniversityChange.bind(this);
    this.handleDegreeChange = this.handleDegreeChange.bind(this);
    this.handleStageChange = this.handleStageChange.bind(this);
  }

  componentDidMount() {
    const { detailPPDS, listCity } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    detailPPDS(id);
    listCity({ length: 99999 })
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  }

  handleTriggerFormEdit() {
    const { universityList, reset } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    reset();
    universityList({ length: 999, is_adaptation: 0 });
  }

  handleTriggerEditFile() {
    const { editPPDS_isEditFile, setIsEditFile } = this.props;
    if (editPPDS_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editPPDS_isEditFile);
  }

  handleStageChange(value) {
    const { change } = this.props;
    const { degreeOptions } = this.state;
    if (value) {
      this.setState({ strata: value.value });
      change("degree", degreeOptions[value.value][0]);
    } else {
      change("degree", null);
      this.setState({ strata: null });
    }
  }

  handleAdaptaionChange(value) {
    const { universityList, change } = this.props;
    const param = {
      length: 999,
      is_adoption: 0,
    };
    universityList(param);
    change("university_from", null);
    change("other_university_from", null);
    change("city", null);
  }

  handleUniversityChange(value) {
    const { change } = this.props;
    if (value && value.city) {
      change("city", value.city);
    } else {
      change("city", null);
    }
  }

  handleDegreeChange(value) {
    const { change } = this.props;
    const { degreeOptions } = this.state;
    if (value) {
      this.setState({ strata: value.value });
      change("degree", value.value);
    } else {
      change("degree", null);
      this.setState({ strata: null });
    }
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, editPPDS, detailPPDS_data } = this.props;
    const {
      is_adaptation,
      university_from,
      university_to,
      country,
      other_university_to,
      city,
      education_stage,
      graduated,
      berkas,
      degree,
    } = values;

    let param = {
      id: detailPPDS_data[0].id,
      city: city.label ? city.label : city,
      degree: degree.value,
      education_stage: education_stage.value,
      graduated: graduated.value,
      is_adaptation: is_adaptation.value,
      file: berkas,
      university_to:
        university_to.value === "other"
          ? other_university_to
          : university_to.label,
    };

    if (is_adaptation.value === 1) {
      param = {
        ...param,
        university_from: university_from,
        country,
      };
    }
    param = {
      ...param,
      attachments: values.file.map((item) => ({
        attachment_name: item.name,
        attachment_filename: item.value,
      }))
    }
    param.isEditFile = berkas === undefined ? false : true;
    param.oldFilename = detailPPDS_data[0].filename;

    const callback = () => {
      detailPPDS();
      this.handleTriggerFormEdit();
    };
    editPPDS(param, callback);
  }



  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailPPDS_data,
      editPPDS_pending,
      editPPDS_isEditFile,
      listUniversity_data,
      handleSubmit,
      initialValues,
      listCity_data,
      formValue: { is_adaptation, university_from, education_stage },
    } = this.props;
    const { strata, degreeOptions, isEdit } = this.state;
    let name = null;
    let university_to = null;
    let filename = null;
    let school_city = null;
    let school_name = null;
    let status = null;
    let reject_notes = null;
    let reject_filename = null;
    let education_attachments = [];

    if (detailPPDS_data && detailPPDS_data.length !== 0) {
      name = detailPPDS_data[0].ppds_data.name;
      university_to = detailPPDS_data[0].university_to;
      school_name = detailPPDS_data[0].school_name;
      school_city = detailPPDS_data[0].city;
      filename = detailPPDS_data[0].filename;
      status = detailPPDS_data[0].status;
      reject_notes = detailPPDS_data[0].reject_notes;
      reject_filename = detailPPDS_data[0].reject_filename;
      education_attachments = detailPPDS_data[0].education_attachments;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    let cityOptions = [];
    // let degreeOptions = [];
    let universityOptions = [];
    let educationStageOptions = [
      { label: "S1", value: "S1" },
      { label: "S2", value: "S2" },
      { label: "S3", value: "S3" },
      { label: "Fellowship", value: "fellowship" },
    ];
    let adaptationOptions = [
      { label: "Ya", value: 1 },
      { label: "Tidak", value: 0 },
    ];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: `${i}` }];
    }
    if (listUniversity_data) {
      universityOptions = listUniversity_data.data.map((item) => ({
        label: item.name,
        value: item.name,
        city: item.city.name,
      }));
      universityOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
    } if (listCity_data) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.name
      }));
    }

    return (
      <ContentContainer>
        {/*<LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data Pendidikan Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_education"
            />
          </LeftContainerComponent> */}
        <SingleContainerComponent>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Row
              vertical="center"
              horizontal="space-between"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <DetailNameComponent name="Data Pendidikan" />

              <EditButtonComponent
                isEdit={isEdit}
                triggerIsEdit={this.handleTriggerFormEdit}
                isLoading={editPPDS_pending}
              />

            </Row>
            <FormContainer label="Jenjang Pendidikan">
              <Field
                isDetail={!isEdit}
                name="education_stage"
                placeholder="Pilih jenjang pendidikan"
                component={renderSelect}
                onChange={this.handleStageChange}
                options={educationStageOptions}
              />
            </FormContainer>
            <FormContainer label="Gelar">
              <Field
                isDetail={!isEdit}
                name="degree"
                placeholder="Pilih gelar"
                component={renderSelect}
                options={strata ? degreeOptions[strata] : []}
              />
            </FormContainer>
            <FormContainer label="Adaptasi">
              <Field
                isDetail={!isEdit}
                name="is_adaptation"
                placeholder="Pilih jenis adaptasi"
                options={adaptationOptions}
                onChange={this.handleAdaptaionChange}
                component={renderSelect}
              />
            </FormContainer>
            {is_adaptation && is_adaptation.value === 1 && (
              <>
                <FormContainer label="Universitas Adaptasi">
                  <Field
                    isDetail={!isEdit}
                    name="university_from"
                    placeholder="Masukkan Universitas Adaptasi"
                    component={renderInput}
                  />
                </FormContainer>
                <FormContainer label="Negara">
                  <Field
                    isDetail={!isEdit}
                    name="country"
                    placeholder="Masukkan negara"
                    component={renderInput}
                  />
                </FormContainer>
              </>
            )}

            {education_stage && (
              <FormContainer
                label='Universitas Asal'
              >
                <Field
                  isDetail={!isEdit}
                  name="university_to"
                  placeholder={`Pilih universitas asal`}
                  options={universityOptions}
                  onChange={this.handleUniversityChange}
                  component={renderSelect}
                />
              </FormContainer>
            )}


            {university_from && university_from.value === "other" && (
              <FormContainer
                isDetail={!isEdit}
                label={`Universitas ${is_adaptation && is_adaptation.value ? "adaptasi" : "tujuan"
                  }`}
              >
                <Field
                  isDetail={!isEdit}
                  name="other_university_from"
                  placeholder={`Masukkan universitas ${is_adaptation && is_adaptation.value
                    ? "adaptasi"
                    : "tujuan"
                    }`}
                  component={renderInput}
                />
              </FormContainer>
            )}
            {education_stage && education_stage.value === "S1" && (
              <>
                <FormContainer label="Kota">
                  {university_from && university_from.value === "other" ?
                    <Field
                      isDetail={!isEdit}
                      name="city"
                      placeholder="Masukkan kota"
                      component={renderSelect}
                      options={cityOptions}
                      disabled={!(university_from && university_from.value === "other")}
                    />
                    :
                    <Field
                      isDetail={!isEdit}
                      name="city"
                      placeholder="Masukkan kota"
                      component={renderInput}
                      disabled={!(university_from && university_from.value === "other")}
                    />
                  }
                </FormContainer>
              </>
            )}
            <FormContainer label="Tahun Lulus">
              <Field
                isDetail={!isEdit}
                name="graduated"
                placeholder="Pilih tahun lulus"
                component={renderSelect}
                options={yearOptions}
              />
            </FormContainer>
            <label className={css(styles.detailSectionName)}>Berkas</label>
            {isEdit ?
              <FormContainer
                label="Berkas"
                contentValueStyle={{ margin: "20px 0" }}
              >
                <FieldArray
                  name="file"
                  isDetail={!isEdit}
                  component={DynamicFormComponent}
                  changeFileValue={this.changeFileValue}
                  initialValues={initialValues}
                  folderPath="ppds_supporting_files"
                />
              </FormContainer>
              :
              <FieldArray
                name="file"
                isDetail={!isEdit}
                component={DynamicFormComponent}
                changeFileValue={this.changeFileValue}
                initialValues={initialValues}
                folderPath="ppds_supporting_files"
              />
            }
          </form>
        </SingleContainerComponent>
      </ContentContainer>
    );
  }
}

function validate(values, { editPPDS_isEditFile }) {
  const {
    is_adaptation,
    university_from,
    university_to,
    country,
    other_university_from,
    city,
    school_name,
    school_city,
    education_stage,
    graduated,
    degree,
    berkas,
  } = values;
  const errors = {};
  if (!is_adaptation) {
    errors.is_adaptation = "Jenis adaptasi wajib diisi";
  } else {
    if (is_adaptation.value === 1) {
      if (!university_to) {
        errors.university_to = "Universitas asal wajib diisi  ";
      }
      if (!country) {
        errors.country = "Negara universitas asal wajib diisi  ";
      }
    }
  }
  if (!university_from) {
    errors.university_from = `universitas ${is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "tujuan"
      } wajib diisi`;
  } else {
    if (university_from.value === "other") {
      if (!other_university_from) {
        errors.other_university_from = `universitas ${is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "tujuan"
          } wajib diisi`;
      }
    }
  }
  if (!city) {
    errors.city = "Kota wajib diisi";
  }
  if (!education_stage) {
    errors.education_stage = "Jenjang pendidikan wajib diisi";
  }
  if (!graduated) {
    errors.graduated = "Tahun lulus wajib diisi";
  }

  if (!degree) {
    errors.degree = "Gelar pendidikan wajib diisi";
  }
  if (editPPDS_isEditFile && !berkas) {
    errors.berkas = "Berkas pendidikan wajib diisi";
  }

  return errors;
}

EducationDetail = reduxForm({
  // a unique name for the form
  form: "EducationDetail",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(EducationDetail);

function mapStateToProps(state) {
  const { detailPPDS, editPPDS, universityList, listCity } = state;
  let initialValues = {};
  const detailPPDS_data = getDetailPPDS(detailPPDS);
  if (detailPPDS_data && detailPPDS_data.length !== 0) {
    initialValues = {
      is_adaptation: {
        label: detailPPDS_data[0].is_adaptation === 1 ? "Ya" : "Tidak",
        value: detailPPDS_data[0].is_adaptation,
      },
      university_to: {
        label: detailPPDS_data[0].university_to,
        value: detailPPDS_data[0].university_to,
      },

      education_stage: {
        label: detailPPDS_data[0].education_stage,
        value: detailPPDS_data[0].education_stage,
      },
      university_from: detailPPDS_data[0].university_from,
      school_name: detailPPDS_data[0].school_name,
      city: detailPPDS_data[0].city,
      country: detailPPDS_data[0].country,
      graduated: {
        label: `${detailPPDS_data[0].graduated}`,
        value: detailPPDS_data[0].graduated,
      },
      degree: {
        label: `${detailPPDS_data[0].degree}`,
        value: detailPPDS_data[0].degree,
      },
      file: detailPPDS_data[0].education_attachments.map(item => ({
        value: item.attachment_filename,
        name: item.attachment_name
      }))
    };
  }

  const selector = formValueSelector("EducationDetail");
  const formValue = selector(state, "university_from", "university_from", "is_adaptation", "education_stage");

  return {
    detailPPDS_data: getDetailPPDS(detailPPDS),
    detailPPDS_error: getPPDSError(detailPPDS),
    detailPPDS_pending: getPPDSPending(detailPPDS),
    editPPDS_error: getPPDSError(editPPDS),
    editPPDS_pending: getPPDSPending(editPPDS),
    editPPDS_isEditFile: getPPDSIsEditFile(editPPDS),
    initialValues,
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    listCity_data: getCityList(listCity),
    listCity_pending: getCityListPending(listCity),
    formValue,
  };
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%"
  },
  rightDetailColumn: {
    width: "100%"
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  billDate: {
    color: "#495057",
    opacity: "0.7",
    marginBottom: 10
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20
  }
});

export default connect(mapStateToProps, {
  detailPPDS,
  editPPDS,
  setIsEditFile,
  universityList,
  listCity
})(withRouter(EducationDetail));
