import React, { Component } from "react";
import ListPPDS from "./List_ppds";

class PPDS extends Component {
  render() {
    return <ListPPDS />;
  }
}

export default PPDS;
