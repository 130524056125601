import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { memberList } from "../../actions/member/member_action";
import { getMemberListPending, getMemberList, getMemberListError } from "../../reducers/member/member_reducer";
import { changeImageProfile } from "../../actions/master-data/user_action";
import { getChangeImageProfilePending, getChangeImageProfile, getChangeImageProfileError } from "../../reducers/master-data/user_reducer";
import DetailMemberPhoto from "../../components/detail/DetailMemberPhoto";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { renderFileForm } from "../../components/form/FormComponent";
import Loading from "../../components/global-components/LoadingComponent";
import { getUser } from '../../helpers/user';

class ModalEditBioMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            isDoneUploading: true,
            uploadButton: false,
            uploadMessage: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.upload = this.upload.bind(this);
    }

    upload(e) {
        const { changeImageProfile, handleRefresh } = this.props;
        if (e !== null && e.target.files[0]) {
            this.setState({ isDoneUploading: false })
            this.setState({ uploadButton: true })
            this.setState({ uploadMessage: <Loading /> })
            let formData = new FormData();
            const callback = () => {
                this.setState({ isDoneUploading: true })
                this.setState({ uploadButton: false })
                this.setState({ uploadMessage: <Loading done={true} /> })
                setTimeout(() => handleRefresh(), 2000)
            }
            this.setState({ file: URL.createObjectURL(e.target.files[0]) });
            formData.append('file', e.target.files[0]);
            formData.isPPDS = true;
            formData.id = getUser().id
            changeImageProfile(formData, callback);
        }
    }

    handleSubmit(values) {
        // const { userChangePassword, onHide } = this.props;
        // const param_userChangePassword = {
        //     password: values.newpass,
        //     confirmation_password: values.newpassConf
        // }
        // const callback = () => {
        //     onHide();
        //     this.props.history.push('/login')
        // }
        // userChangePassword(param_userChangePassword, callback);
    }

    render() {
        const { handleSubmit, show, onHide, id, name, status, img_profile } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                id={id}
                name={name}
                status={status}
                img_profile={img_profile}
                dialogClassName="modal-width-change-status"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Edit Foto Profil
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column className={css(styles.bodyModal)}>
                            <Row className={css(styles.photoComponent)}>
                                {(img_profile === null && this.state.file === null) ? <DetailMemberPhoto width="150px" height="150px" />
                                    : this.state.file === null && img_profile !== null ? <DetailMemberPhoto filename={img_profile} folder="ppds_supporting_files" source="api" width="150px" height="150px" />
                                        : <DetailMemberPhoto filename={this.state.file} source="local" width="150px" height="150px" />}
                            </Row>
                            <Field
                                name="memberPhoto"
                                type="file"
                                fileType="image" //image or pdf
                                title="Pilih Foto"
                                disabled={this.state.uploadButton}
                                isDone={this.state.isDoneUploading}
                                message={!this.props.formValue ? "PNG atau JPG maksimal 2 MB" : this.state.uploadMessage}
                                onChange={(e) => this.upload(e)}
                                component={renderFileForm}
                            />
                        </Column>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 20
    },
    bodyModalFalse: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40
    },
    photoComponent: {
        justifyContent: "center",
        marginBottom: 20
    }
});

function validate(values) {
    const { name, subspesialis } = values;
    const errors = {};
    if (!name) {
        errors.name = "Nama tidak boleh kosong"
    }
    if (!subspesialis) {
        errors.subspesialis = "Subspesialis tidak boleh kosong"
    }
    return errors;
}

ModalEditBioMember = reduxForm({
    form: "ModalEditBioMember",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalEditBioMember);

function mapStateToProps(state) {
    const { memberList, changeImageProfile } = state;
    const selector = formValueSelector("ModalEditBioMember");
    const formValue = selector(state, "memberPhoto");
    return {
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        changeImageProfile_pending: getChangeImageProfilePending(changeImageProfile),
        changeImageProfile_data: getChangeImageProfile(changeImageProfile),
        changeImageProfile_error: getChangeImageProfileError(changeImageProfile),
        formValue
    };
}

export default connect(mapStateToProps, { memberList, changeImageProfile })(ModalEditBioMember);