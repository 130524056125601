import {
  MEMBER_LIST_PENDING,
  MEMBER_LIST_SUCCESS,
  MEMBER_LIST_ERROR,
  MEMBER_LIST_ID_PENDING,
  MEMBER_LIST_ID_SUCCESS,
  MEMBER_LIST_ID_ERROR,
  DETAIL_MEMBER_PENDING,
  DETAIL_MEMBER_SUCCESS,
  DETAIL_MEMBER_ERROR,
  MEMBER_P2KB_EXISTING_PENDING,
  MEMBER_P2KB_EXISTING_SUCCESS,
  MEMBER_P2KB_EXISTING_ERROR,
  EDIT_MEMBER_PENDING,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
  CHANGE_MEMBER_STATUS_PENDING,
  CHANGE_MEMBER_STATUS_SUCCESS,
  CHANGE_MEMBER_STATUS_ERROR,
  UPLOAD_BERKAS_PENDING,
  UPLOAD_BERKAS_SUCCESS,
  UPLOAD_BERKAS_ERROR,
  SET_SELECTED_MEMBER,
  MEMBER_CHANGE_PASSWORD_ERROR,
  MEMBER_CHANGE_PASSWORD_PENDING,
  MEMBER_CHANGE_PASSWORD_SUCCESS,
} from "../../actions/member/member_action";

const initialState = {
  pending: false,
  error: null,
};

export function memberList(
  state = { ...initialState, memberList: null },
  action
) {
  switch (action.type) {
    case MEMBER_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MEMBER_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        memberList: action.data,
      };
    case MEMBER_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function memberListId(
  state = { ...initialState, memberListId: null },
  action
) {
  switch (action.type) {
    case MEMBER_LIST_ID_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MEMBER_LIST_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        memberListId: action.data,
      };
    case MEMBER_LIST_ID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function detailMember(
  state = { ...initialState, detail_member: null },
  action
) {
  switch (action.type) {
    case DETAIL_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_member: action.data,
      };
    case DETAIL_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function memberP2kbExisting(
  state = { ...initialState, existingP2kb: null },
  action
) {
  switch (action.type) {
    case MEMBER_P2KB_EXISTING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MEMBER_P2KB_EXISTING_SUCCESS:
      return {
        ...state,
        pending: false,
        existingP2kb: action.data,
      };
    case MEMBER_P2KB_EXISTING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function changeMemberStatus(
  state = { ...initialState, changeMemberStatus: null },
  action
) {
  switch (action.type) {
    case CHANGE_MEMBER_STATUS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_MEMBER_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        changeMemberStatus: action.data,
      };
    case CHANGE_MEMBER_STATUS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editMember(state = initialState, action) {
  switch (action.type) {
    case EDIT_MEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_MEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function selectedMember(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_MEMBER:
      return action.value;
    default:
      return state;
  }
}

export function uploadBerkas(
  state = { ...initialState, uploadBerkas: null },
  action
) {
  switch (action.type) {
    case UPLOAD_BERKAS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_BERKAS_SUCCESS:
      return {
        ...state,
        pending: false,
        uploadBerkas: action.data,
      };
    case UPLOAD_BERKAS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function memberChangePassword(
  state = { ...initialState, memberChangePassword: null },
  action
) {
  switch (action.type) {
    case MEMBER_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MEMBER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        userChangePassword: action.data,
      };
    case MEMBER_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getMemberListPending = (state) => state.pending;
export const getMemberList = (state) => state.memberList;
export const getMemberListError = (state) => state.error;
export const getMemberListIdPending = (state) => state.pending;
export const getMemberListId = (state) => state.memberListId;
export const getMemberListIdError = (state) => state.error;
export const getChangeMemberStatusPending = (state) => state.pending;
export const getChangeMemberStatus = (state) => state.changeMemberStatus;
export const getChangeMemberStatusError = (state) => state.error;
export const getDetailMember = (state) => state.detail_member;
export const getMemberPending = (state) => state.pending;
export const getMemberError = (state) => state.error;
export const getSelectedMember = (state) => state.selectedMember;
export const getBerkasUploadPending = (state) => state.pending;
export const getBerkasUpload = (state) => state.uploadBerkas;
export const getBerkasUploadError = (state) => state.error;
export const getMemberP2kbExistingPending = (state) => state.pending;
export const getMemberP2kbExisting = (state) => state.existingP2kb;
export const getMemberP2kbExistingError = (state) => state.error;
export const getMemberChangePasswordPending = (state) => state.pending;
export const getMemberChangePassword = (state) => state.MemberChangePassword;
export const getMemberChangePasswordError = (state) => state.error;
