import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

import { listAllocation } from "../../actions/dashboard/cabang_action";
import { getDashboardData } from "../../reducers/dashboard/cabang_reducer";
import { connect } from "react-redux";
import CountUp from "react-countup";
import { getUser } from "../../helpers/user";

class CardDanaCabang extends Component {
  componentDidMount() {
    const { listAllocation } = this.props;
    listAllocation({ branch_id: getUser().branch_id });
  }

  render() {
    let actual = 0,
      estimation = 0,
      outstanding = 0;

    const { listAllocation_data } = this.props;
    if (listAllocation_data) {
      ({ actual, estimation, outstanding } = listAllocation_data);
    }
    return (
      <Column
        style={{
          width: "100%",
          boxShadow: "0px 3px 6px #00000029",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        <div className={css(styles.cardHeader)}></div>
        <Column
          className={css(styles.wrapper, styles.borderBottom)}
          style={{ width: "100%" }}
        >
          <div style={{ height: 80 }}>
            <label className={css(styles.titleLabel)}>
              {" "}
              Alokasi Dana
              <br /> Cabang
            </label>
          </div>
          <Column style={{ height: 100 }} vertical="center" horizontal="center">
            <div style={{ fontSize: 28, fontWeight: "bold", marginBottom: 8 }}>
              Rp <CountUp end={estimation} separator="." duration={1} />
            </div>
            <div style={{ fontWeight: "bold" }}> Dana Cabang</div>
          </Column>
        </Column>
        <Column
          className={css(styles.wrapper)}
          style={{ width: "100%" }}
          vertical="space-around"
        >
          <Row style={{}} horizontal="space-between" vertical="center">
            <div>
              <div className={css(styles.cardLabel)}>Dana Actual</div>
              <div style={{ color: "#49505799" }}> udah ditransfer</div>
            </div>
            <span
              style={{ fontSize: 20, fontWeight: "bold", color: "#45A3E7" }}
            >
              Rp <CountUp end={actual} separator="." duration={1} />{" "}
            </span>
          </Row>
          <Row style={{}} horizontal="space-between" vertical="center">
            <div>
              <div className={css(styles.cardLabel)}>Dana Outstanding</div>
              <div style={{ color: "#49505799" }}> belum ditransfer</div>
            </div>
            <span
              style={{ fontSize: 20, fontWeight: "bold", color: "#45A3E7" }}
            >
              <CountUp end={outstanding} separator="." duration={1} />
            </span>
          </Row>
        </Column>
      </Column>
    );
  }
}
function mapStateToProps({ listAllocation }) {
  return {
    listAllocation_data: getDashboardData(listAllocation),
  };
}

export default connect(mapStateToProps, {
  listAllocation,
})(CardDanaCabang);

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    padding: 15,
    height: 180,
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#45A3E7",
    height: 10,
    width: "100%",
  },
});
