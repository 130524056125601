import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_APPRECIATION_PENDING = "LIST_APPRECIATION_PENDING";
export const LIST_APPRECIATION_SUCCESS = "LIST_APPRECIATION_SUCCESS";
export const LIST_APPRECIATION_ERROR = "LIST_APPRECIATION_ERROR";

export const DETAIL_APPRECIATION_PENDING = "DETAIL_APPRECIATION_PENDING";
export const DETAIL_APPRECIATION_SUCCESS = "DETAIL_APPRECIATION_SUCCESS";
export const DETAIL_APPRECIATION_ERROR = "DETAIL_APPRECIATION_ERROR";

export const ADD_APPRECIATION_PENDING = "ADD_APPRECIATION_PENDING";
export const ADD_APPRECIATION_SUCCESS = "ADD_APPRECIATION_SUCCESS";
export const ADD_APPRECIATION_ERROR = "ADD_APPRECIATION_ERROR";

export const EDIT_APPRECIATION_PENDING = "EDIT_APPRECIATION_PENDING";
export const EDIT_APPRECIATION_SUCCESS = "EDIT_APPRECIATION_SUCCESS";
export const EDIT_APPRECIATION_ERROR = "EDIT_APPRECIATION_ERROR";

export const DELETE_APPRECIATION_PENDING = "DELETE_APPRECIATION_PENDING";
export const DELETE_APPRECIATION_SUCCESS = "DELETE_APPRECIATION_SUCCESS";
export const DELETE_APPRECIATION_ERROR = "DELETE_APPRECIATION_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE";

const LIST_APPRECIATION_URL = "member/appreciation/find";
const UPLOAD_APPRECIATION_URL = "image/upload/member_appreciation";
const EDIT_APPRECIATION_URL = "member/appreciation/edit";
const DELETE_APPRECIATION_URL = "member/appreciation/delete";
const ADD_APPRECIATION_URL = "member/appreciation/add";

export function listAppreciation(param = {}, resolve) {
  // param.user_id = getUser().id;
  return (dispatch) => {
    dispatch(actionPending(LIST_APPRECIATION_PENDING));
    API.post(LIST_APPRECIATION_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_APPRECIATION_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_APPRECIATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list penghargaan, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addAppreciation({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_APPRECIATION_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_APPRECIATION_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        return API.post(ADD_APPRECIATION_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_APPRECIATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan penghargaan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_APPRECIATION_ERROR, error));
        toastError(error, "Gagal menambahkan penghargaan, ");
      });
  };
}

export function editAppreciation({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_APPRECIATION_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_APPRECIATION_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // return null;
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_APPRECIATION_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_APPRECIATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah penghargaan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_APPRECIATION_ERROR, error));
        toastError(error, "Gagal mengubah penghargaan, ");
      });
  };
}

export function detailAppreciation(id = null) {
  const param = {
    // user_id: getUser().id,
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_APPRECIATION_PENDING));
    API.post(LIST_APPRECIATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_APPRECIATION_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_APPRECIATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list penghargaan, ");
      });
  };
}

export function deleteAppreciation(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_APPRECIATION_PENDING));
    API.post(DELETE_APPRECIATION_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_APPRECIATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus penghargaan");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_APPRECIATION_ERROR, error));
        toastError(error, "Gagal menghapus penghargaan, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}
