import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { renderInputWithLabel, renderSelectWithLabel } from "../../components/form/FormComponent";
import { addFinalPaper } from "../../actions/cms-final-paper/cms_final_paper_action";
import {
    getFinalPaperAdd,
    getFinalPaperAddPending,
    getFinalPaperAddError
} from "../../reducers/cms-final-paper/cms_final_paper_reducer";
import { universityList } from "../../actions/master-data/university_action";
import {
    getUniversityList,
    getUniversityListPending,
} from "../../reducers/master-data/university_reducer";
import { listEvent } from "../../actions/event/event_action";
import {
    getEventPending,
    getListEvent,
} from "../../reducers/event/event_reducer";

class ModalFinalPaperAdd extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUniversityChange = this.handleUniversityChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show === true) {
            this.props.reset();
        }
    }

    handleUniversityChange(values) {
        if (values) {
            this.props.change("univ", values.abbreviation);
        } else {
            this.props.change("univ", null);
        }
    }

    handleSubmit(values) {
        const { addFinalPaper, onHide, handleRefresh } = this.props;
        const param = {
            title_paper: values.title_paper,
            univ_paper: values.univ_paper.label,
            event_id: values.event_id.value,
            year: values.year.value,
            author_paper: values.author_paper
        };

        const callback = () => {
            onHide();
            handleRefresh();
            this.props.reset();
        };
        addFinalPaper(param, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            addFinalPaper_pending,
            listEvent_data,
            listUniversity_data
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        let universityOptions = [];
        let eventOptions = [];
        if (listEvent_data) {
            eventOptions = listEvent_data.data.map((item) => ({
                label: item.event_name,
                value: item.id,
            }));
        }
        if (listUniversity_data) {
            universityOptions = listUniversity_data.data.map((item) => ({
                label: item.name,
                value: item.id,
                abbreviation: item.abbreviation
            }));
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Final Paper</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            {(
                                <>
                                    <Field
                                        label="Judul Paper"
                                        name="title_paper"
                                        placeholder="Masukkan judul paper"
                                        component={renderInputWithLabel}
                                    />
                                    <Field
                                        label="Nama Penulis"
                                        name="author_paper"
                                        placeholder="Masukkan nama penulis"
                                        component={renderInputWithLabel}
                                    />
                                    <Field
                                        label="Universitas"
                                        name="univ_paper"
                                        placeholder="Pilih universitas"
                                        options={universityOptions}
                                        onChange={this.handleUniversityChange}
                                        component={renderSelectWithLabel}
                                    />
                                    <Field
                                        label="Singkatan Universitas"
                                        name="univ"
                                        placeholder="Pilih universitas untuk mendapatkan singkatan universitas"
                                        disable={true}
                                        component={renderInputWithLabel}
                                    />
                                    <Field
                                        label="Event"
                                        name="event_id"
                                        placeholder="Pilih event"
                                        options={eventOptions}
                                        component={renderSelectWithLabel}
                                    />
                                    <Field
                                        label="Tahun"
                                        name="year"
                                        placeholder="Pilih tahun"
                                        options={yearOptions}
                                        component={renderSelectWithLabel}
                                    />
                                </>
                            )}
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={addFinalPaper_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    addFinalPaper_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const { title_paper, author_paper, univ_paper, event_id, year } = values;
    const errors = {};
    if (!title_paper) {
        errors.title_paper = "Judul paper tidak boleh kosong!";
    }
    if (!author_paper) {
        errors.author_paper = "Nama penulis tidak boleh kosong!";
    }
    if (!univ_paper) {
        errors.univ_paper = "Universitas tidak boleh kosong!";
    }
    if (!event_id) {
        errors.event_id = "Event tidak boleh kosong!";
    }
    if (!year) {
        errors.year = "Tahun tidak boleh kosong!";
    }
    return errors;
}

ModalFinalPaperAdd = reduxForm({
    form: "ModalAddFinalPaper",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalFinalPaperAdd);

function mapStateToProps({ addFinalPaper, listEvent, universityList }) {
    return {
        addFinalPaper_pending: getFinalPaperAddPending(addFinalPaper),
        addFinalPaper_data: getFinalPaperAdd(addFinalPaper),
        addFinalPaper_error: getFinalPaperAddError(addFinalPaper),
        listEvent_data: getListEvent(listEvent),
        listEvent_pending: getEventPending(listEvent),
        listUniversity_data: getUniversityList(universityList),
        listUniversity_pending: getUniversityListPending(universityList),
    };
}

export default connect(mapStateToProps, {
    addFinalPaper, listEvent, universityList
})(withRouter(ModalFinalPaperAdd));