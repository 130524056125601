import {
  LIST_CITY_PENDING,
  LIST_CITY_SUCCESS,
  LIST_CITY_ERROR
} from "../../actions/master-data/city_action";

const initialState = {
  pending: false,
  error: null,
};

export function listCity(state = initialState, action) {
  switch (action.type) {
    case LIST_CITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_CITY_SUCCESS:
      return {
        ...state,
        pending: false,
        listCity: action.data,
      };
    case LIST_CITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getCityListPending = (state) => state.pending;
export const getCityList = (state) => state.listCity;
export const getCityListError = (state) => state.error;