import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
// import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";

import _ from "lodash";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
});
class EducationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ education: e.target.value });
    this.delayedQuery(e.target.value);
  }

  render() {
    const { handleShowModalAdd } = this.props;
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={handleShowModalAdd}
          >
            tambah Pendidikan
          </Button>
        </div>
        <div>
          <div className={css(styles.container)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.education}
              placeholder="Cari nama universitas"
              startIcon={SearchIcon}
            />
          </div>
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(EducationHeader);
