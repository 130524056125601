import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_NOTIFICATION_PENDING = 'LIST_NOTIFICATION_PENDING'
export const LIST_NOTIFICATION_SUCCESS = 'LIST_NOTIFICATION_SUCCESS'
export const LIST_NOTIFICATION_ERROR = 'LIST_NOTIFICATION_ERROR'
export const READ_NOTIFICATION_PENDING = 'READ_NOTIFICATION_PENDING'
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS'
export const READ_NOTIFICATION_ERROR = 'READ_NOTIFICATION_ERROR'

const LIST_NOTIFICATION_URL = 'notification/find';
const READ_NOTIFICATION_URL = 'notification/read';

export function notificationList(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_NOTIFICATION_PENDING));
        API.post(LIST_NOTIFICATION_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_NOTIFICATION_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    ...item, no: i + 1 + (param.page - 1) * 10,
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_NOTIFICATION_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}

export function readNotification(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(READ_NOTIFICATION_PENDING));
        API.post(READ_NOTIFICATION_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(READ_NOTIFICATION_SUCCESS, res.data.data));
                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(READ_NOTIFICATION_ERROR, error));
                toastError(error);
            });
    };
}
