import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListUser from "./List_pengguna";

class User extends Component {
    render() {
        return (
            <ContentContainer>
                <ListUser />
            </ContentContainer>
        );
    }
}

export default User;