import {
  LIST_PPDS_PENDING,
  LIST_PPDS_SUCCESS,
  LIST_PPDS_ERROR,
  DETAIL_PPDS_PENDING,
  DETAIL_PPDS_SUCCESS,
  DETAIL_PPDS_ERROR,
  EDIT_PPDS_PENDING,
  EDIT_PPDS_SUCCESS,
  EDIT_PPDS_ERROR, 
  ADD_PPDS_PENDING,
  ADD_PPDS_SUCCESS,
  ADD_PPDS_ERROR,
  UPLOAD_PPDS_PENDING,
  UPLOAD_PPDS_SUCCESS,
  UPLOAD_PPDS_ERROR,
  APPROVE_PPDS_PENDING,
  APPROVE_PPDS_SUCCESS,
  APPROVE_PPDS_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/manajemen-ppds/manajemen_ppds_action";

const initialState = {
  pending: false,
  error: null,
};

export function listPPDS(state = { ...initialState, list_PPDS: null }, action) {
  switch (action.type) {
    case LIST_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        list_PPDS: action.data,
      };
    case LIST_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listPPDSAdmin(state = { ...initialState, list_PPDS: null }, action) {
  switch (action.type) {
    case LIST_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        list_PPDS: action.data,
      };
    case LIST_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailPPDS(
  state = { ...initialState, detail_PPDS: null },
  action
) {
  switch (action.type) {
    case DETAIL_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_PPDS: action.data,
      };
    case EDIT_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_PPDS: [action.data],
      };
    case DETAIL_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editPPDS(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addPPDS(state = initialState, action) {
  switch (action.type) {
    case ADD_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function uploadPPDS(
  state = { ...initialState, uploadPPDS: null },
  action
) {
  switch (action.type) {
    case UPLOAD_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        uploadPPDS: action.data,
      };
    case UPLOAD_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approvePPDS(state = initialState, action) {
  switch (action.type) {
    case APPROVE_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListPPDS = (state) => state.list_PPDS;
export const getListPPDSAdmin = (state) => state.list_PPDS;
export const getDetailPPDS = (state) => state.detail_PPDS;
export const getPPDSPending = (state) => state.pending;
export const getPPDSError = (state) => state.error;
export const getPPDSIsEditFile = (state) => state.isEditFile;
export const getPPDSUpload = (state) => state.uploadPPDS;
export const getPPDSUploadError = (state) => state.error;