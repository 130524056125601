import {
  LIST_TRAINING_PENDING,
  LIST_TRAINING_SUCCESS,
  LIST_TRAINING_ERROR,
  DETAIL_TRAINING_PENDING,
  DETAIL_TRAINING_SUCCESS,
  DETAIL_TRAINING_ERROR,
  EDIT_TRAINING_PENDING,
  EDIT_TRAINING_SUCCESS,
  EDIT_TRAINING_ERROR,
  DELETE_TRAINING_PENDING,
  DELETE_TRAINING_SUCCESS,
  DELETE_TRAINING_ERROR,
  ADD_TRAINING_PENDING,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_ERROR,
  APPROVE_TRAINING_PENDING,
  APPROVE_TRAINING_SUCCESS,
  APPROVE_TRAINING_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/member/training_action";

const initialState = {
  pending: false,
  error: null,
};

export function listTraining(
  state = { ...initialState, list_training: null },
  action
) {
  switch (action.type) {
    case LIST_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
        list_training: action.data,
      };
    case LIST_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailTraining(
  state = { ...initialState, detail_training: null },
  action
) {
  switch (action.type) {
    case DETAIL_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_training: action.data,
      };
    case DETAIL_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editTraining(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addTraining(state = initialState, action) {
  switch (action.type) {
    case ADD_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteTraining(state = initialState, action) {
  switch (action.type) {
    case APPROVE_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveTraining(state = initialState, action) {
  switch (action.type) {
    case DELETE_TRAINING_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_TRAINING_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_TRAINING_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListTraining = (state) => state.list_training;
export const getDetailTraining = (state) => state.detail_training;
export const getTrainingPending = (state) => state.pending;
export const getTrainingError = (state) => state.error;
export const getTrainingIsEditFile = (state) => state.isEditFile;
