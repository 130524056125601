import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import { getUser } from "../../helpers/user";

const styles = StyleSheet.create({
  container: {
    width: 150,
    marginLeft: 10,
  },
  searchContainer: {
    width: 200,
  },
});
class HeaderPractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: getUser().id,
      search: "",
      status: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
    // this.props.handleRefresh({ search: e.target.value });
  }

  handleStatusChange(value) {
    // this.setState({ faculty: value });
    if (value) {
      this.props.handleRefresh({ status: value.value });
    } else {
      this.props.handleRefresh({ status: "" });
    }
  }

  // handleSearchChange = _.debounce((e) => {
  // }, 1000);

  render() {
    let buttonAddPractice;
    let statusOption = [
      {
        label: "Baru",
        value: "0",
      },
      {
        label: "Terverifikasi",
        value: "1",
      },
      {
        label: "Ditolak",
        value: "2",
      },
    ];

    const { handleOpenModalAdd } = this.props;

    if (getUser().role_codes[0] === "member") {
      buttonAddPractice = (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={css(styles.approveButton)}
          startIcon={<AddIcon />}
          onClick={handleOpenModalAdd}
        >
          tambah jadwal praktik
        </Button>
      );
    } else {
      buttonAddPractice = "";
    }
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal={
          getUser().role_codes[0] === "member" ? "space-between" : "flex-end"
        }
      >
        <div>{buttonAddPractice}</div>
        <Row>
          <div className={css(styles.searchContainer)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.tes}
              placeholder="Cari Nama RS, Member"
              startIcon={SearchIcon}
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleStatusChange}
              placeholder="Status"
              options={statusOption}
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HeaderPractice);
