import {
    LIST_EXPERIENCE_PENDING,
    LIST_EXPERIENCE_SUCCESS,
    LIST_EXPERIENCE_ERROR,
    EDIT_EXPERIENCE_PENDING,
    EDIT_EXPERIENCE_SUCCESS,
    EDIT_EXPERIENCE_ERROR,
    ADD_EXPERIENCE_PENDING,
    ADD_EXPERIENCE_SUCCESS,
    ADD_EXPERIENCE_ERROR,
    DELETE_EXPERIENCE_PENDING,
    DELETE_EXPERIENCE_SUCCESS,
    DELETE_EXPERIENCE_ERROR,
    SET_IS_EDIT_FILE_EXPERIENCE
} from "../../actions/master-data/experience_action";

const initialState = {
    pending: false,
    error: null
};

export function experienceList(
    state = { ...initialState, experienceList: null },
    action
) {
    switch (action.type) {
        case LIST_EXPERIENCE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_EXPERIENCE_SUCCESS:
            return {
                ...state,
                pending: false,
                experienceList: action.data,
            };
        case LIST_EXPERIENCE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addExperience(
    state = { ...initialState, addExperience: null },
    action
) {
    switch (action.type) {
        case ADD_EXPERIENCE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_EXPERIENCE_SUCCESS:
            return {
                ...state,
                pending: false,
                addExperience: action.data,
            };
        case ADD_EXPERIENCE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editExperience(
    state = { ...initialState, isEditFile: false },
    action
  ) {
    switch (action.type) {
      case EDIT_EXPERIENCE_PENDING:
        return {
          ...state,
          pending: true,
        };
      case EDIT_EXPERIENCE_SUCCESS:
        return {
          ...state,
          pending: false,
        };
      case EDIT_EXPERIENCE_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      case SET_IS_EDIT_FILE_EXPERIENCE:
        return {
          ...state,
          isEditFile: action.value,
        };
      default:
        return state;
    }
  }

export function deleteExperience(
    state = { ...initialState, deleteExperience: null },
    action
) {
    switch (action.type) {
        case DELETE_EXPERIENCE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteExperience: action.data,
            };
        case DELETE_EXPERIENCE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getExperienceListPending = (state) => state.pending;
export const getExperienceList = (state) => state.experienceList;
export const getExperienceListError = (state) => state.error;
export const getExperienceAddPending = (state) => state.pending;
export const getExperienceAdd = (state) => state.addExperience;
export const getExperienceAddError = (state) => state.error;
export const getExperienceEditPending = (state) => state.pending;
export const getExperienceEdit = (state) => state.editExperience;
export const getExperienceEditError = (state) => state.error;
export const getExperienceDeletePending = (state) => state.pending;
export const getExperienceDelete = (state) => state.deleteExperience;
export const getExperienceDeleteError = (state) => state.error;
export const getExperienceIsEditFile = (state) => state.isEditFile;
