import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { rejectApprovalJournal } from "../../actions/journal/journal_action";
import {
    getJournalRejectApproval,
    getJournalRejectApprovalPending,
    getJournalRejectApprovalError,
} from "../../reducers/journal/journal_reducer";
import {
    renderSelectWithLabel
} from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

class ModalRejectRejectJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { rejectApprovalJournal, onHide, id, step } = this.props;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("is_approve", "false");
        if (step === 2) {
            formData.append("reviewer_id", values.reviewer.value);
        } else if (step === 3) {
            formData.append("editor_id", values.editor.value);
        }
        const callback = () => {
            onHide();
            this.props.handleRefresh();
        };
        rejectApprovalJournal(formData, callback);
    }

    render() {
        const { approvalJournal_pending, handleSubmit } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                reviewer_id={this.props.reviewer_id}
                reviewerOptions={this.props.reviewerOptions}
                editorOptions={this.props.editorOptions}
                step={this.props.step}
                dialogClassName="modal-width-change-status"
                // centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column className={css(styles.bodyModal)}>
                            {this.props.step === 2 &&
                                <Field
                                    name="reviewer"
                                    placeholder="Pilih Reviewer"
                                    label="Reviewer"
                                    isClearable={false}
                                    options={this.props.reviewerOptions}
                                    component={renderSelectWithLabel}
                                />
                            }
                            {this.props.step === 3 &&
                                <Field
                                    name="editor"
                                    placeholder="Pilih Editor"
                                    label="Editor"
                                    isClearable={false}
                                    options={this.props.editorOptions}
                                    component={renderSelectWithLabel}
                                />
                            }
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.rejectButton)}
                                startIcon={<ClearIcon />}
                                onClick={() => this.props.onHide}
                            >
                                BATAL
                        </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={
                                    approvalJournal_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                YA
                        </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function validate(values) {
    const { reviewer, editor } = values;
    const errors = {};
    if (!reviewer) {
        errors.reviewer = "Reviewer wajib diisi";
    }
    if (!editor) {
        errors.editor = "Editor wajib diisi";
    }
    return errors;
}

ModalRejectRejectJournal = reduxForm({
    form: "ModalRejectRejectJournal",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalRejectRejectJournal);

function mapStateToProps({ approvalJournal, rejectApprovalJournal }) {
    return {
        approvalRejectJournal_pending: getJournalRejectApprovalPending(rejectApprovalJournal),
        approvalRejectJournal_data: getJournalRejectApproval(rejectApprovalJournal),
        approvalRejectJournal_error: getJournalRejectApprovalError(rejectApprovalJournal),
    };
}

export default connect(mapStateToProps, { rejectApprovalJournal })(ModalRejectRejectJournal);