import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_SUBSPECIALIST_PENDING = "LIST_SUBSPECIALIST_PENDING";
export const LIST_SUBSPECIALIST_SUCCESS = "LIST_SUBSPECIALIST_SUCCESS";
export const LIST_SUBSPECIALIST_ERROR = "LIST_SUBSPECIALIST_ERROR";

export const LIST_TOTALMEMBER_PENDING = "LIST_TOTALMEMBER_PENDING";
export const LIST_TOTALMEMBER_SUCCESS = "LIST_TOTALMEMBER_SUCCESS";
export const LIST_TOTALMEMBER_ERROR = "LIST_TOTALMEMBER_ERROR";

export const LIST_ESTIMATIONFEE_PENDING = "LIST_ESTIMATIONFEE_PENDING";
export const LIST_ESTIMATIONFEE_SUCCESS = "LIST_ESTIMATIONFEE_SUCCESS";
export const LIST_ESTIMATIONFEE_ERROR = "LIST_ESTIMATIONFEE_ERROR";

export const LIST_TOTALFEEIN_PENDING = "LIST_TOTALFEEIN_PENDING";
export const LIST_TOTALFEEIN_SUCCESS = "LIST_TOTALFEEIN_SUCCESS";
export const LIST_TOTALFEEIN_ERROR = "LIST_TOTALFEEIN_ERROR";

export const LIST_ALLOCATION_PENDING = "LIST_ALLOCATION_PENDING";
export const LIST_ALLOCATION_SUCCESS = "LIST_ALLOCATION_SUCCESS";
export const LIST_ALLOCATION_ERROR = "LIST_ALLOCATION_ERROR";

export const LIST_TOTALMEMBER_DETAIL_PENDING = "LIST_TOTALMEMBER_DETAIL_PENDING";
export const LIST_TOTALMEMBER_DETAIL_SUCCESS = "LIST_TOTALMEMBER_DETAIL_SUCCESS";
export const LIST_TOTALMEMBER_DETAIL_ERROR = "LIST_TOTALMEMBER_DETAIL_ERROR";


const LIST_SUBSPECIALIST_URL = "dashboard/branch/subspecialist_doctor";
const LIST_TOTALMEMBER_URL = "dashboard/branch/total_member";
const LIST_ESTIMATIONFEE_URL = "dashboard/branch/estimation_fee";
const LIST_TOTALFEEIN_URL = "dashboard/branch/total_fee_in";
const LIST_ALLOCATION_URL = "dashboard/branch/total_allocation";

export function listSubspecialist(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_SUBSPECIALIST_PENDING));
        API.post(LIST_SUBSPECIALIST_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_SUBSPECIALIST_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_SUBSPECIALIST_ERROR, error));
                toastError(error, "Gagal mendapatkan data SUBSPECIALIST, ");
            });
    };
}
export function listTotalMember(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALMEMBER_PENDING));
        API.post(LIST_TOTALMEMBER_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALMEMBER_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALMEMBER_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL MEMBER, ");
            });
    };
}

export function listTotalMemberDetail(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALMEMBER_DETAIL_PENDING));
        API.post(LIST_TOTALMEMBER_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALMEMBER_DETAIL_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALMEMBER_DETAIL_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL MEMBER, ");
            });
    };
}
export function listEstimationFee(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ESTIMATIONFEE_PENDING));
        API.post(LIST_ESTIMATIONFEE_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ESTIMATIONFEE_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ESTIMATIONFEE_ERROR, error));
                toastError(error, "Gagal mendapatkan data ESTIMATION FEE, ");
            });
    };
}
export function listTotalFeeIn(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALFEEIN_PENDING));
        API.post(LIST_TOTALFEEIN_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALFEEIN_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALFEEIN_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL FEE IN, ");
            });
    };
}
export function listAllocation(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ALLOCATION_PENDING));
        API.post(LIST_ALLOCATION_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ALLOCATION_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ALLOCATION_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL ALLOCATION, ");
            });
    };
}




