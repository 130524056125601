import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 200,
    margin: 10,
    zIndex: 99,
  },
});
class HeaderMemberP2KB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nama: "",
      status: null,
    };
    this.handleChangeNama = this.handleChangeNama.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    return handleRefresh(value);
  }, 500);

  handleChangeNama(e) {
    if (e !== null) {
      this.delayedQuery({ search: e.target.value });
    } else {
      this.delayedQuery({ search: "" });
    }
  }

  handleChangeStatus(value) {
    if (value) {
      this.props.handleRefresh({ status: value.value });
    } else {
      this.props.handleRefresh({ status: null });
    }
  }

  render() {
    let statusOption = [
      { value: 0, label: "Menunggu" },
      { value: 1, label: "Disetujui" },
      { value: 2, label: "Ditolak" },
    ];
    return (
      <Row
        style={{ padding: 20, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="flex-end"
      >
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleChangeNama}
            placeholder="Cari Nama"
            startIcon={SearchIcon}
            id="nama"
          />
        </div>
        <div className={css(styles.container)}>
          <SelectComponent
            onChange={this.handleChangeStatus}
            placeholder="Status"
            options={statusOption}
            id="status"
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps({ memberList }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
  };
}

export default connect(mapStateToProps, { memberList })(HeaderMemberP2KB);
