import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { memberList } from "../../actions/member/member_action";
import {
  renderInputWithLabel,
  renderSelectWithLabel,
  renderDatepickerWithLabel2,
  renderDatepickerWithLabel

} from "../../components/form/FormComponent";
import {
  editMembership,
} from "../../actions/koperasi/membership_action";
import {
  getDetailMembership,
  getMembershipPending
} from "../../reducers/koperasi/membership_reducer";
import moment from "moment";
import localization from "moment/locale/id";


class ModalEditMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      durationOptions: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    const { show, initialValues } = this.props;
    if (prevProps.show !== show && show === true) {
      this.props.reset();
      // this.props.change("main_deposit", 0)
    }
    if (!prevProps.initialValues.payment_period && initialValues.payment_period) {
      this.durationValue(initialValues.payment_period.value)
    }
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, editMembership, detailMembership_data } = this.props
    const { required_deposit, status, code, date } = values;
    const [{ id }] = detailMembership_data;
    const param = {
      id, required_deposit,
      code: code,
      status: status.value,
      join_date : date,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editMembership(param, callback);
  }

  durationValue = (value) => {
    let i;
    let newDurationOptions = [];
    for (i = 1; i <= 60; i++) {
      if (i % value === 0) {
        if (value !== 12) {
          newDurationOptions = [...newDurationOptions, { label: i + ' Bulan', value: i }];
        } else {
          newDurationOptions = [...newDurationOptions, { label: i / 12 + ' Tahun', value: i }];
        }
      }
    }
    this.setState({ durationOptions: newDurationOptions })
  }

  handlePeriodChange = (e) => {
    this.props.change("duration", null)
    if (e) {
      this.durationValue(e.value);
    } else {
      this.setState({ durationOptions: [] })
      this.props.change("duration", null)
    }
  }

  formatAmount(input) {
    if (!input) return "";
    else input = input.toString()
    if (isNaN(parseInt(input[input.length - 1], 10))) {
      return "";
    } else input = Number(input)
    var reverse = input.toString().split('').reverse().join(''),
      ribuan = reverse.match(/\d{1,3}/g);
    ribuan = ribuan.join('.').split('').reverse().join('');
    return "Rp " + ribuan;
  }

  normalizeCurrency = (val) => {
    if (!val) {
      return ""
    }
    let result = val.replace("Rp ", "").split('.').join('');

    if (isNaN(parseInt(result, 10))) {
      return ""
    }
    return result
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      editMembership_pending,
      detailMembership_pending
    } = this.props;

    const statusOptions = [
      {
        label: "Aktif",
        value: 1
      },
      {
        label: "Tidak Aktif",
        value: 0
      },
    ]
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit Anggota</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailMembership_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <Field
                    name="date"
                    placeholder="Masukkan tanggal pengajuan"
                    label="Tanggal Pengajuan"
                    component={renderDatepickerWithLabel}
                  />
                  <Field
                    name="code"
                    type="text"
                    label='ID Koperasi'
                    placeholder="Masukkan id koperasi"
                    component={renderInputWithLabel}
                  />
                  <Field
                    disable
                    name="member"
                    label="Nama Dokter"
                    placeholder="Pilih Member"
                    component={renderInputWithLabel}
                  />
                  <Field
                    disable
                    name="main_deposit"
                    type="text"
                    label='Simpanan Pokok'
                    placeholder="Masukkan simpanan pokok"
                    format={this.formatAmount}
                    normalize={this.normalizeCurrency}
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="status"
                    label="Status Anggota"
                    placeholder="Pilih status anggota"
                    component={renderSelectWithLabel}
                    options={statusOptions}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editMembership_pending}
                className={css(styles.approveButton)}
                startIcon={
                  editMembership_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  const { member, main_deposit, required_deposit, status } = values;
  const errors = {};
  if (!main_deposit) {
    errors.main_deposit = 'Simpanan pokok harus diisi!'
  }
  if (!member) {
    errors.member = "Nama dokter harus diisi!"
  }
  if (!status) {
    errors.status = "Status anggota harus diisi!"
  }

  return errors;
}

ModalEditMembership = reduxForm({
  // a unique name for the form
  form: "ModalEditMembership",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  // shouldError: () => true,
})(ModalEditMembership);

function mapStateToProps({ memberList, editMembership, detailMembership }) {

  let detailMembership_data = getDetailMembership(detailMembership);
  let initialValues = {};
  if (detailMembership_data && detailMembership_data.length !== 0) {
    const [{ member_data: { name }, code, status, join_date, main_deposit, required_deposit, payment_period, duration }] = detailMembership_data;
    initialValues = {
      member: name,
      date: join_date,
      main_deposit,
      code,
      required_deposit,
      status: {
        value: status,
        label: status === 1 ? 'Aktif' : 'Tidak Aktif'
      }
    }
  }

  return {
    editMembership_pending: getMembershipPending(editMembership),
    detailMembership_pending: getMembershipPending(detailMembership),
    detailMembership_data,
    initialValues
  };
}

export default connect(mapStateToProps, {
  memberList, editMembership
})(withRouter(ModalEditMembership));
