import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from '../../actions/login/login_action';



let user = JSON.parse(localStorage.getItem('user_paboi'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case LOGIN_PENDING:
            return {
                loggingIn: true,
            };
        case LOGIN_SUCCESS: 
            return {
                loggedIn: true,
                user: action.data
            };
        case LOGIN_ERROR:
            return {};
        case LOGOUT:
            return {};
        default:
            return state
    }
}


