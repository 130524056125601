import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { default_api } from "../../constants/default_api";

class ModalBuktiTolak extends Component {
  render() {
    const folder = this.props.folder ? this.props.folder : "register_reject";
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Alasan Penolakan</p>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            <span className={css(styles.reason)}>{this.props.reason}</span>
            {!this.props.isWithoutImage && (
              <>
                <p
                  style={{ fontSize: 20, fontWeight: 600, margin: "10px 0px" }}
                >
                  Screenshot Kesalahan
                </p>
                {this.props.imgUrl ? (
                  <img
                    src={
                      default_api +
                      "/image/show/" +
                      folder +
                      "/" +
                      this.props.imgUrl
                    }
                    alt="verifikasi-ditolak"
                    className={css(styles.imgBuktiPembayaran)}
                  />
                ) : (
                  <span>-</span>
                )}
              </>
            )}
          </Column>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  imgBuktiPembayaran: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
  reason: {
    opacity: 0.7,
    marginTop: 20,
    marginBottom: 10,
  },
});

export default ModalBuktiTolak;
