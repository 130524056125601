import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import SelectComponent from "../../components/form/SelectComponent";
import InputComponent from "../../components/form/InputComponent";
import { universityList, editUniversity } from "../../actions/master-data/university_action";
import { getUniversityListPending, getUniversityList, getUniversityListError, getUniversityEdit } from "../../reducers/master-data/university_reducer";

class ModalEditUniversitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            university_code: "",
            university_name: "",
            university_abbreviation: "",
            university_city_value: "",
            university_city_label: "",
            search: ""
        };
        this.handleChangeUCode = this.handleChangeUCode.bind(this);
        this.handleChangeUName = this.handleChangeUName.bind(this);
        this.handleChangeUAbbreviation = this.handleChangeUAbbreviation.bind(this);
        this.handleChangeUCity = this.handleChangeUCity.bind(this);
    }

    handleChangeUCode(e) {
        if (e !== null) {
            this.setState({ university_code: e.target.value })
        } else {
            this.setState({ university_code: "" })
        }
    }

    handleChangeUName(e) {
        if (e !== null) {
            this.setState({ university_name: e.target.value })
        } else {
            this.setState({ university_name: "" })
        }
    }

    handleChangeUAbbreviation(e) {
        if (e !== null) {
            this.setState({ university_abbreviation: e.target.value })
        } else {
            this.setState({ university_abbreviation: "" })
        }
    }

    handleChangeUCity(e) {
        if (e !== null) {
            this.setState({ university_city: e.value })
        } else {
            this.setState({ university_city: "" })
        }
    }

    editUniversitas() {
        const { editUniversity, onHide } = this.props;
        const param_editUniversitas = {
            id: this.props.data.data[0].id,
            code: this.state.university_code,
            name: this.state.university_name,
            abbreviation: this.state.university_abbreviation,
            city_id: this.state.university_city_value,
        }

        const callback = () => {
            onHide();
            this.props.handleRefresh({ search: "" })
        }
        editUniversity(param_editUniversitas, callback);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.setState({ university_code: this.props.data.data[0].code })
            this.setState({ university_name: this.props.data.data[0].name })
            this.setState({ university_abbreviation: this.props.data.data[0].abbreviation !== null ? this.props.data.data[0].abbreviation : "" })
            this.setState({ university_city_value: this.props.data.data[0].city.city_id })
            this.setState({ university_city_label: this.props.data.data[0].city.name })
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                cityOption={this.props.listCityOption}
                id={this.props.id}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Edit Universitas
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <LabelInputVerticalComponent label="Kode Universitas" marginTop="20px">
                            <InputComponent
                                isClearable={false}
                                placeholder="Masukkan kode universitas"
                                onChange={this.handleChangeUCode}
                                value={this.state.university_code}
                                id="universityCode"
                                disabled
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Nama Universitas" marginTop="20px">
                            <InputComponent
                                isClearable={false}
                                placeholder="Masukkan nama universitas"
                                onChange={this.handleChangeUName}
                                value={this.state.university_name}
                                id="universityName"
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Singkatan Universitas" marginTop="20px">
                            <InputComponent
                                isClearable={false}
                                placeholder="Masukkan singkatan universitas"
                                onChange={this.handleChangeUAbbreviation}
                                value={this.state.university_abbreviation}
                                id="universityAbbreviation"
                            />
                        </LabelInputVerticalComponent>
                        <LabelInputVerticalComponent label="Kota" marginTop="20px">
                            <SelectComponent
                                isClearable={false}
                                placeholder="Masukkan asal kota universitas"
                                options={this.props.listCityOption}
                                onChange={this.handleChangeUCity}
                                value={{ value: this.state.university_city_value, label: this.state.university_city_label }}
                                id="universityCity"
                            />
                        </LabelInputVerticalComponent>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.editUniversitas()}
                        >
                            SIMPAN
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ editUniversity, universityList }) {
    return {
        editUniversitas: getUniversityEdit(editUniversity),
        universityList_pending: getUniversityListPending(universityList),
        universityList_data: getUniversityList(universityList),
        universityList_error: getUniversityListError(universityList),
    };
}


export default connect(mapStateToProps, { editUniversity, universityList })(ModalEditUniversitas);