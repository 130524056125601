import {
    REALM_ACTIVITY_PENDING,
    REALM_ACTIVITY_SUCCESS,
    REALM_ACTIVITY_ERROR,
} from "../../actions/master-data/realm_activity_action";

const initialState = {
    pending: false,
    error: null
};

export function realmActivityList(
    state = { ...initialState, realmActivityList: null },
    action
) {
    switch (action.type) {
        case REALM_ACTIVITY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case REALM_ACTIVITY_SUCCESS:
            return {
                ...state,
                pending: false,
                realmActivityList: action.data,
            };
        case REALM_ACTIVITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getRealmActivityListPending = (state) => state.pending;
export const getRealmActivityList = (state) => state.realmActivityList;
export const getRealmActivityListError = (state) => state.error;