import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import Button from "@material-ui/core/Button";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    detailSip,
    changeSipType,
} from "../../actions/p2kb/sip_action";
import {
    getSipPending,
    getSipError,
    getChangeSipType,
} from "../../reducers/p2kb/sip_reducer";
import { reduxForm, Field } from "redux-form";
import { renderInput, renderInputAktivasi } from "../../components/form/FormComponent";

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    }
});

class SipModalActivation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes_value: null,
        }
        this.handleSipActivation = this.handleSipActivation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        if (event !== undefined) {
            const value = event.target.value
            this.setState({ notes_value: value })
        }
    }

    handleSipActivation(event) {
        event.preventDefault();
        const { id, changeSipType, detailSip } = this.props;
        const onHide = this.props.onHide();
        let param_changeSipType = {
            id: id,
            sip_type: 1,
            activation_notes: this.state.notes_value,
        }
        const callback = () => {
            onHide();
            detailSip(id);
        }
        changeSipType(param_changeSipType, callback);
    }

    render() {
        const { changeSipType_pending, memberName } = this.props;
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide()}
                    dialogClassName="modal-width"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            onSubmit={this.handleSipActivation}
                            style={{ width: "100%" }}
                        >
                            <Column className={css(styles.bodyModal)}>
                                <span>Apakah Anda yakin ingin mengubah status SIP {memberName} menjadi Aktif? </span>
                                <>
                                    <Field
                                        name="activation_notes"
                                        placeholder="Masukkan catatan aktivasi"
                                        onChange={this.handleInputChange}
                                        component={renderInputAktivasi}
                                    />
                                </>
                            </Column>
                            <Row
                                horizontal="flex-end"
                                vertical="center"
                                className={css(styles.footerModal)}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.rejectButton)}
                                    onClick={this.props.onHide()}
                                >
                                    Batal
                                 </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={changeSipType_pending}
                                    className={css(styles.approveButton)}
                                >
                                    Ya, Yakin
                                </Button>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

SipModalActivation = reduxForm({
    form: "SipModalActivation",
    enableReinitialize: true,
    shouldError: () => true
})(SipModalActivation);

function mapStateToProps(state) {
    return {
        changeSipType_pending: getSipPending(state.changeSipType),
        changeSipType_data: getChangeSipType(state.changeSipType),
        changeSipType_error: getSipError(state.changeSipType),
    };
}

export default connect(mapStateToProps, { detailSip, changeSipType })(withRouter(SipModalActivation));