import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";


import _ from "lodash";

import { getUser } from "../../helpers/user";

const styles = StyleSheet.create({
  container: {
    width: 150,
    marginLeft: 10,
  },
});
class HeaderKoperasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: getUser().id,
      search: "",
      status: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search_text: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleStatusChange(value) {
    if (value) {
      this.props.handleRefresh({ status: value.value });
    } else {
      this.props.handleRefresh({ status: "" });
    }
  }



  render() {

    const { handleShowModalAdd } = this.props;


    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={handleShowModalAdd}
          >
            tambah anggota
        </Button>
        </div>
        <div>
          <InputComponent
            onChange={this.handleSearchChange}
            value={this.state.tes}
            placeholder="Cari member"
            startIcon={SearchIcon}
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HeaderKoperasi);
