import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  trainingList,
  editTraining,
  setIsEditFile,
} from "../../actions/master-data/training_actions";
import {
  getTrainingListPending,
  getTrainingList,
  getTrainingListError,
  getTrainingEditPending,
  getTrainingEdit,
  getTrainingEditError,
  getTrainingIsEditFile,
} from "../../reducers/master-data/training_reducer";
import { connect } from "react-redux";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import { getExperienceIsEditFile } from "../../reducers/master-data/experience_reducer";

class DetailPelatihan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { trainingList } = this.props;
    const param_trainingList = {
      page: 1,
      length: 1,
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_trainingList.id = id;
    trainingList(param_trainingList);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editTraining_isEditFile, setIsEditFile } = this.props;
    if (editTraining_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editTraining_isEditFile);
  }

  handleSubmit(values) {
    const { trainingList_data, editTraining } = this.props;

    const { berkas, institution, training, year } = values;
    const param = {
      id: trainingList_data.data[0].id,
      filename: trainingList_data.data[0].filename,
      institution,
      name: training,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      trainingList();
      this.handleTriggerFormEdit();
    };
    editTraining(param, callback);
  }

  render() {
    const {
      trainingList_data,
      handleSubmit,
      editTraining_pending,
      editTraining_data,
      editTraining_isEditFile,
    } = this.props;
    const { isEdit } = this.state;
    let data = [];
    let member_name = "";
    let status = "";
    let filename = null;
    let reject_filename = null;
    let reject_notes = null;
    if (trainingList_data) {
      data = trainingList_data.data[0];
      member_name = data.member_data.name;
      status = data.status;
      filename = data.filename;
      reject_notes = data.reject_notes;
      filename = data.filename;
      reject_filename = data.reject_filename;
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1957; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <Row>
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={member_name} />
              <StatusSquareComponent
                statusLabel={
                  status === 0
                    ? "Menunggu verifikasi"
                    : status === 1
                      ? "Data Pelatihan Terverifikasi"
                      : reject_notes
                }
                statusTitle={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : "Ditolak"
                }
                status={status}
                filename={reject_filename}
                filepath="member_training"
              />
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Pelatihan" />
                {status === 2 &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editTraining_pending}
                  />
                }
              </Row>
              <FormContainer label="Nama Institusi">
                <Field
                  isDetail={!isEdit}
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Pelatihan dan Lokakarya">
                <Field
                  isDetail={!isEdit}
                  name="training"
                  placeholder="Masukkan nama pelatihan"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Tahun">
                <Field
                  isDetail={!isEdit}
                  options={yearOptions}
                  name="year"
                  placeholder="Masukkan tahun"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editTraining_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  filePath="member_training"
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
            </form>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
});

function validate(values, props) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const {editTraining_isEditFile} = props;
  const { institution, training, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!training) {
    errors.training = "Pelatihan wajib diisi";
  }
  if (!year) {
    errors.year = "Tahun wajib diisi";
  }
  if (editTraining_isEditFile && !berkas) {
    errors.berkas = "Berkas pelatihan wajib diisi";
  }
  if(values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
  }
  if(values.berkas && (values.berkas.size > maxFileSize)){
    errors.berkas = "File lebih dari 500KB"
  }

  return errors;
}

DetailPelatihan = reduxForm({
  // a unique name for the form
  form: "DetailPelatihan",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(DetailPelatihan);

function mapStateToProps(state) {
  const { trainingList, editTraining } = state;
  let initialValues = {};
  const trainingList_data = getTrainingList(trainingList);
  if (trainingList_data) {
    initialValues = {
      institution: trainingList_data.data[0].institution,
      training: trainingList_data.data[0].name,
      year: {
        label: `${trainingList_data.data[0].year}`,
        value: trainingList_data.data[0].year,
      },
    };
  }
  return {
    trainingList_pending: getTrainingListPending(trainingList),
    trainingList_data: getTrainingList(trainingList),
    trainingList_error: getTrainingListError(trainingList),
    editTraining_pending: getTrainingEditPending(editTraining),
    editTraining_data: getTrainingEdit(editTraining),
    editTraining_isEditFile:getExperienceIsEditFile(editTraining),
    editTraining_error: getTrainingEditError(editTraining),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  trainingList,
  editTraining,
  setIsEditFile,
})(withRouter(DetailPelatihan));
