import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
    renderFile,
} from "../../components/form/FormComponent";
import { importReport } from '../../actions/report/report_action';
import { getImportReport, getImportReportPending, getImportReportError } from '../../reducers/report/report_reducer';

class MasterReportUpload extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            errorData: []
        }
    }

    handleSubmit = (values) => {
        const { onHide, importReport, handleRefresh } = this.props;
        const callback = () => {
            onHide();
            handleRefresh();
        };
        const callbackError = (value) => {
            this.setState({
                errorData: value
            })
        };
        importReport(values.berkas, callback, callbackError);
        setTimeout(()=>{
            values.berkas = null
        }, 3000)
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            importReport_pending,
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Import Laporan</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <label>Berkas Laporan</label>
                                <Field
                                    name="berkas"
                                    type="file"
                                    fileType="excel"
                                    title="upload berkas"
                                    message=".xls atau .xlsx"
                                    component={renderFile}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={importReport_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    importReport_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                        {this.state.errorData.length > 0 &&(
                            <Row>
                                <Column vertical="center"
                                horizontal="stretch"
                                className={css(styles.bodyModalError)}>
                                <h6>Mohon melakukan import ulang karena data berikut tidak sesuai!</h6>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Paboi ID
                                            </th>
                                            <th>
                                                Transaction
                                            </th>
                                            <th>
                                                Payment Type
                                            </th>
                                            <th>
                                                Amount
                                            </th>
                                            <th>Ket</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.errorData.map((value)=>{
                                            return (
                                            <tr>
                                                <td>{value.paboi_id}</td>
                                                <td>{value.transaction_code}</td>
                                                <td>{value.payment_type}</td>
                                                <td>{value.amount}</td>
                                                <td>{value.ket}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </Column>
                            </Row>
                        )}
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
    bodyModalError: {
        padding: "0px 40px 20px",
        width: "100%",
        color: "red"
    },
});

function validate(values) {
    const {
        berkas,
    } = values;
    const errors = {};
    if (!berkas) {
        errors.berkas = "Berkas wajib diisi";
    }

    return errors;
}

MasterReportUpload = reduxForm({
    // a unique name for the form
    form: "MasterReportUpload",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    shouldError: () => true,
    enableReinitialize: true,
})(MasterReportUpload);

function mapStateToProps(state) {
    const { importReport } = state;
    const selector = formValueSelector("MasterReportUpload");
    const formValue = selector(state, "berkas");

    return {
        importReport_pending: getImportReportPending(importReport),
        importReport_data: getImportReport(importReport),
        importReport_data: getImportReportError(importReport),
        formValue,
    };
}

export default connect(mapStateToProps, { importReport })(withRouter(MasterReportUpload));
