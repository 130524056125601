import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { editSubspesialis } from "../../actions/master-data/subspesialis_action";
import { renderInput } from "../../components/form/FormComponent";
import {
  getSubspesialisError,
  getDetailSubspesialis,
  getSubspesialisPending,
} from "../../reducers/master-data/subspesialis_reducer";

import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";

class ModalSubspesialisEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { editSubspesialis, onHide, id, handleRefresh } = this.props;
    const param = {
      id: id,
      name: values.name,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editSubspesialis(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      editSubspesialis_pending,
      detailSubspesialis_pending,
      listProvince_pending,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit Subspesialis</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailSubspesialis_pending || listProvince_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <LabelInputVerticalComponent
                    label="Nama Subspesialis"
                    marginTop="20px"
                  ></LabelInputVerticalComponent>
                  <Field
                    name="name"
                    placeholder="Masukkan nama Subspesialis"
                    component={renderInput}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editSubspesialis_pending}
                className={css(styles.approveButton)}
                startIcon={
                  editSubspesialis_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { name } = values;
  const errors = {};

  if (!name) {
    errors.name = "Nama Subspesialis tidak boleh kosong";
  }
  return errors;
}

ModalSubspesialisEdit = reduxForm({
  // a unique name for the form
  form: "ModalSubspesialisEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ModalSubspesialisEdit);

function mapStateToProps({ detailSubspesialis, editSubspesialis }) {
  let initialValues = {};
  const detailSubspesialis_data = getDetailSubspesialis(detailSubspesialis);
  if (detailSubspesialis_data) {
    initialValues = {
      name: detailSubspesialis_data[0].name,
    };
  }
  return {
    detailSubspesialis_pending: getSubspesialisPending(detailSubspesialis),
    detailSubspesialis_data: getDetailSubspesialis(detailSubspesialis),
    detailSubspesialis_error: getSubspesialisError(detailSubspesialis),
    editSubspesialis_pending: getSubspesialisPending(editSubspesialis),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  editSubspesialis,
})(withRouter(ModalSubspesialisEdit));
