import React, { Component } from "react";
import { connect } from "react-redux";
import { listActivity } from "../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityError,
  getActivityPending,
} from "../../reducers/master-data/activity_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import HeaderActivity from "./HeaderActivity";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
    };

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  componentDidMount() {}

  render() {
    const { listActivity, listActivity_data } = this.props;
    const { search_text } = this.state;
    let data = [];
    let records_total = 0;
    if (listActivity_data) {
      data = listActivity_data.data;
      records_total = listActivity_data.records_total;
    }
    return (
      <ContentContainer>
        <HeaderActivity handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama Kegiatan",
              field: "name",
            },
            {
              title: "Tipe Kegiatan",
              field: "activity_type",
            },
            {
              title: "Ranah Kegiatan",
              field: "realm_activity.name",
            },
            // {
            //   title: "Total SKP",
            //   render: ({ id }) => {
            //     return <Row>0</Row>;
            //   },
            // },

            // {
            //   title: "",
            //   render: ({ id }) => {
            //     return (
            //       <DetailButtonComponent>
            //         <MenuItem>Detail</MenuItem>
            //       </DetailButtonComponent>
            //     );
            //   },
            // },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
              };
              listActivity(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listActivity }) {
  return {
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    listActivity_pending: getActivityPending(listActivity),
  };
}

export default connect(mapStateToProps, {
  listActivity,
})(Activity);
