import React, { Component } from "react";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import SupportList from "./SupportList";
import DetailButton from "./DetailButton";
import { listSupport } from "../../actions/support/support_action";
import { getListSupport } from "../../reducers/support/support_reducer";
import htmlToText from "html-to-text";
import { getUser } from "../../helpers/user";
import SelectComponent from "../../components/form/SelectComponent";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

class CardSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.props.listSupport();
  }

  handleSelectChange(e) {
    this.setState({ branch_id: e });
    if (e) {
      this.props.listSupport({ branch_id: e.value });
    } else {
      this.props.listSupport();
    }
  }

  render() {
    const { listSupport_data, listBranch_data } = this.props;
    const user = getUser().role_codes[0];

    let branchOptions = [];
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => {
        return { label: name, value: id };
      });
    }

    let support_result = (
      <>
        <div className={css(styles.supportWrapper)}></div>
        <div className={css(styles.supportWrapper)}></div>
        <div className={css(styles.supportWrapper)}></div>
        <div className={css(styles.supportWrapper)}></div>
      </>
    );
    if (listSupport_data && listSupport_data.data.length !== 0) {
      support_result = listSupport_data.data
        .filter((item, i) => i < 4)
        .map(
          (
            { date, description, title, member_data: { name, img_profile } },
            status
          ) => (
            <SupportList
              key={uuidv4()}
              support_name={name}
              support_date={date}
              support_title={title}
              support_desc={htmlToText.fromString(description)}
              support_status={1}
              support_image={img_profile}
            />
          )
        );
    } else if (listSupport_data && listSupport_data.data.length === 0) {
      support_result = <span style={{ textAlign: "center", margin: "auto" }}>Tidak ada data</span>
    }

    return (
      <Column>
        <div className={css(styles.cardHeader)}></div>

        <Column
          className={css(styles.cardSupportWrapper)}
          style={{ width: "100%", minHeight: 600 }}
        >
          <Column
            className={css(styles.wrapperTop, styles.borderBottom)}
            style={{ width: "100%", padding: 20 }}
            vertical="center"
            horizontal="center"
          >
            <label className={css(styles.titleLabel)}>Support Trouble</label>
            {(user === "root" || user === 'ketua_paboi_pusat') && (
              <div style={{ width: 200 }}>
                <SelectComponent
                  options={branchOptions}
                  isRounded
                  value={this.state.branch_id}
                  onChange={this.handleSelectChange}
                  placeholder="Pilih Cabang"
                />
              </div>
            )}
          </Column>
          {support_result}
          <div style={{ marginTop: 20 }}>
            <DetailButton
              detail_url={
                "http://" +
                window.location.href.split("/")[2] +
                "/admin/support"
              }
            />
          </div>
        </Column>
      </Column>
    );
  }
}

function mapStateToProps({ listSupport, listBranch }) {
  return {
    listSupport_data: getListSupport(listSupport),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
  };
}

export default connect(mapStateToProps, {
  listSupport,
})(CardSupport);

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "0px 0px 15px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  cardSupportWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 15,
  },
  cardHeader: {
    backgroundColor: "#00A4ED",
    height: 10,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  supportWrapper: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    marginBottom: 10,
    height: 140,
  },
});
