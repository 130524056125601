import {
  LIST_SUBSPECIALIST_PENDING,
  LIST_SUBSPECIALIST_SUCCESS,
  LIST_SUBSPECIALIST_ERROR,
  LIST_ALLOCATION_ERROR,
  LIST_ALLOCATION_PENDING,
  LIST_ALLOCATION_SUCCESS,
  LIST_ESTIMATIONFEE_ERROR,
  LIST_ESTIMATIONFEE_PENDING,
  LIST_ESTIMATIONFEE_SUCCESS,
  LIST_TOTALFEEIN_ERROR,
  LIST_TOTALFEEIN_PENDING,
  LIST_TOTALFEEIN_SUCCESS,
  LIST_TOTALMEMBER_ERROR,
  LIST_TOTALMEMBER_PENDING,
  LIST_TOTALMEMBER_SUCCESS,
  LIST_COOPERATIVE_MEMBERHSIP_ERROR,
  LIST_COOPERATIVE_MEMBERHSIP_PENDING,
  LIST_COOPERATIVE_MEMBERHSIP_SUCCESS
} from "../../actions/dashboard/pusat_action";

import {
  LIST_MAIN_DEPOSIT_ERROR,
  LIST_MAIN_DEPOSIT_PENDING,
  LIST_MAIN_DEPOSIT_SUCCESS,
  LIST_REQUIRED_DEPOSIT_ERROR,
  LIST_REQUIRED_DEPOSIT_PENDING,
  LIST_REQUIRED_DEPOSIT_SUCCESS,
  LIST_SHU_ERROR,
  LIST_SHU_PENDING,
  LIST_SHU_SUCCESS,
  REPORT_SHU_ERROR,
  REPORT_SHU_PENDING,
  REPORT_SHU_SUCCESS,
} from "../../actions/dashboard/koperasi_action";

const initialState = {
  pending: false,
  data: null,
  error: null,
  mainDeposit: null,
  requiredDeposit: null,
  shu: null,
  reportSHU: null,
};

export function listSubspecialist(state = initialState, action) {
  switch (action.type) {
    case LIST_SUBSPECIALIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SUBSPECIALIST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_SUBSPECIALIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listEstimationFee(state = initialState, action) {
  switch (action.type) {
    case LIST_ESTIMATIONFEE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ESTIMATIONFEE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_ESTIMATIONFEE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTotalMember(state = initialState, action) {
  switch (action.type) {
    case LIST_TOTALMEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TOTALMEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_TOTALMEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTotalFeeIn(state = initialState, action) {
  switch (action.type) {
    case LIST_TOTALFEEIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TOTALFEEIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_TOTALFEEIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listAllocation(state = initialState, action) {
  switch (action.type) {
    case LIST_ALLOCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ALLOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_ALLOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function listCooperative(state = initialState, action) {
  switch (action.type) {
    case LIST_COOPERATIVE_MEMBERHSIP_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_COOPERATIVE_MEMBERHSIP_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_COOPERATIVE_MEMBERHSIP_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listMainDeposit(state = initialState, action) {
  switch (action.type) {
    case LIST_MAIN_DEPOSIT_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_MAIN_DEPOSIT_SUCCESS:
      return {
        ...state,
        pending: false,
        mainDeposit: action.data,
      };
    case LIST_MAIN_DEPOSIT_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listRequiredDeposit(state = initialState, action) {
  switch (action.type) {
    case LIST_REQUIRED_DEPOSIT_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_REQUIRED_DEPOSIT_SUCCESS:
      return {
        ...state,
        pending: false,
        requiredDeposit: action.data,
      };
    case LIST_REQUIRED_DEPOSIT_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listSHU(state = initialState, action) {
  switch (action.type) {
    case LIST_SHU_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_SHU_SUCCESS:
      return {
        ...state,
        pending: false,
        shu: action.data,
      };
    case LIST_SHU_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function reportSHU(state = initialState, action) {
  switch (action.type) {
    case REPORT_SHU_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case REPORT_SHU_SUCCESS:
      return {
        ...state,
        pending: false,
        reportSHU: action.data,
      };
    case REPORT_SHU_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getDashboardData = (state) => state.data;
export const getDashboardPending = (state) => state.pending;
export const getDashboardError = (state) => state.error;
export const getMainDepositData = (state) => state.mainDeposit;
export const getMainDepositPending = (state) => state.pending;
export const getMainDepositError = (state) => state.error;
export const getRequiredDepositData = (state) => state.requiredDeposit;
export const getRequiredDepositPending = (state) => state.pending;
export const getRequiredDepositError = (state) => state.error;
export const getSHUData = (state) => state.shu;
export const getSHUPending = (state) => state.pending;
export const getSHUError = (state) => state.error;
export const getReportSHUData = (state) => state.reportSHU;
export const getReportSHUPending = (state) => state.pending;
export const getReportSHUError = (state) => state.error;
