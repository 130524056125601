import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import RenderArray from "./BranchForm";
import { reduxForm, FieldArray, Field } from "redux-form";
import { editBranch } from "../../actions/master-data/branch_action";
import { renderInput, renderSelect } from "../../components/form/FormComponent";
import {
  getProvincePending,
  getListProvince,
} from "../../reducers/master-data/province_temp_reducer";

import {
  getBranchError,
  getDetailBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";

class ModalBranchEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { editBranch, onHide, id, handleRefresh } = this.props;
    let province = [];
    if (values.province.length > 0) {
      province.push(values.province[0].value)
    }
    else {
      province.push(values.province.value)
    }
    console.log(values)
    const param = {
      id: id,
      branch_provinces: province,
      name: values.name,
      city_id: values.city_id.value,
      office_address: values.office_address,
      phone: values.phone,
      email: values.email,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editBranch(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      editBranch_pending,
      isEdit,
      detailBranch_pending,
      cityOptions,
      listProvice_data,
      names
    } = this.props;
    let province_options = [];
    if (listProvice_data) {
      const names_id = names ? names.map((item) => item && item.value) : [];
      province_options = listProvice_data.data
        .filter((item) => names_id.indexOf(item.province_id) === -1)
        .map((item) => ({
          value: item.province_id,
          label: item.name,
        }));
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            {`${!isEdit ? "Provinsi" : "Edit"}`} Cabang
          </p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailBranch_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  {isEdit && (
                    <>
                      <Field
                        name="name"
                        placeholder="Masukkan nama cabang"
                        component={renderInput}
                      />
                      <Field
                        name="city_id"
                        placeholder="Pilih Kota"
                        options={cityOptions}
                        component={renderSelect}
                      />
                      <Field
                        name="office_address"
                        placeholder="Masukkan alamat sekretariat"
                        component={renderInput}
                      />
                      <Field
                        name="phone"
                        type="number"
                        placeholder="Masukkan no.telepon"
                        component={renderInput}
                      />
                      <Field
                        name="email"
                        type="email"
                        placeholder="Masukkan email"
                        component={renderInput}
                      />
                    </>
                  )}

                  <Field
                    name="province"
                    options={province_options}
                    placeholder="Pilih Provinsi"
                    component={renderSelect}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editBranch_pending}
                className={css(styles.approveButton)}
                startIcon={
                  editBranch_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { province, name, city_id, phone, office_address } = values;
  const errors = {};
  const provinceArrayErrors = [];
  if (!province) {
    errors.province = "Provinsi tidak boleh kosong";
  }
  if (!name) {
    errors.name = "Nama cabang tidak boleh kosong";
  }
  if (!phone) {
    errors.phone = "No. telepon tidak boleh kosong";
  }
  if (!office_address) {
    errors.office_address = "Alamat cabang tidak boleh kosong";
  }
  if (!city_id) {
    errors.city_id = "Kota tidak boleh kosong";
  }
  return errors;
}

ModalBranchEdit = reduxForm({
  // a unique name for the form
  form: "ModalBranchEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ModalBranchEdit);

function mapStateToProps({ detailBranch, editBranch, listProvincetTemp }) {
  let initialValues = {};
  const detailBranch_data = getDetailBranch(detailBranch);
  if (detailBranch_data && detailBranch_data.length !== 0) {
    const provinceData = detailBranch_data[0].branch_provinces.map((item) => ({
      label: item.province_name,
      value: item.province_id,
    }));
    initialValues = { province: provinceData, email: detailBranch_data[0].email, phone: detailBranch_data[0].phone, office_address: detailBranch_data[0].office_address, name: detailBranch_data[0].name, city_id: { label: detailBranch_data[0].city ? detailBranch_data[0].city.name : null, value: detailBranch_data[0].city ? detailBranch_data[0].city_id : null } };
  }
  return {
    detailBranch_pending: getBranchPending(detailBranch),
    detailBranch_data: getDetailBranch(detailBranch),
    detailBranch_error: getBranchError(detailBranch),
    editBranch_pending: getBranchPending(editBranch),
    listProvice_pending: getProvincePending(listProvincetTemp),
    listProvice_data: getListProvince(listProvincetTemp),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  editBranch,
})(withRouter(ModalBranchEdit));
