import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function EditButtonComponent(props) {
  const { isLoading, isEdit, triggerIsEdit, onSave, cancelEdit, isBerkasPendukung } = props;
  return (
    <Row vertical="center" className={css(styles.editButtonContainer)}>
      {isEdit ? (
        <Row vertical="center">
          <Button
            // type={onSave ? "button" : "submit"}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            className={css(styles.approveButton)}
            startIcon={
              isLoading ? (
                <CircularProgress size={14} color="secondary" />
              ) : (
                  <CheckIcon />
                )
            }
            onClick={onSave}
          >
            simpan
          </Button>
          <ClearIcon
            onClick={cancelEdit ? cancelEdit : triggerIsEdit}
            style={{ color: "#495057", cursor: "pointer" }}
          />
        </Row>
      ) : (
          isBerkasPendukung ?
          <>
            <Button
                onClick={triggerIsEdit}
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={css(styles.approveButton)}
                startIcon={<EditIcon />}
              >
                edit
            </Button>
            <ClearIcon
              onHide = {!isEdit}
              onClick={ () => props.closeModal(false) }
              style={{ color: "#495057", cursor: "pointer" }}
            />
          </>
          :
          <Button
              onClick={triggerIsEdit}
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={css(styles.approveButton)}
              startIcon={<EditIcon />}
            >
              edit
          </Button>
        
        )}
    </Row>
  );
}

const styles = StyleSheet.create({
  // editButtonContainer: {
  //   padding: 5,
  //   ":hover": {
  //     backgroundColor: "#eaeaea",
  //     borderRadius: 5,
  //     cursor: "pointer",
  //   },
  // },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginRight: 10,
  },
  rejectButton: {
    background: "#EAEBED",
    marginLeft: 10,
    textAlign: "center",
    color: "#495057",
    // boxShadow: "none",
  },
});

export default EditButtonComponent;
