import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
// import CanvasJSReact from "../../assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import {
    listSubspecialist,
    listTotalMemberCabang,
} from "../../actions/dashboard/pusat_action";
import {
    getDashboardData,
} from "../../reducers/dashboard/pusat_reducer";
import {
    listBranch,
    listMemberDistribution,
    detailBranch,
} from "../../actions/master-data/branch_action";
import {
    getListBranch,
    getBranchPending,
    getDetailBranch,
    getDistributionError,
    getDistributionList,
    getDistributionPending,
} from "../../reducers/master-data/branch_reducer";
import SelectComponent from "../form/SelectComponent";
import mapsImage from "../../assets/maps_ijo.png";
import maps from "../../constants/maps";
import MapsCluster from "../../components/dasboard/MapsCluster";
import CardSebaranAnggotaTable from "../../components/dasboard/CardSebaranAnggotaTable";

// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CardMapsKolegium extends Component {
    constructor() {
        super();
        this.state = {
            branch_id: null,
            duration: 2,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    componentDidMount() {
        this.props.listMemberDistribution()
        this.props.detailBranch();
        this.props.listSubspecialist();
        this.props.listTotalMemberCabang();
        this.props.listBranch({ length: 999 })
    }

    handleSelectChange(e) {
        this.setState({ duration: 0.1 });
        this.setState({ branch_id: e });
        if (e) {
            this.props.detailBranch(e.value);
            this.props.listMemberDistribution({ branch_id: e.value })
            this.props.listSubspecialist({ branch_id: e.value });
            this.props.listTotalMemberCabang({ branch_id: e.value });
        } else {
            this.props.detailBranch("111");
            this.props.listMemberDistribution({ branch_id: "111" });
            this.props.listSubspecialist({ branch_id: "111" });
            this.props.listTotalMemberCabang({ branch_id: "111" });
        }
    }

    render() {
        const {
            listBranch_data,
            listDistribution_data,
            detailBranch_data,
            listSubspecialist_data,
            listTotalMember_pusat_cabang_data,
        } = this.props;
        let branchOptions = [],
            dot;
        let cabang,
            member_active = 0,
            member_non_active = 0;
        if (listBranch_data && listBranch_data.length !== 0) {
            branchOptions = listBranch_data.data.map(({ name, id }) => {
                return { label: name, value: id };
            });
        }
        branchOptions = [{
            value: '',
            label: 'Semua Cabang'
        }, ...branchOptions]
        if (detailBranch_data && detailBranch_data.length !== 0) {
            cabang = detailBranch_data.length > 1 ? 'Semua Cabang' : detailBranch_data[0].name;
            dot = detailBranch_data[0].branch_provinces.map(({ province_id }) => {
                let mapdata = maps.find(({ province_id: id }) => province_id === id);
                if (mapdata) {
                    const {
                        dot: [top, left],
                    } = mapdata;
                    return (
                        <div
                            className="dotmaps"
                            style={{
                                border: "2px solid white",
                                width: 12,
                                height: 12,
                                backgroundColor: "#5AB853",
                                borderRadius: "50%",
                                position: "absolute",
                                top: top,
                                left: left,
                            }}
                        ></div>
                    );
                }
                return null;
            });
        }

        let doctor = [];
        if (listSubspecialist_data) {
            doctor = listSubspecialist_data.map(
                ({ subspecialist_name, total }, i) => ({
                    subspecialist_name: subspecialist_name,
                    total: total,
                    no: i + 1,
                })
            );
        }

        if (
            listTotalMember_pusat_cabang_data &&
            listTotalMember_pusat_cabang_data.length !== 0
        ) {
            [
                { member_active, member_non_active },
            ] = listTotalMember_pusat_cabang_data;
        }

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Column
                    style={{
                        boxShadow: "0px 3px 6px #00000029",
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        height: "100%",
                        minHeight: 300,
                        width: "100%",
                        backgroundColor: "white",
                    }}
                >
                    <div className={css(styles.cardHeader)}></div>

                    <Row
                        className={css(styles.wrapperTop)}
                        style={{ width: "100%", padding: 20, backgroundColor: "white" }}
                        vertical="center"
                        horizontal="space-between"
                    >
                        <label className={css(styles.titleLabel)}>
                            {" "}
              Peta Sebaran Anggota PABOI
            </label>
                    </Row>
                    <Column
                        className={css(styles.wrapper)}
                        style={{
                            width: "100%",
                            padding: "20px 30px",
                            backgroundColor: "black",
                        }}
                        horizontal="center"
                    >
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <MapsCluster dataMember={listDistribution_data} />
                        </div>
                        <div style={{ width: 300, marginTop: 20 }}>
                            <SelectComponent
                                options={branchOptions}
                                isRounded
                                value={this.state.branch_id}
                                isClearable={false}
                                onChange={this.handleSelectChange}
                                placeholder="Pilih Cabang"
                            />
                        </div>
                        <CardSebaranAnggotaTable
                            data={doctor}
                            active={member_active}
                            non_active={member_non_active}
                            cabang={cabang}
                        />
                    </Column>
                </Column>
            </div>
        );
    }
}

function mapStateToProps({
    listSubspecialist_pusat,
    listBranch,
    listMemberDistribution,
    detailBranch,
    listTotalMember_pusat_cabang,
}) {
    return {
        listBranch_data: getListBranch(listBranch),
        listDistribution_data: getDistributionList(listMemberDistribution),
        listSubspecialist_data: getDashboardData(listSubspecialist_pusat),
        listTotalMember_pusat_cabang_data: getDashboardData(
            listTotalMember_pusat_cabang
        ),
        listBranch_pending: getBranchPending(listBranch),
        detailBranch_data: getDetailBranch(detailBranch),
    };
}

export default connect(mapStateToProps, {
    listSubspecialist,
    listBranch,
    detailBranch,
    listMemberDistribution,
    listTotalMemberCabang,
})(CardMapsKolegium);

const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: "bold",
        margin: "5px 0",
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 4,
    },
    wrapper: {
        // padding: 15,
        // height: 400,
        backgroundColor: "white",
    },
    borderBottom: {
        borderBottom: "1px solid #eeee",
    },
    cardHeader: {
        backgroundColor: "#5AB853",
        height: 10,
        width: "100%",
    },
});
