import {
    LIST_USER_PENDING,
    LIST_USER_SUCCESS,
    LIST_USER_ERROR,
    EDIT_USER_PENDING,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    DELETE_USER_PENDING,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    ADD_USER_PENDING,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    CHANGE_IMAGE_PROFILE_PENDING,
    CHANGE_IMAGE_PROFILE_SUCCESS,
    CHANGE_IMAGE_PROFILE_ERROR,
} from "../../actions/master-data/user_action";

const initialState = {
    pending: false,
    error: null
};

export function userList(
    state = { ...initialState, userList: null },
    action
) {
    switch (action.type) {
        case LIST_USER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                userList: action.data,
            };
        case LIST_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addUser(
    state = { ...initialState, addUser: null },
    action
) {
    switch (action.type) {
        case ADD_USER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                addUser: action.data,
            };
        case ADD_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editUser(
    state = { ...initialState, editUser: null },
    action
) {
    switch (action.type) {
        case EDIT_USER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                editUser: action.data,
            };
        case EDIT_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function deleteUser(
    state = { ...initialState, deleteUser: null },
    action
) {
    switch (action.type) {
        case DELETE_USER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteUser: action.data,
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function changeImageProfile(
    state = { ...initialState, changeImageProfile: null },
    action
) {
    switch (action.type) {
        case CHANGE_IMAGE_PROFILE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case CHANGE_IMAGE_PROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                changeImageProfile: action.data,
            };
        case CHANGE_IMAGE_PROFILE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getUserListPending = (state) => state.pending;
export const getUserList = (state) => state.userList;
export const getUserListError = (state) => state.error;
export const getUserAddPending = (state) => state.pending;
export const getUserAdd = (state) => state.addUser;
export const getUserAddError = (state) => state.error;
export const getUserEditPending = (state) => state.pending;
export const getUserEdit = (state) => state.editUser;
export const getUserEditError = (state) => state.error;
export const getUserDeletePending = (state) => state.pending;
export const getUserDelete = (state) => state.deleteUser;
export const getUserDeleteError = (state) => state.error;
export const getChangeImageProfilePending = (state) => state.pending;
export const getChangeImageProfile = (state) => state.changeImageProfile;
export const getChangeImageProfileError = (state) => state.error;