import {
    LIST_SIP_PENDING,
    LIST_SIP_SUCCESS,
    LIST_SIP_ERROR,
    EDIT_SIP_PENDING,
    EDIT_SIP_SUCCESS,
    EDIT_SIP_ERROR,
    APPROVAL_SIP_PENDING,
    APPROVAL_SIP_SUCCESS,
    APPROVAL_SIP_ERROR,
    ADD_SIP_PENDING,
    ADD_SIP_SUCCESS,
    ADD_SIP_ERROR,
    DETAIL_SIP_PENDING,
    DETAIL_SIP_SUCCESS,
    DETAIL_SIP_ERROR,
    UPLOAD_SIP_PENDING,
    UPLOAD_SIP_SUCCESS,
    UPLOAD_SIP_ERROR,
    SET_SELECTED_SIP,
} from "../../actions/pengajuan-sip/sip_action";

const initialState = {
    pending: false,
    error: null,
};

export function SIPList(state = { ...initialState, SIPList: null }, action) {
    switch (action.type) {
        case LIST_SIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_SIP_SUCCESS:
            return {
                ...state,
                pending: false,
                SIPList: action.data,
            };
        case LIST_SIP_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addSIP(state = { ...initialState, addSIP: null }, action) {
    switch (action.type) {
        case ADD_SIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_SIP_SUCCESS:
            return {
                ...state,
                pending: false,
                addSIP: action.data,
            };
        case ADD_SIP_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editSIP(state = { ...initialState, editSIP: null }, action) {
    switch (action.type) {
        case EDIT_SIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_SIP_SUCCESS:
            return {
                ...state,
                pending: false,
                editSIP: action.data,
            };
        case EDIT_SIP_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedSIP(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_SIP:
            return action.value;
        default:
            return state;
    }
}

export function approvalSIP(
    state = { ...initialState, approvalSIP: null },
    action
) {
    switch (action.type) {
        case APPROVAL_SIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVAL_SIP_SUCCESS:
            return {
                ...state,
                pending: false,
                approvalSIP: action.data,
            };
        case APPROVAL_SIP_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailSIP(
    state = { ...initialState, detail_branch: null },
    action
) {
    switch (action.type) {
        case DETAIL_SIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_SIP_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_branch: action.data,
            };
        case DETAIL_SIP_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function uploadSIP(
    state = { ...initialState, uploadSIP: null },
    action
  ) {
    switch (action.type) {
      case UPLOAD_SIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case UPLOAD_SIP_SUCCESS:
        return {
          ...state,
          pending: false,
          uploadSIP: action.data,
        };
      case UPLOAD_SIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }

export const getSIPPending = (state) => state.pending;
export const getDetailSIP = (state) => state.detail_branch;
export const getSIPError = (state) => state.error;
export const getSIPListPending = (state) => state.pending;
export const getSIPList = (state) => state.SIPList;
export const getSIPListError = (state) => state.error;
export const getSIPAddPending = (state) => state.pending;
export const getSIPAdd = (state) => state.addSIP;
export const getSIPAddError = (state) => state.error;
export const getSIPEditPending = (state) => state.pending;
export const getSIPEdit = (state) => state.editSIP;
export const getSIPEditError = (state) => state.error;
export const getSIPApprovalPending = (state) => state.pending;
export const getSIPApproval = (state) => state.approvalSIP;
export const getSIPApprovalError = (state) => state.error;
export const getSelectedSIP = (state) => state.selectedSIP;
export const getSIPUploadPending = (state) => state.pending;
export const getSIPUpload = (state) => state.uploadSIP;
export const getSIPUploadError = (state) => state.error;