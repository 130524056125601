import React, { Component } from "react";
import { connect } from "react-redux";
import { listSupport } from "../../actions/support/support_action";
import {
  getListSupport,
  getSupportError,
  getSupportPending,
} from "../../reducers/support/support_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import SupportHeader from "./SupportHeader";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import moment from "moment";

class Support extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/support/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id })
    }
  }

  render() {
    const { listSupport, listSupport_data } = this.props;
    const { search_text } = this.state;
    let data = [];
    let records_total = 0;
    if (listSupport_data) {
      data = listSupport_data.data;
      records_total = listSupport_data.records_total;
    } 
    return (
      <ContentContainer>
        <SupportHeader handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Tanggal Pengajuan",
              render: ({ date }) => moment(date).format("DD MMMM YYYY"),
            },
            // {
            //   title: "Deskripsi",
            //   field: "description",
            //   width: 480,
            //   render: ({ description }) => {
            //     let result = htmlToText.fromString(description);
            //     if (result && result.length > 150) {
            //       result = result.substring(0, 150) + "...";
            //     }
            //     return result;
            //   },
            // },
            {
              title: "No Trouble Ticket",
              field: "ticket_no",
            },
            {
              title: "Judul Pengajuan",
              field: "title",
            },
            {
              title: "Jenis Pengajuan",
              render: ({types }) =>
                types === 1
                  ? "Teknis" : "Profesional"
              },
            {
              title: "User",
              headerStyle: {
                minWidth: 140,
              },
              field: "forward_to_admin",
              render: ({ forward_to_admin, level, status, types }) => {
                if (forward_to_admin === 0) {
                  return ("Admin Cabang")
                }  
                else if (forward_to_admin === 1){
                  return ("Admin Pusat")
                }
                else {
                  return ("Sekretaris Depoi")
                }
              }
            },

            {
              title: "Status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Menunggu"
                        : status === 1
                        ? "Proses"
                        : "Selesai"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.detailRecord(id)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                id: this.state.id
              };
              listSupport(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSupport }) {
  return {
    listSupport_data: getListSupport(listSupport),
    listSupport_error: getSupportError(listSupport),
    listSupport_pending: getSupportPending(listSupport),
  };
}

export default connect(mapStateToProps, {
  listSupport,
})(Support);
