import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
    renderInputWithLabel
} from "../../components/form/FormComponent";
import { addMasterBenefit } from "../../actions/master-data/master_benefit_action";
import { getMasterBenefitAddPending, getMasterBenefitAdd, getMasterBenefitAddError } from "../../reducers/master-data/master_benefit_reducer";

class ModalAddBenefit extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        let param = {
            name: values.name,
            total_shu: values.total_shu.match(/\d+/g).join('')
        }
        const callback = () => {
            this.props.reset();
            this.props.handleRefresh();
            this.props.onHide();
        }
        this.props.addMasterBenefit(param, callback);
    }

    toRupiah(value) {
        if (value) {
            if (/\d+/g.test(value)) {
                const reformatted = value.match(/\d+/g).join('');
                return reformatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            }
        }
    };

    render() {
        const { show,
            onHide,
            handleSubmit,
            addMasterBenefit_pending
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Benefit</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="name"
                                    placeholder="Masukkan Nama Benefit"
                                    label="Nama Benefit"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="total_shu"
                                    placeholder="Masukkan Jumlah SHU"
                                    label="Jumlah SHU"
                                    normalize={this.toRupiah}
                                    component={renderInputWithLabel}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                disabled={addMasterBenefit_pending}
                                startIcon={
                                    addMasterBenefit_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                Simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const { name, total_shu } = values;
    const errors = {}
    if (!name) {
        errors.name = "Nama Benefit wajib diisi"
    }
    if (!total_shu) {
        errors.total_shu = "Jumlah SHU wajib diisi"
    }
    return errors;
}

ModalAddBenefit = reduxForm({
    form: "ModalAddBenefit",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalAddBenefit)

function mapStateToProps(state) {
    const { addMasterBenefit } = state;
    const selector = formValueSelector("ModalAddBenefit");
    const formValue = selector(state, "benefit_id", "filename");
    return {
        addMasterBenefit_pending: getMasterBenefitAddPending(addMasterBenefit),
        addMasterBenefit_data: getMasterBenefitAdd(addMasterBenefit),
        addMasterBenefit_error: getMasterBenefitAddError(addMasterBenefit),
        formValue
    }
}

export default connect(mapStateToProps, { addMasterBenefit })(withRouter(ModalAddBenefit));