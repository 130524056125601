import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_SUBSPECIALIST_PENDING = "LIST_SUBSPECIALIST_PENDING_ADMIN";
export const LIST_SUBSPECIALIST_SUCCESS = "LIST_SUBSPECIALIST_SUCCESS_ADMIN";
export const LIST_SUBSPECIALIST_ERROR = "LIST_SUBSPECIALIST_ERROR_ADMIN";

export const LIST_TOTALMEMBER_PENDING = "LIST_TOTALMEMBER_PENDING_ADMIN";
export const LIST_TOTALMEMBER_SUCCESS = "LIST_TOTALMEMBER_SUCCESS_ADMIN";
export const LIST_TOTALMEMBER_ERROR = "LIST_TOTALMEMBER_ERROR_ADMIN";

export const LIST_ESTIMATIONFEE_PENDING = "LIST_ESTIMATIONFEE_PENDING_ADMIN";
export const LIST_ESTIMATIONFEE_SUCCESS = "LIST_ESTIMATIONFEE_SUCCESS_ADMIN";
export const LIST_ESTIMATIONFEE_ERROR = "LIST_ESTIMATIONFEE_ERROR_ADMIN";

export const LIST_TOTALFEEIN_PENDING = "LIST_TOTALFEEIN_PENDING_ADMIN";
export const LIST_TOTALFEEIN_SUCCESS = "LIST_TOTALFEEIN_SUCCESS_ADMIN";
export const LIST_TOTALFEEIN_ERROR = "LIST_TOTALFEEIN_ERROR_ADMIN";

export const LIST_ALLOCATION_PENDING = "LIST_ALLOCATION_PENDING_ADMIN";
export const LIST_ALLOCATION_SUCCESS = "LIST_ALLOCATION_SUCCESS_ADMIN";
export const LIST_ALLOCATION_ERROR = "LIST_ALLOCATION_ERROR_ADMIN";

export const LIST_COOPERATIVE_MEMBERHSIP_PENDING =
  "LIST_COOPERATIVE_MEMBERHSIP_PENDING_ADMIN";
export const LIST_COOPERATIVE_MEMBERHSIP_SUCCESS =
  "LIST_COOPERATIVE_MEMBERHSIP_SUCCESS_ADMIN";
export const LIST_COOPERATIVE_MEMBERHSIP_ERROR =
  "LIST_COOPERATIVE_MEMBERHSIP_ERROR_ADMIN";

export const LIST_MAIN_DEPOSIT_PENDING = "LIST_MAIN_DEPOSIT_PENDING";
export const LIST_MAIN_DEPOSIT_SUCCESS = "LIST_MAIN_DEPOSIT_SUCCESS";
export const LIST_MAIN_DEPOSIT_ERROR = "LIST_MAIN_DEPOSIT_ERROR";
export const LIST_REQUIRED_DEPOSIT_PENDING = "LIST_REQUIRED_DEPOSIT_PENDING";
export const LIST_REQUIRED_DEPOSIT_SUCCESS = "LIST_REQUIRED_DEPOSIT_SUCCESS";
export const LIST_REQUIRED_DEPOSIT_ERROR = "LIST_REQUIRED_DEPOSIT_ERROR";
export const LIST_SHU_PENDING = "LIST_SHU_PENDING";
export const LIST_SHU_SUCCESS = "LIST_SHU_SUCCESS";
export const LIST_SHU_ERROR = "LIST_SHU_ERROR";
export const REPORT_SHU_PENDING = "REPORT_SHU_PENDING";
export const REPORT_SHU_SUCCESS = "REPORT_SHU_SUCCESS";
export const REPORT_SHU_ERROR = "REPORT_SHU_ERROR";

const LIST_SUBSPECIALIST_URL = "dashboard/cooperative/subspecialist_doctor";
const LIST_TOTALMEMBER_URL = "dashboard/cooperative/total_member";
const LIST_ESTIMATIONFEE_URL = "dashboard/cooperative/estimation_fee";
const LIST_TOTALFEEIN_URL = "dashboard/cooperative/total_fee_in";
const LIST_ALLOCATION_URL = "dashboard/cooperative/total_allocation";
const LIST_COOPERATIVE_URL = "dashboard/cooperative/cooperative_membership";
const LIST_MAIN_DEPOSIT_URL = "dashboard/cooperative/total_main_deposit";
const LIST_REQUIRED_DEPOSIT_URL =
  "dashboard/cooperative/total_required_deposit";
const LIST_SHU_URL = "dashboard/cooperative/total_shu";
const REPORT_SHU_URL = "dashboard/cooperative/report_shu";

export function listCooperative(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_COOPERATIVE_MEMBERHSIP_PENDING));
    API.post(LIST_COOPERATIVE_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_COOPERATIVE_MEMBERHSIP_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_COOPERATIVE_MEMBERHSIP_ERROR, error));
        toastError(error, "Gagal mendapatkan data COOPERATIVE MEMBERSHIP, ");
      });
  };
}

export function listSubspecialist(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SUBSPECIALIST_PENDING));
    API.post(LIST_SUBSPECIALIST_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SUBSPECIALIST_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_SUBSPECIALIST_ERROR, error));
        toastError(error, "Gagal mendapatkan data SUBSPECIALIST, ");
      });
  };
}
export function listTotalMember(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_TOTALMEMBER_PENDING));
    API.post(LIST_TOTALMEMBER_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_TOTALMEMBER_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_TOTALMEMBER_ERROR, error));
        toastError(error, "Gagal mendapatkan data TOTAL MEMBER, ");
      });
  };
}
export function listEstimationFee(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_ESTIMATIONFEE_PENDING));
    API.post(LIST_ESTIMATIONFEE_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_ESTIMATIONFEE_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_ESTIMATIONFEE_ERROR, error));
        toastError(error, "Gagal mendapatkan data ESTIMATION FEE, ");
      });
  };
}
export function listTotalFeeIn(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_TOTALFEEIN_PENDING));
    API.post(LIST_TOTALFEEIN_URL, null, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_TOTALFEEIN_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_TOTALFEEIN_ERROR, error));
        toastError(error, "Gagal mendapatkan data TOTAL FEE IN, ");
      });
  };
}
export function listAllocation(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_ALLOCATION_PENDING));
    API.post(LIST_ALLOCATION_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_ALLOCATION_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_ALLOCATION_ERROR, error));
        toastError(error, "Gagal mendapatkan data TOTAL ALLOCATION, ");
      });
  };
}

export function listMainDeposit(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_MAIN_DEPOSIT_PENDING));
    API.post(LIST_MAIN_DEPOSIT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_MAIN_DEPOSIT_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_MAIN_DEPOSIT_ERROR, error));
        toastError(error, "Gagal mendapatkan data simpanan pokok koperasi");
      });
  };
}

export function listRequiredDeposit(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_REQUIRED_DEPOSIT_PENDING));
    API.post(LIST_REQUIRED_DEPOSIT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_REQUIRED_DEPOSIT_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_REQUIRED_DEPOSIT_ERROR, error));
        toastError(error, "Gagal mendapatkan data simpanan wajib koperasi");
      });
  };
}

export function listSHU(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SHU_PENDING));
    API.post(LIST_SHU_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SHU_SUCCESS, data));
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_SHU_ERROR, error));
        toastError(error, "Gagal mendapatkan data total SHU");
      });
  };
}

export function reportSHU(param = {}, resolve, reject, callback) {
  return (dispatch) => {
    dispatch(actionPending(REPORT_SHU_PENDING));
    API.post(REPORT_SHU_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(REPORT_SHU_SUCCESS, res.data));
        if (resolve) {
          resolve({
            data: res.data.data,
            page: param.page - 1,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(REPORT_SHU_ERROR, error));
        toastError(error, "Gagal mendapatkan data laporan dana SHU");
      });
  };
}
