import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import SuccessImage from "../../assets/mail_sent.png"

class ModalSuccess extends Component {
  constructor(props) {
    super(props);
  
  }

 

  componentDidMount() {
    
  }

  render() {
    const {show, onHide, email} = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
         <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className={css(styles.modalContent)}>
             <img src={SuccessImage}></img>
              <h2>Email Reset Password Terkirim</h2>
              <p>Email reset password akan dikirim ke email <b className={css(styles.greenFont)} >{email}</b></p>
          </div>
        </Modal.Body>
      </Modal >
    );
  }
}

const styles = StyleSheet.create({
    modalContent: {
      padding: "0px 50px 50px 50px",
        textAlign: "center"
    },
    greenFont: {
        color: "#04AA59"
    }
});


export default ModalSuccess;
