import { history } from "../../helpers/history";
import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import React from "react";
// import firebase from '../../firebase';
import { getUser } from "../../helpers/user";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

const LOGIN_URL = "auth/login";
const LOGIN_PPDS = 'auth/login_ppds';

export function login(email, password, isPPDS) {
  return (dispatch) => {
    var login = isPPDS ? LOGIN_PPDS : LOGIN_URL;
    dispatch(actionPending(LOGIN_PENDING));
    API.post(login, { email, password })
      .then((user) => {
        if (user.error) {
          throw user.error;
        }
        // const messaging = firebase.messaging();
        // messaging.requestPermission().then(() => {
        //   return messaging.getToken()
        // }).then(token => {
        //   API.post("auth/add_web_device", { user_id: getUser().id, reg_id: token })
        //     .then(res => {
        //       // console.log(res);
        //     })
        // }).catch(() => {
        //   console.log('error');
        // })
        dispatch(actionSuccess(LOGIN_SUCCESS, user.data.data));
        localStorage.setItem("user_paboi", JSON.stringify(user.data));
        history.push(`/${user.data.data.role_codes[0].toLowerCase()}`);
        toast.success(`Selamat Datang ${user.data.data.name}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((error) => {
        dispatch(actionError(LOGIN_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function logout() {
  if (getUser() && getUser() !== null) {
    API.post("auth/add_web_device", { user_id: getUser().id, reg_id: "null" })
      .then(res => {
        // console.log(res);
      })
  }
  localStorage.removeItem("user_paboi");
  return <Redirect to={{ pathname: "/login" }} />;
}
