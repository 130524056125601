import React, { Component } from "react";
import {
    renderSelect, renderInput
} from "../../components/form/FormComponent";
import { Field, FieldArray } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";
import {
    getMemberList,
    getMemberListPending,
    getMemberListError,
} from "../../reducers/member/member_reducer";
import { formValueSelector } from "redux-form";
import { getUser } from "../../helpers/user";
import RenderArrayField from "./Array_form_field";
import RenderArrayMember from "./Array_form_member";


class RenderArray extends Component {
    render() {
        const {
            fields,
            meta: { submitFailed, error },
            memberList_data,
        } = this.props;
        const user_branch_id = getUser().branch_id;
        let memberOptions = [];
        if (memberList_data) {
            memberOptions = memberList_data.data
                .filter((item) => item.branch_id === user_branch_id)
                .map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
        }

        return (
            <>
                {submitFailed && error && (
                    <Row
                        style={{
                            height: 38,
                            color: "#ED2A2A",
                            borderRadius: 3,
                            marginTop: 5,
                            marginBottom: 10,
                            backgroundColor: "#FFDEDE",
                        }}
                        horizontal="center"
                        vertical="center"
                    >
                        <span>
                            <ErrorIcon style={{ marginRight: 10 }} />
                            {error}
                        </span>
                    </Row>
                )}
                {fields.map((pengurus, index) => (
                    <Row
                        horizontal="space-between"
                        key={index}
                        style={{ marginBottom: 5 }}
                    >
                        <Field
                            name={pengurus}
                            placeholder="Masukkan nama bidang"
                            component={renderInput}
                        />
                        <Column style={{ width: "60%" }}>
                            <FieldArray
                                name={`member_field[${index}]`}
                                placeholder="Pilih Member"
                                component={RenderArrayMember}
                            />
                        </Column>
                        <CancelIcon
                            className={css(styles.closeButton)}
                            onClick={() => fields.remove(index)}
                        />
                    </Row>
                ))}

                <Column
                    horizontal="center"
                    vertical="center"
                    className={css(styles.buttonContainer)}
                >
                    <div
                        style={{
                            width: "100%",
                            borderBottom: "2px solid #EDEDEE",
                            top: 18,
                            position: "relative",
                        }}
                    />
                    <div
                        style={{ padding: "0px 10px", backgroundColor: "white", zIndex: 0 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            // onClick={this.handleSubmit}
                            startIcon={<AddIcon />}
                            onClick={() => fields.push()}
                        >
                            Tambah
                        </Button>
                    </div>
                </Column>
            </>
        );
    }
}

const styles = StyleSheet.create({
    closeButton: {
        color: "#CDCFD1",
        marginTop: 7,
        ":hover": {
            color: "#ED2A2A",
        },
    },
    Selectcontainer: { width: "20%" },
    approveButton: {
        zIndex: 0,
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
});

const selector = formValueSelector("Pengurus");

function mapStateToProps({
    memberList,
    ...restState
}) {
    const names = selector(restState, "pengurus");

    return {
        memberList_data: getMemberList(memberList),
        memberList_pending: getMemberListPending(memberList),
        memberList_error: getMemberListError(memberList),
        names,
    };
}

export default connect(mapStateToProps)(RenderArray);
