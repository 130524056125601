import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_PROVINCE_PENDING = "LIST_PROVINCE_PENDING";
export const LIST_PROVINCE_SUCCESS = "LIST_PROVINCE_SUCCESS";
export const LIST_PROVINCE_ERROR = "LIST_PROVINCE_ERROR";

const LIST_PROVINCE_URL = "master_data/data_reference/find_province";

export function listProvince(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_PROVINCE_PENDING));
    API.post(LIST_PROVINCE_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_PROVINCE_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_PROVINCE_ERROR, error));
        toastError(error, "Gagal mendapatkan list PROVINCE, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}
