import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { approvalTransrs } from "../../actions/transrs/transrs_action";
import {
    getTransrsAdd,
    getTransrsAddPending,
    getTransrsAddError
} from "../../reducers/transrs/transrs_reducer";

class ModalApprovalTransRS extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { approvalTransrs, onHide, handleRefresh, id } = this.props;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("is_approve", "true");
        const callback = () => {
            onHide();
            handleRefresh();
        };
        approvalTransrs(formData, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            approvalTransrs_pending
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi Trans RS</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            Apakah Anda yakin ingin menyetujuinya?
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={approvalTransrs_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    approvalTransrs_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

ModalApprovalTransRS = reduxForm({
    form: "ModalApprovalTransRS",
    // validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalApprovalTransRS);

function mapStateToProps({ approvalTransrs }) {
    return {
        approvalTransrs_pending: getTransrsAddPending(approvalTransrs),
        approvalTransrs_data: getTransrsAdd(approvalTransrs),
        approvalTransrs_error: getTransrsAddError(approvalTransrs),
    };
}

export default connect(mapStateToProps, {
    approvalTransrs,
})(withRouter(ModalApprovalTransRS));