import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import moment from "moment";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document";

class ModalAddDetailSKP_MembuatVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      modalDetailDokumenSKPShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_sip: null,
      berkas_medis: null,
      skp: 0,
      imgUrl: "",
      modal_title: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDetailDokumenSKPShow: true });
  }

  handleChangeTingkatan(values) {
    let listActivity_selected = this.props.listActivity_data.data.find(
      (item) => item.id === this.state.tempSKPData.activity.value
    );
    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: childrens_selected.values[0].id });
    let skp_must_be_multipied =
      childrens_selected.values[0].skp_must_be_multipied;
    let value_multipied = childrens_selected.values[0].value_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", childrens_selected.values[0].value_skp);
      this.setState({ skp: childrens_selected.values[0].value_skp });
    } else {
      this.props.change(
        "skp_total",
        Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        )
      );
      this.setState({
        skp: Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        ),
      });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    if (formName === "berkas_sip") {
      this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" });
      this.props.change("berkas_sip", "dvorak");
    } else if (formName === "berkas_medis") {
      this.setState({ berkas_medis: "Berkas Medis berhasil diunggah" });
      this.props.change("berkas_medis", "qwerty");
    }
    if (values.target.files[0].size < 2000000) {
      const callback = () => {
        if (this.props.uploadP2kb_data) {
          if (formName === "berkas_medis") {
            this.props.change("berkas_medis", this.props.uploadP2kb_data);
            this.setState({
              berkas_medis: (
                <DetailContentLihatBerkas
                  onClick={() =>
                    this.handleOpenDocument(
                      this.props.formValue.berkas_medis,
                      "Medis"
                    )
                  }
                />
              ),
            });
          }
        }
      };
      this.props.uploadP2kb(values.target.files[0], callback);
    } else {
      if (formName === "berkas_medis") {
        this.setState({ berkas_medis: "Batas maksimal ukuran file 2 MB!" });
      }
    }
  }

  handleSubmit(values) {
    let details_tobesent = {
      activity_date: moment(values.activity_date).format("DD MMMM YYYY"),
      topics: values.topics,
      total_skp: values.skp_total,
      mst_activity_skp_type_detail_data: {
        name: values.tingkatan.label
      },
      last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
      values_id: this.state.values_id,
      attachments: [
        {
          label: "Dokumen Bukti",
          filename: values.link,
        },
      ],
    };
    let details_tobeshown = {
      ...details_tobesent,
      tingkatan: values.tingkatan.label,
      link: values.link,
      skp: Math.round(this.state.skp),
    };
    this.props.handleAddDetail(details_tobeshown, details_tobesent);
    this.props.onHide();
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      listActivity_data,
      uploadP2kb_pending,
    } = this.props;
    let tingkatanOptions = [];
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
      tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="tingkatan"
                  placeholder="Masukkan Tingkat Kegiatan"
                  label="Tingkat Kegiatan"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={tingkatanOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="topics"
                  placeholder="Topik"
                  label="Topik"
                  component={renderInputWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="link"
                  placeholder="Link Video"
                  label="Link Video"
                  component={renderInputWithLabel}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { activity_date, tingkatan, topics, link } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!tingkatan) {
    errors.tingkatan = "Tingkat Kegiatan wajib diisi";
  }
  if (!topics) {
    errors.topics = "Topik wajib diisi";
  }
  if (!link) {
    errors.link = "Berkas wajib diisi";
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_MembuatVideo"));

ModalAddDetailSKP_MembuatVideo = reduxForm({
  form: "ModalAddDetailSKP_MembuatVideo",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalAddDetailSKP_MembuatVideo);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb } = state;
  const selector = formValueSelector("ModalAddDetailSKP_MembuatVideo");
  const formValue = selector(
    state,
    "tingkatan",
    "total_for_calculate_skp",
    "berkas_sip",
    "berkas_medis"
  );
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    formValue,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb })(
  withRouter(ModalAddDetailSKP_MembuatVideo)
);
