import { 
    LIST_MEMBERSHIP_PENDING,
    LIST_MEMBERSHIP_SUCCESS,
    LIST_MEMBERSHIP_ERROR,
    DETAIL_MEMBERSHIP_PENDING,
    DETAIL_MEMBERSHIP_SUCCESS,
    DETAIL_MEMBERSHIP_ERROR,
    EDIT_MEMBERSHIP_PENDING,
    EDIT_MEMBERSHIP_SUCCESS,
    EDIT_MEMBERSHIP_ERROR,
    DELETE_MEMBERSHIP_PENDING,
    DELETE_MEMBERSHIP_SUCCESS,
    DELETE_MEMBERSHIP_ERROR,
    ADD_MEMBERSHIP_PENDING,
    ADD_MEMBERSHIP_SUCCESS,
    ADD_MEMBERSHIP_ERROR,
    SET_SELECTED_MEMBERSHIP
  } from "../../actions/koperasi/membership_action";
  
   
   
  
  const initialState2 = {
    pending: false,
    list_membership: null,
    detail_membership: null,
    error: null,
  };
  
  export function listMembership(state = initialState2, action) {
    switch (action.type) {
      case LIST_MEMBERSHIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case LIST_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          pending: false,
          list_membership: action.data,
        };
      case LIST_MEMBERSHIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
  export function detailMembership(state = initialState2, action) {
    switch (action.type) {
      case DETAIL_MEMBERSHIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case DETAIL_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          pending: false,
          detail_membership: action.data,
        };
      case DETAIL_MEMBERSHIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
  export function editMembership(state = initialState2, action) {
    switch (action.type) {
      case EDIT_MEMBERSHIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case EDIT_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          pending: false,
        };
      case EDIT_MEMBERSHIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
  export function addMembership(state = initialState2, action) {
    switch (action.type) {
      case ADD_MEMBERSHIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case ADD_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          pending: false,
        };
      case ADD_MEMBERSHIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
  export function deleteMembership(state = initialState2, action) {
    switch (action.type) {
      case DELETE_MEMBERSHIP_PENDING:
        return {
          ...state,
          pending: true,
        };
      case DELETE_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          pending: false,
        };
      case DELETE_MEMBERSHIP_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
  export function selectedMembership(state = null, action) {
    switch (action.type) {
      case SET_SELECTED_MEMBERSHIP:
        return action.value;
      default:
        return state;
    }
  }
  
  export const getListMembership = (state) => state.list_membership;
  export const getDetailMembership = (state) => state.detail_membership;
  export const getMembershipPending = (state) => state.pending;
  export const getMembershipError = (state) => state.error;
  export const getSelectedMembership = (state) => state.selectedMembership;