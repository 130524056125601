import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalAddDetailSKP_PengabdianMasyarakat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      upload_berkas: null,
      skp: 0,
      imgUrl: "",
      modal_title: "",
      jenisOptions: null,
      isDoneUploading: true,
      upload_berkas_button: null,
      spek_upload_berkas: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleChangeJenisTingkatan = this.handleChangeJenisTingkatan.bind(
      this
    );
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  handleChangeTingkatan(values) {
    this.setState({ hasChooseStage: true })
    let listActivity_selected = this.props.listActivity_data.data.find(
      (item) => item.id === this.state.tempSKPData.activity.value
    );
    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    this.props.change("jenisTingkatan", null);
    this.props.change("skp_total", null);

    this.setState({
      jenisOptions: childrens_selected.values.map((item) => ({
        label: item.label,
        value: item.id,
      })),
    });

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
  }

  handleChangeJenisTingkatan(values) {
    let listActivity_selected = this.props.listActivity_data.data.find(
      (item) => item.id === this.state.tempSKPData.activity.value
    );
    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === this.state.last_activity_skp_detail_id
    );
    let values_selected = childrens_selected.values.find(
      (item) => item.id === values.value
    );

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: values_selected.id });
    let skp_must_be_multipied = values_selected.skp_must_be_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", values_selected.value_skp);
      this.setState({ skp: values_selected.value_skp });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({ upload_berkas_button: true })
    this.setState({ isDoneUploading: false })
    if (formName === "upload_berkas") {
      this.setState({ upload_berkas: <Loading /> })
    }
    const callback = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ upload_berkas_button: false })
      if (this.props.uploadP2kb_data) {
        if (formName === "upload_berkas") {
          this.props.change("upload_berkas", this.props.uploadP2kb_data);
          this.setState({ upload_berkas: <Loading done={true} /> })
          setTimeout(() => this.setState({ upload_berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.upload_berkas, "Berkas")} /> }), 2000)
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ upload_berkas_button: false })
      if (this.props.uploadP2kb_error) {
        if (formName === "upload_berkas") {
          this.props.change("upload_berkas", null);
          this.setState({ upload_berkas: <Loading done={true} failed={true} /> })
        }
      }
    }
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_upload_berkas", values.target.files[0]);
  }

  handleSubmit(values) {
    let details_tobesent = {
      activity_date: values.activity_date,
      activity_name: values.activity_name,
      activity_place: values.activity_place,
      city_name: values.city_name,
      mst_activity_skp_type_detail_data: {
        name: values.tingkatan.label,
      },
      total_skp: values.skp_total,
      total_for_calculate_skp: this.state.skp,
      last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
      values_id: this.state.values_id,
      attachments: [
        {
          label: "Keterangan / Sertifikat Kegiatan",
          filename: values.upload_berkas,
        },
      ],
    };

    let details_tobeshown = {
      ...details_tobesent,
      tingkatan: values.tingkatan.label,
      jenisTingkatan: values.jenisTingkatan.label,
      skp: this.state.skp,
    };
    this.props.handleAddDetail(details_tobeshown, details_tobesent);
    this.props.onHide();
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const {
      show,
      onHide,
      listActivity_data,
      handleSubmit,
      uploadP2kb_pending,
    } = this.props;
    let tingkatanOptions = [];
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
      tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="activity_name"
                  placeholder="Nama Kegiatan"
                  label="Nama Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="activity_place"
                  placeholder="Tempat Kegiatan"
                  label="Tempat Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="city_name"
                  placeholder="Kota Penyelenggara"
                  label="Kota Penyelenggara"
                  component={renderInputWithLabel}
                />
                <Field
                  name="tingkatan"
                  placeholder="Masukkan Skala Kegiatan"
                  label="Skala Kegiatan"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={tingkatanOptions}
                  component={renderSelectWithLabel}
                />
                {this.state.hasChooseStage && <Field
                  name="jenisTingkatan"
                  placeholder="Masukkan Jenis Kegiatan"
                  label="Jenis Kegiatan"
                  onChange={this.handleChangeJenisTingkatan}
                  isClearable={false}
                  options={this.state.jenisOptions}
                  component={renderSelectWithLabel}
                />}

                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="upload_berkas"
                  type="file"
                  fileType="skp"
                  title="Upload Berkas"
                  disabled={this.state.upload_berkas_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.upload_berkas === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.upload_berkas === null
                        ? "Image atau PDF maksimal 1 MB"
                        : this.state.upload_berkas
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/
  const fileType = "application/pdf"
  const {
    activity_date,
    activity_name,
    activity_place,
    city_name,
    tingkatan,
    upload_berkas,
    jenisTingkatan,
    spek_upload_berkas
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!activity_name) {
    errors.activity_name = "Nama Kegiatan wajib diisi";
  }
  if (!tingkatan) {
    errors.tingkatan = "Skala Kegiatan wajib diisi";
  }
  if (!jenisTingkatan) {
    errors.jenisTingkatan = "Jenis Kegiatan wajib diisi";
  }
  if (!activity_place) {
    errors.activity_place = "Tempat Kegiatan wajib diisi";
  }
  if (!city_name) {
    errors.city_name = "Kota Kegiatan wajib diisi";
  }
  if (!upload_berkas) {
    errors.upload_berkas = "Bukti Kegiatan wajib diisi";
  }
  else{
    if (spek_upload_berkas && (!spek_upload_berkas.type.match(typeImage) && spek_upload_berkas.type !== fileType)){
      errors.upload_berkas = "Format file hanya bisa Image atau PDF"
    }
    else if (spek_upload_berkas && (spek_upload_berkas.size > maxFileSize)){
      errors.upload_berkas = "File lebih dari 1 MB"
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_PengabdianMasyarakat"));

ModalAddDetailSKP_PengabdianMasyarakat = reduxForm({
  form: "ModalAddDetailSKP_PengabdianMasyarakat",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalAddDetailSKP_PengabdianMasyarakat);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb } = state;
  const selector = formValueSelector("ModalAddDetailSKP_PengabdianMasyarakat");
  const formValue = selector(
    state,
    "tingkatan",
    "waktuPenyelenggaraan",
    "total_for_calculate_skp",
    "berkas_sip",
    "upload_berkas",
    "spek_upload_berkas"
  );
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    formValue,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb })(
  withRouter(ModalAddDetailSKP_PengabdianMasyarakat)
);
