import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  listPractice,
  detailPractice,
} from "../../actions/sub-menu/practice_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { listHospital } from "../../actions/master-data/hospital_action";
import ModalPracticeAdd from "./ModalPracticeAdd";
import ModalPracticeDelete from "./ModalPracticeDelete";
import NumberInfo from "../../components/table/NumberInfo";
import {
  getPracticePending,
  getListPractice,
  getPracticeError,
} from "../../reducers/sub-menu/practice_reducer";
import HeaderPractice from "./HeaderPractice";

import { getUser } from "../../helpers/user";

class Practice extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    if (getUser().role_codes[0] === "member") {
      this.state = {
        user_id: getUser().id,
        search: "",
        status: "",
        id: null
      };
    } else {
      this.state = {
        user_id: "",
        search: "",
        status: "",
        id: null
      };
    }
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
  }

  componentDidMount() {
    const { listHospital } = this.props;
    listHospital({ length: 9999 });
    const url = window.location.href;
    const ID = url.substring(url.lastIndexOf('/') + 1);
    const isID = url.substring(url.lastIndexOf('/') + 1) !== "practice";
    if (isID && this.state.id === null) {
      this.setState({ id: ID })
    }
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/practice/practice-detail/${id}`);
  }

  handleOpenModalAdd(id) {
    this.setState({ id, modalAddShow: true });
  }

  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleOpenModalEdit(id, isEdit = false) {
    const { detailPractice } = this.props;
    detailPractice(id);
    this.setState({ id, isEdit });
    this.setState({ modalEditShow: true });
  }

  render() {
    let data = [];
    let records_total = 0;
    const { listPractice, listPractice_data } = this.props;
    if (listPractice_data) {
      data = listPractice_data.data;
      records_total = listPractice_data.records_total;
    }

    let day = [
      "-",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
      "Minggu",
    ];

    const {
      id,
      modalDeleteShow,
      modalAddShow,
    } = this.state;
    return (
      <ContentContainer>
        <ModalPracticeAdd
          id={id}
          show={modalAddShow}
          onHide={() => this.setState({ modalAddShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalPracticeDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <HeaderPractice
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />

        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama Member",
              field: "member_data.name",
            },
            {
              title: "Nama RS",
              field: "hospital_data.name",
            },
            {
              title: "Kelas RS",
              field: "hospital_data.class_type",
              render: ({ hospital_data }) => {
                if (hospital_data.class_type !== null) {
                  return hospital_data.class_type
                } else {
                  return "-"
                }
              }
            },
            {
              title: "Alamat",
              render: ({ hospital_data }) => {
                const hospital_address = hospital_data ? hospital_data.address : null;
                if (hospital_address && hospital_address !== null) {
                  return hospital_address
                } else {
                  return "-"
                }
              }
            },
            {
              title: "No Telepon",
              field: "phone",
            },
            // {
            //   title: "Email",
            //   field: "email",
            // },
            {
              title: "Jam Praktik",
              render: ({ member_practice_place_days, id }) => {
                return (
                  <NumberInfo
                    data={
                      member_practice_place_days.length !== 0
                        ? day[member_practice_place_days[0].day_code] +
                        " (" +
                        member_practice_place_days[0].start_time +
                        " - " +
                        member_practice_place_days[0].end_time +
                        ")"
                        : "-"
                    }
                    numberInfo={member_practice_place_days.length}
                    arrayData={member_practice_place_days.map(
                      (item) =>
                        day[item.day_code] +
                        " (" +
                        item.start_time +
                        " - " +
                        item.end_time +
                        ")"
                    )}
                    onClick={() => this.handleOpenModalEdit(id)}
                    stylesProps="dataA"
                  />
                );
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Baru"
                        : status === 1
                          ? "Terverifikasi"
                          : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: (rowData) => {
                let menu;
                if (getUser().role_codes[0] === "member") {
                  menu = (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                        Detail
                      </MenuItem>
                      {/* <MenuItem
                        onClick={() => this.handleOpenModalDelete(rowData.id)}
                      >
                        Delete
                      </MenuItem> */}
                    </DetailButtonComponent>
                  );
                } else {
                  menu = (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                        Detail
                      </MenuItem>
                    </DetailButtonComponent>
                  );
                }
                return menu;
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                user_id: this.state.user_id,
                status: this.state.status,
                id: this.state.id
              };
              listPractice(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listPractice }) {
  return {
    listPractice_data: getListPractice(listPractice),
    listPractice_error: getPracticeError(listPractice),
    listPractice_pending: getPracticePending(listPractice),
  };
}

export default connect(mapStateToProps, {
  listPractice,
  detailPractice,
  listHospital,
})(withRouter(Practice));
