import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_MASTER_BENEFIT_PENDING = "LIST_MASTER_BENEFIT_PENDING";
export const LIST_MASTER_BENEFIT_SUCCESS = "LIST_MASTER_BENEFIT_SUCCESS";
export const LIST_MASTER_BENEFIT_ERROR = "LIST_MASTER_BENEFIT_ERROR";

export const DETAIL_MASTER_BENEFIT_PENDING = "DETAIL_MASTER_BENEFIT_PENDING";
export const DETAIL_MASTER_BENEFIT_SUCCESS = "DETAIL_MASTER_BENEFIT_SUCCESS";
export const DETAIL_MASTER_BENEFIT_ERROR = "DETAIL_MASTER_BENEFIT_ERROR";

export const EDIT_MASTER_BENEFIT_PENDING = "EDIT_MASTER_BENEFIT_PENDING";
export const EDIT_MASTER_BENEFIT_SUCCESS = "EDIT_MASTER_BENEFIT_SUCCESS";
export const EDIT_MASTER_BENEFIT_ERROR = "EDIT_MASTER_BENEFIT_ERROR";

export const ADD_MASTER_BENEFIT_PENDING = "ADD_MASTER_BENEFIT_PENDING";
export const ADD_MASTER_BENEFIT_SUCCESS = "ADD_MASTER_BENEFIT_SUCCESS";
export const ADD_MASTER_BENEFIT_ERROR = "ADD_MASTER_BENEFIT_ERROR";

export const DELETE_MASTER_BENEFIT_PENDING = "DELETE_MASTER_BENEFIT_PENDING";
export const DELETE_MASTER_BENEFIT_SUCCESS = "DELETE_MASTER_BENEFIT_SUCCESS";
export const DELETE_MASTER_BENEFIT_ERROR = "DELETE_MASTER_BENEFIT_ERROR";

export const SET_SELECTED_MASTER_BENEFIT = "SET_SELECTED_MASTER_BENEFIT";

const LIST_MASTER_BENEFIT_URL = "master_data/benefit/find";
const EDIT_MASTER_BENEFIT_URL = "master_data/benefit/edit";
const ADD_MASTER_BENEFIT_URL = "master_data/benefit/add";
const DELETE_MASTER_BENEFIT_URL = "master_data/benefit/delete";

export function listMasterBenefit(param = {}, resolve, detail = false) {
    return (dispatch) => {
        dispatch(actionPending(LIST_MASTER_BENEFIT_PENDING));
        API.post(LIST_MASTER_BENEFIT_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_MASTER_BENEFIT_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    if (detail === true) {
                        let detailBenefit = data.data[0].journal_participants.map(
                            (item, i) => ({
                                ...item,
                                no: i + 1 + (param.page - 1) * 10,
                            })
                        );
                        resolve({
                            data: detailBenefit,
                            page: param.page - 1,
                            totalCount: detailBenefit.length,
                        });
                    } else {
                        resolve({
                            data: result,
                            page: param.page - 1,
                            totalCount: records_total,
                        });
                    }
                }
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_MASTER_BENEFIT_ERROR, error));
                toastError(error, "Gagal mendapatkan list BENEFIT, ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function detailMasterBenefit(id = null, callback) {
    const param = {
        id: id,
    };
    return (dispatch) => {
        dispatch(actionPending(DETAIL_MASTER_BENEFIT_PENDING));
        API.post(LIST_MASTER_BENEFIT_URL, param, getHeader())
            .then((res) => {
                dispatch(actionSuccess(DETAIL_MASTER_BENEFIT_SUCCESS, res.data.data));
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DETAIL_MASTER_BENEFIT_ERROR, error));
                toastError(error, "Gagal mendapatkan list BENEFIT, ");
            });
    };
}

export function setSelectedMasterBenefit(value) {
    return {
        type: SET_SELECTED_MASTER_BENEFIT,
        value: value,
    };
}

export function editMasterBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_MASTER_BENEFIT_PENDING));
        API.post(EDIT_MASTER_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_MASTER_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah data benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_MASTER_BENEFIT_ERROR, error));
                toastError(error, "Gagal mengubah data benefit, ");
            });
    };
}

export function addMasterBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_MASTER_BENEFIT_PENDING));
        API.post(ADD_MASTER_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_MASTER_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_MASTER_BENEFIT_ERROR, error));
                toastError(error, "Gagal menambahkan benefit, ");
            });
    };
}

export function deleteMasterBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_MASTER_BENEFIT_PENDING));
        API.post(DELETE_MASTER_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_MASTER_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_MASTER_BENEFIT_ERROR, error));
                toastError(error, "Gagal menghapus benefit, ");
            });
    };
}