import {
    LIST_KOPERASI_PENDING,
    LIST_KOPERASI_SUCCESS,
    LIST_KOPERASI_ERROR,
    DETAIL_KOPERASI_PENDING,
    DETAIL_KOPERASI_SUCCESS,
    DETAIL_KOPERASI_ERROR,
    ADD_KOPERASI_PENDING,
    ADD_KOPERASI_SUCCESS,
    ADD_KOPERASI_ERROR,
    SET_SELECTED_KOPERASI,
    APPROVAL_KOPERASI_PENDING,
    APPROVAL_KOPERASI_SUCCESS,
    APPROVAL_KOPERASI_ERROR,
    FIND_SHU_KOPERASI_PENDING,
    FIND_SHU_KOPERASI_SUCCESS,
    FIND_SHU_KOPERASI_ERROR,
    UPDATE_SHU_KOPERASI_PENDING,
    UPDATE_SHU_KOPERASI_SUCCESS,
    UPDATE_SHU_KOPERASI_ERROR,
    MAIN_DEPOSIT_PENDING,
    MAIN_DEPOSIT_SUCCESS,
    MAIN_DEPOSIT_ERROR,
} from "../../actions/koperasi/koperasi_action";

const initialState = {
    pending: false,
    error: null,
};

export function listKoperasi(
    state = { ...initialState, list_koperasi: null },
    action
) {
    switch (action.type) {
        case LIST_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
                list_koperasi: action.data,
            };
        case LIST_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailKoperasi(
    state = { ...initialState, detail_koperasi: null },
    action
) {
    switch (action.type) {
        case DETAIL_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_koperasi: action.data,
            };
        case DETAIL_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addKoperasi(state = initialState, action) {
    switch (action.type) {
        case ADD_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedKoperasi(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_KOPERASI:
            return action.value;
        default:
            return state;
    }
}

export function approvalKoperasi(
    state = { ...initialState, approvalKoperasi: null },
    action
) {
    switch (action.type) {
        case APPROVAL_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVAL_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
                approvalKoperasi: action.data
            };
        case APPROVAL_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function findSHUKoperasi(
    state = { ...initialState, findSHUKoperasi: null },
    action
) {
    switch (action.type) {
        case FIND_SHU_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case FIND_SHU_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
                findSHUKoperasi: action.data
            };
        case FIND_SHU_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function updateSHUKoperasi(
    state = { ...initialState, updateSHUKoperasi: null },
    action
) {
    switch (action.type) {
        case UPDATE_SHU_KOPERASI_PENDING:
            return {
                ...state,
                pending: true,
            };
        case UPDATE_SHU_KOPERASI_SUCCESS:
            return {
                ...state,
                pending: false,
                updateSHUKoperasi: action.data
            };
        case UPDATE_SHU_KOPERASI_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function mainDeposit(
    state = { ...initialState, mainDeposit: null },
    action
) {
    switch (action.type) {
        case MAIN_DEPOSIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case MAIN_DEPOSIT_SUCCESS:
            return {
                ...state,
                pending: false,
                mainDeposit: action.data
            };
        case MAIN_DEPOSIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListKoperasi = (state) => state.list_koperasi;
export const getListKoperasiPending = (state) => state.pending;
export const getListKoperasiError = (state) => state.error;
export const getDetailKoperasi = (state) => state.detail_koperasi;
export const getSelectedSKP = (state) => state.selectedSKP;
export const getKoperasiApprovalPending = (state) => state.pending;
export const getKoperasiApproval = (state) => state.approvalKoperasi;
export const getKoperasiApprovalError = (state) => state.error;
export const getKoperasiAddPending = (state) => state.pending;
export const getKoperasiAdd = (state) => state.approvalKoperasi;
export const getKoperasiAddError = (state) => state.error;
export const getSHUKoperasiPending = (state) => state.pending;
export const getSHUKoperasi = (state) => state.findSHUKoperasi;
export const getSHUKoperasiError = (state) => state.error;
export const getUpdateSHUPending = (state) => state.pending;
export const getUpdateSHU = (state) => state.updateSHUKoperasi;
export const getUpdateSHUError = (state) => state.error;
export const getMainDepositPending = (state) => state.pending;
export const getMainDeposit = (state) => state.mainDeposit;
export const getMainDepositError = (state) => state.error;