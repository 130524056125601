import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_PRACTICE_PENDING = "LIST_PRACTICE_PENDING";
export const LIST_PRACTICE_SUCCESS = "LIST_PRACTICE_SUCCESS";
export const LIST_PRACTICE_ERROR = "LIST_PRACTICE_ERROR";

export const DETAIL_PRACTICE_PENDING = "DETAIL_PRACTICE_PENDING";
export const DETAIL_PRACTICE_SUCCESS = "DETAIL_PRACTICE_SUCCESS";
export const DETAIL_PRACTICE_ERROR = "DETAIL_PRACTICE_ERROR";

export const ADD_PRACTICE_PENDING = "ADD_PRACTICE_PENDING";
export const ADD_PRACTICE_SUCCESS = "ADD_PRACTICE_SUCCESS";
export const ADD_PRACTICE_ERROR = "ADD_PRACTICE_ERROR";

export const EDIT_PRACTICE_PENDING = "EDIT_PRACTICE_PENDING";
export const EDIT_PRACTICE_SUCCESS = "EDIT_PRACTICE_SUCCESS";
export const EDIT_PRACTICE_ERROR = "EDIT_PRACTICE_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE";

export const CHANGE_STATUS_PRACTICE_PENDING = "CHANGE_STATUS_PRACTICE_PENDING";
export const CHANGE_STATUS_PRACTICE_SUCCESS = "CHANGE_STATUS_PRACTICE_SUCCESS";
export const CHANGE_STATUS_PRACTICE_ERROR = "CHANGE_STATUS_PRACTICE_ERROR";

const LIST_PRACTICE_URL = "member/practice_place/find";
const EDIT_PRACTICE_URL = "member/practice_place/edit";
const ADD_PRACTICE_URL = "member/practice_place/add";
const UPLOAD_PRACTICE_URL = "image/upload/member_practice_place";
const CHANGE_STATUS_PRACTICE_URL = "member/practice_place/approve";

export function listPractice(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_PRACTICE_PENDING));
    API.post(LIST_PRACTICE_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_PRACTICE_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_PRACTICE_ERROR, error));
        toastError(error, "Gagal mendapatkan list tempat praktik, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addPractice({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_PRACTICE_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_PRACTICE_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        return API.post(ADD_PRACTICE_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_PRACTICE_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan tempat praktik");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_PRACTICE_ERROR, error));
        toastError(error, "Gagal menambahkan tempat praktik, ");
      });
  };
}

export function detailPractice(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_PRACTICE_PENDING));
    API.post(LIST_PRACTICE_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_PRACTICE_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_PRACTICE_ERROR, error));
        toastError(error, "Gagal mendapatkan list tempat praktik, ");
      });
  };
}

export function editPractice({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_PRACTICE_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_PRACTICE_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // return null;
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_PRACTICE_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_PRACTICE_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah tempat praktik");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_PRACTICE_ERROR, error));
        toastError(error, "Gagal mengubah tempat praktik, ");
      });
  };
}

export function changeStatusPractice(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(CHANGE_STATUS_PRACTICE_PENDING));
    API.post(CHANGE_STATUS_PRACTICE_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(CHANGE_STATUS_PRACTICE_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah Status Jadwal Praktik");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(CHANGE_STATUS_PRACTICE_ERROR, error));
        toastError(error, "Gagal mengubah Status Jadwal Praktik, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}
