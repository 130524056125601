import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import moment from "moment";

const styles = StyleSheet.create({
  container: {
    width: 160,
    marginRight: 10,
    marginTop: 15
  },
});
class HeaderEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      event_date_start: null,
      event_date_end: null,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleStartDateChange(e) {
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.props.handleRefresh({ event_date_start: date });
      this.setState({ event_date_start: date });
    } else {
      this.props.handleRefresh({ event_date_start: "" });
      this.setState({ event_date_start: null });
    }
  }

  handleEndDateChange(e) {
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.props.handleRefresh({ event_date_end: date });
      this.setState({ event_date_end: date });
    } else {
      this.props.handleRefresh({ event_date_end: "" });
      this.setState({ event_date_end: null });
    }
  }

  // handleSearchChange = _.debounce((e) => {
  // }, 1000);

  render() {
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="flex-end"
      >
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleSearchChange}
            value={this.state.tes}
            placeholder="Cari Judul Event"
            startIcon={SearchIcon}
          />
        </div>
        <div style={{ marginLeft: 10, width: 150 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
            Tanggal Awal
            <KeyboardDatePicker
              margin="dense"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={this.state.event_date_start}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={this.handleStartDateChange}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ marginLeft: 10, width: 150 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
            Tanggal Akhir
            <KeyboardDatePicker
              margin="dense"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={this.state.event_date_end}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={this.handleEndDateChange}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(HeaderEvent);
