import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailStr,
  editStr,
  setIsEditFile,
} from "../../actions/p2kb/str_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getStrPending,
  getDetailStr,
  getStrError,
  getStrIsEditFile,
} from "../../reducers/p2kb/str_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  renderInput,
  renderDate,
  renderFile,
} from "../../components/form/FormComponent";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import moment from "moment";
import localization from "moment/locale/id";

class StrDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  detailRecord(id) {
    this.props.history.push(`/admin/str/detail/${id}`);
  }

  componentDidMount() {
    const { detailStr } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailStr(id);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editStr_isEditFile, setIsEditFile } = this.props;
    if (editStr_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editStr_isEditFile);
  }

  handleSubmit(values) {
    const { detailStr_data, editStr, detailStr } = this.props;
    const { str_no, start_date, end_date, berkas } = values;

    let formData = new FormData();
    formData.append("id", detailStr_data[0].id);
    formData.append("str_no", str_no);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);

    if (berkas) {
      formData.append("file", berkas);
    }
    const callback = () => {
      detailStr();
      this.handleTriggerFormEdit();
    };
    editStr(formData, callback);
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailStr_data,
      editStr_pending,
      editStr_isEditFile,
      handleSubmit,
      formValue
    } = this.props;
    const { isEdit } = this.state;
    let name, filename, status, reject_notes, reject_filename;
    if (detailStr_data && detailStr_data.length !== 0) {
      name = detailStr_data[0].user_data.name;
      filename = detailStr_data[0].filename;
      status = detailStr_data[0].status;
      reject_notes = detailStr_data[0].reject_notes;
      reject_filename = detailStr_data[0].reject_filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data STR Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_str"
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi STR" />
                {status === 2 &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editStr_pending}
                  />
                }
              </Row>
              <FormContainer label="No STR">
                <Field
                  isDetail={!isEdit}
                  name="str_no"
                  placeholder="Masukkan no STR"
                  component={renderInput}
                />
              </FormContainer>
              {!isEdit ?
                <DetailContentComponent
                  label="Tanggal Penetapan"
                  value={formValue.start_date ? moment(formValue.start_date).locale("id", localization).format("DD MMMM YYYY") : null}
                />
                :
                <FormContainer label="Tanggal Penetapan">
                  <Field
                    isDetail={!isEdit}
                    name="start_date"
                    placeholder="Masukkan tanggal penetapan"
                    component={renderDate}
                  />
                </FormContainer>
              }
              {!isEdit ?
                <DetailContentComponent
                  label="Tanggal Berakhir"
                  value={formValue.end_date ? moment(formValue.end_date).locale("id", localization).format("DD MMMM YYYY") : null}
                />
                :
                <FormContainer label="Tanggal Berakhir">
                  <Field
                    isDetail={!isEdit}
                    name="end_date"
                    placeholder="Masukkan tanggal berakhir"
                    component={renderDate}
                  />
                </FormContainer>
              }
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editStr_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_str"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : status === 2
                        ? "Ditolak"
                        : "-"
                }
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function validate(values, props) {
  const { editStr_isEditFile } = props;
  const { institution, str_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!str_type) {
    errors.str_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editStr_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

StrDetail = reduxForm({
  // a unique name for the form
  form: "StrEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(StrDetail);

function mapStateToProps(state) {
  const { detailStr, editStr } = state;
  const selector = formValueSelector("StrEdit")
  const formValue = selector(state, "start_date", "end_date");
  let initialValues = {};
  const detailStr_data = getDetailStr(detailStr);
  if (detailStr_data && detailStr_data.length !== 0) {
    initialValues = {
      str_no: detailStr_data[0].str_no,
      start_date: detailStr_data[0].start_date,
      end_date: detailStr_data[0].end_date,
    };
  }

  return {
    detailStr_data: getDetailStr(detailStr),
    detailStr_error: getStrError(detailStr),
    detailStr_pending: getStrPending(detailStr),
    editStr_error: getStrError(editStr),
    editStr_pending: getStrPending(editStr),
    editStr_isEditFile: getStrIsEditFile(editStr),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailStr,
  editStr,
  setIsEditFile,
})(withRouter(StrDetail));
