import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { listKoperasi, findSHU } from "../../actions/koperasi/koperasi_action";
import { getListKoperasiPending, getListKoperasi, getListKoperasiError, getSHUKoperasiPending, getSHUKoperasi, getSHUKoperasiError } from "../../reducers/koperasi/koperasi_reducer";
import PaginationComponent from '../../components/table/PaginationComponent';
import { Paper } from "@material-ui/core";
import HeaderIuranMember from "./IuranMemberHeader";
import moment from "moment";
import localization from "moment/locale/id";

class IuranMember extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.tableRef2 = React.createRef();
        this.state = {
            search: "",
            status: "",
            year: null,
            active_tab: 1

        };
        this.handleRefresh = this.handleRefresh.bind(this);
        this.active_tab = this.active_tab.bind(this);

    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
        this.tableRef2.current && this.tableRef2.current.onQueryChange();

    }
    active_tab(tab) {
        this.setState({ active_tab: tab.tab_active })
    }

    render() {
        const { listKoperasi, listKoperasi_data, match, findSHU, findSHU_data } = this.props;
        let length, records_total, length2, records_total2 = "";
        if (listKoperasi_data) {
            length = listKoperasi_data.data.length;
            records_total = listKoperasi_data.records_total;
        }
        if (findSHU_data) {
            length2 = findSHU_data.data.length;
            records_total2 = findSHU_data.records_total;
        }
        return (
            <ContentContainer>
                <HeaderIuranMember
                    handleRefresh={this.handleRefresh}
                    id={match.params.id}
                    active_tab={this.active_tab}
                />
                <MaterialTable tabId="1"
                    tableRef={this.tableRef}
                    columns={[
                        {
                            title: "ID Transaksi",
                            field: "transaction_number",
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            title: "Tanggal Pembayaran",
                            render: (data) => {
                                return moment(data.transaction_date).locale("id", localization).format("DD MMMM YYYY");
                            }
                        },
                        {
                            title: "Dana Aktual",
                            render: (data) => {
                                let total;
                                if (data.amount && data.amount !== null) {
                                    total = data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                        {
                            title: "Total Simpanan",
                            render: (data) => {
                                let total;
                                if (data.amount_running && data.amount_running !== null) {
                                    total = data.amount_running.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                status: this.state.status,
                                year: this.state.year,
                                user_id: this.props.match?.params?.id,
                            };
                            listKoperasi(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={length}
                                records_total={records_total}
                                {...props}
                            />
                        ),
                    }}
                    style={this.state.active_tab == 1 ? { display: "block" } : { display: "none" }}
                />
                <MaterialTable tabId="2"
                    tableRef={this.tableRef2}
                    columns={[
                        {
                            title: "ID Transaksi",
                            field: "transaction_number",
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            title: "Tanggal Pembayaran",
                            render: (data) => {
                                return moment(data.transaction_date).locale("id", localization).format("DD MMMM YYYY");
                            }
                        },
                        {
                            title: "Dana SHU",
                            render: (data) => {
                                let total;
                                if (data.amount && data.amount !== null) {
                                    total = data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                        {
                            title: "Total SHU",
                            render: (data) => {
                                let total;
                                if (data.amount_running && data.amount_running !== null) {
                                    total = data.amount_running.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                status: this.state.status,
                                year: this.state.year,
                            };
                            findSHU(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={length2}
                                records_total={records_total2}
                                {...props}
                            />
                        ),
                    }}
                    style={this.state.active_tab == 2 ? { display: "block" } : { display: "none" }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const { listKoperasi, selectedMembership, findSHU } = state;
    return {
        listKoperasi_pending: getListKoperasiPending(listKoperasi),
        listKoperasi_data: getListKoperasi(listKoperasi),
        listKoperasi_error: getListKoperasiError(listKoperasi),
        findSHU_pending: getSHUKoperasiPending(findSHU),
        findSHU_data: getSHUKoperasi(findSHU),
        findSHU_error: getSHUKoperasiError(findSHU),
        selectedMembership: selectedMembership
    };
}

export default connect(mapStateToProps, { listKoperasi, findSHU })(withRouter(IuranMember));