import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { p2kbList, deleteP2kb } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
  getP2kbDeletePending,
  getP2kbDelete,
  getP2kbDeleteError,
} from "../../reducers/p2kb/p2kb_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderP2kb from "./Header_p2kb";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import HeaderListP2kbComponent from "../../components/p2kb/HeaderAddDetailP2kbComponent";
import { memberList } from "../../actions/member/member_action";
import { listActivity } from "../../actions/master-data/activity_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import moment from "moment";
import localization from "moment/locale/id";
import ModalAddSKP from "./Modal_add_skp";

import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../reducers/master-data/activity_reducer";

import { getUser } from "../../helpers/user";

class ListP2kb extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      modalEditP2kbShow: false,
      modalAddSKPShow: false,
      modalHapusP2kbShow: false,
      p2kb_id: "",
      status: "",
      activity_type: "",
      isLoad: true,
      id: null,
      start_date: localStorage.getItem('start_date') ? moment(localStorage.getItem('start_date')).format('YYYY-MM-DD') : '',
      end_date: localStorage.getItem('end_date') ? moment(localStorage.getItem('end_date')).format('YYYY-MM-DD') : '',
      str_active: null,
      submit_skp: 0,
      realm_activity_id: JSON.parse(localStorage.getItem("skp_add_data_temp_first")).realm_activity.value
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleDeleteSkp = this.handleDeleteSkp.bind(this);
  }

  handleDeleteSkp(data) {
    const param = {
      id: data.id
    }
    const callback = () => {
      this.handleRefresh()
    };
    this.props.deleteP2kb(param, callback);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailP2kb(data) {
    let realm_activity = null;
    let values = null;
    realm_activity = JSON.parse(localStorage.getItem('skp_add_data_temp_first'))
    values = {
      activity: {
        value: data.activity_data.id
      }
    };
    const tindakanOperasi = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dc",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dd",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363de",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363df",
    ];
    const pelayananMedis = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dg",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dh",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363di",
    ];
    const pertemuanAuditor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dl"];
    const membacaJurnal = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dn"];
    const penelusuranInformasi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363do"];
    const acaraIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dj"];
    const seminar = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dq",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr",
    ];
    const kajianIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dk"];
    const edukasiPasien = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dm"];
    const menghadiriKongres = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ds"];
    const menulisIlmiah = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dw",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dx",
    ];
    const menulisBuku = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dy"];
    const membimbingMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eg"];
    const mengajarMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ee"];
    const mengikutiPelatihan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dt"];
    const kepengurusanProfesi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dv"];
    const pengabdianMasyarakat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363du"];
    const penelitianDipublikasikan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ec"];
    const penyeliaSupervisor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ed"];
    const wawancaraKajianElektronik = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dz"];
    const menciptakanAlat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eb"];
    const praktekMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ef"];
    const membuatVideo = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ea"];
    const mengujiMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eh"];

    if (tindakanOperasi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/tindakan-operasi/${data.activity_id}`);
    }
    if (pelayananMedis.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pelayanan-medis/${data.activity_id}`);
    }
    if (pertemuanAuditor.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pertemuan-auditor/${data.activity_id}`);
    }
    if (membacaJurnal.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/membaca-jurnal/${data.activity_id}`);
    }
    if (penelusuranInformasi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/penelusuran-informasi/${data.activity_id}`);
    }
    if (acaraIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/acara-ilmiah/${data.activity_id}`);
    }
    if (seminar.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/seminar/${data.activity_id}`);
    }
    if (kepengurusanProfesi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/kepengurusan-profesi/${data.activity_id}`);
    }
    if (penelitianDipublikasikan.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        `/p2kb/details/penelitian-dipublikasikan/${data.activity_id}`
      );
    }
    if (mengikutiPelatihan.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pelatihan/${data.activity_id}`);
    }
    if (penyeliaSupervisor.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/penyelia-supervisor/${data.activity_id}`);
    }
    if (kajianIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/kajian-ilmiah/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menghadiriKongres.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menghadiri-kongres/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (edukasiPasien.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/edukasi-pasien/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menulisIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menulis-ilmiah/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menulisBuku.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menulis-buku/" + data.activity_data.id + "/" + data.id
      );
    }
    if (membimbingMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/membimbing-mahasiswa/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (mengajarMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/mengajar-mahasiswa/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (pengabdianMasyarakat.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pengabdian-masyarakat/${data.activity_id}`);
    }
    if (wawancaraKajianElektronik.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/kajian-elektronik/${data.activity_id}`);
    }
    if (menciptakanAlat.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/menciptakan-alat/${data.activity_id}`);
    }
    if (praktekMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/praktek-mahasiswa/${data.activity_id}`);
    }
    if (membuatVideo.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/membuat-video/${data.activity_id}`);
    }
    if (mengujiMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/menguji-mahasiswa/${data.activity_id}`);
    }
  }

  componentDidMount() {
    localStorage.removeItem("tempSKPData");
    localStorage.removeItem("skp_edit_detail_skp_seminar");
    localStorage.removeItem("skp_edit_detail_skp_tindakan_operasi");
    localStorage.setItem('start_date',moment(this.state.start_date).format('YYYY-MM-DD'));
    localStorage.setItem('end_date',moment(this.state.end_date).format('YYYY-MM-DD'));

    let param = {
      id: getUser().id,
    };
    new Promise((callback) => {
      setTimeout(() => this.setState({ isLoad: false }), 2000);
      this.props.memberList(param, callback);
    });
    let param2 = {
      isFirstLayer: false,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      realm_activity_id: localStorage.getItem('realm_activity_id'),
    };
    new Promise((callback) => {
      this.props.p2kbList(param2, callback);
    });

    const url = window.location.href;
    const ID = url.substring(url.lastIndexOf('/') + 1);
    const isID = url.substring(url.lastIndexOf('/') + 1) !== "skp";
    if (isID && this.state.id === null) {
      this.setState({ id: ID })
    }
  }

  render() {
    const { modalAddSKPShow } = this.state;
    const { p2kbList, p2kbList_data, memberList_data, listActivity, isFirstLayer } = this.props;
    let data = [];
    let records_total = "";
    let length = "";
    let user = null;
    let total_skp_header = 0;
    let total_skp = null;
    let ranah = localStorage.getItem('nama_ranah');
    let member_list_data = null;
    let str_active = null;
    let submit_skp = 1;
    let recentSkp = [];
    if (p2kbList_data) {
      data = p2kbList_data;
      records_total = data.records_total;
      length = data.data.length;
      if (memberList_data) {
        member_list_data = memberList_data.data
          ? memberList_data.data[0]
          : memberList_data;
        total_skp = member_list_data?.member_profile.total_skp;
      }
      p2kbList_data.data.forEach((item, index) => {
        total_skp_header += item.total_skp_grouped
        recentSkp.push(item.activity_id)
      })
      localStorage.setItem('recentSkp', recentSkp.join())
      localStorage.setItem('recentSkpData', JSON.stringify(p2kbList_data.data))
      if (p2kbList_data.str_active) {
        str_active = p2kbList_data.str_active
      }
      if (p2kbList_data.submitted_data) {
        submit_skp = p2kbList_data.submitted_data.total_submitted.total_skp_not_sumbitted
      }
    }



    return (
      <ContentContainer>
        <HeaderListP2kbComponent
          total_skp_header={total_skp_header ? total_skp_header.toFixed(2) : '-'}
          ranah={ranah ? ranah : "-"}
        />
        <HeaderP2kb isFirstLayer={false} handleRefresh={this.handleRefresh} str_active={str_active} submit_skp={submit_skp} />
        <ModalAddSKP
          show={modalAddSKPShow}
          recentSkp={recentSkp}
          isFirstLayer={isFirstLayer}
          onHide={() => this.setState({ modalAddSKPShow: false })}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="P2kb"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            { title: "Nama Kegiatan", field: "activity_data.name" },
            { title: "Total SKP", 
              // field: "total_skp_grouped"
              render: (data) => {
                let total_skp_grouped_fixed = data.total_skp_grouped.toFixed(2);
                return total_skp_grouped_fixed
              }
            },
            {
              title: "",
              render: (data) => {
                if (data.status === 0 && data.submit_status === 0) {
                  return (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleDetailP2kb(data)}>
                        Detail
                    </MenuItem>
                      <MenuItem onClick={() => this.handleDeleteSkp(data)}>
                        Hapus
                    </MenuItem>
                    </DetailButtonComponent>
                  )
                }
                else {
                  return (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleDetailP2kb(data)}>
                        Detail
                    </MenuItem>
                    </DetailButtonComponent>
                  )
                }
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                status: this.state.status,
                activity_type: this.state.activity_type,
                isFirstLayer: false,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                realm_activity_id: this.state.realm_activity_id,
              };
              p2kbList(param, resolve, reject);
            })
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                // vertical="center"
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    // backgroundColor: "red",
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p>
                    {" "}
                    Menampilkan {length} dari {records_total} data{" "}
                  </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}>
                      {/* {props.labelDisplayedRows(row)} */}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ p2kbList, deleteP2kb, memberList }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    p2kbDelete_pending: getP2kbDeletePending(deleteP2kb),
    p2kbDelete_data: getP2kbDelete(deleteP2kb),
    p2kbDelete_error: getP2kbDeleteError(deleteP2kb),
  };
}

export default connect(mapStateToProps, { p2kbList, deleteP2kb, memberList, listActivity })(
  withRouter(ListP2kb)
);
