import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CountUp from "react-countup";
export default (props) => {
  const { member_total, card_label, img_url } = props;
  return (
    <Row
      className={css(styles.cardMemberWrapper)}
      style={{ width: "100%", height : "100%" }}
      horizontal="space-between"
    >
      <img
        style={{ height: 70, width: 70, marginRight : 10 }}
        src={require("../../assets/" + img_url)}
        alt="Total Member"
      ></img>
      <Column className={css(styles.detailColumn)}>
        <label className={css(styles.memberTotal)}>
          <CountUp end={parseInt(member_total)} separator="." />
        </label>
        <label className={css(styles.cardLabel)}>{card_label}</label>
      </Column>
    </Row>
  );
};

const styles = StyleSheet.create({
  memberTotal: {
    color: "#495057",
    fontSize: 30,
    fontWeight: "bold",
    margin: "5px 0px 15px 0px",
  },
  cardLabel: {
    color: "#BCBCBC",
    marginBottom: 0,
  },
  detailColumn: {
    textAlign: "right",
  },
  cardMemberWrapper: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 5,
    padding: 15,
  },
});
