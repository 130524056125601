import React, { Component } from "react";
import { Row } from 'simple-flexbox'
import { connect } from "react-redux";
import CardSupport from "../../components/dasboard/CardSupport";
import CardDanaEstimasi from "../../components/dasboard/CardDanaEstimasi";
import CardDanaPusat from "../../components/dasboard/CardDanaPusat";
import CardDanaMasuk from "../../components/dasboard/CardDanaMasuk";
import CardMemberPusat from "../../components/dasboard/CardMemberPusat";
import CardKoperasi from "../../components/dasboard/CardKoperasiPusat";
import CardMaps from "../../components/dasboard/CardMapsKolegium";
import CardPPDS from "../../components/dasboard/CardPPDS";
import { listBranch } from "../../actions/master-data/branch_action";
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer'
class DashboardAdminPusat extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }



    componentDidMount() {
        this.props.listBranch({ length: 999 })

    }
    render() {
        return (
            <>
                <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '75%', }}>
                        <div style={{ marginBottom: 20 }}>
                            <CardMaps />
                        </div>
                        <div>
                            <CardKoperasi />
                        </div>
                    </div>
                    <div style={{ padding: "0 10px", width: '25%' }}>
                        <div style={{ marginBottom: 40 }}>
                            <CardMemberPusat />
                        </div>
                        <CardSupport />
                    </div>
                </Row>
                <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '75%', }}>
                    </div>
                    <div style={{ padding: "0 10px", width: '25%' }}>

                    </div>
                </Row>

                <Row style={{ marginBottom: 20 }} alignItems='stretch'>
                    <div style={{ padding: "0 10px", width: '33.33%', }}>
                        <CardDanaEstimasi />
                    </div>
                    <div style={{ padding: "0 10px", width: '33.33%' }}>
                        <CardDanaMasuk />
                    </div>
                    <div style={{ padding: "0 10px", width: '33.33%' }}>
                        <CardDanaPusat />
                    </div>
                </Row>
            </>
        )
    }
}

function mapStateToProps({
    listCandidate,
    listGraduated,
    listOrthopaedi }) {
    return {
        listCandidate_data: getDashboardData(listCandidate),
        listGraduated_data: getDashboardData(listGraduated),
        listOrthopaedi_data: getDashboardData(listOrthopaedi),
    };
}

export default connect(mapStateToProps, {
    listBranch
})(DashboardAdminPusat);
