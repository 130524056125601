import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import {
  renderInput,
  renderSelect,
} from "../../components/form/FormComponent";
import {
  universityList,
  addUniversity,
} from "../../actions/master-data/university_action";
import {
  getUniversityListPending,
  getUniversityList,
  getUniversityListError,
  getUniversityAdd,
  getUniversityAddPending,
} from "../../reducers/master-data/university_reducer";

class ModalAddUniversitas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      university_code: "",
      university_name: "",
      university_abbreviation: "",
      university_city: "",
    };
    this.handleChangeUCode = this.handleChangeUCode.bind(this);
    this.handleChangeUName = this.handleChangeUName.bind(this);
    this.handleChangeUAbbreviation = this.handleChangeUAbbreviation.bind(this);
    this.handleChangeUCity = this.handleChangeUCity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeUCode(e) {
    const errors = {};
    if (e !== null) {
      var regexWrong = /\W|[b-z]|[B-Z]/;
      var regexAdaptation = /^[\d+][^b-z][^B-Z]$/;
      var regexOrigin = /^\d+$/;

      if (regexWrong.test(e.target.value)) {
        this.setState({ university_code: "" });
        errors.universityCode = "Kode Tidak Valid";
      } else if (regexAdaptation.test(e.target.value)) {
        this.setState({ university_code: e.target.value });
      } else if (regexOrigin.test(e.target.value)) {
        this.setState({ university_code: e.target.value });
      } else {
        this.setState({ university_code: "" });
      }
    } else {
      this.setState({ university_code: "" });
    }
  }

  handleChangeUName(e) {
    if (e !== null) {
      this.setState({ university_name: e.target.value });
    } else {
      this.setState({ university_name: "" });
    }
  }

  handleChangeUAbbreviation(e) {
    if (e !== null) {
      this.setState({ university_abbreviation: e.target.value });
    } else {
      this.setState({ university_abbreviation: "" });
    }
  }

  handleChangeUCity(e) {
    if (e !== null) {
      this.setState({ university_city: e.value });
    } else {
      this.setState({ university_city: "" });
    }
  }

  handleSubmit(values) {
    const { addUniversity, onHide } = this.props;
    const param_addUniversitas = {
      code: values.universityCode,
      name: values.universityName,
      abbreviation: values.universityAbbreviation,
      city_id: values.universityCity.value,
    };
    const param_listUniversitas = {
      page: 1,
      length: 1,
      search_text: "",
    };

    const callback = () => {
      onHide();
      this.props.universityList(param_listUniversitas);
    };
    addUniversity(param_addUniversitas, callback);
  }

  render() {
    const { handleSubmit, addUniversitasPending } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        cityOption={this.props.listCityOption}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Universitas</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>

              <LabelInputVerticalComponent
                label="Nama Universitas"
                marginTop="20px"
              >
                <Field
                  isClearable={false}
                  placeholder="Masukkan nama universitas"
                  onChange={this.handleChangeUName}
                  name="universityName"
                led
                  component={renderInput}
                />
              </LabelInputVerticalComponent>
              <LabelInputVerticalComponent
                label="Singkatan Universitas"
                marginTop="20px"
              >
                <Field
                  isClearable={false}
                  placeholder="Masukkan singkatan universitas"
                  onChange={this.handleChangeUAbbreviation}
                  name="universityAbbreviation"
                  component={renderInput}
                />
              </LabelInputVerticalComponent>
              <LabelInputVerticalComponent label="Kota" marginTop="20px">
                <Field
                  isClearable={false}
                  placeholder="Masukkan asal kota universitas"
                  options={this.props.listCityOption}
                  onChange={this.handleChangeUCity}
                  name="universityCity"
                  component={renderSelect}
                />
              </LabelInputVerticalComponent>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addUniversitasPending}
                className={css(styles.approveButton)}
                startIcon={<CheckIcon />}
                // onClick={() => this.addUniversitas()}
              >
                SIMPAN
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function validate(values) {
  const {
    universityCode,
    universityName,
    universityAbbreviation,
    universityCity,
  } = values;
  const errors = {};
  const success = {};

  if (!universityName) {
    errors.universityName = "Nama Universitas Wajib Disertakan";
  }
  if (!universityAbbreviation) {
    errors.universityAbbreviation = "ingkatan Universitas Wajib Disertakan";
  }
  if (!universityCity) {
    errors.universityCity = "Kota Universitas Wajib Disertakan";
  }
  return errors;
}

ModalAddUniversitas = reduxForm({
  // a unique name for the form
  form: "ModalAddPractice",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalAddUniversitas);

function mapStateToProps({ addUniversity, universityList }) {
  return {
    addUniversitas: getUniversityAdd(addUniversity),
    addUniversitasPending: getUniversityAddPending(addUniversity),
    universityList_pending: getUniversityListPending(universityList),
    universityList_data: getUniversityList(universityList),
    universityList_error: getUniversityListError(universityList),
  };
}

export default connect(mapStateToProps, { addUniversity, universityList })(
  ModalAddUniversitas
);
