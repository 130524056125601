import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { listMasterBenefit, setSelectedMasterBenefit } from "../../actions/master-data/master_benefit_action";
import { getListMasterBenefitPending, getListMasterBenefit, getListMasterBenefitError } from "../../reducers/master-data/master_benefit_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderBenefit from "./Header_benefit";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ModalEditBenefit from "./Modal_edit_benefit";
import ModalDeleteBenefit from "./Modal_delete_benefit";

class ListBenefit extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            status: "",
            isLoad: true,
            modalEditBenefitShow: false,
            modalDeleteBenefitShow: false,
            columns: [
                {
                    title: "No", field: "name",
                    render: (rowData) => {
                        return <>{rowData.no}</>
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                { title: "Nama Benefit", field: "name" },
                {
                    title: "Jumlah SHU",
                    render: (data) => {
                        const total = data.total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                        return "Rp " + total;
                    }
                },
                {
                    title: "Status",
                    render: (data) => {
                        return (
                            <TableStatusComponent
                                status={
                                    data.status === 0
                                        ? "Nonaktif"
                                        : "Aktif"
                                }
                            />
                        );
                    },
                    width: 210,
                },
                {
                    title: "",
                    render: (rowData) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.handleEditBenefit(rowData)}>
                                    Edit
                                </MenuItem>
                                {rowData.status !== 1 &&
                                    <MenuItem onClick={() => this.handleDeleteBenefit(rowData)}>
                                        Hapus
                                    </MenuItem>
                                }
                            </DetailButtonComponent>
                        );
                    },
                },
            ]
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleEditBenefit(data) {
        data = { ...data, isEditMasterBenefit: true }
        this.props.setSelectedMasterBenefit(data);
        this.setState({ modalEditBenefitShow: true })
    }

    handleDeleteBenefit(data) {
        data = { ...data, isDeleteMasterBenefit: true }
        this.props.setSelectedMasterBenefit(data);
        this.setState({ modalDeleteBenefitShow: true })
    }

    componentDidMount() {
        let param_listBenefit = {
            page: 1,
            length: 10,
            search_text: "",
            id: "",
        };
        this.props.listMasterBenefit(param_listBenefit);
    }

    shouldComponentRender() {
        const { listBenefit_pending } = this.props;
        if (listBenefit_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const { listBenefit_data } = this.props;
        let data;
        let records_total, length = 0;
        if (listBenefit_data) {
            data = listBenefit_data.data;
            length = data.length;
            records_total = listBenefit_data.records_total;
        }
        return (
            <ContentContainer>
                <ModalEditBenefit
                    show={this.state.modalEditBenefitShow}
                    onHide={() => this.setState({ modalEditBenefitShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <ModalDeleteBenefit
                    show={this.state.modalDeleteBenefitShow}
                    onHide={() => this.setState({ modalDeleteBenefitShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderBenefit handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Member"
                    columns={this.state.columns}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                id: "",
                                status: this.state.status
                            };
                            this.props.listMasterBenefit(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listMasterBenefit }) {
    return {
        listBenefit_pending: getListMasterBenefitPending(listMasterBenefit),
        listBenefit_data: getListMasterBenefit(listMasterBenefit),
        listBenefit_error: getListMasterBenefitError(listMasterBenefit),
    };
}

export default connect(mapStateToProps, { listMasterBenefit, setSelectedMasterBenefit })(withRouter(ListBenefit));