import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import localization from "moment/locale/id";

import { listJournal, detailJournal, setSelectedJournal } from "../../actions/journal/journal_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { provinceList } from "../../actions/master-data/province_action";

import {
    getJournalPending,
    getListJournal,
    getJournalError,
} from "../../reducers/journal/journal_reducer";
import HeaderJournal from "./HeaderJournal";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";

class Journal extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            start_date: "",
            end_date: "",
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleDetailJournal(data) {
        this.props.setSelectedJournal(data);
        this.props.history.push(`journal/detail/informasi-umum/${data.id}`);
    }

    render() {
        let data = [];
        let records_total = 0;
        const { listJournal, listJournal_data } = this.props;
        const isPeranColumn = getUser().role_codes[0] === "root" || getUser().role_codes[0] === "chief_editor" ? true : false;
        if (listJournal_data) {
            data = listJournal_data.data;
            records_total = listJournal_data.records_total;
        }

        return (
            <ContentContainer>
                <HeaderJournal
                    handleRefresh={this.handleRefresh}
                    handleOpenModalAdd={this.handleOpenModalAdd}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Simple Action Preview"
                    columns={isPeranColumn ? [
                        {
                            title: "No",
                            field: "journal_name",
                            render: (rowData) => {
                                return <>{rowData.no}</>;
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 30,
                            },
                            cellStyle: {
                                paddingLeft: 30,
                            },
                        },
                        {
                            title: "Tanggal Pengajuan",
                            render: (data) => {
                                return moment(data.date)
                                    .locale("id", localization)
                                    .format("DD MMMM YYYY");
                            },
                        },
                        {
                            title: "Judul Jurnal",
                            field: "title",
                        },
                        {
                            title: "Nama Penulis",
                            field: "author",
                        },
                        {
                            title: "Status",
                            render: (data) => {
                                return (
                                    <TableStatusComponent
                                        status={
                                            data.status === 0
                                                ? "Menunggu Verifikasi"
                                                : data.status === 1
                                                    ? "Terverifikasi"
                                                    : data.status === 2
                                                        ? "Ditolak"
                                                        : data.status === 3
                                                            ? "Publish"
                                                            : "Revisi"
                                        }
                                    />
                                );
                            },
                            width: 210,
                        },
                        {
                            title: "Peran",
                            render: (data) => {
                                if (data.status === 1 && data.step === 5) {
                                    return "Language Editor";
                                } else if (data.status === 1 && data.step === 6) {
                                    return "Chief Editor";
                                } else if (data.status === 3) {
                                    return "Admin Pusat"
                                } else if (data.status === 2 && data.is_reject_approve === 1) {
                                    return "Chief Editor"
                                } else {
                                    return data.role_next_approve.role_name;
                                }
                            }
                        },
                        {
                            title: "",
                            render: (rowData) => {
                                return (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.handleDetailJournal(rowData)}>
                                            Detail
                                        </MenuItem>
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]
                        :
                        [
                            {
                                title: "No",
                                field: "journal_name",
                                render: (rowData) => {
                                    return <>{rowData.no}</>;
                                },
                                width: 20,
                                headerStyle: {
                                    paddingLeft: 40,
                                },
                                cellStyle: {
                                    paddingLeft: 40,
                                },
                            },
                            {
                                title: "Tanggal Pengajuan",
                                render: (data) => {
                                    return moment(data.date)
                                        .locale("id", localization)
                                        .format("DD MMMM YYYY");
                                },
                            },
                            {
                                title: "Judul Jurnal",
                                field: "title",
                            },
                            {
                                title: "Nama Penulis",
                                field: "author",
                            },
                            {
                                title: "Status",
                                render: (data) => {
                                    return (
                                        <TableStatusComponent
                                            status={
                                                data.status === 0
                                                    ? "Menunggu Verifikasi"
                                                    : data.status === 1
                                                        ? "Terverifikasi"
                                                        : data.status === 2
                                                            ? "Ditolak"
                                                            : data.status === 3
                                                                ? "Publish"
                                                                : "Revisi"
                                            }
                                        />
                                    );
                                },
                                width: 210
                            },
                            {
                                title: "",
                                render: (rowData) => {
                                    return (
                                        <DetailButtonComponent>
                                            <MenuItem onClick={() => this.handleDetailJournal(rowData)}>
                                                Detail
                                            </MenuItem>
                                        </DetailButtonComponent>
                                    );
                                },
                            },
                        ]
                    }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                start_date: this.state.start_date,
                                end_date: this.state.end_date,
                            };
                            listJournal(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                            // width: 10,
                            // paddingLeft: "40px",
                            // paddingRight: "40px",
                        }, //change header padding
                        // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <td style={{ width: "100%", display: "block" }}>
                                <Row
                                    // vertical="center"
                                    horizontal="space-between"
                                    style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        // background: "red",
                                    }}
                                >
                                    <Row
                                        style={{
                                            // backgroundColor: "red",
                                            width: "50%",
                                        }}
                                        vertical="center"
                                    >
                                        <p>
                                            Menampilkan {data.length} dari {records_total} data
                                        </p>
                                    </Row>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <TablePagination {...props} />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                            </td>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listJournal, setSelectedJournal }) {
    return {
        listJournal_data: getListJournal(listJournal),
        listJournal_error: getJournalError(listJournal),
        listJournal_pending: getJournalPending(listJournal),
    };
}

export default connect(mapStateToProps, {
    listJournal,
    detailJournal,
    provinceList,
    setSelectedJournal
})(Journal);
