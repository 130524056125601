import {
    LIST_BENEFIT_PENDING,
    LIST_BENEFIT_SUCCESS,
    LIST_BENEFIT_ERROR,
    DETAIL_BENEFIT_PENDING,
    DETAIL_BENEFIT_SUCCESS,
    DETAIL_BENEFIT_ERROR,
    ADD_BENEFIT_PENDING,
    ADD_BENEFIT_SUCCESS,
    ADD_BENEFIT_ERROR,
    SET_SELECTED_BENEFIT,
    APPROVAL_BENEFIT_PENDING,
    APPROVAL_BENEFIT_SUCCESS,
    APPROVAL_BENEFIT_ERROR,
    EDIT_BENEFIT_PENDING,
    EDIT_BENEFIT_SUCCESS,
    EDIT_BENEFIT_ERROR,
    UPLOAD_BENEFIT_PENDING,
    UPLOAD_BENEFIT_SUCCESS,
    UPLOAD_BENEFIT_ERROR,
} from "../../actions/benefit/benefit_action";

const initialState = {
    pending: false,
    error: null,
};

export function listBenefit(
    state = { ...initialState, list_benefit: null },
    action
) {
    switch (action.type) {
        case LIST_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                list_benefit: action.data,
            };
        case LIST_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailBenefit(
    state = { ...initialState, detail_benefit: null },
    action
) {
    switch (action.type) {
        case DETAIL_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_benefit: action.data,
            };
        case DETAIL_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addBenefit(state = initialState, action) {
    switch (action.type) {
        case ADD_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedBenefit(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_BENEFIT:
            return action.value;
        default:
            return state;
    }
}

export function approvalBenefit(
    state = { ...initialState, approvalBenefit: null },
    action
) {
    switch (action.type) {
        case APPROVAL_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVAL_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                approvalBenefit: action.data
            };
        case APPROVAL_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editBenefit(
    state = { ...initialState, editBenefit: null },
    action
) {
    switch (action.type) {
        case EDIT_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                editBenefit: action.data
            };
        case EDIT_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function uploadBenefit(
    state = { ...initialState, uploadBenefit: null },
    action
  ) {
    switch (action.type) {
      case UPLOAD_BENEFIT_PENDING:
        return {
          ...state,
          pending: true,
        };
      case UPLOAD_BENEFIT_SUCCESS:
        return {
          ...state,
          pending: false,
          uploadBenefit: action.data,
        };
      case UPLOAD_BENEFIT_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }

export const getListBenefit = (state) => state.list_benefit;
export const getListBenefitPending = (state) => state.pending;
export const getListBenefitError = (state) => state.error;
export const getDetailBenefit = (state) => state.detail_benefit;
export const getSelectedBenefit = (state) => state.selectedBenefit;
export const getBenefitApprovalPending = (state) => state.pending;
export const getBenefitApproval = (state) => state.approvalBenefit;
export const getBenefitApprovalError = (state) => state.error;
export const getBenefitAddPending = (state) => state.pending;
export const getBenefitAdd = (state) => state.approvalBenefit;
export const getBenefitAddError = (state) => state.error;
export const getBenefitEditPending = (state) => state.pending;
export const getBenefitEdit = (state) => state.editBenefit;
export const getBenefitEditError = (state) => state.error;
export const getBenefitUploadPending = (state) => state.pending;
export const getBenefitUpload = (state) => state.uploadBenefit;
export const getBenefitUploadError = (state) => state.error;