import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";

import { toastSuccess, toastError } from "../../components/toast/Toast";
export const LIST_HOSPITAL_PENDING = "LIST_HOSPITAL_PENDING";
export const LIST_HOSPITAL_SUCCESS = "LIST_HOSPITAL_SUCCESS";
export const LIST_HOSPITAL_ERROR = "LIST_HOSPITAL_ERROR";

export const DETAIL_HOSPITAL_PENDING = "DETAIL_HOSPITAL_PENDING";
export const DETAIL_HOSPITAL_SUCCESS = "DETAIL_HOSPITAL_SUCCESS";
export const DETAIL_HOSPITAL_ERROR = "DETAIL_HOSPITAL_ERROR";

export const ADD_HOSPITAL_PENDING = "ADD_HOSPITAL_PENDING";
export const ADD_HOSPITAL_SUCCESS = "ADD_HOSPITAL_SUCCESS";
export const ADD_HOSPITAL_ERROR = "ADD_HOSPITAL_ERROR";

export const EDIT_HOSPITAL_PENDING = "EDIT_HOSPITAL_PENDING";
export const EDIT_HOSPITAL_SUCCESS = "EDIT_HOSPITAL_SUCCESS";
export const EDIT_HOSPITAL_ERROR = "EDIT_HOSPITAL_ERROR";

export const DELETE_HOSPITAL_PENDING = "DELETE_HOSPITAL_PENDING";
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS";
export const DELETE_HOSPITAL_ERROR = "DELETE_HOSPITAL_ERROR";

export const LIST_PROVINCE_PENDING = "LIST_PROVINCE_PENDING";
export const LIST_PROVINCE_SUCCESS = "LIST_PROVINCE_SUCCESS";
export const LIST_PROVINCE_ERROR = "LIST_PROVINCE_ERROR";

const LIST_HOSPITAL_URL = "master_data/hospital/find";
const EDIT_HOSPITAL_URL = "master_data/hospital/edit";
const DELETE_HOSPITAL_URL = "master_data/hospital/delete";
const ADD_HOSPITAL_URL = "master_data/hospital/add";

export function listHospital(param = {}, resolve, reject, callback) {
  return (dispatch) => {
    dispatch(actionPending(LIST_HOSPITAL_PENDING));
    API.post(LIST_HOSPITAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_HOSPITAL_SUCCESS, res.data));
        // toastSuccess("Berhasil mendapatkan list HOSPITAL");
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          code: item.code ? item.code : "-",
          name: item.name ? item.name : "-",
          class_type: item.class_type ? "Kelas " + item.class_type : "-",
          province: item.province ? item.province.name : "-",
          phone: item.phone ? item.phone : "-",
          address: item.address ? item.address : "-",
          id: item.id,
          upload_by: item.upload_by ? item.upload_by : "-"
        }));

        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }

        return res;
      })
      .catch((error) => {
        dispatch(actionError(LIST_HOSPITAL_ERROR, error));
        toastError(error, "Gagal mendapatkan list rumah sakit, ");
      });
  };
}

export function addHospital(param = {}, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_HOSPITAL_PENDING));
    API.post(ADD_HOSPITAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_HOSPITAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan rumah sakit");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_HOSPITAL_ERROR, error));
        toastError(error, "Gagal menambahkan rumah sakit, ");
      });
  };
}

export function detailHospital(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_HOSPITAL_PENDING));
    API.post(LIST_HOSPITAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DETAIL_HOSPITAL_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_HOSPITAL_ERROR, error));
        toastError(error, "Gagal mendapatkan list rumah sakit, ");
      });
  };
}

export function editHospital(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_HOSPITAL_PENDING));
    API.post(EDIT_HOSPITAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_HOSPITAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah rumah sakit");

        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_HOSPITAL_ERROR, error));
        toastError(error, "Gagal mengubah rumah sakit, ");
      });
  };
}

export function deleteHospital(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_HOSPITAL_PENDING));
    API.post(DELETE_HOSPITAL_URL, { id: id }, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_HOSPITAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus rumah sakit");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_HOSPITAL_ERROR, error));
        toastError(error, "Gagal menghapus rumah sakit, ");
      });
  };
}
