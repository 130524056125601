import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
    getP2kbEditDetailPending,
    getP2kbEditDetail,
    getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import { getUser } from "../../../helpers/user";
import { memberList } from "../../../actions/member/member_action";
import {
    getMemberListPending,
    getMemberList,
    getMemberListError,
} from "../../../reducers/member/member_reducer";
// import moment from 'moment';
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import { listHospital } from "../../../actions/master-data/hospital_action";
import {
    getListHospital,
    getHospitalPending,
    getHospitalError,
} from "../../../reducers/master-data/hospital_reducer";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_TindakanOperasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenShow: false,
            last_activity_skp_detail_id: "",
            values_id: "",
            berkas_sip: null,
            berkas_medis: null,
            berkas_sip_button: false,
            berkas_medis_button: false,
            isDoneUploading: true,
            skp: "",
            imgUrl: "",
            modal_title: "",
            spek_berkas_medis: null,
            spek_berkas_sip: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePasien = this.handleChangePasien.bind(this);
        this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleChangeTingkatan(values) {
        let listActivity_selected = "";
        if (this.props.formValue.value_id) {
            listActivity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.skp_detail.activity_id);
        } else {
            listActivity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        }
        let childrens_selected = listActivity_selected.childrens.find(item => item.id === values.value);
        this.setState({ last_activity_skp_detail_id: childrens_selected.id })
        this.setState({ values_id: childrens_selected.values[0].id })
        let skp_must_be_multipied = childrens_selected.values[0].skp_must_be_multipied;
        let value_multipied = childrens_selected.values[0].value_multipied;
        if (skp_must_be_multipied === 0) {
            this.props.change('skp_total', this.props.formValue.total_for_calculate_skp);
        } else {
            this.props.change('skp_total', +(this.props.formValue.total_for_calculate_skp * value_multipied).toFixed(2));
        }
    }

    handleChangePasien(values) {
        let listActivity_selected = "";
        if (this.props.formValue.value_id) {
            listActivity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.skp_detail.activity_id);
        } else {
            listActivity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        }
        let childrens_selected = listActivity_selected.childrens.find(item => item.id === this.props.formValue.tingkatan.value);
        let skp_must_be_multipied = childrens_selected.values[0].skp_must_be_multipied;
        let value_multipied = childrens_selected.values[0].value_multipied;
        if (skp_must_be_multipied === 0) {
            this.props.change('skp_total', values.target.value);
        } else {
            this.props.change('skp_total', +(values.target.value * value_multipied)).toFixed(2);
        }
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sip") {
        //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
        //     this.props.change("berkas_sip", "dvorak");
        // } else if (formName === "berkas_medis") {
        //     this.setState({ berkas_medis: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas_medis", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_medis_button: true })
        this.setState({ berkas_sip_button: true })
        if (formName === "berkas_sip") {
            this.setState({ berkas_sip: <Loading /> })
        } else if (formName === "berkas_medis") {
            this.setState({ berkas_medis: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sip_button: false })
            this.setState({ berkas_medis_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas_sip") {
                    this.props.change("berkas_sip", this.props.uploadP2kb_data);
                    this.setState({ berkas_sip: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas_sip: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_sip, "SIP")} /> }), 2000)
                    this.props.change("spek_berkas_sip", values.target.files[0]);
                } else if (formName === "berkas_medis") {
                    this.props.change("berkas_medis", this.props.uploadP2kb_data);
                    this.setState({ berkas_medis: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas_medis: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_medis, "Medis")} /> }), 3000)
                    this.props.change("spek_berkas_medis", values.target.files[0]);
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sip_button: false })
            this.setState({ berkas_medis_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas_sip") {
                    this.props.change("berkas_sip", null);
                    this.setState({ berkas_sip: <Loading done={true} failed={true} /> })
                } else if (formName === "berkas_medis") {
                    this.props.change("berkas_medis", null);
                    this.setState({ berkas_medis: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        // } else {
        //     if (formName === "berkas_sip") {
        //         this.setState({ berkas_sip: "Batas maksimal ukuran file 2 MB!" })
        //     } else if (formName === "berkas_medis") {
        //         this.setState({ berkas_medis: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        const { handleEditDetail, onHide, handleRefresh, editDetailP2kb } = this.props;
        if (values.status === undefined || values.status === 0) {
            const editData = JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi"));
            let details_tobesent = {
                activity_date: values.activity_date,
                sip_no: values.sip_no,
                hasEdit: true,
                total_for_calculate_skp: values.total_for_calculate_skp,
                last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
                values_id: this.state.values_id,
                practice_name: !editData.isDetail ? values.practice_name : values.practice_name.label,
                no: editData.no,
                attachments: [
                    {
                        label: "SIP",
                        filename: values.berkas_sip
                    },
                    {
                        label: "Medis",
                        filename: values.berkas_medis
                    }
                ]
            }
            let details_tobeshown = {
                ...details_tobesent,
                tingkatan: values.tingkatan.label,
                skp: +(this.props.formValue.skp_total).toFixed(2),
                no: editData.no,
                tableData: editData.tableData,
                activity_id: this.props.formValue.values_id
            }
            handleEditDetail(details_tobeshown, details_tobesent);
            onHide();
        } else {
            const param = {
                id: values.id,
                activity_date: values.activity_date,
                sip_no: values.sip_no,
                total_for_calculate_skp: values.total_for_calculate_skp,
                last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
                values_id: this.state.values_id,
                practice_name: values.practice_name.label,
                attachments: [
                    {
                        label: "SIP",
                        filename: values.berkas_sip
                    },
                    {
                        label: "Medis",
                        filename: values.berkas_medis
                    }
                ]
            }
            const callback = () => {
                onHide();
                handleRefresh();
            }
            editDetailP2kb(param, callback);
        }
    }

    updateState() {
        if (this.state.last_activity_skp_detail_id === "") {
            if (JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi") !== null)) {
                this.setState({ last_activity_skp_detail_id: JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi")).last_activity_skp_detail_id })
                this.setState({ values_id: JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi")).values_id })
                this.setState({ berkas_sip: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi")).attachments[0].filename, "SIP")} /> })
                this.setState({ berkas_medis: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi")).attachments[1].filename, "Medis")} /> })
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateState();
    }

    componentDidMount() {
        this.props.listActivity();
        this.props.listHospital();
        this.props.memberList({ id: getUser().id });

    }

    render() {
        const { show,
            onHide,
            listActivity_data,
            handleSubmit,
            uploadP2kb_pending,
            listHospital_data,
            memberList_data,
            formValue: tingkatan, status
        } = this.props;
        let tingkatanOptions = [];
        let total_for_calculate_skp_disabled = tingkatan.tingkatan !== undefined ? false : true;
        let total_for_calculate_skp_placeholder = tingkatan.tingkatan !== undefined ? "Masukkan Jumlah Pasien" : "Pilih tingkatan terlebih dahulu";
        const activity_id_skp = this.state.tempSKPData === null ? this.state.skp_detail.activity_id : this.props.formValue.values_id ? this.props.formValue.values_id : this.state.tempSKPData.activity.value;
        if (listActivity_data) {
            let listActivity_selected = listActivity_data.data.find(item => item.id === activity_id_skp)
            if (listActivity_selected) {
                tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            }
        }
        let hospitalOptions = [];
        if (memberList_data && memberList_data.data[0].member_practice_place_data.length > 0) {
            let member_data = memberList_data.data[0].member_practice_place_data
            hospitalOptions = member_data.map((item) => ({
                value: item.hospital_id,
                label: item.hospital_data.name
            }));
        } else {
            if (listHospital_data) {
                hospitalOptions = listHospital_data.data.map((item) => ({
                    value: item.id,
                    label: item.name
                }));
            }
        }

        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="sip_no"
                                    placeholder="Masukkan Nomor SIP/ Surat Tugas/ Surat Penunjukkan"
                                    label="Nomor SIP/ Surat Tugas/ Surat Penunjukkan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="tingkatan"
                                    placeholder="Masukkan Tingkatan Kegiatan"
                                    label="Tingkatan"
                                    onChange={this.handleChangeTingkatan}
                                    isClearable={false}
                                    options={tingkatanOptions}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="practice_name"
                                    placeholder="Masukkan Tempat Praktek"
                                    label="Tempat Praktek"
                                    isAsync
                                    asyncUrl="master_data/hospital/find"
                                    options={hospitalOptions}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="total_for_calculate_skp"
                                    placeholder={total_for_calculate_skp_placeholder}
                                    label="Jumlah Pasien"
                                    onChange={this.handleChangePasien}
                                    disable={total_for_calculate_skp_disabled}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="skp_total"
                                    placeholder="Jumlah SKP"
                                    label="Jumlah SKP"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="berkas_sip"
                                    type="file"
                                    fileType="image"
                                    title="Berkas SIP"
                                    disabled={this.state.berkas_sip_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas_sip === undefined ? "PDF, PNG, JPG maksimal 1 MB" : this.state.berkas_sip === null ? "PDF, PNG, JPG maksimal 1 MB" : this.state.berkas_sip}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                                <Field
                                    name="berkas_medis"
                                    type="file"
                                    fileType="image"
                                    title="Berkas Medis"
                                    disabled={this.state.berkas_medis_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas_medis === undefined ? "PDF, PNG, JPG maksimal 1 MB" : this.state.berkas_medis === null ? "PDF, PNG, JPG maksimal 1 MB" : this.state.berkas_medis}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                {status === 0 ? "submit ulang" : "simpan"}
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const fileType = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
    const { activity_date, sip_no, tingkatan, total_for_calculate_skp, berkas_sip, berkas_medis, spek_berkas_medis, spek_berkas_sip } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!sip_no) {
        errors.sip_no = "Nomor SIP/ Surat Tugas/ Surat Penunjukkan wajib diisi"
    }
    if (!tingkatan) {
        errors.tingkatan = "Tingkatan wajib diisi"
    }
    if (!total_for_calculate_skp) {
        errors.total_for_calculate_skp = "Jumlah pasien wajib diisi"
    }
    if (!berkas_sip) {
        errors.berkas_sip = "Berkas SIP wajib diisi"
    }
    else{
        if (spek_berkas_sip && (spek_berkas_sip.type !== fileType[0] && spek_berkas_sip.type !== fileType[1] && spek_berkas_sip.type !== fileType[2])){
            errors.berkas_sip = "Format file hanya bisa PDF, PNG, JPG"
        }
        else if (spek_berkas_sip && (spek_berkas_sip.size > maxFileSize)){
            errors.berkas_sip = "File lebih dari 1 MB"
        }
    }
    if (!berkas_medis) {
        errors.berkas_medis = "Berkas medis wajib diisi"
    }
    else{
        if (spek_berkas_medis && (spek_berkas_medis.type !== fileType[0] && spek_berkas_medis.type !== fileType[1] && spek_berkas_medis.type !== fileType[2] && spek_berkas_medis.type !== fileType[3])){
            errors.berkas_medis = "Format file hanya bisa PDF, PNG, JPG"
        }
        else if (spek_berkas_medis && (spek_berkas_medis.size > maxFileSize)){
            errors.berkas_medis = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_TindakanOperasi'));

ModalEditDetailSKP_TindakanOperasi = reduxForm({
    form: "ModalEditDetailSKP_TindakanOperasi",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalEditDetailSKP_TindakanOperasi)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb, listHospital, editDetailP2kb, memberList } = state;
    const selector = formValueSelector("ModalEditDetailSKP_TindakanOperasi");
    const formValue = selector(state, "tingkatan", "total_for_calculate_skp", "berkas_sip", "berkas_medis", "skp_total", "id", "values_id");
    const editData = JSON.parse(localStorage.getItem("skp_edit_detail_skp_tindakan_operasi"));
    let initialValues = {};
    if (editData) {
        if (!editData.isDetail) {
            initialValues = {
                activity_date: editData.activity_date,
                sip_no: editData.sip_no,
                tingkatan: {
                    label: editData.tingkatan,
                    value: editData.last_activity_skp_detail_id
                },
                practice_name: editData.practice_name,
                total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
                skp_total: editData.skp,
                berkas_sip: editData.attachments[0].filename,
                berkas_medis: editData.attachments[1].filename
            }
        } else {
            initialValues = {
                id: editData.id,
                values_id: editData.mst_activity_skp_type_detail_data.activity_id ? editData.mst_activity_skp_type_detail_data.activity_id : editData.activity_id,
                status: editData.status,
                activity_date: editData.activity_date,
                sip_no: editData.sip_no,
                tingkatan: {
                    label: editData.mst_activity_skp_type_detail_data.name,
                    value: editData.mst_activity_skp_type_detail_data.id ? editData.mst_activity_skp_type_detail_data.id : editData.last_activity_skp_detail_id,
                },
                total_for_calculate_skp: parseFloat(editData.total_for_calculate_skp),
                practice_name: { label: editData.practice_name },
                skp_total: editData.total_skp,
                berkas_sip: editData.attachments[0].filename,
                berkas_medis: editData.attachments[1].filename
            }
        }
    }
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
        editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
        editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
        listHospital_data: getListHospital(listHospital),
        listHospital_pending: getHospitalPending(listHospital),
        listHospital_error: getHospitalError(listHospital),
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        formValue,
        initialValues
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, listHospital, editDetailP2kb, memberList })(withRouter(ModalEditDetailSKP_TindakanOperasi));