import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalJournal } from "../../actions/journal/journal_action";
import {
  getJournalApproval,
  getJournalApprovalPending,
  getJournalApprovalError,
} from "../../reducers/journal/journal_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInputWithLabel
} from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class ModalRejectJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forRefresh: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { approvalJournal, id, onHide } = this.props;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("is_approve", "false");
    formData.append("reject_notes", values.reject_notes);

    const callback = () => {
      onHide();
      this.props.handleRefresh();
    };
    approvalJournal(formData, callback);
  }

  render() {
    const { handleSubmit, approvalJournal_pending } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="reject_notes"
                  placeholder="Masukkan alasan penolakan"
                  label="Alasan Penolakan"
                  component={renderInputWithLabel}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={
                  approvalJournal_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                      <CheckIcon />
                    )
                }
              >
                Submit
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function validate(values) {
  const { reject_notes } = values;
  const errors = {};
  if (!reject_notes) {
    errors.reject_notes = "Alasan penolakan wajib diisi";
  }
  return errors;
}

ModalRejectJournal = reduxForm({
  form: "ModalRejectJournal",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalRejectJournal);

function mapStateToProps({ approvalJournal }) {
  return {
    approvalJournal_pending: getJournalApprovalPending(approvalJournal),
    approvalJournal_data: getJournalApproval(approvalJournal),
    approvalJournal_error: getJournalApprovalError(approvalJournal),
  };
}

export default connect(mapStateToProps, { approvalJournal })(ModalRejectJournal);
