import {
  LIST_EDUCATION_PENDING,
  LIST_EDUCATION_SUCCESS,
  LIST_EDUCATION_ERROR,
  DETAIL_EDUCATION_PENDING,
  DETAIL_EDUCATION_SUCCESS,
  DETAIL_EDUCATION_ERROR,
  UPLOAD_EDUCATION_PENDING,
  UPLOAD_EDUCATION_SUCCESS,
  UPLOAD_EDUCATION_ERROR,
  EDIT_EDUCATION_PENDING,
  EDIT_EDUCATION_SUCCESS,
  EDIT_EDUCATION_ERROR,
  ADD_EDUCATION_PENDING,
  ADD_EDUCATION_SUCCESS,
  ADD_EDUCATION_ERROR,
  SET_IS_EDIT_FILE,
  APPROVE_EDUCATION_PENDING,
  APPROVE_EDUCATION_ERROR,
  APPROVE_EDUCATION_SUCCESS,
} from "../../actions/member/education_action";

const initialState = {
  pending: false,
  error: null,
};

export function listEducation(
  state = { ...initialState, list_education: null },
  action
) {
  switch (action.type) {
    case LIST_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        list_education: action.data,
      };
    case LIST_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function uploadEducation(
  state = { ...initialState, uploadEducation: null },
  action
) {
  switch (action.type) {
    case UPLOAD_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        uploadEducation: action.data,
      };
    case UPLOAD_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}


export function detailEducation(
  state = { ...initialState, detail_education: null },
  action
) {
  switch (action.type) {
    case DETAIL_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_education: action.data,
      };
    case DETAIL_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editEducation(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addEducation(state = initialState, action) {
  switch (action.type) {
    case ADD_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveEducation(state = initialState, action) {
  switch (action.type) {
    case APPROVE_EDUCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_EDUCATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_EDUCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListEducation = (state) => state.list_education;
export const getDetailEducation = (state) => state.detail_education;
export const getEducationPending = (state) => state.pending;
export const getEducationError = (state) => state.error;
export const getEducationIsEditFile = (state) => state.isEditFile;
export const getEducationUpload = (state) => state.uploadEducation;
export const getEducationUploadError = (state) => state.error;