import React, { Component } from "react";
import { connect } from "react-redux";
import { listReport } from "../../actions/report/report_action";
import { Row } from "simple-flexbox";
import {
  getListReport,
  getReportError,
  getReportPending,
} from "../../reducers/report/report_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import ReportHeader from "./ReportHeader";
import { listBranch } from "../../actions/master-data/branch_action";
import { default_api } from "../../constants/default_api";


class Report extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
      branch_id: null,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/report/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    this.props.listBranch({ length: 999 });
  }

  render() {
    const { listReport, listReport_data } = this.props;
    const { search_text, branch_id } = this.state;
    let data = [];
    let records_total = 0;
    if (listReport_data) {
      data = listReport_data.data;
      records_total = listReport_data.records_total;
    }
    return (
      <ContentContainer>
        <ReportHeader handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama",
              field: "member_data.name",
              render: ({ member_data: { img_profile, name } }) => (
                <Row vertical="center">
                  <img
                    src={`${default_api}/image/show/user_profile/${img_profile}`}
                    alt="profile"
                    height={40}
                    width={40}
                    style={{ borderRadius: 20 }}
                  />
                  <div style={{ marginLeft: 20 }}>{name}</div>
                </Row>
              ),
            },
            {
              title: "No ID PABOI",
              field: "member_data.member_profile.paboi_id",
            },

            {
              title: "Estimasi Dana Masuk (Rp)",
              render: ({ estimation_total }) => {
                return estimation_total
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },

            {
              title: "Dana Aktual (Rp)",
              render: ({ act_total }) => {
                return act_total
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
            {
              title: "Dana Outstanding",
              render: ({ estimation_total, act_total }) => {
                return (Number(estimation_total) - Number(act_total))
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
            {
              title: "Alokasi Dana Cabang (Rp)",
              render: ({ estimation_total }) => {
                return ((estimation_total * 25) / 100)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
            // {
            //   title: "Alokasi Dana Yayasan (Rp)",
            //   render: ({ allocation_total_foundation }) => {
            //     return allocation_total_foundation
            //       .toString()
            //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            //   },
            // },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                branch_id: branch_id,
                // branch_id: "798be5f1-702c-49b1-ba7d-ba5070184a71",
              };
              listReport(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Data tidak ditemukan",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
        {/* <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama Cabang",
              field: "branch_data.name",
            },
            {
              title: "Jumlah Anggota",
              render: ({ total_member }) => {
                if (total_member > 0) {
                  return total_member;
                } else {
                  return "-";
                }
              }
            },
            {
              title: "Estimasi Dana Masuk (Rp)",
              render: ({ estimation_total }) => {
                if (estimation_total && estimation_total > 0) {
                  return estimation_total
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },

            {
              title: "Dana Aktual (Rp)",
              render: ({ act_total }) => {
                if (act_total && act_total > 0) {
                  return act_total
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Dana Outstanding (Rp)",
              render: ({ estimation_total, act_total }) => {
                if (Number(estimation_total - Number(act_total)) > 0) {
                  return (Number(estimation_total) - Number(act_total))
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Alokasi Dana PABOI Aktual (Rp)",
              render: ({ allocation_total_paboi }) => {
                if (allocation_total_paboi && allocation_total_paboi > 0) {
                  return allocation_total_paboi
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Alokasi Dana PABOI Cabang (Rp)",
              render: ({ allocation_total_branch }) => {
                if (allocation_total_branch && allocation_total_branch > 0) {
                  return allocation_total_branch
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Alokasi Dana Yayasan (Rp)",
              render: ({ allocation_total_foundation }) => {
                if (allocation_total_foundation && allocation_total_foundation > 0) {
                  return allocation_total_foundation
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                branch_id: branch_id,
              };
              listReport(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        /> */}
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listReport }) {
  return {
    listReport_data: getListReport(listReport),
    listReport_error: getReportError(listReport),
    listReport_pending: getReportPending(listReport),
  };
}

export default connect(mapStateToProps, {
  listReport,
  listBranch,
})(Report);
