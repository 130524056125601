import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

function DetailNameComponent(props) {
  const { name } = props;
  return <h3 className={css(styles.detailName)}> {name} </h3>;
}

DetailNameComponent.propTypes = {
  name: string,
};

const styles = StyleSheet.create({
  detailName: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: 800,
    color: "#495057",
    marginBottom: 0,

  },
});

export default DetailNameComponent;
