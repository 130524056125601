import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Placeholder from "./skp_placeholder.js";
import FadeIn from "react-fade-in";

export default (props) => {
    const { ranah, total_skp, total_skp_header, isLoad } = props;
    return (
        <Column>
            <div className={css(styles.detailHeaderContainer)}>
                <Row>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div className={css(styles.detailHeaderSKPColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Ranah Kegiatan
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && ranah === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {ranah}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div>
                            <div className={css(styles.detailHeaderTitle)}>
                                Total SKP
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && total_skp === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {total_skp_header}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>

                </Row>
            </div>
        </Column>
    )
}

const styles = StyleSheet.create({
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderSKPColumn: {
        margin: "20px 20px 20px 30px",
        width: "50%"
    },
    detailHeaderSKPColumnValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderSKPOutstandingColumn: {
        margin: "20px 50px",
        width: "15%"
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
})