import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';

export const LIST_CITY_PENDING = ' LIST_CITY_PENDING'
export const LIST_CITY_SUCCESS = ' LIST_CITY_SUCCESS'
export const LIST_CITY_ERROR = ' LIST_CITY_ERROR'

const LIST_CITY_URL = 'master_data/data_reference/find_city';

export function listCity(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_CITY_PENDING));
        API.post(LIST_CITY_URL, param)
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_CITY_SUCCESS, res.data));
                const { records_total } = res.data;
                if (resolve) {
                    resolve({
                        data: res.data.data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_CITY_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}