import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_ACTIVITY_PENDING = "LIST_ACTIVITY_PENDING";
export const LIST_ACTIVITY_SUCCESS = "LIST_ACTIVITY_SUCCESS";
export const LIST_ACTIVITY_ERROR = "LIST_ACTIVITY_ERROR";

export const DETAIL_ACTIVITY_PENDING = "DETAIL_ACTIVITY_PENDING";
export const DETAIL_ACTIVITY_SUCCESS = "DETAIL_ACTIVITY_SUCCESS";
export const DETAIL_ACTIVITY_ERROR = "DETAIL_ACTIVITY_ERROR";

export const ADD_ACTIVITY_PENDING = "ADD_ACTIVITY_PENDING";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_ERROR = "ADD_ACTIVITY_ERROR";

export const EDIT_ACTIVITY_PENDING = "EDIT_ACTIVITY_PENDING";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_ERROR = "EDIT_ACTIVITY_ERROR";

export const DELETE_ACTIVITY_PENDING = "DELETE_ACTIVITY_PENDING";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_ERROR = "DELETE_ACTIVITY_ERROR";

const LIST_ACTIVITY_URL = "master_data/activity/find";
const EDIT_ACTIVITY_URL = "master_data/activity/edit";
const DELETE_ACTIVITY_URL = "master_data/activity/delete";
const ADD_ACTIVITY_URL = "master_data/activity/add";

export function listActivity(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(LIST_ACTIVITY_PENDING));
    API.post(LIST_ACTIVITY_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_ACTIVITY_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_ACTIVITY_ERROR, error));
        toastError(error, "Gagal mendapatkan list ACTIVITY, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addActivity(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_ACTIVITY_PENDING));
    API.post(ADD_ACTIVITY_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_ACTIVITY_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan ACTIVITY");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_ACTIVITY_ERROR, error));
        toastError(error, "Gagal menambahkan ACTIVITY, ");
      });
  };
}

export function detailActivity(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_ACTIVITY_PENDING));
    API.post(LIST_ACTIVITY_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_ACTIVITY_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_ACTIVITY_ERROR, error));
        toastError(error, "Gagal mendapatkan list ACTIVITY, ");
      });
  };
}

export function editActivity(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_ACTIVITY_PENDING));
    API.post(EDIT_ACTIVITY_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_ACTIVITY_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah ACTIVITY");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_ACTIVITY_ERROR, error));
        toastError(error, "Gagal mengubah ACTIVITY, ");
      });
  };
}

export function deleteActivity(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_ACTIVITY_PENDING));
    API.post(DELETE_ACTIVITY_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_ACTIVITY_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus ACTIVITY");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_ACTIVITY_ERROR, error));
        toastError(error, "Gagal menghapus ACTIVITY, ");
      });
  };
}
