import {
    MEMBER_FORGOT_PASSWORD_PENDING,
    MEMBER_FORGOT_PASSWORD_SUCCESS,
    MEMBER_FORGOT_PASSWORD_ERROR
} from "../../actions/guests/member_forgot_password_action";

const initialState = {
    pending: false,
    error: null
};

export function memberForgotPassword(
    state = { ...initialState, memberForgotPassword: null },
    action
) {
    switch (action.type) {
        case MEMBER_FORGOT_PASSWORD_PENDING:
            return {
                ...state,
                pending: true,
            };
        case MEMBER_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                pending: false,
                memberForgotPassword: action.data,
            };
        case MEMBER_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getMemberForgotPasswordPending = (state) => state.pending;
export const getMemberForgotPassword = (state) => state.memberForgotPassword;
export const getMemberForgotPasswordError = (state) => state.error;