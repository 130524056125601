export const default_url = {
  admin_kolegium: "dashboard-admin-kolegium",
  ketua_kolegium: "dashboard-admin-kolegium",
  admin_paboi_cabang: "dashboard-admin-cabang",
  ketua_paboi_cabang: "dashboard-admin-cabang",
  root: "dashboard-admin-pusat",
  admin_koperasi: "dashboard-admin-koperasi",
  ketua_paboi_pusat: "dashboard-admin-pusat",
  cpd_cme: "admin-p2kb",
  chief_editor: "journal",
  reviewer: "journal",
  editor: "journal",
  language_editor: "journal",
  ketua_koperasi: "benefit",
  bendahara_koperasi: "benefit",
  ketua_depoi: "admin/support",
  sekretaris_depoi: "admin/support",
  member: "member/member-profile/pengantar",
  ppds: "manajemen-ppds/member-profile"
};
