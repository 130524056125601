import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import IconError from '../../assets/icon-error';
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { listJournal, approvalJournal, revisionJournal } from "../../actions/journal/journal_action";
import {
    getJournalPending,
    getListJournal,
    getJournalError,
    getJournalApproval,
    getJournalApprovalPending,
    getJournalApprovalError,
    getJournalRevision,
    getJournalRevisionPending,
    getJournalRevisionError,
} from "../../reducers/journal/journal_reducer";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/id";
import ModalBerkasJurnal from "./Modal_berkas_jurnal";
import { userList } from "../../actions/master-data/user_action";
import { getUserListPending, getUserList, getUserListError } from "../../reducers/master-data/user_reducer";
import { getUser } from "../../helpers/user";
import {
    renderSelectWithLabel, renderInputWithLabel
} from "../../components/form/FormComponent";
import { reduxForm, Field, FieldArray } from "redux-form";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ModalApproveJournal from "./Modal_approve_journal";
import ModalRejectJournal from "./Modal_reject_journal";
import ModalPublishJournal from "./Modal_publish_journal";
import ModalRevisionApproveJournal from "./Modal_revision_approve_journal";
import ModalRevisionRejectJournal from "./Modal_revision_reject_journal";
import RenderArray from "./Array_form";

class DetailJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalBerkasJurnalShow: false,
            modalApproveJournalShow: false,
            modalRejectJournalShow: false,
            modalPublishJournalShow: false,
            modalRevisionApproveShow: false,
            modalRevisionRejectShow: false,
            isRevision: false,
            isApprove: false,
            checkboxMethods: false,
            checkboxResult: false,
            checkboxDiscussion: false,
            forRefresh: false
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        let param_listJournal = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listJournal.id = id;
        this.props.listJournal(param_listJournal);
    }

    abstrak(id) {
        this.props.history.push(`/journal/detail/abstrak/${id}`)
    }
    revisi(id) {
        this.props.history.push(`/journal/detail/revisi/${id}`)
    }

    handleSubmit(values) {
        let formData = new FormData();
        const { approvalJournal, revisionJournal, match: { params: { id } } } = this.props;
        if (values.editor) {
            formData.append("id", id);
            formData.append("is_approve", "true");
            formData.append("editor_id", values.editor.value);
            formData.append("reviewer_id", values.reviewer.value);
            formData.append("language_editor_id", values.langEditor.value);
            const callback = () => {
                this.handleRefresh();
                this.setState({ isApprove: false });
            };
            approvalJournal(formData, callback);
        }
        if (values.recommendation) {
            formData.append("id", id);
            formData.append("general_remarks", values.generalRemarks);
            formData.append("recommendation", values.recommendation.value);
            formData.append("quality", values.quality.value);
            formData.append("novelty", values.novelty.value);
            formData.append("impact", values.impact.value);
            values.specificRemarks.map(item => formData.append("remarks[]", item));
            if (values.improveMethod) {
                formData.append("method", values.improveMethod);
            }
            if (values.improveResult) {
                formData.append("result", values.improveResult);
            }
            if (values.improveDiscussion) {
                formData.append("discussion", values.improveDiscussion);
            }
            const callback = () => {
                this.handleRefresh();
                this.setState({ isRevision: false });
            };
            revisionJournal(formData, callback);
        }
    }

    componentDidMount() {
        let param_listJournal = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listJournal.id = id;
        this.props.listJournal(param_listJournal);
        this.props.userList({ length: 999 });
    }

    shouldComponentRender() {
        const { listJournal_pending } = this.props;
        if (listJournal_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const { listJournal_data, handleSubmit, userList_data } = this.props;
        const { isRevision, isApprove, checkboxDiscussion, checkboxMethods, checkboxResult } = this.state;
        let data, dataUser, reviewerOptions, editorOptions, langEditorOptions = [];
        let title, status, messageTitle, message, step, role_next_approve, author, publish_date, reject_notes,
            code, date, number, volume, isRoleMatch, role_code_next_approve, id, is_reject_approve, reviewer_id = "";
        let isNumVolNull = true;
        let filename = null;
        const role_code = getUser().role_codes[0];
        if (listJournal_data) {
            data = listJournal_data.data[0];
            id = data.id;
            status = data.status;
            step = data.step;
            title = data.title;
            author = data.author;
            publish_date = data.publish_date;
            code = data.code;
            date = data.date;
            is_reject_approve = data.is_reject_approve;
            number = data.number === null ? "-" : data.number;
            volume = data.volume === null ? "-" : data.volume;
            isNumVolNull = data.number === null ? true : false;
            if (status === 3) {
                role_code_next_approve = "root";
                role_next_approve = "Admin Pusat";
            } else if (status !== 1 && step !== 5) {
                role_code_next_approve = data.role_next_approve.role_code;
                role_next_approve = data.role_next_approve.role_name;
                isRoleMatch = role_code === data.role_next_approve.role_code ? true : false;
            } else if (status === 0 && step === 5) {
                isRoleMatch = false;
                role_next_approve = data.role_next_approve.role_name;
            } else if (status === 1 && step === 6) {
                role_code_next_approve = "root";
                isRoleMatch = true;
                role_next_approve = data.role_next_approve ? data.role_next_approve.role_name : "Chief Editor";
            } else {
                role_next_approve = data.role_next_approve ? data.role_next_approve.role_name : "Chief Editor";
                isRoleMatch = role_code === data.role_next_approve ? true : false;
                role_code_next_approve = data.role_next_approve ? data.role_next_approve.role_code : "root";
            }
            filename = data.filename;
            reviewer_id = data.reviewer_id;
            reject_notes = data.last_reject_notes ? data.last_reject_notes : null;
            messageTitle = status === 0 ? "Menunggu Verifikasi" : status === 1 ? "Disetujui" : status === 2 ? "Ditolak" : status === 3 ? "Publish" : "Revisi";
            message = status === 0 ? "Menunggu Verifikasi " + role_next_approve : status === 1 && step === 6 ? "Telah dilakukan final approval oleh " + role_next_approve : status === 1 ? "Telah disetujui oleh " + role_next_approve : status === 2 ? "Ditolak" : status === 3 ? "Jurnal ini telah terbit" : "Terdapat revisi jurnal oleh " + role_next_approve;
        }
        if (userList_data) {
            dataUser = userList_data.data;
            const reviewer = dataUser.filter(function (item) {
                return (item.role_codes[0] === "reviewer");
            });
            reviewerOptions = reviewer.map((item) => {
                return { value: item.id, label: item.name }
            });
            const editor = dataUser.filter(function (item) {
                return (item.role_codes[0] === "editor");
            });
            editorOptions = editor.map((item) => {
                return { value: item.id, label: item.name }
            });
            const langEditor = dataUser.filter(function (item) {
                return (item.role_codes[0] === "language_editor");
            });
            langEditorOptions = langEditor.map((item) => {
                return { value: item.id, label: item.name }
            });
        }
        const recommendationOptions = [
            {
                label: "Accepted with major revision",
                value: "Accepted with major revision"
            },
            {
                label: "Accepted with minor revision",
                value: "Accepted with minor revision"
            },
        ]
        const qualityOptions = [
            {
                label: "Excellent",
                value: "Excellent"
            },
            {
                label: "Very Good",
                value: "Very Good"
            },
            {
                label: "Good",
                value: "Good"
            },
            {
                label: "Average",
                value: "Average"
            },
        ]
        const impactOptions = [
            {
                label: "High",
                value: "High"
            },
            {
                label: "Moderate",
                value: "Moderate"
            },
            {
                label: "Low",
                value: "Low"
            },
        ]
        const noveltyOptions = [
            {
                label: "High",
                value: "High"
            },
            {
                label: "Moderate",
                value: "Moderate"
            },
            {
                label: "Low",
                value: "Low"
            },
        ]
        return (
            <ContentContainer>
                <Row style={{ minHeight: "78vh" }}>
                    <ModalBerkasJurnal
                        show={this.state.modalBerkasJurnalShow}
                        onHide={() => this.setState({ modalBerkasJurnalShow: false })}
                        imgUrl={filename}
                    />
                    <Row className={(css(styles.leftDetailContainer))}>
                        <Column >
                            <DetailNameComponent name={title} />
                            <DetailRegistrationStatusComponent
                                icon={IconError}
                                status={status}
                                messageTitle={messageTitle}
                                message={message}
                                reason={reject_notes}
                                isWithoutImage={true}
                            />
                            <DetailMenuComponent menuName="Informasi Umum" isActive={true} />
                            <DetailMenuComponent menuName="Abstrak" onClick={() => this.abstrak(data.id)} />
                            {status === -1 && (
                                <DetailMenuComponent
                                    onClick={() => this.revisi(data.id)}
                                    menuName="Detail Revisi"
                                />
                            )}

                        </Column>
                    </Row>
                    <Row className={(css(styles.rightDetailContainer))}>
                        <Column className={(css(styles.rightDetailColumn))}>
                            <ModalApproveJournal
                                show={this.state.modalApproveJournalShow}
                                onHide={() => this.setState({ modalApproveJournalShow: false })}
                                id={id}
                                handleRefresh={this.handleRefresh}
                            />
                            <ModalRejectJournal
                                show={this.state.modalRejectJournalShow}
                                onHide={() => this.setState({ modalRejectJournalShow: false })}
                                id={id}
                                handleRefresh={this.handleRefresh}
                            />
                            <ModalPublishJournal
                                show={this.state.modalPublishJournalShow}
                                onHide={() => this.setState({ modalPublishJournalShow: false })}
                                id={id}
                                handleRefresh={this.handleRefresh}
                            />
                            <ModalRevisionApproveJournal
                                show={this.state.modalRevisionApproveShow}
                                onHide={() => this.setState({ modalRevisionApproveShow: false })}
                                id={id}
                                reviewer_id={reviewer_id}
                                handleRefresh={this.handleRefresh}
                            />
                            <ModalRevisionRejectJournal
                                show={this.state.modalRevisionRejectShow}
                                onHide={() => this.setState({ modalRevisionRejectShow: false })}
                                id={id}
                                reviewer_id={reviewer_id}
                                reviewerOptions={reviewerOptions}
                                editorOptions={editorOptions}
                                step={step}
                                handleRefresh={this.handleRefresh}
                            />
                            {isRevision === false && isApprove === false ?
                                <>
                                    <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                        <DetailTitleComponent title="Informasi Umum" marginBottom={true} />
                                        {isRoleMatch === true && role_code_next_approve !== "chief_editor" && role_code_next_approve !== "root" && status !== -1 && status !== 2 ? <Row>
                                            {(role_code_next_approve === "reviewer" || role_code_next_approve === "editor") &&
                                                <Button variant="contained" color="" startIcon={<ClearIcon />} className={css(styles.rejectButton)} onClick={() => this.setState({ modalRejectJournalShow: true })}>Tolak</Button>
                                            }
                                            <Button variant="contained" color="" startIcon={<ClearIcon />} className={css(styles.revisionButton)} onClick={() => this.setState({ isRevision: true })}>Revisi</Button>
                                            <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalApproveJournalShow: true })}>Setuju</Button>
                                        </Row>
                                            : role_code === "chief_editor" && status === 2 && is_reject_approve === 0 && reviewer_id !== null ?
                                                <Row>
                                                    <Button variant="contained" color="" startIcon={<ClearIcon />} className={css(styles.rejectButton)} onClick={() => this.setState({ modalRevisionRejectShow: true })}>Tolak</Button>
                                                    <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalRevisionApproveShow: true })}>Setuju</Button>
                                                </Row>
                                                : isRoleMatch === true && role_code_next_approve === "chief_editor" && status !== 2 ?
                                                    <Row>
                                                        <Button variant="contained" color="" startIcon={<ClearIcon />} className={css(styles.rejectButton)} onClick={() => this.setState({ modalRejectJournalShow: true })}>Tolak</Button>
                                                        <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ isApprove: true })}>Setuju</Button>
                                                    </Row>
                                                    :
                                                    isRoleMatch === true && role_code_next_approve === "root" && status !== 3 && role_code === "root" ?
                                                        <Row>
                                                            {/* <Button variant="contained" color="" startIcon={<ClearIcon />} className={css(styles.revisionButton)} onClick={() => this.setState({ isRevision: true })}>Revisi</Button> */}
                                                            <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalPublishJournalShow: true })}>Publish</Button>
                                                        </Row>
                                                        : role_code === "chief_editor" && step === 5 && !publish_date &&
                                                        <Row>
                                                            <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalApproveJournalShow: true })}>Approval Final</Button>
                                                        </Row>

                                        }
                                    </Row>
                                    <DetailContentComponent label="Nama Penulis" value={author} />
                                    <DetailContentComponent label="Tanggal Publish" value={publish_date === null ? "-" : moment(publish_date).locale("id", localization).format("DD MMMM YYYY")} />
                                    <DetailContentComponent label="No dan Volume" value={isNumVolNull === true ? "-" : "No. " + number + " & Vol. " + volume} />
                                    <DetailContentComponent label="Kode Jurnal" value={code === null ? "-" : code} />
                                    <DetailContentComponent label="Tanggal Pengajuan" value={moment(date).locale("id", localization).format("DD MMMM YYYY")} />
                                    {filename !== null ? <DetailContentComponent label="Berkas Jurnal" value={<DetailContentLihatBerkas onClick={() => this.setState({ modalBerkasJurnalShow: true })} />} /> : <DetailContentComponent label="Berkas Jurnal" value='-' />}
                                </>
                                : isRevision === false && isApprove === true ?
                                    <>
                                        <form
                                            onSubmit={handleSubmit(this.handleSubmit)}
                                            style={{ width: "100%" }}
                                        >
                                            <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                                <DetailTitleComponent title="Approval" marginBottom={true} />
                                                {isRoleMatch === true && <Row>
                                                    <Button variant="contained" type="submit" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ isApprove: true })}>Setuju</Button>
                                                    <Button variant="contained" color="" className={css(styles.revisionButton)} onClick={() => this.setState({ isApprove: false })}><ClearIcon /></Button>
                                                </Row>}
                                            </Row>
                                            <Column
                                                vertical="center"
                                                horizontal="stretch"
                                                className={css(styles.bodyModal)}
                                            >
                                                <>
                                                    <DetailContentComponent label="Reviewer" value={
                                                        <Field
                                                            name="reviewer"
                                                            placeholder="Pilih Reviewer"
                                                            isClearable={false}
                                                            options={reviewerOptions}
                                                            component={renderSelectWithLabel}
                                                        />
                                                    } />
                                                    <DetailContentComponent label="Editor" value={
                                                        <Field
                                                            name="editor"
                                                            placeholder="Pilih Editor"
                                                            isClearable={false}
                                                            options={editorOptions}
                                                            component={renderSelectWithLabel}
                                                        />
                                                    } />
                                                    <DetailContentComponent label="Language Editor" value={
                                                        <Field
                                                            name="langEditor"
                                                            placeholder="Pilih Language Editor"
                                                            isClearable={false}
                                                            options={langEditorOptions}
                                                            component={renderSelectWithLabel}
                                                        />
                                                    } />
                                                </>
                                            </Column>
                                        </form>
                                    </>
                                    : isRevision === true && isApprove === false ?
                                        <>
                                            <form
                                                onSubmit={handleSubmit(this.handleSubmit)}
                                                style={{ width: "100%" }}
                                            >
                                                <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                                    <DetailTitleComponent title="Revisi" marginBottom={true} />
                                                    {isRoleMatch === true && <Row>
                                                        <Button variant="contained" type="submit" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ isRevision: true })}>Selesai</Button>
                                                        <Button variant="contained" color="" className={css(styles.revisionButton)} onClick={() => this.setState({ isRevision: false })}><ClearIcon /></Button>
                                                    </Row>}
                                                </Row>
                                                <Column
                                                    vertical="center"
                                                    horizontal="stretch"
                                                    className={css(styles.bodyModal)}
                                                >
                                                    <>
                                                        <DetailContentComponent label="General Remarks" value={
                                                            <Field
                                                                name="generalRemarks"
                                                                placeholder="Alasan boleh lebih dari 1"
                                                                isTextarea={true}
                                                                component={renderInputWithLabel}
                                                            />
                                                        } />
                                                        <DetailContentComponent label="Recommendation" value={
                                                            <Field
                                                                name="recommendation"
                                                                placeholder="Pilih Rekomendasi"
                                                                isClearable={false}
                                                                options={recommendationOptions}
                                                                component={renderSelectWithLabel}
                                                            />
                                                        } />
                                                        <DetailContentComponent label="Quality" value={
                                                            <Field
                                                                name="quality"
                                                                placeholder="Pilih Kualitas"
                                                                isClearable={false}
                                                                options={qualityOptions}
                                                                component={renderSelectWithLabel}
                                                            />
                                                        } />
                                                        <DetailContentComponent label="Impact for knowledge transfer" value={
                                                            <Field
                                                                name="impact"
                                                                placeholder="Pilih Impact"
                                                                isClearable={false}
                                                                options={impactOptions}
                                                                component={renderSelectWithLabel}
                                                            />
                                                        } />
                                                        <DetailContentComponent label="Novelty or originality" value={
                                                            <Field
                                                                name="novelty"
                                                                placeholder="Pilih Novelty or originality"
                                                                isClearable={false}
                                                                options={noveltyOptions}
                                                                component={renderSelectWithLabel}
                                                            />
                                                        } />
                                                        <DetailContentComponent label="Specific Remarks" value={
                                                            <Row>
                                                                <Column style={{ width: "100%", marginTop: 10 }}>
                                                                    <FieldArray
                                                                        name="specificRemarks"
                                                                        component={RenderArray}
                                                                    />
                                                                </Column>
                                                            </Row>
                                                        } />
                                                        <DetailContentComponent label="Things to Improve" value={
                                                            <FormControl component="fieldset" style={{ width: "100%" }}>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={checkboxMethods} onChange={() => this.setState({ checkboxMethods: !checkboxMethods })} name="methods" />}
                                                                        label="Methods"
                                                                    />
                                                                    {checkboxMethods &&
                                                                        <Field
                                                                            name="improveMethod"
                                                                            placeholder="Things to improve"
                                                                            isTextarea={true}
                                                                            component={renderInputWithLabel}
                                                                        />
                                                                    }
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={checkboxResult} onChange={() => this.setState({ checkboxResult: !checkboxResult })} name="results" />}
                                                                        label="Results"
                                                                    />
                                                                    {checkboxResult &&
                                                                        <Field
                                                                            name="improveResult"
                                                                            placeholder="Things to improve"
                                                                            isTextarea={true}
                                                                            component={renderInputWithLabel}
                                                                        />
                                                                    }
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={checkboxDiscussion} onChange={() => this.setState({ checkboxDiscussion: !checkboxDiscussion })} name="discussion" />}
                                                                        label="Discussion"
                                                                    />
                                                                    {checkboxDiscussion &&
                                                                        <Field
                                                                            name="improveDiscussion"
                                                                            placeholder="Things to improve"
                                                                            isTextarea={true}
                                                                            component={renderInputWithLabel}
                                                                        />
                                                                    }
                                                                </FormGroup>
                                                            </FormControl>
                                                        } />
                                                    </>
                                                </Column>
                                            </form>
                                        </>
                                        :
                                        <>
                                        </>
                            }
                        </Column>
                    </Row>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40
    },
    rightDetailContainer: {
        padding: 40,
        width: "70%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        marginLeft: 10,
        padding: 10
    },
    revisionButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginLeft: 10,
        color: "#495057",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#ED2A2A 0% 0% no-repeat padding-box",
        marginLeft: 10,
        color: "#FFFFFF",
        boxShadow: "none",
    },
});

function validate(values) {
    const { reviewer, editor, langEditor, generalRemarks, recommendation, quality, impact, novelty,
        specificRemarks, improveMethod, improveResult, improveDiscussion } = values;
    const errors = {}
    if (!reviewer) {
        errors.reviewer = "Reviewer wajib diisi"
    }
    if (!editor) {
        errors.editor = "Editor wajib diisi"
    }
    if (!langEditor) {
        errors.langEditor = "Language Editor wajib diisi"
    }
    if (!generalRemarks) {
        errors.generalRemarks = "General Remarks wajib diisi"
    }
    if (!recommendation) {
        errors.recommendation = "Recommendation wajib diisi"
    }
    if (!quality) {
        errors.quality = "Quality wajib diisi"
    }
    if (!impact) {
        errors.impact = "Impact wajib diisi"
    }
    if (!novelty) {
        errors.novelty = "Novelty wajib diisi"
    }
    const specificRemarksArrayErrors = [];
    if (!specificRemarks || !specificRemarks.length) {
        errors.specificRemarks = { _error: "Specific Remarks wajib diisi" };
    } else {
        specificRemarks.forEach((item, index) => {
            if (!item) {
                specificRemarksArrayErrors[index] = "Specific Remarks wajib diisi"
            }
        });
    }
    if (specificRemarksArrayErrors.length) {
        errors.specificRemarks = specificRemarksArrayErrors;
    }
    if (!improveMethod) {
        errors.improveMethod = "Method wajib diisi"
    }
    if (!improveResult) {
        errors.improveResult = "Results wajib diisi"
    }
    if (!improveDiscussion) {
        errors.improveDiscussion = "Discussion wajib diisi"
    }
    return errors;
}

DetailJournal = reduxForm({
    form: "DetailJournal",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(DetailJournal)

function mapStateToProps({ listJournal, userList, approvalJournal, revisionJournal }) {
    return {
        listJournal_pending: getJournalPending(listJournal),
        listJournal_data: getListJournal(listJournal),
        listJournal_error: getJournalError(listJournal),
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
        approvalJournal_pending: getJournalApprovalPending(approvalJournal),
        approvalJournal_data: getJournalApproval(approvalJournal),
        approvalJournal_error: getJournalApprovalError(approvalJournal),
        revisionJournal_pending: getJournalRevisionPending(revisionJournal),
        revisionJournal_data: getJournalRevision(revisionJournal),
        revisionJournal_error: getJournalRevisionError(revisionJournal),
    };
}


export default connect(mapStateToProps, { listJournal, userList, approvalJournal, revisionJournal })(withRouter(DetailJournal));
