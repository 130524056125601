import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError
} from "../../reducers/member/member_reducer";
import { changeImageProfile } from "../../actions/master-data/user_action";
import {
  getChangeImageProfilePending,
  getChangeImageProfile,
  getChangeImageProfileError
} from "../../reducers/master-data/user_reducer";
import DetailMemberPhoto from "../../components/detail/DetailMemberPhoto";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { renderFileForm } from "../../components/form/FormComponent";
import Loading from "../../components/global-components/LoadingComponent";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import { Form, Button, Container } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import { withThemeCreator } from "@material-ui/styles";

class ModalEditBioMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      srcImg: null,
      isDoneUploading: true,
      crop: {
        aspect: 1 / 1
      },
      uploadButton: false,
      hasCropped: false,
      uploadMessage: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.dataURIToBlob = this.dataURIToBlob.bind(this);
    this.upload = this.upload.bind(this);
  }

  dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  upload(base64Image) {
    const { changeImageProfile, handleRefresh } = this.props;
    const e = this.dataURIToBlob(base64Image);
    if (e !== null && e) {
      this.setState({ isDoneUploading: false });
      this.setState({ uploadButton: true });
      this.setState({ uploadMessage: <Loading /> });
      let formData = new FormData();
      const callback = () => {
        this.setState({ isDoneUploading: true });
        this.setState({ uploadButton: false });
        this.setState({ uploadMessage: <Loading done={true} /> });
        setTimeout(() => handleRefresh(), 2000);
      };
      this.setState({ file: URL.createObjectURL(e) });
      //formData.append("blob",this.state.result, 'Photo')
      formData.append("file", e);
      changeImageProfile(formData, callback);
    }
  }

  handleSubmit(values) {
    // const { userChangePassword, onHide } = this.props;
    // const param_userChangePassword = {
    //     password: values.newpass,
    //     confirmation_password: values.newpassConf
    // }
    // const callback = () => {
    //     onHide();
    //     this.props.history.push('/login')
    // }
    // userChangePassword(param_userChangePassword, callback);
  }

  getCroppedImg() {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = this.state.image.naturalWidth / this.state.image.width;
      const scaleY = this.state.image.naturalHeight / this.state.image.height;
      canvas.width = this.state.crop.width;
      canvas.height = this.state.crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        this.state.image,
        this.state.crop.x * scaleX,
        this.state.crop.y * scaleY,
        this.state.crop.width * scaleX,
        this.state.crop.height * scaleY,
        0,
        0,
        this.state.crop.width,
        this.state.crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      this.setState({
        result: base64Image
      });
      this.upload(base64Image);
    } catch (e) {
      console.log(e, "crop the image");
    }
  }

  render() {
    const { handleSubmit, show, onHide, id, name, status, img_profile } =
      this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        id={id}
        name={name}
        status={status}
        img_profile={img_profile}
        dialogClassName="modal-width-change-status"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit Foto Profil</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              {this.state.srcImg && (
                <>
                  <Row className={css(styles.photoComponent)}>
                    <ReactCrop
                      //style={{ minWidth: "100%" }}
                      src={this.state.srcImg}
                      onImageLoaded={(e) => {
                        this.setState({ image: e });
                      }}
                      circularCrop="true"
                      crop={this.state.crop}
                      onChange={(event) => {
                        if (event.height > 0){
                          this.setState({
                            hasCropped: true,
                            crop: event
                          });
                        }
                      }}
                    />
                  </Row>
                  <br></br>
                  <div style={{ textAlign: "center" }}>
                    Klik foto dan geser area yang akan ditampilkan
                  </div>
                  <br></br>
                </>
              )}
              {!this.state.srcImg && (
                <Row className={css(styles.photoComponent)}>
                  {img_profile === null && this.state.file === null ? (
                    <DetailMemberPhoto width="150px" height="150px" />
                  ) : this.state.file === null && img_profile !== null ? (
                    <DetailMemberPhoto
                      filename={img_profile}
                      folder="user_profile"
                      source="api"
                      width="150px"
                      height="150px"
                    />
                  ) : (
                    <DetailMemberPhoto
                      filename={this.state.file}
                      source="local"
                      width="150px"
                      height="150px"
                    />
                  )}
                </Row>
              )}

              <Field
                name="memberPhoto"
                type="file"
                fileType="image" //image or pdf
                title="Pilih Foto"
                disabled={this.state.uploadButton}
                isDone={this.state.isDoneUploading}
                message={
                  !this.props.formValue
                    ? "PNG atau JPG maksimal 2 MB"
                    : this.state.uploadMessage
                }
                onChange={(event) => {
                  this.setState({
                    srcImg: URL.createObjectURL(event.target.files[0]),
                    rawImg: event.target.files[0]
                  });
                }}
                component={renderFileForm}
              />
              {this.state.hasCropped && (
                <Row
                  horizontal="space-between"
                  vertical="center"
                  horizontal="space-between"
                  style={{ color: "white" }}
                >
                  <Button
                    variant="contained"
                    style={{ minWidth: "140px" }}
                    color="primary"
                    className={css(styles.approveButton)}
                    style={{ minWidth: "140px" }}
                    onClick={this.getCroppedImg}
                  >
                    SIMPAN
                  </Button>
                </Row>
              )}
            </Column>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    color: "white"
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20
  },
  bodyModalFalse: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40
  },
  photoComponent: {
    justifyContent: "center",
    marginBottom: 20
  }
});

function validate(values) {
  const { name, subspesialis } = values;
  const errors = {};
  if (!name) {
    errors.name = "Nama tidak boleh kosong";
  }
  if (!subspesialis) {
    errors.subspesialis = "Subspesialis tidak boleh kosong";
  }
  return errors;
}

ModalEditBioMember = reduxForm({
  form: "ModalEditBioMember",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true
})(ModalEditBioMember);

function mapStateToProps(state) {
  const { memberList, changeImageProfile } = state;
  const selector = formValueSelector("ModalEditBioMember");
  const formValue = selector(state, "memberPhoto");
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    changeImageProfile_pending:
      getChangeImageProfilePending(changeImageProfile),
    changeImageProfile_data: getChangeImageProfile(changeImageProfile),
    changeImageProfile_error: getChangeImageProfileError(changeImageProfile),
    formValue
  };
}

export default connect(mapStateToProps, { memberList, changeImageProfile })(
  ModalEditBioMember
);
