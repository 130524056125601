import React, { Component } from "react";
import { connect } from "react-redux";
import { listAdvertisement } from "../../actions/advertisement/advertisement_action";
import {
    getListAdvertisement,
    getListAdvertisementError,
    getListAdvertisementPending,
} from "../../reducers/advertisement/advertisement_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import moment from 'moment';
import localization from 'moment/locale/id';
import HeaderAdvertisement from "./AdvertisementHeader";
import ModalEditAdvertisement from "./Modal_edit_advertisement";
import ModalDeleteAdvertisement from "./Modal_delete_advertisement";

class Advertisement extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            id: "",
            search_text: "",
            modalEditAdvertisementShow: false,
            modalDeleteAdvertisementShow: false,
            dataToBeEdited: null
        };

        this.handleRefresh = this.handleRefresh.bind(this);
        this.detailRecord = this.detailRecord.bind(this);
        this.editAdvertisement = this.editAdvertisement.bind(this);
        this.deleteAdvertisement = this.deleteAdvertisement.bind(this);
    }

    onchange(e) {
        this.setState({ input: e.target.value });
    }

    detailRecord(id) {
        this.props.history.push(`/iklan/${id}`);
    }

    editAdvertisement(data) {
        this.setState({ dataToBeEdited: data });
        this.setState({ modalEditAdvertisementShow: true });
    }

    deleteAdvertisement(data) {
        this.setState({ id: data.id });
        this.setState({ modalDeleteAdvertisementShow: true });
    }

    handleRefresh(state) {
        if (state) {
            this.setState(state);
        }
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    render() {
        const { listAdvertisement, listAdvertisement_data } = this.props;
        const { search_text } = this.state;
        let data = [];
        let records_total = 0;
        if (listAdvertisement_data) {
            data = listAdvertisement_data.data;
            records_total = listAdvertisement_data.records_total;
        }
        return (
            <ContentContainer>
                <ModalEditAdvertisement
                    show={this.state.modalEditAdvertisementShow}
                    onHide={() => this.setState({ modalEditAdvertisementShow: false })}
                    handleRefresh={this.handleRefresh}
                    editData={this.state.dataToBeEdited}
                />
                <ModalDeleteAdvertisement
                    show={this.state.modalDeleteAdvertisementShow}
                    onHide={() => this.setState({ modalDeleteAdvertisementShow: false })}
                    handleRefresh={this.handleRefresh}
                    id={this.state.id}
                />
                <HeaderAdvertisement
                    handleRefresh={this.handleRefresh}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={[
                        {
                            title: "No",
                            field: "no",
                            width: 20,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            title: "Periode",
                            render: (data) => {
                                return moment(data.start_period).locale('id', localization).format('DD MMMM YYYY') + " - " + moment(data.end_period).locale('id', localization).format('DD MMMM YYYY')
                            }
                        },
                        {
                            title: "Nama Instansi",
                            field: "instance_name",
                        },
                        {
                            title: "Judul Iklan",
                            field: "title",
                        },
                        // {
                        //     title: "Status",
                        //     field: "year",
                        // },
                        {
                            title: "",
                            render: (data) => {
                                return (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.detailRecord(data.id)}>
                                            Detail
                                        </MenuItem>
                                        <MenuItem onClick={() => this.editAdvertisement(data)}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => this.deleteAdvertisement(data)}>
                                            Hapus
                                        </MenuItem>
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: search_text
                            };
                            listAdvertisement(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={data.length}
                                records_total={records_total}
                                {...props}
                            />
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listAdvertisement }) {
    return {
        listAdvertisement_data: getListAdvertisement(listAdvertisement),
        listAdvertisement_error: getListAdvertisementError(listAdvertisement),
        listAdvertisement_pending: getListAdvertisementPending(listAdvertisement),
    };
}

export default connect(mapStateToProps, {
    listAdvertisement,
})(Advertisement);
