import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { universityList, deleteUniversity } from "../../actions/master-data/university_action";
import { getUniversityListPending, getUniversityList, getUniversityListError, getUniversityDelete } from "../../reducers/master-data/university_reducer";

class ModalHapusUniversitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            university_name: ""
        };
    }

    deleteUniversitas() {
        const { deleteUniversity, onHide } = this.props;
        const param_deleteUniversitas = {
            id: this.props.data.data[0].id,
            code: this.state.university_code,
            name: this.state.university_name,
            abbreviation: this.state.university_abbreviation,
            city_id: this.state.university_city_value,
        }

        const callback = () => {
            onHide();
            this.props.handleRefresh({ search: ""})
        }
        deleteUniversity(param_deleteUniversitas, callback);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.setState({ university_name: this.props.data.data[0].name })
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                cityOption={this.props.listCityOption}
                id={this.props.id}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Hapus Universitas
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menghapus {this.state.university_name} ?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={this.props.onHide}
                        >
                            Tidak
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.deleteUniversitas()}
                        >
                            Ya
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ deleteUniversity, universityList }) {
    return {
        deleteUniversitas: getUniversityDelete(deleteUniversity),
        universityList_pending: getUniversityListPending(universityList),
        universityList_data: getUniversityList(universityList),
        universityList_error: getUniversityListError(universityList),
    };
}


export default connect(mapStateToProps, { deleteUniversity, universityList })(ModalHapusUniversitas);