import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import {
  listSubspesialis,
  detailSubspesialis,
} from "../../actions/master-data/subspesialis_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { provinceList } from "../../actions/master-data/province_action";

import ModalSubspesialisAdd from "./ModalSubspesialisAdd";
import ModalSubspesialisDelete from "./ModalSubspesialisDelete";
import ModalSubspesialisEdit from "./ModalSubspesialisEdit";

import {
  getSubspesialisPending,
  getListSubspesialis,
  getSubspesialisError,
} from "../../reducers/master-data/subspesialis_reducer";
import HeaderSubspesialis from "./HeaderSubspesialis";

class Subspesialis extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenModalAdd(id) {
    this.setState({ id, modalAddShow: true });
  }

  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleOpenModalEdit(id, isEdit = false) {
    const { detailSubspesialis } = this.props;
    detailSubspesialis(id);
    this.setState({ id, isEdit });
    this.setState({ modalEditShow: true });
  }

  render() {
    let data = [];
    let records_total = 0;
    const { listSubspesialis, listSubspesialis_data } = this.props;
    if (listSubspesialis_data) {
      data = listSubspesialis_data.data;
      records_total = listSubspesialis_data.records_total;
    }
    const {
      id,
      isEdit,
      modalEditShow,
      modalDeleteShow,
      modalAddShow,
    } = this.state;
    return (
      <ContentContainer>
        <ModalSubspesialisAdd
          id={id}
          show={modalAddShow}
          onHide={() => this.setState({ modalAddShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalSubspesialisDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalSubspesialisEdit
          id={id}
          show={modalEditShow}
          onHide={() => this.setState({ modalEditShow: false })}
          handleRefresh={this.handleRefresh}
          isEdit={isEdit}
        />
        <HeaderSubspesialis
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama Subspesialis",
              field: "name",
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() => this.handleOpenModalEdit(rowData.id)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleOpenModalDelete(rowData.id)}
                    >
                      Delete
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                class_type: this.state.class_type,
                province_id: this.state.province_id,
              };
              listSubspesialis(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSubspesialis }) {
  return {
    listSubspesialis_data: getListSubspesialis(listSubspesialis),
    listSubspesialis_error: getSubspesialisError(listSubspesialis),
    listSubspesialis_pending: getSubspesialisPending(listSubspesialis),
  };
}

export default connect(mapStateToProps, {
  listSubspesialis,
  detailSubspesialis,
  provinceList,
})(Subspesialis);
