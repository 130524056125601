import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { userList, editUser } from "../../actions/master-data/user_action";
import {
  getUserListPending,
  getUserList,
  getUserListError,
  getUserEdit,
  getUserEditPending,
  getUserEditError,
} from "../../reducers/master-data/user_reducer";
import { roleList } from "../../actions/master-data/role_action";
import {
  getRoleListPending,
  getRoleList,
  getRoleListError,
} from "../../reducers/master-data/role_reducer";
import { branchList } from "../../actions/master-data/branch_action";
import {
  getBranchListPending,
  getBranchList,
  getBranchListError,
} from "../../reducers/master-data/branch_reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  renderInputWithLabel,
  renderSelectWithLabel,
} from "../../components/form/FormComponent";

class modalEditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_role: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { editUser, onHide, handleRefresh, data, reset } = this.props;
    const { name, email, role, role_branch } = values;
    let param = {
      id: data.data[0].id,
      name,
      email,
      role_code: role.value,
      npa_idi: values.npa_idi,
    };
    if (
      role.value === "ketua_paboi_cabang" ||
      role.value === "admin_paboi_cabang"
    ) {
      param = {
        ...param,
        branch_id: role_branch.value,
      };
    }
    const callback = () => {
      onHide();
      handleRefresh();
      reset();
    };
    editUser(param, callback);
  }

  componentDidMount() {
    this.props.roleList({ length: 999 });
    this.props.branchList();
  }

  render() {
    const {
      roleList_data,
      handleSubmit,
      show,
      onHide,
      editUserPending,
      branchList_data,
      formValue: role,
    } = this.props;
    let roleOption = [];
    let branchOption = [];
    if (roleList_data) {
      let data = roleList_data.data;
      let dataWithoutMember = data.filter(
        (data) => data.role_code !== "member"
      );
      dataWithoutMember.map((value) => {
        const role = { value: value.role_code, label: value.role_name };
        roleOption = [...roleOption, role];
        return role;
      });
    }
    if (branchList_data) {
      let data = branchList_data;
      data.map((value) => {
        const branch = { value: value.id, label: value.name };
        branchOption = [...branchOption, branch];
        return branch;
      });
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit Pengguna</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              <Column>
                <>
                  <Field
                    name="name"
                    placeholder="Masukkan nama"
                    label="Nama"
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Masukkan email"
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="npa_idi"
                    label="NPA IDI"
                    placeholder="Masukkan NPA IDI"
                    component={renderInputWithLabel}
                  />
                  {role && role.value === "member" && (
                    <>
                      <Field
                        name="role"
                        label="Role"
                        isDisabled={true}
                        component={renderSelectWithLabel}
                      />
                    </>
                  )}
                  {role && role.value !== "member" && (
                    <>
                      <Field
                        name="role"
                        label="Role"
                        placeholder="Pilih role"
                        options={roleOption}
                        component={renderSelectWithLabel}
                      />
                    </>
                  )}
                  {role &&
                    (role.value === "ketua_paboi_cabang" ||
                      role.value === "admin_paboi_cabang") && (
                      <>
                        <Field
                          name="role_branch"
                          placeholder="Pilih cabang"
                          label="Cabang"
                          options={branchOption}
                          component={renderSelectWithLabel}
                        />
                      </>
                    )}
                </>
              </Column>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editUserPending}
                className={css(styles.approveButton)}
                startIcon={
                  editUserPending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function validate(values) {
  const { name, email, role, role_branch, npa_idi } = values;
  const errors = {};
  if (!name) {
    errors.name = "Nama wajib diisi";
  }
  if (!email) {
    errors.email = "Email wajib diisi";
  }
  if (!role) {
    errors.role = "Role wajib diisi";
  } else {
    if (role === "ketua_paboi_cabang") {
      if (!role_branch) {
        errors.role_branch = "Cabang wajib diisi";
      }
    }
  }

  if (role != undefined) {
    if (
      !npa_idi &&
      role.value !== "root" &&
      role.value !== "admin_kolegium" &&
      role.value !== "admin_paboi_cabang" &&
      role.value !== "sekretaris_depoi" &&
      role.value !== "admin_koperasi"
    ) {
      errors.npa_idi = "NPA IDI wajib diisi";
    }
  }

  // if (!npa_idi) {
  //   errors.npa_idi = "NPA IDI wajib diisi";
  // }
  return errors;
}

modalEditUser = reduxForm({
  form: "modalEditUserForm",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(modalEditUser);

function mapStateToProps(state) {
  const { editUser, userList, roleList, branchList } = state;
  const selector = formValueSelector("modalEditUserForm");
  const formValue = selector(state, "role");
  let initialValues = {};
  const userList_data = getUserList(userList);
  if (userList_data && userList_data.data.length > 0) {
    initialValues = {
      name: userList_data.data[0].name,
      email: userList_data.data[0].email,
      npa_idi: userList_data.data[0].npa_idi
        ? userList_data.data[0].npa_idi
        : null,
      role: {
        value: userList_data.data[0].role_codes[0],
        label: userList_data.data[0].role_names[0],
      },
    };
    if (
      userList_data.data[0].role_codes[0] === "ketua_paboi_cabang" ||
      userList_data.data[0].role_codes[0] === "admin_paboi_cabang"
    ) {
      initialValues = {
        ...initialValues,
        role_branch: {
          value: userList_data.data[0].branch_data?.id,
          label: userList_data.data[0].branch_data?.name,
        },
      };
    }
  }
  return {
    editUser: getUserEdit(editUser),
    editUserPending: getUserEditPending(editUser),
    editUserError: getUserEditError(editUser),
    userList_pending: getUserListPending(userList),
    userList_data: getUserList(userList),
    userList_error: getUserListError(userList),
    roleList_pending: getRoleListPending(roleList),
    roleList_data: getRoleList(roleList),
    roleList_error: getRoleListError(roleList),
    branchList_pending: getBranchListPending(branchList),
    branchList_data: getBranchList(branchList),
    branchList_error: getBranchListError(branchList),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  editUser,
  userList,
  roleList,
  branchList,
})(modalEditUser);
