import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import ModalApprovePPDS from "./ModalApprovePPDS";
import ModalRejectPPDS from "./ModalRejectPPDS";
import FormContainer from "../../components/container/FormContainer";
import { detailPpds } from "../../actions/member/ppds_action";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import {
  getPpdsPending,
  getDetailPpds,
  getPpdsError,
} from "../../reducers/member/ppds_reducer";
import { connect } from "react-redux";

class DetailPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = { modalApproveShow: false, modalRejectShow: false };
  }

  render() {
    const { modalApproveShow, modalRejectShow } = this.state;
    const { detailPpds_data } = this.props;
    let result = {
      kolegium_no: null,
      npa_idi: null,
      abbreviation: null,
      status_ppds: null,
      status_graduated: null,
      exam_date: null,
      graduated_date: null,
      young_member_id: null,
    };
    if (detailPpds_data && detailPpds_data.data.length !== 0) {
      const data = detailPpds_data.data[0];
      result.kolegium_no = data.kolegium_no;
      result.npa_idi = data.npa_idi;
      result.young_member_id = data.young_member_id;
      result.abbreviation =
        data.ppds_destination_university_data[0].university_data.abbreviation +
        "/" +
        data.ppds_destination_university_data[0].period_selection_month +
        "/" +
        data.ppds_destination_university_data[0].period_selection_year;
      result.status_ppds = data.status_ppds;
      result.status_graduated = data.status_graduated;
      result.exam_date = data.exam_date;
      result.graduated_date = data.graduated_date;
      result.id = data.id;
    }

    const {
      id,
      kolegium_no,
      npa_idi,
      abbreviation,
      status_ppds,
      status_graduated,
      graduated_date,
      young_member_id,
    } = result;

    return (
      <>
        <ModalApprovePPDS
          id={id}
          show={modalApproveShow}
          onHide={() => this.setState({ modalApproveShow: false })}
        />
        <ModalRejectPPDS
          id={id}
          show={modalRejectShow}
          onHide={() => this.setState({ modalRejectShow: false })}
        />

        <DetailNameComponent name="Informasi PPDS" />
        <DetailContentComponent
          label="No Kolegium"
          value={kolegium_no ? kolegium_no : "-"}
        />
        <DetailContentComponent
          label="ID Anggota Muda"
          value={young_member_id ? young_member_id : "-"}
        />
        <DetailContentComponent label="FK PPDS" value={abbreviation} />
        <DetailContentComponent
          label="Status PPDS"
          value={status_ppds === 1 ? "Aktif" : "Nonaktif"}
        />
        {status_graduated !== 0 ? (
          <>
            <DetailContentComponent
              label="Status Kelulusan"
              value={
                status_graduated === 1
                  ? "Lulus"
                  : status_graduated === 2
                  ? "Keluar"
                  : ""
              }
            />
          </>
        ) : (
          <FormContainer label="Status Kelulusan">
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              onClick={() => this.setState({ modalApproveShow: true })}
            >
              LULUS
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              onClick={() => this.setState({ modalRejectShow: true })}
            >
              KELUAR
            </Button>
          </FormContainer>
        )}
        <DetailContentComponent
          label="Tanggal Lulus"
          value={graduated_date ? graduated_date.slice(0, 10) : "-"}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
});

function mapStateToProps({ detailPpds }) {
  return {
    detailPpds_data: getDetailPpds(detailPpds),
    detailPpds_error: getPpdsError(detailPpds),
    detailPpds_pending: getPpdsPending(detailPpds),
  };
}

export default connect(mapStateToProps, { detailPpds })(withRouter(DetailPPDS));
