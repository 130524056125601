import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import ContentContainer from "../../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../../components/p2kb/HeaderDetailP2kbComponent";
import { StyleSheet, css } from "aphrodite";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addP2kb, setSelectedSKP } from "../../../actions/p2kb/p2kb_action";
import { getP2kbAddPending } from "../../../reducers/p2kb/p2kb_reducer";
import { detailActivity } from "../../../actions/master-data/activity_action";
import { getDetailActivity } from "../../../reducers/master-data/activity_reducer";
import moment from "moment";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import KajianIlmiahModalAdd from "./KajianIlmiahModalAdd";
import KajianIlmiahModalEdit from "./KajianIlmiahModalEdit";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../../components/global-components/DetailButtonComponent";
import IconDocumentP2KB from "../../../assets/icon-document-p2kb";
import IconLaunch from "../../../assets/icon-launch";
import PaginationComponent from "../../../components/table/PaginationComponent";
import ModalDocument from "../../../components/modal/ModalDocument";
class AddDetailSKP_PelayananMedis extends Component {
  constructor() {
    super();
    this.state = {
      showDocument: false,
      showModalAdd: false,
      showModalEdit: false,
      filename: "",
      modalTitle: "",
      skp_total: 0,
      p2kb_details: [],
      columns: [
        {
          title: "No",
          field: "no",
          width: 20,
          headerStyle: {
            paddingLeft: 40,
          },
          cellStyle: {
            paddingLeft: 40,
          },
        },

        {
          title: "Tanggal Kegiatan",
          render: ({ activity_date }) =>
            moment(activity_date).format("DD MMMM YYYY"),
        },
        { title: "Tema Kegiatan", field: "theme" },
        { title: "Tingkat", field: "last_activity.label" },
        { title: "status", field: "values_id.label" },
        { title: "Jumlah SKP", field: "skp_total" },
        {
          title: "Document",
          render: (data) => {
            return (
              <DetailButtonComponent
                button={(props) => (
                  <Row
                    onClick={props.onClick}
                    className={css(styles.buttonDocHover)}
                  >
                    <IconDocumentP2KB fill="Black" />
                  </Row>
                )}
              >
                <MenuItem
                  onClick={() =>
                    this.handleOpenDocument(data.berkas, "Daftar Hadir")
                  }
                >
                  <IconLaunch />
                  <span style={{ marginLeft: 10, paddingTop: 2 }}>
                    Daftar Hadir
                  </span>
                </MenuItem>
              </DetailButtonComponent>
            );
          },
        },
        {
          render: (data) => {
            return (
              <DetailButtonComponent>
                <MenuItem onClick={() => this.handleShowModalEdit(data)}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => this.handleDeleteSkp(data)}>
                  Hapus
                </MenuItem>
              </DetailButtonComponent>
            );
          },
        },
      ],
      data: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddSkp = this.handleAddSkp.bind(this);
    this.handleEditSkp = this.handleEditSkp.bind(this);
    this.handleShowModalEdit = this.handleShowModalEdit.bind(this);
    this.handleDeleteSkp = this.handleDeleteSkp.bind(this);
  }
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      detailActivity,
    } = this.props;
    detailActivity(id);
  }

  handleAddSkp(values) {
    let result = {
      ...values,
      no: this.state.data.length + 1,
    };
    this.setState(({ data }) => ({
      data: [...data, result],
    }));
  }

  handleShowModalEdit(data) {
    this.props.setSelectedSKP(data);
    this.setState({ showModalEdit: true });
  }

  handleEditSkp(values) {
    const result = this.state.data.map((item) => {
      if (values.no === item.no) {
        return values;
      }
      return item;
    });
    this.setState({ data: result });
  }

  handleDeleteSkp(values) {
    const result = this.state.data
      .filter((item) => values.no !== item.no)
      .map((item, i) => ({ ...item, no: i + 1 }));
    this.setState({ data: result });
  }

  handleSubmit() {
    const { data } = this.state;
    const {
      detailActivity_data: [{ id }],
    } = this.props;
    const p2kb_details = data.map((item) => ({
      activity_date: item.activity_date,
      last_activity_skp_detail_id: item.last_activity.value,
      values_id: item.values_id.value,
      theme: item.theme,
      attachments: [
        {
          label: "Daftar Hadir & Portofolio",
          filename: item.berkas,
        },
      ],
    }));

    const param = {
      activity_id: id,
      p2kb_details,
    };

    const callback = () => {
      this.props.history.push(`/skp`);
    };
    this.props.addP2kb(param, callback);
  }

  handleOpenDocument(filename, modalTitle) {
    this.setState({
      filename: filename,
      modalTitle: modalTitle,
      showDocument: true,
    });
  }
  render() {
    const { detailActivity_data, addP2kb_pending } = this.props;
    const { showDocument, filename, modalTitle } = this.state;

    let activity_name, realm_activity_name, activity_type;
    if (detailActivity_data && detailActivity_data.lenght !== 0) {
      [
        {
          name: activity_name,
          activity_type,
          realm_activity: { name: realm_activity_name },
        },
      ] = detailActivity_data;
    }
    let skp_total = 0;
    if (this.state.data.length !== 0) {
      skp_total = this.state.data
        .map(({ skp_total }) => skp_total)
        .reduce(function (a, b) {
          return a + b;
        });
    }
    return (
      <ContentContainer>
        <ModalDocument
          show={showDocument}
          onHide={() => this.setState({ showDocument: false })}
          filepath="member_p2kb"
          filename={filename}
          modalTitle={modalTitle}
        />
        <KajianIlmiahModalAdd
          show={this.state.showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleAddSkp={this.handleAddSkp}
        />
        <KajianIlmiahModalEdit
          show={this.state.showModalEdit}
          onHide={() => this.setState({ showModalEdit: false })}
          handleEditSkp={this.handleEditSkp}
        />
        <HeaderDetailP2kbComponent
          activity_name={activity_name}
          activity_type={activity_type}
          realm_activity={realm_activity_name}
          skp={skp_total}
          isAddDetail={true}
        />
        <Column>
          <div className={css(styles.submitButtonContainer)}>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              onClick={() => this.setState({ showModalAdd: true })}
              startIcon={<AddIcon />}
            >
              Tambah
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              onClick={this.handleSubmit}
              startIcon={
                addP2kb_pending ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              Simpan
            </Button>
          </div>
          <MaterialTable
            isLoading={false}
            columns={this.state.columns}
            data={this.state.data}
            options={{
              paginationType: "stepped",
              pageSize: 10,
              pageSizeOptions: [],
              showTitle: false,
              search: false,
              headerStyle: {
                backgroundColor: "#F8F9FB",
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
              Container: (props) => <Paper {...props} elevation={0} />,
              Pagination: (props) => (
                <PaginationComponent
                  records={this.state.data.length}
                  records_total={this.state.data.length}
                  {...props}
                />
              ),
            }}
          />
        </Column>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
  columnNo: {
    width: "5%",
    padding: 10,
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 20,
  },
  columnDate: {
    width: "20%",
    padding: 10,
    marginBottom: 15,
    marginTop: 20,
  },
  columnPasienSIPSKP: {
    width: "15%",
    padding: 10,
    marginBottom: 15,
    marginTop: 20,
  },
  columnDocument: {
    width: "25%",
    padding: 10,
    marginBottom: 15,
    marginTop: 20,
  },
  submitButtonContainer: {
    padding: 20,
    textAlign: "left",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginRight: 10,
  },
  rejectButton: {
    background: "#EAEBED",
    marginLeft: 10,
    marginRight: 20,
    textAlign: "center",
    color: "#495057",
    boxShadow: "none",
  },
});

function mapStateToProps({ detailActivity, addP2kb }) {
  return {
    addP2kb_pending: getP2kbAddPending(addP2kb),
    detailActivity_data: getDetailActivity(detailActivity),
  };
}

export default connect(mapStateToProps, {
  addP2kb,
  setSelectedSKP,
  detailActivity,
})(withRouter(AddDetailSKP_PelayananMedis));
