import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_SERKOM_ADMIN_PENDING = "LIST_SERKOM_ADMIN_PENDING";
export const LIST_SERKOM_ADMIN_SUCCESS = "LIST_SERKOM_ADMIN_SUCCESS";
export const LIST_SERKOM_ADMIN_ERROR = "LIST_SERKOM_ADMIN_ERROR";
export const EDIT_SERKOM_ADMIN_PENDING = "EDIT_SERKOM_ADMIN_PENDING";
export const EDIT_SERKOM_ADMIN_SUCCESS = "EDIT_SERKOM_ADMIN_SUCCESS";
export const EDIT_SERKOM_ADMIN_ERROR = "EDIT_SERKOM_ADMIN_ERROR";
export const ADD_SERKOM_ADMIN_PENDING = "ADD_SERKOM_ADMIN_PENDING";
export const ADD_SERKOM_ADMIN_SUCCESS = "ADD_SERKOM_ADMIN_SUCCESS";
export const ADD_SERKOM_ADMIN_ERROR = "ADD_SERKOM_ADMIN_ERROR";
export const DETAIL_SERKOM_ADMIN_PENDING = "DETAIL_SERKOM_ADMIN_PENDING";
export const DETAIL_SERKOM_ADMIN_SUCCESS = "DETAIL_SERKOM_ADMIN_SUCCESS";
export const DETAIL_SERKOM_ADMIN_ERROR = "DETAIL_SERKOM_ADMIN_ERROR";
export const DELETE_SERKOM_ADMIN_PENDING = "DELETE_SERKOM_ADMIN_PENDING";
export const DELETE_SERKOM_ADMIN_SUCCESS = "DELETE_SERKOM_ADMIN_SUCCESS";
export const DELETE_SERKOM_ADMIN_ERROR = "DELETE_SERKOM_ADMIN_ERROR";
export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_SERKOM_ADMIN";

export const APPROVE_SERKOM_ADMIN_PENDING = "APPROVE_SERKOM_ADMIN_PENDING";
export const APPROVE_SERKOM_ADMIN_SUCCESS = "APPROVE_SERKOM_ADMIN_SUCCESS";
export const APPROVE_SERKOM_ADMIN_ERROR = "APPROVE_SERKOM_ADMIN_ERROR";

const LIST_SERKOM_ADMIN_URL = "serkom/find";
const EDIT_SERKOM_ADMIN_URL = "serkom/edit";
const ADD_SERKOM_ADMIN_URL = "serkom/add";
const DELETE_SERKOM_ADMIN_URL = "serkom/delete";
const UPLOAD_SERKOM_ADMIN_URL = "image/upload/member_serkom";
const APPROVE_SERKOM_ADMIN_URL = "serkom/approve";

export function listSerkomAdmin(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SERKOM_ADMIN_PENDING));
    API.post(LIST_SERKOM_ADMIN_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SERKOM_ADMIN_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal mendapatkan list SERKOM, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function editSerkomAdmin({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SERKOM_ADMIN_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_SERKOM_ADMIN_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_SERKOM_ADMIN_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_SERKOM_ADMIN_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah SERKOM");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal mengubah SERKOM, ");
      });
  };
}

// export function addSerkomAdmin({ file, ...restParam }, callback) {
//   return (dispatch) => {
//     dispatch(actionPending(ADD_SERKOM_ADMIN_PENDING));
//     var formData = new FormData();
//     formData.append("file", file);
//     API.post(UPLOAD_SERKOM_ADMIN_URL, formData, getHeader())
//       .then((res) => {
//         return res.data.filename;
//       })
//       .then((filename) => {
//         restParam.filename = filename;
//         return API.post(ADD_SERKOM_ADMIN_URL, restParam, getHeader());
//       })
//       .then((res) => {
//         dispatch(actionSuccess(ADD_SERKOM_ADMIN_SUCCESS, res.data.data));
//         toastSuccess("Berhasil menambahkan pelatihan");
//         if (callback) {
//           callback();
//         }
//         return res;
//       })
//       .catch((error) => {
//         dispatch(actionError(ADD_SERKOM_ADMIN_ERROR, error));
//         toastError(error, "Gagal menambahkan pelatihan, ");
//       });
//   };
// }

export function addSerkomAdmin(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SERKOM_ADMIN_PENDING));
    API.post(ADD_SERKOM_ADMIN_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_SERKOM_ADMIN_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan SERKOM");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal menambahkan SERKOM, ");
      });
  };
}

export function deleteSerkomAdmin(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_SERKOM_ADMIN_PENDING));
    API.post(DELETE_SERKOM_ADMIN_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_SERKOM_ADMIN_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus SERKOM");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal menghapus SERKOM, ");
      });
  };
}

export function detailSerkomAdmin(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SERKOM_ADMIN_PENDING));
    API.post(LIST_SERKOM_ADMIN_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SERKOM_ADMIN_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal mendapatkan list SERKOM, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveSerkomAdmin(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_SERKOM_ADMIN_PENDING));
    API.post(APPROVE_SERKOM_ADMIN_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_SERKOM_ADMIN_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status SERKOM");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_SERKOM_ADMIN_ERROR, error));
        toastError(error, "Gagal mengubah status SERKOM, ");
      });
  };
}
