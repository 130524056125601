import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import CardMember from "./CardMember";

import {
  listTotalMember,
} from "../../actions/dashboard/pusat_action";

import {
  getDashboardData,
} from "../../reducers/dashboard/pusat_reducer";
class CardMemberPusat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.listTotalMember();
  }

  render() {
    const { listTotalMember_data } = this.props;

    let member_active = 0,
      member_non_active = 0,
      member_pension = 0,
      total_member = 0,
      member_departed = 0;
    if (listTotalMember_data && listTotalMember_data.length !== 0) {
      [
        {
          member_active,
          member_non_active,
          member_pension,
          total_member,
          member_departed,
        },
      ] = listTotalMember_data;
    }

    return (
      <Column style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: 10 }}>
          <CardMember
            img_url="member-green.png"
            member_total={`${total_member}`}
            card_label="Total Member"
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <CardMember
            img_url="member-blue.png"
            member_total={`${member_active}`}
            card_label="Member Aktif"
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <CardMember
            img_url="member-yellow.png"
            member_total={`${member_non_active}`}
            card_label="Member Non-aktif"
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <CardMember
            img_url="member-red.png"
            member_total={`${member_pension}`}
            card_label="Retired Member"
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <CardMember
            img_url="image-abu.png"
            member_total={`${member_departed}`}
            card_label="Departed Member"
          />
        </div>
      </Column>
    );
  }
}

function mapStateToProps({ listTotalMember_pusat, listSupport }) {
  return {
    listTotalMember_data: getDashboardData(listTotalMember_pusat),
  };
}

export default connect(mapStateToProps, {
  listTotalMember,
})(CardMemberPusat);
