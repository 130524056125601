import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const BRANCH_LIST_PENDING = "BRANCH_LIST_PENDING";
export const BRANCH_LIST_SUCCESS = "BRANCH_LIST_PENDING_SUCCESS";
export const BRANCH_LIST_ERROR = "BRANCH_LIST_PENDING_ERROR";

export const LIST_DISTRIBUTION_PENDING = "DISTRIBUTION_LIST_PENDING";
export const LIST_DISTRIBUTION_SUCCESS = "DISTRIBUTION_LIST_PENDING_SUCCESS";
export const LIST_DISTRIBUTION_ERROR = "DISTRIBUTION_LIST_PENDING_ERROR";


export const LIST_BRANCH_PENDING = "LIST_BRANCH_PENDING";
export const LIST_BRANCH_SUCCESS = "LIST_BRANCH_SUCCESS";
export const LIST_BRANCH_ERROR = "LIST_BRANCH_ERROR";

export const DETAIL_BRANCH_PENDING = "DETAIL_BRANCH_PENDING";
export const DETAIL_BRANCH_SUCCESS = "DETAIL_BRANCH_SUCCESS";
export const DETAIL_BRANCH_ERROR = "DETAIL_BRANCH_ERROR";

export const ADD_BRANCH_PENDING = "ADD_BRANCH_PENDING";
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS";
export const ADD_BRANCH_ERROR = "ADD_BRANCH_ERROR";

export const EDIT_BRANCH_PENDING = "EDIT_BRANCH_PENDING";
export const EDIT_BRANCH_SUCCESS = "EDIT_BRANCH_SUCCESS";
export const EDIT_BRANCH_ERROR = "EDIT_BRANCH_ERROR";

export const DELETE_BRANCH_PENDING = "DELETE_BRANCH_PENDING";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_ERROR = "DELETE_BRANCH_ERROR";

const BRANCH_LIST_URL = "master_data/branch/find";
const LIST_BRANCH_URL = "master_data/branch/find";
const LIST_DISTRIBUTION_URL = "dashboard/admin/member_distribution";
const EDIT_BRANCH_URL = "master_data/branch/edit";
const DELETE_BRANCH_URL = "master_data/branch/delete";
const ADD_BRANCH_URL = "master_data/branch/add";

export function branchList(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(BRANCH_LIST_PENDING));
    API.post(BRANCH_LIST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        let data = res.data.data;
        dispatch(actionSuccess(BRANCH_LIST_SUCCESS, data));
      })
      .catch((error) => {
        dispatch(actionError(BRANCH_LIST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function listBranch(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_BRANCH_PENDING));
    API.post(LIST_BRANCH_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_BRANCH_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_BRANCH_ERROR, error));
        toastError(error, "Gagal mendapatkan list cabang, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function listMemberDistribution(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_DISTRIBUTION_PENDING));
    API.post(LIST_DISTRIBUTION_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_DISTRIBUTION_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_DISTRIBUTION_ERROR, error));
        toastError(error, "Gagal mendapatkan list distribusi member, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addBranch(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_BRANCH_PENDING));
    API.post(ADD_BRANCH_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_BRANCH_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan cabang");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_BRANCH_ERROR, error));
        toastError(error, "Gagal menambahkan cabang, ");
      });
  };
}

export function detailBranch(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_BRANCH_PENDING));
    API.post(LIST_BRANCH_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_BRANCH_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_BRANCH_ERROR, error));
        toastError(error, "Gagal mendapatkan list cabang, ");
      });
  };
}

export function editBranch(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_BRANCH_PENDING));
    API.post(EDIT_BRANCH_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_BRANCH_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah cabang");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_BRANCH_ERROR, error));
        toastError(error, "Gagal mengubah cabang, ");
      });
  };
}

export function deleteBranch(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_BRANCH_PENDING));
    API.post(DELETE_BRANCH_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_BRANCH_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus cabang");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_BRANCH_ERROR, error));
        toastError(error, "Gagal menghapus cabang, ");
      });
  };
}
