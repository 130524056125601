import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalP2kb } from "../../actions/p2kb/p2kb_action";
import { getP2kbApproval, getP2kbApprovalPending, getP2kbApprovalError } from "../../reducers/p2kb/p2kb_reducer";
import { connect } from "react-redux";

class ModalAcceptSKP extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    p2kbApproval(id) {
        const { approvalP2kb, onHide } = this.props;
        const param_approval = {
            id: id,
            is_approve: "true"
        }
        const callback = () => {
            onHide();
            this.props.handleRefresh({ forRefresh: true })
        }
        approvalP2kb(param_approval, callback)
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width"
                // centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Konfirmasi
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menyetujuinya?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={() => this.setState({ modalShow: false })}
                        >
                            BATAL
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.p2kbApproval(this.props.id)}
                        >
                            YA
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ approvalP2kb }) {
    return {
        approvalP2kb_pending: getP2kbApprovalPending(approvalP2kb),
        approvalP2kb_data: getP2kbApproval(approvalP2kb),
        approvalP2kb_error: getP2kbApprovalError(approvalP2kb),
    };
}


export default connect(mapStateToProps, { approvalP2kb })(ModalAcceptSKP);