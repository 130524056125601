import React, { Component } from "react";
import { detailSupport } from "../../actions/support/support_action";
import { withRouter } from "react-router-dom";
import {
  getSupportPending,
  getSupportError,
  getDetailSupport,
  getSupportIsEditFile,
} from "../../reducers/support/support_reducer";
import { connect } from "react-redux";
import moment from "moment";
import DetailContentComponent from "../../components/detail/DetailContentComponent";

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
    };
    this.tableRef = React.createRef();
  }

  createMarkup(html) {
    return { __html: html };
  }

  // componentDidMount() {
  //   const {
  //     match: {
  //       params: { id },
  //     },
  //     detailSupport,
  //   } = this.props;
  //   detailSupport(id);
  // }
  render() {
    const { detailSupport_data } = this.props;
    let name, email, primary_phone, date;

    if (detailSupport_data && detailSupport_data.length !== 0) {
      [
        {
          member_data: {
            name,
            email,
            date,
            member_profile: { primary_phone },
          },
        },
      ] = detailSupport_data;
    }

    return (
      <>
        <DetailContentComponent
          label="Tanggal Pengajuan"
          value={moment(date).format("DD MMMM YYYY")}
        />
        <DetailContentComponent label="Nama Lengkap" value={name} />
        <DetailContentComponent label="No ID PABOI" value={email} />
        <DetailContentComponent label="No Telp" value={primary_phone} />
        <DetailContentComponent label="Email" value={email} />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { editSupport, detailSupport } = state;

  return {
    editSupport_error: getSupportError(editSupport),
    editSupport_pending: getSupportPending(editSupport),
    detailSupport_pending: getSupportPending(detailSupport),
    detailSupport_data: getDetailSupport(detailSupport),
    editSupport_isEditFile: getSupportIsEditFile(editSupport),
  };
}

export default connect(mapStateToProps, {
  detailSupport,
})(withRouter(SupportDetail));
