import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { default_api } from "../../constants/default_api";

class DetailRejectModal extends Component {
  render() {
    const { show, onHide, reason, filename, filepath } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Alasan Penolakan</p>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            <div className={css(styles.reason)}>{reason}</div>
            <div
              style={{
                fontSize: 18,
                fontWeight: 600,
                margin: "10px 0 20px",
              }}
            >
              Screenshot Kesalahan
            </div>
            {filename ? (
              <>
                <img
                  src={`${default_api}/image/show/${filepath}/${filename}`}
                  alt="Screenshot Kesalahan"
                  className={css(styles.imgBuktiPembayaran)}
                />
              </>
            ) : (
              "-"
            )}
          </Column>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  imgBuktiPembayaran: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
  reason: {
    opacity: 0.7,
    marginTop: 20,
    marginBottom: 10,
  },
});

export default DetailRejectModal;
