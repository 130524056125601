import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    }
});

class ModalBranchVerification extends Component {
    constructor(props) {
        super(props);
        this.rejectBranch = this.rejectBranch.bind(this);
        this.approveBranch = this.approveBranch.bind(this);
    }

    rejectBranch() {
        const { onHide } = this.props;
        this.props.onBranchChange(false);
        onHide();
    }

    approveBranch() {
        const { onHide } = this.props;
        this.props.onBranchChange(true);
        onHide();
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    dialogClassName="modal-width"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                    </Modal.Header>
                    <Modal.Body>
                        <Column className={css(styles.bodyModal)}>
                            <span>Apakah cabang yang dipilih sesuai dengan cabang member? </span>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.rejectButton)}
                                startIcon={<ClearIcon />}
                                onClick={this.rejectBranch}
                            >
                                TIDAK
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={<CheckIcon />}
                                onClick={this.approveBranch}
                            >
                                YA
                            </Button>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {})(ModalBranchVerification);