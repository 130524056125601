import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { getUser } from "../../../helpers/user";
import { memberList } from "../../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../../reducers/member/member_reducer";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
  getP2kbEditDetailPending,
  getP2kbEditDetail,
  getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
// import moment from 'moment';
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import { listHospital } from "../../../actions/master-data/hospital_action";
import {
  getListHospital,
  getHospitalPending,
  getHospitalError,
} from "../../../reducers/master-data/hospital_reducer";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_PelayananMedis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_sip: null,
      berkas_pasien: null,
      berkas_sip_button: false,
      berkas_pasien_button: false,
      isDoneUploading: true,
      skp: "",
      imgUrl: "",
      modal_title: "",
      selected_activity: "",
      spek_berkas_sip: null,
      spek_berkas_pasien: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePasien = this.handleChangePasien.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true });
    } else {
      this.setState({ modalDetailDokumenShow: true });
    }
  }

  handleChangePasien(values) {
    let listActivity_selected;
    if (this.props.formValue.isDetail) {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.props.formValue.activity_id
      );
    } else {
      listActivity_selected = this.props.listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
    }
    this.setState({ selected_activity: listActivity_selected.childrens[0].id });
    let childrens_selected = listActivity_selected.childrens[0].values.find(
      (item) => item.id === values.value
    );
    let skp_must_be_multipied = childrens_selected.skp_must_be_multipied;
    let value_multipied = childrens_selected.value_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", childrens_selected.value_skp);
    } else {
      this.props.change(
        "skp_total",
        +(childrens_selected.value_skp * value_multipied).toFixed(2)
      );
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    // if (formName === "berkas_sip") {
    //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
    //     this.props.change("berkas_sip", "dvorak");
    // } else if (formName === "berkas_pasien") {
    //     this.setState({ berkas_pasien: "Berkas Medis berhasil diunggah" })
    //     this.props.change("berkas_pasien", "qwerty");
    // }
    // if (values.target.files[0].size < 2000000) {
    this.setState({ isDoneUploading: false });
    this.setState({ berkas_pasien_button: true });
    this.setState({ berkas_sip_button: true });
    if (formName === "berkas_sip") {
      this.setState({ berkas_sip: <Loading /> });
    } else if (formName === "berkas_pasien") {
      this.setState({ berkas_pasien: <Loading /> });
    }
    const callback = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_sip_button: false });
      this.setState({ berkas_pasien_button: false });
      if (this.props.uploadP2kb_data) {
        if (formName === "berkas_sip") {
          this.props.change("berkas_sip", this.props.uploadP2kb_data);
          this.setState({ berkas_sip: <Loading done={true} /> });
          setTimeout(
            () =>
              this.setState({
                berkas_sip: (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        this.props.formValue.berkas_sip,
                        "SIP"
                      )
                    }
                  />
                ),
              }),
            2000
          );
          this.props.change("spek_berkas_sip", values.target.files[0]);
        } else if (formName === "berkas_pasien") {
          this.props.change("berkas_pasien", this.props.uploadP2kb_data);
          this.setState({ berkas_pasien: <Loading done={true} /> });
          setTimeout(
            () =>
              this.setState({
                berkas_pasien: (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        this.props.formValue.berkas_pasien,
                        "Daftar Pasien"
                      )
                    }
                  />
                ),
              }),
            3000
          );
          this.props.change("spek_berkas_pasien", values.target.files[0]);
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true });
      this.setState({ berkas_sip_button: false });
      this.setState({ berkas_pasien_button: false });
      if (this.props.uploadP2kb_error) {
        if (formName === "berkas_sip") {
          this.props.change("berkas_sip", null);
          this.setState({ berkas_sip: <Loading done={true} failed={true} /> });
        } else if (formName === "berkas_pasien") {
          this.props.change("berkas_pasien", null);
          this.setState({
            berkas_pasien: <Loading done={true} failed={true} />,
          });
        }
      }
    };
    this.props.uploadP2kb(values.target.files[0], callback, error);
    // } else {
    //     if (formName === "berkas_sip") {
    //         this.setState({ berkas_sip: "Batas maksimal ukuran file 2 MB!" })
    //     } else if (formName === "berkas_pasien") {
    //         this.setState({ berkas_pasien: "Batas maksimal ukuran file 2 MB!" })
    //     }
    // }
  }

  handleSubmit(values) {
    const {
      handleEditDetail,
      onHide,
      handleRefresh,
      editDetailP2kb,
      formValue,
    } = this.props;
    if (values.status === undefined || values.status === 0) {
      let details_tobesent = {
        activity_date: values.activity_date,
        sip_no: values.sip_no,
        hasEdit: true,
        practice_name: values.practice_name.label,
        last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
        values_id: values.values_id.value,
        attachments: [
          {
            label: "SIP",
            filename: values.berkas_sip,
          },
          {
            label: "Daftar Pasien",
            filename: values.berkas_pasien,
          },
        ],
      };
      let details_tobeshown = {
        ...details_tobesent,
        skp: +formValue.skp_total.toFixed(2),
        values_label: values.values_id.label,
        no: formValue.no,
      };
      handleEditDetail(details_tobeshown, details_tobesent);
      onHide();
    } else {
      const param = {
        id: values.id,
        activity_date: values.activity_date,
        sip_no: values.sip_no,
        practice_name: values.practice_name.label,
        last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
        values_id: values.values_id.value,
        attachments: [
          {
            label: "SIP",
            filename: values.berkas_sip,
          },
          {
            label: "Daftar Pasien",
            filename: values.berkas_pasien,
          },
        ],
      };
      const callback = () => {
        onHide();
        handleRefresh();
      };
      editDetailP2kb(param, callback);
    }
  }

  updateState() {
    const {
      berkas_sip,
      berkas_pasien,
      values_id,
      last_activity_skp_detail_id,
    } = this.props.formValue;
    if (this.state.values_id === "") {
      if (berkas_sip) {
        this.setState({ selected_activity: last_activity_skp_detail_id });
        this.setState({ values_id: values_id.value });
        this.setState({
          berkas_sip: (
            <DetailContentLihatBerkas
              onClick={() => this.handleOpenDocument(berkas_sip, "SIP")}
            />
          ),
        });
        this.setState({
          berkas_pasien: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(berkas_pasien, "Berkas Pasien")
              }
            />
          ),
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateState();
  }

  componentDidMount() {
    this.props.listActivity({ length: 9999 });
    this.props.listHospital();
    this.props.memberList({ id: getUser().id });
  }

  render() {
    const {
      show,
      onHide,
      listActivity_data,
      handleSubmit,
      uploadP2kb_pending,
      listHospital_data,
      memberList_data,
    } = this.props;
    let hospitalOptions = [];
    let pasienOptions = [];
    // if (listHospital_data) {
    //     hospitalOptions = listHospital_data.data.map((item) => ({
    //         value: item.id,
    //         label: item.name
    //     }));
    // }
    if (
      memberList_data &&
      memberList_data.data[0].member_practice_place_data.length > 0
    ) {
      let member_data = memberList_data.data[0].member_practice_place_data;
      hospitalOptions = member_data.map((item) => ({
        value: item.hospital_id,
        label: item.hospital_data.name,
      }));
    } else {
      if (listHospital_data) {
        hospitalOptions = listHospital_data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
    }
    const activity_id_skp =
      this.state.tempSKPData === null
        ? this.state.skp_detail.activity_id
        : this.state.tempSKPData.activity.value;
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === activity_id_skp
      );
      if (listActivity_selected) {
        pasienOptions = listActivity_selected.childrens[0].values.map(
          (item) => ({
            label: item.label,
            value: item.id,
          })
        );
      }
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="practice_name"
                  placeholder="Masukkan Tempat Praktek"
                  label="Tempat Praktek"
                  isAsync
                  asyncUrl="master_data/hospital/find"
                  options={hospitalOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="sip_no"
                  placeholder="Masukkan Nomor SIP/ Surat Tugas/ Surat Penunjukkan"
                  label="Nomor SIP/ Surat Tugas/ Surat Penunjukkan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="values_id"
                  placeholder="Pilih Jumlah Pasien"
                  label="Jumlah Pasien"
                  options={pasienOptions}
                  onChange={this.handleChangePasien}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas_sip"
                  type="file"
                  fileType="skp"
                  title="Berkas SIP"
                  disabled={this.state.berkas_sip_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas_sip === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_sip === null
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_sip
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
                <Field
                  name="berkas_pasien"
                  type="file"
                  fileType="skp"
                  title="Daftar Pasien"
                  disabled={this.state.berkas_pasien_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas_pasien === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_pasien === null
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_pasien
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/;
  const fileType = "application/pdf";
  const {
    activity_date,
    sip_no,
    total_for_calculate_skp,
    berkas_sip,
    berkas_pasien,
    spek_berkas_sip,
    spek_berkas_pasien,
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!sip_no) {
    errors.sip_no = "Nomor SIP/ Surat Tugas/ Surat Penunjukkan wajib diisi";
  }
  if (!total_for_calculate_skp) {
    errors.total_for_calculate_skp = "Jumlah pasien wajib diisi";
  }
  if (!berkas_sip) {
    errors.berkas_sip = "Berkas SIP wajib diisi";
  } else {
    if (
      spek_berkas_sip &&
      !spek_berkas_sip.type.match(typeImage) &&
      spek_berkas_sip.type !== fileType
    ) {
      errors.berkas_sip = "Format file hanya bisa Image atau PDF";
    } else if (spek_berkas_sip && spek_berkas_sip.size > maxFileSize) {
      errors.berkas_sip = "File lebih dari 1 MB";
    }
  }
  if (!berkas_pasien) {
    errors.berkas_pasien = "Berkas medis wajib diisi";
  } else {
    if (
      spek_berkas_pasien &&
      !spek_berkas_pasien.type.match(typeImage) &&
      spek_berkas_pasien.type !== fileType
    ) {
      errors.berkas_pasien = "Format file hanya bisa Image atau PDF";
    } else if (spek_berkas_pasien && spek_berkas_pasien.size > maxFileSize) {
      errors.berkas_pasien = "File lebih dari 1 MB";
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_PelayananMedis"));

ModalEditDetailSKP_PelayananMedis = reduxForm({
  form: "ModalEditDetailSKP_PelayananMedis",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalEditDetailSKP_PelayananMedis);

function mapStateToProps(state) {
  const {
    listActivity,
    uploadP2kb,
    listHospital,
    selectedSKP,
    editDetailP2kb,
    memberList,
  } = state;
  const selector = formValueSelector("ModalEditDetailSKP_PelayananMedis");
  const formValue = selector(
    state,
    "total_for_calculate_skp",
    "berkas_sip",
    "berkas_pasien",
    "skp_total",
    "activity_date",
    "values_id",
    "last_activity_skp_detail_id",
    "no",
    "isDetail",
    "activity_id",
    "spek_berkas_sip",
    "spek_berkas_pasien"
  );
  let initialValues = {};
  if (selectedSKP) {
    if (selectedSKP.isEditMedis) {
      if (!selectedSKP.isDetail) {
        initialValues = {
          activity_date: selectedSKP.activity_date,
          practice_name: {
            value: selectedSKP.practice_name,
            label: selectedSKP.practice_name,
          },
          sip_no: selectedSKP.sip_no,
          values_id: {
            value: selectedSKP.values_id,
            label: selectedSKP.values_label,
          },
          last_activity_skp_detail_id: {
            label: selectedSKP.mst_activity_skp_type_detail_data.name,
            value: selectedSKP.mst_activity_skp_type_detail_data.id,
          },
          skp_total: selectedSKP.skp,
          berkas_sip: selectedSKP.attachments[0].filename,
          berkas_pasien: selectedSKP.attachments[1].filename,
          no: selectedSKP.no,
        };
      } else {
        initialValues = {
          isDetail: true,
          activity_id:
            selectedSKP.mst_activity_skp_type_detail_data.activity_id,
          id: selectedSKP.id,
          last_activity_skp_detail_id: {
            label: selectedSKP.mst_activity_skp_type_detail_data.name,
            value: selectedSKP.mst_activity_skp_type_detail_data.id,
          },
          status: selectedSKP.status,
          no: selectedSKP.no,
          activity_date: selectedSKP.activity_date,
          practice_name: {
            value: selectedSKP.practice_name,
            label: selectedSKP.practice_name,
          },
          sip_no: selectedSKP.sip_no,
          values_id: {
            value: selectedSKP.values_id,
            label: selectedSKP.activity_skp_type_value_data
              ? selectedSKP.activity_skp_type_value_data.label
              : "",
          },
          skp_total: selectedSKP.total_skp,
          berkas_sip: selectedSKP.attachments[0].filename,
          berkas_pasien: selectedSKP.attachments[1].filename,
        };
      }
    }
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
    editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
    editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listHospital_error: getHospitalError(listHospital),
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  listActivity,
  uploadP2kb,
  listHospital,
  editDetailP2kb,
  memberList,
})(withRouter(ModalEditDetailSKP_PelayananMedis));
