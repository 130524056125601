import React from "react";
import { StyleSheet, css } from "aphrodite";
import ArrowIcon from "../../assets/icon-arrow-forward";

export default (props) => {
    const { detail_url } = props;
    return (
        <div className={css(styles.detailWrapper)}>
            <a href={detail_url} className={css(styles.detailButton)}>Lihat Semua <ArrowIcon fill="#4D4D4D" /></a>
        </div>
    )
};

const styles = StyleSheet.create({
    detailWrapper: {
        width: 130,
        marginTop: 20,
        marginBottom: 20
    },
    detailButton: {
        background: "#F5F5F5 0% 0% no-repeat padding-box",
        borderRadius: 15,
        padding: "10px 15px",
        textDecoration: "none",
        color: "#4D4D4D",
    },
});