import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { addMutation } from "../../actions/mutation/mutation_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import {
  renderInputWithLabel,
  renderSelectWithLabel,
  renderDatepickerWithLabel2,
} from "../../components/form/FormComponent";
import { getMutationPending } from "../../reducers/mutation/mutation_reducer";
import { getUser } from "../../helpers/user";
import { getDetailMember } from "../../reducers/member/member_reducer";
import moment from "moment";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import FileValidation from "../../components/form/FileValidation";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import { listSip } from "../../actions/p2kb/sip_action";
import { listHospital } from "../../actions/master-data/hospital_action";
import { getListSip } from "../../reducers/p2kb/sip_reducer";
import { listSerkom } from "../../actions/serkom/serkom_action";
import { getListSerkom } from "../../reducers/serkom/serkom_reducer";
import {
  memberList,
  memberP2kbExisting,
} from "../../actions/member/member_action";
import {
  getMemberList,
  getMemberP2kbExistingPending,
  getMemberP2kbExisting,
} from "../../reducers/member/member_reducer";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import { default_api } from "../../constants/default_api";

class ModalMutationAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: "",
      modal_title: "",
      folder: "",
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpenDocument = (imgUrl, modal_title, folder = "") => {
    if (modal_title === "CV") {
      window.open(default_api + "/member/download/cv/" + imgUrl + ".pdf");
    } else {
      this.setState({ folder: folder });
      this.setState({ imgUrl: imgUrl });
      this.setState({ modal_title: modal_title });
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true });
      } else {
        this.setState({ modalDocumentImgShow: true });
      }
    }
  };

  componentDidMount() {
    this.props.memberP2kbExisting({ id: getUser().id });
    this.props.memberList({ id: getUser().id });
    this.props.listHospital();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addMutation, onHide, handleRefresh, memberP2kbExisting_data } =
      this.props;
    const {
      new_address,
      hospital_id_to,
      berkas,
      file,
      branch_to: { value: branch_to },
    } = values;
    const param = {
      new_address,
      branch_to,
      berkas,
      type_address: 1,
      attachments: values.file
        ? file.map((item) => ({
            attachment_name: item.name,
            attachment_filename: item.value,
          }))
        : "",
    };

    if (memberP2kbExisting_data.member_serkom !== null) {
      param.serkom_no = memberP2kbExisting_data.member_serkom.sk_no;
    }

    if (memberP2kbExisting_data.member_str !== null) {
      param.str_no = memberP2kbExisting_data.member_str.str_no;
    }

    if (memberP2kbExisting_data.member_sip !== null) {
      param.sip_no = memberP2kbExisting_data.member_sip.sip_no;
    }

    if (hospital_id_to) {
      param.hospital_id_to = hospital_id_to.value;
    }

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addMutation(param, callback);
  }

  isValidMoreThanSixMonth = (date) => {
    const sixMonthsAfterCurrentDate = moment(new Date()).add(6, "months");
    return moment(date).isSameOrAfter(sixMonthsAfterCurrentDate);
  };

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  };

  render() {
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder,
    } = this.state;
    const {
      handleSubmit,
      show,
      onHide,
      change,
      addMutation_pending,
      listHospital_data,
      listBranch_data,
      initialValues,
      memberP2kbExisting_data,
      memberList_data,
    } = this.props;
    let hospitalOptions = [],
      branchOptions = [];
    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data
        .filter((item) => item.name !== initialValues.branch_from)
        .map(({ name, id }) => {
          return { label: name, value: id };
        });
    }

    let hasSIP,
      hasSTR,
      hasSerkom,
      hasKTP = false;
    const hasCV = true;
    const CVfilename = getUser().id;
    let SIPfilename,
      STRfilename,
      SerkomFilename,
      KTPfilename = null;
    if (getUser().role_codes[0] === "member") {
      change("memberID", getUser().member_profile.paboi_id);
    }
    if (memberList_data) {
      const data = memberList_data.data[0];
      KTPfilename = data.member_profile.scan_ktp_image;
      hasKTP = KTPfilename !== null ? true : false;
      change(
        "branch_from",
        data.branch_data ? data.branch_data.name : "Belum ada cabang"
      );
    }
    if (memberP2kbExisting_data) {
      const data = memberP2kbExisting_data;
      if (data.member_str !== null) {
        hasSTR = true;
        STRfilename = data.member_str.filename;
      }
      if (data.member_sip !== null) {
        hasSIP = true;
        SIPfilename = data.member_sip.filename;
      }
      if (data.member_serkom !== null) {
        hasSerkom = true;
        SerkomFilename = data.member_serkom.filename;
      }
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Pengajuan Mutasi Anggota
          </p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <Field
                name="date"
                isDetail
                placeholder="Masukkan tanggal pengajuan"
                label="Tanggal Pengajuan"
                component={renderDatepickerWithLabel2}
              />
              <Field
                name="memberID"
                label="No ID Member"
                disable={true}
                component={renderInputWithLabel}
              />
              <Field
                name="branch_from"
                disable
                placeholder="Pilih cabang asal"
                label="Cabang Asal"
                component={renderInputWithLabel}
              />
              <Field
                name="branch_to"
                placeholder="Pilih cabang tujuan"
                label="Cabang Tujuan"
                options={branchOptions}
                component={renderSelectWithLabel}
              />
              <Field
                name="hospital_id_to"
                placeholder="Pilih tempat praktek tujuan"
                label="Tempat Praktek Tujuan"
                options={hospitalOptions}
                isAsync
                asyncUrl="master_data/hospital/find"
                component={renderSelectWithLabel}
              />
              <Field
                name="new_address"
                placeholder="Masukkan Alamat baru"
                label="Alamat Rumah Baru"
                component={renderInputWithLabel}
              />
              <div style={{ marginBottom: 15, marginTop: 15 }}>
                <label className={css(styles.labelMargin)}>
                  Surat Permohonan Tertulis
                </label>
                <Field
                  name="berkas"
                  label="Berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </div>
              <span style={{ marginBottom: 10 }}>Berkas</span>
              <FileValidation
                label="KTP"
                hasFile={hasKTP}
                openFile={() =>
                  this.handleOpenDocument(
                    KTPfilename,
                    "KTP",
                    "member_supporting_files"
                  )
                }
              />
              <FileValidation
                label="CV"
                hasFile={hasCV}
                openFile={() => this.handleOpenDocument(CVfilename, "CV")}
              />
              <FileValidation
                label="STR"
                hasFile={hasSTR}
                openFile={() =>
                  this.handleOpenDocument(STRfilename, "STR", "member_str")
                }
              />
              {hasSIP ? (
                <FileValidation
                  label="SIP"
                  hasFile={hasSIP}
                  openFile={() =>
                    this.handleOpenDocument(SIPfilename, "SIP", "member_sip")
                  }
                />
              ) : (
                <label className={css(styles.labelMargin)}>SIP</label>
              )}
              {hasSerkom ? (
                <FileValidation
                  label="Serkom"
                  hasFile={hasSerkom}
                  openFile={() =>
                    this.handleOpenDocument(
                      SerkomFilename,
                      "Serkom",
                      "member_serkom"
                    )
                  }
                />
              ) : (
                <label className={css(styles.labelMargin)}>Serkom</label>
              )}
              <FieldArray
                name="file"
                component={DynamicFormComponent}
                changeFileValue={this.changeFileValue}
                folderPath="member_branch_mutation"
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addMutation_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addMutation_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"];
  const {
    sip_no,
    str_no,
    // type_address,
    new_address,
    hospital_id_to,
    branch_to,
    berkas,
    // file
  } = values;
  const errors = {};
  // const fileArrayErrors = [];
  if (!hospital_id_to) {
    errors.hospital_id_to = "Tempat Praktek tujuan wajib diisi";
  }
  if (!branch_to) {
    errors.branch_to = "Cabang tujuan wajib diisi";
  }
  if (sip_no || str_no) {
    if (!sip_no) {
      errors.sip_no = "Nomor SIP wajib diisi";
    }
    if (!str_no) {
      errors.str_no = "Nomor STR wajib diisi";
    }
  }
  if (!new_address) {
    errors.new_address = "Alamat Baru wajib diisi";
  }

  if (!berkas) {
    errors.berkas = "Surat permohonan tertulis tidak boleh kosong!";
  }

  if (
    values.berkas &&
    values.berkas.type !== imageType[0] &&
    values.berkas.type !== imageType[1]
  ) {
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG";
  } else if (values.berkas && values.berkas.size > maxFileSize) {
    errors.berkas = "File lebih dari 500KB";
  }

  // if (!file || !file.length) {
  //   errors.file = {
  //     _error: "Berkas tidak boleh kosong"
  //   }
  // } else {
  //   file.forEach((item, index) => {
  //     if (!item) {
  //       fileArrayErrors[index] = "Berkas tidak boleh kosong"
  //     }
  //   })
  // }
  // if (fileArrayErrors.length) {
  //   errors.file = fileArrayErrors;
  // }
  return errors;
}

ModalMutationAdd = reduxForm({
  // a unique name for the form
  form: "ModalMutationAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalMutationAdd);
function mapStateToProps({
  addMutation,
  listHospital,
  listBranch,
  detailMember,
  listStr,
  listSip,
  listSerkom,
  memberList,
  memberP2kbExisting,
}) {
  const detailMember_data = getDetailMember(detailMember);
  let initialValues = {};
  if (detailMember_data && detailMember_data.length !== 0) {
    const [{ branch_data: { name: branch_name } = {} } = {}] =
      detailMember_data;
    initialValues = {
      branch_from: branch_name,
    };
  }

  return {
    addMutation_pending: getMutationPending(addMutation),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    listSip_data: getListSip(listSip),
    listSerkom_data: getListSerkom(listSerkom),
    memberList_data: getMemberList(memberList),
    memberP2kbExisting_pending:
      getMemberP2kbExistingPending(memberP2kbExisting),
    memberP2kbExisting_data: getMemberP2kbExisting(memberP2kbExisting),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  addMutation,
  listStr,
  listSip,
  listSerkom,
  memberList,
  listHospital,
  memberP2kbExisting,
})(withRouter(ModalMutationAdd));
