import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { renderFile, renderInput } from "../../components/form/FormComponent";

import {
  changeStatusPractice,
  detailPractice,
} from "../../actions/sub-menu/practice_action";
import { getPracticePending } from "../../reducers/sub-menu/practice_reducer";

class ModalPracticeReject extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(value) {
    const {
      changeStatusPractice,
      detailPractice,
      id_practice,
      onHide,
    } = this.props;

    const callback = () => {
      onHide();
      detailPractice(id_practice);
    };

    let formData = new FormData();
    formData.append("id", id_practice);
    formData.append("is_approve", false);
    formData.append("reject_notes", value.reject_notes);
    formData.append("reject_file", value.screenshot);
    changeStatusPractice(formData, callback);
  }

  render() {
    const {
      handleSubmit,
    } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi Penolakan</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              <span className={css(styles.labelMargin)}>Alasan penolakan</span>
              <>
                <Field
                  name="reject_notes"
                  placeholder="Masukkan alasan penolakan"
                  component={renderInput}
                />
              </>
              <Field
                name="screenshot"
                type="file"
                fileType="image" //image or pdf
                title="upload screenshot"
                message="PNG atau JPG maksimal 500 kb"
                component={renderFile}
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={<ClearIcon />}
                onClick={this.props.onHide}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={<CheckIcon />}
              >
                Submit
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  labelMargin: {
    marginBottom: 10,
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { screenshot, reject_notes } = values;
  const errors = {};

  if (!screenshot) {
    errors.screenshot = "Screenshot wajib diisi";
  }
  if (!reject_notes) {
    errors.reject_notes = "Alasan penolakan wajib diisi";
  }
  if (values.screenshot && (values.screenshot.type !== imageType[0] && values.screenshot.type !== imageType[1])){
    errors.screenshot = "Format file hanya bisa PNG/JPG/JPEG"
  }
  else if (values.screenshot && (values.screenshot.size > maxFileSize)){
    errors.screenshot = "File lebih dari 500KB"
  }

  return errors;
}

ModalPracticeReject = reduxForm({
  // a unique name for the form
  form: "ModalPracticeReject",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true
})(ModalPracticeReject);

function mapStateToProps({ changeStatusPractice }) {
  return {
    changeStatusPractice_pending: getPracticePending(changeStatusPractice),
  };
}

export default connect(mapStateToProps, {
  changeStatusPractice,
  detailPractice,
})(ModalPracticeReject);
