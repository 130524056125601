import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { userList } from "../../actions/master-data/user_action";
import { getUserListPending, getUserList, getUserListError } from "../../reducers/master-data/user_reducer";
import Button from "@material-ui/core/Button";
import ModalAddUser from "./Modal_add_pengguna";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import { roleList } from "../../actions/master-data/role_action";
import { getRoleListPending, getRoleList, getRoleListError } from "../../reducers/master-data/role_reducer";

class HeaderPengguna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            modalAddUserShow: false
        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }

    delayedQuery = _.debounce((value) => {
        const { handleRefresh } = this.props;
        return handleRefresh(value);
    }, 500);

    handleChangeSearch(e) {
        if (e !== null) {
            this.setState({ search: e.target.value });
            this.delayedQuery({ search: e.target.value });
        }
    }

    handleChangeRole(e) {
        const { handleRefresh } = this.props;
        if (e !== null) {
            handleRefresh({ role_code: e.value });
        } else {
            handleRefresh({ role_code: null });
        }
    }

    handleRefresh() {
        this.props.handleRefresh({ search: "" })
    }

    componentDidMount() {
        this.props.roleList({ length: 999 });
    }

    render() {
        const { modalAddUserShow } = this.state;
        const { roleList_data } = this.props;
        let roleOption = [];
        if (roleList_data) {
            let data = roleList_data.data;
            data.map((value) => {
                const role = { value: value.role_code, label: value.role_name }
                roleOption = [...roleOption, role]
                return role;
            })
        }
        return (
            <Row
                style={{ padding: 20, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="space-between"
            >
                <ModalAddUser
                    show={modalAddUserShow}
                    onHide={() => this.setState({ modalAddUserShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        className={css(styles.approveButton)}
                        onClick={() => this.setState({ modalAddUserShow: true })}
                    >
                        Tambah User
                </Button>
                </div>
                <Row>
                    <div className={css(styles.filterRole)}>
                        <SelectComponent
                            options={roleOption}
                            placeholder="Pilih Role"
                            onChange={this.handleChangeRole}
                        />
                    </div>
                    <div className={css(styles.container)}>
                        <InputComponent
                            onChange={this.handleChangeSearch}
                            value={this.state.search}
                            placeholder="Cari User"
                            startIcon={SearchIcon}
                            id="search"
                        />
                    </div>
                </Row>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        marginTop: 10
    },
    container: {
        width: 150,
        margin: 10,
    },
    filterRole: {
        width: 200,
        margin: 10,
        zIndex: 99
    }
});

function mapStateToProps({ userList, roleList }) {
    return {
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
        roleList_pending: getRoleListPending(roleList),
        roleList_data: getRoleList(roleList),
        roleList_error: getRoleListError(roleList),
    };
}

export default connect(mapStateToProps, { userList, roleList })(HeaderPengguna);