import React, { Component } from "react";
import { Row } from "simple-flexbox";
// import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router-dom";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
});
class HeaderApreciation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      support: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ support: e.target.value });
    this.delayedQuery(e.target.value);
  }

  render() {
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={() => this.props.history.push("/support/detail/add")}
          >
            Kirim Baru
          </Button>
        </div>
        <div>
          <div className={css(styles.container)}>
            {/* <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.support}
              placeholder="Cari kode, cabang"
              startIcon={SearchIcon}
            /> */}
          </div>
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(withRouter(HeaderApreciation));
