import React from "react";
import { string, func } from "prop-types";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ModalBuktiTolak from "./DetailStatusComponentModal";

function DetailRegistrationStatusComponent(props) {
  const [modalBuktiTolakShow, setModalBuktiTolakShow] = React.useState(false);
  const {
    icon,
    status,
    message,
    messageTitle,
    imgUrl,
    reason,
    isWithoutImage,
    folder,
  } = props;
  const Icon = icon;
  return (
    <Row
      className={
        status === 0
          ? css(styles.newStatusContainer)
          : status === 1 || status === 3
          ? css(styles.approvedStatusContainer)
          : css(styles.refusedStatusContainer)
      }
    >
      <ModalBuktiTolak
        show={modalBuktiTolakShow}
        onHide={() => setModalBuktiTolakShow(false)}
        reason={reason}
        imgUrl={imgUrl}
        folder={folder}
        isWithoutImage={isWithoutImage}
      />
      <Column className={css(styles.detailStatusIconColumn)}>
        <Icon />
      </Column>
      <Column className={css(styles.detailStatusTitleColumn)}>
        <span className={css(styles.detailStatusTitle)}>{messageTitle}</span>
      </Column>
      <Column className={css(styles.detailStatusDescColumn)}>
        <span className={css(styles.detailStatusDesc)}>{message}</span>
        <Row
          onClick={() => setModalBuktiTolakShow(true)}
          style={{ marginTop: 5 }}
        >
          {status === 2 && (
            <span className={css(styles.detailStatusErrorLink)}>
              Selengkapnya
            </span>
          )}
        </Row>
      </Column>
    </Row>
  );
}

DetailRegistrationStatusComponent.propTypes = {
  icon: func,
  title: string,
  desc: string,
};

const styles = StyleSheet.create({
  newStatusContainer: {
    background: "#F57C2B 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    opacity: 1,
    marginTop: 30,
    marginBottom: 30,
    flexFlow: "wrap",
    width: "250px",
  },
  approvedStatusContainer: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    opacity: 1,
    marginTop: 30,
    marginBottom: 30,
    flexFlow: "wrap",
    width: "250px",
  },
  refusedStatusContainer: {
    background: "#ED2A2A 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    opacity: 1,
    marginTop: 30,
    marginBottom: 30,
    flexFlow: "wrap",
  },
  detailStatusTitle: {
    fontSize: 16,
    color: "#FFFFFF",
  },
  detailStatusDesc: {
    fontSize: 12,
    color: "#FFFFFF",
  },
  detailStatusErrorLink: {
    fontSize: 12,
    color: "#FFFFFF",
    opacity: 0.7,
    textDecoration: "underline",
    ":hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
  detailStatusIconColumn: {
    marginRight: 15,
    marginTop: 10,
  },
  detailStatusTitleColumn: {
    flex: "0 0 80%",
  },
  detailStatusDescColumn: {
    flex: "0 0 80%",
    marginLeft: 40,
  },
});

export default DetailRegistrationStatusComponent;
