import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import SelectComponent from "../../components/form/SelectComponent";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ModalAddBenefit from "./Modal_add_benefit";

class HeaderBenefit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            status: "",
            modalAddBenefitShow: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }
    componentDidMount() { }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search: value });
    }, 500);

    handleRefresh(state) {
        this.setState(state);
        this.props.handleRefresh();
    }

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
        this.delayedQuery(e.target.value);
    }

    handleStatusChange(e) {
        if (e) {
            this.props.handleRefresh({ status: e.value });
        } else {
            this.props.handleRefresh({ status: "" });
        }
    }

    render() {
        const statusOptions = [
            {
                label: "Tidak Aktif",
                value: 0
            },
            {
                label: "Aktif",
                value: 1
            },
        ]
        return (
            <Row
                style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal={"space-between"}
            >
                <>
                    <ModalAddBenefit
                        show={this.state.modalAddBenefitShow}
                        onHide={() => this.setState({ modalAddBenefitShow: false })}
                        handleRefresh={this.handleRefresh}
                    />
                    <Row>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<AddIcon />}
                            onClick={() => this.setState({ modalAddBenefitShow: true })}
                        >
                            Tambah Benefit
                        </Button>
                    </Row>
                </>
                <Row>
                    <div className={css(styles.container)}>
                        <InputComponent
                            onChange={this.handleSearchChange}
                            placeholder="Cari Nama Benefit"
                            startIcon={SearchIcon}
                        />
                    </div>
                    <div className={css(styles.container)}>
                        <SelectComponent
                            options={statusOptions}
                            placeholder="Status"
                            onChange={this.handleStatusChange}
                        />
                    </div>
                </Row>
            </Row >
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: 150,
        marginLeft: 10,
        zIndex: 999
    },
});

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(HeaderBenefit);