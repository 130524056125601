import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import ClearIcon from "@material-ui/icons/Clear";

import {
  approveEducation,
  detailEducation,
} from "../../actions/member/education_action";

import { getEducationPending } from "../../reducers/member/education_reducer";
import { reduxForm, Field } from "redux-form";
import { renderFile, renderInput } from "../../components/form/FormComponent";

class EducationModalReject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_date: new Date(),
      graduated_date: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { approveEducation, detailEducation, onHide } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const callback = () => {
      onHide();
      detailEducation(id);
    };
    let formData = new FormData();
    formData.append("id", id);
    formData.append("is_approve", false);
    formData.append("reject_file", values.reject_file);
    formData.append("reject_notes", values.reject_notes);
    approveEducation(formData, callback);
  }

  render() {
    const { show, onHide, approveEducation_pending, handleSubmit } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi Pendidikan</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              <span className={css(styles.labelMargin)}>Alasan penolakan</span>
              <>
                <Field
                  name="reject_notes"
                  placeholder="Masukkan alasan penolakan"
                  component={renderInput}
                />
              </>
              <Field
                name="reject_file"
                type="file"
                fileType="image" //image or pdf
                title="upload berkas"
                message="PNG atau JPG maksimal 500 kb"
                component={renderFile}
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={<ClearIcon />}
                onClick={this.props.onHide}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={approveEducation_pending}
                className={css(styles.approveButton)}
                startIcon={
                  approveEducation_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                Ya
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { reject_file, reject_notes } = values;
  const errors = {};
  if (!reject_notes) {
    errors.reject_notes = "Alasan penolakan wajib diisi";
  }
  if (!reject_file) {
    errors.reject_file = "berkas wajib diisi";
  }
  else{
    if (values.reject_file && (values.reject_file.type !== imageType[0] && values.reject_file.type !== imageType[1])){
      errors.reject_file = "Format file hanya bisa PNG/JPG/JPEG"
    }
    else if (values.reject_file && (values.reject_file.size > maxFileSize)){
      errors.reject_file = "File lebih dari 500KB"
    }
  }

  return errors;
}

EducationModalReject = reduxForm({
  // a unique name for the form
  form: "EducationModalReject",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(EducationModalReject);

function mapStateToProps({ approveEducation }) {
  return {
    approveEducation_pending: getEducationPending(approveEducation),
  };
}

export default connect(mapStateToProps, {
  approveEducation,
  detailEducation,
})(withRouter(EducationModalReject));
