import {
  LIST_ACTIVITY_PENDING,
  LIST_ACTIVITY_SUCCESS,
  LIST_ACTIVITY_ERROR,
  DETAIL_ACTIVITY_PENDING,
  DETAIL_ACTIVITY_SUCCESS,
  DETAIL_ACTIVITY_ERROR,
  EDIT_ACTIVITY_PENDING,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_ERROR,
  DELETE_ACTIVITY_PENDING,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
  ADD_ACTIVITY_PENDING,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_ERROR,
} from "../../actions/master-data/activity_action";

const initialState = {
  pending: false,
  error: null,
};

export function listActivity(state = { ...initialState, list_activity: null }, action) {
  switch (action.type) {
    case LIST_ACTIVITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ACTIVITY_SUCCESS:
      return {
        ...state,
        pending: false,
        list_activity: action.data,
      };
    case LIST_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailActivity(
  state = { ...initialState, detail_activity: null },
  action
) {
  switch (action.type) {
    case DETAIL_ACTIVITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_ACTIVITY_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_activity: action.data,
      };
    case DETAIL_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editActivity(state = initialState, action) {
  switch (action.type) {
    case EDIT_ACTIVITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_ACTIVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addActivity(state = initialState, action) {
  switch (action.type) {
    case ADD_ACTIVITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteActivity(state = initialState, action) {
  switch (action.type) {
    case DELETE_ACTIVITY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListActivity = (state) => state.list_activity;
export const getDetailActivity = (state) => state.detail_activity;
export const getActivityPending = (state) => state.pending;
export const getActivityError = (state) => state.error;
