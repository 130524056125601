import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
// import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { universityList } from "../../actions/master-data/university_action";
import { getUniversityListPending, getUniversityList, getUniversityListError } from "../../reducers/master-data/university_reducer";

import _ from "lodash";
import SelectComponent from "../../components/form/SelectComponent";
import SearchIcon from "@material-ui/icons/Search";


class PPDSHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: "",
      search_text: "",
      exam_year: ""
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchPPDSChange = this.handleSearchPPDSChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
    this.handleChangeFKTujuan = this.handleChangeFKTujuan.bind(this);
  }
  componentDidMount() {
    let param_universityList = {
      page: 1,
      length: 999,
      search_text: "",
      id: "",
    };
    this.props.universityList(param_universityList);
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    // const val = value !== null ? value.value : value;

    // return handleRefresh({ exam_year: val });
    return handleRefresh({ value });
  }, 500);

  handleSearchChange(e) {
    // this.setState({ education: e });
    // this.delayedQuery(e);
    if (e !== null) {
      this.props.handleRefresh({ exam_year: e.value });
    } else {
      this.props.handleRefresh({ exam_year: "" });
    }
  }
  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchPPDSChange(e) {
    this.setState({ search_text: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleChangeStatus(e) {
    if (e !== null) {
      this.props.handleRefresh({ status_graduated: e.value });
    } else {
      this.props.handleRefresh({ status_graduated: null });
    }
  }

  handleChangeFKTujuan(e) {
    if (e !== null) {
      this.props.handleRefresh({ university_to: e.value });
    } else {
      this.props.handleRefresh({ university_to: "" });
    }
  }

  render() {
    const { universityList_data } = this.props;
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    let facultyOption = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    const statusOption = [
      { value: 0, label: "Belum Lulus" },
      { value: 1, label: "Lulus" },
      { value: 2, label: "Keluar" }
    ];
    if (universityList_data) {
      let data = universityList_data.data;
      data.map((value, index) => {
        const faculty = { value: value.id, label: value.name }
        facultyOption = [...facultyOption, faculty]
        return faculty;
      })
    }


    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
        </div>
        <Row>
          <div>
            <div className={css(styles.container)}>
              <InputComponent
                onChange={this.handleSearchPPDSChange}
                value={this.state.search_text}
                placeholder="Cari PPDS"
                startIcon={SearchIcon}
              />
            </div>
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleChangeFKTujuan}
              placeholder="Univ Tujuan"
              options={facultyOption}
              id="fktujuan"
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleSearchChange}
              placeholder="Pilih Tahun"
              options={yearOptions}
              id="status"
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleChangeStatus}
              placeholder="Status"
              options={statusOption}
              id="registrasi"
            />
          </div>
        </Row>
      </Row>
    );
  }
}


const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  container: {
    width: 150,
    zIndex: 15,
    margin: 10,
  },
});

function mapStateToProps({ universityList }) {
  return {
    universityList_pending: getUniversityListPending(universityList),
    universityList_data: getUniversityList(universityList),
    universityList_error: getUniversityListError(universityList),
  };
}

export default connect(mapStateToProps, { universityList })(PPDSHeader);
