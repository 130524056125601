import {
  LIST_EVENT_PENDING,
  LIST_EVENT_SUCCESS,
  LIST_EVENT_ERROR,
  DETAIL_EVENT_PENDING,
  DETAIL_EVENT_SUCCESS,
  DETAIL_EVENT_ERROR,
  EDIT_EVENT_PENDING,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  DELETE_EVENT_PENDING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  ADD_EVENT_PENDING,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_ERROR,
  SET_SELECTED_EVENT,
  APPROVE_EVENT_PENDING,
  APPROVE_EVENT_SUCCESS,
  APPROVE_EVENT_ERROR,
} from "../../actions/event/event_action";

const initialState = {
  pending: false,
  error: null,
};

export function listEvent(
  state = { ...initialState, list_event: null },
  action
) {
  switch (action.type) {
    case LIST_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        list_event: action.data,
      };
    case LIST_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailEvent(
  state = { ...initialState, detail_event: null },
  action
) {
  switch (action.type) {
    case DETAIL_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_event: action.data,
      };
    case DETAIL_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editEvent(state = initialState, action) {
  switch (action.type) {
    case EDIT_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addEvent(state = initialState, action) {
  switch (action.type) {
    case ADD_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteEvent(state = initialState, action) {
  switch (action.type) {
    case DELETE_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function selectedEvent(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_EVENT:
      return action.value;
    default:
      return state;
  }
}

export function approveEvent(state = initialState, action) {
  switch (action.type) {
    case APPROVE_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListEvent = (state) => state.list_event;
export const getDetailEvent = (state) => state.detail_event;
export const getEventPending = (state) => state.pending;
export const getEventError = (state) => state.error;
export const getSelectedSKP = (state) => state.selectedSKP;
