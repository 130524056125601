import React from "react";

export default (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 2 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"
      fill={props.fill || "#FFFFFF"}
    />
  </svg>
);
