import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import moment from "moment";
import localization from "moment/locale/id";
import { default_api } from "../../constants/default_api";
import avatar from "../../assets/profil_picture.png";
export default (props) => {
  const {
    support_name,
    support_date,
    support_title,
    support_desc,
    support_status,
  } = props;
  const menunggu = "#FFDCBE";
  const menungguLabel = "#F57C2B";
  const proses = "#FFDCBE";
  const prosesLabel = "#F57C2B";
  const selesai = "#E9F8F0";
  const selesaiLabel = "#E9F8F0";
  let status_background =
    support_status === 0 ? menunggu : support_status === 1 ? proses : selesai;
  let status_color =
    support_status === 0
      ? menungguLabel
      : support_status === 1
        ? prosesLabel
        : selesaiLabel;
  return (
    <div className={css(styles.supportWrapper)}>
      <Row style={{ marginBottom: 5 }}>
        <img
          src={
            props.support_image
              ? `${default_api}/image/show/user_profile/${props.support_image}`
              : avatar
          }
          alt="Profile"
          width="40"
          height="40"
          style={{ borderRadius: "50%" }}
        ></img>
        <Column style={{ paddingTop: 5, marginLeft: 10 }}>
          <label className={css(styles.cardSupportName)}>{support_name}</label>
          <label className={css(styles.cardSupportDate)}>
            {moment(support_date)
              .locale("id", localization)
              .format("DD MMM YYYY")}
          </label>
        </Column>
      </Row>
      <label className={css(styles.cardSupportTitle)}>{support_title}</label>
      <label className={css(styles.cardSupportDesc)}>{support_desc}</label>
      <label
        style={{
          backgroundColor: status_background,
          color: status_color,
        }}
        className={css(styles.status)}
      >
        {support_status === 0
          ? "Menunggu"
          : support_status === 1
            ? "Proses"
            : "Selesai"}
      </label>
    </div>
  );
};

const styles = StyleSheet.create({
  supportWrapper: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    borderRadius: 5,
    padding: 15,
    marginBottom: 10,
  },
  cardSupportName: {
    color: "#495057",
    marginBottom: 5,
  },
  cardSupportDate: {
    color: "#49505799",
    fontSize: 10,
  },
  cardSupportTitle: {
    color: "#495057",
  },
  cardSupportDesc: {
    color: "#49505799",
    whiteSpace: "nowrap",
    width: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  status: {
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 30,
    marginBottom: 0,
  },
});
