import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { userChangePassword } from "../actions/master-data/user_change_password_action";
import { renderInput } from "../components/form/FormComponent";
import {
    getUserChangePassword,
    getUserChangePasswordPending,
    getUserChangePasswordError
} from "../reducers/master-data/user_change_password_reducer";

class ModalUserChangePassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show === true) {
            this.props.reset();
        }
    }

    handleSubmit(values) {
        const { userChangePassword, onHide } = this.props;
        const param_userChangePassword = {
            password: values.newpass,
            confirmation_password: values.newpassConf
        }
        const callback = () => {
            onHide();
            this.props.history.push('/login')
        }
        userChangePassword(param_userChangePassword, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            userChangePassword_pending
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Ubah Password</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            {(
                                <>
                                    <Field
                                        label="Password Baru"
                                        name="newpass"
                                        placeholder="Masukkan password baru"
                                        type="password"
                                        component={renderInput}
                                    />
                                    <Field
                                        label="Konfirmasi Password Baru"
                                        name="newpassConf"
                                        placeholder="Masukkan ulang password baru"
                                        type="password"
                                        component={renderInput}
                                    />
                                </>
                            )}
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={userChangePassword_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    userChangePassword_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const { newpassConf, newpass } = values;
    const errors = {};
    if (!newpass) {
        errors.newpass = "Password tidak boleh kosong"
    }
    if (newpass && newpass.length < 8) {
        errors.newpass = "Password minimal terdiri dari 8 karakter"
    }
    if (!newpassConf) {
        errors.newpassConf = "Konfirmasi password tidak boleh kosong"
    }
    if (newpassConf && newpassConf !== newpass) {
        errors.newpassConf = "Konfirmasi password harus sesuai dengan password"
    }
    return errors;
}

ModalUserChangePassword = reduxForm({
    form: "ModalAddTraining",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalUserChangePassword);

function mapStateToProps({ userChangePassword }) {
    return {
        userChangePassword_pending: getUserChangePasswordPending(userChangePassword),
        userChangePassword_data: getUserChangePassword(userChangePassword),
        userChangePassword_error: getUserChangePasswordError(userChangePassword),
    };
}

export default connect(mapStateToProps, {
    userChangePassword
})(withRouter(ModalUserChangePassword));