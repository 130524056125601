import {
  LIST_P2KB_PENDING,
  LIST_P2KB_SUCCESS,
  LIST_P2KB_ERROR,
  EDIT_P2KB_PENDING,
  EDIT_P2KB_SUCCESS,
  EDIT_P2KB_ERROR,
  DELETE_P2KB_PENDING,
  DELETE_P2KB_SUCCESS,
  DELETE_P2KB_ERROR,
  DATE_P2KB_PENDING,
  DATE_P2KB_SUCCESS,
  DATE_P2KB_ERROR,
  APPROVAL_P2KB_PENDING,
  APPROVAL_P2KB_SUCCESS,
  APPROVAL_P2KB_ERROR,
  ADD_P2KB_PENDING,
  ADD_P2KB_SUCCESS,
  ADD_P2KB_ERROR,
  DETAIL_P2KB_PENDING,
  DETAIL_P2KB_SUCCESS,
  DETAIL_P2KB_ERROR,
  UPLOAD_P2KB_PENDING,
  UPLOAD_P2KB_SUCCESS,
  UPLOAD_P2KB_ERROR,
  APPROVAL_ALL_P2KB_PENDING,
  APPROVAL_ALL_P2KB_SUCCESS,
  APPROVAL_ALL_P2KB_ERROR,
  SUBMIT_ALL_P2KB_PENDING,
  SUBMIT_ALL_P2KB_SUCCESS,
  SUBMIT_ALL_P2KB_ERROR,
  EDIT_DETAIL_P2KB_PENDING,
  EDIT_DETAIL_P2KB_SUCCESS,
  EDIT_DETAIL_P2KB_ERROR,
  REJECT_DETAIL_P2KB_PENDING,
  REJECT_DETAIL_P2KB_SUCCESS,
  REJECT_DETAIL_P2KB_ERROR,
  SET_IS_EDIT_FILE_P2KB,
  SET_SELECTED_SKP,
  LIST_MEMBER_P2KB_PENDING,
  LIST_MEMBER_P2KB_SUCCESS,
  LIST_MEMBER_P2KB_ERROR
} from "../../actions/p2kb/p2kb_action";

const initialState = {
  pending: false,
  error: null,
};

export function p2kbListMember(state = { ...initialState, p2kbMemberList: null }, action) {
  switch (action.type) {
    case LIST_MEMBER_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_MEMBER_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        p2kMemberList: action.data,
      };
    case LIST_MEMBER_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function p2kbList(state = { ...initialState, p2kbList: null }, action) {
  switch (action.type) {
    case LIST_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        p2kbList: action.data,
      };
    case LIST_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addP2kb(state = { ...initialState, addP2kb: null }, action) {
  switch (action.type) {
    case ADD_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        addP2kb: action.data,
      };
    case ADD_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editP2kb(state = { ...initialState, editP2kb: null }, action) {
  switch (action.type) {
    case EDIT_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        editP2kb: action.data,
      };
    case EDIT_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE_P2KB:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function deleteP2kb(state = { ...initialState, deleteP2kb: null }, action) {
  switch (action.type) {
    case DELETE_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteP2kb: action.data,
      };
    case DELETE_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function selectedSKP(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_SKP:
      return action.value;
    default:
      return state;
  }
}

export function approvalP2kb(
  state = { ...initialState, approvalP2kb: null },
  action
) {
  switch (action.type) {
    case APPROVAL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVAL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        approvalP2kb: action.data,
      };
    case APPROVAL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function uploadP2kb(
  state = { ...initialState, uploadP2kb: null },
  action
) {
  switch (action.type) {
    case UPLOAD_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        uploadP2kb: action.data,
      };
    case UPLOAD_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailP2kb(
  state = { ...initialState, detail_p2kb: null },
  action
) {
  switch (action.type) {
    case DETAIL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_p2kb: action.data,
      };
    case DETAIL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function dateP2kb(
  state = { ...initialState, date_p2kb: null },
  action
) {
  switch (action.type) {
    case DATE_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DATE_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        date_p2kb: action.data,
      };
    case DATE_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approvalAllP2kb(
  state = { ...initialState, approval_all: null },
  action
) {
  switch (action.type) {
    case APPROVAL_ALL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVAL_ALL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        approval_all: action.data,
      };
    case APPROVAL_ALL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function submitAllP2kb(
  state = { ...initialState, submit_all: null },
  action
) {
  switch (action.type) {
    case SUBMIT_ALL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case SUBMIT_ALL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        submit_all: action.data,
      };
    case SUBMIT_ALL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editDetailP2kb(
  state = { ...initialState, edit_detail: null },
  action
) {
  switch (action.type) {
    case EDIT_DETAIL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_DETAIL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        edit_detail: action.data,
      };
    case EDIT_DETAIL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function rejectDetailP2kb(
  state = { ...initialState, reject_detail: null },
  action
) {
  switch (action.type) {
    case REJECT_DETAIL_P2KB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REJECT_DETAIL_P2KB_SUCCESS:
      return {
        ...state,
        pending: false,
        reject_detail: action.data,
      };
    case REJECT_DETAIL_P2KB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getP2kbPending = (state) => state.pending;
export const getDetailP2kb = (state) => state.detail_p2kb;
export const getDateP2kb = (state) => state.date_p2kb;
export const getP2kbError = (state) => state.error;

export const getP2kbListPending = (state) => state.pending;
export const getP2kbList = (state) => state.p2kbList;
export const getP2kbListError = (state) => state.error;
export const getP2kbAddPending = (state) => state.pending;
export const getP2kbAdd = (state) => state.addP2kb;
export const getP2kbAddError = (state) => state.error;
export const getP2kbEditPending = (state) => state.pending;
export const getP2kbEdit = (state) => state.editP2kb;
export const getP2kbEditError = (state) => state.error;
export const getP2kbDeletePending = (state) => state.pending;
export const getP2kbDelete = (state) => state.deleteP2kb;
export const getP2kbDeleteError = (state) => state.error;
export const getP2kbIsEditFile = (state) => state.isEditFile;
export const getP2kbApprovalPending = (state) => state.pending;
export const getP2kbApproval = (state) => state.approvalP2kb;
export const getP2kbApprovalError = (state) => state.error;
export const getP2kbUploadPending = (state) => state.pending;
export const getP2kbUpload = (state) => state.uploadP2kb;
export const getP2kbUploadError = (state) => state.error;
export const getSelectedSKP = (state) => state.selectedSKP;
export const getP2kbApprovalAllPending = (state) => state.pending;
export const getP2kbApprovalAll = (state) => state.approvalAllP2kb;
export const getP2kbApprovalAllError = (state) => state.error;
export const getP2kbSubmitAllPending = (state) => state.pending;
export const getP2kbSubmitAll = (state) => state.submitAllP2kb;
export const getP2kbSubmitAllError = (state) => state.error;
export const getP2kbEditDetailPending = (state) => state.pending;
export const getP2kbEditDetail = (state) => state.editDetailP2kb;
export const getP2kbEditDetailError = (state) => state.error;
export const getP2kbRejectDetailPending = (state) => state.pending;
export const getP2kbRejectDetail = (state) => state.rejectDetailP2kb;
export const getP2kbRejectDetailError = (state) => state.error;
export const getP2kbMemberListPending = (state) => state.pending;
export const getP2kbMemberList = (state) => state.p2kbListMember;
export const getP2kbMemberListError = (state) => state.error;
