import {
    MEMBER_VERIFY_PASSWORD_PENDING,
    MEMBER_VERIFY_PASSWORD_SUCCESS,
    MEMBER_VERIFY_PASSWORD_ERROR
} from "../../actions/guests/member_verify_password_action";

const initialState = {
    pending: false,
    error: null
};

export function memberVerifyPassword(
    state = { ...initialState, memberVerifyPassword: null },
    action
) {
    switch (action.type) {
        case MEMBER_VERIFY_PASSWORD_PENDING:
            return {
                ...state,
                pending: true,
            };
        case MEMBER_VERIFY_PASSWORD_SUCCESS:
            return {
                ...state,
                pending: false,
                memberVerifyPassword: action.data,
            };
        case MEMBER_VERIFY_PASSWORD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getMemberVerifyPasswordPending = (state) => state.pending;
export const getMemberVerifyPassword = (state) => state.memberVerifyPassword;
export const getMemberVerifyPasswordError = (state) => state.error;