import React, { Component } from "react";
import { connect } from "react-redux";
import { listMembership, detailMembership } from "../../actions/koperasi/membership_action";
import {
  getListMembership,
  getMembershipError,
  getMembershipPending,
  getDetailMembership
} from "../../reducers/koperasi/membership_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import MembershipHeader from "./MembershipHeader";
import MembershipModalAdd from "./MembershipModalAdd";
import MembershipModalEdit from "./MembershipModalEdit";
import MembershipModalDetail from "./MembershipModalDetail";
import MembershipModalDelete from "./MembershipModalDelete";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { Row } from "simple-flexbox";
import moment from "moment";
import { memberList } from "../../actions/member/member_action";
import avatarImage from "../../assets/avatar.png";
import { default_api } from "../../constants/default_api";

class Membership extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
      showModalEdit: false,
      showModalDetail: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.editRecord = this.editRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  editRecord(id) {
    this.props.detailMembership(id)
    this.setState({ showModalEdit: true })
  }
  detailRecord(id) {
    this.props.detailMembership(id)
    this.setState({ showModalDetail: true })
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    const { memberList } = this.props
    memberList();
  }

  render() {
    const { listMembership, listMembership_data } = this.props;
    const { search_text, showModalAdd, showModalEdit, showModalDelete, id, showModalDetail } = this.state;
    let data = [];
    let records_total = 0;
    let alreadyMembership = [];
    if (listMembership_data && listMembership_data.length > 0) {
      listMembership_data.data.map(item => {
        alreadyMembership = [...alreadyMembership, item.member_data.id];
        return alreadyMembership;
      });
      alreadyMembership.push(listMembership_data.data[0].member_data.id);
    }
    if (listMembership_data && listMembership_data.length > 0) {
      data = listMembership_data.data;
      records_total = listMembership_data.records_total;
    }
    return (
      <ContentContainer>
        <MembershipHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <MembershipModalDelete
          id={id}
          show={showModalDelete}
          onHide={() => this.setState({ showModalDelete: false })}
          handleRefresh={this.handleRefresh}
        />
        <MembershipModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
          alreadyMembership={alreadyMembership}
        />
        <MembershipModalEdit
          show={showModalEdit}
          onHide={() => this.setState({ showModalEdit: false })}
          handleRefresh={this.handleRefresh}
        />
        <MembershipModalDetail
          show={showModalDetail}
          onHide={() => this.setState({ showModalDetail: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama Dokter",
              render: ({ member_data: { img_profile, name } }) => (
                <Row vertical="center">
                  {img_profile ? (<img
                    src={`${default_api}/image/show/user_profile/${img_profile}`}
                    alt="profile"
                    height={40}
                    width={40}
                    style={{ borderRadius: 20 }}
                  />) : (<img
                    src={avatarImage}
                    alt="profile"
                    height={40}
                    width={40}
                    style={{ borderRadius: 20 }}
                  />)}

                  <div style={{ marginLeft: 20 }}>{name}</div>
                </Row>
              ),
            },
            {
              title: "ID Koperasi",
              field: "code",

            },
            {
              title: "Tanggal gabung",
              render: ({ join_date }) => moment(join_date).format("DD MMMM YYYY")
            },


            {
              title: "Status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Nonaktif"
                        : "Aktif"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.editRecord(id)}>
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => this.detailRecord(id)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
              };
              listMembership(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listMembership, detailMembership }) {
  return {
    detailMembership_data: getDetailMembership(detailMembership),
    listMembership_data: getListMembership(listMembership),
    listMembership_error: getMembershipError(listMembership),
    listMembership_pending: getMembershipPending(listMembership),
  };
}

export default connect(mapStateToProps, {
  listMembership,
  detailMembership,
  memberList
})(Membership);
