import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { experienceList, deleteExperience } from "../../actions/master-data/experience_action";
import { getExperienceListPending, getExperienceList, getExperienceListError, getExperienceDelete } from "../../reducers/master-data/experience_reducer";

class ModalHapusPelatihan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            experience_name: "",
            institution_name: "",
        };
    }

    deletePelatihan() {
        // const { deleteExperience, onHide } = this.props;
        // const param_deletePelatihan = {
        //     id: this.props.data.data[0].id
        // }

        // const callback = () => {
        //     onHide();
        // }
        // deleteExperience(param_deletePelatihan, callback);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.setState({ experience_name: this.props.data.data[0].position })
            this.setState({ institution_name: this.props.data.data[0].institution })
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Hapus Pelatihan
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menghapus pengalaman {this.state.experience_name} di {this.state.institution_name} ?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                        >
                            Tidak
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.deletePelatihan()}
                        >
                            Ya
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ deleteExperience, experienceList }) {
    return {
        deletePelatihan: getExperienceDelete(deleteExperience),
        experienceList_pending: getExperienceListPending(experienceList),
        experienceList_data: getExperienceList(experienceList),
        experienceList_error: getExperienceListError(experienceList),
    };
}


export default connect(mapStateToProps, { deleteExperience, experienceList })(ModalHapusPelatihan);