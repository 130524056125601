import React, { Component } from "react";
import { connect } from "react-redux";
import { listTransfer } from "../../actions/report/report_action";
import {
  getListTransfer,
  getTransferError,
  getTransferPending,
} from "../../reducers/report/report_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import TransferHeader from "./TransferHeader";
import { listBranch } from "../../actions/master-data/branch_action";
import { getListBranch } from "../../reducers/master-data/branch_reducer";

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
      branch_id: null,
      year: null
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/transfer/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    this.props.listBranch({ length: 99999999 });
  }

  render() {
    const { listTransfer, listTransfer_data } = this.props;
    const { search_text, branch_id, year } = this.state;
    let data = [];
    let records_total = 0;
    if (listTransfer_data) {
      data = listTransfer_data.data;
      records_total = listTransfer_data.records_total;
    }
    return (
      <ContentContainer>
        <TransferHeader handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama Cabang",
              field: "branch_data.name",
            },
            {
              title: "Jumlah Anggota",
              render: ({ total_member }) => {
                if (total_member > 0) {
                  return total_member;
                } else {
                  return "-";
                }
              }
            },
            {
              title: "Estimasi Dana Masuk (Rp)",
              render: ({ estimation_total_branch }) => {
                if (estimation_total_branch && estimation_total_branch > 0) {
                  return estimation_total_branch
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },

            {
              title: "Dana Aktual (Rp)",
              render: ({ act_total_branch }) => {
                if (act_total_branch && act_total_branch > 0) {
                  return act_total_branch
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Dana Outstanding (Rp)",
              render: ({ estimation_total_branch, act_total_branch }) => {
                if (Number(estimation_total_branch - Number(act_total_branch)) > 0) {
                  return (Number(estimation_total_branch) - Number(act_total_branch))
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                } else {
                  return "-";
                }
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                branch_id: branch_id,
                year: year
              };
              listTransfer(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listTransfer, listBranch }) {
  return {
    listTransfer_data: getListTransfer(listTransfer),
    listTransfer_error: getTransferError(listTransfer),
    listTransfer_pending: getTransferPending(listTransfer),
    listBranch_data: getListBranch(listBranch),
  };
}

export default connect(mapStateToProps, {
  listTransfer,
  listBranch
})(Transfer);
