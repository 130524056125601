import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import DoctorList from "./DoctorList";
import { connect } from "react-redux";
import { listSubspecialist } from '../../actions/dashboard/kolegium_action'
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer'
import SelectComponent from "../../components/form/SelectComponent";

class DashboardAdminPusat extends Component {
    constructor() {
        super();
        this.state = {
            year: new Date().getFullYear()
        };
        this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    }

    componentDidMount() {
        this.props.listSubspecialist({ year: this.state.year })
    }

    handleYearSelectChange(e) {
        if (e) {
            this.setState({ year: e.value });
            this.props.listSubspecialist({ year: e.value })
        } else {
            this.setState({ year: new Date().getFullYear() });
            this.props.listSubspecialist({ year: new Date().getFullYear() })
        }
    }

    render() {
        const { listSubspecialist_data } = this.props
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        let doctor = null
        if (listSubspecialist_data && listSubspecialist_data.subspecialist) {
            const max = Math.max.apply(Math, listSubspecialist_data.subspecialist.map(item => item.total));

            if (max !== 0) {
                doctor = listSubspecialist_data.subspecialist.map(item => (<DoctorList
                    subspecialist={item.name}
                    total={item.total}
                    bar={item.total / max * 100}
                />))
            }else{
                doctor = listSubspecialist_data.subspecialist.map(item => (<DoctorList
                    subspecialist={item.name}
                    total={item.total}
                    bar={0}
                />))
            }
            
        } else if (listSubspecialist_data) {
            const max = Math.max.apply(Math, listSubspecialist_data.map(item => item.total));

            if (max !== 0){
                doctor = listSubspecialist_data.map(item => (<DoctorList
                    subspecialist={item.name}
                    total={item.total}
                    bar={item.total / max * 100}
                />))
            }else{
                doctor = listSubspecialist_data.map(item => (<DoctorList
                    subspecialist={item.name}
                    total={item.total}
                    bar={0}
                />))
            }

           
        }
        return (
            <Column className={css(styles.wrapper)}>
                <div className={css(styles.cardHeader)}></div>
                <Row className={css(styles.wrapperTop, styles.borderBottom)} horizontal="space-between" style={{ width: "100%", padding: 20, backgroundColor: 'white' }} vertical='center'>


                    <label className={css(styles.titleLabel)}>Dokter Subspesialis</label>
                    <Row>
                        <div style={{ width: 150, margin: "0 10px" }}>
                            <SelectComponent
                                options={yearOptions}
                                isRounded
                                onChange={this.handleYearSelectChange}
                                placeholder="Pilih Tahun"
                            />
                        </div>
                    </Row>
                </Row>
                <Column horizontal='center' className={css(styles.wrapper,)} style={{ width: "100%", padding: 20 }}>
                    <div style={{ marginTop: 30 }}>
                        {doctor}
                    </div>
                </Column>

            </Column>
        )
    }
}

function mapStateToProps({
    listSubspecialist }) {
    return {
        listSubspecialist_data: getDashboardData(listSubspecialist),
    };
}

export default connect(mapStateToProps, {
    listSubspecialist,
})(DashboardAdminPusat);



const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: "bold",
        margin: "0px 0px 15px 0px"
    },
    cardLabel: {
        color: "#BCBCBC",
        marginBottom: 0
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    wrapper: {
        boxShadow: "0px 3px 6px #00000029",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        height: "100%",
        backgroundColor: 'white'
    },
    cardSubspecialistWrapper: {
        padding: 30
    },
    cardHeader: {
        backgroundColor: "#2CB96A",
        height: 10,
        width: "100%",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    }
});