import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import DatePicker from "react-date-picker";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { p2kbList, submitAllP2kb } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ModalAddSKP from "./Modal_add_skp";
import CheckIcon from "@material-ui/icons/Check";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DetailRejectModal from "../../components/modal/DetailRejectModalP2kb";
import { getUser } from "../../helpers/user";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import { detailP2kb, dateP2kb } from "../../actions/p2kb/p2kb_action";
import { getDetailP2kb, getDateP2kb } from "../../reducers/p2kb/p2kb_reducer";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import MenuItem from "@material-ui/core/MenuItem";
import LaunchIcon from "../../assets/icon-launch";
import ExportButton from "../../assets/icon-document-p2kb";
import ModalDocumentNotfound from "./Modal_document_notfound";
import ModalDetailDokumen from "./Modal_document";
import DetailBerkasPendukung from "./Modal_berkas_pendukung";
import { userList } from "../../actions/master-data/user_action";
import {
  getUserListPending,
  getUserList,
  getUserListError,
} from "../../reducers/master-data/user_reducer";
import {
  detailMember,
  editMember,
  uploadBerkas,
} from "../../actions/member/member_action";

import {
  getDetailMember,
  getMemberPending,
  getBerkasUpload,
  getBerkasUploadPending,
  getBerkasUploadError,
} from "../../reducers/member/member_reducer";

class HeaderP2KB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddSKPShow: false,
      modalDetailRejectSKPShow: false,
      start_date: localStorage.getItem("start_date")
        ? localStorage.getItem("start_date")
        : "",
      end_date: localStorage.getItem("end_date")
        ? localStorage.getItem("end_date")
        : moment().format("YYYY-MM-DD"),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      modalDocumentNotfoundShow: false,
      user_data: null,
      user_img: null,
      user_ktp: null,
      user_medical: null,
      user_name: null,
      user_str: null,
      is_done: null,
      is_str_done: null,
      folder: null,
      modalBerkasPendukungShow: false,
    };
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleBerkasPendukung = this.handleBerkasPendukung.bind(this);
    this.handleCloseButton = this.handleCloseButton.bind(this);
    localStorage.setItem("end_date", moment().format("YYYY-MM-DD"));
  }

  handleChangeSearch(e) {
    if (e !== null) {
      this.props.handleRefresh({ search: e.target.value });
    } else {
      this.props.handleRefresh({ search: "" });
    }
  }

  handleChangeStatus(e) {
    if (e !== null) {
      this.props.handleRefresh({ status: e.value });
    } else {
      this.props.handleRefresh({ status: "" });
    }
  }

  handleChangeActivity(e) {
    if (e !== null) {
      this.props.handleRefresh({ activity_type: e.value });
    } else {
      this.props.handleRefresh({ activity_type: "" });
    }
  }

  handleChangeStart(value) {
    const old = value;
    value = moment(value).format("YYYY-MM-DD");
    this.setState({
      start_date: old,
    });
    localStorage.setItem("start_date", value);
  }

  handleChangeEnd(value) {
    const old = value;
    value = moment(value).format("YYYY-MM-DD");
    this.setState({
      end_date: old,
    });
    localStorage.setItem("end_date", value);
  }

  handleCloseButton(newvalue) {
    this.setState({ modalBerkasPendukungShow: newvalue });
  }

  handleSubmit() {
    const { handleRefresh, submitAllP2kb, submit_skp } = this.props;
    const callback = () => {
      handleRefresh();
    };
    submitAllP2kb(null, callback);
  }
  handleShowRevision() {
    // this.setState({ revisionImage: data.last_reject_filename });
    // this.setState({ revisionReason: data.last_reject_notes });
    this.setState({ modalDetailRejectSKPShow: true });
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ folder: "member_supporting_files" });
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl !== null) {
      this.setState({ modalDetailDokumenShow: true });
    } else {
      this.setState({ modalDocumentNotfoundShow: true });
    }
  }

  handleOpenDocumentSTR(imgUrl, modal_title) {
    this.setState({ folder: "member_str" });
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl !== null) {
      this.setState({ modalDetailDokumenShow: true });
    } else {
      this.setState({ modalDocumentNotfoundShow: true });
    }
  }

  handleBerkasPendukung() {
    const id = getUser().id;
    this.props.detailMember(id);

    this.setState({ modalBerkasPendukungShow: true });
  }
  componentDidUpdate() {
    let isSerkom,
      isSKP = false;
    if (window.location.href.includes("skp")) {
      isSKP = true;
    } else if (window.location.href.includes("serkom")) {
      isSerkom = true;
    }
    const user_id = getUser().id;
    if ((user_id && isSerkom) || isSKP) {
      if (this.state.is_done === null) {
        // eslint-disable-next-line no-unused-vars
        let hasSetFile = false;
        // eslint-disable-next-line no-unused-vars
        const callback = () => {
          const data = this.props.userList_data.data[0];
          if (data) {
            this.setState({
              user_img: data.member_profile.additional_file_image,
            });
            this.setState({ user_ktp: data.member_profile.scan_ktp_image });
            this.setState({
              user_medical: data.member_profile.health_information_image,
            });
            this.setState({ user_name: data.name });
            hasSetFile = true;
          }
        };
        this.props.userList({ id: user_id }, callback);
        this.setState({ is_done: true });
        // const callbacks = () => {
        //   const data = this.props.listStr_data.data;
        //   console.log("data callback", data);
        //   let strApproved = data.filter((item) => item.status === 1);
        //   if (strApproved.length > 0) {
        //     this.setState({ user_str: strApproved[0].filename });
        //   }
        //   if (!hasSetFile) {
        //     const data2 = data[0];
        //     this.setState({
        //       user_img: data2.user_data.member_profile.additional_file_image,
        //     });
        //     this.setState({
        //       user_ktp: data2.user_data.member_profile.scan_ktp_image,
        //     });
        //     this.setState({
        //       user_medical:
        //         data2.user_data.member_profile.health_information_image,
        //     });
        //     this.setState({ user_name: data2.user_data.name });
        //   }
        // };
        // this.props.listStr({ user_id: user_id }, callbacks);
      }
      if (isSKP && this.state.is_str_done === null) {
        const callback = () => {
          const data = this.props.listStr_data.data;
          let strApproved = data.filter((item) => item.status === 1);
          if (strApproved.length > 0) {
            this.setState({ user_str: strApproved[0].filename });
          }
        };
        this.props.listStr({ user_id: user_id }, callback);

        this.setState({ is_str_done: true });
      }
    }
  }

  componentDidMount() {
    let id = "";
    if (getUser()) {
      id = getUser().id;
    }
    const callbackFilter = () => {
      if (this.props.dateP2kb_data.start_date !== null) {
        const dateFilter = moment(this.props.dateP2kb_data.start_date).format(
          "YYYY-MM-DD"
        );
        this.setState({ start_date: dateFilter });
        let start = localStorage.getItem("start_date")
          ? localStorage.getItem("start_date")
          : null;
        if (start === null) {
          localStorage.setItem("start_date", dateFilter);
        }
      } else {
        this.setState({ start_date: moment().format("YYYY-MM-DD") });
        localStorage.setItem("start_date", moment().format("YYYY-MM-DD"));
      }
    };
    this.props.dateP2kb({ isFindDate: true, user_id: id }, callbackFilter);
  }

  render() {
    const { modalAddSKPShow } = this.state;
    const { modalDetailRejectSKPShow } = this.state;
    const { isFirstLayer } = this.props;
    const { status } = this.props;
    const { submit_status } = this.props;
    const { last_reject_notes } = this.props;
    const { last_reject_filename } = this.props;
    const { submit_skp } = this.props;
    const { str_active } = this.props;
    const activityOption = [
      { value: 1, label: "Internal" },
      { value: 2, label: "Eksternal" },
      { value: 3, label: "Pribadi" },
    ];
    const statusOption = [
      { value: 0, label: "Menunggu" },
      { value: 1, label: "Approved" },
      { value: 2, label: "Ditolak" },
    ];

    return (
      <Row
        style={{
          padding: 20,
          paddingBottom: 30,
          backgroundColor: "#F8F9FB",
          alignItems: "center",
        }}
        horizontal="space-between"
      >
        <ModalAddSKP
          show={modalAddSKPShow}
          isFirstLayer={isFirstLayer}
          onHide={() => this.setState({ modalAddSKPShow: false })}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder={this.state.folder}
        />

        <ModalDocumentNotfound
          show={this.state.modalDocumentNotfoundShow}
          onHide={() => this.setState({ modalDocumentNotfoundShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          member_name={this.state.user_name}
        />
        <DetailRejectModal
          show={modalDetailRejectSKPShow}
          onHide={() => this.setState({ modalDetailRejectSKPShow: false })}
          reason={last_reject_notes}
          filepath="member_p2kb"
          filename={last_reject_filename}
        />
        <DetailBerkasPendukung
          show={this.state.modalBerkasPendukungShow}
          onHide={() => this.setState({ modalBerkasPendukungShow: false })}
          handleCloseButton={this.handleCloseButton}
        />

        <div>
          {!isFirstLayer && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={css(styles.addButton)}
              startIcon={<AddIcon />}
              onClick={() => this.setState({ modalAddSKPShow: true })}
            >
              {isFirstLayer ? "tambah skp" : "tambah kegiatan"}
            </Button>
          )}
          {isFirstLayer && (
            <Row>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={<CheckIcon />}
                onClick={() => this.handleSubmit()}
                disabled={
                  submit_skp < 250 ||
                  str_active === "nonactive" ||
                  submit_status == 1
                }
              >
                submit
              </Button>

              {status === 2 && (
                <Button
                  type="submit"
                  color="secondary"
                  startIcon={<ErrorOutlineOutlinedIcon />}
                  onClick={() => this.handleShowRevision()}
                  className={css(styles.rejectButton)}
                >
                  Alasan Penolakan
                </Button>
              )}

              {status === 2 ? (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    className={css(styles.BerkasPendukungButtonReject)}
                    startIcon={<ExportButton fill="#626568" />}
                    onClick={() => this.handleBerkasPendukung()}
                  >
                    Berkas Pendukung
                  </Button>
                </>
              ) : (
                <>
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={
                          getUser().role_codes[0] === "member"
                            ? css(styles.buttonDocHoverRoot)
                            : css(styles.buttonDocHover)
                        }
                      >
                        <ExportButton fill="#626568" />
                        <span className={css(styles.exportButton)}>
                          Berkas Pendukung
                        </span>
                      </Row>
                    )}
                    horizontal="left"
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(this.state.user_img, "Pas Foto")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>Pas Foto</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(this.state.user_ktp, "KTP")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>Scan KTP</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(
                          this.state.user_medical,
                          "Surat Keterangan Kesehatan"
                        )
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>
                        Surat Ket. Kesehatan
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocumentSTR(this.state.user_str, "STR")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>STR</span>
                    </MenuItem>
                  </DetailButtonComponent>
                </>
              )}
            </Row>
          )}
        </div>
        <Row>
          <form>
            {this.props.isFirstLayer && (
              <Row>
                {this.state.start_date !== "" && this.state.end_date !== "" ? (
                  <>
                    <span className={css(styles.filterLabel)}>
                      Tanggal Awal
                    </span>
                    <DatePicker
                      className={css(styles.filterDate)}
                      onChange={this.handleChangeStart}
                      value={
                        this.state.start_date === ""
                          ? this.state.start_date
                          : new Date(this.state.start_date)
                      }
                    />
                    <span className={css(styles.filterLabel)}>
                      Tanggal Akhir
                    </span>
                    <DatePicker
                      className={css(styles.filterDate)}
                      onChange={this.handleChangeEnd}
                      value={
                        this.state.end_date === ""
                          ? this.state.end_date
                          : new Date(this.state.end_date)
                      }
                    />
                  </>
                ) : (
                  <>
                    <span className={css(styles.filterLabel)}>
                      Tanggal Awal
                    </span>
                    <DatePicker
                      className={css(styles.filterDate)}
                      onChange={this.handleChangeStart}
                      value={
                        this.state.start_date === ""
                          ? this.state.start_date
                          : new Date(this.state.start_date)
                      }
                    />
                    <span className={css(styles.filterLabel)}>
                      Tanggal Akhir
                    </span>
                    <DatePicker
                      className={css(styles.filterDate)}
                      onChange={this.handleChangeEnd}
                      value={
                        this.state.end_date === ""
                          ? this.state.end_date
                          : new Date(this.state.end_date)
                      }
                    />
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() =>
                    this.props.handleRefresh({
                      start_date: localStorage.getItem("start_date"),
                      end_date: localStorage.getItem("end_date"),
                    })
                  }
                >
                  FILTER
                </Button>
              </Row>
            )}
          </form>
        </Row>
      </Row>
    );
  }
}

const styles = StyleSheet.create({
  addButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  BerkasPendukungButtonReject: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    color: "#495057",
    boxShadow: "none",
    marginLeft: 10,
    marginRight: -5,
    fontSize: 13,
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 5,
    boxShadow: "none",
    marginLeft: -16,
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    color: "#495057",
    boxShadow: "none",
    marginRight: -5,
  },
  container: {
    width: 150,
    margin: 10,
  },
  filterDate: {
    marginRight: 10,
  },
  filterLabel: {
    margin: "auto 10px",
  },
  launchButton: {
    alignItems: "center",
    display: "flex",
    paddingTop: 2.5,
    marginLeft: 5,
  },
  buttonDocHoverRoot: {
    padding: "10px 15px",
    backgroundColor: "rgb(239, 240, 242)",
    marginLeft: 10,
    borderRadius: 5,
    ":hover": {
      cursor: "pointer",
    },
  },
  buttonDocHover: {
    padding: "10px 15px",
    backgroundColor: "rgb(239, 240, 242)",
    //marginLeft: 10,
    borderRadius: 5,
    ":hover": {
      cursor: "pointer",
    },
  },
  exportButton: {
    alignItems: "center",
    display: "flex",
    marginLeft: 10,
    color: "#626568",
  },
});

HeaderP2KB = reduxForm({
  form: "HeaderP2KB",
})(HeaderP2KB);

function mapStateToProps({
  p2kbList,
  submitAllP2kb,
  listStr,
  detailP2kb,
  dateP2kb,
  userList,
  detailMember,
}) {
  return {
    P2kbList_pending: getP2kbListPending(p2kbList),
    P2kbList_data: getP2kbList(p2kbList),
    P2kbList_error: getP2kbListError(p2kbList),
    submitAll_pending: getP2kbListPending(submitAllP2kb),
    submitAll_data: getP2kbList(submitAllP2kb),
    submitAll_error: getP2kbListError(submitAllP2kb),
    userList_pending: getUserListPending(userList),
    userList_data: getUserList(userList),
    userList_error: getUserListError(userList),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    detailP2kb_data: getDetailP2kb(detailP2kb),
    dateP2kb_data: getDateP2kb(dateP2kb),
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    editMember_data: getDetailMember(detailMember),
  };
}

export default connect(mapStateToProps, {
  p2kbList,
  userList,
  submitAllP2kb,
  listStr,
  detailP2kb,
  dateP2kb,
  detailMember,
})(HeaderP2KB);
