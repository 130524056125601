import {
  LIST_SERKOM_ADMIN_PENDING,
  LIST_SERKOM_ADMIN_SUCCESS,
  LIST_SERKOM_ADMIN_ERROR,
  DETAIL_SERKOM_ADMIN_PENDING,
  DETAIL_SERKOM_ADMIN_SUCCESS,
  DETAIL_SERKOM_ADMIN_ERROR,
  EDIT_SERKOM_ADMIN_PENDING,
  EDIT_SERKOM_ADMIN_SUCCESS,
  EDIT_SERKOM_ADMIN_ERROR,
  DELETE_SERKOM_ADMIN_PENDING,
  DELETE_SERKOM_ADMIN_SUCCESS,
  DELETE_SERKOM_ADMIN_ERROR,
  ADD_SERKOM_ADMIN_PENDING,
  ADD_SERKOM_ADMIN_SUCCESS,
  ADD_SERKOM_ADMIN_ERROR,
  APPROVE_SERKOM_ADMIN_PENDING,
  APPROVE_SERKOM_ADMIN_SUCCESS,
  APPROVE_SERKOM_ADMIN_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/serkom/serkomAdmin_action";

const initialState = {
  pending: false,
  error: null,
};

export function listSerkomAdmin(
  state = { ...initialState, list_serkomAdmin: null },
  action
) {
  switch (action.type) {
    case LIST_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        list_serkomAdmin: action.data,
      };
    case LIST_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailSerkomAdmin(
  state = { ...initialState, detail_serkomAdmin: null },
  action
) {
  switch (action.type) {
    case DETAIL_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_serkomAdmin: action.data,
      };
    case DETAIL_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editSerkomAdmin(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addSerkomAdmin(state = initialState, action) {
  switch (action.type) {
    case ADD_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteSerkomAdmin(state = initialState, action) {
  switch (action.type) {
    case APPROVE_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveSerkomAdmin(state = initialState, action) {
  switch (action.type) {
    case DELETE_SERKOM_ADMIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SERKOM_ADMIN_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_SERKOM_ADMIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListSerkomAdmin = (state) => state.list_serkomAdmin;
export const getDetailSerkomAdmin = (state) => state.detail_serkomAdmin;
export const getSerkomAdminPending = (state) => state.pending;
export const getSerkomAdminError = (state) => state.error;
export const getSerkomAdminIsEditFile = (state) => state.isEditFile;
