import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_JOURNAL_PENDING = "LIST_JOURNAL_PENDING";
export const LIST_JOURNAL_SUCCESS = "LIST_JOURNAL_SUCCESS";
export const LIST_JOURNAL_ERROR = "LIST_JOURNAL_ERROR";

export const DETAIL_JOURNAL_PENDING = "DETAIL_JOURNAL_PENDING";
export const DETAIL_JOURNAL_SUCCESS = "DETAIL_JOURNAL_SUCCESS";
export const DETAIL_JOURNAL_ERROR = "DETAIL_JOURNAL_ERROR";

export const EDIT_JOURNAL_PENDING = "EDIT_JOURNAL_PENDING";
export const EDIT_JOURNAL_SUCCESS = "EDIT_JOURNAL_SUCCESS";
export const EDIT_JOURNAL_ERROR = "EDIT_JOURNAL_ERROR";

export const APPROVAL_JOURNAL_PENDING = "APPROVAL_JOURNAL_PENDING";
export const APPROVAL_JOURNAL_SUCCESS = "APPROVAL_JOURNAL_SUCCESS";
export const APPROVAL_JOURNAL_ERROR = "APPROVAL_JOURNAL_ERROR";

export const REJECT_APPROVAL_JOURNAL_PENDING = "REJECT_APPROVAL_JOURNAL_PENDING";
export const REJECT_APPROVAL_JOURNAL_SUCCESS = "REJECT_APPROVAL_JOURNAL_SUCCESS";
export const REJECT_APPROVAL_JOURNAL_ERROR = "REJECT_APPROVAL_JOURNAL_ERROR";

export const PUBLISH_JOURNAL_PENDING = "PUBLISH_JOURNAL_PENDING";
export const PUBLISH_JOURNAL_SUCCESS = "PUBLISH_JOURNAL_SUCCESS";
export const PUBLISH_JOURNAL_ERROR = "PUBLISH_JOURNAL_ERROR";

export const JOURNAL_REVISION_PENDING = "JOURNAL_REVISION_PENDING";
export const JOURNAL_REVISION_SUCCESS = "JOURNAL_REVISION_SUCCESS";
export const JOURNAL_REVISION_ERROR = "JOURNAL_REVISION_ERROR";

export const SET_SELECTED_JOURNAL = "SET_SELECTED_JOURNAL";

const LIST_JOURNAL_URL = "journal/find";
const APPROVAL_JOURNAL_URL = "journal/approve";
const REJECT_APPROVAL_JOURNAL_URL = "journal/approve_reject";
const PUBLISH_JOURNAL_URL = "journal/publish_journal";
const JOURNAL_REVISION_URL = "journal/revision";

export function listJournal(param = {}, resolve, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_JOURNAL_PENDING));
    API.post(LIST_JOURNAL_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_JOURNAL_SUCCESS, data));
        const { records_total, data: response } = data;
        const result = response.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          ...item,
        }));
        if (resolve) {
          if (detail === true) {
            let detailJournal = data.data[0].journal_participants.map(
              (item, i) => ({
                ...item,
                no: i + 1 + (param.page - 1) * 10,
              })
            );
            resolve({
              data: detailJournal,
              page: param.page - 1,
              totalCount: detailJournal.length,
            });
          } else {
            resolve({
              data: result,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_JOURNAL_ERROR, error));
        toastError(error, "Gagal mendapatkan list JOURNAL, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function detailJournal(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_JOURNAL_PENDING));
    API.post(LIST_JOURNAL_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_JOURNAL_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_JOURNAL_ERROR, error));
        toastError(error, "Gagal mendapatkan list JOURNAL, ");
      });
  };
}

export function setSelectedJournal(value) {
  return {
    type: SET_SELECTED_JOURNAL,
    value: value,
  };
}

export function approvalJournal(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_JOURNAL_PENDING));
    API.post(APPROVAL_JOURNAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_JOURNAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status jurnal");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_JOURNAL_ERROR, error));
        toastError(error, "Gagal mengubah status jurnal, ");
      });
  };
}

export function revisionJournal(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(JOURNAL_REVISION_PENDING));
    API.post(JOURNAL_REVISION_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(JOURNAL_REVISION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status jurnal");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(JOURNAL_REVISION_ERROR, error));
        toastError(error, "Gagal mengubah status jurnal, ");
      });
  };
}

export function rejectApprovalJournal(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(REJECT_APPROVAL_JOURNAL_PENDING));
    API.post(REJECT_APPROVAL_JOURNAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(REJECT_APPROVAL_JOURNAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status jurnal");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(REJECT_APPROVAL_JOURNAL_ERROR, error));
        toastError(error, "Gagal mengubah status jurnal, ");
      });
  };
}

export function publishJournal(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(PUBLISH_JOURNAL_PENDING));
    API.post(PUBLISH_JOURNAL_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(PUBLISH_JOURNAL_SUCCESS, res.data.data));
        toastSuccess("Berhasil melakukan publish jurnal");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(PUBLISH_JOURNAL_ERROR, error));
        toastError(error, "Gagal melakukan publish jurnal, ");
      });
  };
}