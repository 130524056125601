import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

export default (props) => {
    const { event_name, event_date, total_participants } = props;
    return (
        <Column>
            <div className={css(styles.detailHeaderContainer)}>
                <Row>
                    <Column className={css(styles.detailHeaderEventName)}>
                        <div className={css(styles.detailHeaderEventNameValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Nama Event
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {event_name}
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderEventDateColumn)}>
                        <div className={css(styles.detailHeaderEventNameValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Tanggal Event
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {event_date}
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderEventParticipantsColumn)}>
                        <div>
                            <div className={css(styles.detailHeaderTitle)}>
                                Total Peserta
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {total_participants}
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
        </Column>
    )
}

const styles = StyleSheet.create({
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderEventName: {
        margin: "20px 20px 20px 30px",
        width: "60%"
    },
    detailHeaderEventNameValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderEventDateColumn: {
        margin: "20px",
        width: "30%"
    },
    detailHeaderEventParticipantsColumn: {
        margin: "20px",
        width: "10%"
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
})