import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import { getUser } from "../../helpers/user";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
});
class HeaderStrKT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: getUser().id,
      search: "",
      status: null,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleStatusChange(value) {
    if (value) {
      this.props.handleRefresh({ status: value.value });
    } else {
      this.props.handleRefresh({ status: null });
    }
  }

  render() {
    let buttonAddPractice;
    let statusOption = [
      { value: 0, label: "Proses" },
      { value: 1, label: "Disetujui" },
      { value: 2, label: "Ditolak" },
    ];

    const { handleOpenModalAdd } = this.props;

    if (getUser().role_codes[0] === "member") {
      buttonAddPractice = (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={css(styles.approveButton)}
          startIcon={<AddIcon />}
          onClick={handleOpenModalAdd}
        >
          tambah STR KT
        </Button>
      );
    } else {
      buttonAddPractice = "";
    }
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>{buttonAddPractice}</div>
        <Row>
          <div className={css(styles.container)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.tes}
              placeholder="Cari STR KT"
              startIcon={SearchIcon}
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleStatusChange}
              placeholder="Status"
              options={statusOption}
              id="status"
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HeaderStrKT);