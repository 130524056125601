/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";

import AvatarButtonComponent from "../../components/profile/AvatarButtonComponent";
import AvatarComponent from "../../components/profile/AvatarComponent";
import NameComponent from "../../components/profile/NameComponent";
import TitleComponent from "../../components/profile/TitleComponent";
import StatusComponent from "../../components/profile/StatusComponent";
import IconCV from "../../assets/icon-downloadcv";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { number } from "prop-types";
import { detailMember } from "../../actions/member/member_action";
import ModalEditBio from "./Modal_edit_bio_member";
import { getUser } from "../../helpers/user";
import ModalEditStatusMemberAktif from "../../pages/member/Modal_edit_status_member_aktif";
import ModalEditStatusMemberNonaktif from "../../pages/member/Modal_edit_status_member_nonaktif";
import ModalEditStatusMemberPensiun from "../../pages/member/Modal_edit_status_member_pensiun";
import ModalEditStatusMemberAlm from "../../pages/member/Modal_edit_status_member_alm";
import { default_api } from "../../constants/default_api";

class ProfileAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalEditBioShow: false,
      ModalEditStatusMemberAktifShow: false,
      ModalEditStatusMemberNonaktifShow: false,
      ModalEditStatusMemberPensiunShow: false,
      ModalEditStatusMemberAlmShow: false,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  unduhCV(id) {
    window.open(default_api + "/member/download/cv/" + id + ".pdf");
  }

  handleRefresh() {
    window.location.reload();
  }

  render() {
    let paboi_id, name, id, title, status, img_profile;
    const {
      ModalEditStatusMemberAktifShow,
      ModalEditStatusMemberNonaktifShow,
      ModalEditStatusMemberPensiunShow,
      ModalEditStatusMemberAlmShow,
    } = this.state;
    const { detailMember_data } = this.props;

    if (detailMember_data && detailMember_data.length > 0) {
      [
        {
          name,
          member_profile: { paboi_id, status },
          img_profile,
          id,
        },
      ] = detailMember_data;
    }

    return (
      <Column style={{ width: "100%" }} horizontal="center">
        <div
          style={{
            width: 100,
            height: 100,
            position: "relative",
          }}
        >
          <ModalEditStatusMemberAktif
            show={ModalEditStatusMemberAktifShow}
            onHide={() =>
              this.setState({ ModalEditStatusMemberAktifShow: false })
            }
            id={id}
            name={name}
            status={status}
            handleRefresh={this.handleRefresh}
          />
          <ModalEditStatusMemberNonaktif
            show={ModalEditStatusMemberNonaktifShow}
            onHide={() =>
              this.setState({ ModalEditStatusMemberNonaktifShow: false })
            }
            id={id}
            name={name}
            status={status}
            handleRefresh={this.handleRefresh}
          />
          <ModalEditStatusMemberPensiun
            show={ModalEditStatusMemberPensiunShow}
            onHide={() =>
              this.setState({ ModalEditStatusMemberPensiunShow: false })
            }
            id={id}
            name={name}
            status={status}
            handleRefresh={this.handleRefresh}
          />
          <ModalEditStatusMemberAlm
            show={ModalEditStatusMemberAlmShow}
            onHide={() =>
              this.setState({ ModalEditStatusMemberAlmShow: false })
            }
            id={id}
            name={name}
            status={status}
            handleRefresh={this.handleRefresh}
          />
          <ModalEditBio
            show={this.state.ModalEditBioShow}
            onHide={() => this.setState({ ModalEditBioShow: false })}
            handleRefresh={this.handleRefresh}
            id={id}
            name={name}
            img_profile={img_profile}
          />
          <AvatarComponent image={img_profile} />
          <AvatarButtonComponent
            style={{ position: "absolute", top: 70, left: 70 }}
            button={({ onClick }) => (
              <Row onClick={onClick} className={css(styles.clickableIcon)}>
                <IconCV />
              </Row>
            )}
          >
            {getUser().role_codes[0] === "member" ||
              (getUser().role_codes[0] === "root" && (
                <MenuItem
                  onClick={() => this.setState({ ModalEditBioShow: true })}
                >
                  Edit
                </MenuItem>
              ))}
            <MenuItem onClick={() => this.unduhCV(id)}>Unduh CV</MenuItem>
          </AvatarButtonComponent>
        </div>
        <NameComponent name={name} />
        <TitleComponent title={title} />
        <StatusComponent
          paboiId={paboi_id}
          status={status}
          statusActive={() =>
            this.setState({ ModalEditStatusMemberAktifShow: true })
          }
          statusNonactive={() =>
            this.setState({ ModalEditStatusMemberNonaktifShow: true })
          }
          statusPensiun={() =>
            this.setState({ ModalEditStatusMemberPensiunShow: true })
          }
          statusAlm={() =>
            this.setState({ ModalEditStatusMemberAlmShow: true })
          }
        />
      </Column>
    );
  }
}

const styles = StyleSheet.create({
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ detailMember }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
  };
}

export default connect(mapStateToProps)(ProfileAvatar);
