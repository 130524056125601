import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import ClearIcon from "@material-ui/icons/Clear";

import {
  approveSupport,
  detailSupport,
} from "../../actions/support/support_action";

import { getSupportPending } from "../../reducers/support/support_reducer";
import { reduxForm } from "redux-form";

class SupportModalApprove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_date: new Date(),
      graduated_date: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    // const { notes } = values;
    const { approveSupport, detailSupport, onHide } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const callback = () => {
      onHide();
      detailSupport(id);
    };
    // let formData = new FormData();
    // formData.append("id", id);
    let param = { id: id, type : 'forward' };
    // formData.append("is_approve", false);
    // formData.append("approve_file", values.approve_file);
    // formData.append("approve_notes", values.reject_notes);
    approveSupport(param, callback);
  }

  render() {
    const { show, onHide, approveSupport_pending, handleSubmit, role } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              <p style={{ marginTop: 20 }}>
                Apakah anda yakin ingin meneruskan laporan ini ke {role}?
              </p>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={<ClearIcon />}
                onClick={this.props.onHide}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={approveSupport_pending}
                className={css(styles.approveButton)}
                startIcon={
                  approveSupport_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                Ya
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

// function validate(values) {
//   const { notes } = values;
//   const errors = {};
//   if (!notes) {
//     errors.notes = "keterangan wajib diisi";
//   }
//   // if (!reject_file) {
//   //   errors.reject_file = "berkas wajib diisi";
//   // }

//   return errors;
// }

SupportModalApprove = reduxForm({
  // a unique name for the form
  form: "SupportModalApprove",
  // validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SupportModalApprove);

function mapStateToProps({ approveSupport }) {
  return {
    approveSupport_pending: getSupportPending(approveSupport),
  };
}

export default connect(mapStateToProps, {
  approveSupport,
  detailSupport,
})(withRouter(SupportModalApprove));
