import {
    BRANCHMAN_LIST_PENDING,
    BRANCHMAN_LIST_SUCCESS,
    BRANCHMAN_LIST_ERROR,
    ADD_BRANCHMAN_PENDING,
    ADD_BRANCHMAN_SUCCESS,
    ADD_BRANCHMAN_ERROR,
} from "../../actions/branch-management/branchman_action";

const initialState = {
    pending: false,
    error: null,
};

export function branchmanList(
    state = { ...initialState, branchmanList: null },
    action
) {
    switch (action.type) {
        case BRANCHMAN_LIST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case BRANCHMAN_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                branchmanList: action.data,
            };
        case BRANCHMAN_LIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addBranchman(state = initialState, action) {
    switch (action.type) {
        case ADD_BRANCHMAN_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_BRANCHMAN_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_BRANCHMAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getBranchmanListPending = (state) => state.pending;
export const getBranchmanList = (state) => state.branchmanList;
export const getBranchmanListError = (state) => state.error;
export const getBranchmanAddPending = (state) => state.pending;
export const getBranchmanAddError = (state) => state.error;