import {
  LIST_STR_PENDING,
  LIST_STR_SUCCESS,
  LIST_STR_ERROR,
  DETAIL_STR_PENDING,
  DETAIL_STR_SUCCESS,
  DETAIL_STR_ERROR,
  EDIT_STR_PENDING,
  EDIT_STR_SUCCESS,
  EDIT_STR_ERROR,
  DELETE_STR_PENDING,
  DELETE_STR_SUCCESS,
  DELETE_STR_ERROR,
  ADD_STR_PENDING,
  ADD_STR_SUCCESS,
  ADD_STR_ERROR,
  APPROVE_STR_PENDING,
  APPROVE_STR_SUCCESS,
  APPROVE_STR_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/p2kb/str_action";

const initialState = {
  pending: false,
  error: null,
};

export function listStr(state = { ...initialState, list_str: null }, action) {
  switch (action.type) {
    case LIST_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_STR_SUCCESS:
      return {
        ...state,
        pending: false,
        list_str: action.data,
      };
    case LIST_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailStr(
  state = { ...initialState, detail_str: null },
  action
) {
  switch (action.type) {
    case DETAIL_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_STR_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_str: action.data,
      };
    case DETAIL_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editStr(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_STR_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addStr(state = initialState, action) {
  switch (action.type) {
    case ADD_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_STR_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteStr(state = initialState, action) {
  switch (action.type) {
    case DELETE_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_STR_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveStr(state = initialState, action) {
  switch (action.type) {
    case APPROVE_STR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_STR_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_STR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListStr = (state) => state.list_str;
export const getDetailStr = (state) => state.detail_str;
export const getStrPending = (state) => state.pending;
export const getStrError = (state) => state.error;
export const getStrIsEditFile = (state) => state.isEditFile;
