import {
    LIST_STR_KT_PENDING,
    LIST_STR_KT_SUCCESS,
    LIST_STR_KT_ERROR,
    DETAIL_STR_KT_PENDING,
    DETAIL_STR_KT_SUCCESS,
    DETAIL_STR_KT_ERROR,
    EDIT_STR_KT_PENDING,
    EDIT_STR_KT_SUCCESS,
    EDIT_STR_KT_ERROR,
    ADD_STR_KT_PENDING,
    ADD_STR_KT_SUCCESS,
    ADD_STR_KT_ERROR,
    APPROVE_STR_KT_PENDING,
    APPROVE_STR_KT_SUCCESS,
    APPROVE_STR_KT_ERROR,
    SET_IS_EDIT_FILE,
} from "../../actions/p2kb-kt/str_kt_action";

const initialState = {
    pending: false,
    error: null,
};

export function listStrKT(state = { ...initialState, list_str_kt: null }, action) {
    switch (action.type) {
        case LIST_STR_KT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_STR_KT_SUCCESS:
            return {
                ...state,
                pending: false,
                list_str_kt: action.data,
            };
        case LIST_STR_KT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailStrKT(
    state = { ...initialState, detail_str_kt: null },
    action
) {
    switch (action.type) {
        case DETAIL_STR_KT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_STR_KT_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_str_kt: action.data,
            };
        case DETAIL_STR_KT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editStrKT(
    state = { ...initialState, isEditFile: false },
    action
) {
    switch (action.type) {
        case EDIT_STR_KT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_STR_KT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case EDIT_STR_KT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case SET_IS_EDIT_FILE:
            return {
                ...state,
                isEditFile: action.value,
            };
        default:
            return state;
    }
}

export function addStrKT(state = initialState, action) {
    switch (action.type) {
        case ADD_STR_KT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_STR_KT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_STR_KT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function approveStrKT(state = initialState, action) {
    switch (action.type) {
        case APPROVE_STR_KT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVE_STR_KT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case APPROVE_STR_KT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListStrKT = (state) => state.list_str_kt;
export const getDetailStrKT = (state) => state.detail_str_kt;
export const getStrKTPending = (state) => state.pending;
export const getStrKTError = (state) => state.error;
export const getStrKTIsEditFile = (state) => state.isEditFile;
