import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/id";

import ModalApprovalSIP from "./Modal_approval_SIP";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import {
  SIPList,
  detailSIP,
  setSelectedSIP,
} from "../../actions/pengajuan-sip/sip_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import DownloadButtonComponent from "../../components/global-components/DownloadButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import {
  getSIPPending,
  getSIPList,
  getSIPError,
} from "../../reducers/pengajuan-sip/sip_reducer";
import SIPHeader from "./SIPHeader";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import { listHospital } from "../../actions/master-data/hospital_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import { listStr } from "../../actions/p2kb/str_action";
import { getUser } from "../../helpers/user";
import SIPModalAdd from "./SIPModalAdd";
import { useDownloadMenuStyles } from "@mui-treasury/styles/menu/download";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Menu from "@material-ui/core/Menu";
import { default_api } from "../../constants/default_api";
import Button from "@material-ui/core/Button";
import { DateRange } from "@material-ui/icons";

// eslint-disable-next-line no-unused-vars

function getMessageTitle(data) {
  // console.log("getMessageTitle()", data);
  if (data.status === 0 && data.step === 1) {
    return "Menunggu";
  }

  if (data.status === 0 && data.step === 2) {
    return "Menunggu Terverifikasi";
  }

  if (data.status === 1 && data.step === 3) {
    return "Terverifikasi";
  }

  return "Ditolak";
}

class TransRS extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      id: null,
      anchorEl: "",
      modalApprovalSIPShow: false,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDownloadSurat = this.handleDownloadSurat.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailSIP(data) {
    this.props.history.push(`pengajuan-sip/detail/${data.id}`);
  }

  handleVerifikasiSIP(data) {
    this.props.history.push(`verifikasi-sip/detail/${data.id}`);
  }

  handleEditTransRS(data) {
    this.props.setSelectedSIP(data);
    this.setState({ modalEditTransRSShow: true });
  }

  handleDownloadSurat(data) {
    window.open(`${default_api}/member/download/sip_request/${data.id}.pdf`);
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  componentDidMount() {
    if (getUser().role_codes[0] === "member") {
      this.props.listBranch({ length: 9999 });
      this.props.listHospital();
      this.props.listStr({ length: 9999 });
    }
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id });
    }
  }

  render() {
    const downloadMenuClasses = useDownloadMenuStyles;

    const handleClose = () => {
      this.setState({
        anchorEl: null,
      });
    };
    const anchorEl = this.state.anchorEl;
    const user = getUser().role_codes[0];
    let data = [];
    let branchOptions = [];
    let hospitalOptions = [];
    let strOptions = [];
    let records_total = 0;
    const {
      SIPList,
      SIPList_data,
      listBranch_data,
      listHospital_data,
      listStr_data,
    } = this.props;
    if (SIPList_data) {
      data = SIPList_data.data;
      records_total = SIPList_data.records_total;
    }
    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    if (listStr_data && listStr_data.length !== 0) {
      strOptions = listStr_data.data
        .filter((item) => item.status === 1)
        .map(({ str_no, id, status }) => ({
          label: str_no,
          value: id,
        }));
    }
    return (
      <ContentContainer>
        <ModalApprovalSIP
          show={this.state.modalApprovalSIPShow}
          onHide={() => this.setState({ modalApprovalSIPShow: false, id: "" })}
          handleRefresh={this.handleRefresh}
          id={this.state.id}
        />
        <SIPModalAdd
          show={this.state.modalEditTransRSShow}
          onHide={() => this.setState({ modalEditTransRSShow: false })}
          handleRefresh={this.handleRefresh}
          branchOptions={branchOptions}
          hospitalOptions={hospitalOptions}
          strOptions={strOptions}
          sipListData={data}
        />
        <SIPHeader
          handleRefresh={this.handleRefresh}
          branchOptions={branchOptions}
          hospitalOptions={hospitalOptions}
          strOptions={strOptions}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={
            user === "member"
              ? [
                  {
                    title: "No",
                    field: "transrs_name",
                    render: (rowData) => {
                      return <>{rowData.no}</>;
                    },
                    width: 20,
                    headerStyle: {
                      paddingLeft: 30,
                    },
                    cellStyle: {
                      paddingLeft: 30,
                    },
                  },
                  {
                    title: "Tanggal Pengajuan",
                    render: (data) => {
                      return moment(data.date)
                        .locale("id", localization)
                        .format("DD MMMM YYYY");
                    },
                  },
                  {
                    title: "Cabang Tujuan",
                    field: "branch_to_data.name",
                  },
                  {
                    title: "RS Tujuan",
                    field: "hospital_to_data.name",
                  },
                  {
                    title: "Status",
                    render: (data) => {
                      let status = getMessageTitle(data);
                      return <TableStatusComponent status={status} />;
                    },
                    width: 210,
                  },
                  {
                    title: "",
                    render: (rowData) => {
                      return (
                        <DetailButtonComponent>
                          <MenuItem
                            onClick={() => this.handleDetailSIP(rowData)}
                          >
                            Detail
                          </MenuItem>
                          {/* {rowData.status === 2 &&
                                                <MenuItem onClick={() => this.handleEditTransRS(rowData)}>
                                                    Edit
                                                </MenuItem>
                                            } */}
                        </DetailButtonComponent>
                      );
                    },
                  },
                ]
              : [
                  {
                    title: "No",
                    field: "transrs_name",
                    render: (rowData) => {
                      return <>{rowData.no}</>;
                    },
                    width: 20,
                    headerStyle: {
                      paddingLeft: 30,
                    },
                    cellStyle: {
                      paddingLeft: 30,
                    },
                  },
                  {
                    title: "Tanggal Pengajuan",
                    render: (data) => {
                      return moment(data.date)
                        .locale("id", localization)
                        .format("DD MMMM YYYY");
                    },
                  },
                  {
                    title: "Member",
                    field: "user_data.name",
                  },
                  {
                    title: "ID Member",
                    field: "user_data.member_profile.paboi_id",
                  },
                  {
                    title: "Cabang Tujuan",
                    field: "branch_to_data.name",
                  },
                  {
                    title: "RS Tujuan",
                    field: "hospital_to_data.name",
                  },
                  {
                    title: "Status",
                    render: (data) => {
                      let status = getMessageTitle(data);
                      return <TableStatusComponent status={status} />;
                      // return "Ditolak";
                    },
                    width: 210,
                  },
                  {
                    title: "",
                    render: (rowData) => {
                      return (
                        <DetailButtonComponent>
                          <MenuItem
                            onClick={() => this.handleVerifikasiSIP(rowData)}
                          >
                            Detail
                          </MenuItem>
                        </DetailButtonComponent>
                      );
                    },
                  },
                ]
          }
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                id: this.state.id,
              };
              SIPList(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ SIPList, listHospital, listBranch, listStr }) {
  return {
    SIPList_data: getSIPList(SIPList),
    SIPList_error: getSIPError(SIPList),
    SIPList_pending: getSIPPending(SIPList),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
  };
}

export default connect(mapStateToProps, {
  SIPList,
  detailSIP,
  setSelectedSIP,
  listHospital,
  listBranch,
  listStr,
})(TransRS);
