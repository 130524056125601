import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { rejectDetailP2kb } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbRejectDetail,
  getP2kbRejectDetailPending,
  getP2kbRejectDetailError,
} from "../../reducers/p2kb/p2kb_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInputWithLabel,
  renderFile,
} from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class ModalRejectSKP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forRefresh: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { rejectDetailP2kb, id, onHide } = this.props;
    let formData = new FormData();
    formData.append("user_id", id);
    formData.append("is_approve", "false");
    formData.append("reject_notes", values.reject_notes);
    // Menambahkan nilai reject_file hanya jika filenya tidak kosong
    if (values.reject_file instanceof File) {
      formData.append("reject_file", values.reject_file);
    }

    const callback = () => {
      onHide();
      this.props.handleRefresh({ forRefresh: true });
    };
    rejectDetailP2kb(formData, callback);
  }

  render() {
    const { handleSubmit, rejectDetailP2kb_pending } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi Penolakan</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="reject_notes"
                  placeholder="Masukkan alasan penolakan"
                  label="Alasan penolakan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="reject_file"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={
                  rejectDetailP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                Submit
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"];
  const { reject_notes, reject_file } = values;
  const errors = {};
  if (!reject_notes) {
    errors.reject_notes = "Alasan penolakan wajib diisi";
  }
  // if (!reject_file) {
  //   errors.reject_file = "Berkas penolakan wajib diisi";
  // }
  if (
    values.reject_file &&
    values.reject_file.type !== imageType[0] &&
    values.reject_file.type !== imageType[1]
  ) {
    errors.reject_file = "Format file hanya bisa PNG/JPG/JPEG";
  } else if (values.reject_file && values.reject_file.size > maxFileSize) {
    errors.reject_file = "File lebih dari 500KB";
  }
  return errors;
}

ModalRejectSKP = reduxForm({
  form: "ModalRejectSKP",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalRejectSKP);

function mapStateToProps({ rejectDetailP2kb }) {
  return {
    rejectDetailP2kb_pending: getP2kbRejectDetailPending(rejectDetailP2kb),
    rejectDetailP2kb_data: getP2kbRejectDetail(rejectDetailP2kb),
    rejectDetailP2kb_error: getP2kbRejectDetailError(rejectDetailP2kb),
  };
}

export default connect(mapStateToProps, { rejectDetailP2kb })(ModalRejectSKP);
