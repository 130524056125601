import React from "react";

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 2 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M10 3h4v12h-4z"
      fill={props.fill || "#FFFFFF"}
    />
  </svg>
);
