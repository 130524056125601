import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addHospital } from "../../actions/master-data/hospital_action";
import { renderInput, renderSelect } from "../../components/form/FormComponent";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import { getHospitalPending } from "../../reducers/master-data/hospital_reducer";

import { provinceList } from "../../actions/master-data/province_action";
import {
  getProvinceListPending,
  getProvinceList,
  getProvinceListError,
} from "../../reducers/master-data/province_reducer";

class ModalHospitalAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addHospital, onHide, id, handleRefresh } = this.props;
    const param = {
      id: id,
      name: values.name,
      code: values.code,
      class_type: values.class_type.value,
      address: values.address,
      phone: values.phone,
      email: values.email,
      province_id: values.province.value,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    addHospital(param, callback);
  }

  render() {
    let provinceOption = [];
    const { provinceList_data } = this.props;
    if (provinceList_data) {
      provinceOption = provinceList_data.map((value, index) => {
        const province = { value: value.province_id, label: value.name };
        return province;
      });
    }
    const rsClassOption = [
      {
        label: "A",
        value: "A",
      },
      {
        label: "B",
        value: "B",
      },
      {
        label: "C",
        value: "C",
      },
      {
        label: "D",
        value: "D",
      },
      {
        label: "E",
        value: "E",
      },
    ];
    const {
      handleSubmit,
      show,
      onHide,
      addHospital_pending,
      detailHospital_pending,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Rumah Sakit</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailHospital_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <LabelInputVerticalComponent label="Kode Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="code"
                    type="number"
                    placeholder="Masukkan kode RS"
                    component={renderInput}
                  />
                  <LabelInputVerticalComponent label="Nama Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="name"
                    placeholder="Masukkan nama RS"
                    component={renderInput}
                  />
                  <LabelInputVerticalComponent label="Kelas Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="class_type"
                    placeholder="Pilih Kelas RS"
                    options={rsClassOption}
                    component={renderSelect}
                  />
                  <LabelInputVerticalComponent label="Alamat Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="address"
                    placeholder="Masukkan alamat RS"
                    component={renderInput}
                  />
                  <LabelInputVerticalComponent label="Nomor Telepon Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="phone"
                    placeholder="Masukkan telepon RS"
                    component={renderInput}
                  />
                  <LabelInputVerticalComponent label="Email Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="email"
                    placeholder="Masukkan email RS"
                    component={renderInput}
                  />
                  <LabelInputVerticalComponent label="Provinsi Rumah Sakit"></LabelInputVerticalComponent>
                  <Field
                    name="province"
                    placeholder="Pilih Provinsi"
                    options={provinceOption}
                    component={renderSelect}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addHospital_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addHospital_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { code, province, name, class_type, address, phone, email } = values;
  const errors = {};
  if (!code) {
    errors.code = "Kode rumah sakit tidak boleh kosong";
  }
  if (!province) {
    errors.province = { _error: "Provinsi tidak boleh kosong" };
  }
  if (!name) {
    errors.name = "Nama rumah sakit tidak boleh kosong";
  }
  if (!class_type) {
    errors.class_type = "Kelas rumah sakit tidak boleh kosong";
  }
  if (!address) {
    errors.address = "Alamat rumah sakit tidak boleh kosong";
  }
  if (!phone) {
    errors.phone = "Nomor telephone rumah sakit tidak boleh kosong";
  }
  if (!email) {
    errors.email = "Email rumah sakit tidak boleh kosong";
  }
  return errors;
}

ModalHospitalAdd = reduxForm({
  // a unique name for the form
  form: "ModalAddHospital",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ModalHospitalAdd);

function mapStateToProps({ addHospital, provinceList }) {
  return {
    addHospital_pending: getHospitalPending(addHospital),

    provinceList_pending: getProvinceListPending(provinceList),
    provinceList_data: getProvinceList(provinceList),
    provinceList_error: getProvinceListError(provinceList),
  };
}

export default connect(mapStateToProps, {
  addHospital,
  provinceList,
})(withRouter(ModalHospitalAdd));
