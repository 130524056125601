import React, { Component } from "react";
import { connect } from "react-redux";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import DetailButton from "./DetailButton";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import LocationIcon from "../../assets/icon-location";
import { reportSHU } from "../../actions/dashboard/koperasi_action";
import {
  getReportSHUData,
  getReportSHUPending,
  getReportSHUError,
} from "../../reducers/dashboard/koperasi_reducer";
import { default_api } from "../../constants/default_api";
import avatarImage from "../../assets/avatar.png";

class CardLaporanDanaSHU extends Component {
  render() {
    const { reportSHU } = this.props;
    return (
      <div className={css(styles.danaSHUWrapper)}>
        <Column>
          <Row horizontal="space-between">
            <label className={css(styles.danaSHUTitle)}>
              Laporan Dana SHU Tahun {new Date().getFullYear()}
            </label>
            <DetailButton
              detail_url={
                "http://" +
                window.location.href.split("/")[2] +
                "/admin/iuran-koperasi"
              }
            />
          </Row>
          <MaterialTable
            columns={[
              {
                title: (
                  <span className={css(styles.tableHeader)}>Nama Anggota</span>
                ),
                render: ({ member_data: { img_profile, name } }) => (
                  <Row vertical="center">
                    <img
                      src={
                        img_profile
                          ? `${default_api}/image/show/user_profile/${img_profile}`
                          : avatarImage
                      }
                      alt="profile"
                      height={40}
                      width={40}
                      style={{ borderRadius: 20 }}
                    />
                    <div style={{ marginLeft: 20 }}>
                      <span className={css(styles.tableFont)}>{name}</span>
                    </div>
                  </Row>
                ),
              },
              {
                title: (
                  <span className={css(styles.tableHeader)}>Cabang PABOI</span>
                ),
                render: ({ member_data }) => {
                  return (
                    <>
                      <LocationIcon fill="#495057" />{" "}
                      <span className={css(styles.tableFont)}>
                        {member_data?.branch_data?.name}
                      </span>
                    </>
                  );
                },
              },
              {
                title: (
                  <span className={css(styles.tableHeader)}>Total SHU</span>
                ),
                render: ({ amount }) => {
                  const total = amount
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
                  return (
                    <span className={css(styles.danaSHUTitle)}>Rp {total}</span>
                  );
                },
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let param = {
                  page: query.page + 1,
                  length: 10,
                };
                reportSHU(param, resolve, reject);
              })
            }
            options={{
              pageSize: 3,
              paging: false,
              pageSizeOptions: [],
              showTitle: false,
              search: false,
              headerStyle: {
                zIndex: 0,
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </Column>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  danaSHUWrapper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 13px 29px #0000000D",
    borderRadius: 5,
    padding: 30,
    marginBottom: 10,
  },
  danaSHUTitle: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20,
  },
  tableFont: {
    fontSize: 16,
  },
  tableHeader: {
    fontSize: 16,
    color: "#495057",
    opacity: 0.6,
    fontWeight: "bold",
  },
});

function mapStateToProps({ reportSHU }) {
  return {
    reportSHU_data: getReportSHUData(reportSHU),
    reportSHU_error: getReportSHUError(reportSHU),
    reportSHU_pending: getReportSHUPending(reportSHU),
  };
}

export default connect(mapStateToProps, { reportSHU })(CardLaporanDanaSHU);
