import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_TRANSRS_PENDING = "LIST_TRANSRS_PENDING";
export const LIST_TRANSRS_SUCCESS = "LIST_TRANSRS_SUCCESS";
export const LIST_TRANSRS_ERROR = "LIST_TRANSRS_ERROR";
export const EDIT_TRANSRS_PENDING = "EDIT_TRANSRS_PENDING";
export const EDIT_TRANSRS_SUCCESS = "EDIT_TRANSRS_SUCCESS";
export const EDIT_TRANSRS_ERROR = "EDIT_TRANSRS_ERROR";
export const ADD_TRANSRS_PENDING = "ADD_TRANSRS_PENDING";
export const ADD_TRANSRS_SUCCESS = "ADD_TRANSRS_SUCCESS";
export const ADD_TRANSRS_ERROR = "ADD_TRANSRS_ERROR";
export const APPROVAL_TRANSRS_PENDING = "APPROVAL_TRANSRS_PENDING";
export const APPROVAL_TRANSRS_SUCCESS = "APPROVAL_TRANSRS_SUCCESS";
export const APPROVAL_TRANSRS_ERROR = "APPROVAL_TRANSRS_ERROR";
export const DETAIL_TRANSRS_PENDING = "APPROVAL_TRANSRS_PENDING";
export const DETAIL_TRANSRS_SUCCESS = "APPROVAL_TRANSRS_SUCCESS";
export const DETAIL_TRANSRS_ERROR = "APPROVAL_TRANSRS_ERROR";
export const UPLOAD_TRANSRS_PENDING = "UPLOAD_TRANSRS_PENDING";
export const UPLOAD_TRANSRS_SUCCESS = "UPLOAD_TRANSRS_SUCCESS";
export const UPLOAD_TRANSRS_ERROR = "UPLOAD_TRANSRS_ERROR";
export const SET_SELECTED_TRANSRS = "SET_SELECTED_TRANSRS";

const LIST_TRANSRS_URL = "member/hospital_mutation/find";
const EDIT_TRANSRS_URL = "member/hospital_mutation/edit";
const ADD_TRANSRS_URL = "member/hospital_mutation/add";
const APPROVAL_TRANSRS_URL = "member/hospital_mutation/approve";
const UPLOAD_TRANSRS_URL = "image/upload/member_hospital_mutation";

export function transrsList(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_TRANSRS_PENDING));
    API.post(LIST_TRANSRS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_TRANSRS_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 10,
        }));
        if (resolve) {
          if (detail) {
            let detailSKP = data[0].p2kb_details.map((item, i) => ({
              ...item,
              no: i + 1 + (param.page - 1) * 10,
            }));
            resolve({
              data: detailSKP,
              page: param.page - 1,
              totalCount: records_total,
            });
          } else {
            resolve({
              data: data,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_TRANSRS_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function editTransrs(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_TRANSRS_PENDING));
    API.post(EDIT_TRANSRS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_TRANSRS_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah transrs");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_TRANSRS_ERROR, error));
        toastError(error, "Gagal mengubah transrs, ");
      });
  };
}

export function addTransrs({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_TRANSRS_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_TRANSRS_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.surat_pendukung_filename = filename;
        return API.post(ADD_TRANSRS_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_TRANSRS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan trans RS");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_TRANSRS_ERROR, error));
        toastError(error, "");
      });
  };
}

export function uploadTransrs(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_TRANSRS_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_TRANSRS_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_TRANSRS_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_TRANSRS_ERROR, error));
        toastError(error, "Gagal upload berkas, ");
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function approvalTransrs(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_TRANSRS_PENDING));
    API.post(APPROVAL_TRANSRS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_TRANSRS_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status transrs");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_TRANSRS_ERROR, error));
        toastError(error, "Gagal mengubah status transrs, ");
      });
  };
}

export function setSelectedTransrs(value) {
  return {
    type: SET_SELECTED_TRANSRS,
    value: value,
  };
}

export function detailTransrs(id = null, callback) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_TRANSRS_PENDING));
    API.post(LIST_TRANSRS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_TRANSRS_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_TRANSRS_ERROR, error));
        toastError(error, "Gagal mendapatkan detail TRANSRS, ");
      });
  };
}
export function detailTransrsSerkom(user_id = null, serkom_id = null) {
  const param = {
    user_id,
    serkom_id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_TRANSRS_PENDING));
    API.post(LIST_TRANSRS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_TRANSRS_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_TRANSRS_ERROR, error));
        toastError(error, "Gagal mendapatkan detail TRANSRS, ");
      });
  };
}
