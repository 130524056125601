import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/layout/layout";
import PrivateRoute from "./components/global-components/PrivateRoute";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "./helpers/user";

import newLogin from "./pages/login/newLogin";
import register from "./pages/login/register";
import verifyPassword from "./pages/login/verifyPassword";
import ListPPDS from "./pages/ppds/ppds";
import ListPPDSSP2 from "./pages/ppds_sp2/ppds";
import ListManajemenPPDS from "./pages/manajemen-ppds/ListManajemenPPDS";
import ProfilePPDS from "./pages/manajemen-ppds/Profile";
import PPDSAdmin from "./pages/ppds-admin/ListManajemenPPDS";
import PPDSDetail from "./pages/manajemen-ppds/PPDSDetail";
import DetailPPDS from "./pages/ppds/DetailPPDS";
import DetailPPDSSP2 from "./pages/ppds_sp2/DetailPPDS";
import ListPengajuanSIP from "./pages/pengajuan-sip/ListPengajuanSIP";
import ListVerifikasiSIP from "./pages/pengajuan-sip/ListVerifikasiSIP";
import DetailPengajuanSIP from "./pages/pengajuan-sip/SIPDetail";
import DetailVerifikasiSIP from "./pages/pengajuan-sip/SIPDetail";
import ListRegistrasi from "./pages/registrasi/Registrasi";
import ListRegistrasiSP2 from "./pages/registrasi_sp2/Registrasi";
import ListMember from "./pages/member/Member";
import ListHospital from "./pages/hospital/Hospital";
import ListSubspesialis from "./pages/subspesialis/Subspesialis";
import ListPractice from "./pages/practice/Practice";
import PracticeDetail from "./pages/practice/PracticeDetail";
import ListUniversitas from "./pages/universitas/Universitas";
import ListPengguna from "./pages/pengguna/Pengguna";
import DetailRegistrasiInformasiUmum from "./pages/registrasi/Detail_registrasi_informasiUmum";
import DetailRegistrasiPendidikanAsal from "./pages/registrasi/Detail_registrasi_pendidikanAsal";
import DetailRegistrasiInformasiPPDS from "./pages/registrasi/Detail_registrasi_informasiPPDS";
import DetailRegistrasiSP2InformasiUmum from "./pages/registrasi_sp2/Detail_registrasi_informasiUmum";
import DetailRegistrasiSP2PendidikanAsal from "./pages/registrasi_sp2/Detail_registrasi_pendidikanAsal";
import DetailRegistrasiSP2InformasiPPDS from "./pages/registrasi_sp2/Detail_registrasi_informasiPPDS";
import DetailMemberPengantar from "./pages/member/Detail_member_pengantar";
// import DetailMemberProfile from "./pages/member/Detail_member_profile";
import DetailMemberP2KB from "./pages/member/Detail_member_P2KB";
import DetailP2kbApproval from "./pages/member/Detail_member_P2KB_approval";
import DetailPengalaman from "./pages/pengalaman_member/Detail_pengalaman";
import Branch from "./pages/branch/Branch";
import Activity from "./pages/activity/Activity";
import Pengalaman from "./pages/pengalaman_member/Pengalaman";
import Appreciation from "./pages/appreciation/Appreciation";
import AppreciationDetail from "./pages/appreciation/AppreciationDetail";
import AppreciationAdmin from "./pages/appreciation-admin/Appreciation";
import AppreciationDetailAdmin from "./pages/appreciation-admin/AppreciationDetail";
import Education from "./pages/education/Education";
import EducationDetail from "./pages/education/EducationDetail";
import P2kb from "./pages/member-p2kb/p2kb";
import DetailMemberPengantarMember from "./pages/member-member/Detail_member_pengantar_member";
// import DetailP2kb from "./pages/member-p2kb/tindakan-operasi/Detail_p2kb";
// import AddDetailSKP from "./pages/member-p2kb/tindakan-operasi/Add_detail_skp";
import DetailP2kb_TindakanOperasi from "./pages/member-p2kb/tindakan-operasi/Detail_p2kb";
import DetailP2kb_PelayananMedis from "./pages/member-p2kb/pelayanan-medis/Detail_p2kb";
import DetailP2kb_PertemuanAuditor from "./pages/member-p2kb/pertemuan-auditor/Detail_p2kb";
import DetailP2kb_Seminar from "./pages/member-p2kb/seminar/Detail_p2kb";
import DetailP2kb_KepengurusanProfesi from "./pages/member-p2kb/kepengurusan-profesi/Detail_p2kb";
import AddDetailSKP from "./pages/member-p2kb/AddDetail";
import AddDetailSKP_TindakanOperasi from "./pages/member-p2kb/tindakan-operasi/Add_detail_skp";
import AddDetailSKP_PelayananMedis from "./pages/member-p2kb/pelayanan-medis/Add_detail_skp";
import AddDetailSKP_PertemuanAuditor from "./pages/member-p2kb/pertemuan-auditor/Add_detail_skp";
import AddDetailSKP_Pelatihan from "./pages/member-p2kb/mengikuti-pelatihan/Add_detail_skp";
import DetailP2kb_MengikutiPelatihan from "./pages/member-p2kb/mengikuti-pelatihan/Detail_p2kb";
import DetailP2kb_MembacaJurnal from "./pages/member-p2kb/membaca-jurnal/Detail_p2kb";
import DetailP2kb_PenelusuranInformasi from "./pages/member-p2kb/penelusuran-informasi/Detail_p2kb";
import DetailP2kb_PenelitianDipublikasikan from "./pages/member-p2kb/penelitian-dipublikasikan/Deatil_p2kb";
import AddDetailSKP_MembacaJurnal from "./pages/member-p2kb/membaca-jurnal/Add_detail_skp";
import AddDetailSKP_PenelusuranInformasi from "./pages/member-p2kb/penelusuran-informasi/Add_detail_skp";
import DetailP2kb_AcaraIlmiah from "./pages/member-p2kb/acara-ilmiah/Detail_p2kb";
import DetailP2kb_PraktekMahasiswa from "./pages/member-p2kb/praktek-mahasiswa/Detail_p2kb";
import DetailP2kb_PenyeliaSupervisor from "./pages/member-p2kb/penyelia-supervisor/Detail_p2kb";
import AddDetailSKP_AcaraIlmiah from "./pages/member-p2kb/acara-ilmiah/Add_detail_skp";
import AddDetailSKP_Seminar from "./pages/member-p2kb/seminar/Add_detail_skp";
import AddDetailSKP_KepengurusanProfesi from "./pages/member-p2kb/kepengurusan-profesi/Add_detail_skp";
import AddDetailSKP_PenelitianDipublikasikan from "./pages/member-p2kb/penelitian-dipublikasikan/Add_detail_skp";
import AddDetailSKP_PenyeliaSupervisor from "./pages/member-p2kb/penyelia-supervisor/Add_detail_skp";
import AddDetailSKP_PraktekMahasiswa from "./pages/member-p2kb/praktek-mahasiswa/Add_detail_skp";
import KajianIlmiah from "./pages/member-p2kb/kajian-ilmiah/KajianIlmiah";
import KajianIlmiahDetail from "./pages/member-p2kb/kajian-ilmiah/KajianIlmiahDetail";
import MenghadiriKongres from "./pages/member-p2kb/menghadiri-kongres/MenghadiriKongres";
import MenghadiriKongresDetail from "./pages/member-p2kb/menghadiri-kongres/MenghadiriKongresDetail";
import EdukasiPasien from "./pages/member-p2kb/edukasi-pasien/EdukasiPasien";
import EdukasiPasienDetail from "./pages/member-p2kb/edukasi-pasien/EdukasiPasienDetail";
import MenulisIlmiah from "./pages/member-p2kb/menulis-ilmiah/MenulisIlmiah";
import MenulisIlmiahDetail from "./pages/member-p2kb/menulis-ilmiah/MenulisIlmiahDetail";
import MenulisBuku from "./pages/member-p2kb/menulis-buku/MenulisBuku";
import MenulisBukuDetail from "./pages/member-p2kb/menulis-buku/MenulisBukuDetail";
import MembimbingMahasiswa from "./pages/member-p2kb/membimbing-mahasiswa/MembimbingMahasiswa";
import MembimbingMahasiswaDetail from "./pages/member-p2kb/membimbing-mahasiswa/MembimbingMahasiswaDetail";
import MengajarMahasiswa from "./pages/member-p2kb/mengajar-mahasiswa/MengajarMahasiswa";
import MengajarMahasiswaDetail from "./pages/member-p2kb/mengajar-mahasiswa/MengajarMahasiswaDetail";
import PengMas from "./pages/member-p2kb/pengabdian-masyarakat/Add_detail_skp";
import PengMasDetail from "./pages/member-p2kb/pengabdian-masyarakat/Detail_p2kb";
import MenciptakanAlat from "./pages/member-p2kb/menciptakan-alat/Add_detail_skp";
import MenciptakanAlatDetail from "./pages/member-p2kb/menciptakan-alat/Detail_p2kb";
import MembuatVideo from "./pages/member-p2kb/membuat-video/Add_detail_skp";
import MembuatVideoDetail from "./pages/member-p2kb/membuat-video/Detail_p2kb";
import MengujiMahasiswa from "./pages/member-p2kb/menguji-mahasiswa/Add_detail_skp";
import MengujiMahasiswaDetail from "./pages/member-p2kb/menguji-mahasiswa/Detail_p2kb";
import kajianElektronik from "./pages/member-p2kb/kajian-elektronik/Add_detail_skp";
import kajianElektronikDetail from "./pages/member-p2kb/kajian-elektronik/Detail_p2kb";
import ListStr from "./pages/str/ListStr";
import ListSip from "./pages/sip/ListSip";
import EducationAdmin from "./pages/education-admin/Education";
import EducationDetailAdmin from "./pages/education-admin/EducationDetail";
import SipAdmin from "./pages/sip-admin/Sip";
import SipDetailAdmin from "./pages/sip-admin/SipDetail";
import SipDetail from "./pages/sip/SipDetail";
import StrDetail from "./pages/str/StrDetail";
import StrKTDetail from "./pages/str-kt/StrKTDetail";
import StrAdmin from "./pages/str-admin/Str";
import StrDetailAdmin from "./pages/str-admin/StrDetail";
import Serkom from "./pages/serkom/Serkom";
import SerkomDetail from "./pages/serkom/SerkomDetail";
import SerkomAdmin from "./pages/serkom-admin/Serkom";
import SerkomDetailAdmin from "./pages/serkom-admin/SerkomDetail";
import Master from "./pages/master/Master";
import Mutation from "./pages/mutation/Mutation";
import MutationDetail from "./pages/mutation/MutationDetail";
import MutationAdmin from "./pages/mutation-admin/Mutation";
import MutationDetailAdmin from "./pages/mutation-admin/MutationDetail";
import Support from "./pages/support/Support";
import SupportDetail from "./pages/support/SupportDetail";
import SupportAdd from "./pages/support/SupportAdd";
import SupportAdmin from "./pages/support-admin/Support";
import SupportDetailAdmin from "./pages/support-admin/SupportDetail";
import Report from "./pages/report/Report";
import ReportAdmin from "./pages/report-admin/Report";
import TransferAdmin from "./pages/report-admin/Transfer";
import TransferCabang from "./pages/report-admin/TransferCabang";
import Shu from "./pages/report-admin/Shu";
import ReportCabang from "./pages/report-admin/ReportCabang";
// import SupportAdd from "./pages/support/SupportAdd";
// import MutationAdmin from "./pages/mutation-admin/Mutation";
// import MutationDetailAdmin from "./pages/mutation-admin/MutationDetail";
// import SerkomAdmin from "./pages/serkom-admin/Serkom";
// import SerkomDetailAdmin from "./pages/serkom-admin/SerkomDetail";
import Profile from "./pages/profile/Profile";
import ProfileAdmin from "./pages/profile-admin/Profile";
import Pelatihan from "./pages/pelatihan/Pelatihan";
import DetailPelatihan from "./pages/pelatihan/Detail_pelatihan";
import TrainingAdmin from "./pages/training-admin/Training";
import TrainingDetailAdmin from "./pages/training-admin/TrainingDetail";
import ExperienceAdmin from "./pages/experience-admin/Experience";
import ExperienceDetailAdmin from "./pages/experience-admin/ExperienceDetail";
import MemberP2KB from "./pages/admin-p2kb/member";
import DetailMemberP2KBAdmin from "./pages/admin-p2kb/List_skp_member";
import ListRanahP2KBAdmin from "./pages/admin-p2kb/List_ranah_member";
import SKPTindakanOperasiApproval from "./pages/admin-p2kb/Skp_tindakan_operasi_approval";
import SKPPelayananMedisApproval from "./pages/admin-p2kb/Skp_pelayanan_medis_approval";
import SKPPertemuanAuditorApproval from "./pages/admin-p2kb/Skp_pertemuan_auditor_approval";
import SKPMembacaJurnalApproval from "./pages/admin-p2kb/Skp_membaca_jurnal_approval";
import SKPPenelusuranInformasiApproval from "./pages/admin-p2kb/Skp_penelusuran_informasi_approval";
import SKPAcaraIlmiahApproval from "./pages/admin-p2kb/Skp_acara_ilmiah_approval";
import SKPSeminarApproval from "./pages/admin-p2kb/Skp_seminar_approval";
import SKPPelatihanApproval from "./pages/admin-p2kb/Skp_pelatihan_approval";
import SKPKinerjaIlmiahApproval from "./pages/admin-p2kb/Skp_kinerja_ilmiah_approval";
import SKPMenghadiriKongresApproval from "./pages/admin-p2kb/Skp_menghadiri_kongres_approval";
import SKPEdukasiPasienApproval from "./pages/admin-p2kb/Skp_edukasi_pasien_approval";
import SKPMenulisIlmiahApproval from "./pages/admin-p2kb/Skp_menulis_ilmiah_approval";
import SKPMenulisBukuApproval from "./pages/admin-p2kb/Skp_menulis_buku_approval";
import SKPMembimbingMahasiswaApproval from "./pages/admin-p2kb/Skp_membimbing_mahasiswa_approval";
import SKPMengajarMahasiswaApproval from "./pages/admin-p2kb/Skp_mengajar_mahasiswa_approval";
import SKPKepengurusanProfesiApproval from "./pages/admin-p2kb/Skp_kepengurusan_profesi_approval";
import SKPPengabdianMasyarakatApproval from "./pages/admin-p2kb/Skp_pengabdian_masyarakat_approval";
import SKPPenelitianDipublikasikan from "./pages/admin-p2kb/Skp_penelitian_dipublikasikan_approval";
import SKPPenyeliaSupervisor from "./pages/admin-p2kb/Skp_penyelia_supervisor_approval";
import SKPKajianElektronikApproval from "./pages/admin-p2kb/Skp_kajian_elektronik_approval";
import SKPManciptakanAlatApproval from "./pages/admin-p2kb/Skp_menciptakan_alat_approval";
import SKPPraktekMahasiswa from "./pages/admin-p2kb/Skp_praktek_mahasiswa_approval";
import SKPMembuatVideoApproval from "./pages/admin-p2kb/Skp_membuat_video_approval";
import SKPMengujiMahasiswaApproval from "./pages/admin-p2kb/Skp_menguji_mahasiswa_approval";
import listEvent from "./pages/event/Event";
import listParticipant from "./pages/event/Participant";
import ParticipantDetail from "./pages/event/ParticipantDetail";
import listJournal from "./pages/journal/Journal";
import detailJournal from "./pages/journal/JournalDetail";
import detailJournalAbstrak from "./pages/journal/JournalDetail_abstrak";
import detailJournalRevisi from "./pages/journal/JournalDetail_revisi";
import ListIuran from "./pages/iuran/Iuran";
import DetailIuran from "./pages/iuran/Detail_iuran";
import ListKoperasi from "./pages/koperasi/Koperasi";
import DetailKoperasi from "./pages/koperasi/Detail_koperasi";
import ListBenefit from "./pages/benefit/Benefit";
import DetailBenefit from "./pages/benefit/Detail_benefit";
import ListMasterBenefit from "./pages/master-benefit/Benefit";
import TransRS from "./pages/trans-rs/TransRS";
import DetailTransRS from "./pages/trans-rs/Detail_transrs";
import Pengurus from "./pages/pengurus-cabang/Pengurus";
import DashboardAdminPusat from "./pages/dashboard/AdminPusat";
import DashboardAdminKolegium from "./pages/dashboard/AdminKolegium";
import DashboardAdminCabang from "./pages/dashboard/AdminCabang";
import DashboardAdminKoperasi from "./pages/dashboard/AdminKoperasi";
import ListStrKT from "./pages/str-kt/ListStrKT";
import MessageBlast from "./pages/message-blast/MessageBlast";
import MessageBlastAdd from "./pages/message-blast/MessageBlastAdd";
import DetailMessageBlast from "./pages/message-blast/Detail_MessageBlast";
import Advertisement from "./pages/advertisement/Advertisement";
import FinalPaper from "./pages/cms-final-paper/FinalPaper";
import DetailFinalPaper from "./pages/cms-final-paper/FinalPaperDetail";
import MasterReport from "./pages/report-admin/MasterReport";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import forgotPassword from "./pages/login/forgotPassword";
import Membership from "./pages/membership/Membership";
import AdvertisementDetail from "./pages/advertisement/AdvertisementDetail";
import AuditTrail from "./pages/audit-trail/Audit_trail";
import SHU from "./pages/shu/Shu";
import SHUMember from "./pages/report-admin/ShuMember";
import Iuran from "./pages/report-admin/Iuran";
import IuranMember from "./pages/report-admin/IuranMember";
import Notification from "./pages/notification/Notification";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2CB96A",
      contrastText: "#FFFFFF",

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      container: {
        marginTop: 20,
        border: "1px solid #EFF0F2",
        minHeight: 300,
        borderRadius: 5,
        backgroundColor: "#FCFCFC",
      },
      toolbar: {
        // backgroundColor: "red",
        borderBottom: "1px solid #EFF0F2",
      },
      root: {
        width: "100%",
      },
      editorContainer: {
        padding: 20,
        fontFamily: "Circular Std Book",
        fontSize: 14,
        lineHeight: 1.6,
        color: "#7F8388",
      },
    },
  },
});

class App extends Component {
  componentDidMount() {
    // window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => this.forceUpdate();

  render() {
    let role;
    if (getUser()) {
      role = getUser().role_codes[0];
    }

    const ContentRoute = () => {
      return (
        <Layout>
          <Switch>
            <PrivateRoute
              head={["Home", "Dashboard"]}
              path="/dashboard-admin-pusat"
              roles={["root", "ketua_paboi_pusat"]}
              exact
              component={DashboardAdminPusat}
            />
            <PrivateRoute
              head={["Home", "Dashboard"]}
              path="/dashboard-admin-kolegium"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={DashboardAdminKolegium}
            />
            <PrivateRoute
              head={["Home", "Dashboard"]}
              path="/dashboard-admin-cabang"
              roles={["admin_paboi_cabang", "ketua_paboi_cabang"]}
              exact
              component={DashboardAdminCabang}
            />
            <PrivateRoute
              head={["Home", "Dashboard"]}
              path="/dashboard-admin-koperasi"
              roles={["admin_koperasi"]}
              exact
              component={DashboardAdminKoperasi}
            />
            <PrivateRoute
              head={["Home", "Dashboard"]}
              path="/dashboard"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={() => <div>Dashboard</div>}
            />
            <PrivateRoute
              head={["Home", "Registrasi"]}
              path="/registrasi"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListRegistrasi}
            />
            <PrivateRoute
              head={["Home", "Registrasi"]}
              path="/registrasi/:id"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListRegistrasi}
            />
            <PrivateRoute
              head={["Home", "Registrasi SP2"]}
              path="/registrasi-sp2"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListRegistrasiSP2}
            />
            <PrivateRoute
              head={["Home", "Registrasi SP2"]}
              path="/registrasi-sp2/:id"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListRegistrasiSP2}
            />
            <PrivateRoute
              head={["Home", "Manajemen Anggota"]}
              path="/koperasi/registrasi"
              roles={["admin_koperasi"]}
              exact
              component={Membership}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Informasi Umum"]}
              path="/registrasi/detail/informasi-umum/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiInformasiUmum}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Pendidikan Asal"]}
              path="/registrasi/detail/pendidikan-asal/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiPendidikanAsal}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Informasi PPDS"]}
              path="/registrasi/detail/informasi-ppds/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiInformasiPPDS}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Informasi Umum"]}
              path="/registrasi-sp2/detail/informasi-umum/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiSP2InformasiUmum}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Pendidikan Asal"]}
              path="/registrasi-sp2/detail/pendidikan-asal/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiSP2PendidikanAsal}
            />
            <PrivateRoute
              head={["Home", "Registrasi", "Informasi PPDS"]}
              path="/registrasi-sp2/detail/informasi-ppds/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailRegistrasiSP2InformasiPPDS}
            />
            <PrivateRoute
              head={["Home", "Member"]}
              path="/member/detail/pengantar/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
              ]}
              exact
              component={DetailMemberPengantar}
            />
            <PrivateRoute
              head={["Home", "Member", "Profil", "Detail"]}
              path="/member/detail/profile/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "ketua_paboi_cabang",
                "cpd_cme",
              ]}
              // exact
              component={ProfileAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Detail"]}
              path="/member/detail/p2kb/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
              ]}
              exact
              component={DetailMemberP2KB}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Approval"]}
              path="/member/detail/p2kb/approval/:id"
              roles={[
                "root",
                "admin_kolegium",
                "admin_paboi_cabang",
                "ketua_kolegium",
                "cpd_cme",
              ]}
              exact
              component={DetailP2kbApproval}
            />
            <PrivateRoute
              head={["Home", "PPDS"]}
              path="/ppds"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListPPDS}
            />
            <PrivateRoute
              head={["Home", "PPDS"]}
              path="/ppds-sp2"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListPPDSSP2}
            />
            <PrivateRoute
              head={["Home", "Manajemen PPDS", "Pendidikan"]}
              path="/manajemen-ppds"
              roles={["ppds"]}
              exact
              component={ListManajemenPPDS}
            />
            <PrivateRoute
              head={["Home", "Manajemen PPDS", "Profile"]}
              path="/manajemen-ppds/member-profile"
              roles={["ppds"]}
              exact
              component={ProfilePPDS}
            />

            <PrivateRoute
              head={["Home", "Pengajuan SIP"]}
              path="/pengajuan-sip"
              roles={["member"]}
              exact
              component={ListPengajuanSIP}
            />
            <PrivateRoute
              head={["Home", "Pengajuan SIP", "Detail"]}
              path="/pengajuan-sip/detail/:id"
              roles={["member"]}
              exact
              component={DetailPengajuanSIP}
            />
            <PrivateRoute
              head={["Home", "Verifikasi SIP", "Detail"]}
              path="/verifikasi-sip/detail/:id"
              roles={["admin_paboi_cabang", "ketua_paboi_cabang"]}
              exact
              component={DetailVerifikasiSIP}
            />
            <PrivateRoute
              head={["Home", "Verifikasi SIP"]}
              path="/verifikasi-sip"
              roles={["admin_paboi_cabang", "ketua_paboi_cabang"]}
              exact
              component={ListPengajuanSIP}
            />

            <PrivateRoute
              head={["Home", "Data Referensi", "Universitas"]}
              path="/universitas"
              roles={["root"]}
              exact
              component={ListUniversitas}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Universitas"]}
              path="/universitas/:id"
              roles={["root"]}
              exact
              component={ListUniversitas}
            />
            <PrivateRoute
              head={["Home", "System", "Pengguna"]}
              path="/pengguna"
              roles={["root"]}
              exact
              component={ListPengguna}
            />
            <PrivateRoute
              head={["Home", "Member", "Profile"]}
              path="/member/member-profile"
              roles={["member", "ppds"]}
              component={Profile}
            />
            <PrivateRoute
              head={["Home", "PPDS", "Detail"]}
              path="/ppds/detail/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              // exact
              component={DetailPPDS}
            />
            <PrivateRoute
              head={["Home", "PPDS", "Detail"]}
              path="/ppds-sp2/detail/:id"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              // exact
              component={DetailPPDSSP2}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SKP"]}
              path="/admin-p2kb"
              roles={[
                "root",
                "admin_kolegium",
                "admin_paboi_cabang",
                "ketua_kolegium",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={MemberP2KB}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SKP", "SKP Nama Member"]}
              path="/admin-p2kb/member/:id"
              link={["/admin-p2kb", "/admin-p2kb", "/admin-p2kb", ""]}
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={ListRanahP2KBAdmin}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Ranah Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={DetailMemberP2KBAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom", "Detail", "SKP Nama Member"]}
              link={["/admin/serkom", "/admin/serkom", "/admin/serkom"]}
              path="/admin/serkom/detail/:id/:serkom_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_pusat",
              ]}
              exact
              component={ListRanahP2KBAdmin}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/tindakan-operasi/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPTindakanOperasiApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/pelayanan-medis/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPelayananMedisApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/pertemuan-auditor/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPertemuanAuditorApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/membaca-jurnal/:id"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMembacaJurnalApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/penelusuran-informasi/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPenelusuranInformasiApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/acara-ilmiah/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPAcaraIlmiahApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/seminar/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPSeminarApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/pelatihan/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPelatihanApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/kinerja-ilmiah/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPKinerjaIlmiahApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/menghadiri-kongres/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMenghadiriKongresApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/edukasi-pasien/:activity_id/:skp_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPEdukasiPasienApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/menulis-ilmiah/:activity_id/:skp_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMenulisIlmiahApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/menulis-buku/:activity_id/:skp_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMenulisBukuApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/membimbing-mahasiswa/:activity_id/:skp_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMembimbingMahasiswaApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/mengajar-mahasiswa/:activity_id/:skp_id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMengajarMahasiswaApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/kepengurusan-profesi/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPKepengurusanProfesiApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/pengabdian-masyarakat/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPengabdianMasyarakatApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/penelitian-dipublikasikan/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPenelitianDipublikasikan}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/penyelia-supervisor/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPenyeliaSupervisor}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/praktek-mahasiswa/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPPraktekMahasiswa}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/kajian-elektronik/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPKajianElektronikApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/menciptakan-alat/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPManciptakanAlatApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/membuat-video/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMembuatVideoApproval}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={[
                "/admin-p2kb",
                "/admin-p2kb",
                "/admin-p2kb/member/:id",
                "/admin-p2kb/:id",
                "",
              ]}
              path="/admin-p2kb/menguji-mahasiswa/:id"
              roles={[
                "root",
                "admin_kolegium",
                "admin_paboi_cabang",
                "ketua_kolegium",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SKPMengujiMahasiswaApproval}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SKP", "Detail"]}
              path="/admin-p2kb/:id/:skpid"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={DetailMemberP2KBAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Profil"]}
              path="/member"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={ListMember}
            />
            <PrivateRoute
              head={["Home", "Member", "Penghargaan"]}
              path="/member/member-appreciation"
              roles={["member"]}
              exact
              component={Appreciation}
            />
            <PrivateRoute
              head={["Home", "Member", "Penghargaan", "Detail"]}
              path="/member/member-appreciation/detail/:id"
              roles={["member"]}
              exact
              component={AppreciationDetail}
            />
            <PrivateRoute
              head={["Home", "Member", "Penghargaan"]}
              path="/admin/member-appreciation"
              roles={[
                "admin_kolegium",
                "ketua_kolegium",
                "root",
                "admin_paboi_cabang",
              ]}
              exact
              component={AppreciationAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Penghargaan", "Detail"]}
              path="/admin/member-appreciation/detail/:id"
              roles={[
                "admin_kolegium",
                "ketua_kolegium",
                "root",
                "admin_paboi_cabang",
              ]}
              exact
              component={AppreciationDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman"]}
              path="/admin/member-experience"
              roles={[
                "admin-kolegium",
                "ketua_kolegium",
                "root",
                "admin_paboi_cabang",
              ]}
              exact
              component={ExperienceAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman"]}
              path="/admin/member-experience/:id"
              roles={[
                "admin-kolegium",
                "ketua_kolegium",
                "root",
                "admin_paboi_cabang",
              ]}
              exact
              component={ExperienceAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman", "Detail"]}
              path="/admin/member-experience/detail/:id"
              roles={["admin-kolegium", "root", "admin_paboi_cabang"]}
              exact
              component={ExperienceDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan"]}
              path="/admin/member-training"
              roles={["admin-kolegium", "root", "admin_paboi_cabang"]}
              exact
              component={TrainingAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan"]}
              path="/admin/member-training/:id"
              roles={["admin-kolegium", "root", "admin_paboi_cabang"]}
              exact
              component={TrainingAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan", "Detail"]}
              path="/admin/member-training/detail/:id"
              roles={["admin-kolegium", "root", "admin_paboi_cabang"]}
              exact
              component={TrainingDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan"]}
              path="/education"
              roles={["member"]}
              exact
              component={Education}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan"]}
              path="/education/:id"
              roles={["member"]}
              exact
              component={Education}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan", "Detail"]}
              path="/education/detail/:id"
              roles={["member"]}
              exact
              component={EducationDetail}
            />
            <PrivateRoute
              head={["Home", "Manajemen PPDS", "Detail"]}
              path="/manajemen-ppds/detail/:id"
              roles={["member", "ppds"]}
              exact
              component={PPDSDetail}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan"]}
              path="/admin/member-education"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
              ]}
              exact
              component={EducationAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan"]}
              path="/admin/member-education/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
              ]}
              exact
              component={EducationAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Pendidikan", "Detail"]}
              path="/admin/member-education/detail/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
              ]}
              exact
              component={EducationDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Member", "Serkom"]}
              path="/member/serkom"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={Serkom}
            />
            <PrivateRoute
              head={["Home", "Member", "Serkom"]}
              path="/member/serkom/:id"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={Serkom}
            />
            <PrivateRoute
              head={["Home", "Member", "Serkom", "Detail"]}
              path="/member/serkom/detail/:id"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={SerkomDetail}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom"]}
              path="/serkom"
              roles={["member"]}
              exact
              component={Serkom}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom"]}
              path="/serkom/:id"
              roles={["member"]}
              exact
              component={Serkom}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom", "Detail"]}
              path="/serkom/detail/:id"
              roles={["member"]}
              exact
              component={SerkomDetail}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota"]}
              path="/member/mutation"
              roles={["member"]}
              exact
              component={Mutation}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota"]}
              path="/member/mutation/:id"
              roles={["member"]}
              exact
              component={Mutation}
            />

            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota", "Detail"]}
              path="/member/mutation/detail/:id"
              roles={["member"]}
              exact
              component={MutationDetail}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota"]}
              path="/admin/mutation"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={MutationAdmin}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota"]}
              path="/admin/mutation/:id"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={MutationAdmin}
            />

            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Mutasi Anggota", "Detail"]}
              path="/admin/mutation/detail/:id"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={MutationDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Report", "Master"]}
              path="/member/master"
              roles={["member"]}
              exact
              component={Master}
            />
            <PrivateRoute
              head={["Home", "Report", "PPDS"]}
              path="/admin/ppds"
              roles={["root"]}
              exact
              component={PPDSAdmin}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran"]}
              path="/member/iuran"
              roles={["member"]}
              exact
              component={Report}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran"]}
              path="/admin/iuran"
              roles={["root", "ketua_paboi_pusat"]}
              exact
              component={ReportAdmin}
            />
            <PrivateRoute
              head={["Home", "Report", "Master"]}
              path="/admin/report-master"
              roles={["root"]}
              exact
              component={MasterReport}
            />
            <PrivateRoute
              head={["Home", "Report", "Dana Cabang"]}
              path="/admin/transfer"
              roles={["root", "ketua_paboi_cabang"]}
              exact
              component={TransferAdmin}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran"]}
              path="/admin/iuran-cabang"
              roles={["admin_paboi_cabang", "ketua_paboi_cabang"]}
              exact
              component={ReportCabang}
            />
            <PrivateRoute
              head={["Home", "Report", "Dana Cabang"]}
              path="/admin/transfer-cabang"
              roles={["admin_paboi_cabang", "ketua_paboi_cabang"]}
              exact
              component={TransferCabang}
            />
            <PrivateRoute
              head={["Home", "Report", "SHU"]}
              path="/admin/shu"
              roles={["admin_koperasi", "ketua_koperasi", "root"]}
              exact
              component={Shu}
            />
            <PrivateRoute
              head={["Home", "Support"]}
              path="/support"
              roles={["member"]}
              exact
              component={Support}
            />
            <PrivateRoute
              head={["Home", "Support"]}
              path="/support/:id"
              roles={["member"]}
              exact
              component={Support}
            />
            <PrivateRoute
              head={["Home", "Support"]}
              path="/support/detail/add"
              roles={["member"]}
              exact
              component={SupportAdd}
            />

            <PrivateRoute
              head={["Home", "Support", "Detail"]}
              path="/support/detail/:id"
              roles={["member"]}
              exact
              component={SupportDetail}
            />
            <PrivateRoute
              head={["Home", "Support"]}
              path="/admin/support"
              roles={[
                "root",
                "admin_paboi_cabang",
                "sekretaris_depoi",
                "ketua_depoi",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={SupportAdmin}
            />
            <PrivateRoute
              head={["Home", "Support"]}
              path="/admin/support/:id"
              roles={[
                "root",
                "admin_paboi_cabang",
                "sekretaris_depoi",
                "ketua_depoi",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={SupportAdmin}
            />
            <PrivateRoute
              head={["Home", "Support", "Detail"]}
              path="/admin/support/detail/:id/:item"
              roles={[
                "root",
                "admin_paboi_cabang",
                "sekretaris_depoi",
                "ketua_depoi",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              // exact
              component={SupportDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom"]}
              path="/admin/serkom"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SerkomAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom"]}
              path="/admin/serkom/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SerkomAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "Serkom", "Detail"]}
              path="/admin/serkom/detail/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SerkomDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Cabang"]}
              path="/branch"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={Branch}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Ranah Kegiatan"]}
              path="/activity"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={Activity}
            />

            <PrivateRoute
              head={["Home", "P2KB", "SKP", "SKP"]}
              path="/skp"
              roles={["member"]}
              exact
              component={P2kb}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SKP"]}
              path="/skp/:id"
              roles={["member"]}
              exact
              component={P2kb}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan"]}
              path="/training"
              roles={["member"]}
              exact
              component={Pelatihan}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan"]}
              path="/training/:id"
              roles={["member"]}
              exact
              component={Pelatihan}
            />
            <PrivateRoute
              head={["Home", "Member", "Pelatihan", "Detail"]}
              path="/training/detail/:id"
              roles={["member"]}
              exact
              component={DetailPelatihan}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman"]}
              path="/experience"
              roles={["member"]}
              exact
              component={Pengalaman}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman"]}
              path="/experience/:id"
              roles={["member"]}
              exact
              component={Pengalaman}
            />
            <PrivateRoute
              head={["Home", "Member", "Pengalaman", "Detail"]}
              path="/experience/detail/:id"
              roles={["member"]}
              exact
              component={DetailPengalaman}
            />
            <PrivateRoute
              head={["Home", "Member", "Tempat Praktik"]}
              path="/practice"
              roles={["root", "member", "admin_paboi_cabang"]}
              exact
              component={ListPractice}
            />
            <PrivateRoute
              head={["Home", "Member", "Tempat Praktik"]}
              path="/practice/:id"
              roles={["root", "member", "admin_paboi_cabang"]}
              exact
              component={ListPractice}
            />
            <PrivateRoute
              head={["Home", "Member", "Tempat Praktik", "Detail"]}
              path="/practice/practice-detail/:id"
              roles={["root", "member", "admin_paboi_cabang"]}
              exact
              component={PracticeDetail}
            />
            <PrivateRoute
              head={["Home", "Member", "Profile"]}
              path="/member-detail"
              roles={["member"]}
              exact
              component={DetailMemberPengantarMember}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/tindakan-operasi/:id"
              roles={["member"]}
              exact
              component={DetailP2kb_TindakanOperasi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/pelayanan-medis/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={DetailP2kb_PelayananMedis}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/pertemuan-auditor/:id"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={DetailP2kb_PertemuanAuditor}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/membaca-jurnal/:id"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={DetailP2kb_MembacaJurnal}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/penelusuran-informasi/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={DetailP2kb_PenelusuranInformasi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/acara-ilmiah/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={DetailP2kb_AcaraIlmiah}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/seminar/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={DetailP2kb_Seminar}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/kepengurusan-profesi/:id"
              roles={["member"]}
              exact
              component={DetailP2kb_KepengurusanProfesi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/penelitian-dipublikasikan/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={DetailP2kb_PenelitianDipublikasikan}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/pelatihan/:id"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={DetailP2kb_MengikutiPelatihan}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/penyelia-supervisor/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={DetailP2kb_PenyeliaSupervisor}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/praktek-mahasiswa/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={DetailP2kb_PraktekMahasiswa}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR"]}
              path="/str"
              roles={["root", "member"]}
              exact
              component={ListStr}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR"]}
              path="/str/:id"
              roles={["member"]}
              exact
              component={ListStr}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR KT"]}
              path="/strkt"
              roles={["member"]}
              exact
              component={ListStrKT}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR KT"]}
              path="/strkt/:id"
              roles={["member"]}
              exact
              component={ListStrKT}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR KT"]}
              path="/admin/strkt"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={ListStrKT}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR KT"]}
              path="/admin/strkt/:id"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={ListStrKT}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP"]}
              path="/sip"
              roles={["root", "member"]}
              exact
              component={ListSip}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP"]}
              path="/sip/:id"
              roles={["member"]}
              exact
              component={ListSip}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP"]}
              path="/admin/sip"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SipAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP"]}
              path="/admin/sip/:id"
              roles={[
                "root",
                "admin_kolegium",
                "admin_paboi_cabang",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SipAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP", "Detail"]}
              path="/admin/sip/detail/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={SipDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR"]}
              path="/admin/str"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={StrAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR"]}
              path="/admin/str/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={StrAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR", "Detail"]}
              path="/admin/str/detail/:id"
              roles={[
                "root",
                "admin_kolegium",
                "admin_paboi_cabang",
                "ketua_kolegium",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={StrDetailAdmin}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STR", "Detail"]}
              path="/member/str/detail/:id"
              roles={["member"]}
              exact
              component={StrDetail}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STRKT", "Detail"]}
              path="/member/strkt/detail/:id"
              roles={["member"]}
              exact
              component={StrKTDetail}
            />
            <PrivateRoute
              head={["Home", "P2KB", "STRKT", "Detail"]}
              path="/admin/strkt/detail/:id"
              roles={[
                "root",
                "admin_paboi_cabang",
                "ketua_paboi_pusat",
                "ketua_paboi_cabang",
              ]}
              exact
              component={StrKTDetail}
            />
            <PrivateRoute
              head={["Home", "P2KB", "SIP", "Detail"]}
              path="/member/sip/detail/:id"
              roles={["member"]}
              exact
              component={SipDetail}
            />

            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/tindakan-operasi"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_TindakanOperasi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Ranah Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/add"
              roles={["member"]}
              exact
              component={AddDetailSKP}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/pelayanan-medis"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_PelayananMedis}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/pertemuan-auditor"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_PertemuanAuditor}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/membaca-jurnal"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={AddDetailSKP_MembacaJurnal}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/penelusuran-informasi"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={AddDetailSKP_PenelusuranInformasi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/acara-ilmiah"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={AddDetailSKP_AcaraIlmiah}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/seminar"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_Seminar}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/pelatihan"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_Pelatihan}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/kepengurusan-profesi"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_KepengurusanProfesi}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/penelitian-dipublikasikan"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={AddDetailSKP_PenelitianDipublikasikan}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/penyelia-supervisor"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_PenyeliaSupervisor}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/praktek-mahasiswa"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={AddDetailSKP_PraktekMahasiswa}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/kajian-ilmiah/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={KajianIlmiah}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/kajian-ilmiah/:activity_id/:skp_id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={KajianIlmiahDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/add/menghadiri-kongres/:id"
              roles={["member"]}
              exact
              component={MenghadiriKongres}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/menghadiri-kongres/:activity_id/:skp_id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MenghadiriKongresDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/edukasi-pasien/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={EdukasiPasien}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/edukasi-pasien/:activity_id/:skp_id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={EdukasiPasienDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/menulis-ilmiah/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MenulisIlmiah}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/menulis-ilmiah/:activity_id/:skp_id"
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              roles={["member"]}
              exact
              component={MenulisIlmiahDetail}
            />

            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/menulis-buku/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MenulisBuku}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/menulis-buku/:activity_id/:skp_id"
              roles={["member"]}
              exact
              component={MenulisBukuDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/membimbing-mahasiswa/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MembimbingMahasiswa}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/membimbing-mahasiswa/:activity_id/:skp_id"
              roles={["member"]}
              exact
              component={MembimbingMahasiswaDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/mengajar-mahasiswa/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={MengajarMahasiswa}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/mengajar-mahasiswa/:activity_id/:skp_id"
              roles={["member"]}
              exact
              component={MengajarMahasiswaDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/pengabdian-masyarakat/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={PengMas}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/pengabdian-masyarakat/:id"
              roles={["member"]}
              exact
              component={PengMasDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/kajian-elektronik/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={kajianElektronik}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/kajian-elektronik/:id"
              roles={["member"]}
              exact
              component={kajianElektronikDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/menciptakan-alat/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MenciptakanAlat}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/details/menciptakan-alat/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={MenciptakanAlatDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/membuat-video/:id"
              roles={["member"]}
              exact
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              component={MembuatVideo}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/membuat-video/:id"
              roles={["member"]}
              exact
              component={MembuatVideoDetail}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              path="/p2kb/add/menguji-mahasiswa/:id"
              roles={["member"]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              exact
              component={MengujiMahasiswa}
            />
            <PrivateRoute
              head={[
                "Home",
                "P2KB",
                "SKP",
                "Detail Ranah Kegiatan",
                "Detail Kegiatan",
                "Detail Kegiatan",
              ]}
              link={["/skp", "/skp", "/skp", "/p2kb/add", ""]}
              path="/p2kb/details/menguji-mahasiswa/:id"
              roles={["member"]}
              exact
              component={MengujiMahasiswaDetail}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Rumah Sakit"]}
              path="/hospital"
              roles={["root"]}
              exact
              component={ListHospital}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Rumah Sakit"]}
              path="/hospital/:id"
              roles={["root"]}
              exact
              component={ListHospital}
            />
            <PrivateRoute
              head={["Home", "Data Referensi", "Subspesialis"]}
              path="/subspesialis"
              roles={["root", "admin_kolegium", "ketua_kolegium"]}
              exact
              component={ListSubspesialis}
            />
            <PrivateRoute
              head={["Home", "event", "Detail"]}
              path="/event"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={listEvent}
            />
            <PrivateRoute
              head={["Home", "event", "Detail"]}
              path="/event/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={listEvent}
            />
            <PrivateRoute
              head={["Home", "event", "Detail"]}
              path="/event/participant/:id"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              exact
              component={listParticipant}
            />
            <PrivateRoute
              head={["Home", "event", "Detail"]}
              path="/event/participant-detail/:id_event/:id_member/:menu"
              roles={[
                "root",
                "admin_kolegium",
                "ketua_kolegium",
                "ketua_paboi_pusat",
              ]}
              component={ParticipantDetail}
            />
            <PrivateRoute
              head={["Home", "Journal"]}
              path="/journal"
              roles={[
                "root",
                "chief_editor",
                "reviewer",
                "editor",
                "language_editor",
              ]}
              exact
              component={listJournal}
            />
            <PrivateRoute
              head={["Home", "Journal", "Detail"]}
              path="/journal/detail/informasi-umum/:id"
              roles={[
                "root",
                "chief_editor",
                "reviewer",
                "editor",
                "language_editor",
              ]}
              exact
              component={detailJournal}
            />
            <PrivateRoute
              head={["Home", "Journal", "Detail"]}
              path="/journal/detail/abstrak/:id"
              roles={[
                "root",
                "chief_editor",
                "reviewer",
                "editor",
                "language_editor",
              ]}
              exact
              component={detailJournalAbstrak}
            />
            <PrivateRoute
              head={["Home", "Journal", "Detail"]}
              path="/journal/detail/revisi/:id"
              roles={[
                "root",
                "chief_editor",
                "reviewer",
                "editor",
                "language_editor",
              ]}
              exact
              component={detailJournalRevisi}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran"]}
              path="/iuran"
              roles={["root", "member"]}
              exact
              component={ListIuran}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran", "Detail"]}
              path="/iuran/:id"
              roles={["root", "member"]}
              exact
              component={DetailIuran}
            />
            <PrivateRoute
              head={["Home", "Report", "Koperasi"]}
              path="/koperasi"
              roles={["root", "member", "ketua_paboi_pusat"]}
              exact
              component={ListKoperasi}
            />
            <PrivateRoute
              head={["Home", "Report", "Koperasi", "Detail"]}
              path="/koperasi/:id"
              roles={["root", "member"]}
              exact
              component={DetailKoperasi}
            />
            <PrivateRoute
              head={
                role === "admin_koperasi"
                  ? ["Home", "Manajemen Benefit", "Benefit"]
                  : ["Home", "Benefit"]
              }
              path="/benefit"
              roles={[
                "admin_koperasi",
                "member",
                "bendahara_koperasi",
                "ketua_koperasi",
              ]}
              exact
              component={ListBenefit}
            />
            <PrivateRoute
              head={
                role === "admin_koperasi"
                  ? ["Home", "Manajemen Benefit", "Benefit"]
                  : ["Home", "Benefit"]
              }
              path="/benefit/:id"
              roles={[
                "admin_koperasi",
                "member",
                "bendahara_koperasi",
                "ketua_koperasi",
              ]}
              exact
              component={DetailBenefit}
            />
            <PrivateRoute
              head={["Home", "Benefit", "Detail"]}
              path="/benefit/:id"
              roles={[
                "admin_koperasi",
                "member",
                "bendahara_koperasi",
                "ketua_koperasi",
              ]}
              exact
              component={DetailBenefit}
            />
            <PrivateRoute
              head={["Home", "Manajemen Benefit", "Data Master"]}
              path="/master-benefit"
              roles={["admin_koperasi"]}
              exact
              component={ListMasterBenefit}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Trans RS"]}
              path="/transrs"
              roles={[
                "member",
                "admin_paboi_cabang",
                "root",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={TransRS}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Trans RS"]}
              path="/transrs/:id"
              roles={[
                "member",
                "admin_paboi_cabang",
                "root",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={TransRS}
            />
            <PrivateRoute
              head={["Home", "Trans/Mutasi", "Trans RS", "Detail"]}
              path="/transrs/detail/:id"
              roles={[
                "member",
                "admin_paboi_cabang",
                "root",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
              ]}
              exact
              component={DetailTransRS}
            />
            <PrivateRoute
              head={["Home", "Pengurus Cabang"]}
              path="/pengurus"
              roles={["admin_paboi_cabang"]}
              exact
              component={Pengurus}
            />
            <PrivateRoute
              head={["Home", "Media", "Message Blast"]}
              path="/message-blast"
              roles={["root"]}
              exact
              component={MessageBlast}
            />
            <PrivateRoute
              head={["Home", "Media", "Message Blast", "Kirim Message Blast"]}
              path="/message-blast/add"
              roles={["root"]}
              exact
              component={MessageBlastAdd}
            />
            <PrivateRoute
              head={["Home", "Media", "Message Blast"]}
              path="/message-blast/detail/:id"
              roles={["root"]}
              exact
              component={DetailMessageBlast}
            />
            <PrivateRoute
              head={["Home", "Media", "Iklan"]}
              path="/iklan"
              roles={["root"]}
              exact
              component={Advertisement}
            />
            <PrivateRoute
              head={["Home", "Media", "Iklan", "Detail"]}
              path="/iklan/:id"
              roles={["root"]}
              exact
              component={AdvertisementDetail}
            />
            <PrivateRoute
              head={["Home", "CMS Final Paper"]}
              path="/final-paper"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={FinalPaper}
            />
            <PrivateRoute
              head={["Home", "CMS Final Paper", "Detail"]}
              path="/final-paper/detail/:id"
              roles={["admin_kolegium", "ketua_kolegium"]}
              exact
              component={DetailFinalPaper}
            />
            <PrivateRoute
              head={["Home", "System", "Audit Trail"]}
              path="/audit-trail"
              roles={["root"]}
              exact
              component={AuditTrail}
            />
            <PrivateRoute
              head={["Home", "Report", "SHU"]}
              path="/shu"
              roles={["member"]}
              exact
              component={SHU}
            />
            <PrivateRoute
              head={["Home", "Report", "SHU", "Detail"]}
              path="/admin/shu/:id"
              roles={["admin_koperasi", "ketua_koperasi", "root"]}
              component={SHUMember}
            />
            <PrivateRoute
              head={["Home", "Report", "Koperasi"]}
              path="/admin/iuran-koperasi"
              roles={[
                "admin_koperasi",
                "ketua_koperasi",
                "root",
                "ketua_paboi_pusat",
              ]}
              component={Iuran}
            />
            <PrivateRoute
              head={["Home", "Report", "Iuran", "Detail"]}
              path="/admin/iuran/:id"
              roles={[
                "admin_koperasi",
                "ketua_koperasi",
                "root",
                "ketua_paboi_pusat",
              ]}
              component={IuranMember}
            />
            <PrivateRoute
              head={["Home", "Notification"]}
              path="/notification"
              roles={[
                "root",
                "member",
                "admin_kolegium",
                "ketua_kolegium",
                "admin_koperasi",
                "bendahara_koperasi",
                "ketua_koperasi",
                "cpd_cme",
                "ketua_paboi_cabang",
                "ketua_paboi_pusat",
                "admin_paboi_cabang",
                "sekretaris_depoi",
                "ketua_depoi",
              ]}
              component={Notification}
            />
            <PrivateRoute roles={[]} exact component={() => <div></div>} />
            {/* <PrivateRoute
              roles={["member"]}
              exact
              component={() => <Redirect to={"/pengalaman"} />}
            /> */}
          </Switch>
        </Layout>
      );
    };

    return (
      <>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Switch>
            <Route path="/login" exact component={newLogin} />
            <Route path="/register" exact component={register} />
            <Route path="/forgot-password" exact component={forgotPassword} />
            <Route path="/verifikasi/:token" exact component={verifyPassword} />
            <Route component={ContentRoute} />
          </Switch>
        </ThemeProvider>
      </>
    );
  }
}

export default App;
