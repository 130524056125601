import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListPengalaman from "./ListPengalaman";

class Pengalaman extends Component {
    render() {
        return (
            <ContentContainer>
                <ListPengalaman />
            </ContentContainer>
        );
    }
}

export default Pengalaman;
