import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { universityList } from "../../actions/master-data/university_action";
import { getUniversityListPending, getUniversityList, getUniversityListError } from "../../reducers/master-data/university_reducer";
import { listCity } from "../../actions/master-data/city_action";
import { getCityListPending, getCityList, getCityListError } from "../../reducers/master-data/city_reducer";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ModalAddUniversitas from "./Modal_add_universitas";
import _ from "lodash";

class HeaderUniversitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            modalAddUniversitasShow: false,
            cityListResponse: false,
        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }

    delayedQuery = _.debounce((value) => {
        const { handleRefresh } = this.props;

        return handleRefresh({ search: value });
    }, 500);

    handleChangeSearch(e) {
        this.setState({ search: e.target.value });
        this.delayedQuery(e.target.value);
    }

    render() {
        const { modalAddUniversitasShow } = this.state;
        const { cityList_data } = this.props;
        let cityOption = [];
        if (cityList_data) {
            let data = cityList_data.data;
            data.map((value, index) => {
                const city = { value: value.city_id, label: value.name }
                cityOption = [...cityOption, city]
                return city;
            })
        }
        return (
            <Row
                style={{ padding: 20, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="space-between"
            >
                <ModalAddUniversitas
                    show={modalAddUniversitasShow}
                    onHide={() => this.setState({ modalAddUniversitasShow: false })}
                    listCityOption={cityOption}
                />
                <div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={css(styles.approveButton)}
                        startIcon={<AddIcon />}
                        onClick={() => this.setState({ modalAddUniversitasShow: true })}
                    >
                        Tambah Universitas
                </Button>
                </div>
                <div className={css(styles.container)}>
                    <InputComponent
                        onChange={this.handleChangeSearch}
                        value={this.state.search}
                        placeholder="Cari Universitas"
                        startIcon={SearchIcon}
                        id="search"
                    />
                </div>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        marginTop: 10
    },
    container: {
        width: 150,
        margin: 10,
    },
});

function mapStateToProps({ universityList, listCity }) {
    return {
        universityList_pending: getUniversityListPending(universityList),
        universityList_data: getUniversityList(universityList),
        universityList_error: getUniversityListError(universityList),
        cityList_pending: getCityListPending(listCity),
        cityList_data: getCityList(listCity),
        cityList_error: getCityListError(listCity),
    };
}

export default connect(mapStateToProps, { universityList, listCity })(HeaderUniversitas);