import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { Route, Switch } from "react-router-dom";
import { detailSupport } from "../../actions/support/support_action";
import { withRouter } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";

import Button from "@material-ui/core/Button";
import {
  getSupportPending,
  getSupportError,
  getDetailSupport,
  getSupportIsEditFile,
} from "../../reducers/support/support_reducer";
import { connect } from "react-redux";
import moment from "moment";
import StatusSquareComponent from "./StatusSquareComponent";
import SupportModalApprove from "./SupportModalApprove";
import SupportModalForward from "./SupportModalForward";
import SupportModalProcess from "./SupportModalProcess";
import SupportDetailUmum from "./SupportDetailUmum";
import SupportDetailLaporan from "./SupportDetailLaporan";
import { getUser } from "../../helpers/user";

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showProcessModal: false,
      showForwardModal: false,
    };
    this.tableRef = React.createRef();
  }

  createMarkup(html) {
    return { __html: html };
  }

  setSelectedItem(item) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ selectedItem: item });
    this.props.history.push(`/admin/support/detail/${id}/${item}`);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      detailSupport,
    } = this.props;
    detailSupport(id);
  }
  render() {
    const role_code = getUser().role_codes[0];

    let showProcessButton = false;
    let showApproveButton = false;
    let showForwardButton = false;

    const {
      detailSupport_pending,
      detailSupport_data,
      match: {
        params: { item },
      },
    } = this.props;
    const { showApproveModal, showProcessModal, showForwardModal } = this.state;
    let status,
      updated_at,
      notes,
      name,
      types,
      level,
      forward_to_admin;

    if (detailSupport_data && detailSupport_data.length !== 0) {
      [
        {
          status,
          forward_to_admin,
          level,
          updated_at,
          notes,
          types,
          member_data: { name },
        },
      ] = detailSupport_data;
    }

    if (status === 0) {
      if (forward_to_admin === 0 && role_code === "admin_paboi_cabang") {
        showProcessButton = true;
      }
      if (forward_to_admin === 1 && level === null && role_code === "root") {
        showProcessButton = true;
        if (types === 2) {
          showForwardButton = true;
        }
      }
      if (
        forward_to_admin === 2 &&
        status === 0 &&
        role_code === "sekretaris_depoi"
      ) {
        showProcessButton = true;
      }
    }
    console.log(showProcessButton)
    if (status === 1 && level !== null){
      showApproveButton = true;
    }
    return (
      <ContentContainer>
        <SupportModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <SupportModalProcess
          show={showProcessModal}
          onHide={() => this.setState({ showProcessModal: false })}
        />
        <SupportModalForward
          show={showForwardModal}
          role={forward_to_admin === 1? 'Sekretaris Depoi' : 'Admin Pusat'}
          onHide={() => this.setState({ showForwardModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />

            {!detailSupport_pending && (
              <StatusSquareComponent
                statusLabel={
                  status === 0
                    ? "Silahkan melakukan konfirmasi penerimaan. Laporan telah diajukan pada " +
                    moment(updated_at).format("DD MMMM YYYY")
                    : status === 2 ? "Laporan telah selesai pada " +
                    moment(updated_at).format("DD MMMM YYYY") : "Mohon tunggu, laporan sedang diproses pada " + moment(updated_at).format("DD MMMM YYYY")
                }
                statusTitle={
                  status === 0
                    ? "Menunggu"
                    : status === 1
                      ? "Proses"
                      : "Selesai"
                }
                status={status}
                reject_notes={notes}
              />
            )}
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("umum")}
              menuName="Informasi Umum"
              isActive={this.state.selectedItem === "umum"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("laporan")}
              menuName="Informasi Laporan"
              isActive={this.state.selectedItem === "laporan"}
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <Row
              vertical="center"
              horizontal="space-between"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <DetailNameComponent
                name={`Informasi ${item[0].toUpperCase() + item.substring(1)}`}
              />
              {(status === 1 && role_code === "root" && forward_to_admin === 1)||(status === 1 && role_code === "admin_paboi_cabang" && forward_to_admin === 0)||(status === 1 && role_code === "sekretaris_depoi" && forward_to_admin === 2) ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Selesai
                </Button>
              ) : (
                  <div>
                    {showProcessButton && role_code !== "ketua_paboi_pusat" && (
                      <Button
                        variant="contained"
                        color="primary"
                        // startIcon={<CheckIcon />}
                        onClick={() => this.setState({ showProcessModal: true })}
                        style={{ marginRight: 10 }}
                      >
                        Proses
                      </Button>
                    )}
                    {status === 0 && forward_to_admin === 0 && role_code !== "ketua_paboi_pusat" && (
                      <Button
                        variant="contained"
                        color="primary"
                        // startIcon={<CheckIcon />}
                        onClick={() => this.setState({ showForwardModal: true })}
                      >
                        Teruskan
                      </Button>
                    )}
                    {status === 0 && forward_to_admin === 1 && types===2 && role_code != 'admin_paboi_cabang' && (
                      <Button
                        variant="contained"
                        color="primary"
                        // startIcon={<CheckIcon />}
                        onClick={() => this.setState({ showForwardModal: true })}
                      >
                        Teruskan
                      </Button>
                    )}
                  </div>
                )}
            </Row>
            <Switch>
              <Route
                path="/admin/support/detail/:id/umum"
                exact
                component={SupportDetailUmum}
              />
              <Route
                path="/admin/support/detail/:id/laporan"
                exact
                component={SupportDetailLaporan}
              />
            </Switch>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function mapStateToProps(state) {
  const { editSupport, detailSupport } = state;

  return {
    editSupport_error: getSupportError(editSupport),
    editSupport_pending: getSupportPending(editSupport),
    detailSupport_pending: getSupportPending(detailSupport),
    detailSupport_data: getDetailSupport(detailSupport),
    editSupport_isEditFile: getSupportIsEditFile(editSupport),
  };
}

export default connect(mapStateToProps, {
  detailSupport,
})(withRouter(SupportDetail));
