import {
    LIST_MASTER_BENEFIT_PENDING,
    LIST_MASTER_BENEFIT_SUCCESS,
    LIST_MASTER_BENEFIT_ERROR,
    DETAIL_MASTER_BENEFIT_PENDING,
    DETAIL_MASTER_BENEFIT_SUCCESS,
    DETAIL_MASTER_BENEFIT_ERROR,
    ADD_MASTER_BENEFIT_PENDING,
    ADD_MASTER_BENEFIT_SUCCESS,
    ADD_MASTER_BENEFIT_ERROR,
    SET_SELECTED_MASTER_BENEFIT,
    EDIT_MASTER_BENEFIT_PENDING,
    EDIT_MASTER_BENEFIT_SUCCESS,
    EDIT_MASTER_BENEFIT_ERROR,
    DELETE_MASTER_BENEFIT_PENDING,
    DELETE_MASTER_BENEFIT_SUCCESS,
    DELETE_MASTER_BENEFIT_ERROR,
} from "../../actions/master-data/master_benefit_action";

const initialState = {
    pending: false,
    error: null,
};

export function listMasterBenefit(
    state = { ...initialState, list_master_benefit: null },
    action
) {
    switch (action.type) {
        case LIST_MASTER_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_MASTER_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                list_master_benefit: action.data,
            };
        case LIST_MASTER_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailMasterBenefit(
    state = { ...initialState, detail_master_benefit: null },
    action
) {
    switch (action.type) {
        case DETAIL_MASTER_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_MASTER_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_master_benefit: action.data,
            };
        case DETAIL_MASTER_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addMasterBenefit(state = initialState, action) {
    switch (action.type) {
        case ADD_MASTER_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_MASTER_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_MASTER_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedMasterBenefit(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_MASTER_BENEFIT:
            return action.value;
        default:
            return state;
    }
}

export function editMasterBenefit(
    state = { ...initialState, editBenefit: null },
    action
) {
    switch (action.type) {
        case EDIT_MASTER_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_MASTER_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                editBenefit: action.data
            };
        case EDIT_MASTER_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}


export function deleteMasterBenefit(
    state = { ...initialState, mainDeposit: null },
    action
) {
    switch (action.type) {
        case DELETE_MASTER_BENEFIT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_MASTER_BENEFIT_SUCCESS:
            return {
                ...state,
                pending: false,
                mainDeposit: action.data
            };
        case DELETE_MASTER_BENEFIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListMasterBenefit = (state) => state.list_master_benefit;
export const getListMasterBenefitPending = (state) => state.pending;
export const getListMasterBenefitError = (state) => state.error;
export const getDetailMasterBenefit = (state) => state.detail_master_benefit;
export const getSelectedMasterBenefit = (state) => state.selectedMasterBenefit;
export const getMasterBenefitEditPending = (state) => state.pending;
export const getMasterBenefitEdit = (state) => state.editBenefit;
export const getMasterBenefitEditError = (state) => state.error;
export const getMasterBenefitAddPending = (state) => state.pending;
export const getMasterBenefitAdd = (state) => state.addBenefit;
export const getMasterBenefitAddError = (state) => state.error;
export const getMasterBenefitDeletePending = (state) => state.pending;
export const getMasterBenefitDelete = (state) => state.addBenefit;
export const getMasterBenefitDeleteError = (state) => state.error;