import React, { Component } from "react";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import IconRefresh from "../../assets/icon-refresh";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import moment from "moment";
import localization from "moment/locale/id";
import Placeholder from "../../components/p2kb/skp_placeholder";
import FadeIn from "react-fade-in";
import { listIuran } from "../../actions/iuran/iuran_action";
import {
  getListIuran,
  getListIuranError,
  getListIuranPending,
} from "../../reducers/iuran/iuran_reducer";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
  refreshButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  syncDiv: {
    paddingTop: 5
  },
  syncLabel: {
    paddingTop: 7
  },
  detailHeaderContainer: {
    backgroundColor: "#EFF0F2"
  },
  detailHeaderBenefitColumn: {
    margin: "20px 20px 20px 30px",
    width: "15%"
  },
  detailHeaderTitle: {
    color: "#495057",
    opacity: 0.7,
    fontSize: 14,
    marginBottom: 20
  },
  detailHeaderValue: {
    color: "Black",
    fontSize: 16
  },
  totalSHUValue: {
    color: "#43C57C"
  }
});
class ReportHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      report: "",
      isLoad: false
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSync = this.handleSync.bind(this);
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ report: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleYearSelectChange(e) {
    if (e) {
      this.props.handleRefresh({
        year: e.value,
      });
    } else {
      this.props.handleRefresh({
        year: null,
      });
    }
  }

  handleStatusChange(e) {
    if (e) {
      this.props.handleRefresh({
        status: e.value,
      });
    } else {
      this.props.handleRefresh({
        status: null,
      });
    }
  }

  handleSync() {
    this.setState({ isLoad: true });
    const callback = () => {
      this.setState({ isLoad: false });
      this.delayedQuery();
    }
    this.props.listIuran({ length: 10 }, callback);
  }

  render() {
    const { listIuran_data } = this.props;
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    let data = null;
    let lastUpdate = new Date();
    let total_estimated, total_outstanding = "-";
    if (listIuran_data) {
      data = listIuran_data.data[0];
      lastUpdate = listIuran_data.latest_update;

      total_estimated = listIuran_data.total_paid.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      total_outstanding = listIuran_data.total_outstanding.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
    return (
      <Column>
        <Column>
          <div className={css(styles.detailHeaderContainer)}>
            <Row>
              <Column className={css(styles.detailHeaderBenefitColumn)}>
                <div>
                  <div className={css(styles.detailHeaderTitle)}>
                    {this.props.role === 'member' ? 'Iuran Sudah Dibayarkan' : 'Estimasi Dana'}
                  </div>
                  <div className={css(styles.detailHeaderValue)}>
                    <span className={css(styles.totalSHUValue)}>Rp {total_estimated}</span>
                  </div>
                </div>
              </Column>
              <Column className={css(styles.detailHeaderBenefitColumn)}>
                <div>
                  <div className={css(styles.detailHeaderTitle)}>
                    Outstanding
                                      </div>
                  <div className={css(styles.detailHeaderValue)}>
                    <span className={css(styles.totalSHUValue)}>Rp {total_outstanding}</span>
                  </div>
                </div>
              </Column>
            </Row>
          </div>
        </Column>
        <Row
          style={{
            padding: 24,
            paddingBottom: 30,
            backgroundColor: "#F8F9FB",
            zIndex: 20,
          }}
          horizontal="space-between"
        >
          <Row>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.refreshButton)}
              startIcon={<IconRefresh />}
              onClick={this.handleSync}
            >
              Refresh
          </Button>
            <div className={css(styles.syncDiv)}>
              {this.state.isLoad || lastUpdate === null ? (
                <Placeholder childwidth={200} parentwidth={200} title="" />
              ) :
                <FadeIn>
                  <label className={css(styles.syncLabel)}>
                    {moment(lastUpdate)
                      .locale("id", localization)
                      .format("dddd, DD MMMM YYYY HH:mm")}
                  </label>
                </FadeIn>
              }
            </div>
          </Row>
          <Row>
            <div className={css(styles.container)}>
              <InputComponent
                onChange={this.handleSearchChange}
                value={this.state.report}
                placeholder="Cari ID Transaksi"
                startIcon={SearchIcon}
              />
            </div>
            <div className={css(styles.container)}>
              <SelectComponent
                options={yearOptions}
                onChange={this.handleYearSelectChange}
                placeholder="Pilih Tahun"
              />
            </div>
          </Row>
        </Row>
      </Column >
    );
  }
}

function mapStateToProps({ listIuran }) {
  return {
    listIuran_data: getListIuran(listIuran),
    listIuran_error: getListIuranError(listIuran),
    listIuran_pending: getListIuranPending(listIuran),
  };
}

export default connect(mapStateToProps, { listIuran })(withRouter(ReportHeader));
