import React, { Component } from "react";
import { connect } from "react-redux";
import {
  detailBranch,
  listBranch,
} from "../../actions/master-data/branch_action";
import { listProvince } from "../../actions/master-data/province_temp_action";
import {
  getListBranch,
  getBranchError,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import NumberInfo from "../../components/table/NumberInfo";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import ModalBranchEdit from "./ModalBranchEdit";
import ModalBranchDelete from "./ModalBranchDelete";
import ModalBranchAdd from "./ModalBranchAdd";
import BrancHeader from "./BranchHeader";
import { createMuiTheme } from "@material-ui/core/styles";
import { listCity } from "../../actions/master-data/city_action";
import {
  getCityListPending,
  getCityList,
  getCityListError,
} from "../../reducers/master-data/city_reducer";

class Branch extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      input: "",
      modalEditShow: false,
      modalDeleteShow: false,
      modalAddShow: false,
      id: "",
      isEdit: false,
      search_text: "",
    };
    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
    this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenModalEdit(id, isEdit = false) {
    const { detailBranch } = this.props;
    detailBranch(id);
    this.setState({ id, isEdit });
    this.setState({ modalEditShow: true });
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }
  handleOpenModalAdd(id) {
    this.setState({ id, modalAddShow: true });
  }

  componentDidMount() {
    const { listProvince, listCity } = this.props;
    listProvince({ length: 999 });
    listCity({ length: 999 });
  }

  render() {
    const customTheme = createMuiTheme();

    Object.assign(customTheme, {
      overrides: {
        MUIRichTextEditor: {
          root: {
            marginTop: 20,
            width: "80%",
          },
          editor: {
            borderBottom: "1px solid gray",
          },
        },
      },
    });

    const { listBranch, listBranch_data, listCity_data } = this.props;
    const {
      id,
      search_text,
      isEdit,
      modalEditShow,
      modalDeleteShow,
      modalAddShow,
    } = this.state;
    let data = [];
    let cityOptions = [];
    let records_total = 0;

    if (listBranch_data) {
      data = listBranch_data.data;
      records_total = listBranch_data.records_total;
    }
    if (listCity_data && listCity_data.data.length !== 0) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.city_id,
      }));
    }
    return (
      <ContentContainer>
        <ModalBranchEdit
          id={id}
          show={modalEditShow}
          onHide={() => this.setState({ modalEditShow: false })}
          handleRefresh={this.handleRefresh}
          isEdit={isEdit}
          cityOptions={cityOptions}
        />
        <ModalBranchAdd
          id={id}
          show={modalAddShow}
          onHide={() => this.setState({ modalAddShow: false })}
          handleRefresh={this.handleRefresh}
          cityOptions={cityOptions}
        />
        <ModalBranchDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <BrancHeader
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama Cabang",
              field: "name",
            },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "Kota",
              render: ({ city }) => {
                return city ? city.name : "-";
              }
            },
            {
              title: "Provinsi Cabang",
              render: ({ branch_provinces, id }) => {
                return (
                  <NumberInfo
                    data={
                      branch_provinces.length !== 0
                        ? branch_provinces[0].province_name
                        : "-"
                    }
                    numberInfo={branch_provinces.length}
                    arrayData={branch_provinces.map(
                      (item) => item.province_name
                    )}
                    onClick={() => this.handleOpenModalEdit(id)}
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() => this.handleOpenModalEdit(id, true)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleOpenModalDelete(id, true)}
                    >
                      Delete
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
              };
              listBranch(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listBranch, listCity }) {
  return {
    listBranch_data: getListBranch(listBranch),
    listBranch_error: getBranchError(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listCity_data: getCityList(listCity),
    listCity_error: getCityListError(listCity),
    listCity_pending: getCityListPending(listCity),
  };
}

export default connect(mapStateToProps, {
  detailBranch,
  listBranch,
  listProvince,
  listCity
})(Branch);
