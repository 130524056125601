import React from "react";
import { Column } from "simple-flexbox";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import { StyleSheet, css } from "aphrodite";

function FileValidation({ label, hasFile, openFile }) {
    return (
        <Column horizontal="stretch">
            <span className={css(styles.label)}>{label}</span>
            {hasFile ?
                <DetailContentLihatBerkas
                    marginTop={5}
                    onClick={() => openFile()} />
                :
                <span className={css(styles.nullFilename)}>Berkas tidak ditemukan. Silahkan lakukan update data!</span>
            }
        </Column>
    )
}

const styles = StyleSheet.create({
    label: {
        marginTop: 10
    },
    nullFilename: {
        color: "red",
        marginBottom: 10,
        marginTop: 5
    }
})

export default FileValidation;