import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

class DetailRejectModal extends Component {
  render() {
    const { show, onHide, reason } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Keterangan</p>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            <div className={css(styles.reason)}>{reason}</div>
          </Column>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  imgBuktiPembayaran: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
  reason: {
    opacity: 0.7,
    marginTop: 20,
    marginBottom: 10,
  },
});

export default DetailRejectModal;
