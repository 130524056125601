import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_SIP_PENDING = "LIST_SIP_PENDING";
export const LIST_SIP_SUCCESS = "LIST_SIP_SUCCESS";
export const LIST_SIP_ERROR = "LIST_SIP_ERROR";
export const EDIT_SIP_PENDING = "EDIT_SIP_PENDING";
export const EDIT_SIP_SUCCESS = "EDIT_SIP_SUCCESS";
export const EDIT_SIP_ERROR = "EDIT_SIP_ERROR";
export const ADD_SIP_PENDING = "ADD_SIP_PENDING";
export const ADD_SIP_SUCCESS = "ADD_SIP_SUCCESS";
export const ADD_SIP_ERROR = "ADD_SIP_ERROR";
export const APPROVAL_SIP_PENDING = "APPROVAL_SIP_PENDING";
export const APPROVAL_SIP_SUCCESS = "APPROVAL_SIP_SUCCESS";
export const APPROVAL_SIP_ERROR = "APPROVAL_SIP_ERROR";
export const DETAIL_SIP_PENDING = "APPROVAL_SIP_PENDING";
export const DETAIL_SIP_SUCCESS = "APPROVAL_SIP_SUCCESS";
export const DETAIL_SIP_ERROR = "APPROVAL_SIP_ERROR";
export const UPLOAD_SIP_PENDING = "UPLOAD_SIP_PENDING";
export const UPLOAD_SIP_SUCCESS = "UPLOAD_SIP_SUCCESS";
export const UPLOAD_SIP_ERROR = "UPLOAD_SIP_ERROR";
export const SET_SELECTED_SIP = "SET_SELECTED_SIP";

const LIST_SIP_URL = "member/sip_request/find";
const EDIT_SIP_URL = "member/sip_request/edit";
const ADD_SIP_URL = "member/sip_request/add";
const APPROVAL_SIP_URL = "member/sip_request/approve";
const UPLOAD_SIP_URL = "image/upload/sip_request_files";

export function SIPList(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SIP_PENDING));
    API.post(LIST_SIP_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_SIP_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 10,
        }));
        if (resolve) {
          if (detail) {
            let detailSKP = data[0].p2kb_details.map((item, i) => ({
              ...item,
              no: i + 1 + (param.page - 1) * 10,
            }));
            resolve({
              data: detailSKP,
              page: param.page - 1,
              totalCount: records_total,
            });
          } else {
            resolve({
              data: data,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_SIP_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function editSIP(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SIP_PENDING));
    API.post(EDIT_SIP_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah SIP");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SIP_ERROR, error));
        toastError(error, "Gagal mengubah SIP, ");
      });
  };
}

export function addSIP(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SIP_PENDING));
    var formData = new FormData();
    formData.append("file", param.file);
    API.post(UPLOAD_SIP_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        param.surat_pendukung_filename = filename;
        return API.post(ADD_SIP_URL, param, getHeader());
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambah SIP");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SIP_ERROR, error));
        toastError(error, "Gagal menambah SIP, ");
      });
  };
}

export function uploadSIP(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_SIP_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_SIP_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_SIP_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_SIP_ERROR, error));
        toastError(error, "Gagal upload berkas, ");
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function approvalSIP(param = {}, resolve, callback, action) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_SIP_PENDING));
    API.post(APPROVAL_SIP_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_SIP_SUCCESS, res.data.data));

        if (action === "tolak") {
          toastSuccess("Pengajuan SIP berhasil ditolak");
        } else {
          toastSuccess("Pengajuan SIP berhasil diverifikasi");
        }

        console.log(param);
        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_SIP_ERROR, error));
        toastError(error, "Gagal memverifikasi SIP, ");
      });
  };
}

export function setSelectedSIP(value) {
  return {
    type: SET_SELECTED_SIP,
    value: value,
  };
}

export function detailSIP(id = null, callback) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SIP_PENDING));
    API.post(LIST_SIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SIP_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SIP_ERROR, error));
        toastError(error, "Gagal mendapatkan detail SIP, ");
      });
  };
}
export function detailSIPSerkom(user_id = null, serkom_id = null) {
  const param = {
    user_id,
    serkom_id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SIP_PENDING));
    API.post(LIST_SIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SIP_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SIP_ERROR, error));
        toastError(error, "Gagal mendapatkan detail SIP, ");
      });
  };
}
