import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { NavLink } from "react-router-dom";
// import IconDashboard from "../../assets/icon-dashboard";
// import IconAccount from "../../assets/icon-account-circle";
// import IconBurger from "../../assets/icon-burger";
// import IconSchool from "../../assets/icon-school";

export default function (props) {
  // const [{icon:  }]
  const { link, title, icon } = props;
  const Icon = icon;

  return (
    <NavLink to={link} exact className={css(styles.navlink)}>
      <Row vertical="center" style={{ height: 40 }}>
        <Icon fontSize="small" />
        <span className={css(styles.title)}>{title}</span>
      </Row>
    </NavLink>
  );
}

const styles = StyleSheet.create({
  navlink: {
    textDecoration: "none",
  },
  title: {
    color: "#FFFFFF",
    marginLeft: 20,
  },
});
