import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import {
  experienceList,
  editExperience,
  setIsEditFile,
} from "../../actions/master-data/experience_action";
import {
  getExperienceListPending,
  getExperienceList,
  getExperienceListError,
  getExperienceEdit,
  getExperienceEditPending,
  getExperienceEditError,
  getExperienceIsEditFile,
} from "../../reducers/master-data/experience_reducer";
import { listCity } from "../../actions/master-data/city_action";
import {
  getCityListPending,
  getCityList,
  getCityListError,
} from "../../reducers/master-data/city_reducer";
import { connect } from "react-redux";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";

class DetailPengalaman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
    };
  }

  componentDidMount() {
    const { experienceList, listCity } = this.props;
    const param_experienceList = {
      page: 1,
      length: 1,
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_experienceList.id = id;
    experienceList(param_experienceList);
    listCity({ length: 999 });

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editExperience_isEditFile, setIsEditFile } = this.props;
    if (editExperience_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editExperience_isEditFile);
  }

  handleSubmit(values) {
    const { experienceList_data, editExperience } = this.props;

    const { berkas, institution, position, year, city } = values;
    const param = {
      id: experienceList_data.data[0].id,
      filename: experienceList_data.data[0].filename,
      institution,
      position,
      city: city.value,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      experienceList();
      this.handleTriggerFormEdit();
    };
    editExperience(param, callback);
  }

  render() {
    const {
      experienceList_data,
      handleSubmit,
      editExperience_pending,
      cityList_data,
      editExperience_isEditFile,
    } = this.props;
    const { isEdit } = this.state;
    let data = [];
    let member_name = "";
    let status = "";
    let filename = null;
    let reject_notes = null;
    let reject_filename = null;
    if (experienceList_data) {
      data = experienceList_data.data[0];
      member_name = data.member_data.name;
      status = data.status;
      filename = data.filename;
      reject_notes = data.reject_notes;
      reject_filename = data.reject_filename;
    }
    let cityOption = [];
    if (cityList_data) {
      let data = cityList_data.data;
      data.map((value) => {
        const city = { value: value.city_id, label: value.name };
        cityOption = [...cityOption, city];
        return city;
      });
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1957; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={member_name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data Pengalaman Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_experience"
            />
          </LeftContainerComponent>

          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Pengalaman" />
                {status === 2 &&
                  < EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editExperience_pending}
                  />
                }
              </Row>
              <FormContainer label="Nama Institusi">
                <Field
                  isDetail={!isEdit}
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Jabatan">
                <Field
                  isDetail={!isEdit}
                  name="position"
                  placeholder="Masukkan jabatan"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Kota">
                <Field
                  isDetail={!isEdit}
                  options={cityOption}
                  name="city"
                  placeholder="Masukkan kota"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Tahun">
                <Field
                  isDetail={!isEdit}
                  options={yearOptions}
                  name="year"
                  placeholder="Masukkan tahun"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Berkas">
                <Field
                  // triggerEditFile={() => {console.log("Edit...")}}
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editExperience_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  filePath="member_experience"
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function validate(values, props) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { editExperience_isEditFile } = props;
  const { institution, position, year, city, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!position) {
    errors.position = "Jabatan wajib diisi";
  }
  if (!year) {
    errors.year = "Tahun wajib diisi";
  }
  if (!city) {
    errors.city = "Kota wajib diisi";
  }
  if (editExperience_isEditFile && !berkas) {
    errors.berkas = "Berkas pengalaman wajib diisi";
  }
  else{
    if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
  }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
        errors.berkas = "File lebih dari 500KB"
    }
  }

  return errors;
}

DetailPengalaman = reduxForm({
  // a unique name for the form
  form: "DetailPengalaman",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(DetailPengalaman);

function mapStateToProps(state) {
  const { experienceList, editExperience, listCity } = state;
  let initialValues = {};
  const experienceList_data = getExperienceList(experienceList);
  const cityList_data = getCityList(listCity);
  if (cityList_data && experienceList_data) {
    // const getExperienceCity = cityList_data.data.find(
    //   (record) => record.name === experienceList_data.data[0].city
    // );
    // const getExperienceCity_id = getExperienceCity.city_id;
    if (experienceList_data && experienceList_data.length !== 0) {
      initialValues = {
        institution: experienceList_data.data[0].institution,
        position: experienceList_data.data[0].position,
        city: {
          label: experienceList_data.data[0].city,
          value: experienceList_data.data[0].city,
        },
        year: {
          label: experienceList_data.data[0].year,
          value: experienceList_data.data[0].year,
        },
      };
    }
  }

  return {
    experienceList_data: getExperienceList(experienceList),
    experienceList_error: getExperienceListError(experienceList),
    experienceList_pending: getExperienceListPending(experienceList),
    editExperience_error: getExperienceEditError(editExperience),
    editExperience_pending: getExperienceEditPending(editExperience),
    editExperience_data: getExperienceEdit(editExperience),
    editExperience_isEditFile: getExperienceIsEditFile(editExperience),
    cityList_error: getCityListError(listCity),
    cityList_pending: getCityListPending(listCity),
    cityList_data: getCityList(listCity),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  experienceList,
  editExperience,
  listCity,
  setIsEditFile,
})(withRouter(DetailPengalaman));
