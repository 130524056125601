import React, { Component } from "react";
import { connect } from "react-redux";
import { listSip } from "../../actions/p2kb/sip_action";
import {
  getListSip,
  getSipError,
  getSipPending,
} from "../../reducers/p2kb/sip_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import SipHeader from "./SipHeader";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import moment from "moment";
import localization from "moment/locale/id";

class Sip extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      status: null,
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/admin/sip/detail/${id}`);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id && this.state.id === null) {
      this.setState({ id: id });
    }
  }

  render() {
    const { listSip, listSip_data } = this.props;
    const { search_text } = this.state;
    let data = [];
    let records_total = 0;
    if (listSip_data) {
      data = listSip_data.data;
      records_total = listSip_data.records_total;
    }
    return (
      <ContentContainer>
        <SipHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Member",
              field: "user_data.name",
            },
            {
              title: "No SIP",
              field: "sip_no",
            },
            {
              title: "Tanggal Penetapan",
              render: ({ start_date }) => {
                return start_date
                  ? moment(start_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY")
                  : "-";
              },
            },
            {
              title: "Tanggal Berakhir",
              render: ({ end_date }) => {
                return end_date
                  ? moment(end_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY")
                  : "-";
              },
            },
            {
              title: "User",
              headerStyle: {
                minWidth: 140,
              },
              field: "role_next_approve",
              render: ({ role_next_approve }) =>
                role_next_approve
                  ? role_next_approve.role_name
                  : "Admin Paboi Cabang",
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Baru"
                        : status === 1
                        ? "Terverifikasi"
                        : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "Status SIP",
              field: "sip_type",
              render: ({ sip_type }) => {
                return (
                  <TableStatusComponent
                    status={
                      sip_type === 0
                        ? "Nonaktif"
                        : sip_type === 1
                        ? "Aktif"
                        : "Nonaktif"
                    }
                    jenisSIP="true"
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.detailRecord(id)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                status: this.state.status,
                id: this.state.id,
              };
              listSip(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSip }) {
  return {
    listSip_data: getListSip(listSip),
    listSip_error: getSipError(listSip),
    listSip_pending: getSipPending(listSip),
  };
}

export default connect(mapStateToProps, {
  listSip,
})(Sip);
