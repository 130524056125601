import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailStr,
  editStr,
  setIsEditFile,
} from "../../actions/p2kb/str_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import {
  getStrPending,
  getDetailStr,
  getStrError,
  getStrIsEditFile,
} from "../../reducers/p2kb/str_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderFile,
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import StrModalApprove from "./StrModalApprove";
import StrModalReject from "./StrModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import moment from 'moment';
import localization from 'moment/locale/id';
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";

class StrDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailStr } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailStr(id);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editStr_isEditFile, setIsEditFile } = this.props;
    if (editStr_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editStr_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true })
    } else {
      this.setState({ modalDocumentImgShow: true })
    }
  }

  handleSubmit(values) {
    const { detailStr_data, editStr, detailStr } = this.props;

    const { berkas, institution, str_type, year } = values;
    const param = {
      id: detailStr_data[0].id,
      filename: detailStr_data[0].filename,
      institution,
      str_type,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailStr();
      this.handleTriggerFormEdit();
    };
    editStr(param, callback);
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];
    const editStr_isEditFile = this.props

    const {
      detailStr_data,
      handleSubmit,
    } = this.props;
    const { isEdit, showApproveModal, showRejectModal } = this.state;
    let name, member_branch, filename, status, reject_notes, str_role_code, reject_filename, start_date, end_date;

    if (detailStr_data && detailStr_data.length !== 0) {
      name = detailStr_data[0].user_data.name;
      member_branch = detailStr_data[0].user_data.branch_id;
      filename = detailStr_data[0].filename;
      status = detailStr_data[0].status;
      reject_notes = detailStr_data[0].reject_notes;
      reject_filename = detailStr_data[0].reject_filename;
      str_role_code = detailStr_data[0].role_next_approve
        ? detailStr_data[0].role_next_approve.role_code
        : null;
      start_date = detailStr_data[0].start_date;
      end_date = detailStr_data[0].end_date;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_str"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_str"
        />
        <StrModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <StrModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data STR Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_str"
            />
            {getUser().role_codes[0] === str_role_code && getUser().branch_id === member_branch && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi STR" />
                {/* <EditButtonComponent
                  isEdit={isEdit}
                  triggerIsEdit={this.handleTriggerFormEdit}
                  isLoading={editStr_pending}
                /> */}
              </Row>
              <FormContainer label="No STR">
                <Field
                  isDetail={!isEdit}
                  name="str_no"
                  placeholder="Masukkan no STR"
                  component={renderInput}
                />
              </FormContainer>
              <DetailContentComponent
                label="Tanggal Penetapan"
                value={moment(start_date).locale('id', localization).format('DD MMMM YYYY')}
              />
              <DetailContentComponent
                label="Tanggal Berakhir"
                value={moment(end_date).locale('id', localization).format('DD MMMM YYYY')}
              />
              {/* <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Berkas STR")} />} /> */}
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editStr_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_str"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : status === 2
                        ? "Ditolak"
                        : "-"
                }
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

function validate(values, props) {
  const { editStr_isEditFile } = props;
  const { institution, str_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!str_type) {
    errors.str_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editStr_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

StrDetail = reduxForm({
  // a unique name for the form
  form: "StrModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(StrDetail);

function mapStateToProps(state) {
  const { detailStr, editStr } = state;
  let initialValues = {};
  const detailStr_data = getDetailStr(detailStr);
  if (detailStr_data && detailStr_data.length !== 0) {
    initialValues = {
      str_no: detailStr_data[0].str_no,
      start_date: detailStr_data[0].start_date,
      end_date: detailStr_data[0].end_date,
    };
  }

  return {
    detailStr_data: getDetailStr(detailStr),
    detailStr_error: getStrError(detailStr),
    detailStr_pending: getStrPending(detailStr),
    editStr_error: getStrError(editStr),
    editStr_pending: getStrPending(editStr),
    editStr_isEditFile: getStrIsEditFile(editStr),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailStr,
  editStr,
  setIsEditFile,
})(withRouter(StrDetail));
