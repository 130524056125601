import {
    REGISTRATION_LIST_PENDING,
    REGISTRATION_LIST_SUCCESS,
    REGISTRATION_LIST_ERROR,
    REGISTRATION_VERIFICATION_PENDING,
    REGISTRATION_VERIFICATION_SUCCESS,
    REGISTRATION_VERIFICATION_ERROR,
    PPDS_STATUS_APPROVAL_PENDING,
    PPDS_STATUS_APPROVAL_SUCCESS,
    PPDS_STATUS_APPROVAL_ERROR
} from "../../actions/registrasi/registrasi_action";

const initialState = {
    pending: false,
    error: null
};

export function registrationList(
    state = { ...initialState, registrationList: null },
    action
) {
    switch (action.type) {
        case REGISTRATION_LIST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case REGISTRATION_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                registrationList: action.data,
            };
        case REGISTRATION_LIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function registrationVerification(
    state = { ...initialState, registrationVerification: null },
    action
) {
    switch (action.type) {
        case REGISTRATION_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case REGISTRATION_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                registrationVerification: action.data,
            };
        case REGISTRATION_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function ppdsStatusApproval(
    state = { ...initialState, ppdsStatusApproval: null },
    action
) {
    switch (action.type) {
        case PPDS_STATUS_APPROVAL_PENDING:
            return {
                ...state,
                pending: true,
            };
        case PPDS_STATUS_APPROVAL_SUCCESS:
            return {
                ...state,
                pending: false,
                ppdsStatusApproval: action.data,
            };
        case PPDS_STATUS_APPROVAL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getRegistrationListPending = (state) => state.pending;
export const getRegistrationList = (state) => state.registrationList;
export const getRegistrationListError = (state) => state.error;
export const getRegistrationVerificationPending = (state) => state.pending;
export const getRegistrationVerification = (state) => state.registrationVerification;
export const getRegistrationVerificationError = (state) => state.error;
export const getppdsStatusApprovalPending = (state) => state.pending;
export const getppdsStatusApproval = (state) => state.ppdsStatusApproval;
export const getppdsStatusApprovalError = (state) => state.error;