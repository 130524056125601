import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import {
  registrationList,
  registrationVerification,
} from "../../actions/registrasi/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
  getRegistrationVerification,
  getRegistrationVerificationPending,
  getRegistrationVerificationError,
} from "../../reducers/registrasi/registrasi_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, reset, formValueSelector } from "redux-form";
import { renderInputWithLabel } from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import InputComponent from "../../components/form/InputComponent";
class ModalRegistrasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: this.props.notes,
      kolegium_no: this.props.kolegium_no,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleChangeKolegiumNo = this.handleChangeKolegiumNo.bind(this);
  }
  registrationVerification(id) {
    const { registrationVerification, onHide } = this.props;
    let formDataRegistrationVerification = new FormData();
    formDataRegistrationVerification.append("ppds_id", id);
    formDataRegistrationVerification.append("is_approve", true);
    // this.props.registrationVerification(formDataRegistrationVerification);
    const param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: id,
    };
    const callback = () => {
      onHide();
      this.props.registrationList(param_registrationList);
    };
    registrationVerification(formDataRegistrationVerification, callback);
  }
  handleSubmit(values) {
    const { registrationVerification, onHide } = this.props;
    let formData = new FormData();
    formData.append(
      "kolegium_no",
      this.state.kolegium_no || this.props.kolegium_no
    );
    formData.append("notes", this.state.notes || this.props.notes);
    formData.append("ppds_id", this.props.id);
    formData.append("is_approve", true);
    const param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: this.props.id,
    };
    const callback = () => {
      onHide();
      this.props.registrationList(param_registrationList);
    };
    registrationVerification(formData, callback);
  }
  handleChangeNotes(e) {
    if (e !== null) {
      this.setState({ notes: e.target.value });
    } else {
      this.setState({ notes: "" });
    }
  }
  handleChangeKolegiumNo(e) {
    if (e !== null) {
      this.setState({ kolegium_no: e.target.value });
    } else {
      this.setState({ kolegium_no: "" });
    }
  }
  render() {
    const { notes, kolegium_no } = this.state;
    let notes_value,
      kolegium_no_value = "";
    if (notes) {
      notes_value = notes;
    }
    if (kolegium_no) {
      kolegium_no_value = kolegium_no;
    }

    const isNoKolegiumEmpty = kolegium_no === ""
    const isNotesEmpty = notes === ""

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={this.props.handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              <LabelInputVerticalComponent label="Nomor Kolegium" width="100%">
                <InputComponent
                  name="kolegium_no"
                  placeholder="Masukkan Nomor Kolegium"
                  onChange={this.handleChangeKolegiumNo}
                  defaultValue={kolegium_no_value}
                  id="kolegium_no"
                />
                {isNoKolegiumEmpty && (
                  <span style={{ color: "red" }}>
                    nomor kolegium tidak boleh kosong*
                  </span>
                )}
              </LabelInputVerticalComponent>
              <LabelInputVerticalComponent label="Catatan" width="100%">
                <InputComponent
                  name="notes"
                  placeholder="Masukkan Catatan"
                  label="Catatan"
                  onChange={this.handleChangeNotes}
                  defaultValue={notes_value}
                  id="notes"
                  isTextarea
                />
                {isNotesEmpty && (
                    <span style={{ color: "red" }}>
                      catatan tidak boleh kosong*
                    </span>
                  )}
              </LabelInputVerticalComponent>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.props.registrationVerification_pending}
                className={css(styles.approveButton)}
                startIcon={
                  this.props.registrationVerification_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                Verifikasi
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}
const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});
function validate(values) {
  const { kolegium_no, notes } = values;
  const errors = {};
  if (!kolegium_no) {
    errors.kolegium_no = "Nomor Kolegium wajib diisi";
  }
  if (!notes) {
    errors.notes = "Catatan wajib diisi";
  }
  return errors;
}
const afterSubmit = (result, dispatch) => dispatch(reset("ModalRegistrasi"));
ModalRegistrasi = reduxForm({
  form: "ModalRegistrasi",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalRegistrasi);
function mapStateToProps(state) {
  const { registrationList, registrationVerification } = state;
  const selector = formValueSelector("ModalRegistrasi");
  const formValue = selector(state, "notes");
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    registrationVerification_pending: getRegistrationVerificationPending(
      registrationVerification
    ),
    registrationVerification_data: getRegistrationVerification(
      registrationVerification
    ),
    registrationVerification_error: getRegistrationVerificationError(
      registrationVerification
    ),
    formValue,
  };
}
export default connect(mapStateToProps, {
  registrationList,
  registrationVerification,
})(ModalRegistrasi);
