import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListPelatihan from "./ListPelatihan";

class Pelatihan extends Component {
    render() {
        return (
            <ContentContainer>
                <ListPelatihan />
            </ContentContainer>
        );
    }
}

export default Pelatihan;