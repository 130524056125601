import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import IconError from "../../assets/icon-error";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { listJournal } from "../../actions/journal/journal_action";
import {
  getJournalPending,
  getListJournal,
  getJournalError,
} from "../../reducers/journal/journal_reducer";
import { userList } from "../../actions/master-data/user_action";
import {
  getUserListPending,
  getUserList,
  getUserListError,
} from "../../reducers/master-data/user_reducer";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { toast } from "react-toastify";
import ModalApproveJournal from "./Modal_approve_journal";
import ModalRejectJournal from "./Modal_reject_journal";
import ModalPublishJournal from "./Modal_publish_journal";
import ModalDetailRevisionJournal from "./Modal_revision_specific";
import IconLaunch from '../../assets/icon-launch';

class DetailJournalRevisi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBerkasJurnalShow: false,
      modalApproveJournalShow: false,
      modalRejectJournalShow: false,
      modalPublishJournalShow: false,
      modalRevisionDetailShow: false,
      isRevision: false,
      isApprove: false,
      checkboxMethods: false,
      checkboxResult: false,
      checkboxDiscussion: false,
      forRefresh: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
  }

  informasiUmum(id) {
    this.props.history.push(`/journal/detail/informasi-umum/${id}`);
  }
  abstrak(id) {
    this.props.history.push(`/journal/detail/abstrak/${id}`);
  }

  handleSubmit(values) {
    if (values.editor) {
      let formData = new FormData();
      formData.append("id", this.props.id);
      formData.append("is_approve", "true");
      formData.append("editor_id", values.editor.value);
      formData.append("reviewer_id", values.reviewer.value);
      formData.append("language_editor_id", values.langEditor.value);
    }
    toast.error("Work in progress", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  componentDidMount() {
    let param_listJournal = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_listJournal.id = id;
    this.props.listJournal(param_listJournal);
    this.props.userList({ length: 999 });
  }

  shouldComponentRender() {
    const { listJournal_pending } = this.props;
    if (listJournal_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { listJournal_data } = this.props;
    let data = [];
    let title,
      status,
      messageTitle,
      message,
      imgUrl,
      step,
      role_next_approve,
      recommendation,
      quality,
      impact,
      novelty,
      last_revisions,
      id,
      generalRemarks = "";
    if (listJournal_data) {
      data = listJournal_data.data[0];
      id = data.id;
      status = data.status;
      step = data.step;
      title = data.title;
      last_revisions = data.journal_revisions[data.journal_revisions.length - 1];
      generalRemarks = last_revisions.general_remarks !== null ? last_revisions.general_remarks : "-";
      recommendation = last_revisions.recommendation !== null ? last_revisions.recommendation : "-";
      quality = last_revisions.quality !== null ? last_revisions.quality : "-";
      impact = last_revisions.impact !== null ? last_revisions.impact : "-";
      novelty = last_revisions.novelty !== null ? last_revisions.novelty : "-";
      if (status !== 1 && step !== 5) {
        role_next_approve = data.role_next_approve.role_name;
      } else {
        role_next_approve = "Language Editor";
      }
      messageTitle =
        status === 0
          ? "Menunggu Verifikasi"
          : status === 1
            ? "Disetujui"
            : status === 2
              ? "Ditolak"
              : status === 3
                ? "Publish"
                : "Revisi";
      message =
        status === 0
          ? "Menunggu Verifikasi " + role_next_approve
          : status === 1
            ? "Disetujui oleh " + role_next_approve
            : status === 2
              ? "Ditolak"
              : status === 3
                ? "Jurnal ini telah terbit"
                : "Terdapat revisi jurnal oleh " + role_next_approve;
    }
    return (
      <ContentContainer>
        <Row style={{ minHeight: "78vh" }}>
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={title} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={status}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl}
              />
              <DetailMenuComponent
                menuName="Informasi Umum"
                onClick={() => this.informasiUmum(data.id)}
              />
              <DetailMenuComponent
                onClick={() => this.abstrak(data.id)}
                menuName="Abstrak"
              />
              {status === -1 && (
                <DetailMenuComponent
                  menuName="Detail Revisi"
                  isActive={true}
                />
              )}
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <ModalApproveJournal
                show={this.state.modalApproveJournalShow}
                onHide={() => this.setState({ modalApproveJournalShow: false })}
                id={id}
                handleRefresh={this.handleRefresh}
              />
              <ModalRejectJournal
                show={this.state.modalRejectJournalShow}
                onHide={() => this.setState({ modalRejectJournalShow: false })}
                id={id}
                handleRefresh={this.handleRefresh}
              />
              <ModalPublishJournal
                show={this.state.modalPublishJournalShow}
                onHide={() => this.setState({ modalPublishJournalShow: false })}
                id={id}
                handleRefresh={this.handleRefresh}
              />
              <ModalDetailRevisionJournal
                show={this.state.modalRevisionDetailShow}
                onHide={() => this.setState({ modalRevisionDetailShow: false })}
                id={id}
                handleRefresh={this.handleRefresh}
              />
              <Row horizontal="space-between" style={{ alignItems: "center" }}>
                <DetailTitleComponent
                  title="Detail Revisi"
                  marginBottom={true}
                />
              </Row>
              <>
                <DetailContentComponent
                  label="General Remarks"
                  value=
                  {
                    generalRemarks && generalRemarks !== "" ? generalRemarks.split("\n").map(function (item, idx) {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      )
                    })
                      :
                      ""
                  }
                />
                <DetailContentComponent label="Recommendation" value={recommendation} />
                <DetailContentComponent label="Quality" value={quality} />
                <DetailContentComponent label="Impact for Knowledge" value={impact} />
                <DetailContentComponent label="Novelty or Originality" value={novelty} />
                <Button variant="contained" color="primary" startIcon={<IconLaunch fill="#FFFFFF" />} className={css(styles.revisionButton)} onClick={() => this.setState({ modalRevisionDetailShow: true })}>Revisi Spesifik</Button>
              </>
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginLeft: 10,
    padding: 10,
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginLeft: 10,
    color: "#495057",
    boxShadow: "none",
  },
  contentLabel: {
    textAlign: "left",
    fontSize: 14,
    color: "#495057",
    width: "30%",
    opacity: 0.7,
  },
  contentValue: {
    textAlign: "left",
    fontSize: 14,
    width: "70%",
  },
  revisionButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginTop: 10,
    padding: 10,
    width: "25%"
  }
});

function validate(values) {
  const {
    reviewer,
    editor,
    langEditor,
    generalRemarks,
    recommendation,
    quality,
    impact,
    novelty,
    specificRemarks
  } = values;
  const errors = {};
  if (!reviewer) {
    errors.reviewer = "Reviewer wajib diisi";
  }
  if (!editor) {
    errors.editor = "Editor wajib diisi";
  }
  if (!langEditor) {
    errors.langEditor = "Language Editor wajib diisi";
  }
  if (!generalRemarks) {
    errors.generalRemarks = "General Remarks wajib diisi";
  }
  if (!recommendation) {
    errors.recommendation = "Recommendation wajib diisi";
  }
  if (!quality) {
    errors.quality = "Quality wajib diisi";
  }
  if (!impact) {
    errors.impact = "Impact wajib diisi";
  }
  if (!novelty) {
    errors.novelty = "Novelty wajib diisi";
  }
  if (!specificRemarks) {
    errors.specificRemarks = "Specific Remarks wajib diisi";
  }
  // if (methods) {
  //     if (!improveMethod){
  //         errors.improveMethod = "Method wajib diisi"
  //     }
  // }
  // if (results) {
  //     if (!improveResult){
  //         errors.improveResult = "Results wajib diisi"
  //     }
  // }
  // if (discussion) {
  //     if (!improveDiscussion){
  //         errors.improveDiscussion = "Discussion wajib diisi"
  //     }
  // }
  return errors;
}

DetailJournalRevisi = reduxForm({
  form: "DetailJournalRevisi",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(DetailJournalRevisi);

function mapStateToProps({ listJournal, userList }) {
  return {
    listJournal_pending: getJournalPending(listJournal),
    listJournal_data: getListJournal(listJournal),
    listJournal_error: getJournalError(listJournal),
    userList_pending: getUserListPending(userList),
    userList_data: getUserList(userList),
    userList_error: getUserListError(userList),
  };
}

export default connect(mapStateToProps, { listJournal, userList })(
  withRouter(DetailJournalRevisi)
);
