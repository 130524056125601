import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { listFinalPaper, detailFinalPaper, setSelectedFinalPaper } from "../../actions/cms-final-paper/cms_final_paper_action";
import {
    getListFinalPaper,
    getListFinalPaperPending,
    getListFinalPaperError
} from "../../reducers/cms-final-paper/cms_final_paper_reducer";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import HeaderFinalPaper from "./FinalPaperHeader";
import { universityList } from "../../actions/master-data/university_action";
import {
    getUniversityList,
    getUniversityListPending,
} from "../../reducers/master-data/university_reducer";
import { listEvent } from "../../actions/event/event_action";
import {
    getEventPending,
    getListEvent,
} from "../../reducers/event/event_reducer";
import ModalEditFinalPaper from "./Modal_Edit_FinalPaper";
import ModalDeleteFinalPaper from "./Modal_delete_FinalPaper";

class FinalPaper extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            modalEditFinalPaperShow: false,
            modalDeleteFinalPaperShow: false,
            id: null
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleDetailFinalPaper(data) {
        this.props.history.push(`final-paper/detail/${data.id}`);
    }

    handleEditFinalPaper(data) {
        this.props.setSelectedFinalPaper(data)
        this.setState({ modalEditFinalPaperShow: true })
    }

    handleDeleteFinalPaper(data) {
        this.setState({ id: data.id });
        this.setState({ modalDeleteFinalPaperShow: true });
    }

    componentDidMount() {
        const { universityList, listEvent } = this.props;
        universityList({ length: 99999 })
        listEvent({ length: 99999 })
    }

    render() {
        let data = [];
        let records_total = 0;
        const { listFinalPaper, listFinalPaper_data } = this.props;
        if (listFinalPaper_data) {
            data = listFinalPaper_data.data;
            records_total = listFinalPaper_data.records_total;
        }
        return (
            <ContentContainer>
                <HeaderFinalPaper
                    handleRefresh={this.handleRefresh}
                />
                <ModalEditFinalPaper
                    show={this.state.modalEditFinalPaperShow}
                    onHide={() => this.setState({ modalEditFinalPaperShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <ModalDeleteFinalPaper
                    show={this.state.modalDeleteFinalPaperShow}
                    onHide={() => this.setState({ modalDeleteFinalPaperShow: false })}
                    handleRefresh={this.handleRefresh}
                    id={this.state.id}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Simple Action Preview"
                    columns={
                        [
                            {
                                title: "No",
                                field: "messageBlast_name",
                                render: (rowData) => {
                                    return <>{rowData.no}</>;
                                },
                                width: 20,
                                headerStyle: {
                                    paddingLeft: 30,
                                },
                                cellStyle: {
                                    paddingLeft: 30,
                                },
                            },
                            {
                                title: "Nama Event",
                                field: "event_data.event_name"
                            },
                            {
                                title: "Nama Penulis",
                                field: "author_paper",
                            },
                            {
                                title: "Judul Paper",
                                field: "title_paper",
                            },
                            {
                                title: "Tahun",
                                field: "year",
                            },
                            {
                                title: "",
                                render: (rowData) => {
                                    return (
                                        <DetailButtonComponent>
                                            <MenuItem onClick={() => this.handleDetailFinalPaper(rowData)}>
                                                Detail
                                            </MenuItem>
                                            <MenuItem onClick={() => this.handleEditFinalPaper(rowData)}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => this.handleDeleteFinalPaper(rowData)}>
                                                Hapus
                                            </MenuItem>
                                        </DetailButtonComponent>
                                    );
                                },
                            },
                        ]
                    }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                            };
                            listFinalPaper(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                            // width: 10,
                            // paddingLeft: "40px",
                            // paddingRight: "40px",
                        }, //change header padding
                        // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <td style={{ width: "100%", display: "block" }}>
                                <Row
                                    // vertical="center"
                                    horizontal="space-between"
                                    style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        // background: "red",
                                    }}
                                >
                                    <Row
                                        style={{
                                            // backgroundColor: "red",
                                            width: "50%",
                                        }}
                                        vertical="center"
                                    >
                                        <p>
                                            Menampilkan {data.length} dari {records_total} data
                                        </p>
                                    </Row>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <TablePagination {...props} />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                            </td>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listFinalPaper, listEvent, universityList }) {
    return {
        listFinalPaper_data: getListFinalPaper(listFinalPaper),
        listFinalPaper_error: getListFinalPaperError(listFinalPaper),
        listFinalPaper_pending: getListFinalPaperPending(listFinalPaper),
        listEvent_data: getListEvent(listEvent),
        listEvent_pending: getEventPending(listEvent),
        listUniversity_data: getUniversityList(universityList),
        listUniversity_pending: getUniversityListPending(universityList),
    };
}

export default connect(mapStateToProps, {
    listFinalPaper,
    detailFinalPaper,
    setSelectedFinalPaper,
    listEvent,
    universityList
})(FinalPaper);