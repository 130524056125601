import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../components/form/FormComponent";
import { uploadBenefit, addBenefit } from "../../actions/benefit/benefit_action";
import { getBenefitUploadPending, getBenefitUpload, getBenefitUploadError, getBenefitAddPending, getBenefitAdd, getBenefitAddError } from "../../reducers/benefit/benefit_reducer";
import { listMasterBenefit } from "../../actions/master-data/master_benefit_action";
import { getListMasterBenefitPending, getListMasterBenefit, getListMasterBenefitError } from "../../reducers/master-data/master_benefit_reducer";
import Loading from "../../components/global-components/LoadingComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocument from "./Modal_document";
import ModalDocumentPDF from "./Modal_document_pdf";

class ModalAddBenefit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filename_button: false,
            isDoneUploading: true,
            modalDetailDokumenPDFShow: false,
            modalDetailDokumenShow: false,
            filename: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeBenefit = this.handleChangeBenefit.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    handleChangeBenefit(e) {
        const { listMasterBenefit_data, change } = this.props;
        if (e) {
            const value = e.value;
            const selected_benefit = listMasterBenefit_data.data.filter(function (item) {
                return item.id === value;
            });
            change('redeem_total', 'Rp ' + selected_benefit[0].total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));
        }
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        this.setState({ isDoneUploading: false })
        this.setState({ filename_button: true })
        if (formName === "filename") {
            this.setState({ filename: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ filename_button: false })
            if (this.props.uploadBenefit_data) {
                if (formName === "filename") {
                    this.props.change("filename", this.props.uploadBenefit_data);
                    this.setState({ filename: <Loading done={true} /> })
                    setTimeout(() => this.setState({ filename: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.filename, "Benefit")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ filename_button: false })
            if (this.props.uploadBenefit_error) {
                if (formName === "filename") {
                    this.props.change("filename", null);
                    this.setState({ filename: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadBenefit(values.target.files[0], callback, error);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenPDFShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleSubmit(values) {
        let param = {
            benefit_id: values.benefit_id.value,
            filename: values.filename ? values.filename : "default.jpg"
        }
        const callback = () => {
            this.props.handleRefresh();
            this.props.onHide();
            this.props.reset();
        }
        this.props.addBenefit(param, callback);
    }

    componentDidMount() {
        this.props.listMasterBenefit({ length: 99999 });
    }

    render() {
        const { show,
            onHide,
            handleSubmit,
            listMasterBenefit_data
        } = this.props;
        let data, benefitOptions = [];
        let activeBenefit;
        if (listMasterBenefit_data) {
            data = listMasterBenefit_data.data;
            activeBenefit = data.filter(item => item.status !== 0);
            benefitOptions = activeBenefit.map((item) => ({
                label: item.name,
                value: item.id
            }));
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDocumentPDF
                    show={this.state.modalDetailDokumenPDFShow}
                    onHide={() => this.setState({ modalDetailDokumenPDFShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDocument
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Pengajuan Benefit</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="redeem_date"
                                    label="Tanggal Redeem"
                                    disabled={true}
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="benefit_id"
                                    placeholder="Pilih Benefit"
                                    label="Nama Benefit"
                                    options={benefitOptions}
                                    onChange={this.handleChangeBenefit}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="redeem_total"
                                    placeholder="Pilih benefit untuk mendapatkan total redeem"
                                    label="Total Redeem"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="filename"
                                    type="file"
                                    fileType="pdf"
                                    title="Upload File"
                                    disabled={this.state.filename_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.filename === undefined ? "PDF" : this.state.filename === null ? "PDF" : this.state.filename}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={<CheckIcon />}
                            >
                                Simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const { benefit_id, redeem_total } = values;
    const errors = {}
    if (!benefit_id) {
        errors.benefit_id = "Nama Benefit wajib diisi"
    }
    if (benefit_id && redeem_total === "Benifit tidak ditemukan") {
        errors.benefit_id = "Benefit tidak ditemukan"
    }
    return errors;
}

ModalAddBenefit = reduxForm({
    form: "ModalAddBenefit",
    validate: validate,
    // onSubmitSuccess: afterSubmit,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalAddBenefit)

function mapStateToProps(state) {
    const { listMasterBenefit, uploadBenefit, addBenefit } = state;
    const selector = formValueSelector("ModalAddBenefit");
    const formValue = selector(state, "benefit_id", "filename");
    return {
        listMasterBenefit_pending: getListMasterBenefitPending(listMasterBenefit),
        listMasterBenefit_data: getListMasterBenefit(listMasterBenefit),
        listMasterBenefit_error: getListMasterBenefitError(listMasterBenefit),
        uploadBenefit_pending: getBenefitUploadPending(uploadBenefit),
        uploadBenefit_data: getBenefitUpload(uploadBenefit),
        uploadBenefit_error: getBenefitUploadError(uploadBenefit),
        addBenefit_pending: getBenefitAddPending(addBenefit),
        addBenefit_data: getBenefitAdd(addBenefit),
        addBenefit_error: getBenefitAddError(addBenefit),
        formValue
    }
}

export default connect(mapStateToProps, { listMasterBenefit, uploadBenefit, addBenefit })(withRouter(ModalAddBenefit));