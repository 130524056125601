import {
  LIST_PPDS_PENDING,
  LIST_PPDS_SUCCESS,
  LIST_PPDS_ERROR,
  DETAIL_PPDS_PENDING,
  DETAIL_PPDS_SUCCESS,
  DETAIL_PPDS_ERROR,
  EDIT_PPDS_PENDING,
  EDIT_PPDS_SUCCESS,
  EDIT_PPDS_ERROR,
  DELETE_PPDS_PENDING,
  DELETE_PPDS_SUCCESS,
  DELETE_PPDS_ERROR,
  ADD_PPDS_PENDING,
  ADD_PPDS_SUCCESS,
  ADD_PPDS_ERROR,
  CHANGE_STATUS_GRADUATION_PPDS_PENDING,
  CHANGE_STATUS_GRADUATION_PPDS_SUCCESS,
  CHANGE_STATUS_GRADUATION_PPDS_ERROR,
} from "../../actions/member/ppds_action";

const initialState = {
  pending: false,
  list_ppds: null,
  detail_ppds: null,
  error: null,
};

export function listPpds(state = initialState, action) {
  switch (action.type) {
    case LIST_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        list_ppds: action.data,
      };
    case LIST_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailPpds(state = initialState, action) {
  switch (action.type) {
    case DETAIL_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_ppds: action.data,
      };
    case DETAIL_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editPpds(state = initialState, action) {
  switch (action.type) {
    case EDIT_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
        editPpds: action.data
      };
    case EDIT_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function chanchangeStatusGraduation(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STATUS_GRADUATION_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_STATUS_GRADUATION_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case CHANGE_STATUS_GRADUATION_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addPpds(state = initialState, action) {
  switch (action.type) {
    case ADD_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deletePpds(state = initialState, action) {
  switch (action.type) {
    case DELETE_PPDS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_PPDS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_PPDS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListPpds = (state) => state.list_ppds;
export const getDetailPpds = (state) => state.detail_ppds;
export const getPpdsPending = (state) => state.pending;
export const getPpdsError = (state) => state.error;
export const getEditPpds = (state) => state.editPpds;
export const getEditPpdsPending = (state) => state.pending;
export const getEditPpdsError = (state) => state.error;