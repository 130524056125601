import {
  LIST_SUBSPECIALIST_PENDING,
  LIST_SUBSPECIALIST_SUCCESS,
  LIST_SUBSPECIALIST_ERROR,
  LIST_ALLOCATION_ERROR,
  LIST_ALLOCATION_PENDING,
  LIST_ALLOCATION_SUCCESS,
  LIST_ESTIMATIONFEE_ERROR,
  LIST_ESTIMATIONFEE_PENDING,
  LIST_ESTIMATIONFEE_SUCCESS,
  LIST_TOTALFEEIN_ERROR,
  LIST_TOTALFEEIN_PENDING,
  LIST_TOTALFEEIN_SUCCESS,
  LIST_TOTALMEMBER_ERROR,
  LIST_TOTALMEMBER_PENDING,
  LIST_TOTALMEMBER_SUCCESS,
  LIST_TOTALMEMBER_CABANG_ERROR,
  LIST_TOTALMEMBER_CABANG_PENDING,
  LIST_TOTALMEMBER_CABANG_SUCCESS,
  LIST_COOPERATIVE_MEMBERHSIP_ERROR,
  LIST_COOPERATIVE_MEMBERHSIP_PENDING,
  LIST_COOPERATIVE_MEMBERHSIP_SUCCESS


} from "../../actions/dashboard/pusat_action";




const initialState = {
  pending: false,
  data: null,
  error: null,
};

export function listSubspecialist(state = initialState, action) {
  switch (action.type) {
    case LIST_SUBSPECIALIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SUBSPECIALIST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_SUBSPECIALIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listEstimationFee(state = initialState, action) {
  switch (action.type) {
    case LIST_ESTIMATIONFEE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ESTIMATIONFEE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_ESTIMATIONFEE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTotalMember(state = initialState, action) {
  switch (action.type) {
    case LIST_TOTALMEMBER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TOTALMEMBER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_TOTALMEMBER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTotalMemberCabang(state = initialState, action) {
  switch (action.type) {
    case LIST_TOTALMEMBER_CABANG_PENDING:
      return {

        ...state,
        data: null,
        pending: true,
      };
    case LIST_TOTALMEMBER_CABANG_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_TOTALMEMBER_CABANG_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTotalFeeIn(state = initialState, action) {
  switch (action.type) {
    case LIST_TOTALFEEIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TOTALFEEIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_TOTALFEEIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listAllocation(state = initialState, action) {
  switch (action.type) {
    case LIST_ALLOCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ALLOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_ALLOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export function listCooperative(state = initialState, action) {
  switch (action.type) {
    case LIST_COOPERATIVE_MEMBERHSIP_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_COOPERATIVE_MEMBERHSIP_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_COOPERATIVE_MEMBERHSIP_ERROR
      :
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}








export const getDashboardData = (state) => state.data;
export const getDashboardPending = (state) => state.pending;
export const getDashboardError = (state) => state.error;
