import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalAddDetailSKP_MenciptakanAlat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas_medis: null,
      skp: 0,
      imgUrl: "",
      modal_title: "",
      isDoneUploading: true,
      berkas_medis_button: null,
      spek_berkas_medis: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTingkatan = this.handleChangeTingkatan.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  handleChangeTingkatan(values) {
    let listActivity_selected = this.props.listActivity_data.data.find(
      (item) => item.id === this.state.tempSKPData.activity.value
    );
    let childrens_selected = listActivity_selected.childrens.find(
      (item) => item.id === values.value
    );

    this.setState({ last_activity_skp_detail_id: childrens_selected.id });
    this.setState({ values_id: childrens_selected.values[0].id });
    let skp_must_be_multipied =
      childrens_selected.values[0].skp_must_be_multipied;
    let value_multipied = childrens_selected.values[0].value_multipied;
    if (skp_must_be_multipied === 0) {
      this.props.change("skp_total", childrens_selected.values[0].value_skp);
      this.setState({ skp: childrens_selected.values[0].value_skp });
    } else {
      this.props.change(
        "skp_total",
        Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        )
      );
      this.setState({
        skp: Math.round(
          this.props.formValue.total_for_calculate_skp * value_multipied
        ),
      });
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({ berkas_medis_button: true })
    this.setState({ isDoneUploading: false })
    if (formName === "berkas_medis") {
      this.setState({ berkas_medis: <Loading /> })
    }
    const callback = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ berkas_medis_button: false })
      if (this.props.uploadP2kb_data) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", this.props.uploadP2kb_data);
          this.setState({ berkas_medis: <Loading done={true} /> })
          setTimeout(() => this.setState({ berkas_medis: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_medis, "Berkas")} /> }), 2000)
        }
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true })
      this.setState({ berkas_medis_button: false })
      if (this.props.uploadP2kb_error) {
        if (formName === "berkas_medis") {
          this.props.change("berkas_medis", null);
          this.setState({ berkas_medis: <Loading done={true} failed={true} /> })
        }
      }
    }
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas_medis", values.target.files[0]);
  }

  handleSubmit(values) {
    let details_tobesent = {
      activity_date: values.activity_date,
      tools_name: values.tools_name,
      tools_function: values.tools_function.value === "Lainnya" ? values.other_function : values.tools_function.value,
      patent_number: values.patent_number,
      patent_date: values.patent_date,
      total_skp: values.skp_total,
      mst_activity_skp_type_detail_data: {
        name: values.tingkatan.label
      },
      last_activity_skp_detail_id: this.state.last_activity_skp_detail_id,
      values_id: this.state.values_id,
      attachments: [
        {
          label: "Dokumen Bukti",
          filename: values.berkas_medis,
        },
      ],
    };
    let details_tobeshown = {
      ...details_tobesent,
      tingkatan: values.tingkatan.label,
      skp: Math.round(this.state.skp),
    };
    this.props.handleAddDetail(details_tobeshown, details_tobesent);
    this.props.onHide();
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      listActivity_data,
      uploadP2kb_pending,
    } = this.props;
    let jenisOptions = [];
    let tingkatanOptions = [];
    if (listActivity_data) {
      let listActivity_selected = listActivity_data.data.find(
        (item) => item.id === this.state.tempSKPData.activity.value
      );
      tingkatanOptions = listActivity_selected?.childrens.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    jenisOptions = [
      {
        label: "Rekontruksi",
        value: "Rekontruksi",
      },
      {
        label: "Internal Fiksasi",
        value: "Internal Fiksasi",
      },
      {
        label: "Lainnya",
        value: "Lainnya",
      },
    ];

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="tingkatan"
                  placeholder="Masukkan Tingkat Kegiatan"
                  label="Tingkat Kegiatan"
                  onChange={this.handleChangeTingkatan}
                  isClearable={false}
                  options={tingkatanOptions}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="tools_name"
                  placeholder="Nama Alat"
                  label="Nama Alat"
                  component={renderInputWithLabel}
                />
                <Field
                  name="tools_function"
                  placeholder="Fungsi Alat"
                  label="Fungsi Alat"
                  isClearable={false}
                  options={jenisOptions}
                  component={renderSelectWithLabel}
                />
                {this.props.formValue.tools_function && this.props.formValue.tools_function.value === "Lainnya" &&
                  <Field
                    name="other_function"
                    placeholder="Masukkan Fungsi Alat"
                    label="Fungsi Alat (lainnya)"
                    component={renderInputWithLabel}
                  />
                }
                <Field
                  name="patent_number"
                  placeholder="Nomor Paten"
                  label="Nomor Paten"
                  component={renderInputWithLabel}
                />
                <Field
                  name="patent_date"
                  placeholder="Masukkan Tanggal Paten"
                  label="Tanggal Paten"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas_medis"
                  type="file"
                  fileType="skp"
                  title="Upload Berkas"
                  disabled={this.state.berkas_medis_button}
                  isDone={this.state.isDoneUploading}
                  message={
                    this.props.formValue.berkas_medis === undefined
                      ? "Image atau PDF maksimal 1 MB"
                      : this.state.berkas_medis === null
                        ? "Image atau PDF maksimal 1 MB"
                        : this.state.berkas_medis
                  }
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const typeImage = /image/
  const fileType = "application/pdf"
  const {
    activity_date,
    tingkatan,
    tools_name,
    tools_function,
    patent_date,
    patent_number,
    berkas_medis,
    other_function,
    spek_berkas_medis
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!tingkatan) {
    errors.tingkatan = "Tibgkat Kegiatan wajib diisi";
  }
  if (!tools_name) {
    errors.tools_name = "Nama Alat wajib diisi";
  }
  if (!tools_function) {
    errors.tools_function = "Fungsi Alat wajib diisi";
  }
  if (tools_function && tools_function.value === "Lainnya" && !other_function) {
    errors.other_function = "Fungsi Alat wajib diisi";
  }
  if (!patent_date) {
    errors.patent_date = "Tanggal Paten wajib diisi";
  }
  if (!patent_number) {
    errors.patent_number = "Nomor Paten wajib diisi";
  }
  if (!berkas_medis) {
    errors.berkas_medis = "Berkas wajib diisi";
  }
  else{
    if (spek_berkas_medis && (!spek_berkas_medis.type.match(typeImage) && spek_berkas_medis.type !== fileType)){
      errors.berkas_medis = "Format file hanya bisa Image atau PDF"
    }
    else if (spek_berkas_medis && (spek_berkas_medis.size > maxFileSize)){
      errors.berkas_medis = "File lebih dari 1 MB"
    }
  }
  return errors;
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModalAddDetailSKP_MenciptakanAlat"));

ModalAddDetailSKP_MenciptakanAlat = reduxForm({
  form: "ModalAddDetailSKP_MenciptakanAlat",
  validate: validate,
  shouldError: () => true,
  onSubmitSuccess: afterSubmit,
  enableReinitialize: true,
})(ModalAddDetailSKP_MenciptakanAlat);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb } = state;
  const selector = formValueSelector("ModalAddDetailSKP_MenciptakanAlat");
  const formValue = selector(
    state,
    "tingkatan",
    "total_for_calculate_skp",
    "tools_function",
    "berkas_medis",
    "spek_berkas_medis"
  );
  return {
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    formValue,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb })(
  withRouter(ModalAddDetailSKP_MenciptakanAlat)
);
