import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import { approveStrKT, detailStrKT } from "../../actions/p2kb-kt/str_kt_action";
import { getStrKTPending } from "../../reducers/p2kb-kt/str_kt_reducer";

class StrKTModalApprove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exam_date: new Date(),
            graduated_date: new Date(),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const { approveStrKT, detailStrKT, onHide } = this.props;
        const {
            match: {
                params: { id },
            },
        } = this.props;
        const callback = () => {
            onHide();
            detailStrKT(id);
        };
        let formData = new FormData();
        formData.append("id", id);
        formData.append("is_approve", true);
        approveStrKT(formData, callback);
    }

    render() {
        const { show, onHide, approveStrKT_pending } = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Konfirmasi Terima STR KT
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column
                        vertical="center"
                        horizontal="stretch"
                        className={css(styles.bodyModal)}
                    >
                        <p style={{ marginTop: 20 }}>
                            Apakah anda yakin data dan berkas STR KT dokter telah
                            sesuai ?
                        </p>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={this.props.onHide}
                        >
                            Batal
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={approveStrKT_pending}
                            className={css(styles.approveButton)}
                            onClick={this.handleSubmit}
                            startIcon={
                                approveStrKT_pending ? (
                                    <CircularProgress size={14} color="secondary" />
                                ) : (
                                        <CheckIcon />
                                    )
                            }
                        >
                            Ya
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function mapStateToProps({ approveStrKT }) {
    return {
        approveStrKT_pending: getStrKTPending(approveStrKT),
    };
}

export default connect(mapStateToProps, {
    approveStrKT,
    detailStrKT,
})(withRouter(StrKTModalApprove));
