import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MenuItem from "@material-ui/core/MenuItem";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import { connect } from "react-redux";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import MaterialTable from "material-table";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { Paper } from "@material-ui/core";
import { p2kbList } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import HeaderDetailP2KB from "./Header_list_skp_member";
import HeaderListP2kbComponent from "../../components/p2kb/HeaderListP2kbComponent";
import PaginationComponent from "../../components/table/PaginationComponent";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import moment from 'moment'
import { detailP2kb, dateP2kb } from "../../actions/p2kb/p2kb_action";
import { getDetailP2kb, getDateP2kb } from "../../reducers/p2kb/p2kb_reducer";


class DetailMemberP2KB extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      status: "",
      activity_type: "",
      id: null,
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : moment().format('YYYY-MM-DD'),
      last_reject_notes: '',
      last_reject_filename: ''
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleApprovalP2kb = this.handleApprovalP2kb.bind(this);
  }

  handleApprovalP2kb(data) {
    const { match } = this.props;
    const {
      match: {
        params: { serkom_id },
      },
    } = this.props;
    let isSerkom = false;
    let user_id = '';
    if (match && match.params.id) {
      user_id = match.params.id;
      isSerkom = match.path.includes("serkom");
      if (isSerkom) {
        localStorage.setItem('isSerkom', true)
        localStorage.setItem('id_serkom', serkom_id)
      }
      localStorage.setItem('id_member', user_id)
    }
    const id = isSerkom ? user_id : localStorage.getItem("ranah_id");
    const dataAdmin = {
      id: data.id,
      name: data.name
    }
    localStorage.setItem("skp_data_admin", JSON.stringify(dataAdmin))
    this.props.history.push(`/admin-p2kb/${id}`);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  componentDidMount() {
    let param_memberList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id, skpid },
      },
    } = this.props;

    param_memberList.id = id;
    this.props.memberList(param_memberList);
    if (this.props.match.params.skpid && this.state.id === null) {
      this.setState({ id: skpid })
    }
    const callback = () => {
      const data = this.props.listStr_data.data;
      if (data.length > 0) {
        let temp_date = data[0].end_date;
        let idx = 0;
        data.map((item, index) => {
          if (new Date(item.end_date) >= new Date(temp_date)) {
            temp_date = item.end_date
            idx = index
          }
        })
        this.setState({ start_date: data[idx].start_date })
        if (localStorage.getItem('start_date') === null) {
          localStorage.setItem('start_date', data[idx].start_date)
        }
      }
      else {
        const date = moment().subtract(5, 'years').format('YYYY-MM-DD')
        this.setState({ start_date: date })
        localStorage.setItem('start_date', date)
      }
      let strApproved = data.filter(item => item.status === 1);
      if (strApproved.length > 0) {
        this.setState({ user_str: strApproved[0].filename });
      }
    }
    this.props.listStr({ user_id: this.props.match.params.id }, callback);
    const callbackFilter = () => {
      console.log(this.props.dateP2kb_data)
      const dateFilter = moment(this.props.dateP2kb_data.start_date).format('YYYY-MM-DD')
      this.setState({ start_date: dateFilter })
      localStorage.setItem('start_date', dateFilter)
    }
    this.props.dateP2kb({ isFindDate: true, user_id: this.props.match.params.id }, callbackFilter);
  }

  shouldComponentRender() {
    const { memberList_pending } = this.props;
    if (memberList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { memberList_data, p2kbList_data, p2kbList, match } = this.props;
    const {
      match: {
        params: { serkom_id },
      },
    } = this.props;
    let user_id;
    let isAdminSKP = false;
    let isSerkom = false;
    if (match && match.params.id) {
      user_id = match.params.id;
      isAdminSKP = match.path.includes("admin-p2kb");
      isSerkom = match.path.includes("serkom");
      if (isSerkom) {
        localStorage.setItem('isSerkom', true)
        localStorage.setItem('id_serkom', serkom_id)
      }
    }
    let data = [];
    let outstanding_skp = 0;
    let current_skp = 0;
    let total_skp_approved = 0;
    let submit_skp = 0;
    if (memberList_data && memberList_data.length > 0) {
      data = memberList_data.data[0];
      current_skp = data.member_profile?.total_skp;
      outstanding_skp = data.member_profile.outstanding_skp;
    }
    let dataP2kb = [];
    let records_total = "";
    let length = "";
    let status = "";
    let submit_status = "";
    let user = null;
    let last_reject_notes = null;
    let last_reject_filename = null;
    let status_arr = [];
    let min = null;
    let max = null;
    let submit_status_arr = [];
    let min_sub = null;
    let max_sub = null;
    let role_next_approve = null;
    if (p2kbList_data && p2kbList_data.data.length > 0) {
      dataP2kb = p2kbList_data;
      records_total = dataP2kb.records_total;
      length = dataP2kb.data.length;

      status = p2kbList_data.data[0].min_status;
      if (p2kbList_data.total_submitted) {
        // submit_status = p2kbList_data.total_submitted.total_submitted.submit_status ? p2kbList_data.total_submitted.total_submitted.submit_status : "-";
      }

      p2kbList_data.data.forEach((item, index) => {
        if (item.min_submit_status !== null) {
          submit_status_arr.push(item.min_submit_status)
        }

        if (item.min_status !== null) {
          status_arr.push(item.min_status)
        }

        // submit_skp += item.total_skp_grouped
      })
      if (p2kbList_data?.total_terkumpul?.total_terkumpul !== null) {
        submit_skp = p2kbList_data?.total_terkumpul?.total_terkumpul
      }
      if (p2kbList_data.total_skp_approve) {
        if (p2kbList_data.total_skp_approve.length > 0) {
          total_skp_approved = p2kbList_data.total_skp_approve[0].total_skp_approved;
          outstanding_skp = 250 - (total_skp_approved + submit_skp);
          outstanding_skp = outstanding_skp > 0 ? outstanding_skp : 0;
        }
      }

      if (p2kbList_data?.total_submitted?.role_next_approve !== null) {
        user = p2kbList_data?.total_submitted?.role_next_approve.role_name
        role_next_approve = p2kbList_data?.total_submitted?.role_next_approve
      }
      if (status_arr && status_arr.length > 0) {
        min = Math.min(...status_arr)
        max = Math.max(...status_arr)
        if (max === 2) {
          status = 2
        } else {
          status = min
        }
      }
      if (submit_status_arr && submit_status_arr.length > 0) {
        min_sub = Math.min(...submit_status_arr)
        submit_status = min_sub
      }
      if (p2kbList_data?.reject_notes !== null) {
        last_reject_notes = p2kbList_data?.reject_notes?.last_reject_notes
        last_reject_filename = p2kbList_data?.reject_notes?.last_reject_filename
      }
    }
    return (
      <ContentContainer>
        <Row className={css(styles.adminP2kbContainer)}>
          <Column className={css(styles.adminP2kbColumn)}>
            <HeaderListP2kbComponent
              current_skp={current_skp}
              outstanding_skp={outstanding_skp}
              submit_skp={submit_skp.toFixed(2)}
              status={status}
              submit_status={submit_status}
              total_skp_approved={total_skp_approved ? total_skp_approved.toFixed(2) : 0}
              user={user}
            />
            <HeaderDetailP2KB
              handleRefresh={this.handleRefresh}
              isFirstLayer={true}
              user_id={user_id}
              isAdminSKP={isAdminSKP}
              isSerkom={isSerkom}
              status={status}
              submit_status={submit_status}
              last_reject_notes={last_reject_notes}
              last_reject_filename={last_reject_filename}
              role_next_approve={role_next_approve}
            />
            {localStorage.getItem('start_date') !== null &&
              <MaterialTable
                tableRef={this.tableRef}
                title="P2kb"
                columns={[
                  {
                    title: "No",
                    field: "name",
                    render: (rowData) => {
                      return <>{rowData.no}</>;
                    },
                    width: 10,
                    headerStyle: {
                      paddingLeft: 40,
                    },
                    cellStyle: {
                      paddingLeft: 40,
                    },
                  },
                  { title: "Ranah Kegiatan", field: "name" },
                  {
                    title: "Total SKP", field: "total_skp_grouped", render: (data) => {
                      let total_skp_grouped = "0"
                      total_skp_grouped = data.total_skp_grouped ? data.total_skp_grouped : total_skp_grouped
                      return total_skp_grouped
                    }
                  },
                  {
                    title: "",
                    render: (data) => {
                      return (
                        <DetailButtonComponent>
                          <MenuItem onClick={() => this.handleApprovalP2kb(data)}>
                            Detail
                          </MenuItem>
                        </DetailButtonComponent>
                      );
                    },
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let param = {
                      page: query.page + 1,
                      length: 10,
                      search_text: this.state.search,
                      status: this.state.status,
                      activity_type: this.state.activity_type,
                      id: this.state.id,
                      start_date: localStorage.getItem('start_date'),
                      end_date: this.state.end_date,
                      isFirstLayer: true,
                      user_id: this.props.match.params.id,
                      serkom_id,
                    };
                    p2kbList(param, resolve, reject);
                  })
                }
                options={{
                  paginationType: "stepped",
                  pageSize: 10,
                  pageSizeOptions: [],
                  showTitle: false,
                  search: false,
                  headerStyle: {
                    backgroundColor: "#FFFFFF",
                    zIndex: 0,
                  },
                }}
                components={{
                  Toolbar: () => <div />,
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Pagination: (props) => (
                    <PaginationComponent
                      records={length}
                      records_total={records_total}
                      {...props}
                    />
                  ),
                }}
              />
            }
          </Column>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  adminP2kbContainer: {
    width: "100%",
  },
  adminP2kbColumn: {
    width: "100%",
  },
  leftDetailColumn: {
    justifyContent: "center",
    alignItems: "center",
  },
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusPensionID: {
    padding: "10px 20px",
    border: "2px solid #ff8c32",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusDepartedID: {
    padding: "10px 20px",
    border: "2px solid #727272",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px 20px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusActiveButton: {
    padding: "10px 20px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusPensionButton: {
    padding: "10px 20px",
    backgroundColor: "#ff8c32",
    border: "2px solid #ff8c32",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusDepartedButton: {
    padding: "10px 20px",
    backgroundColor: "#727272",
    border: "2px solid #727272",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusPensionIDFont: {
    color: "#ff8c32",
  },
  detailMemberStatusDepartedIDFont: {
    color: "#727272",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  changeStatusButton: {
    padding: 0,
    fontSize: 14,
  },
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ memberList, p2kbList, listStr, detailP2kb, dateP2kb }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    detailP2kb_data: getDetailP2kb(detailP2kb),
    dateP2kb_data: getDateP2kb(dateP2kb),

  };
}

export default connect(mapStateToProps, { memberList, p2kbList, listStr, detailP2kb, dateP2kb })(
  withRouter(DetailMemberP2KB)
);
