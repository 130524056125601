import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from '../../assets/canvas/canvasjs.react';
import { connect } from "react-redux";
import { listGraduated } from '../../actions/dashboard/kolegium_action';
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer';
import SelectComponent from "../../components/form/SelectComponent";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;



class CardKelulusanPaboi extends Component {
    constructor() {
        super();
        this.state = {
            year: new Date().getFullYear()
        };
        this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    }
    componentDidMount() {
        this.props.listGraduated({ year: this.state.year })
    }

    handleYearSelectChange(e) {
        if (e) {
            this.setState({ year: e.value });
            this.props.listGraduated({ year: e.value })
        } else {
            this.setState({ year: new Date().getFullYear() });
            this.props.listGraduated({ year: new Date().getFullYear() })
        }
    }

    render() {

        const { listGraduated_data } = this.props
        let data1 = [], data2 = [], result = null
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }

        if (listGraduated_data) {

            data1 = listGraduated_data.map(({ year, graduated }) => ({ x: new Date(year, 0), y: graduated }))
            data2 = listGraduated_data.map(({ year, not_graduated }) => ({ x: new Date(year, 0), y: not_graduated }))

            const options = {
                animationEnabled: true,
                height: 300,
                axisX: {
                    valueFormatString: "YYYY",

                },
                axisY: {
                    includeZero: true,
                    gridThickness: 0,
                },
                data: [
                    {
                        xValueFormatString: "YYYY",
                        type: "splineArea",
                        lineColor: "#F6C269",
                        color: '#FCEBCD',
                        lineThickness: 8,
                        markerColor: 'white',
                        markerBorderColor: '#F6C269',
                        markerBorderThickness: 4,
                        markerSize: 10,
                        // backgroundColor: "#45A3E7",
                        dataPoints: data1
                    },
                    {
                        xValueFormatString: "YYYY",
                        type: "splineArea",
                        lineColor: "red",
                        fillOpacity: .3,

                        lineThickness: 4,
                        markerColor: 'white',
                        markerBorderColor: 'red',
                        markerBorderThickness: 4,
                        markerSize: 10,
                        color: 'white',
                        dataPoints: data2
                    },]
            }
            result = (
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
            )
        }

        return (
            <div style={{ width: "100%", height: '50%', paddingTop: 10 }}>
                <Column style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'white'

                }}>

                    <div className={css(styles.cardHeader)}></div>


                    {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                    <Row className={css(styles.wrapperTop, styles.borderBottom)} horizontal="space-between" style={{ width: "100%", padding: 20, backgroundColor: 'white' }} vertical='center'>


                        <label className={css(styles.titleLabel)}> Kelulusan Anggota PABOI</label>
                        <Row>
                            <div style={{ width: 150, margin: "0 10px" }}>
                                <SelectComponent
                                    options={yearOptions}
                                    isRounded
                                    onChange={this.handleYearSelectChange}
                                    placeholder="Pilih Tahun"
                                />
                            </div>
                        </Row>

                    </Row>
                    <Column horizontal='center' className={css(styles.wrapper,)} style={{ width: "100%", padding: 20 }}>
                        {result}

                    </Column>

                </Column>
            </div>

        )
    }
}

function mapStateToProps({ listGraduated }) {
    return {
        listGraduated_data: getDashboardData(listGraduated),
    };
}

export default connect(mapStateToProps, {
    listGraduated,
})(CardKelulusanPaboi);

const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: 'bold',
        margin: "5px 0"
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
    wrapper: {
        padding: 20,
        // height: 400,
        backgroundColor: 'white'
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    cardHeader: {
        backgroundColor: "#F6C269",
        height: 10,
        width: "100%"
    }
});

