import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import {
  notificationList,
  readNotification,
} from "../../actions/notification/notification_action";
import {
  getNotificationListPending,
  getNotificationList,
  getNotificationListError,
  getNotificationRead,
} from "../../reducers/notification/notification_reducer";
import { Paper } from "@material-ui/core";
import NotificationHeader from "./NotificationHeader";
import PaginationComponent from "../../components/table/PaginationComponent";
import moment from "moment";
import localization from "moment/locale/id";
import IconDone from "../../assets/icon-done";
import IconClear from "../../assets/icon-clear";
import IconPriorityHigh from "../../assets/icon-priority-high";
import IconArrowForward from "../../assets/icon-arrow-forward";
import { StyleSheet, css } from "aphrodite";
import { getUser } from "../../helpers/user";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.notificationRedirect = this.notificationRedirect.bind(this);
  }

  notificationRedirect(
    id,
    type_notif,
    payload_id,
    payload_skp_user_id_submitting = null,
    created_at
  ) {
    this.props.readNotification({ id: id });
    switch (type_notif.toUpperCase()) {
      case "PPDS":
        this.props.history.push("/registrasi/" + payload_id);
        break;
      case "MEMBER_TRAINING":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/training/" + payload_id);
        } else {
          this.props.history.push("/admin/member-training/" + payload_id);
        }
        break;
      case "MEMBER_EXPERIENCE":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/experience/" + payload_id);
        } else {
          this.props.history.push("/admin/member-experience/" + payload_id);
        }
        break;
      case "MEMBER_EDUCATION":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/education/" + payload_id);
        } else {
          this.props.history.push("/admin/member-education/" + payload_id);
        }
        break;
      case "MEMBER_PRACTICE_PLACE":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/practice/" + payload_id);
        } else {
          this.props.history.push("/practice/" + payload_id);
        }
        break;
      case "SKP":
        localStorage.setItem(
          "start_date",
          moment(created_at).format("YYYY-MM-DD")
        );
        localStorage.setItem(
          "end_date",
          moment(created_at).format("YYYY-MM-DD")
        );
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/skp/" + payload_id);
        } else {
          this.props.history.push(
            "/admin-p2kb/" + payload_skp_user_id_submitting + "/" + payload_id
          );
        }
        break;
      case "STR":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/str/" + payload_id);
        } else {
          this.props.history.push("/admin/str/" + payload_id);
        }
        break;
      case "MEMBER_REDEEM_ADMIN":
        this.props.history.push("/benefit/");
        break;
      case "SIP":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/sip/" + payload_id);
        } else {
          this.props.history.push("/admin/sip/" + payload_id);
        }
        break;
      case "STR_KT":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/strkt/" + payload_id);
        } else {
          this.props.history.push("/admin/strkt/" + payload_id);
        }
        break;
      case "SERKOM":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/serkom/" + payload_id);
        } else {
          this.props.history.push("/admin/serkom/" + payload_id);
        }
        break;
      case "TRANS_RS":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/transrs/" + payload_id);
        } else {
          this.props.history.push("/transrs/" + payload_id);
        }
        break;
      case "MEMBER_BRANCH_MUTATION":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/member/mutation/" + payload_id);
        } else {
          this.props.history.push("/admin/mutation/" + payload_id);
        }
        break;
      case "MEMBER_BENEFIT":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/benefit/" + payload_id);
        } else {
          this.props.history.push("/benefit/" + payload_id);
        }
        break;
      case "EVENT":
        this.props.history.push("/event/" + payload_id);
        break;
      case "HOSPITAL":
        this.props.history.push("/hospital/" + payload_id);
        break;
      case "UNIVERSITY":
        this.props.history.push("/universitas/" + payload_id);
        break;
      case "SUPPORT":
        if (getUser().role_codes[0] === "member") {
          this.props.history.push("/support/" + payload_id);
        } else {
          this.props.history.push("/admin/support/" + payload_id);
        }
        break;
      default:
        this.props.history.push("");
    }
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  render() {
    const { notificationList, notificationList_data } = this.props;
    const { search } = this.state;
    let data = [];
    let records_total = "";
    let length = "";
    if (notificationList_data) {
      data = notificationList_data.data;
      records_total = notificationList_data.records_total;
      length = data.length;
    }
    return (
      <ContentContainer>
        <NotificationHeader handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Notifikasi",
              render: ({ title, body, created_at, species_notif }) => (
                <Row>
                  <div
                    style={{
                      backgroundColor:
                        species_notif === "info"
                          ? "#049FAA"
                          : species_notif === "approve"
                          ? "#2CB96A"
                          : "#ED2A2A",
                      borderRadius: "50%",
                      width: 35,
                      height: 35,
                      padding: 5,
                      margin: "auto 10px auto 0px",
                    }}
                  >
                    {species_notif === "info" ? (
                      <IconPriorityHigh />
                    ) : species_notif === "approve" ? (
                      <IconDone />
                    ) : species_notif === "reject" ? (
                      <IconClear />
                    ) : null}
                  </div>
                  <Column>
                    <span style={{ fontWeight: "bold" }}>
                      {title} :{" "}
                      <span style={{ fontWeight: "normal" }}>{body}</span>{" "}
                    </span>
                    <span style={{ fontSize: 12, color: "#777777" }}>
                      {moment(created_at)
                        .locale("id", localization)
                        .format("DD MMMM YYYY, HH:mm")}
                    </span>
                  </Column>
                </Row>
              ),
            },
            {
              title: "",
              render: ({ id, type_notif, payload, created_at }) => {
                const payload_id = JSON.parse(payload).id;
                let payload_skp_user_id_submitting;
                if (
                  type_notif === "SKP" &&
                  getUser().role_codes[0] !== "member"
                ) {
                  payload_skp_user_id_submitting =
                    JSON.parse(payload).user_id_submitting;
                  return (
                    <div
                      className={css(styles.arrowButton)}
                      onClick={() =>
                        this.notificationRedirect(
                          id,
                          type_notif,
                          payload_id,
                          payload_skp_user_id_submitting,
                          created_at
                        )
                      }
                    >
                      <IconArrowForward fill="#000000" />
                    </div>
                  );
                } else if (
                  type_notif === "SERKOM_ADMIN" &&
                  getUser().role_codes[0] !== "member"
                ) {
                  return <div></div>;
                } else {
                  return (
                    <div
                      className={css(styles.arrowButton)}
                      onClick={() =>
                        this.notificationRedirect(id, type_notif, payload_id)
                      }
                    >
                      <IconArrowForward fill="#000000" />
                    </div>
                  );
                }
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                id: "",
              };

              notificationList(param, resolve, reject);
            })
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
            rowStyle: (rowData) => ({
              // color: (rowData.is_read === 1) ? '#2CB96A' : '#ED2A2A',
              backgroundColor:
                rowData.is_read === 0 ? "#E9F8F0" : "transparent",
            }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  arrowButton: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ notificationList, readNotification }) {
  return {
    notificationList_pending: getNotificationListPending(notificationList),
    notificationList_data: getNotificationList(notificationList),
    notificationList_error: getNotificationListError(notificationList),
    notificationRead_data: getNotificationRead(readNotification),
  };
}

export default connect(mapStateToProps, { notificationList, readNotification })(
  withRouter(Notification)
);
