import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { userList, deleteUser } from "../../actions/master-data/user_action";
import { getUserListPending, getUserList, getUserListError, getUserDelete } from "../../reducers/master-data/user_reducer";

class ModalHapusPengguna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_name: ""
        };
    }

    deleteUser() {
        const { deleteUser, onHide } = this.props;
        const param_deleteUser = {
            id: this.props.data.data[0].id,
            code: this.state.user_code,
            name: this.state.user_name,
            abbreviation: this.state.user_abbreviation,
            city_id: this.state.user_city_value,
        }
        const param_listUser = {
            page: 1,
            length: 10,
            search_text: ""
        };

        const callback = () => {
            onHide();
            this.props.userList(param_listUser);
        }
        deleteUser(param_deleteUser, callback);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show) {
            this.setState({ user_name: this.props.data.data[0].name })
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                cityOption={this.props.listCityOption}
                id={this.props.id}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Hapus User
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menghapus {this.state.user_name} ?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={this.props.onHide}
                        >
                            Tidak
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.deleteUser()}
                        >
                            Ya
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ deleteUser, userList }) {
    return {
        deleteUser: getUserDelete(deleteUser),
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
    };
}


export default connect(mapStateToProps, { deleteUser, userList })(ModalHapusPengguna);