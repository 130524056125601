import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray, formValueSelector } from "redux-form";
import { addEducation } from "../../actions/member/education_action";
import { universityList } from "../../actions/master-data/university_action";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import { getEducationPending } from "../../reducers/member/education_reducer";
import {
  getUniversityListPending,
  getUniversityList,
} from "../../reducers/master-data/university_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import {
  getCityListPending,
  getCityList
} from "../../reducers/master-data/city_reducer";

class ModalEducationAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strata: null,
      degreeOptions: {
        S1: [
          { label: "dr.", value: "dr." },
          { label: "Lainnya...", value: "other"  },
        ],
        S2: [
          { label: "Sp.(OT)", value: "Sp.(OT)" },
          { label: "Lainnya...", value: "other" },
        ],
        S3: [
          { label: "Dr.", value: "Dr." },
          { label: "Lainnya...", value: "other" },
        ],
        Fellowship: [
          { label: "K.", value: "K." },
          { label: "Lainnya...", value: "other" },
        ],
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdaptaionChange = this.handleAdaptaionChange.bind(this);
    this.handleUniversityChange = this.handleUniversityChange.bind(this);
    this.handleDegreeChange = this.handleDegreeChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleAdaptaionChange(value) {
    const { universityList, change } = this.props;
    const param = {
      length: 999,
      is_adoption: value ? value.value : undefined,
    };
    universityList(param);
    change("university_to", null);
    change("other_university_to", null);
    change("city", null);
  }

  handleUniversityChange(value) {
    const { change } = this.props;
    if (value && value.city) {
      change("city", value.city);
    } else {
      change("city", null);
    }
  }

  handleDegreeChange(value) {
    const { change } = this.props;
    const { degreeOptions } = this.state;
    if (value) {
      this.setState({ strata: value.value });
      change("degree", degreeOptions[value.value][0]);
    } else {
      change("degree_other", null);
      change("degree", null);
      this.setState({ strata: null });
    }
  }

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, addEducation } = this.props;
    const {
      is_adaptation,
      university_from,
      country,
      university_to,
      other_university_to,
      city,
      education_stage,
      graduated,
      berkas,
      degree,
      degree_other,
    } = values;

    let param = {
      city: city.label ? city.label : city,
      degree: degree.value === "other" ? degree_other : degree.value,
      education_stage: education_stage.value,
      graduated: graduated.value,
      is_adaptation: is_adaptation.value,
      file: berkas,
      university_to:
        university_to.value === "other"
          ? other_university_to
          : university_to.label,
    };

    if (is_adaptation.value === 1) {
      param = {
        ...param,
        university_from: university_from,
        country,
      };
    }
    param = {
      ...param,
      attachments: values?.file ? values.file.map((item) => ({
        attachment_name: item.name,
        attachment_filename: item.value,
      })) : []
    }
    const callback = () => {
      onHide();
      handleRefresh();
    };
    addEducation(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addEducation_pending,
      listUniversity_data,
      listCity_data,
      formValue: { is_adaptation, university_to, degree, degree_other },
    } = this.props;
    const { strata, degreeOptions } = this.state;

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    let universityOptions = [];
    let cityOptions = [];
    let educationStageOptions = [
      { label: "S1", value: "S1" },
      { label: "S2", value: "S2" },
      { label: "S3", value: "S3" },
      { label: "Fellowship", value: "Fellowship" },
    ];
    let adaptaionOptions = [
      { label: "Ya", value: 1 },
      { label: "Tidak", value: 0 },
    ];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: `${i}` }];
    }
    if (listUniversity_data) {
      universityOptions = listUniversity_data.data.map((item) => ({
        label: item.name,
        value: item.name,
        city: item.city.name,
      }));
      universityOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
    }
    if (listCity_data) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.name
      }));
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Pendidikan</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="is_adaptation"
                  placeholder="Pilih jenis adaptasi"
                  options={adaptaionOptions}
                  onChange={this.handleAdaptaionChange}
                  component={renderSelect}
                />
                {is_adaptation && is_adaptation.value === 1 && (
                  <>
                    <Field
                      name="university_from"
                      placeholder="Masukkan Universitas Asal"
                      component={renderInput}
                    />
                    <Field
                      name="country"
                      placeholder="Masukkan negara"
                      component={renderInput}
                    />
                  </>
                )}
                <Field
                  name="university_to"
                  placeholder={`Pilih universitas ${is_adaptation && is_adaptation.value ? "adaptasi" : "asal"
                    }`}
                  options={universityOptions}
                  onChange={this.handleUniversityChange}
                  component={renderSelect}
                />
                {university_to && university_to.value === "other" && (
                  <Field
                    name="other_university_to"
                    placeholder={`Masukkan universitas ${is_adaptation && is_adaptation.value
                      ? "adaptasi"
                      : "asal"
                      }`}
                    component={renderInput}
                  />
                )}

                {university_to && university_to.value === "other" ?
                  <Field
                    name="city"
                    placeholder="Masukkan kota"
                    component={renderSelect}
                    options={cityOptions}
                    disabled={!(university_to && university_to.value === "other")}
                  />
                  :
                  <Field
                    name="city"
                    placeholder="Masukkan kota"
                    component={renderInput}
                    disabled={!(university_to && university_to.value === "other")}
                  />
                }
                <Field
                  name="education_stage"
                  placeholder="Pilih jenjang pendidikan"
                  component={renderSelect}
                  onChange={this.handleDegreeChange}
                  options={educationStageOptions}
                />
                <Field
                  name="degree"
                  placeholder="Pilih gelar"
                  component={renderSelect}
                  options={strata ? degreeOptions[strata] : []}
                />
                {degree && degree.value === "other" && (
                  <Field 
                  name="degree_other" 
                  placeholder="Masukan Gelar" 
                  component={renderInput} />
                )}
                <Field
                  name="graduated"
                  placeholder="Pilih tahun lulus"
                  component={renderSelect}
                  options={yearOptions}
                />
                <FieldArray
                  name="file"
                  component={DynamicFormComponent}
                  changeFileValue={this.changeFileValue}
                  folderPath="member_education"
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addEducation_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addEducation_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const {
    is_adaptation,
    university_from,
    country,
    university_to,
    other_university_to,
    city,
    education_stage,
    graduated,
    degree,
    degree_other,
    berkas,
    file,
  } = values;
  const errors = {};
  const fileArrayErrors = [];
  if (!is_adaptation) {
    errors.is_adaptation = "Jenis adaptasi wajib diisi";
  } else {
    if (is_adaptation.value === 1) {
      if (!university_from) {
        errors.university_from = "Universitas asal wajib diisi  ";
      }
      if (!country) {
        errors.country = "Negara universitas asal wajib diisi  ";
      }
    }
  }
  if (!university_to) {
    errors.university_to = `universitas ${is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "asal"
      } wajib diisi`;
  } else {
    if (university_to.value === "other") {
      if (!other_university_to) {
        errors.other_university_to = `universitas ${is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "asal"
          } wajib diisi`;
      }
    }
  }
  if (!city) {
    errors.city = "Kota wajib diisi";
  }
  if (!education_stage) {
    errors.education_stage = "Jenjang pendidikan wajib diisi";
  }
  if (!graduated) {
    errors.graduated = "Tahun lulus wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas pendidikan wajib diisi";
  }
  if (!degree) {
    errors.degree = "Gelar pendidikan wajib diisi";
  }
  if (!degree_other) {
    errors.degree_other = "Gelar pendidikan wajib diisi";
  }
  if (file && file.length) {
      file.forEach((item, index) => {
          if (!item) {
              fileArrayErrors[index] = "Berkas tidak boleh kosong"
              
          }
      })
  }
  if (fileArrayErrors.length) {
    errors.file = fileArrayErrors;
  }

  if (file && file.length){
    file.forEach((item, index) => {
      if (!item) {
        fileArrayErrors[index] = "Berkas wajib diisi!";
      }
    });
  }

  if (fileArrayErrors.length) {
    errors.file = fileArrayErrors;
  }
  
  return errors;
}

ModalEducationAdd = reduxForm({
  // a unique name for the form
  form: "EducationModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalEducationAdd);

function mapStateToProps(state) {
  const { addEducation, universityList, listCity } = state;
  const selector = formValueSelector("EducationModalAdd");
  const formValue = selector(state, "university_to", "is_adaptation", "degree", "degree_other" );

  return {
    addEducation_pending: getEducationPending(addEducation),
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    listCity_data: getCityList(listCity),
    listCity_pending: getCityListPending(listCity),
    formValue,
  };
}

export default connect(mapStateToProps, {
  addEducation,
  universityList,
})(withRouter(ModalEducationAdd));
