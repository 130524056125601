import {
  LIST_SUBSPECIALIST_PENDING,
  LIST_SUBSPECIALIST_SUCCESS,
  LIST_SUBSPECIALIST_ERROR,
  LIST_CANDIDATE_ERROR,
  LIST_CANDIDATE_PENDING,
  LIST_CANDIDATE_SUCCESS,
  LIST_GRADUATED_ERROR,
  LIST_GRADUATED_PENDING,
  LIST_GRADUATED_SUCCESS,
  LIST_ORTHOPAEDI_ERROR,
  LIST_ORTHOPAEDI_PENDING,
  LIST_ORTHOPAEDI_SUCCESS,
} from "../../actions/dashboard/kolegium_action";




const initialState = {
  pending: false,
  data: null,
  error: null,
};

export function listSubspecialist(state = initialState, action) {
  switch (action.type) {
    case LIST_SUBSPECIALIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SUBSPECIALIST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_SUBSPECIALIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listCandidate(state = initialState, action) {
  switch (action.type) {
    case LIST_CANDIDATE_PENDING:
      return {
        ...state,
        pending: true,
        data: null

      };
    case LIST_CANDIDATE_SUCCESS: 
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_CANDIDATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listOrthopaedi(state = initialState, action) {
  switch (action.type) {
    case LIST_ORTHOPAEDI_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_ORTHOPAEDI_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_ORTHOPAEDI_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listGraduated(state = initialState, action) {
  switch (action.type) {
    case LIST_GRADUATED_PENDING:
      return {
        ...state,
        pending: true,
        data: null
      };
    case LIST_GRADUATED_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case LIST_GRADUATED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}



export const getDashboardData = (state) => state.data;
export const getDashboardPending = (state) => state.pending;
export const getDashboardError = (state) => state.error;
