import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { addSerkomAdmin } from "../../actions/serkom/serkomAdmin_action";
import { getSerkomAdminPending } from "../../reducers/serkom/serkomAdmin_reducer";

import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import SerkomModalAddHeader from "./SerkomModalAddHeader";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import localization from "moment/locale/id";

class ModalSerkomAdd extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();

    this.state = {
      search_text: "",
      selected_member: [],
      isSelection: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
      this.props.memberList({ for_serkom: 1 });
      this.setState({ isSelection: true });
    }
  }

  // componentDidMount() {}

  handleRefresh(value) {
    // const { search_text } = this.state;

    this.props.memberList({ for_serkom: 1, search_text: value });
  }

  handleSubmit() {
    const { addSerkomAdmin, onHide, handleRefresh } = this.props;
    const { selected_member } = this.state;

    const serkom_members = selected_member.map(({ id }) => id);
    const callback = () => {
      onHide();
      handleRefresh();
    };
    addSerkomAdmin({ serkom_members }, callback);
  }

  render() {
    const {
      memberList_data,
      show,
      onHide,
      addSerkom_pending,
    } = this.props;
    const { selected_member, isSelection } = this.state;
    let data = [];
    let records_total = 0;
    if (memberList_data) {
      let result = memberList_data.data.map((item, i) => {
        // if (selected_member.indexOf(item.id) !== -1) {
        //   return { ...item, tableData: {  checked: true } };
        // }
        return {
          no: i + 1,
          ...item,
          tableData: {
            checked: selected_member.find(({ id }) => id === item.id) != null,
          },
        };
      });

      data = isSelection ? result : selected_member;
      records_total = memberList_data.records_total;
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Serkom</p>
        </Modal.Header>
        <Modal.Body>
          <Column
            vertical="center"
            horizontal="stretch"
            className={css(styles.bodyModal)}
          >
            {isSelection ? (
              <SerkomModalAddHeader
                selectedMembers={selected_member.length}
                handleRefresh={this.handleRefresh}
              />
            ) : (
              <div
                style={{ color: "#7F8388", fontSize: 16, padding: "20px 0" }}
              >
                Berikut adalah nama member yang anda pilih. Klik tombol ajukan
                dibawah untuk memproses pengajuan serkom.
              </div>
            )}
            <MaterialTable
              tableRef={this.tableRef}
              columns={[
                {
                  title: "No",
                  field: "no",
                  width: 20,
                  headerStyle: {
                    paddingLeft: 40,
                  },
                  cellStyle: {
                    paddingLeft: 40,
                  },
                },

                { title: "Nama", field: "name" },
                {
                  title: "Tanggal Lulus",
                  render: ({ ppds_graduated_date }) =>
                    moment(ppds_graduated_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY"),
                },
                { title: "No ID PABOI", field: "member_profile.paboi_id" },
                {
                  title: "Total SKP",
                  field: "member_profile.total_skp",
                },
              ]}
              data={data}
              options={{
                selection: this.state.isSelection,
                pageSize: 5,
                paginationType: "stepped",
                pageSizeOptions: [],
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Tidak ada data",
                },
              }}
              components={{
                Toolbar: () => <div />,
                Container: (props) => <Paper {...props} elevation={0} />,
                Pagination: (props) => (
                  <PaginationComponent
                    records={data.length}
                    records_total={records_total}
                    {...props}
                  />
                ),
              }}
              onSelectionChange={(members) => {
                // const member = members.map(({ id }) => id);
                this.setState({ selected_member: members });
              }}
            />
          </Column>

          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            {isSelection ? (
              <Button
                variant="contained"
                color="primary"
                // disabled={approveTraining_pending}
                className={css(styles.approveButton)}
                onClick={() => this.setState({ isSelection: false })}
              // startIcon={
              //   approveTraining_pending ? (
              //     <CircularProgress size={14} color="secondary" />
              //   ) : (
              //     <CheckIcon />
              //   )
              // }
              >
                Lanjut
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  startIcon={<ClearIcon />}
                  onClick={() => this.setState({ isSelection: true })}
                >
                  Kembali
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={this.handleSubmit}
                  startIcon={
                    addSerkom_pending ? (
                      <CircularProgress size={14} color="secondary" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                >
                  Ajukan
                </Button>
              </>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { sk_no, start_date, end_date, berkas } = values;
  const errors = {};
  if (!sk_no) {
    errors.sk_no = "Nomor surat serkom wajib diisi";
  }
  if (!start_date) {
    errors.start_date = "Tanggal penetapan wajib diisi";
  }
  if (!end_date) {
    errors.end_date = "Tanggal berakhir wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  else {
    if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
    }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 500KB"
    }
  }
  return errors;
}

ModalSerkomAdd = reduxForm({
  // a unique name for the form
  form: "SerkomModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalSerkomAdd);

function mapStateToProps({ addSerkomAdmin, memberList }) {
  return {
    addSerkom_pending: getSerkomAdminPending(addSerkomAdmin),
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
  };
}

export default connect(mapStateToProps, {
  addSerkomAdmin,
  memberList,
})(withRouter(ModalSerkomAdd));
