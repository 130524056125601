import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

class HeaderPelatihan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notif: "",
    };
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    return handleRefresh(value);
  }, 500);

  handleChangeSearch(e) {
    if (e !== null) {
      this.delayedQuery({ search: e.target.value });
    } else {
      this.delayedQuery({ search: "" });
    }
  }

  render() {
    return (
      <Row
        style={{
          padding: 20,
          paddingBottom: 10,
          backgroundColor: "#F8F9FB",
          alignItems: "center",
        }}
        horizontal="flex-end"
      >
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleChangeSearch}
            // value={this.state.search}
            placeholder="Cari Notifikasi"
            startIcon={SearchIcon}
            id="notif"
          />
        </div>
      </Row>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  container: {
    width: 150,
    margin: 10,
    zIndex: 999,
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(HeaderPelatihan);
