import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import RenderArray from "./PracticeForm";
import { reduxForm, FieldArray, Field, formValueSelector } from "redux-form";
import { addPractice } from "../../actions/sub-menu/practice_action";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getPracticePending,
} from "../../reducers/sub-menu/practice_reducer";
import { listHospital } from "../../actions/master-data/hospital_action";
import {
  getListHospital,
  getHospitalPending,
  getHospitalError,
} from "../../reducers/master-data/hospital_reducer";

class ModalPracticeAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addPractice, onHide, handleRefresh } = this.props;
    const param = {
      hospital_name:
        values.Hospital.value === "other"
          ? values.other_hospital
          : values.Hospital.label,
      phone: values.nomor_telepon,
      email: values.email,
      file: values.berkas,
      member_practice_place_days: values.hospital.map((item) => ({
        day_code: item.day.value,
        start_time: item.time_start,
        end_time: item.time_end,
      })),
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addPractice(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addPractice_pending,
      detailPractice_pending,
      listHospital_data,
      listHospital_pending,
      formValue
    } = this.props;

    let hospital_options = [];
    if (listHospital_data) {
      hospital_options = listHospital_data.data.map((item) => ({
        value: item.id,
        label: item.name,
        phone: item.phone,
        email: item.email,
      }));
      hospital_options.splice(0, 0, { label: "Lainnya...", value: "other" })
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Jadwal Praktik</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailPractice_pending || listHospital_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                  <>
                    <Field
                      name="Hospital"
                      type="text"
                      placeholder="Pilih RS"
                      options={hospital_options}
                      component={renderSelect}
                    />
                    {formValue && formValue.value === "other" &&
                      <Field
                        name="other_hospital"
                        type="text"
                        placeholder="Masukkan Rumah Sakit"
                        component={renderInput}
                      />
                    }
                    <Field
                      name="nomor_telepon"
                      type="number"
                      placeholder="Masukkan Nomor Telepon"
                      component={renderInput}
                    />

                    <Field
                      name="email"
                      type="email"
                      placeholder="Masukkan Email"
                      component={renderInput}
                    />

                    <FieldArray
                      name="hospital"
                      placeholder="Masukkan Jadwal Baru"
                      component={RenderArray}
                    />

                    <Field
                      name="berkas"
                      type="file"
                      fileType="image" //image or pdf
                      title="upload berkas"
                      message="PNG atau JPG maksimal 500 kb"
                      component={renderFile}
                    />
                  </>
                )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addPractice_pending || listHospital_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addPractice_pending || listHospital_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                      <CheckIcon />
                    )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { hospital, Hospital, berkas, email, nomor_telepon, other_hospital } = values;
  const errors = {};
  const hospitalArrayErrors = [];
  if (!hospital || !hospital.length) {
    errors.hospital = { _error: "Jadwal tidak boleh kosong" };
  } else {
    hospital.forEach((item, index) => {
      const hospitalErrors = {};
      if (!item || !item.day) {
        hospitalErrors.day = "Tentukan hari praktik";
        hospitalArrayErrors[index] = hospitalErrors;
      }
      if (!item || !item.time_start) {
        hospitalErrors.time_start = "Tentukan waktu mulai";
        hospitalArrayErrors[index] = hospitalErrors;
      }
      if (!item || !item.time_end) {
        hospitalErrors.time_end = "Tentukan waktu selesai";
        hospitalArrayErrors[index] = hospitalErrors;
      }
    });
  }
  if (hospitalArrayErrors.length) {
    errors.hospital = hospitalArrayErrors;
  }
  if (!Hospital) {
    errors.Hospital = "Rumah Sakit tidak boleh kosong";
  } else {
    if (Hospital.value === "other") {
      if (!other_hospital) {
        errors.other_hospital = "Rumah Sakit tidak boleh kosong"
      }
    }
  }
  if (!berkas) {
    errors.berkas = "Berkas Wajib Disertakan";
  }
  if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
  }
  else if (values.berkas && (values.berkas.size > maxFileSize)){
    errors.berkas = "File lebih dari 500KB"
  }
  if (!email) {
    errors.email = "Email Wajib Disertakan";
  }
  if (!nomor_telepon) {
    errors.nomor_telepon = "Nomor Telepon Wajib Disertakan";
  }
  return errors;
}

ModalPracticeAdd = reduxForm({
  // a unique name for the form
  form: "ModalAddPractice",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalPracticeAdd);

function mapStateToProps(state) {
  const { addPractice, listHospital } = state;
  const selector = formValueSelector("ModalAddPractice");
  const formValue = selector(state, "Hospital");
  return {
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listHospital_error: getHospitalError(listHospital),
    addPractice_pending: getPracticePending(addPractice),
    formValue
  };
}

export default connect(mapStateToProps, {
  addPractice,
  listHospital,
})(withRouter(ModalPracticeAdd));
