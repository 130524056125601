import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
    getP2kbEditDetailPending,
    getP2kbEditDetail,
    getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import moment from 'moment';
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_PenelitianDipublikasikan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
            modalDetailDokumenSKPShow: false,
            berkas: "",
            berkas_button: false,
            isDoneUploading: true,
            imgUrl: "",
            modal_title: "",
            selected_activity: null,
            selected_scale: null,
            selected_peran: null,
            selected_duration: null,
            spek_berkas: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleChangeTingkat = this.handleChangeTingkat.bind(this);
        this.handleChangeSAL = this.handleChangeSAL.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleChangeTingkat(values) {
        const { listActivity_data, change } = this.props;
        let selected_activity = listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        let selected_tingkat = selected_activity.childrens.find(item => item.id === values.value);
        let value_skp = selected_tingkat.values[0].value_skp;
        change('values_id', selected_tingkat.values[0].id)
        change('skp_total', value_skp);
    }

    handleChangeSAL(values) {
        const { listActivity_data, change } = this.props;
        let {
            scale,
            last_activity_skp_detail_id
        } = this.props.formValue;
        let selected_activity = listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        let selected_scale = selected_activity.childrens.find(item => item.id === scale.value);
        let selected_position = selected_scale.childrens.find(item => item.id === last_activity_skp_detail_id.value);
        let selected_sal = selected_position.values.find(item => item.id === values.value);
        change('skp_total', selected_sal.value_skp);
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sip") {
        //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
        //     this.props.change("berkas_sip", "dvorak");
        // } else if (formName === "berkas") {
        //     this.setState({ berkas: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_button: true })
        if (formName === "berkas") {
            this.setState({ berkas: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas") {
                    this.props.change("berkas", this.props.uploadP2kb_data);
                    this.setState({ berkas: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas, "Keterangan Dokumen")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas") {
                    this.props.change("berkas", null);
                    this.setState({ berkas: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        this.props.change("spek_berkas", values.target.files[0]);
        // } else {
        //     if (formName === "berkas") {
        //         this.setState({ berkas: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        const { handleEditDetail, onHide, submit_status, skp_status, handleRefresh, editDetailP2kb, formValue } = this.props;
        if (skp_status === undefined || skp_status === 0) {
            let details_tobesent = {
                activity_date: moment(values.activity_date).format('DD MMMM YYYY'),
                publish_date: moment(values.publish_date).format('DD MMMM YYYY'),
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                magazine_name: values.magazine_name,
                values_id: formValue.values_id,
                volume: values.volume,
                hasEdit: true,
                title_of_research: values.title_of_research,
                issn_number: values.issn_number,
                status: values.status.value,
                attachments: [
                    {
                        label: "Dokumen Majalah",
                        filename: values.berkas
                    }
                ]
            }
            let details_tobeshown = {
                ...details_tobesent,
                no: formValue.no,
                total_skp: values.skp_total,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id
            }
            handleEditDetail(details_tobeshown, details_tobesent);
            onHide();
        } else {
            const param = {
                id: values.id,
                activity_date: moment(values.activity_date).format('DD MMMM YYYY'),
                publish_date: moment(values.publish_date).format('DD MMMM YYYY'),
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                magazine_name: values.magazine_name,
                values_id: formValue.values_id,
                volume: values.volume,
                title_of_research: values.title_of_research,
                issn_number: values.issn_number,
                status: values.status.value,
                attachments: [
                    {
                        label: "Dokumen Majalah",
                        filename: values.berkas
                    }
                ]
            }
            const callback = () => {
                onHide();
                handleRefresh();
            }
            editDetailP2kb(param, callback);
        }
    }

    updateState() {
        if (this.state.berkas === "") {
            if (this.props.formValue.berkas) {
                this.setState({ berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas, "Keterangan Dokumen")} /> })
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateState();
    }

    componentDidMount() {
        this.props.listActivity({ length: 999 });
    }

    render() {
        const { show,
            onHide,
            status_skp,
            handleSubmit,
            uploadP2kb_pending,
            listActivity_data,
        } = this.props;
        let tingkatOptions, statusOptions = [];
        if (listActivity_data) {
            let selected_activity = listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
            tingkatOptions = selected_activity.childrens.map((item) => ({
                label: item.name,
                value: item.id
            }));
        }
        statusOptions = [
            {
                label: "Penulis Utama",
                value: "Penulis Utama"
            },
            {
                label: "Penulis Pembantu",
                value: "Penulis Pembantu"
            }
        ]
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="last_activity_skp_detail_id"
                                    placeholder="Pilih Tingkat"
                                    label="Tingkat"
                                    options={tingkatOptions}
                                    onChange={this.handleChangeTingkat}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="title_of_research"
                                    placeholder="Masukkan Judul Penelitian"
                                    label="Judul Penelitian"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="magazine_name"
                                    placeholder="Masukkan Nama Majalah"
                                    label="Nama Majalah"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="volume"
                                    placeholder="Masukkan Volume Majalah"
                                    label="Volume Majalah"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="publish_date"
                                    placeholder="Masukkan Tanggal Terbit"
                                    label="Tanggal Terbit"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="issn_number"
                                    placeholder="Masukkan No ISSN"
                                    label="No ISSN"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="status"
                                    placeholder="Pilih Status"
                                    label="Status"
                                    options={statusOptions}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="skp_total"
                                    placeholder="Jumlah SKP"
                                    label="Jumlah SKP"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="berkas"
                                    type="file"
                                    fileType="skp"
                                    title="Dokumen Majalah"
                                    disabled={this.state.berkas_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas === undefined ? "Image atau PDF maksimal 1 MB" : this.state.berkas === null ? "Image atau PDF maksimal 1 MB" : this.state.berkas}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { activity_date, publish_date, title_of_research, last_activity_skp_detail_id, magazine_name, volume, issn_number, status, berkas, spek_berkas } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!publish_date) {
        errors.publish_date = "Tanggal terbit wajib diisi"
    }
    if (!title_of_research) {
        errors.title_of_research = "Judul makalah wajib diisi"
    }
    if (!last_activity_skp_detail_id) {
        errors.last_activity_skp_detail_id = "Tingkat wajib diisi"
    }
    if (!magazine_name) {
        errors.magazine_name = "Nama majalah Level wajib diisi"
    }
    if (!volume) {
        errors.volume = "Periode wajib diisi"
    }
    if (!issn_number) {
        errors.issn_number = "No ISSN wajib diisi"
    }
    if (!status) {
        errors.status = "Status wajib diisi"
    }
    if (!berkas) {
        errors.berkas = "Berkas sertifikat wajib diisi"
    }
    else{
        if (spek_berkas && (!spek_berkas.type.match(typeImage) && spek_berkas.type !== fileType)){
            errors.berkas= "Format file hanya bisa Image atau PDF"
        }
        else if (spek_berkas && (spek_berkas.size > maxFileSize)){
            errors.berkas = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_PenelitianDipublikasikan'));

ModalEditDetailSKP_PenelitianDipublikasikan = reduxForm({
    form: "ModalEditDetailSKP_PenelitianDipublikasikan",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalEditDetailSKP_PenelitianDipublikasikan)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb, selectedSKP, editDetailP2kb } = state;
    const selector = formValueSelector("ModalEditDetailSKP_PenelitianDipublikasikan");
    const formValue = selector(state, "activity_id", "skp_total", "scale", "last_activity_skp_detail_id", "values_id", "berkas", "no", "spek_berkas")
    let initialValues = {};
    let form_json = null;
    if (selectedSKP) {
        if (selectedSKP.isEditPenelitianDipublikasikan) {
            if (!selectedSKP.isDetail) {
                initialValues = {
                    activity_date: selectedSKP.activity_date,
                    last_activity_skp_detail_id: {
                        label: selectedSKP.last_activity_skp_detail_id.label,
                        value: selectedSKP.last_activity_skp_detail_id.value
                    },
                    magazine_name: selectedSKP.magazine_name,
                    title_of_research: selectedSKP.title_of_research,
                    volume: selectedSKP.volume,
                    publish_date: selectedSKP.publish_date,
                    issn_number: selectedSKP.issn_number,
                    status: {
                        label: selectedSKP.status,
                        value: selectedSKP.status
                    },
                    skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                    berkas: selectedSKP.attachments[0].filename,
                    no: selectedSKP.no,
                    values_id: selectedSKP.values_id
                }
            } else {
                if (selectedSKP.form_json) {
                    form_json = JSON.parse(selectedSKP.form_json)
                    initialValues = {
                        id: selectedSKP.id,
                        activity_date: selectedSKP.activity_date,
                        last_activity_skp_detail_id: {
                            label: selectedSKP.mst_activity_skp_type_detail_data.name,
                            value: selectedSKP.mst_activity_skp_type_detail_data.id
                        },
                        magazine_name: form_json.magazine_name,
                        title_of_research: form_json.title_of_research,
                        volume: form_json.volume,
                        publish_date: form_json.publish_date,
                        issn_number: form_json.issn_number,
                        status: {
                            label: form_json.status,
                            value: form_json.status
                        },
                        skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                        berkas: selectedSKP.attachments[0].filename,
                        no: selectedSKP.no,
                        values_id: selectedSKP.values_id
                    }
                } else {
                    initialValues = {
                        activity_date: selectedSKP.activity_date,
                        last_activity_skp_detail_id: {
                            label: selectedSKP.last_activity_skp_detail_id.label,
                            value: selectedSKP.last_activity_skp_detail_id.value
                        },
                        magazine_name: selectedSKP.magazine_name,
                        title_of_research: selectedSKP.title_of_research,
                        volume: selectedSKP.volume,
                        publish_date: selectedSKP.publish_date,
                        issn_number: selectedSKP.issn_number,
                        status: {
                            label: selectedSKP.status,
                            value: selectedSKP.status
                        },
                        skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                        berkas: selectedSKP.attachments[0].filename,
                        no: selectedSKP.no,
                        values_id: selectedSKP.values_id
                    }
                }
            }
        }
    }
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
        editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
        editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        formValue,
        initialValues
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(withRouter(ModalEditDetailSKP_PenelitianDipublikasikan));