import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listMembership, setSelectedMembership
} from "../../actions/koperasi/membership_action";
import {
  getListMembership,
  getMembershipError,
  getMembershipPending
} from "../../reducers/koperasi/membership_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { Row } from "simple-flexbox";
import { memberList } from "../../actions/member/member_action";
import avatarImage from "../../assets/avatar.png";
import ShuHeader from "./ShuHeader";
import { listBranch } from "../../actions/master-data/branch_action";
import { default_api } from "../../constants/default_api";


class Membership extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
    };

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailMembership(data) {
    this.props.setSelectedMembership(data);
    this.props.history.push(`/admin/shu/${data.member_data.id}`);
  }

  componentDidMount() {
    const { memberList, listBranch } = this.props;
    memberList();
    listBranch({ length: 999 });
  }

  render() {
    const { listMembership, listMembership_data } = this.props;
    const {
      search_text,
    } = this.state;
    let data = [];
    let records_total = 0;
    if (listMembership_data) {
      data = listMembership_data.data;
      records_total = listMembership_data.records_total;
    }
    return (
      <ContentContainer>
        <ShuHeader
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama Member",
              render: ({ member_data: { img_profile, name } }) => (
                <Row vertical="center">
                  {img_profile ? (
                    <img
                      src={`${default_api}/image/show/user_profile/${img_profile}`}
                      alt="profile"
                      height={40}
                      width={40}
                      style={{ borderRadius: 20 }}
                    />
                  ) : (
                    <img
                      src={avatarImage}
                      alt="profile"
                      height={40}
                      width={40}
                      style={{ borderRadius: 20 }}
                    />
                  )}

                  <div style={{ marginLeft: 20 }}>{name}</div>
                </Row>
              ),
            },
            {
              title: "ID Member",
              field: "code",
            },
            {
              title: "Cabang",
              render: ({ member_data: { branch_data } }) =>
                branch_data ? branch_data.name : "-",
              // field: "member_data.branch_data.name",
            },

            {
              title: "Total SHU",
              field: "total_shu",
              render: ({ total_shu }) =>
                total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
            },
            {
              title: "",
              render: (data) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.detailMembership(data)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                branch_id: this.state.branch_id,
              };
              listMembership(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listMembership }) {
  return {
    listMembership_data: getListMembership(listMembership),
    listMembership_error: getMembershipError(listMembership),
    listMembership_pending: getMembershipPending(listMembership),
  };
}

export default connect(mapStateToProps, {
  listMembership,
  memberList,
  listBranch,
  setSelectedMembership
})(Membership);
