import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";

import CircularProgress from "@material-ui/core/CircularProgress";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import { changeStatusGraduation } from "../../actions/member/ppds_action";
import {
  getPpdsError,
  getPpdsPending,
} from "../../reducers/member/ppds_reducer";
import { detailPpds } from "../../actions/member/ppds_action";

class ModalRejectPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_date: new Date(),
      graduated_date: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { changeStatusGraduation, onHide, id, detailPpds } = this.props;
    const param = {
      id,
      is_graduate: "false",
    };

    const callback = () => {
      onHide();
      detailPpds(id);
    };
    changeStatusGraduation(param, callback);
  }
  render() {
    const { changeStatusGraduation_pending } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          // size="lg"
          // centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
          </Modal.Header>
          <Modal.Body>
            <Column className={css(styles.bodyModal)}>
              <p>Apakah Anda yakin ingin peserta ini KELUAR ?</p>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={<ClearIcon />}
                onClick={this.props.onHide}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                disabled={changeStatusGraduation_pending}
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={
                  changeStatusGraduation_pending ? (
                    <CircularProgress size={14} color="default" />
                  ) : (
                    <CheckIcon />
                  )
                }
                onClick={this.handleSubmit}
              >
                Ya
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = StyleSheet.create({
  rejectButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  approveButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    marginTop: 20,
    paddingRight: 40,
  },
});

function mapStateToProps({ chanchangeStatusGraduation }) {
  return {
    changeStatusGraduation_error: getPpdsError(chanchangeStatusGraduation),
    changeStatusGraduation_pending: getPpdsPending(chanchangeStatusGraduation),
  };
}
export default connect(mapStateToProps, { changeStatusGraduation, detailPpds })(
  ModalRejectPPDS
);
