import {
  LIST_JOURNAL_PENDING,
  LIST_JOURNAL_SUCCESS,
  LIST_JOURNAL_ERROR,
  DETAIL_JOURNAL_PENDING,
  DETAIL_JOURNAL_SUCCESS,
  DETAIL_JOURNAL_ERROR,
  EDIT_JOURNAL_PENDING,
  EDIT_JOURNAL_SUCCESS,
  EDIT_JOURNAL_ERROR,
  SET_SELECTED_JOURNAL,
  APPROVAL_JOURNAL_PENDING,
  APPROVAL_JOURNAL_SUCCESS,
  APPROVAL_JOURNAL_ERROR,
  REJECT_APPROVAL_JOURNAL_PENDING,
  REJECT_APPROVAL_JOURNAL_SUCCESS,
  REJECT_APPROVAL_JOURNAL_ERROR,
  PUBLISH_JOURNAL_PENDING,
  PUBLISH_JOURNAL_SUCCESS,
  PUBLISH_JOURNAL_ERROR,
  JOURNAL_REVISION_PENDING,
  JOURNAL_REVISION_SUCCESS,
  JOURNAL_REVISION_ERROR,
} from "../../actions/journal/journal_action";

const initialState = {
  pending: false,
  error: null,
};

export function listJournal(
  state = { ...initialState, list_journal: null },
  action
) {
  switch (action.type) {
    case LIST_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
        list_journal: action.data,
      };
    case LIST_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailJournal(
  state = { ...initialState, detail_journal: null },
  action
) {
  switch (action.type) {
    case DETAIL_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_journal: action.data,
      };
    case DETAIL_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editJournal(state = initialState, action) {
  switch (action.type) {
    case EDIT_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function selectedJournal(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_JOURNAL:
      return action.value;
    default:
      return state;
  }
}

export function approvalJournal(
  state = { ...initialState, approvalJournal: null },
  action
) {
  switch (action.type) {
    case APPROVAL_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVAL_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
        approvalJournal: action.data
      };
    case APPROVAL_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function revisionJournal(
  state = { ...initialState, revisionJournal: null },
  action
) {
  switch (action.type) {
    case JOURNAL_REVISION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case JOURNAL_REVISION_SUCCESS:
      return {
        ...state,
        pending: false,
        revisionJournal: action.data
      };
    case JOURNAL_REVISION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function rejectApprovalJournal(
  state = { ...initialState, rejectApprovalJournal: null },
  action
) {
  switch (action.type) {
    case REJECT_APPROVAL_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REJECT_APPROVAL_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
        rejectApprovalJournal: action.data
      };
    case REJECT_APPROVAL_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function publishJournal(
  state = { ...initialState, publishJournal: null },
  action
) {
  switch (action.type) {
    case PUBLISH_JOURNAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUBLISH_JOURNAL_SUCCESS:
      return {
        ...state,
        pending: false,
        publishJournal: action.data
      };
    case PUBLISH_JOURNAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListJournal = (state) => state.list_journal;
export const getDetailJournal = (state) => state.detail_journal;
export const getJournalPending = (state) => state.pending;
export const getJournalError = (state) => state.error;
export const getSelectedSKP = (state) => state.selectedSKP;
export const getJournalApprovalPending = (state) => state.pending;
export const getJournalApproval = (state) => state.approvalJournal;
export const getJournalApprovalError = (state) => state.error;
export const getJournalRejectApprovalPending = (state) => state.pending;
export const getJournalRejectApproval = (state) => state.rejectApprovalJournal;
export const getJournalRejectApprovalError = (state) => state.error;
export const getJournalPublishPending = (state) => state.pending;
export const getJournalPublish = (state) => state.publishJournal;
export const getJournalPublishError = (state) => state.error;
export const getJournalRevisionPending = (state) => state.pending;
export const getJournalRevision = (state) => state.revisionJournal;
export const getJournalRevisionError = (state) => state.error;