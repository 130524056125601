import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { publishJournal } from "../../actions/journal/journal_action";
import {
    getJournalPublish,
    getJournalPublishPending,
    getJournalPublishError,
} from "../../reducers/journal/journal_reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
    renderFile, renderInputWithLabel
} from "../../components/form/FormComponent";

class ModalPublishJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { publishJournal, id, onHide } = this.props;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("number", values.number);
        formData.append("volume", values.volume);
        formData.append("code", values.code);
        formData.append("file", values.publish_file);

        const callback = () => {
            onHide();
            this.props.handleRefresh({ forRefresh: true });
        };
        publishJournal(formData, callback);
    }

    render() {
        const { publishJournal_pending, handleSubmit } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width"
                // centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <span>Apakah Anda yakin untuk mempublikasikan jurnal ini?</span>
                            <br></br>
                            <span>Harap lengkapi persyaratan penerbitan jurnal (mis. no, vol), dan unggah kembali jurnal yang telah memenuhi persyaratan untuk segera dipublikasikan.</span>
                            <hr></hr>
                            <>
                                <Field
                                    name="number"
                                    placeholder="Masukkan Nomor Jurnal"
                                    label="Nomor"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="volume"
                                    placeholder="Masukkan Volume Jurnal"
                                    label="Volume"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="code"
                                    placeholder="Masukkan Kode Jurnal"
                                    label="Kode"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="publish_file"
                                    type="file"
                                    fileType="pdf" //image or pdf
                                    title="upload berkas"
                                    message="(Opsional) WORD, Maksimal 5000 KB"
                                    component={renderFile}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={
                                    publishJournal_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                Submit
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function validate(values) {
    const { publish_file, number, volume, code } = values;
    const errors = {};
    if (!publish_file) {
        errors.publish_file = "Berkas publikasi wajib diisi";
    }
    if (!number) {
        errors.number = "Nomor jurnal wajib diisi";
    }
    if (!volume) {
        errors.volume = "Volume jurnal wajib diisi";
    }
    if (!code) {
        errors.code = "Kode jurnal wajib diisi";
    }
    return errors;
}

ModalPublishJournal = reduxForm({
    form: "ModalPublishJournal",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalPublishJournal);

function mapStateToProps({ publishJournal }) {
    return {
        publishJournal_pending: getJournalPublishPending(publishJournal),
        publishJournal_data: getJournalPublish(publishJournal),
        publishJournal_error: getJournalPublishError(publishJournal),
    };
}

export default connect(mapStateToProps, { publishJournal })(ModalPublishJournal);
