import React from "react";
import { StyleSheet, css } from "aphrodite";
import avatarImage from "../../assets/avatar.png";
import { getUser } from '../../helpers/user';
import { default_api } from "../../constants/default_api";



function AvatarComponent(props) {
  const { image } = props;
  let folder = '';
  if (getUser() !== null) {
    folder = getUser().role_codes[0] === 'PPDS' ? 'ppds_supporting_files' : 'user_profile';
  }
  else {
    folder = 'user_profile';
  }
  const imageUrl =
    default_api + "/image/show/" + folder + "/" + image;
  return (
    <>
      <img
        src={image ? imageUrl : avatarImage}
        alt="profil"
        width="100%"
        height="100%"
        className={css(styles.image)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  image: {
    borderRadius: "50%",
    objectFit: "contain",
  },
});

AvatarComponent.propsTypes = {
  // image: node,
};
AvatarComponent.defaultProps = {
  // image: avatarImage,
};

export default AvatarComponent;
