import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
    detailAdvertisement,
} from "../../actions/advertisement/advertisement_action";
import { withRouter } from "react-router-dom";
import {
    getDetailAdvertisement
} from "../../reducers/advertisement/advertisement_reducer";
import { connect } from "react-redux";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import moment from 'moment';
import localization from 'moment/locale/id';
import ModalBannerPreview from "./Modal_banner_preview";

class AdvertisementDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalBannerPreviewShow: false,
            imgUrl: null,
            modal_title: null
        };
    }

    handleOpenBanner(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        this.setState({ modalBannerPreviewShow: true })
    }

    createMarkup(html) {
        return { __html: html };
    }

    componentDidMount() {
        const { detailAdvertisement } = this.props;
        const {
            match: {
                params: { id },
            },
        } = this.props;
        detailAdvertisement(id);
    }

    render() {
        const {
            detailAdvertisement_data,
        } = this.props;
        let title, instance_name, period, description, url, banner, data;
        if (detailAdvertisement_data && detailAdvertisement_data.length !== 0) {
            data = detailAdvertisement_data[0];
            title = data.title;
            instance_name = data.instance_name;
            period = moment(data.start_period).locale('id', localization).format('DD MMMM YYYY') + " - " + moment(data.end_period).locale('id', localization).format('DD MMMM YYYY');
            description = data.description;
            url = data.url ? <a href={data.url} target="_blank" rel="noopener noreferrer">{data.url}</a> : "-";
            banner = data.banner_name;
        }
        return (
            <ContentContainer>
                <Row>
                    <ModalBannerPreview
                        show={this.state.modalBannerPreviewShow}
                        onHide={() => this.setState({ modalBannerPreviewShow: false })}
                        imgUrl={this.state.imgUrl}
                        modal_title={this.state.modal_title}
                    />
                    <LeftContainerComponent>
                        <DetailNameComponent name={title} />
                    </LeftContainerComponent>
                    <RightContainerComponent>
                        <DetailContentComponent label="Periode" value={period} />
                        <DetailContentComponent label="Nama Instansi" value={instance_name} />
                        <DetailContentComponent label="Deskripsi" alignItems="baseline" value={<div dangerouslySetInnerHTML={this.createMarkup(description)}></div>} />
                        <DetailContentComponent label="URL" value={url} />
                        <DetailContentComponent label="Banner" value={<DetailContentLihatBerkas label="Preview Iklan" onClick={() => this.handleOpenBanner(banner, "Preview Iklan")} />} />
                    </RightContainerComponent>
                </Row>
            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const { detailAdvertisement } = state;
    return {
        detailAdvertisement_data: getDetailAdvertisement(detailAdvertisement),
    };
}

export default connect(mapStateToProps, {
    detailAdvertisement
})(withRouter(AdvertisementDetail));
