import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addTraining } from "../../actions/master-data/training_actions";
import { renderInputWithLabel, renderSelectWithLabel, renderFile } from "../../components/form/FormComponent";
import {
    getTrainingAdd,
    getTrainingAddPending,
    getTrainingAddError
} from "../../reducers/master-data/training_reducer";

class ModalTrainingAdd extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show === true) {
            this.props.reset();
        }
    }

    handleSubmit(values) {
        const { addTraining, onHide, handleRefresh } = this.props;
        const param = {
            file: values.berkas,
            institution: values.institusi,
            name: values.pelatihan,
            year: values.tahun ? values.tahun.value : null,
        };

        const callback = () => {
            onHide();
            handleRefresh();
        };
        addTraining(param, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            addTraining_pending
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Pelatihan</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <Field
                                label="Nama Institusi"
                                name="institusi"
                                placeholder="Masukkan nama institusi"
                                component={renderInputWithLabel}
                            />
                            <Field
                                label="Nama Pelatihan"
                                name="pelatihan"
                                placeholder="Masukkan nama pelatihan"
                                component={renderInputWithLabel}
                            />
                            <Field
                                label="Tahun"
                                name="tahun"
                                placeholder="Pilih tahun"
                                options={yearOptions}
                                component={renderSelectWithLabel}
                            />
                            <Field
                                type="file"
                                name="berkas"
                                fileType="image"
                                message="PNG atau JPG (maksimal 1 MB)"
                                title="UPLOAD BERKAS"
                                component={renderFile}
                            />
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={addTraining_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    addTraining_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const maxFileSize = 1000000; //500kb
    const imageType = ["image/png", "image/jpeg"]
    const { institusi, pelatihan, tahun, berkas } = values;
    const errors = {};
    if (!institusi) {
        errors.institusi = "Nama institusi tidak boleh kosong!";
    }
    if (!pelatihan) {
        errors.pelatihan = "Nama pelatihan tidak boleh kosong!";
    }
    if (!tahun) {
        errors.tahun = "Tahun tidak boleh kosong!";
    }
    if (!berkas) {
        errors.berkas = "Berkas tidak boleh kosong!";
    }
    else{
        if (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1]){
            errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
        }
        else if (values.berkas.size > maxFileSize){
            errors.berkas = "File lebih dari 1 MB"
        }
    }
    
    return errors;
}

ModalTrainingAdd = reduxForm({
    form: "ModalTrainingAdd",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalTrainingAdd);

function mapStateToProps({ addTraining }) {
    return {
        addTraining_pending: getTrainingAddPending(addTraining),
        addTraining_data: getTrainingAdd(addTraining),
        addTraining_error: getTrainingAddError(addTraining),
    };
}

export default connect(mapStateToProps, {
    addTraining,
})(withRouter(ModalTrainingAdd));
