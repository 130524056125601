import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import ContentContainer from "../../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../../components/p2kb/HeaderDetailP2kbComponent";
import { StyleSheet, css } from "aphrodite";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import { p2kbList, addP2kb, uploadP2kb, setSelectedSKP } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbListPending,
    getP2kbList,
    getP2kbListError,
    getP2kbAddPending,
    getP2kbAdd,
    getP2kbAddError,
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import { reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ModalAddDetailSKPPenyeliaSupervisor from "./Modal_add_detail_skp_penyelia_supervisor";
import ModalEditDetailSKPPenyeliaSupervisor from "./Modal_edit_detail_skp_penyelia_supervisor";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../../components/global-components/DetailButtonComponent";
import IconDocumentP2KB from '../../../assets/icon-document-p2kb';
import IconLaunch from '../../../assets/icon-launch';
import moment from 'moment';
import localization from 'moment/locale/id';
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
class AddDetailSKP_PenyeliaSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenShow: false,
            ModalAddDetailSKP_PenyeliaSupervisorShow: false,
            ModalEditDetailSKP_PenyeliaSupervisorShow: false,
            imgUrl: "",
            modal_title: "",
            skp_total: 0,
            p2kb_details: [],
            columns: [
                {
                    title: "No", field: "no",
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                {
                    title: "Tanggal Kegiatan",
                    render: (data) => {
                        return moment(data.activity_date).locale('id', localization).format('DD MMMM YYYY')
                    }
                },
                { title: "Nama Kegiatan", field: "activity_name" },
                { title: "Nama Institusi", field: "institution_name" },
                {
                    title: "Jumlah SKP",
                    render: (data) => {
                        if (data.total_skp) {
                            return data.total_skp
                        } else {
                            return data.skp
                        }
                    }
                },
                {
                    title: "Document",
                    render: (data) => {
                        return (
                            <DetailButtonComponent button={props => <Row onClick={props.onClick} className={css(styles.buttonDocHover)}><IconDocumentP2KB fill="Black" /></Row>}>
                                <MenuItem onClick={() => this.handleOpenDocument(data.attachments[0].filename, "Keterangan Dokumen")}>
                                    <IconLaunch /> <span style={{ marginLeft: 10, paddingTop: 2 }}>Keterangan Dokumen</span>
                                </MenuItem>
                            </DetailButtonComponent>
                        );
                    },
                },
                {
                    title: "",
                    render: (data) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.handleEditSkpDetailModal(data)}>
                                    Edit
                                </MenuItem>
                                <MenuItem onClick={() => this.handleDeleteSkpDetail(data)}>
                                    Hapus
                                </MenuItem>
                            </DetailButtonComponent>
                        );
                    },
                },
            ],
            data: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleAddSkpDetail = this.handleAddSkpDetail.bind(this);
        this.handleAddSkp = this.handleAddSkp.bind(this);
        this.handleEditSkpDetail = this.handleEditSkpDetail.bind(this);
        this.handleEditSkpDetailModal = this.handleEditSkpDetailModal.bind(this);
        this.handleDeleteSkpDetail = this.handleDeleteSkpDetail.bind(this);
    }

    handleAddSkpDetail(values_tobeshown, values_tobesent) {
        let data_tobeshown = { ...values_tobeshown, no: this.state.data.length + 1, tableData: { id: this.state.data.length } }
        let data_tobesent = { ...values_tobesent, no: this.state.data.length + 1 }
        let newData_tobeshown = [...this.state.data, data_tobeshown]
        let newData_tobesent = [...this.state.p2kb_details, data_tobesent]
        let sum_skp_total = this.state.skp_total + data_tobeshown.skp
        this.setState({ data: newData_tobeshown });
        this.setState({ p2kb_details: newData_tobesent });
        this.setState({ skp_total: sum_skp_total });
    }

    handleAddSkp() {
        if (this.state.data.length > 0) {
            toast.error("Maksimal detail SKP Penyelia (Supervisor) yang bisa diajukan adalah 1", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            this.setState({ ModalAddDetailSKP_PenyeliaSupervisorShow: true })
        }
    }

    handleEditSkpDetailModal(data) {
        data = { ...data, isEditPenyeliaSupervisor: true }
        this.props.setSelectedSKP(data);
        this.setState({ ModalEditDetailSKP_PenyeliaSupervisorShow: true })
    }

    handleEditSkpDetail(values_tobeshown, values_tobesent) {
        const index = values_tobeshown.no - 1;
        let skp_total = 0;
        let newData_tobeshown = [...this.state.data];
        newData_tobeshown[index] = values_tobeshown;
        this.setState({ data: newData_tobeshown });
        let newData_tobesent = [...this.state.p2kb_details];
        newData_tobesent[index] = values_tobesent;
        this.setState({ p2kb_details: newData_tobesent });
        newData_tobeshown.forEach((item) => {
            skp_total += item.skp ? item.skp : item.total_skp
        });
        this.setState({ skp_total: skp_total });
    }

    handleDeleteSkpDetail(data) {
        let newData_tobeshown = this.state.data.filter(item => item.no !== data.no);
        let newData_tobesent = this.state.p2kb_details.filter(item => item.no !== data.no);
        let skp_total = 0;
        newData_tobeshown = newData_tobeshown.map((item, index) => ({
            ...item, no: index + 1
        }));
        newData_tobesent = newData_tobesent.map((item, index) => ({
            ...item, no: index + 1
        }));
        newData_tobeshown.forEach((item) => {
            skp_total += item.skp
        });
        this.setState({ skp_total: skp_total });
        this.setState({ data: newData_tobeshown });
        this.setState({ p2kb_details: newData_tobesent });
    }

    handleSubmit(values) {
        this.setState({ hasSubmit: true })
        const { tempSKPData } = this.state;
        const activity_value = tempSKPData.activity.value;
        const param_addSKP = {
            activity_id: activity_value,
            p2kb_details: this.state.p2kb_details
        }

        const callback = () => {
            this.props.history.push(`/skp`)
        }
        this.props.addP2kb(param_addSKP, callback);
    }

    handleRefresh(state) {
        this.setState(state);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    render() {
        const { handleSubmit } = this.props;
        const { tempSKPData } = this.state;
        const activity_name = tempSKPData.activity.label;
        const realm_activity_name = tempSKPData.realm_activity.label;
        const activity_type = tempSKPData.activity_type;
        return (
            <ContentContainer>
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalAddDetailSKPPenyeliaSupervisor
                    show={this.state.ModalAddDetailSKP_PenyeliaSupervisorShow}
                    onHide={() => this.setState({ ModalAddDetailSKP_PenyeliaSupervisorShow: false })}
                    handleAddDetail={this.handleAddSkpDetail}
                />
                <ModalEditDetailSKPPenyeliaSupervisor
                    show={this.state.ModalEditDetailSKP_PenyeliaSupervisorShow}
                    onHide={() => this.setState({ ModalEditDetailSKP_PenyeliaSupervisorShow: false })}
                    handleEditDetail={this.handleEditSkpDetail}
                />
                <HeaderDetailP2kbComponent
                    activity_name={activity_name}
                    activity_type={activity_type}
                    realm_activity={realm_activity_name}
                    skp={this.state.skp_total}
                    isAddDetail={true}
                />
                <Column>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <div className={css(styles.submitButtonContainer)}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.rejectButton)}
                                onClick={() => this.handleAddSkp()}
                                startIcon={<AddIcon />}
                            >
                                Tambah
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                type="submit"
                                startIcon={
                                    this.state.hasSubmit ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                Simpan
                            </Button>
                        </div>
                        <MaterialTable
                            title="detail_skp"
                            columns={this.state.columns}
                            data={this.state.data}
                            options={{
                                paginationType: "stepped",
                                pageSize: 10,
                                pageSizeOptions: [],
                                showTitle: false,
                                search: false,
                                headerStyle: {
                                    backgroundColor: "#F8F9FB",
                                },
                            }}
                            components={{
                                Toolbar: () => <div />,
                                Container: (props) => <Paper {...props} elevation={0} />,
                                Pagination: (props) => (
                                    <Row
                                        // vertical="center"
                                        horizontal="space-between"
                                        style={{ paddingRight: 20, paddingLeft: 20 }}
                                    >
                                        <Row
                                            style={{
                                                // backgroundColor: "red",
                                                width: "50%",
                                                borderBottom: "1px solid rgba(224, 224, 224)",
                                            }}
                                            vertical="center"
                                        >
                                            <p> Menampilkan {this.state.data.length > 10 ? "10" : this.state.data.length} dari {this.state.data.length} data </p>
                                        </Row>
                                        <TablePagination
                                            {...props}
                                            color="primary"
                                            labelRowsPerPage={
                                                <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                            }
                                            labelDisplayedRows={(row) => (
                                                <div style={{ fontSize: 14, color: "red" }}>
                                                    {/* {props.labelDisplayedRows(row)} */}
                                                </div>
                                            )}
                                            SelectProps={{
                                                style: {
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </Row>
                                ),
                            }}
                        />
                    </form>
                </Column>
            </ContentContainer>
        )
    }
}

const styles = StyleSheet.create({
    buttonDocHover: {
        ":hover": {
            cursor: "pointer"
        }
    },
    columnNo: {
        width: "5%",
        padding: 10,
        marginLeft: 20,
        marginBottom: 15,
        marginTop: 20
    },
    columnDate: {
        width: "20%",
        padding: 10,
        marginBottom: 15,
        marginTop: 20
    },
    columnPasienSIPSKP: {
        width: "15%",
        padding: 10,
        marginBottom: 15,
        marginTop: 20
    },
    columnDocument: {
        width: "25%",
        padding: 10,
        marginBottom: 15,
        marginTop: 20
    },
    submitButtonContainer: {
        padding: 20,
        textAlign: "left"
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        marginRight: 10,
    },
    rejectButton: {
        background: "#EAEBED",
        marginLeft: 10,
        marginRight: 20,
        textAlign: "center",
        color: "#495057",
        boxShadow: "none",
    }
})

AddDetailSKP_PenyeliaSupervisor = reduxForm({
    form: "AddDetailSKP_PenyeliaSupervisor",
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    shouldError: () => true,
})(AddDetailSKP_PenyeliaSupervisor);

function mapStateToProps({ p2kbList, uploadP2kb, addP2kb, setSelectedSKP, ...state }) {
    return {
        p2kbList_pending: getP2kbListPending(p2kbList),
        p2kbList_data: getP2kbList(p2kbList),
        p2kbList_error: getP2kbListError(p2kbList),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        addP2kb_pending: getP2kbAddPending(addP2kb),
        addP2kb_data: getP2kbAdd(addP2kb),
        addP2kb_error: getP2kbAddError(addP2kb)
    };
}

export default connect(mapStateToProps, { p2kbList, uploadP2kb, addP2kb, setSelectedSKP })(withRouter(AddDetailSKP_PenyeliaSupervisor));