import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import IconEdit from '../../assets/icon-edit';

function EditComponent(props) {
  return (
    <Row onClick={props.onClick} className={css(styles.editButtonContainer)}>
      <IconEdit />
    </Row>
  );
}

const styles = StyleSheet.create({
  editButtonContainer: {
      padding: 5,
      ":hover":{
          backgroundColor: "#eaeaea",
          borderRadius: 5,
          cursor: "pointer"
      }
  }
});

export default EditComponent;
