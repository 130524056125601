import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { memberVerifyPassword } from "../../actions/guests/member_verify_password_action";
import { getMemberVerifyPassword, getMemberVerifyPasswordPending, getMemberVerifyPasswordError } from "../../reducers/guests/member_verify_password_reducer"
import Button from "@material-ui/core/Button";
import { reduxForm, Field } from "redux-form";
import { renderInput } from "../../components/form/FormComponent";
import IconInput from "../../assets/icon-input";
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from "@material-ui/core";

class verifyPassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { memberVerifyPassword } = this.props;
        const url = window.location.href.split('/');
        const verify_token = url.pop();
        const param_memberVerifyPassword = {
            verify_token,
            password: values.password,
            confirmation_password: values.passwordConf
        };
        const callback = () => {
            this.props.history.push('/login')
        }
        memberVerifyPassword(param_memberVerifyPassword, callback);
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Grid container>
                <Grid item xs={false} sm={false} md={4} lg={4} className={css(styles.sideContainer)}></Grid>
                <Grid item xs={10} sm={10} md={7} lg={7} className={css(styles.memberVerifyPasswordContainer)}>
                    <Column>
                        <Row>
                            <img src={require('../../assets/ic_paboi.png')} className={css(styles.logo)} alt="logo-paboi" />
                        </Row>
                        <span className={css(styles.memberVerifyPasswordTitle)}>Verifikasi Password</span>
                        <span className={css(styles.memberVerifyPasswordDesc)}>Masukkan password baru</span>
                        <hr className={css(styles.memberVerifyPasswordSeparator)} />
                        <form
                            onSubmit={handleSubmit(this.handleSubmit)}
                            className={css(styles.memberVerifyPasswordForm)}
                        >
                            <Field
                                name="password"
                                placeholder="Password"
                                type="password"
                                component={renderInput}
                                className={css(styles.inputMargin)}
                            />
                            <Field
                                name="passwordConf"
                                placeholder="Konfirmasi Password"
                                type="password"
                                component={renderInput}
                                className={css(styles.inputMargin)}
                            />
                            <Row style={{ justifyContent: "space-between" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.approveButton)}
                                    disabled={this.props.memberVerifyPassword_pending}
                                    startIcon={
                                        this.props.memberVerifyPassword_pending ? (
                                            <CircularProgress size={14} color="secondary" />
                                        ) : (
                                                <IconInput />
                                            )
                                    }
                                >
                                    Verifikasi
                                </Button>
                                <Column>
                                    <span className={css(styles.daftarLabel)}>Sudah punya akun?</span>
                                    <span onClick={() => this.props.history.push('/login')} className={css(styles.inputLinkDaftar)}>Login</span>
                                </Column>
                            </Row>
                        </form>
                    </Column>
                </Grid>
            </Grid >
        )
    }
}

const styles = StyleSheet.create({
    sideContainer: {
        height: "100vh",
        background: "transparent linear-gradient(180deg, #2CB96A 0%, #43C767 66%, #63DB62 100%) 0% 0% no-repeat padding-box"
    },
    memberVerifyPasswordContainer: {
        margin: "auto"
    },
    memberVerifyPasswordTitle: {
        color: "#04AA59",
        fontSize: 36,
        fontWeight: "bold",
        margin: 15
    },
    memberVerifyPasswordDesc: {
        fontSize: 16,
        margin: "0 15px"
    },
    memberVerifyPasswordSeparator: {
        width: 400,
        margin: "20px 15px",
        borderTop: "2px solid rgba(0,0,0,0.1)",
        '@media (max-width: 480px)': {
            width: "100%"
        }
    },
    memberVerifyPasswordForm: {
        width: 400,
        marginLeft: 15,
        '@media (max-width: 480px)': {
            width: "100%"
        }

    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        margin: "15px 0"
    },
    inputMargin: {
        margin: "15px 0"
    },
    inputLinkDaftar: {
        fontSize: 16,
        color: "black",
        textDecoration: "none",
        marginTop: 5,
        textAlign: "right",
        ":hover": {
            cursor: "pointer"
        }
    },
    daftarLabel: {
        marginLeft: 15,
        marginTop: 15,
        textAlign: "right"
    }
})

function validate(values) {
    const { passwordConf, password } = values;
    const errors = {};
    if (!password) {
        errors.password = "Password tidak boleh kosong"
    }
    if (password && password.length < 8) {
        errors.password = "Password minimal terdiri dari 8 karakter"
    }
    if (!passwordConf) {
        errors.passwordConf = "Password tidak boleh kosong"
    }
    if (passwordConf && passwordConf !== password) {
        errors.passwordConf = "Konfirmasi password harus sesuai dengan password"
    }
    return errors;
}

verifyPassword = reduxForm({
    // a unique name for the form
    form: "verifyPassword",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    shouldError: () => true,
})(verifyPassword);

function mapStateToProps(memberVerifyPassword) {
    return {
        memberVerifyPassword_pending: getMemberVerifyPasswordPending(memberVerifyPassword),
        memberVerifyPassword_data: getMemberVerifyPassword(memberVerifyPassword),
        memberVerifyPassword_error: getMemberVerifyPasswordError(memberVerifyPassword)
    }
}

export default connect(mapStateToProps, { memberVerifyPassword })(verifyPassword);