import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addExperience } from "../../actions/master-data/experience_action";
import { renderInputWithLabel, renderSelectWithLabel, renderFile } from "../../components/form/FormComponent";
import {
    getExperienceAdd,
    getExperienceAddPending,
    getExperienceAddError
} from "../../reducers/master-data/experience_reducer";

class ModalExperienceAdd extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show === true) {
            this.props.reset();
        }
    }

    handleSubmit(values) {
        const { addExperience, onHide, handleRefresh } = this.props;
        const param = {
            file: values.berkas,
            institution: values.institusi,
            position: values.jabatan,
            year: `${values.tahun.value}`,
            city: values.kota.label,
        };

        const callback = () => {
            onHide();
            handleRefresh();
        };
        addExperience(param, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            addExperience_pending
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Pengalaman</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            {(
                                    <>
                                        <Field
                                            label="Nama Institusi"
                                            name="institusi"
                                            placeholder="Masukkan nama institusi"
                                            component={renderInputWithLabel}
                                        />
                                        <Field
                                            label="Jabatan"
                                            name="jabatan"
                                            placeholder="Masukkan jabatan"
                                            component={renderInputWithLabel}
                                        />
                                        <Field
                                            label="Kota"
                                            name="kota"
                                            placeholder="Pilih kota"
                                            options={this.props.cityOption}
                                            component={renderSelectWithLabel}
                                        />
                                        <Field
                                            label="Tahun"
                                            name="tahun"
                                            placeholder="Pilih tahun"
                                            options={yearOptions}
                                            component={renderSelectWithLabel}
                                        />
                                        <Field
                                            type="file"
                                            name="berkas"
                                            fileType="image"
                                            message="PNG atau JPG (maksimal 1 MB)"
                                            title="UPLOAD BERKAS"
                                            component={renderFile}
                                        />
                                    </>
                                )}
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={addExperience_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    addExperience_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const maxFileSize = 1000000; //1mb
    const imageType = ["image/png", "image/jpeg"]
    const { institusi, jabatan, kota, tahun, berkas } = values;
    const errors = {};
    if (!institusi) {
        errors.institusi = "Nama institusi tidak boleh kosong!";
    }
    if (!jabatan) {
        errors.jabatan = "Jabatan tidak boleh kosong!";
    }
    if (!kota) {
        errors.kota = "Kota tidak boleh kosong!";
    }
    if (!tahun) {
        errors.tahun = "Tahun tidak boleh kosong!";
    }
    if (!berkas) {
        errors.berkas = "Berkas tidak boleh kosong!";
    }
    else{
        if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
            errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
        }
        else if (values.berkas && (values.berkas.size > maxFileSize)){
            errors.berkas = "File lebih dari 1 MB"
        }
    }
    return errors;
}

ModalExperienceAdd = reduxForm({
    form: "ModalAddExperience",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalExperienceAdd);

function mapStateToProps({ addExperience }) {
    return {
        addExperience_pending: getExperienceAddPending(addExperience),
        addExperience_data: getExperienceAdd(addExperience),
        addExperience_error: getExperienceAddError(addExperience),
    };
}

export default connect(mapStateToProps, {
    addExperience,
})(withRouter(ModalExperienceAdd));
