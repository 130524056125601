import {
  LIST_SUPPORT_PENDING,
  LIST_SUPPORT_SUCCESS,
  LIST_SUPPORT_ERROR,
  DETAIL_SUPPORT_PENDING,
  DETAIL_SUPPORT_SUCCESS,
  DETAIL_SUPPORT_ERROR,
  EDIT_SUPPORT_PENDING,
  EDIT_SUPPORT_SUCCESS,
  EDIT_SUPPORT_ERROR, 
  ADD_SUPPORT_PENDING,
  ADD_SUPPORT_SUCCESS,
  ADD_SUPPORT_ERROR,
  APPROVE_SUPPORT_PENDING,
  APPROVE_SUPPORT_SUCCESS,
  APPROVE_SUPPORT_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/support/support_action";

const initialState = {
  pending: false,
  error: null,
};

export function listSupport(state = { ...initialState, list_support: null }, action) {
  switch (action.type) {
    case LIST_SUPPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SUPPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        list_support: action.data,
      };
    case LIST_SUPPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailSupport(
  state = { ...initialState, detail_support: null },
  action
) {
  switch (action.type) {
    case DETAIL_SUPPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_SUPPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_support: action.data,
      };
    case DETAIL_SUPPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editSupport(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_SUPPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SUPPORT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_SUPPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addSupport(state = initialState, action) {
  switch (action.type) {
    case ADD_SUPPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_SUPPORT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_SUPPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

 

export function approveSupport(state = initialState, action) {
  switch (action.type) {
    case APPROVE_SUPPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_SUPPORT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_SUPPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListSupport = (state) => state.list_support;
export const getDetailSupport = (state) => state.detail_support;
export const getSupportPending = (state) => state.pending;
export const getSupportError = (state) => state.error;
export const getSupportIsEditFile = (state) => state.isEditFile;
