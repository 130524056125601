import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import localization from "moment/locale/id";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { addSIP } from "../../actions/pengajuan-sip/sip_action";
import { renderSelectWithLabel, renderInputWithLabel, renderFile, renderDatepickerWithLabel2 } from "../../components/form/FormComponent";
import {
    getSIPAdd,
    getSIPAddPending,
    getSIPAddError
} from "../../reducers/pengajuan-sip/sip_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import FileValidation from "../../components/form/FileValidation";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import { memberList, memberP2kbExisting } from "../../actions/member/member_action";
import {
    getMemberList,
    getMemberP2kbExistingPending,
    getMemberP2kbExisting
} from "../../reducers/member/member_reducer";
import { getUser } from '../../helpers/user';
import moment from "moment"
import { default_api } from "../../constants/default_api";

class SIPModalAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: "",
            modal_title: "",
            folder: "",
            modalDocumentImgShow: false,
            modalDocumentPdfShow: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOpenDocument = (imgUrl, modal_title, folder = "") => {
        if (modal_title === "CV") {
            window.open(default_api + "/member/download/cv/" + imgUrl + ".pdf");
        } else {
            this.setState({ folder: folder })
            this.setState({ imgUrl: imgUrl })
            this.setState({ modal_title: modal_title })
            if (imgUrl.split('.')[1] === "pdf") {
                this.setState({ modalDocumentPdfShow: true })
            } else {
                this.setState({ modalDocumentImgShow: true })
            }
        }
    }

    handleSubmit(values) {
        const { addSIP, onHide, handleRefresh, memberP2kbExisting_data } = this.props;
        const param = {
            branch_to: getUser().branch_id,
            hospital_id_to: values.hospital.value,
            file: values.berkas,
            date: moment().format('YYYY-MM-DD'),
            str_no: values.str,
            attachments: values.file.map((item) => ({
                attachment_name: item.name,
                attachment_filename: item.value,
            }))
        };
        if (memberP2kbExisting_data.member_serkom !== null) {
            param.serkom_no = memberP2kbExisting_data.member_serkom.sk_no;
        }
        if (memberP2kbExisting_data.member_str !== null) {
            param.str_no = memberP2kbExisting_data.member_str.str_no;
        }
        const callback = () => {
            onHide();
            handleRefresh();
        };
        addSIP(param, callback);
    }

    componentDidMount() {
        if (getUser().role_codes[0] === "member") {
            this.props.memberList({ id: getUser().id });
            this.props.memberP2kbExisting({ id: getUser().id });
        }
    }

    isValidMoreThanSixMonth = (date) => {
        const sixMonthsAfterCurrentDate = moment(new Date()).add(6, "months");
        return moment(date).isSameOrAfter(sixMonthsAfterCurrentDate);
    }

    changeFileValue = (formName, value) => {
        this.props.change(formName, value);
    }

    render() {
        const {
            modalDocumentImgShow,
            modalDocumentPdfShow,
            modal_title,
            imgUrl,
            folder
        } = this.state;
        const {
            handleSubmit,
            show,
            onHide,
            change,
            addSIP_pending,
            branchOptions,
            hospitalOptions,
            memberList_data,
            memberP2kbExisting_data,
            sipListData
        } = this.props;
        let hasSIP, hasSTR, hasSerkom, hasKTP = false;
        const hasCV = true;
        const CVfilename = getUser().id;
        let SIPfilename, STRfilename, SerkomFilename, KTPfilename = null;
        if (getUser().role_codes[0] === "member") {
            change("memberID", getUser().member_profile.paboi_id);
        }
        if (memberList_data) {
            const data = memberList_data.data[0];
            KTPfilename = data.member_profile.scan_ktp_image;
            hasKTP = KTPfilename !== null ? true : false;
            change("currentBranch", data.branch_data ? data.branch_data.name : "Belum ada cabang");
        }
        if (memberP2kbExisting_data) {
            const data = memberP2kbExisting_data;
            if (data.member_str !== null) {
                change("str", data.member_str.str_no);
                hasSTR = true;
                STRfilename = data.member_str.filename
            }
            if (data.member_sip !== null) {
                hasSIP = true;
                SIPfilename = data.member_sip.filename
            }
            if (data.member_serkom !== null) {
                hasSerkom = true;
                SerkomFilename = data.member_serkom.filename
            }
        }
        if (sipListData) {
            if (sipListData.length !== 0) {
                const data = "Pengajuan SIP ke " + (sipListData[0].count_practice + 1)
                change("sipRequest", data);
            } else {
                change("sipRequest", "Pengajuan SIP ke 1");
            }
        }

        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDocumentImg
                    show={modalDocumentImgShow}
                    onHide={() => this.setState({ modalDocumentImgShow: false })}
                    imgUrl={imgUrl}
                    modal_title={modal_title}
                    folder={folder}
                />
                <ModalDocumentPdf
                    show={modalDocumentPdfShow}
                    onHide={() => this.setState({ modalDocumentPdfShow: false })}
                    imgUrl={imgUrl}
                    modal_title={modal_title}
                    folder={folder}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Pengajuan SIP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            {(
                                <>
                                    <Field
                                        name="date"
                                        isDetail
                                        placeholder="Masukkan tanggal pengajuan"
                                        label="Tanggal Pengajuan"
                                        component={renderDatepickerWithLabel2}
                                    />
                                    <Field
                                        disable={true}
                                        label="Urutan Pengajuan SIP"
                                        name="sipRequest"
                                        component={renderInputWithLabel}
                                    />

                                    <Field
                                        disable={true}
                                        label="Cabang Tujuan"
                                        name="currentBranch"
                                        component={renderInputWithLabel}
                                    />
                                    <Field
                                        label="RS Tujuan"
                                        name="hospital"
                                        placeholder="Pilih RS Tujuan"
                                        options={hospitalOptions}
                                        isAsync
                                        asyncUrl="master_data/hospital/find"
                                        component={renderSelectWithLabel}
                                    />
                                    <label className={css(styles.labelMargin)}>Surat Permohonan Tertulis</label>
                                    <Field
                                        name="berkas"
                                        label="Berkas"
                                        type="file"
                                        fileType="image" //image or pdf
                                        title="upload berkas"
                                        message="PNG atau JPG maksimal 500 kb"
                                        component={renderFile}
                                    />
                                    <span style={{ marginBottom: 10 }}>Berkas</span>
                                    <FileValidation
                                        label="KTP"
                                        hasFile={hasKTP}
                                        openFile={() => this.handleOpenDocument(KTPfilename, "KTP", "member_supporting_files")}
                                    />
                                    <FileValidation
                                        label="CV"
                                        hasFile={hasCV}
                                        openFile={() => this.handleOpenDocument(CVfilename, "CV")}
                                    />
                                    <FileValidation
                                        label="STR"
                                        hasFile={hasSTR}
                                        openFile={() => this.handleOpenDocument(STRfilename, "STR", "member_str")}
                                    />
                                    { hasSerkom ? <FileValidation
                                        label="Serkom"
                                        hasFile={hasSerkom}
                                        openFile={() => this.handleOpenDocument(SerkomFilename, "Serkom", "member_serkom")}
                                    />: <label className={css(styles.labelMargin)}>Serkom</label> }
                                    <FieldArray
                                        name="file"
                                        component={DynamicFormComponent}
                                        changeFileValue={this.changeFileValue}
                                        folderPath="sip_request_files"
                                    />
                                </>
                            )}
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={addSIP_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    addSIP_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    labelMargin: {
        marginTop: "10px"
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const maxFileSize = 500000; //500kb
    const imageType = ["image/png", "image/jpeg"]
    const { currentBranch, hospital, str, file, berkas } = values;
    const errors = {};
    const fileArrayErrors = [];
    if (!currentBranch) {
        errors.currentBranch = "Cabang tujuan tidak boleh kosong!";
    }
    if (!hospital) {
        errors.hospital = "Rumah sakit tujuan tidak boleh kosong!";
    }
    if (!str) {
        errors.str = "Nomor STR tidak boleh kosong!";
    }
    if (!berkas) {
        errors.berkas = "Surat permohonan tertulis tidak boleh kosong!";
    }
    if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
        errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
    }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
        errors.berkas = "File lebih dari 500KB"
    }
    if (!file || !file.length) {
        errors.file = {
            _error: "Berkas tambahan tidak boleh kosong"
        }
    } else {
        file.forEach((item, index) => {
            if (!item) {
                fileArrayErrors[index] = "Berkas tidak boleh kosong"
            }
        })
    }
    if (fileArrayErrors.length) {
        errors.file = fileArrayErrors;
    }
    return errors;
}

SIPModalAdd = reduxForm({
    form: "ModaladdSIP",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(SIPModalAdd);

function mapStateToProps({ addSIP, memberList, memberP2kbExisting }) {
    return {
        addSIP_pending: getSIPAddPending(addSIP),
        addSIP_data: getSIPAdd(addSIP),
        addSIP_error: getSIPAddError(addSIP),
        memberList_data: getMemberList(memberList),
        memberP2kbExisting_pending: getMemberP2kbExistingPending(memberP2kbExisting),
        memberP2kbExisting_data: getMemberP2kbExisting(memberP2kbExisting),
    };
}

export default connect(mapStateToProps, {
    addSIP, memberList, memberP2kbExisting
})(withRouter(SIPModalAdd));
