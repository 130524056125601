import React, { Component } from "react";
import { detailSupport } from "../../actions/support/support_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import {
  getSupportPending,
  getSupportError,
  getDetailSupport,
  getSupportIsEditFile,
} from "../../reducers/support/support_reducer";
import { connect } from "react-redux";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    this.tableRef = React.createRef();
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true })
    } else {
      this.setState({ modalDocumentImgShow: true })
    }
  }

  createMarkup(html) {
    return { __html: html };
  }

  // componentDidMount() {
  //   const {
  //     match: {
  //       params: { id },
  //     },
  //     detailSupport,
  //   } = this.props;
  //   detailSupport(id);
  // }
  render() {
    const {
      detailSupport_data,
    } = this.props;
    let description,
      filename,
      file_handling,
      title,
      types,
      ticket_no,
      member_name,
      status,
      level;

    if (detailSupport_data && detailSupport_data.length !== 0) {
      [
        {
          description,
          filename,
          title,
          file_handling,
          ticket_no,
          level,
          types,
          status,
          report_member_data: { name: member_name = "-" } = {},
        },
      ] = detailSupport_data;
    }

    return (
      <>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_support"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_support"
        />
        <DetailContentComponent label="Judul Pengajuan" value={title} />
        <DetailContentComponent label="Jenis Pengajuan" value={types === 1 ? 'Teknis' : 'Profesional'} />
        <DetailContentComponent
          label="Nomor Trouble Ticket"
          value={ticket_no}
        />
        <FormContainer
          label={
            <div style={{ height: "100%", paddingTop: 20 }}>Deskripsi</div>
          }
        >
          <div
            className="support"
            dangerouslySetInnerHTML={this.createMarkup(description)}
          />
        </FormContainer>
        <DetailContentComponent
          label="Member yang Dilaporkan"
          value={member_name}
        />
        <DetailContentComponent label="Berkas Pendukung" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Berkas Support")} />} />
        {status === 2 && (
          <DetailContentComponent label="Berkas Penanganan" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(file_handling, "Berkas Penanganan")} />} />
        )}
        <DetailContentComponent
          label="Status Penanganan"
          style={{
            color:
              level === 1
                ? "yellow"
                : level === 2
                  ? "orange"
                  : level === 3
                    ? "red"
                    : "#212529",
          }}
          value={
            level === 1
              ? "Ringan"
              : level === 2
                ? "Sedang"
                : level === 3
                  ? "Berat"
                  : "-"
          }
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { editSupport, detailSupport } = state;

  return {
    editSupport_error: getSupportError(editSupport),
    editSupport_pending: getSupportPending(editSupport),
    detailSupport_pending: getSupportPending(detailSupport),
    detailSupport_data: getDetailSupport(detailSupport),
    editSupport_isEditFile: getSupportIsEditFile(editSupport),
  };
}

export default connect(mapStateToProps, {
  detailSupport,
})(withRouter(SupportDetail));
