import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import DatePicker from "react-date-picker";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { p2kbList, approvalAllP2kb } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
  getP2kbApprovalAllPending,
  getP2kbApprovalAll,
} from "../../reducers/p2kb/p2kb_reducer";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import MenuItem from "@material-ui/core/MenuItem";
import ExportButton from "../../assets/icon-document-p2kb";
import LaunchIcon from "../../assets/icon-launch";
import ModalDetailDokumenSKP from "./Modal_document_pdf";
import ModalDetailDokumen from "./Modal_document";
import ModalAcceptSkp from "./Modal_accept_skp";
import { userList } from "../../actions/master-data/user_action";
import {
  getUserListPending,
  getUserList,
  getUserListError,
} from "../../reducers/master-data/user_reducer";
import { getUser } from "../../helpers/user";
import ModalDocumentNotfound from "./Modal_document_notfound";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import DetailRejectModal from "../../components/modal/DetailRejectModalP2kb";
import moment from "moment";
import { detailP2kb, dateP2kb } from "../../actions/p2kb/p2kb_action";
import { getDetailP2kb, getDateP2kb } from "../../reducers/p2kb/p2kb_reducer";

class HeaderDetailP2KBAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      modalDocumentNotfoundShow: false,
      modalDetailRejectSKPShow: false,
      modalAcceptSkp: false,
      user_img: null,
      user_ktp: null,
      user_medical: null,
      user_name: null,
      user_str: null,
      is_done: null,
      is_str_done: null,
      folder: null,
      start_date: localStorage.getItem("start_date")
        ? localStorage.getItem("start_date")
        : "",
      end_date: localStorage.getItem("end_date")
        ? localStorage.getItem("end_date")
        : moment().format("YYYY-MM-DD"),
    };
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);

    localStorage.setItem("end_date", moment().format("YYYY-MM-DD"));
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    return handleRefresh(value);
  }, 500);

  handleChangeSearch(e) {
    if (e !== null) {
      this.delayedQuery({ search: e.target.value });
    } else {
      this.delayedQuery({ search: "" });
    }
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ folder: "member_supporting_files" });
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl !== null) {
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDetailDokumenSKPShow: true });
      } else {
        this.setState({ modalDetailDokumenShow: true });
      }
    } else {
      this.setState({ modalDocumentNotfoundShow: true });
    }
  }

  handleOpenDocumentSTR(imgUrl, modal_title) {
    this.setState({ folder: "member_str" });
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl !== null) {
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDetailDokumenSKPShow: true });
      } else {
        this.setState({ modalDetailDokumenShow: true });
      }
    } else {
      this.setState({ modalDocumentNotfoundShow: true });
    }
  }

  handleChangeStart(value) {
    const old = value;
    value = moment(value).format("YYYY-MM-DD");
    this.setState({
      start_date: old,
    });
    localStorage.setItem("start_date", value);
  }

  handleChangeEnd(value) {
    const old = value;
    value = moment(value).format("YYYY-MM-DD");
    this.setState({
      end_date: old,
    });
    localStorage.setItem("end_date", value);
  }

  handleChangeStatus(e) {
    if (e !== null) {
      this.props.handleRefresh({ status: e.value });
    } else {
      this.props.handleRefresh({ status: "" });
    }
  }

  handleChangeActivity(e) {
    if (e !== null) {
      this.props.handleRefresh({ activity_type: e.value });
    } else {
      this.props.handleRefresh({ activity_type: "" });
    }
  }

  handleCallback(start, end) {
    this.props.handleRefresh({
      start_date: start?.format("YYYY-MM-DD"),
      end_date: end?.format("YYYY-MM-DD"),
    });
    localStorage.setItem("start_date", start?.format("YYYY-MM-DD"));
    localStorage.setItem("end_date", end?.format("YYYY-MM-DD"));
  }

  handleSubmit() {
    this.setState({
      modalAcceptSkp: true,
    });
  }
  handleShowRevision() {
    // this.setState({ revisionImage: data.last_reject_filename });
    // this.setState({ revisionReason: data.last_reject_notes });
    this.setState({ modalDetailRejectSKPShow: true });
  }

  componentDidUpdate() {
    let isSerkom,
      isSKP = false;
    if (window.location.href.includes("admin-p2kb")) {
      isSKP = true;
    } else if (window.location.href.includes("serkom")) {
      isSerkom = true;
    }
    const user_id = this.props.user_id;
    if (user_id && (isSerkom || isSKP)) {
      if (this.state.is_done === null) {
        let hasSetFile = false;
        const callback = () => {
          const data = this.props.userList_data.data[0];
          if (data) {
            this.setState({
              user_img: data.member_profile.additional_file_image,
            });
            this.setState({ user_ktp: data.member_profile.scan_ktp_image });
            this.setState({
              user_medical: data.member_profile.health_information_image,
            });
            this.setState({ user_name: data.name });
            hasSetFile = true;
          }
        };
        this.props.userList({ id: user_id }, callback);
        this.setState({ is_done: true });
        // const callbacks = () => {
        //     const data = this.props.listStr_data.data;
        //     let strApproved = data.filter(item => item.status === 1);
        //     if (strApproved.length > 0) {
        //         this.setState({ user_str: strApproved[0].filename });
        //     }
        //     if (!hasSetFile) {
        //         const data2 = data[0];
        //         this.setState({ user_img: data2.user_data.member_profile.additional_file_image });
        //         this.setState({ user_ktp: data2.user_data.member_profile.scan_ktp_image });
        //         this.setState({ user_medical: data2.user_data.member_profile.health_information_image });
        //         this.setState({ user_name: data2.user_data.name });
        //     }
        // }
        // this.props.listStr({ user_id: user_id }, callbacks);
      }
      if (isSKP && this.state.is_str_done === null) {
        const callback = () => {
          const data = this.props.listStr_data.data;
          let strApproved = data.filter((item) => item.status === 1);
          if (strApproved.length > 0) {
            this.setState({ user_str: strApproved[0].filename });
          }
        };
        this.props.listStr({ user_id: user_id }, callback);

        this.setState({ is_str_done: true });
      }
    }
  }

  componentDidMount() {
    const user_id = this.props.user_id;
    const callbackFilter = () => {
      const dateFilter = moment(this.props.dateP2kb_data.start_date).format(
        "YYYY-MM-DD"
      );
      this.setState({ start_date: dateFilter });
      localStorage.setItem("start_date", dateFilter);
    };
    this.props.dateP2kb({ isFindDate: true, user_id: user_id }, callbackFilter);
  }

  render() {
    const {
      isAdminSKP,
      isSerkom,
      isFirstLayer,
      status,
      submit_status,
      last_reject_notes,
      last_reject_filename,
      role_next_approve,
    } = this.props;
    const { modalDetailRejectSKPShow } = this.state;
    const activityOption = [
      { value: 1, label: "Internal" },
      { value: 2, label: "Eksternal" },
      { value: 3, label: "Pribadi" },
    ];
    const statusOption = [
      { value: 0, label: "Menunggu" },
      { value: 1, label: "Approved" },
      { value: 2, label: "Ditolak" },
    ];
    return (
      <Row
        style={{
          padding: "10px 0",
          backgroundColor: "#FFFFFF",
          alignItems: "center",
        }}
        horizontal={isAdminSKP ? "space-between" : "space-between"}
      >
        {isFirstLayer ? (
          <>
            <ModalDetailDokumenSKP
              show={this.state.modalDetailDokumenSKPShow}
              onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
              imgUrl={this.state.imgUrl}
              modal_title={this.state.modal_title}
              folder={this.state.folder}
            />
            <ModalDetailDokumen
              show={this.state.modalDetailDokumenShow}
              onHide={() => this.setState({ modalDetailDokumenShow: false })}
              imgUrl={this.state.imgUrl}
              modal_title={this.state.modal_title}
              folder={this.state.folder}
            />

            <ModalDocumentNotfound
              show={this.state.modalDocumentNotfoundShow}
              onHide={() => this.setState({ modalDocumentNotfoundShow: false })}
              imgUrl={this.state.imgUrl}
              modal_title={this.state.modal_title}
              member_name={this.state.user_name}
            />
            <DetailRejectModal
              show={modalDetailRejectSKPShow}
              onHide={() => this.setState({ modalDetailRejectSKPShow: false })}
              reason={last_reject_notes}
              filepath="member_p2kb"
              filename={last_reject_filename}
            />
            {(getUser().role_codes[0] === "root" ||
              getUser().role_codes[0] === "admin_kolegium" ||
              getUser().role_codes[0] === "ketua_kolegium") &&
              isSerkom && (
                <Row style={{ marginRight: "20px" }}>
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHoverRoot)}
                      >
                        <ExportButton fill="#626568" />
                        <span className={css(styles.exportButton)}>
                          Berkas Pendukung
                        </span>
                      </Row>
                    )}
                    horizontal="left"
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(this.state.user_img, "Pas Foto")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>Pas Foto</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(this.state.user_ktp, "KTP")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>Scan KTP</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(
                          this.state.user_medical,
                          "Surat Keterangan Kesehatan"
                        )
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>
                        Surat Ket. Kesehatan
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocumentSTR(this.state.user_str, "STR")
                      }
                    >
                      <LaunchIcon />{" "}
                      <span className={css(styles.launchButton)}>STR</span>
                    </MenuItem>
                  </DetailButtonComponent>
                </Row>
              )}
            {isAdminSKP && (
              <Row>
                {getUser().role_codes[0] !== "ketua_paboi_pusat" && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={css(styles.approveButton)}
                    startIcon={<CheckIcon />}
                    onClick={() => this.handleSubmit()}
                    disabled={
                      status === 2 ||
                      status === 1 ||
                      status === null ||
                      role_next_approve?.role_code !== getUser().role_codes[0]
                    }
                  >
                    {getUser().role_codes[0].includes("ketua") ||
                    getUser().role_codes[0].includes("cpd")
                      ? "Setujui"
                      : "Setujui"}
                  </Button>
                )}
                {status === 2 && (
                  <Button
                    type="submit"
                    color="secondary"
                    startIcon={<ErrorOutlineOutlinedIcon />}
                    onClick={() => this.handleShowRevision()}
                    className={css(styles.rejectButton)}
                  >
                    Alasan Penolakan
                  </Button>
                )}
                <DetailButtonComponent
                  button={(props) => (
                    <Row
                      onClick={props.onClick}
                      className={
                        getUser().role_codes[0] === "ketua_paboi_pusat"
                          ? css(styles.buttonDocHoverRoot)
                          : css(styles.buttonDocHover)
                      }
                    >
                      <ExportButton fill="#626568" />
                      <span className={css(styles.exportButton)}>
                        Berkas Pendukung
                      </span>
                    </Row>
                  )}
                  horizontal="left"
                >
                  <MenuItem
                    onClick={() =>
                      this.handleOpenDocument(this.state.user_img, "Pas Foto")
                    }
                  >
                    <LaunchIcon />{" "}
                    <span className={css(styles.launchButton)}>Pas Foto</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.handleOpenDocument(this.state.user_ktp, "KTP")
                    }
                  >
                    <LaunchIcon />{" "}
                    <span className={css(styles.launchButton)}>Scan KTP</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.handleOpenDocument(
                        this.state.user_medical,
                        "Surat Keterangan Kesehatan"
                      )
                    }
                  >
                    <LaunchIcon />{" "}
                    <span className={css(styles.launchButton)}>
                      Surat Ket. Kesehatan
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.handleOpenDocumentSTR(this.state.user_str, "STR")
                    }
                  >
                    <LaunchIcon />{" "}
                    <span className={css(styles.launchButton)}>STR</span>
                  </MenuItem>
                </DetailButtonComponent>
                <ModalAcceptSkp
                  show={this.state.modalAcceptSkp}
                  handleRefresh={this.props.handleRefresh}
                  onHide={() => this.setState({ modalAcceptSkp: false })}
                  id={this.props.user_id}
                />
              </Row>
            )}
          </>
        ) : (
          <Row></Row>
        )}

        {isFirstLayer && (
          <Row style={{ marginRight: "10px" }}>
            {this.state.start_date !== "" && this.state.end_date !== "" ? (
              <>
                <span className={css(styles.filterLabel)}>Tanggal Awal</span>
                <DatePicker
                  className={css(styles.filterDate)}
                  onChange={this.handleChangeStart}
                  value={
                    this.state.start_date === ""
                      ? this.state.start_date
                      : new Date(this.state.start_date)
                  }
                />
                <span className={css(styles.filterLabel)}>Tanggal Akhir</span>
                <DatePicker
                  className={css(styles.filterDate)}
                  onChange={this.handleChangeEnd}
                  value={
                    this.state.end_date === ""
                      ? this.state.end_date
                      : new Date(this.state.end_date)
                  }
                />
              </>
            ) : (
              <>
                <span className={css(styles.filterLabel)}>Tanggal Awal</span>
                <DatePicker
                  className={css(styles.filterDate)}
                  onChange={this.handleChangeStart}
                  value={
                    this.state.start_date === ""
                      ? this.state.start_date
                      : new Date(this.state.start_date)
                  }
                />
                <span className={css(styles.filterLabel)}>Tanggal Akhir</span>
                <DatePicker
                  className={css(styles.filterDate)}
                  onChange={this.handleChangeEnd}
                  value={
                    this.state.end_date === ""
                      ? this.state.end_date
                      : new Date(this.state.end_date)
                  }
                />
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              onClick={() =>
                this.props.handleRefresh({
                  start_date: localStorage.getItem("start_date"),
                  end_date: localStorage.getItem("end_date"),
                })
              }
            >
              FILTER
            </Button>
          </Row>
        )}
      </Row>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginLeft: 5,
    marginRight: 5,
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    marginLeft: 5,
  },
  filterLabel: {
    margin: "auto 5px",
    fontSize: 11,
  },
  container: {
    width: 150,
    margin: 10,
  },
  buttonDocHover: {
    padding: "10px 15px",
    backgroundColor: "rgb(239, 240, 242)",
    //marginLeft: 10,
    borderRadius: 5,
    ":hover": {
      cursor: "pointer",
    },
  },
  buttonDocHoverRoot: {
    padding: "10px 15px",
    backgroundColor: "rgb(239, 240, 242)",
    marginLeft: 10,
    borderRadius: 5,
    ":hover": {
      cursor: "pointer",
    },
  },
  exportButton: {
    alignItems: "center",
    display: "flex",
    marginLeft: 10,
    color: "#626568",
  },
  launchButton: {
    alignItems: "center",
    display: "flex",
    paddingTop: 2.5,
    marginLeft: 5,
  },
  filterDate: {
    marginRight: 5,
  },
});

function mapStateToProps({
  p2kbList,
  userList,
  approvalAllP2kb,
  listStr,
  detailP2kb,
  dateP2kb,
}) {
  return {
    P2kbList_pending: getP2kbListPending(p2kbList),
    P2kbList_data: getP2kbList(p2kbList),
    P2kbList_error: getP2kbListError(p2kbList),
    approveAll_pending: getP2kbApprovalAllPending(approvalAllP2kb),
    approveAll_data: getP2kbApprovalAll(approvalAllP2kb),
    userList_pending: getUserListPending(userList),
    userList_data: getUserList(userList),
    userList_error: getUserListError(userList),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    detailP2kb_data: getDetailP2kb(detailP2kb),
    dateP2kb_data: getDateP2kb(dateP2kb),
    //detailP2kb_pending: getP2kbPending(detailP2kb),
  };
}

export default connect(mapStateToProps, {
  p2kbList,
  userList,
  approvalAllP2kb,
  listStr,
  detailP2kb,
  dateP2kb,
})(withRouter(HeaderDetailP2KBAdmin));
