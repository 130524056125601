import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_SIP_PENDING = "LIST_SIP_PENDING";
export const LIST_SIP_SUCCESS = "LIST_SIP_SUCCESS";
export const LIST_SIP_ERROR = "LIST_SIP_ERROR";

export const DETAIL_SIP_PENDING = "DETAIL_SIP_PENDING";
export const DETAIL_SIP_SUCCESS = "DETAIL_SIP_SUCCESS";
export const DETAIL_SIP_ERROR = "DETAIL_SIP_ERROR";

export const ADD_SIP_PENDING = "ADD_SIP_PENDING";
export const ADD_SIP_SUCCESS = "ADD_SIP_SUCCESS";
export const ADD_SIP_ERROR = "ADD_SIP_ERROR";

export const EDIT_SIP_PENDING = "EDIT_SIP_PENDING";
export const EDIT_SIP_SUCCESS = "EDIT_SIP_SUCCESS";
export const EDIT_SIP_ERROR = "EDIT_SIP_ERROR";

export const DELETE_SIP_PENDING = "DELETE_SIP_PENDING";
export const DELETE_SIP_SUCCESS = "DELETE_SIP_SUCCESS";
export const DELETE_SIP_ERROR = "DELETE_SIP_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_SIP";
export const APPROVE_SIP_PENDING = "APPROVE_SIP_PENDING";
export const APPROVE_SIP_SUCCESS = "APPROVE_SIP_SUCCESS";
export const APPROVE_SIP_ERROR = "APPROVE_SIP_ERROR";

export const CHANGE_SIP_TYPE_PENDING = "CHANGE_SIP_TYPE_PENDING"
export const CHANGE_SIP_TYPE_SUCCESS = "CHANGE_SIP_TYPE_SUCCESS"
export const CHANGE_SIP_TYPE_ERROR = "CHANGE_SIP_TYPE_ERROR"

const LIST_SIP_URL = "member/sip/find";
const EDIT_SIP_URL = "member/sip/edit";
const DELETE_SIP_URL = "member/sip/delete";
const ADD_SIP_URL = "member/sip/add";
const APPROVE_SIP_URL = "member/sip/approve";
const UPLOAD_SIP_URL = "image/upload/member_sip";
const CHANGE_SIP_TYPE_URL = "member/sip/change_type";


export function listSip(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SIP_PENDING));
    API.post(LIST_SIP_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SIP_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_SIP_ERROR, error));
        toastError(error, "Gagal mendapatkan list SIP, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addSip({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SIP_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_SIP_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        formData.append("sip_no", restParam.sip_no);
        formData.append("start_date", restParam.start_date);
        formData.append("end_date", restParam.end_date);
        formData.append("hospital_id", restParam.hospital_id);
        formData.append("member_practice_place_id", restParam.member_practice_place_id);
        return API.post(ADD_SIP_URL, formData, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan SIP");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SIP_ERROR, error));
        toastError(error, "Gagal menambahkan SIP, ");
      });
  };
}

export function detailSip(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SIP_PENDING));
    API.post(LIST_SIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SIP_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SIP_ERROR, error));
        toastError(error, "Gagal mendapatkan list SIP, ");
      });
  };
}

export function editSip(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SIP_PENDING));
    API.post(EDIT_SIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah SIP");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SIP_ERROR, error));
        toastError(error, "Gagal mengubah SIP, ");
      });
  };
}

export function deleteSip(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_SIP_PENDING));
    API.post(DELETE_SIP_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus SIP");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_SIP_ERROR, error));
        toastError(error, "Gagal menghapus SIP, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveSip(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_SIP_PENDING));
    API.post(APPROVE_SIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_SIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status SIP");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_SIP_ERROR, error));
        toastError(error, "Gagal mengubah status SIP, ");
      });
  };
}

export function changeSipType(param = {}, callback) {
  return dispatch => {
    dispatch(actionPending(CHANGE_SIP_TYPE_PENDING));
    API.post(CHANGE_SIP_TYPE_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(CHANGE_SIP_TYPE_SUCCESS, res.datadata));
        toastSuccess("Berhasil mengubah jenis SIP")
        if (callback) {
          callback();
        }
        return res;
      })
      .catch(error => {
        dispatch(actionError(CHANGE_SIP_TYPE_ERROR, error));
        toastError(error, "Gagal mengubah jenis SIP, ")
      })
  };
}
