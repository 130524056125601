import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailSip,
  editSip,
  setIsEditFile,
} from "../../actions/p2kb/sip_action";
import {
  detailPractice,
} from "../../actions/sub-menu/practice_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getSipPending,
  getDetailSip,
  getSipError,
  getSipIsEditFile,
} from "../../reducers/p2kb/sip_reducer";
import { connect } from "react-redux";
import {
  getPracticePending,
  getDetailPractice,
  getPracticeError,
} from "../../reducers/sub-menu/practice_reducer";
import { reduxForm, Field, formValueSelector, FieldArray } from "redux-form";
import {
  renderInput,
  renderDate,
  renderFile,
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import SipModalApprove from "../sip-admin/SipModalApprove";
import SipModalReject from "../sip-admin/SipModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import RenderArray from "./PracticeForm";
import localization from "moment/locale/id";

class SipDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailSip, detailPractice } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailSip(id);
    detailPractice();

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editSip_isEditFile, setIsEditFile } = this.props;
    if (editSip_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editSip_isEditFile);
  }

  handleSubmit(values) {
    const { detailSip_data, editSip, detailSip } = this.props;
    const { sip_no, start_date, end_date, berkas } = values;

    let formData = new FormData();
    formData.append("id", detailSip_data[0].id);
    formData.append("sip_no", sip_no);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    if (berkas) {
      formData.append("file", berkas);
    }

    const callback = () => {
      detailSip();
      this.handleTriggerFormEdit();
    };
    editSip(formData, callback);
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailSip_data,
      editSip_pending,
      editSip_isEditFile,
      handleSubmit,
      formValue
    } = this.props;
    const { isEdit, showApproveModal, showRejectModal } = this.state;
    let name, member_branch, filename, status, reject_notes, sip_role_code, reject_filename, activation_notes, deactivation_notes, sip_type;

    if (detailSip_data && detailSip_data.length !== 0) {
      name = detailSip_data[0].user_data.name;
      member_branch = detailSip_data[0].user_data.branch_id;
      filename = detailSip_data[0].filename;
      status = detailSip_data[0].status;
      reject_notes = detailSip_data[0].reject_notes;
      reject_filename = detailSip_data[0].reject_filename;
      sip_role_code = detailSip_data[0].role_next_approve
        ? detailSip_data[0].role_next_approve.role_code
        : null;
      activation_notes = detailSip_data[0].activation_notes;
      deactivation_notes = detailSip_data[0].deactivation_notes;
      sip_type = detailSip_data[0].sip_type;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    function checkStatus() {
      if (status === 0) return "Baru"
      
      if (status === 1) {
        if (sip_type === 1) return "Terverifikasi - Aktif"
        if (sip_type === 0) return "Terverifikasi - Nonaktif"
        
        return "Terverifikasi"
      }
      
      return "Ditolak"
    }

    let catatan = ""
    if (checkStatus().includes('- Aktif')) catatan = activation_notes
    if (checkStatus().includes('- Nonaktif')) catatan = deactivation_notes

    return (
      <ContentContainer>
        <SipModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <SipModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data SIP Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_sip"
            />
            {getUser().role_codes[0] === sip_role_code && getUser().branch_id === member_branch && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi SIP" />
                {status === 2 &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editSip_pending}
                  />
                }
              </Row>
              <FormContainer label="No SIP">
                <Field
                  isDetail={!isEdit}
                  name="sip_no"
                  placeholder="Masukkan no SIP"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Nama Rumah Sakit">
                <Field
                  isDetail={!isEdit}
                  name="hospital_name"
                  placeholder="Masukkan no SIP"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer
                label="Jam Praktik"
                contentValueStyle={{ margin: "20px 0" }}
              >
                <FieldArray
                  isDetail={!isEdit}
                  name="schedule"
                  placeholder="Masukkan Jadwal Baru"
                  component={RenderArray}
                  small
                />
              </FormContainer>
              {!isEdit ?
                <DetailContentComponent
                  label="Tanggal Penetapan"
                  value={formValue.start_date ? moment(formValue.start_date).locale("id", localization).format("DD MMMM YYYY") : null}
                />
                :
                <FormContainer label="Tanggal Penetapan">
                  <Field
                    isDetail={!isEdit}
                    name="start_date"
                    placeholder="Masukkan tanggal penetapan"
                    component={renderDate}
                  />
                </FormContainer>
              }
              {!isEdit ?
                <DetailContentComponent
                  label="Tanggal Berakhir"
                  value={formValue.end_date ? moment(formValue.end_date).locale("id", localization).format("DD MMMM YYYY") : null}
                />
                :
                <FormContainer label="Tanggal Berakhir">
                  <Field
                    isDetail={!isEdit}
                    name="end_date"
                    placeholder="Masukkan tanggal berakhir"
                    component={renderDate}
                  />
                </FormContainer>
              }
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editSip_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_sip"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : status === 2
                        ? "Ditolak"
                        : "-"
                }
              />
              {/* catatan */}
              <DetailContentComponent
                label="Catatan"
                value={catatan}
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}
const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});
function validate(values, props) {
  const { editSip_isEditFile } = props;
  const { institution, sip_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!sip_type) {
    errors.sip_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editSip_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

SipDetail = reduxForm({
  // a unique name for the form
  form: "sipEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SipDetail);

function mapStateToProps(state) {
  const day = [
    "0",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];
  const { detailSip, editSip, detailPractice } = state;
  const selector = formValueSelector("sipEdit")
  const formValue = selector(state, "start_date", "end_date");
  let initialValues = {};
  let jadwal = [];
  const detailSip_data = getDetailSip(detailSip);
  const detailPractice_data = getDetailPractice(detailPractice);
  if (detailSip_data && detailSip_data.length !== 0) {
    if (detailSip_data[0].member_practice_place_days.lenght !== 0 ){
      jadwal = detailSip_data[0].member_practice_place_days
    }
    initialValues = {
      sip_no: detailSip_data[0].sip_no,
      start_date: detailSip_data[0].start_date,
      end_date: detailSip_data[0].end_date,
      hospital_name: detailSip_data[0]?.hospital_data?.name,
      schedule : jadwal.map(
        (item) => ({
                  day: { value: item.day_code, label: day[item.day_code] },
                  time_start: item.start_time,
                  // format="mm:ss";
                  time_end: item.end_time,
                })
      )
    };
    // if (detailPractice_data && detailPractice_data.length !== 0) {
    //   const idx = detailPractice_data.map(function (e) { return e.id; }).indexOf(detailSip_data[0].member_practice_place_id);
    //   initialValues = {
    //     ...initialValues,
    //     schedule: detailPractice_data[idx].member_practice_place_days.map(
    //       (item) => ({
    //         day: { value: item.day_code, label: day[item.day_code] },
    //         time_start: item.start_time,
    //         // format="mm:ss";
    //         time_end: item.end_time,
    //       })
    //     ),
    //   }
    // }
  }

  return {
    detailPractice_data: getDetailPractice(detailPractice),
    detailPractice_error: getPracticeError(detailPractice),
    detailPractice_pending: getPracticePending(detailPractice),
    detailSip_data: getDetailSip(detailSip),
    detailSip_error: getSipError(detailSip),
    detailSip_pending: getSipPending(detailSip),
    editSip_error: getSipError(editSip),
    editSip_pending: getSipPending(editSip),
    editSip_isEditFile: getSipIsEditFile(editSip),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailSip,
  detailPractice,
  editSip,
  setIsEditFile,
})(withRouter(SipDetail));
