import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalAddDetailSKP_Pelatihan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenSKP: false,
            berkas_sertifikat: null,
            berkas_sertifikat_button: false,
            isDoneUploading: true,
            imgUrl: "",
            modal_title: "",
            selected_activity: null,
            selected_scale: null,
            selected_peran: null,
            selected_duration: null,
            spek_berkas_sertifikat: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeScale = this.handleChangeScale.bind(this);
        this.handleChangePeran = this.handleChangePeran.bind(this);
        this.handleChangeDuration = this.handleChangeDuration.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleChangeScale(values) {
        let activity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        this.setState({ selected_activity: activity_selected })
        let scale_selected = activity_selected.childrens.find(item => item.id === values.value);
        this.setState({ selected_scale: scale_selected })
        this.props.change('last_activity_skp_detail_id', null);
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangePeran(values) {
        let activity_selected = this.props.listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        let scale_selected = activity_selected.childrens.find(item => item.id === this.props.formValue.pertemuan.value);
        let peran_selected = scale_selected.childrens.find(item => item.id === values.value);
        this.setState({ selected_peran: peran_selected })
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangeDuration(values) {
        let duration_selected = this.state.selected_peran.values.find(item => item.id === values.value);
        this.setState({ selected_duration: duration_selected })
        let skp_must_be_multipied = duration_selected.skp_must_be_multipied;
        let value_multipied = duration_selected.value_multipied;
        if (skp_must_be_multipied === 0) {
            this.props.change('skp_total', duration_selected.value_skp);
        } else {
            this.props.change('skp_total', Math.round(duration_selected.value_skp * value_multipied));
        }
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sertifikat") {
        //     this.setState({ berkas_sertifikat: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas_sertifikat", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_sertifikat_button: true })
        if (formName === "berkas_sertifikat") {
            this.setState({ berkas_sertifikat: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sertifikat_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas_sertifikat") {
                    this.props.change("berkas_sertifikat", this.props.uploadP2kb_data);
                    this.setState({ berkas_sertifikat: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas_sertifikat: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas_sertifikat, "Bukti Sertifikat")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_sertifikat_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas_sertifikat") {
                    this.props.change("berkas_sertifikat", null);
                    this.setState({ berkas_sertifikat: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        this.props.change("spek_berkas_sertifikat", values.target.files[0]);
        // } else {
        //     if (formName === "berkas_sertifikat") {
        //         this.setState({ berkas_sertifikat: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        let details_tobesent = {
            activity_date: values.activity_date,
            last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
            values_id: values.values_id.value,
            total_skp: values.skp_total,
            activity_name: values.activity_name,
            organizer: values.organizer,
            place_of_organization: values.place_of_organization,
            sk_idi: values.sk_idi,
            city_name: values.city_name,
            title_of_paper: values.title_of_paper,
            total_skp_eksternal: values.total_skp_eksternal,
            mst_activity_skp_type_detail_data: {
                name: values.last_activity_skp_detail_id.label,
                parent: {
                    name: values.pertemuan.label
                }
            },

            attachments: [
                {
                    label: "Sertifikat Sebagai Pembicara",
                    filename: values.berkas_sertifikat
                }
            ]
        }
        let details_tobeshown = {
            ...details_tobesent,
            skp: values.skp_total,
            skala_pertemuan: values.pertemuan.label,
            last_activity_skp_detail_id_label: values.last_activity_skp_detail_id.label,
            pertemuan_label: values.pertemuan.label,
            pertemuan_value: values.pertemuan.value,
            values_id_label: values.values_id.label,
        }
        this.props.handleAddDetail(details_tobeshown, details_tobesent);
        this.props.onHide();
    }

    componentDidMount() {
        this.props.listActivity({ length: 999 });
    }

    render() {
        const { show,
            onHide,
            handleSubmit,
            uploadP2kb_pending,
            listActivity_data
        } = this.props;
        const { tempSKPData,
            selected_scale,
            selected_peran
        } = this.state;
        let scaleOptions, peranOptions, durationOptions = [];
        if (listActivity_data) {
            let listActivity_selected = listActivity_data.data.find(item => item.id === tempSKPData.activity.value);
            scaleOptions = listActivity_selected.childrens.map((item) => ({
                label: item.name,
                value: item.id
            }));
            if (selected_scale !== null) {
                peranOptions = selected_scale.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            }
            if (selected_peran !== null) {
                durationOptions = selected_peran.values.map((item) => ({
                    label: item.label,
                    value: item.id
                }));
            }
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="activity_name"
                                    placeholder="Masukkan Nama Kegiatan"
                                    label="Nama Kegiatan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="pertemuan"
                                    placeholder="Masukkan Skala Pertemuan"
                                    label="Skala Pertemuan"
                                    options={scaleOptions}
                                    onChange={this.handleChangeScale}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="last_activity_skp_detail_id"
                                    placeholder="Masukkan Peran"
                                    label="Peran"
                                    options={peranOptions}
                                    onChange={this.handleChangePeran}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="values_id"
                                    placeholder="Masukkan Durasi"
                                    label="Durasi"
                                    options={durationOptions}
                                    onChange={this.handleChangeDuration}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="organizer"
                                    placeholder="Masukkan Nama Penyelenggara"
                                    label="Penyelenggara"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="sk_idi"
                                    placeholder="Masukkan No SK IDI"
                                    label="No SK IDI"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="title_of_paper"
                                    placeholder="Masukkan Judul Makalah"
                                    label="Judul Makalah"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="place_of_organization"
                                    placeholder="Masukkan Tempat Penyelenggaraan"
                                    label="Tempat Penyelenggaraan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="city_name"
                                    placeholder="Masukkan Nama Kota"
                                    label="Kota"
                                    component={renderInputWithLabel}
                                />

                                <Field
                                    name="skp_total"
                                    placeholder="Jumlah SKP 1"
                                    label="Jumlah SKP 1"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="total_skp_eksternal"
                                    placeholder="Jumlah SKP 2 (Optional)"
                                    label="Jumlah SKP 2 (Diisi jika SKP 1 tidak sesuai sertifikat)"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="berkas_sertifikat"
                                    type="file"
                                    fileType="skp"
                                    title="Bukti Sertifikat"
                                    disabled={this.state.berkas_sertifikat_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas_sertifikat === undefined ? "Image atau PDF maksimal 1 MB" : this.state.berkas_sertifikat === null ? "Image atau PDF maksimal 1 MB" : this.state.berkas_sertifikat}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { activity_date, activity_name, pertemuan, last_activity_skp_detail_id, values_id, organizer,
        sk_idi, title_of_paper, place_of_organization, city_name, berkas_sertifikat, spek_berkas_sertifikat } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!activity_name) {
        errors.activity_name = "Nama kegiatan wajib diisi"
    }
    if (!pertemuan) {
        errors.pertemuan = "Skala pertemuan wajib diisi"
    }
    if (!last_activity_skp_detail_id) {
        errors.last_activity_skp_detail_id = "Peran wajib diisi"
    }
    if (!values_id) {
        errors.values_id = "Durasi wajib diisi"
    }
    if (!organizer) {
        errors.organizer = "Nama penyelenggara wajib diisi"
    }
    if (!sk_idi) {
        errors.sk_idi = "NPA IDI wajib diisi"
    }
    if (!title_of_paper) {
        errors.title_of_paper = "Judul makalah wajib diisi"
    }
    if (!place_of_organization) {
        errors.place_of_organization = "Tempat penyelenggaraan wajib diisi"
    }
    if (!city_name) {
        errors.city_name = "Kota wajib diisi"
    }
    if (!berkas_sertifikat) {
        errors.berkas_sertifikat = "Berkas sertifikat wajib diisi"
    }
    else{
        if (spek_berkas_sertifikat && (!spek_berkas_sertifikat.type.match(typeImage) && spek_berkas_sertifikat.type !== fileType)){
          errors.berkas_sertifikat = "Format file hanya bisa Image atau PDF"
        }
        else if (spek_berkas_sertifikat && (spek_berkas_sertifikat.size > maxFileSize)){
          errors.berkas_sertifikat = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_Pelatihan'));

ModalAddDetailSKP_Pelatihan = reduxForm({
    form: "ModalAddDetailSKP_Pelatihan",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalAddDetailSKP_Pelatihan)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb } = state;
    const selector = formValueSelector("ModalAddDetailSKP_Pelatihan");
    const formValue = selector(state, "skp_total", "sk_idi", "pertemuan", "last_activity_skp_detail_id", "values_id", "berkas_sertifikat", "spek_berkas_sertifikat")
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        formValue
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb })(withRouter(ModalAddDetailSKP_Pelatihan));