import React, { Component } from "react";
import { connect } from "react-redux";
import { listTransfer } from "../../actions/report/report_action";
import {
  getListTransfer,
  getTransferError,
  getTransferPending,
} from "../../reducers/report/report_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import TransferHeader from "./TransferHeader";

class Transfer extends Component {
  constructor(props) {
    super();
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/transfer/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {}

  render() {
    const { listTransfer, listTransfer_data } = this.props;
    const { search_text } = this.state;
    let data = [];
    let records_total = 0;
    if (listTransfer_data) {
      data = listTransfer_data.data;
      records_total = listTransfer_data.records_total;
    }
    return (
      <ContentContainer>
        <TransferHeader handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Tahun",
              render: ({ no }) => no + 2015,
            },
            {
              title: "Estimasi Dana Masuk (Rp)",
              render: ({ estimation_total_branch }) => {
                return (estimation_total_branch * 70)
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },

            {
              title: "Dana Aktual (Rp)",
              render: ({ act_total_branch }) => {
                return act_total_branch
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
            {
              title: "Dana Outstanding (Rp)",
              render: ({ estimation_total_branch, act_total_branch }) => {
                return (Number(estimation_total_branch * 70) - Number(act_total_branch))
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                branch_id: this.state.branch_id,
              };
              listTransfer(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listTransfer }) {
  return {
    listTransfer_data: getListTransfer(listTransfer),
    listTransfer_error: getTransferError(listTransfer),
    listTransfer_pending: getTransferPending(listTransfer),
  };
}

export default connect(mapStateToProps, {
  listTransfer,
})(Transfer);
