import React, { Component } from "react";
import { connect } from "react-redux";
import { listAppreciation } from "../../actions/member/appreciation_action";
import {
  getListAppreciation,
  getAppreciationError,
  getAppreciationPending,
} from "../../reducers/member/appreciation_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import AppreciationHeader from "./AppreciationHeader";
import AppreciationModalAdd from "./AppreciationModalAdd";
import AppreciationModalDelete from "./AppreciationModalDelete";
import { getUser } from "../../helpers/user";

class Appreciation extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/member-appreciation/detail/${id}`);
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {}

  render() {
    const { listAppreciation, listAppreciation_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listAppreciation_data) {
      data = listAppreciation_data.data;
      records_total = listAppreciation_data.records_total;
    }
    return (
      <ContentContainer>
        <AppreciationHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <AppreciationModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <AppreciationModalDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Nama Institusi",
              field: "institution",
            },
            {
              title: "Jenis Penghargaan",
              field: "appreciation_type",
            },
            {
              title: "Tahun",
              field: "year",
            },
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(id)}>
                      Detail
                    </MenuItem>
                    {/* <MenuItem onClick={() => this.handleOpenModalDelete(id)}>
                      Delete
                    </MenuItem> */}
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                user_id: getUser().id,
              };
              listAppreciation(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listAppreciation }) {
  return {
    listAppreciation_data: getListAppreciation(listAppreciation),
    listAppreciation_error: getAppreciationError(listAppreciation),
    listAppreciation_pending: getAppreciationPending(listAppreciation),
  };
}

export default connect(mapStateToProps, {
  listAppreciation,
})(Appreciation);
