import React, { useState } from "react";
import { string } from "prop-types";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ErrorIcon from "@material-ui/icons/Error";
import DetailRejectModal from "./DetailRejectModal";

function StatusSquareComponent(props) {
  const [openModal, setOpenModal] = useState(false);
  const {
    icon,
    status,
    statusTitle,
    statusLabel,
    filename,
    filepath,
    reject_notes,
  } = props;
  const statusColor = ["#F57C2B", "#F57C2B", "#2CB96A"];
  const Icon = icon;
  let statusShort = statusLabel;
  if (statusLabel && statusLabel.length > 100) {
    statusShort = statusLabel.substring(0, 50) + "...";
  }
  return (
    <>
      <DetailRejectModal
        show={openModal}
        onHide={() => setOpenModal(false)}
        reason={reject_notes ? reject_notes : statusLabel}
        filepath={filepath}
        filename={filename}
      />
      <Row
        className={css(styles.statusContainer)}
        vertical="center"
        style={{ backgroundColor: statusColor[status] }}
      >
        <Row className={css(styles.iconContainer)}>
          <Icon fontSize="large" style={{ color: "white" }} />
        </Row>
        <Column className={css(styles.descContainer)}>
          <div className={css(styles.statusTitle)}>{statusTitle}</div>
          <div className={css(styles.statusLabel)}>{statusShort}</div>
          {status === 2 && (
            <div
              className={css(styles.errorLink)}
              onClick={() => setOpenModal(true)}
            >
              Selengkapnya
            </div>
          )}
        </Column>
      </Row>
    </>
  );
}

StatusSquareComponent.propTypes = {
  title: string,
  desc: string,
  statusLabel: string,
};

StatusSquareComponent.defaultProps = {
  icon: ErrorIcon,
  statusTitle: "Baru",
  statusLabel: "Status",
  status: 0,
};

const styles = StyleSheet.create({
  statusContainer: {
    width: "100%",
    // background: "#2CB96A",
    borderRadius: 5,
    padding: 15,
    marginTop: 30,
    marginBottom: 30,
  },
  statusTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#FFFFFF",
    letterSpacing: 1,
  },
  statusLabel: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  descContainer: {
    marginLeft: 10,
  },
  errorLink: {
    marginTop: 5,
    fontSize: 12,
    color: "#FFFFFF",
    opacity: 0.7,
    textDecoration: "underline",
    ":hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
});

export default StatusSquareComponent;
