import React, { Component } from "react";
import { Row, Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
// import { Route, Switch } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import { reduxForm, Field, formValueSelector, FieldArray } from "redux-form";
import { renderSelect, renderInput } from "../../components/form/FormComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList,
  getMemberListPending,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import {
  branchmanList,
  addBranchman,
} from "../../actions/branch-management/branchman_action";
import {
  getBranchmanList,
  getBranchmanListPending,
  getBranchmanListError,
  getBranchmanAddPending,
  getBranchmanAddError,
} from "../../reducers/branch-management/branchman_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RenderArray from "./Array_form";
import RenderArraySection from "./Array_form_section";
import RenderArrayField from "./Array_form_field";

class Pengurus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
    this.tableRef = React.createRef();
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTriggerFormEdit() {
    const { formValue, reset, change } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    reset();
    if (formValue.chairman && formValue.chairman.label === "-") {
      change("chairman", null);
    }
    if (formValue.vice_chairman && formValue.vice_chairman.label === "-") {
      change("vice_chairman", null);
    }
    if (formValue.secretary && formValue.secretary.label === "-") {
      change("secretary", null);
    }
    if (formValue.secretary_2 && formValue.secretary_2.label === "-") {
      change("secretary_2", null);
    }
    if (formValue.treasurer && formValue.treasurer.label === "-") {
      change("treasurer", null);
    }
  }

  handleSubmit(values) {
    let temp_section = [];
    let temp_field = [];

    if (
      Array.isArray(values.member_section) &&
      values.member_section.length > 0 &&
      typeof values.member_section[0] !== "undefined"
    ) {
      values.custom_section.map((item, index) => {
        temp_section.push({
          section_name: values.custom_section[index],
          member: this.toArray(values.member_section[index]),
        });
      });
    }
    if (
      Array.isArray(values.member_field) &&
      values.member_field.length > 0 &&
      typeof values.member_field[0] !== "undefined"
    ) {
      values.custom_field.map((item, index) => {
        temp_field.push({
          field_name: values.custom_field[index],
          member: this.toArray(values.member_field[index]),
        });
      });
    }

    const param_branchman = {
      start_period: values.start_period.label,
      end_period: values.end_period.label,
      chairman: values.chairman.label,
      vice_chairman: values.vice_chairman.label,
      secretary: values.secretary.label,
      secretary_2: values.secretary_2.label,
      treasurer: values.treasurer.label,
      advisory_council: this.toArray(values.advisory_council),
      custom_section: temp_section,
      custom_field: temp_field,
    };
    const callback = () => {
      this.props.branchmanList();
      this.setState({ isEdit: !this.state.isEdit });
    };
    this.props.addBranchman(param_branchman, callback);
  }

  toArray(data) {
    let result = [];
    if (Array.isArray(data) && !data.includes(undefined)) {
      const objectArray = Object.entries(data);
      objectArray.forEach(([key, value]) => {
        if (value !== null) {
          result.push(value.label);
        }
      });
    }
    return result;
  }

  componentDidMount() {
    const { memberList, branchmanList } = this.props;
    memberList({ length: 999999, branch_id: getUser().branch_id });
    branchmanList();
  }

  render() {
    const { isEdit } = this.state;
    const {
      memberList_data,
      handleSubmit,
      formValue,
      member_section_data,
      member_field_data,
      custom_section_data,
      custom_field_data,
    } = this.props;
    const user = getUser();
    const user_branch_id = user.branch_id;
    let fields = [];
    const user_branch_name = user.branch_id ? user.name : "-";
    let advisory_council,
      custom_section,
      custom_field,
      section_name,
      field_name,
      member_field,
      member_section,
      scientific,
      business,
      public_relations,
      advocacy,
      law_firm,
      education,
      sports,
      recreation = [];
    if (formValue) {
      advisory_council = formValue.advisory_council;
      member_section = formValue.member_section;
      member_field = formValue.member_field;
      custom_field = formValue.custom_field;
      custom_section = formValue.custom_section;
      section_name = formValue.section_name;
      field_name = formValue.field_name;
      fields = custom_field;
      if (advisory_council) {
        advisory_council = this.toArray(advisory_council);
      }
      if (member_section) {
        let temp = [];
        member_section.map((item, index) => {
          temp.push(this.toArray(item));
        });
        member_section = temp;
      }
      if (member_field) {
        let temp = [];
        member_field.map((item, index) => {
          temp.push(this.toArray(item));
        });
        member_field = temp;
      }
    }
    let yearOptions = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= currentYear + 20; i++) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    let memberOptions = [];
    if (memberList_data) {
      memberOptions = memberList_data.data
        .filter((item) => item.branch_id === user_branch_id)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }));
    }
    return (
      <ContentContainer>
        <Row>
          <Column className={css(styles.leftDetailContainer)}>
            <span className={css(styles.titleContainer)}>Kepengurusan</span>
            <DetailNameComponent name={user_branch_name} />
          </Column>
          <Column className={css(styles.rightDetailContainer)}>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row horizontal="space-between" style={{ alignItems: "center" }}>
                <DetailTitleComponent
                  title="Susunan Pengurus"
                  marginBottom={true}
                />
                {user !== "admin_paboi_cabang" && (
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    // isLoading={editEducation_pending}
                  />
                )}
              </Row>
              <FormContainer label="Periode">
                <Row
                  style={{ width: "100%" }}
                  horizontal={isEdit ? "space-between" : ""}
                  vertical="center"
                >
                  <Column style={{ width: isEdit ? "48%" : "" }}>
                    <Field
                      isDetail={!isEdit}
                      options={yearOptions}
                      name="start_period"
                      placeholder="Pilih Awal Periode"
                      component={renderSelect}
                    />
                  </Column>
                  {!isEdit && "-"}
                  <Column style={{ width: isEdit ? "48%" : "" }}>
                    <Field
                      isDetail={!isEdit}
                      options={yearOptions}
                      name="end_period"
                      placeholder="Pilih Akhir Periode"
                      component={renderSelect}
                    />
                  </Column>
                </Row>
              </FormContainer>
              {!isEdit ? (
                <DetailContentComponent
                  label="Dewan Pertimbangan"
                  alignItems="baseline"
                  value={
                    advisory_council
                      ? advisory_council.map((item, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              {idx + 1}. {item}
                              <br />
                              <br />
                            </React.Fragment>
                          );
                        })
                      : "-"
                  }
                />
              ) : (
                <Row>
                  <label className={css(styles.subtitleContainer)}>
                    Dewan Pertimbangan
                  </label>
                  <Column style={{ width: "70%", marginTop: 10 }}>
                    <FieldArray
                      name="advisory_council"
                      placeholder="Pilih Dewan Pertimbangan"
                      component={RenderArray}
                    />
                  </Column>
                </Row>
              )}
              <label className={css(styles.pengurusSection)}>
                Pengurus Inti
              </label>
              <FormContainer label="Ketua">
                <Field
                  isDetail={!isEdit}
                  isClearable={false}
                  options={memberOptions}
                  name="chairman"
                  placeholder="Pilih Ketua"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Wakil Ketua">
                <Field
                  isDetail={!isEdit}
                  isClearable={false}
                  options={memberOptions}
                  name="vice_chairman"
                  placeholder="Pilih Wakil Ketua"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Sekretaris 1">
                <Field
                  isDetail={!isEdit}
                  isClearable={false}
                  options={memberOptions}
                  name="secretary"
                  placeholder="Pilih Sekertaris"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Sekretaris 2">
                <Field
                  isDetail={!isEdit}
                  isClearable={false}
                  options={memberOptions}
                  name="secretary_2"
                  placeholder="Pilih Sekertaris"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Bendahara">
                <Field
                  isDetail={!isEdit}
                  isClearable={false}
                  options={memberOptions}
                  name="treasurer"
                  placeholder="Pilih Bendahara"
                  component={renderSelect}
                />
              </FormContainer>
              <label className={css(styles.pengurusSection)}>Seksi</label>
              <br></br>
              {!isEdit ? (
                custom_section !== undefined ? (
                  custom_section.map((item, index) => (
                    <DetailContentComponent
                      label={item}
                      alignItems="baseline"
                      value={
                        member_section
                          ? member_section[index].map((data, idx) => {
                              return (
                                <React.Fragment key={idx}>
                                  {idx + 1}. {data}
                                  <br />
                                  <br />
                                </React.Fragment>
                              );
                            })
                          : "-"
                      }
                    />
                  ))
                ) : (
                  ""
                )
              ) : (
                <>
                  <FieldArray
                    name="custom_section"
                    placeholder="Pilih Member"
                    component={RenderArraySection}
                  />
                </>
              )}
              <label className={css(styles.pengurusSection)}>Bidang</label>
              {!isEdit ? (
                custom_field !== undefined ? (
                  custom_field.map((item, index) => (
                    <DetailContentComponent
                      label={item}
                      alignItems="baseline"
                      value={
                        member_field
                          ? member_field[index].map((data, idx) => {
                              return (
                                <React.Fragment key={idx}>
                                  {idx + 1}. {data}
                                  <br />
                                  <br />
                                </React.Fragment>
                              );
                            })
                          : "-"
                      }
                    />
                  ))
                ) : (
                  ""
                )
              ) : (
                <>
                  <FieldArray
                    name="custom_field"
                    placeholder="Pilih Member"
                    component={RenderArrayField}
                  />
                </>
              )}
            </form>
          </Column>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  pengurusSection: {
    fontSize: 16,
    fontWeight: "bold",
    margin: "20px 0px 10px 0px",
  },
  titleContainer: {
    color: "#495057",
    opacity: "0.7",
    marginBottom: 10,
  },
  subtitleContainer: {
    color: "#495057",
    opacity: "0.7",
    margin: "20px 0",
    width: "30%",
  },
  periode: {
    color: "#495057",
    fontSize: 16,
    marginTop: 10,
  },
});

function validate(values) {
  const {
    start_period,
    end_period,
    chairman,
    vice_chairman,
    custom_section,
    member_section,
    member_field,
    custom_field,
    secretary,
    secretary_2,
    treasurer,
    advisory_council,
    scientific,
    business,
    public_relations,
    advocacy,
    law_firm,
    education,
    sports,
    recreation,
  } = values;
  const errors = {};
  if (!start_period) {
    errors.start_period = "Periode awal wajib diisi";
  }
  if (!end_period) {
    errors.end_period = "Periode akhir wajib diisi";
  }
  if (!chairman) {
    errors.chairman = "Ketua wajib diisi";
  }
  if (!vice_chairman) {
    errors.vice_chairman = "Wakil Ketua wajib diisi";
  }
  if (!secretary) {
    errors.secretary = "Sekretaris wajib diisi";
  }
  if (!treasurer) {
    errors.treasurer = "Bendahara wajib diisi";
  }
  const advisory_councilArrayErrors = [];
  //   if (!advisory_council || !advisory_council.length) {
  //     errors.advisory_council = {
  //       _error: "Dewan pertimbangan tidak boleh kosong",
  //     };
  //   } else {
  //     advisory_council.forEach((item, index) => {
  //       if (!item) {
  //         advisory_councilArrayErrors[index] =
  //           "Dewan pertimbangan tidak boleh kosong";
  //       }
  //     });
  //   }
  if (advisory_councilArrayErrors.length) {
    errors.advisory_council = advisory_councilArrayErrors;
  }
  const scientificArrayErrors = [];
  if (!scientific || !scientific.length) {
    errors.scientific = { _error: "Sie. Ilmiah tidak boleh kosong" };
  } else {
    scientific.forEach((item, index) => {
      if (!item) {
        scientificArrayErrors[index] = "Sie. Ilmiah tidak boleh kosong";
      }
    });
  }
  if (scientificArrayErrors.length) {
    errors.scientific = scientificArrayErrors;
  }
  const businessArrayErrors = [];
  if (!business || !business.length) {
    errors.business = {
      _error:
        "Sie. Bidang Usaha, dalam negeri dan luar negeri tidak boleh kosong",
    };
  } else {
    business.forEach((item, index) => {
      if (!item) {
        businessArrayErrors[index] =
          "Sie. Bidang Usaha, dalam negeri dan luar negeri tidak boleh kosong";
      }
    });
  }
  if (businessArrayErrors.length) {
    errors.business = businessArrayErrors;
  }
  const public_relationsArrayErrors = [];
  if (!public_relations || !public_relations.length) {
    errors.public_relations = {
      _error: "Sie. Bidang Humas tidak boleh kosong",
    };
  } else {
    public_relations.forEach((item, index) => {
      if (!item) {
        public_relationsArrayErrors[index] =
          "Sie. Bidang Humas tidak boleh kosong";
      }
    });
  }
  if (public_relationsArrayErrors.length) {
    errors.public_relations = public_relationsArrayErrors;
  }
  const advocacyArrayErrors = [];
  if (!advocacy || !advocacy.length) {
    errors.advocacy = {
      _error: "Sie. Bidang Advokasi dan Legislasi Kebijakan tidak boleh kosong",
    };
  } else {
    advocacy.forEach((item, index) => {
      if (!item) {
        advocacyArrayErrors[index] =
          "Sie. Bidang Advokasi dan Legislasi Kebijakan tidak boleh kosong";
      }
    });
  }
  if (advocacyArrayErrors.length) {
    errors.advocacy = advocacyArrayErrors;
  }
  const law_firmArrayErrors = [];
  if (!law_firm || !law_firm.length) {
    errors.law_firm = {
      _error:
        "Sie. Bidang Biro Hukum dan Pembinaan/Pembelaan Anggota (BHP2A) tidak boleh kosong",
    };
  } else {
    law_firm.forEach((item, index) => {
      if (!item) {
        law_firmArrayErrors[index] =
          "Sie. Bidang Biro Hukum dan Pembinaan/Pembelaan Anggota (BHP2A) tidak boleh kosong";
      }
    });
  }
  if (law_firmArrayErrors.length) {
    errors.law_firm = law_firmArrayErrors;
  }
  const educationArrayErrors = [];
  if (!education || !education.length) {
    errors.education = {
      _error:
        "Bidang Tim Pendidikan Keprofesian Berkelanjutan (P2KB/ CPD) tidak boleh kosong",
    };
  } else {
    education.forEach((item, index) => {
      if (!item) {
        educationArrayErrors[index] =
          "Bidang Tim Pendidikan Keprofesian Berkelanjutan (P2KB/ CPD) tidak boleh kosong";
      }
    });
  }
  if (educationArrayErrors.length) {
    errors.education = educationArrayErrors;
  }
  const sportsArrayErrors = [];
  if (!sports || !sports.length) {
    errors.sports = { _error: "Bidang Tim Olahraga tidak boleh kosong" };
  } else {
    sports.forEach((item, index) => {
      if (!item) {
        sportsArrayErrors[index] = "Bidang Tim Olahraga tidak boleh kosong";
      }
    });
  }
  if (sportsArrayErrors.length) {
    errors.sports = sportsArrayErrors;
  }
  const recreationArrayErrors = [];
  if (!recreation || !recreation.length) {
    errors.recreation = { _error: "Bidang Tim Rekreasi tidak boleh kosong" };
  } else {
    recreation.forEach((item, index) => {
      if (!item) {
        recreationArrayErrors[index] = "Bidang Tim Rekreasi tidak boleh kosong";
      }
    });
  }
  if (recreationArrayErrors.length) {
    errors.recreation = recreationArrayErrors;
  }
  return errors;
}

Pengurus = reduxForm({
  form: "Pengurus",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(Pengurus);

function mapStateToProps(state) {
  const { branchmanList, addBranchman, memberList } = state;
  const selector = formValueSelector("Pengurus");
  const formValue = selector(
    state,
    "advisory_council",
    "custom_section",
    "field_name",
    "section_name",
    "member_section",
    "member_field",
    "custom_field",
    "chairman",
    "vice_chairman",
    "secretary",
    "secretary_2",
    "treasurer"
  );
  let data = [];
  let start_period,
    end_period,
    advisory_council,
    section_name,
    field_name,
    member_section,
    member_field,
    custom_section,
    custom_field,
    chairman,
    vice_chairman,
    secretary,
    secretary_2,
    treasurer,
    advisory_council_data,
    section_name_data,
    field_name_data,
    member_section_data,
    member_field_data,
    custom_section_data,
    custom_field_data = null;
  if (
    branchmanList.branchmanList !== null &&
    branchmanList.branchmanList.records_total !== 0
  ) {
    data = branchmanList.branchmanList.data[0];
    start_period = data.start_period;
    end_period = data.end_period;
    advisory_council = JSON.parse(data.advisory_council);

    if (
      data.branch_management_section.custom_section &&
      JSON.parse(data.branch_management_section.custom_section).length > 0
    ) {
      custom_section = JSON.parse(
        data.branch_management_section.custom_section
      );
      section_name = custom_section[0].section_name;
      let temp = [];
      let temp_member = [];
      custom_section.map((item, index) => {
        temp.push(item.section_name);
        temp_member.push(item.member);
      });
      member_section = temp_member;
      custom_section = temp;
      temp = [];
      member_section.map((item, index) => {
        temp.push([]);
        item.map((data, idx) => {
          temp[index].push({
            label: data,
            item: data,
          });
        });
      });
      member_section_data = temp;
    }

    if (
      data.branch_management_field.custom_field &&
      JSON.parse(data.branch_management_field.custom_field).length > 0
    ) {
      custom_field = JSON.parse(data.branch_management_field.custom_field);
      field_name = custom_field[0].field_name;
      let temp = [];
      let temp_member = [];
      custom_field.map((item, index) => {
        temp.push(item.field_name);
        temp_member.push(item.member);
      });
      member_field = temp_member;
      custom_field = temp;
      temp = [];
      member_field.map((item, index) => {
        temp.push([]);
        item.map((data, idx) => {
          temp[index].push({
            label: data,
            item: data,
          });
        });
      });
      member_field_data = temp;
    }
    console.log("isiii data", data);
    chairman = data.chairman;
    vice_chairman = data.vice_chairman;
    secretary = data.secretary;
    secretary_2 = data.secretary_2;
    treasurer = data.treasurer;
    advisory_council_data = advisory_council.map((item) => ({
      label: item,
      value: item,
    }));

    section_name_data = section_name;
    field_name_data = field_name;
    custom_field_data = custom_field;
    custom_section_data = custom_section;
  }
  let initialValues = {};
  if (start_period) {
    initialValues.start_period = {
      label: start_period,
      value: start_period,
    };
  }
  if (end_period) {
    initialValues.end_period = {
      label: end_period,
      value: end_period,
    };
  }
  if (chairman) {
    initialValues.chairman = {
      label: chairman,
      value: chairman,
    };
  } else {
    initialValues.chairman = {
      label: "-",
    };
  }
  if (vice_chairman) {
    initialValues.vice_chairman = {
      label: vice_chairman,
      value: vice_chairman,
    };
  } else {
    initialValues.vice_chairman = {
      label: "-",
    };
  }
  if (secretary) {
    initialValues.secretary = {
      label: secretary,
      value: secretary,
    };
  } else {
    initialValues.secretary = {
      label: "-",
    };
  }
  if (secretary_2) {
    initialValues.secretary_2 = {
      label: secretary_2,
      value: secretary_2,
    };
  } else {
    initialValues.secretary_2 = {
      label: "-",
    };
  }
  if (treasurer) {
    initialValues.treasurer = {
      label: treasurer,
      value: treasurer,
    };
  } else {
    initialValues.treasurer = {
      label: "-",
    };
  }
  if (advisory_council) {
    initialValues.advisory_council = advisory_council_data;
  }
  if (member_section) {
    initialValues.member_section = member_section_data;
  }
  if (section_name) {
    initialValues.section_name = section_name_data;
  }
  if (field_name) {
    initialValues.field_name = field_name_data;
  }
  if (custom_field) {
    initialValues.custom_field = custom_field_data;
  }
  if (custom_section) {
    initialValues.custom_section = custom_section_data;
  }
  if (member_field) {
    initialValues.member_field = member_field_data;
  }

  return {
    memberList_data: getMemberList(memberList),
    memberList_pending: getMemberListPending(memberList),
    memberList_error: getMemberListError(memberList),
    branchmanList_data: getBranchmanList(branchmanList),
    branchmanList_pending: getBranchmanListPending(branchmanList),
    branchmanList_error: getBranchmanListError(branchmanList),
    branchmanAdd_pending: getBranchmanAddPending(addBranchman),
    branchmanAdd_error: getBranchmanAddError(addBranchman),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  memberList,
  branchmanList,
  addBranchman,
})(withRouter(Pengurus));
