import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_EXPERIENCE_PENDING = 'LIST_EXPERIENCE_PENDING'
export const LIST_EXPERIENCE_SUCCESS = 'LIST_EXPERIENCE_SUCCESS'
export const LIST_EXPERIENCE_ERROR = 'LIST_EXPERIENCE_ERROR'
export const EDIT_EXPERIENCE_PENDING = 'EDIT_EXPERIENCE_PENDING'
export const EDIT_EXPERIENCE_SUCCESS = 'EDIT_EXPERIENCE_SUCCESS'
export const EDIT_EXPERIENCE_ERROR = 'EDIT_EXPERIENCE_ERROR'
export const ADD_EXPERIENCE_PENDING = 'ADD_EXPERIENCE_PENDING'
export const ADD_EXPERIENCE_SUCCESS = 'ADD_EXPERIENCE_SUCCESS'
export const ADD_EXPERIENCE_ERROR = 'ADD_EXPERIENCE_ERROR'
export const DELETE_EXPERIENCE_PENDING = 'DELETE_EXPERIENCE_PENDING'
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS'
export const DELETE_EXPERIENCE_ERROR = 'DELETE_EXPERIENCE_ERROR'
export const SET_IS_EDIT_FILE_EXPERIENCE = "SET_IS_EDIT_FILE_EXPERIENCE";

const LIST_EXPERIENCE_URL = 'member/experience/find';
const EDIT_EXPERIENCE_URL = 'member/experience/edit';
const ADD_EXPERIENCE_URL = 'member/experience/add';
const DELETE_EXPERIENCE_URL = 'member/experience/delete';
const UPLOAD_EXPERIENCE_URL = "image/upload/member_experience";

export function experienceList(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_EXPERIENCE_PENDING));
        API.post(LIST_EXPERIENCE_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_EXPERIENCE_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    ...item, no: i + 1 + (param.page - 1) * 10,
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_EXPERIENCE_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}

export function editExperience({ file, ...restParam }, callback) {
    return (dispatch) => {
      dispatch(actionPending(EDIT_EXPERIENCE_PENDING));
      new Promise((resolve, reject) => {
        if (file) {
          var formData = new FormData();
          formData.append("file", file);
          API.post(UPLOAD_EXPERIENCE_URL, formData, getHeader())
            .then((res) => {
              resolve(res.data.filename);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(null);
        }
      })
        .then((filename) => {
          if (filename) {
            restParam.filename = filename;
          }
          return API.post(EDIT_EXPERIENCE_URL, restParam, getHeader());
        })
        .then((res) => {
          dispatch(actionSuccess(EDIT_EXPERIENCE_SUCCESS, res.data.data));
          toastSuccess("Berhasil mengubah pengalaman");
          if (callback) {
            callback();
          }
          return res;
        })
        .catch((error) => {
          dispatch(actionError(EDIT_EXPERIENCE_ERROR, error));
          toastError(error, "Gagal mengubah pengalaman, ");
        });
    };
  }

export function addExperience({ file, ...restParam }, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_EXPERIENCE_PENDING));
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_EXPERIENCE_URL, formData, getHeader())
            .then((res) => {
                return res.data.filename;
            })
            .then((filename) => {
                restParam.filename = filename;
                return API.post(ADD_EXPERIENCE_URL, restParam, getHeader());
            })
            .then((res) => {
                dispatch(actionSuccess(ADD_EXPERIENCE_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan pengalaman");
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_EXPERIENCE_ERROR, error));
                toastError(error, "Gagal menambahkan pengalaman, ");
            });
    };
}

export function deleteExperience(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_EXPERIENCE_PENDING));
        API.post(DELETE_EXPERIENCE_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_EXPERIENCE_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus pengalaman");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_EXPERIENCE_ERROR, error));
                toastError(error, "Gagal menghapus pengalaman, ");
            });
    };
}

export function setIsEditFile(value) {
    return {
        type: SET_IS_EDIT_FILE_EXPERIENCE,
        value: value,
    };
}