import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../components/p2kb/HeaderDetailP2kbComponent";
import IconError from "../../assets/icon-error";
import IconDocumentP2KB from "../../assets/icon-document-p2kb";
import IconLaunch from "../../assets/icon-launch";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import localization from "moment/locale/id";

import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { p2kbList, setSelectedSKP } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import ModalDetailDokumenSKP from "./Modal_document_pdf";
import ModalDetailDokumen from "./Modal_document";
import ModalAcceptSkp from "./Modal_accept_skp";
import ModalRejectSkp from "./Modal_reject_skp";
import ModalDetailSKPMembacaJurnal from "./Skp_membaca_jurnal_detail";
import ModalRevisionDetail from './Revision_detail';
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";

class SKPMembacaJurnalApproval extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      modalAcceptSKPShow: false,
      modalRejectSKPShow: false,
      ModalDetailSKP_MembacaJurnalShow: false,
      modalDetailRevisionShow: false,
      imgUrl: "",
      modal_title: "",
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : '',
      id: "",
      forRefresh: false,
      revisionImage: null,
      revisionReason: null,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  handleSkpDetail(data) {
    this.props.setSelectedSKP(data);
    this.setState({ ModalDetailSKP_MembacaJurnalShow: true });
  }

  componentDidMount() {
    const param = {
      activity_id: this.props.match.params.id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      user_id: localStorage.getItem('user_id_skp'),
      serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',

    };
    this.props.p2kbList(param);
  }

  handleRevision(id) {
    this.setState({ id: id });
    this.setState({ modalRejectSKPShow: true });
  }

  handleOpenRevision(data) {
    this.setState({ revisionImage: data.last_reject_filename });
    this.setState({ revisionReason: data.last_reject_notes });
    this.setState({ modalDetailRevisionShow: true });
  }

  render() {
    const {
      p2kbList,
      p2kbList_data,
      match: {
        params: { id },
      },
    } = this.props;
    const role_codes = JSON.parse(localStorage.getItem("user_paboi")).data
      .role_codes[0];
    let data = [];
    let activity_name = "";
    let activity_type = "";
    let activity_id = "";
    let realm_activity = "";
    let skp = "";
    let skp_status = "";
    let last_skp_admin = "";
    let step, is_reject, confirmation_date, length, records_total = "";
    let isRoleCodesMatchStep, isRoleCodeMatch = false;
    let stepOne = "admin_paboi_cabang";
    let stepTwo = "ketua_paboi_cabang";
    let stepThree = "root";
    let stepFour = "ketua_paboi_pusat";
    let stepFive = "cpd_cme";
    if (p2kbList_data) {
      data = p2kbList_data.data[0];
      let arrayData = []
      p2kbList_data.data.map((item, index) => {
        item.p2kb_details.map((detail, idx) => {
          arrayData.push(detail)
        })
      })
      data.p2kb_details = arrayData;
      activity_name = data.activity_data.name;
      activity_type = data.activity_data.activity_type;
      activity_id = data.activity_id;
      realm_activity = data.activity_data.realm_activity.name;
      skp = data.total_skp;
      skp_status = data.status;
      step = data.step;
      last_skp_admin =
        data.role_next_approve !== null
          ? data.role_next_approve.role_name
          : "CPD/CME";
      isRoleCodeMatch = data.role_next_approve !== null ? data.role_next_approve.role_code === getUser().role_codes[0] : null;
      is_reject = skp_status === 2 ? true : false;
      if (is_reject === true) {
        confirmation_date = "Direvisi pada " + moment(data.last_rejected_date).format("DD/MM/YYYY");
      } else {
        confirmation_date = "Diajukan pada " + moment(data.updated_at).format("DD/MM/YYYY");
      }
      if (role_codes === stepOne && step === 1 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepTwo && step === 2 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepThree && step === 3 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepFour && step === 4 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_codes === stepFive && step === 5 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      length = data.p2kb_details.length > 10 ? 10 : data.p2kb_details.length;
      records_total = data.p2kb_details.length;
    }
    return (
      <ContentContainer>
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalAcceptSkp
          show={this.state.modalAcceptSKPShow}
          onHide={() => this.setState({ modalAcceptSKPShow: false })}
          id={data.id}
          handleRefresh={this.handleRefresh}
        />
        <ModalRejectSkp
          show={this.state.modalRejectSKPShow}
          onHide={() => this.setState({ modalRejectSKPShow: false })}
          id={this.state.id}
          handleRefresh={this.handleRefresh}
        />
        <ModalRevisionDetail
          show={this.state.modalDetailRevisionShow}
          onHide={() => this.setState({ modalDetailRevisionShow: false })}
          reason={this.state.revisionReason}
          imgUrl={this.state.revisionImage}
        />
        <ModalDetailSKPMembacaJurnal
          show={this.state.ModalDetailSKP_MembacaJurnalShow}
          onHide={() =>
            this.setState({ ModalDetailSKP_MembacaJurnalShow: false })
          }
          handleEditDetail={this.handleEditSkpDetail}
          activity_id={activity_id}
        />
        <HeaderDetailP2kbComponent
          activity_name={activity_name}
          activity_type={activity_type}
          realm_activity={realm_activity}
          skp={skp}
          skp_status={skp_status}
          last_skp_admin={last_skp_admin}
          icon={IconError}
          isAdmin={isRoleCodesMatchStep === true ? role_codes.includes("ketua") || role_codes.includes("cpd") ? "ketua" : "admin" : false}
          approval={data.role_next_approve ? data.role_next_approve.role_code.includes("ketua") || data.role_next_approve.role_code.includes("cpd") ? "Persetujuan" : "Verifikasi" : "Persetujuan"}
          acceptSKP={() => this.setState({ modalAcceptSKPShow: true })}
          rejectSKP={() => this.setState({ modalRejectSKPShow: true })}
          confirmation_date={confirmation_date}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="P2kb"
          columns={[
            {
              title: "No",
              field: "name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tanggal Kegiatan",
              render: (data) => {
                return moment(data.activity_date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            {
              title: "Judul Materi",
              render: (data) => {
                let result = JSON.parse(data.form_json);
                return result.material_title;
              },
            },
            {
              title: "Nama Majalah",
              render: (data) => {
                let result = JSON.parse(data.form_json);
                return result.magazine_name;
              },
            },
            {
              title: "Tahun Terbit",
              render: (data) => {
                let result = JSON.parse(data.form_json);
                return result.publication_year;
              },
            },
            { title: "Jumlah SKP", field: "total_skp" },
            {
              title: "Dokumen",
              render: (data) => {
                return (
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHover)}
                      >
                        <IconDocumentP2KB />
                      </Row>
                    )}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(
                          data.attachments[0].filename,
                          "Pernyataan Lulus Dari Jurnal"
                        )
                      }
                    >
                      <IconLaunch />{" "}
                      <span style={{ marginLeft: 10, paddingTop: 2 }}>
                        Pernyataan Lulus Dari Jurnal
                      </span>
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Menunggu"
                        : status === 1
                          ? "Approved"
                          : "Revisi"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: (data) => {
                return (
                  data.status !== 2 ?
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleSkpDetail(data)}>
                        Detail
                              </MenuItem>
                      {/* {isRoleCodeMatch &&
                        <MenuItem onClick={() => this.handleRevision(data.id)}>
                          Revisi
                                  </MenuItem>
                      } */}
                    </DetailButtonComponent>
                    :
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleSkpDetail(data)}>
                        Detail
                              </MenuItem>
                      {/* <MenuItem onClick={() => this.handleOpenRevision(data)}>
                        Alasan Penolakan
                              </MenuItem> */}
                    </DetailButtonComponent>
                );
              },
            }
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                activity_id: this.props.match.params.id,
                start_date: this.state.start_date,
                user_id: localStorage.getItem('user_id_skp'),
                end_date: this.state.end_date,
                serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',
              };
              p2kbList(param, resolve, reject, true);
            })
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                // vertical="center"
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    // backgroundColor: "red",
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p>
                    {" "}
                    Menampilkan {length} dari {records_total} data{" "}
                  </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}></div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ p2kbList, setSelectedSKP }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
  };
}

export default connect(mapStateToProps, { p2kbList, setSelectedSKP })(
  withRouter(SKPMembacaJurnalApproval)
);
