import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { renderSelectWithLabel } from "../../components/form/FormComponent";
import { realmActivityList } from "../../actions/master-data/realm_activity_action";
import {
  getRealmActivityListPending,
  getRealmActivityList,
  getRealmActivityListError,
} from "../../reducers/master-data/realm_activity_reducer";
import { listActivity } from "../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../reducers/master-data/activity_reducer";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import InputComponent from "../../components/form/InputComponent";

class ModalAddSKP extends Component {
  constructor(props) {
    super(props);
    const { isFirstLayer } = this.props;
    this.state = {
      activity_type: "",
      childOptions: "",
      activity_index: "",
      isDisabled: true,
      isFirstLayer: isFirstLayer,
      nullActivity: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRealmActivityChange = this.handleRealmActivityChange.bind(this);
    this.handleActivityChange = this.handleActivityChange.bind(this);
  }

  handleRealmActivityChange(e) {
    this.props.change("activity", null);
    this.setState({ activity_type: "" });
    if (e !== null) {
      this.setState({ isDisabled: false });
      this.setState({ nullActivity: true });
      const callback = () => {
        this.setState({ nullActivity: false });
      };
      this.props.listActivity(
        { length: 999, realm_of_activity_id: e.value },
        callback
      );
      this.setState({ childOptions: "" });
    } else {
      this.props.listActivity({ length: 999, realm_of_activity_id: "" });
      this.setState({ childOptions: "" });
    }
  }

  handleActivityChange(e) {
    if (e !== null) {
      this.setState({ activity_index: e.index });
      this.setState({
        activity_type: this.props.listActivity_data.data[e.index].activity_type,
      });
      let childOptions = this.props.listActivity_data.data[
        e.index
      ].childrens.map((item, index) => ({
        label: item.name,
        value: item.id,
        index,
      }));
      this.setState({ childOptions: childOptions });
    } else {
      this.setState({ activity_type: "" });
    }
  }

  handleDetailP2kb(data) {
    let realm_activity = null;
    let values = null;
    realm_activity = JSON.parse(localStorage.getItem('skp_add_data_temp_first'))
    values = {
      activity: {
        value: data.activity_data.id
      }
    };
    const tindakanOperasi = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dc",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dd",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363de",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363df",
    ];
    const pelayananMedis = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dg",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dh",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363di",
    ];
    const pertemuanAuditor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dl"];
    const membacaJurnal = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dn"];
    const penelusuranInformasi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363do"];
    const acaraIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dj"];
    const seminar = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dq",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr",
    ];
    const kajianIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dk"];
    const edukasiPasien = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dm"];
    const menghadiriKongres = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ds"];
    const menulisIlmiah = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dw",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dx",
    ];
    const menulisBuku = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dy"];
    const membimbingMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eg"];
    const mengajarMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ee"];
    const mengikutiPelatihan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dt"];
    const kepengurusanProfesi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dv"];
    const pengabdianMasyarakat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363du"];
    const penelitianDipublikasikan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ec"];
    const penyeliaSupervisor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ed"];
    const wawancaraKajianElektronik = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dz"];
    const menciptakanAlat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eb"];
    const praktekMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ef"];
    const membuatVideo = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ea"];
    const mengujiMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eh"];

    if (tindakanOperasi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/tindakan-operasi/${data.activity_id}`);
    }
    if (pelayananMedis.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pelayanan-medis/${data.activity_id}`);
    }
    if (pertemuanAuditor.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pertemuan-auditor/${data.activity_id}`);
    }
    if (membacaJurnal.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/membaca-jurnal/${data.activity_id}`);
    }
    if (penelusuranInformasi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/penelusuran-informasi/${data.activity_id}`);
    }
    if (acaraIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/acara-ilmiah/${data.activity_id}`);
    }
    if (seminar.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/seminar/${data.activity_id}`);
    }
    if (kepengurusanProfesi.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/kepengurusan-profesi/${data.activity_id}`);
    }
    if (penelitianDipublikasikan.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        `/p2kb/details/penelitian-dipublikasikan/${data.activity_id}`
      );
    }
    if (mengikutiPelatihan.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pelatihan/${data.activity_id}`);
    }
    if (penyeliaSupervisor.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/penyelia-supervisor/${data.activity_id}`);
    }
    if (kajianIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/kajian-ilmiah/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menghadiriKongres.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menghadiri-kongres/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (edukasiPasien.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/edukasi-pasien/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menulisIlmiah.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menulis-ilmiah/" + data.activity_data.id + "/" + data.id
      );
    }
    if (menulisBuku.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/menulis-buku/" + data.activity_data.id + "/" + data.id
      );
    }
    if (membimbingMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/membimbing-mahasiswa/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (mengajarMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(
        "/p2kb/details/mengajar-mahasiswa/" +
        data.activity_data.id +
        "/" +
        data.id
      );
    }
    if (pengabdianMasyarakat.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/pengabdian-masyarakat/${data.activity_id}`);
    }
    if (wawancaraKajianElektronik.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/kajian-elektronik/${data.activity_id}`);
    }
    if (menciptakanAlat.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/menciptakan-alat/${data.activity_id}`);
    }
    if (praktekMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/praktek-mahasiswa/${data.activity_id}`);
    }
    if (membuatVideo.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/membuat-video/${data.activity_id}`);
    }
    if (mengujiMahasiswa.includes(data.activity_data.id)) {
      localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
      this.props.history.push(`/p2kb/details/menguji-mahasiswa/${data.activity_id}`);
    }
  }

  handleSubmit(values) {
    const recentSkp = JSON.parse(localStorage.getItem('recentSkpData'))
    let idx = 0;
    if (recentSkp.length > 0) {
      idx = recentSkp.map(function (e) { return e.activity_id; }).indexOf(values.activity.value);
      if (idx >= 0) {
        this.handleDetailP2kb(recentSkp[idx])
        return
      }
    }
    const isFirstLayer = this.state.isFirstLayer;
    let realm_activity = null;
    if (!isFirstLayer) {
      realm_activity = JSON.parse(localStorage.getItem('skp_add_data_temp_first')).realm_activity
      values = { ...values, activity_type: this.state.activity_type, realm_activity };
    }
    else {
      values = { ...values, activity_type: this.state.activity_type };
    }
    const tindakanOperasi = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dc",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dd",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363de",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363df",
    ];
    const pelayananMedis = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dg",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dh",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363di",
    ];
    const pertemuanAuditor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dl"];
    const membacaJurnal = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dn"];
    const penelusuranInformasi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363do"];
    const acaraIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dj"];
    const seminar = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dq",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr",
    ];
    const kajianIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dk"];
    const menghadiriKongres = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ds"];
    const mengikutiPelatihan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dt"];
    const edukasiPasien = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dm"];
    const kepengurusanProfesi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dv"];
    const pengabdianMasyarakat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363du"];
    const penelitianDipublikasikan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ec"];
    const penyeliaSupervisor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ed"];
    const praktekMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ef"];
    const wawancaraKajianElektronik = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dz"];
    const menciptakanAlat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eb"];
    const membuatVideo = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ea"];
    const mengujiMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eh"];

    const menulisIlmiah = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dw",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dx",
    ];
    const menulisBuku = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dy"];
    const membimbingMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eg"];
    const mengajarMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ee"];
    if (isFirstLayer) {
      localStorage.setItem("skp_add_data_temp_first", JSON.stringify(values));
      this.props.history.push("p2kb/add/");
    }
    else {
      if (tindakanOperasi.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/tindakan-operasi");
      }
      if (pelayananMedis.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/pelayanan-medis");
      }
      if (pertemuanAuditor.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/pertemuan-auditor");
      }
      if (seminar.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/seminar");
      }
      if (membacaJurnal.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/membaca-jurnal");
      }
      if (penelusuranInformasi.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/penelusuran-informasi");
      }
      if (acaraIlmiah.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/acara-ilmiah");
      }
      if (penelitianDipublikasikan.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/penelitian-dipublikasikan");
      }
      if (penyeliaSupervisor.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/penyelia-supervisor");
      }
      if (praktekMahasiswa.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/praktek-mahasiswa");
      }
      if (mengikutiPelatihan.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/pelatihan");
      }
      if (kajianIlmiah.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/kajian-ilmiah/" + values.activity.value
        );
      }
      if (menghadiriKongres.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/menghadiri-kongres/" + values.activity.value
        );
      }
      if (edukasiPasien.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/edukasi-pasien/" + values.activity.value
        );
      }
      if (menulisIlmiah.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/menulis-ilmiah/" + values.activity.value
        );
      }
      if (membimbingMahasiswa.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/membimbing-mahasiswa/" + values.activity.value
        );
      }
      if (mengajarMahasiswa.includes(values.activity.value)) {
        this.props.history.push(
          "/p2kb/add/mengajar-mahasiswa/" + values.activity.value
        );
      }
      if (menulisBuku.includes(values.activity.value)) {
        this.props.history.push("/p2kb/add/menulis-buku/" + values.activity.value);
      }
      if (kepengurusanProfesi.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push("/p2kb/add/kepengurusan-profesi");
      }
      if (pengabdianMasyarakat.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push(
          "/p2kb/add/pengabdian-masyarakat/" + values.activity.value
        );
      }
      if (wawancaraKajianElektronik.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push(
          "/p2kb/add/kajian-elektronik/" + values.activity.value
        );
      }
      if (menciptakanAlat.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push(
          "/p2kb/add/menciptakan-alat/" + values.activity.value
        );
      }
      if (membuatVideo.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push(
          "/p2kb/add/membuat-video/" + values.activity.value
        );
      }
      if (mengujiMahasiswa.includes(values.activity.value)) {
        localStorage.setItem("skp_add_data_temp", JSON.stringify(values));
        this.props.history.push(
          "/p2kb/add/menguji-mahasiswa/" + values.activity.value
        );
      }
    }
  }

  componentDidMount() {
    this.props.realmActivityList({ length: 999 });
    let realm_id = null;
    if (localStorage.getItem('skp_add_data_temp_first')) {
      realm_id = JSON.parse(localStorage.getItem('skp_add_data_temp_first')).realm_activity ? JSON.parse(localStorage.getItem('skp_add_data_temp_first')).realm_activity.value : '';
    }
    else {
      realm_id = '';
    }
    const callback = () => {
      this.setState({ nullActivity: false });
    };
    this.props.listActivity(
      { length: 999, realm_of_activity_id: realm_id },
      callback
    );
    this.setState({ childOptions: "" });
  }

  render() {
    const {
      show,
      onHide,
      realmActivityList_data,
      listActivity_data,
      handleSubmit,
      isFirstLayer,
      recentSkp
    } = this.props;
    const { activity_type, nullActivity } = this.state;
    let realmActivityOptions = [];
    let listActivityOptions = [];
    if (realmActivityList_data) {
      realmActivityOptions = realmActivityList_data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    if (nullActivity === false) {
      if (listActivity_data) {
        listActivityOptions = listActivity_data.data.map((item, index) => ({
          label: item.name,
          value: item.id,
          index: index,
        }));
      }
    } else {
      listActivityOptions = [];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {isFirstLayer ?
                <Field
                  name="realm_activity"
                  placeholder="Pilih Ranah Kegiatan"
                  label="Ranah Kegiatan"
                  options={realmActivityOptions}
                  isClearable={false}
                  onChange={this.handleRealmActivityChange}
                  component={renderSelectWithLabel}
                /> :
                <>
                  <Field
                    name="activity"
                    placeholder={
                      this.state.isDisabled === true
                        ? "Pilih ranah kegiatan terlebih dahulu"
                        : "Pilih Nama Kegiatan"
                    }
                    label="Nama Kegiatan"
                    options={listActivityOptions}
                    isClearable={false}
                    onChange={this.handleActivityChange}
                    component={renderSelectWithLabel}
                  />
                  <LabelInputVerticalComponent label="Tipe Kegiatan">
                    <InputComponent
                      isClearable={false}
                      disabled={true}
                      value={activity_type}
                      id="activity_type"
                    />
                  </LabelInputVerticalComponent>
                </>
              }

            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { activity, realm_activity } = values;
  const errors = {};
  if (!realm_activity) {
    errors.realm_activity = "Ranah kegiatan wajib diisi";
  }
  if (!activity) {
    errors.activity = "Nama kegiatan wajib diisi";
  }
  return errors;
}

ModalAddSKP = reduxForm({
  form: "ModalAddSKP",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalAddSKP);

function mapStateToProps(state) {
  const { realmActivityList, listActivity } = state;
  const selector = formValueSelector("ModalAddSKP");
  const formValue = selector(state, "realm_activity", "activity");
  return {
    realmActivityList_pending: getRealmActivityListPending(realmActivityList),
    realmActivityList_data: getRealmActivityList(realmActivityList),
    realmActivityList_error: getRealmActivityListError(realmActivityList),
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    formValue,
  };
}

export default connect(mapStateToProps, { realmActivityList, listActivity })(
  withRouter(ModalAddSKP)
);
