import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalJournal } from "../../actions/journal/journal_action";
import {
    getJournalApproval,
    getJournalApprovalPending,
    getJournalApprovalError,
} from "../../reducers/journal/journal_reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

class ModalApproveJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    journalApproval(id) {
        const { approvalJournal, onHide } = this.props;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("is_approve", "true")
        const callback = () => {
            onHide();
            this.props.handleRefresh();
        };
        approvalJournal(formData, callback);
    }

    render() {
        const { approvalJournal_pending } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width"
                // centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menyetujuinya?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={() => this.props.onHide}
                        >
                            BATAL
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={
                                approvalJournal_pending ? (
                                    <CircularProgress size={14} color="secondary" />
                                ) : (
                                        <CheckIcon />
                                    )
                            }
                            onClick={() => this.journalApproval(this.props.id)}
                        >
                            YA
            </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ approvalJournal }) {
    return {
        approvalJournal_pending: getJournalApprovalPending(approvalJournal),
        approvalJournal_data: getJournalApproval(approvalJournal),
        approvalJournal_error: getJournalApprovalError(approvalJournal),
    };
}

export default connect(mapStateToProps, { approvalJournal })(ModalApproveJournal);
