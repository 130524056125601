import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

const bgColor = {
  Aktif: "#E9F8F0",
  AktifLabel: "#2CB96A",
  Approved: "#E9F8F0",
  ApprovedLabel: "#2CB96A",
  Nonaktif: "#FDE9E9",
  NonaktifLabel: "#ED2A2A",
  Baru: "#ffdcbe",
  BaruLabel: "#F57C2B",
  Terverifikasi: "#E9F8F0",
  TerverifikasiLabel: "#2CB96A",
  Disetujui: "#E9F8F0",
  DisetujuiLabel: "#2CB96A",
  Ditolak: "#FDE9E9",
  DitolakLabel: "#ED2A2A",
  Pensiun: "#ffdcbe",
  PensiunLabel: "#F57C2B",
  Menunggu: "#ffdcbe",
  MenungguLabel: "#F57C2B",
  Proses: "#ffdcbe",
  ProsesLabel: "#F57C2B",
  Wafat: "#ededed",
  WafatLabel: "#727272",
  Hadir: "#E9F8F0",
  HadirLabel: "#2CB96A",
  Publish: "#E9F8F0",
  PublishLabel: "#2CB96A",
};

function TableStatusComponent(props) {
  const { status, style, jenisSIP } = props;
  let statusCheck = null;
  if (
    status === "Tidak Hadir" ||
    status === "Overdue" ||
    status === "Revisi" ||
    status === "Failed" ||
    status === "Keluar"
  ) {
    statusCheck = "Ditolak";
  } else if (
    status === "Belum Hadir" ||
    status === "Menunggu Konfirmasi" ||
    status === "Menunggu Verifikasi" ||
    status === "Menunggu Terverifikasi" ||
    status === "Unpaid" ||
    status === "Proses" ||
    status === "Draft" ||
    status === ">65 Tahun" ||
    status === "Belum Lulus"
  ) {
    statusCheck = "Baru";
  } else if (
    status === "Paid" ||
    status === "Late Payment" ||
    status === "Disetujui" ||
    status === "Selesai" ||
    status === "Lulus" ||
    status === "Success"
  ) {
    statusCheck = "Terverifikasi";
  } else {
    statusCheck = status;
  }

  if (jenisSIP) {
    return (
      <span
        style={{
          color: bgColor[statusCheck + "Label"],
          ...style,
        }}
      >
        {status}
      </span>
    );
  }
  return (
    <span
      style={{
        fontSize: 12,
        backgroundColor: bgColor[statusCheck],
        color: bgColor[statusCheck + "Label"],
        ...style,
      }}
      className={css(styles.status)}
    >
      {status}
    </span>
  );
}

TableStatusComponent.propTypes = {
  name: string,
};

const styles = StyleSheet.create({
  status: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 30,
    // color: "white",
  },
});

export default TableStatusComponent;
