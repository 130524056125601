import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { formValueSelector } from "redux-form"; // ES6

import { detailMember, editMember } from "../../actions/member/member_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getMemberPending,
  getDetailMember,
  getMemberError,
} from "../../reducers/member/member_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderInputSosmed,
  renderDate,
} from "../../components/form/FormComponent";
import { getCityList } from "../../reducers/master-data/city_reducer";
import { getUser } from "../../helpers/user";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { listSubspesialis } from "../../actions/master-data/subspesialis_action";
import {
  getSubspesialisPending,
  getListSubspesialis,
  getSubspesialisError,
} from "../../reducers/master-data/subspesialis_reducer";
import { listBranch } from "../../actions/master-data/branch_action";

class MemberDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false, isEditFile: false };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    detailMember(getUser().id);
    this.props.listBranch();
    this.props.listSubspesialis();
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {}

  handleSubmit(values) {
    const { detailMember_data, editMember, detailMember } = this.props;

    let param = {
      ...values,
      id: detailMember_data[0].id,
      place_of_birth: values.place_of_birth?.value,
      identity_type: values.identity_type?.value,
      gender: values.gender?.value,
      religion: values.religion?.value,
      affiliation: values.affiliation,
      name: detailMember_data[0].name,
      subspecialist_id: values.subspecialist?.value,
      mailing_address: values.mailing_address?.value,
    };
    if (
      detailMember_data[0].branch_id === null ||
      detailMember_data[0].branch_id === undefined
    ) {
      param["branch"] = { value: values?.branch };
      param["branch_id"] = values?.branch?.value;
    }

    const callback = () => {
      detailMember(detailMember_data[0].id);
      this.handleTriggerFormEdit();
    };

    editMember(param, callback);
  }

  render() {
    const {
      listCity_data,
      editMember_pending,
      handleSubmit,
      listBranch_data,
      listSubspesialis_data,
    } = this.props;
    const { isEdit } = this.state;
    const { formValue } = this.props;
    let cityOptions = [],
      branchOptions = [],
      subspecialistOptions = [];

    let genderOptions = [
      { label: "Laki-Laki", value: 1 },
      { label: "Perempuan", value: 0 },
    ];
    let identityOptions = [
      { label: "KTP", value: "KTP" },
      { label: "SIM", value: "SIM" },
      { label: "Passport", value: "Passport" },
    ];
    let religionOptions = [
      { label: "Islam", value: "Islam" },
      { label: "Kristen Protestan", value: "Kristen Protestan" },
      { label: "Katolik", value: "Katolik" },
      { label: "Hindu", value: "Hindu" },
      { label: "Buddha", value: "Buddha" },
      { label: "Kong Hu Cu", value: "Kong Hu Cu" },
    ];
    let addressOptions = [
      { label: "Alamat Rumah", value: 1 },
      { label: "Alamat RS Tempat Praktik", value: 2 },
    ];

    if (listCity_data && listCity_data.data.length !== 0) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    }
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    if (listSubspesialis_data && listSubspesialis_data.length !== 0) {
      subspecialistOptions = listSubspesialis_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    let secondary_email, secondary_phone, branch;
    if (formValue) {
      ({ secondary_email, secondary_phone, branch } = formValue);
    }

    return (
      <form
        onSubmit={handleSubmit(this.handleSubmit)}
        style={{ width: "100%" }}
      >
        <Row
          vertical="center"
          horizontal="space-between"
          style={{ width: "100%", marginBottom: 20 }}
        >
          <DetailNameComponent name="Profile" />
          <EditButtonComponent
            isEdit={isEdit}
            triggerIsEdit={this.handleTriggerFormEdit}
            isLoading={editMember_pending}
          />
        </Row>
        <FormContainer label="Subspesialis">
          <Field
            isDetail={!isEdit}
            options={subspecialistOptions}
            name="subspecialist"
            placeholder="Pilih subspesialis"
            component={renderSelect}
          />
        </FormContainer>
        <FormContainer label="NPA IDI">
          <Field
            isDetail={!isEdit}
            name="npa_idi"
            placeholder="Masukkan NPA IDI"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Cabang">
          <Field
            isDisabled={branch ? true : false}
            isDetail={!isEdit}
            isAsync
            asyncUrl="master_data/branch/find"
            options={branchOptions}
            name="branch"
            placeholder="Pilih cabang"
            component={renderSelect}
          />
        </FormContainer>
        <FormContainer label="Tempat, Tgl Lahir">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            vertical="center"
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                name="place_of_birth"
                placeholder="Masukkan Kota"
                options={cityOptions}
                component={renderSelect}
              />
            </Column>
            {!isEdit && <div className={css(styles.dot)} />}
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                name="birthday"
                component={renderDate}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="No Identitas">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            vertical="center"
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                options={identityOptions}
                name="identity_type"
                placeholder="Pilih Jenis Identitas"
                component={renderSelect}
              />
            </Column>
            {!isEdit && <div className={css(styles.dot)} />}
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="number"
                name="identity_no"
                placeholder="Masukkan nomor identitas"
                component={renderInput}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Suku Bangsa">
          <Field
            isDetail={!isEdit}
            name="tribes"
            placeholder="Masukkan suku bangsa"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Jenis Kelamin">
          <Field
            isDetail={!isEdit}
            options={genderOptions}
            name="gender"
            placeholder="Pilih jenis kelamin"
            component={renderSelect}
          />
        </FormContainer>
        <FormContainer label="Agama">
          <Field
            isDetail={!isEdit}
            options={religionOptions}
            name="religion"
            placeholder="Pilih Agama"
            component={renderSelect}
          />
        </FormContainer>
        <FormContainer label="Anggota Organisasi">
          <Field
            isDetail={!isEdit}
            name="affiliation"
            placeholder="Masukkan keanggotaan"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="No Telepon">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="number"
                name="primary_phone"
                placeholder="Masukkan telepon utama"
                component={renderInput}
              />
            </Column>
            {!isEdit && secondary_phone && <div className={css(styles.dot)} />}

            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="number"
                name="secondary_phone"
                placeholder="Masukkan telepon alternatif"
                component={renderInput}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Email">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            vertical="center"
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="email"
                name="primary_email"
                placeholder="Masukkan email utama"
                component={renderInput}
              />
            </Column>
            {!isEdit && secondary_email && <div className={css(styles.dot)} />}
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                type="email"
                isDetail={!isEdit}
                name="secondary_email"
                placeholder="Masukkan email alternatif"
                component={renderInput}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Alamat Rumah">
          <Field
            isDetail={!isEdit}
            name="home_address"
            placeholder="Masukkan alamat rumah"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Alamat RS Tempat Praktik">
          <Field
            isDetail={!isEdit}
            name="office_address"
            placeholder="Masukkan alamat RS tempat praktik"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Alamat Surat">
          <Field
            isDetail={!isEdit}
            options={addressOptions}
            name="mailing_address"
            placeholder="Pilih Alamat Surat"
            component={renderSelect}
          />
        </FormContainer>
        <FormContainer label="Telepon Kantor">
          <Field
            isDetail={!isEdit}
            type="number"
            name="office_phone"
            placeholder="Masukkan telepon kantor"
            component={renderInput}
          />
        </FormContainer>
        <FormContainer label="Kontak Darurat">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            vertical="center"
          >
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                name="contact_emergency_name"
                placeholder="Masukkan nama"
                component={renderInput}
              />
            </Column>
            {!isEdit && <div className={css(styles.dot)} />}
            <Column style={{ width: isEdit ? "48%" : "" }}>
              <Field
                isDetail={!isEdit}
                type="number"
                name="contact_emergency_phone"
                placeholder="Masukkan nomor darurat"
                component={renderInput}
              />
            </Column>
          </Row>
        </FormContainer>
        <FormContainer label="Media Sosial">
          <Row
            style={{ width: "100%" }}
            horizontal={isEdit ? "space-between" : ""}
            wrap
          >
            <Column style={{ width: isEdit ? "48%" : "60px" }}>
              <Field
                isDetail={!isEdit}
                name="twitter"
                placeholder="Username twitter"
                component={renderInputSosmed}
                startIcon={(props) => (
                  <div className={css(styles.icon)}>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      size="lg"
                      color="#495057"
                      {...props}
                    />
                  </div>
                )}
              />
            </Column>
            <Column style={{ width: isEdit ? "48%" : "60px" }}>
              <Field
                isDetail={!isEdit}
                name="fb"
                placeholder="Username facebook"
                component={renderInputSosmed}
                startIcon={(props) => (
                  <div className={css(styles.icon)}>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="lg"
                      color="#495057"
                      {...props}
                    />
                  </div>
                )}
              />
            </Column>
            <Column style={{ width: isEdit ? "48%" : "60px" }}>
              <Field
                isDetail={!isEdit}
                name="linkedin"
                placeholder="Username linkedin"
                component={renderInputSosmed}
                startIcon={(props) => (
                  <div className={css(styles.icon)}>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="lg"
                      color="#495057"
                      {...props}
                    />
                  </div>
                )}
              />
            </Column>
            <Column style={{ width: isEdit ? "48%" : "60px" }}>
              <Field
                isDetail={!isEdit}
                name="instagram"
                placeholder="Username instagram"
                component={renderInputSosmed}
                startIcon={(props) => (
                  <div className={css(styles.icon)}>
                    <FontAwesomeIcon
                      icon={faInstagram}
                      color="#495057"
                      size="lg"
                      {...props}
                    />
                  </div>
                )}
                // startIcon={EditIcon}
              />
            </Column>
          </Row>
        </FormContainer>
      </form>
    );
  }
}

const styles = StyleSheet.create({
  dot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: "black",
    margin: "0px 10px",
  },
  icon: {
    position: "absolute",
    top: 0,
    height: 20,
    color: "#495057",
    width: 20,
    // left: 10,
  },
});

function validate(values) {
  const {
    place_of_birth,
    birthday,
    identity_type,
    identity_no,
    tribes,
    gender,
    religion,
    primary_phone,
    primary_email,
    home_address,
    office_address,
    contact_emergency_name,
    contact_emergency_phone,
  } = values;
  const errors = {};
  if (!place_of_birth) {
    errors.place_of_birth = "Tempat lahir wajib diisi";
  }
  if (!birthday) {
    errors.birthday = "Tanggal lahir wajib diisi";
  }
  if (!identity_type) {
    errors.identity_type = "Jenis identitas wajib diisi";
  }
  if (!identity_no) {
    errors.identity_no = "Nomor identitas wajib diisi";
  }
  if (!tribes) {
    errors.tribes = "Suku bangsa wajib diisi";
  }
  if (!gender) {
    errors.gender = "Jenis Kelamin wajib diisi";
  }
  if (!religion) {
    errors.religion = "Agama wajib diisi";
  }
  if (!primary_phone) {
    errors.primary_phone = "Telepon utama wajib diisi";
  }

  if (!primary_email) {
    errors.primary_email = "Email utama wajib diisi";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(primary_email)) {
    errors.primary_email = "format email salah";
  }

  if (!home_address) {
    errors.home_address = "Alamat Rumah wajib diisi";
  }

  if (!office_address) {
    errors.office_address = "Alamat RS tempat praktik wajib diisi";
  }
  // if (!mailing_address,,) {
  //   errors.place_of_birth = "Tempat lahir wajib diisi";
  // }
  if (!contact_emergency_name) {
    errors.contact_emergency_name = "Nama Kontak wajib diisi";
  }
  if (!contact_emergency_phone) {
    errors.contact_emergency_phone = "Nomor Kontak wajib diisi";
  }
  // if (!twitter) {
  //   errors.twitter = "Tempat lahir wajib diisi";
  // }
  // if (!linkedin) {
  //   errors.linkedin = "Tempat lahir wajib diisi";
  // }
  // if (!fb) {
  //   errors.fb = "Tempat lahir wajib diisi";
  // }
  // if (!instagram) {
  //   errors.instagram = "Tempat lahir wajib diisi";
  // }

  return errors;
}

MemberDetail = reduxForm({
  // a unique name for the form
  form: "MemberDataDiri",
  // validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(MemberDetail);

function mapStateToProps(state) {
  const { detailMember, editMember, listCity, listBranch, listSubspesialis } =
    state;
  let initialValues = {};
  const detailMember_data = getDetailMember(detailMember);
  let place_of_birth,
    identity_type,
    gender,
    branch_data,
    religion,
    email,
    mailing_address,
    member_profile;
  if (detailMember_data && detailMember_data.length !== 0) {
    [
      {
        email,
        member_profile,
        member_profile: {
          place_of_birth,
          identity_type,
          gender,
          religion,
          mailing_address,
          gender,
        },
        branch_data,
      },
    ] = detailMember_data;
    initialValues = {
      ...member_profile,
      primary_email: email,
      mailing_address: {
        label:
          mailing_address === 1 ? "Alamat Rumah" : "Alamat RS Tempat Praktik",
        value: mailing_address,
      },
      subspecialist: {
        label: member_profile.subspecialist_data
          ? member_profile.subspecialist_data.name
          : null,
        value: member_profile.subspecialist_data
          ? member_profile.subspecialist_data.id
          : null,
      },
    };
  }

  if (place_of_birth) {
    initialValues.place_of_birth = {
      label: place_of_birth,
      value: place_of_birth,
    };
  }
  if (branch_data) {
    initialValues.branch = {
      label: branch_data.name,
      value: branch_data.id,
    };
  }
  if (identity_type) {
    initialValues.identity_type = {
      label: identity_type,
      value: identity_type,
    };
  }
  if (gender !== null) {
    initialValues.gender = {
      label: gender === 0 ? "Perempuan" : "Laki-laki",
      value: gender,
    };
  }
  if (religion) {
    initialValues.religion = { label: religion, value: religion };
  }

  const selector = formValueSelector("MemberDataDiri");
  const formValue = selector(
    state,
    "secondary_email",
    "secondary_phone",
    "branch"
  );
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_error: getMemberError(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    editMember_error: getMemberError(editMember),
    editMember_pending: getMemberPending(editMember),
    listCity_data: getCityList(listCity),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listSubspesialis_data: getListSubspesialis(listSubspesialis),
    listSubspesialis_error: getSubspesialisError(listSubspesialis),
    listSubspesialis_pending: getSubspesialisPending(listSubspesialis),
    initialValues,
    formValue,
  };
}

export default connect(mapStateToProps, {
  detailMember,
  editMember,
  listBranch,
  listSubspesialis,
})(withRouter(MemberDetail));
