import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import IconError from '../../assets/icon-error';
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import { getRegistrationListPending, getRegistrationList, getRegistrationListError } from "../../reducers/registrasi_sp2/registrasi_reducer";
import { connect } from "react-redux";

class DetailRegistrasiPendidikanAsal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  informasiUmum(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-umum/${id}`)
  }

  pendidikanAsal(id) {
    this.props.history.push(`/registrasi-sp2/detail/pendidikan-asal/${id}`)
  }

  informasiPPDS(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-ppds/${id}`)
  }

  componentDidMount() {
    let param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: ""
    };
    this.props.registrationList(param_registrationList);
    const { match: { params: { id } } } = this.props;
    param_registrationList.id = id;
    this.props.registrationList(param_registrationList);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { registrationList_data } = this.props;
    let data = [];
    let prodi_asal, status, messageTitle, message, imgUrl, reject_reason = "";
    if (registrationList_data) {
      data = registrationList_data.data[0];
      prodi_asal = data.university_before_data.abbreviation + " - Fakultas Kedokteran " + data.university_before_data.name;
      status = data.status_registration;
      messageTitle = status === 0 ? "Baru" : status === 1 ? "Terverifikasi" : "Ditolak";
      message = status === 0 ? "Verifikasi berkas pembayaran" : status === 1 ? "Konfirmasi hasil tes peserta" : "Berkas bukti pendaftaran tidak Sesuai. Silahkan upload ulang berkas pembayaran.";
      reject_reason = data.ppds_reject_data.length > 0 ? data.ppds_reject_data[0].notes : "";
      imgUrl = data.ppds_reject_data.length > 0 ? data.ppds_reject_data[0].attachment_list[0].attachment_name : "";
    }
    return (
      <ContentContainer>
        <Row>
          <Row className={(css(styles.leftDetailContainer))}>
            <Column >
              <DetailNameComponent name={data.name} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={data.status_registration}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl}
                reason={reject_reason}
              />
              <DetailMenuComponent menuName="Informasi Umum" onClick={() => this.informasiUmum(data.id)} />
              <DetailMenuComponent menuName="Pendidikan Asal" isActive={true} />
              <DetailMenuComponent menuName="Informasi PPDS" onClick={() => this.informasiPPDS(data.id)} />
            </Column>
          </Row>
          <Row className={(css(styles.rightDetailContainer))}>
            <Column className={(css(styles.rightDetailColumn))}>
              <DetailTitleComponent title="Pendidikan Asal" />
              <DetailContentComponent label="Prodi Asal" value={prodi_asal} />
              <DetailContentComponent label="Tahun Kelulusan" value={data.graduated} />
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%"
  },
  rightDetailColumn: {
    width: "100%"
  }
});

function mapStateToProps({ registrationList }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
  };
}


export default connect(mapStateToProps, { registrationList })(withRouter(DetailRegistrasiPendidikanAsal));
