import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { Route, Switch } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import { detailMember } from "../../actions/member/member_action";
import { listCity } from "../../actions/master-data/city_action";
import { getUser } from "../../helpers/user";
import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProfileAvatar from "./ProfileAvatar";
import ProfilePengantar from "./ProfilePengantar";
import ProfileDataDiri from "./ProfileDataDiri";
import { listBranch } from "../../actions/master-data/branch_action";
import ProfilePendukung from "./ProfilePendukung";
import {
  listSubspesialis
} from "../../actions/master-data/subspesialis_action";
import {
  getSubspesialisPending,
  getListSubspesialis,
  getSubspesialisError,
} from "../../reducers/master-data/subspesialis_reducer";
import ModalChangePassword from "./Modal_change_password";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum", data: null };
    // this.ref = React.createRef(TMUIRichTextEditorRef, null);
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    const { detailMember, listCity, listBranch, listSubspesialis, show } = this.props;
    const id = getUser().id;
    const is_default = getUser().is_default;
    detailMember(id);
    listCity({ length: 999999 });
    listBranch({ length: 999999 });
    listSubspesialis({ length: 999999 });
    if (is_default == 1) {
      this.state.show = 1
    }
  }
  
  setSelectedItem(item) {
    this.setState({ selectedItem: item });
    this.props.history.push(`/member/member-profile/${item}`);
  }

  render() {
    return (
      <ContentContainer>
         <ModalChangePassword
        show={this.state.show}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      ></ModalChangePassword>
        <Row>
          <LeftContainerComponent>
            <ProfileAvatar />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pengantar")}
              menuName="Pengantar"
              isActive={this.state.selectedItem === "pengantar"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("profile")}
              menuName="Profile"
              isActive={this.state.selectedItem === "profile"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pendukung")}
              menuName="Berkas Pendukung"
              isActive={this.state.selectedItem === "pendukung"}
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <Switch>
              <Route
                path="/member/member-profile/pengantar"
                exact
                component={ProfilePengantar}
              />
              <Route
                path="/member/member-profile/profile"
                exact
                component={ProfileDataDiri}
              />
              <Route
                path="/member/member-profile/pendukung"
                exact
                component={ProfilePendukung}
              />
              {/* <Route
                path="/ppds/detail/:id/pendidikan"
                exact
                component={PendidikanPPDS}
              />
              <Route
                path="/member-profile/profile"
                exact
                component={InformasiPPDS}
              /> */}
            </Switch>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function mapStateToProps({ detailMember, listSubspesialis }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    listSubspesialis_data: getListSubspesialis(listSubspesialis),
    listSubspesialis_error: getSubspesialisError(listSubspesialis),
    listSubspesialis_pending: getSubspesialisPending(listSubspesialis),
  };
}

export default connect(mapStateToProps, {
  detailMember,
  listCity,
  listBranch,
  listSubspesialis
})(withRouter(Profile));
