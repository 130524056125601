import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from "../../assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import { listCooperative } from "../../actions/dashboard/koperasi_action";
import {
  getDashboardData,
  getDashboardPending,
} from "../../reducers/dashboard/koperasi_reducer";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import SelectComponent from "../../components/form/SelectComponent";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CardKoperasi extends Component {
  constructor() {
    super();
    this.state = {
      branch_id: null,
      start_year: 2016,
      end_year: 2021,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
  }
  componentDidMount() {
    this.props.listCooperative();
    this.props.listBranch({length: 999});
  }

  handleSelectChange(e) {
    const { start_year, end_year } = this.state;
    if (e) {
      this.setState({ branch_id: e.value });
      this.props.listCooperative({
        branch_id: e.value,
        start_year: start_year,
        end_year: end_year,
      });
    } else {
      this.props.listCooperative({
        start_year: start_year,
        end_year: end_year,
      });
    }
  }
  handleYearSelectChange(e) {
    const { branch_id } = this.state;
    if (e) {
      this.setState({ start_year: e.value - 5, end_year: e.value });
      this.props.listCooperative({
        start_year: e.value - 5,
        end_year: e.value,
        branch_id: branch_id,
      });
    } else {
      this.props.listCooperative({ branch_id: branch_id });
    }
  }

  render() {
    const {
      listCooperative_data,
      listBranch_data,
      listBranch_pending,
      listCooperative_pending,
    } = this.props;
    const { end_year, branch_id } = this.state;
    let data = [],
      branchOptions = [],
      result = null;
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => {
        return { label: name, value: id };
      });
      branchOptions.push({ value: null, label: "Semua Cabang" });
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    if (listCooperative_data) {
      data = listCooperative_data.map(({ year, total }) => ({
        label: year,
        y: total,
      }));

      const options = {
        animationEnabled: true,
        height: 240,
        axisX: {
          valueFormatString: "YYYY",
        },
        axisY: {
          includeZero: true,
          gridThickness: 0,
        },
        data: [
          {
            xValueFormatString: "YYYY",
            intervalType: "year",
            interval: 1,
            type: "splineArea",
            lineColor: "#5AB853",
            color: "#C9E8D4",
            lineThickness: 8,
            markerColor: "white",
            markerBorderColor: "#5AB853",
            markerBorderThickness: 4,
            markerSize: 10,
            dataPoints: data,
          },
        ],
      };
      result = <CanvasJSChart options={options} />;
    }

    if (listBranch_pending || listCooperative_pending) {
      result = null;
    }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Column
          style={{
            boxShadow: "0px 3px 6px #00000029",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "100%",
            minHeight: 300,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div className={css(styles.cardHeader)}></div>

          {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          <Row
            className={css(styles.wrapperTop, styles.borderBottom)}
            style={{ width: "100%", padding: 20, backgroundColor: "white" }}
            vertical="center"
            horizontal="space-between"
          >
            <label className={css(styles.titleLabel)}>
              {" "}
              Keanggotaan Koperasi Member PABOI
            </label>
            <Row>
              <div style={{ width: 150, margin: "0 10px" }}>
                <SelectComponent
                  options={yearOptions}
                  isRounded
                  onChange={this.handleYearSelectChange}
                  defaultValue={{ value: end_year, label: end_year }}
                  placeholder="Pilih Tahun"
                />
              </div>
              <div style={{ width: 200 }}>
                <SelectComponent
                  options={branchOptions}
                  isRounded
                  onChange={this.handleSelectChange}
                  defaultValue={{
                    value: null,
                    label: "Semua Cabang"
                  }}
                  placeholder="Pilih Cabang"
                />
              </div>
            </Row>
          </Row>
          <Column
            className={css(styles.wrapper)}
            style={{
              width: "100%",
              padding: "20px 30px",
              backgroundColor: "red",
            }}
          >
            {result || ""}
          </Column>
        </Column>
      </div>
    );
  }
}

function mapStateToProps({ listCooperative, listBranch }) {
  return {
    listCooperative_data: getDashboardData(listCooperative),
    listCooperative_pending: getDashboardPending(listCooperative),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
  };
}

export default connect(mapStateToProps, {
  listCooperative,
  listBranch,
})(CardKoperasi);

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    // padding: 15,
    // height: 400,
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#5AB853",
    height: 10,
    width: "100%",
  },
});
