import React, { Component } from "react";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEventPending,
  getDetailEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
class Pembayaran extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    let result = {
      status: null,
    };
    const {
      detailEvent_data,
      match: {
        params: { id_member },
      },
    } = this.props;

    if (detailEvent_data && detailEvent_data.length !== 0) {
      const member = detailEvent_data[0].event_participants.find(
        ({ id }) => id_member === `${id}`
      );
      if (member) {
        result.status = member.acceptance_status;
      }
      const data = detailEvent_data[0];
      result.event_name = data.event_name;
    }

    const { status, berkas } = result;

    return (
      <>
        <DetailNameComponent name="Informasi Pembayaran" />
        <DetailContentComponent
          label="Status"
          style={{
            color: statusColor[status],
            fontWeight: "bold",
          }}
          value={
            status === 0
              ? "Baru"
              : status === 1
              ? "Terverifikasi"
              : status === 2
              ? "Ditolak"
              : "-"
          }
        />
        <DetailContentComponent label="Berkas Pembayaran" value={berkas} />
      </>
    );
  }
}

function mapStateToProps({ detailEvent }) {
  return {
    detailEvent_data: getDetailEvent(detailEvent),
    detailEvent_error: getEventError(detailEvent),
    detailEvent_pending: getEventPending(detailEvent),
  };
}

export default connect(mapStateToProps)(withRouter(Pembayaran));
