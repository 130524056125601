import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import {
  listPractice,
} from "../../actions/sub-menu/practice_action";
import {
  getPracticePending,
  getListPractice,
  getPracticeError,
} from "../../reducers/sub-menu/practice_reducer";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addSip } from "../../actions/p2kb/sip_action";
import {
  renderInput,
  renderDatepicker,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getSipPending,
} from "../../reducers/p2kb/sip_reducer";
import { getUser } from "../../helpers/user";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import moment from "moment";

class ModalSipAdd extends Component {
  constructor(props) {
    super(props);
    if (getUser().role_codes[0] === "member") {
      this.state = {
        user_id: getUser().id,
        search: "",
        practiceTime: [],
        listHospital: [],
        timeList: [],
        status: "",
        id: null
      };
    } else {
      this.state = {
        user_id: "",
        search: "",
        status: "",
        id: null,
        timeList: [],
      };
    }
    this.handleChangeHospital = this.handleChangeHospital.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  componentDidMount() {
    const { listPractice, listPractice_data } = this.props;
    listPractice({ user_id: this.state.user_id });
    let practiceTime, hospitalOptions = [];
  }



  handleChangeHospital(value) {
    const { listPractice_data } = this.props;
    console.log(this.props)
    console.log("aku ada dimana")
    this.setState({
      practiceTime: listPractice_data.data[value.key].member_practice_place_days
    })
  }

  handleSubmit(values) {
    const { addSip, onHide, id, handleRefresh } = this.props;
    //console.log(values)
    const param = {
      id: id,
      hospital_id: values.hospital.hospital_id,
      member_practice_place_id: values.hospital.value,
      sip_no: values.sip_no,
      start_date: values.start_date,
      end_date: values.end_date,
      file: values.berkas,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    addSip(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      listPractice,
      listPractice_data,
      addSip_pending,
    } = this.props;
    let hospitalOptions = [];
    let practiceTime = [];
    let day = [
      "-",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
      "Minggu",
    ];
    if (listPractice_data) {
      listPractice_data.data.map((item, idx) => {
        practiceTime.push(item.member_practice_place_days)
        hospitalOptions.push({
          label: item.hospital_data.name,
          key: idx,
          hospital_id: item.hospital_data.id,
          value: item.id
        })
      })
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SIP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <LabelInputVerticalComponent
                  label="Nomor SIP"
                  marginTop="20px"
                ></LabelInputVerticalComponent>
                <Field
                  name="sip_no"
                  type="text"
                  placeholder="Masukkan Nomor SIP"
                  component={renderInput}
                />

                <Row horizontal="space-between">
                  <div>
                    <LabelInputVerticalComponent
                      label="Tanggal Pengesahan"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="start_date"
                      type="text"
                      placeholder="Pilih RS"
                      component={renderDatepicker}
                    />
                  </div>

                  <div>
                    <LabelInputVerticalComponent
                      label="Berlaku Sampai"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="end_date"
                      type="text"
                      placeholder="Pilih RS"
                      component={renderDatepicker}
                    />
                  </div>
                </Row>

                <LabelInputVerticalComponent
                  label="Nama RS"
                  marginTop="20px"
                ></LabelInputVerticalComponent>
                <Field
                  options={hospitalOptions}
                  name="hospital"
                  onChange={this.handleChangeHospital}
                  placeholder="Pilih RS"
                  component={renderSelect}
                />

                <LabelInputVerticalComponent
                  label="Waktu Praktek"
                  marginTop="20px"
                ></LabelInputVerticalComponent>
                {this.state.practiceTime.length > 0 &&
                  <table style={{ textAlign: 'center', marginBottom: 10 }}>
                    <thead>
                      <tr>
                        <th>Hari</th>
                        <th>Jam Mulai</th>
                        <th>Jam Selesai</th>
                      </tr>
                    </thead>
                    <tbody>

                      {this.state.practiceTime.map((item, idx) => {
                        return (
                          <tr>
                            <td>{day[item.day_code]}</td>
                            <td>{item.start_time}</td>
                            <td>{item.end_time}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                }


                {/* <Field
                  disabled
                  name="end_date"
                  type="text"
                  placeholder="Pilih RS"
                  component={renderInput}
                />
 */}
                <Field
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addSip_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addSip_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  console.log(values, "value")
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { sip_no, start_date, end_date, berkas, hospital } = values;
  const errors = {};
  if (!sip_no) {
    errors.sip_no = "Nomor SIP tidak boleh kosong";
  }
  if (!start_date) {
    errors.start_date = "Tanggal Pengesahan SIP tidak boleh kosong";
  }
  if (!end_date) {
    errors.end_date = "Tanggal Akhir SIP tidak boleh kosong";
  }
  if (!berkas) {
    errors.berkas = "Berkas Wajib Disertakan";
  }
  if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
  }
  else if (values.berkas && (values.berkas.size > maxFileSize)){
    errors.berkas = "File lebih dari 500KB"
  }
  if (!values.hospital){
    errors.hospital = "Nama RS tidak boleh kosong"
  }
  return errors;
}

ModalSipAdd = reduxForm({
  // a unique name for the form
  form: "ModalAddSip",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalSipAdd);

function mapStateToProps({ addSip, listPractice }) {
  let initialValues = {
    start_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD"),
    end_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD")
    
  };
  return {
    initialValues,
    addSip_pending: getSipPending(addSip),
    listPractice_data: getListPractice(listPractice),
    listPractice_error: getPracticeError(listPractice),
    listPractice_pending: getPracticePending(listPractice),

  };
}

export default connect(mapStateToProps, {
  addSip, listPractice
})(withRouter(ModalSipAdd));
