import React, { Component } from "react";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEventPending,
  getDetailEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
import moment from "moment";
import localization from "moment/locale/id";
class UmumEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
  }

  render() {
    let result = {};
    const {
      detailEvent_data,
      match: {
        params: { id_member },
      },
    } = this.props;

    if (detailEvent_data && detailEvent_data.length !== 0) {
      const member = detailEvent_data[0].event_participants.find(
        ({ id }) => id_member === `${id}`
      );
      if (member) {
        result.abstract_title = member.abstract_title;
        result.abstract_type = member.abstract_type;
        result.membership = member.membership;
        result.fullname = member.fullname;
        result.registration_date = moment(member.created_at)
          .locale("id", localization)
          .format("DD MMMM YYYY");
        result.registration_no = member.register_number;
      }
      const data = detailEvent_data[0];
      result.event_name = data.event_name;
    }

    const { abstract_title, abstract_type, fullname, abstract } = result;

    return (
      <>
        <DetailNameComponent name="Abstrak" />
        <DetailContentComponent label="Judul" value={abstract_title} />
        <DetailContentComponent label="Tipe Abstrak" value={abstract_type} />
        <DetailContentComponent label="Nama Penulis" value={fullname} />
        <DetailContentComponent label="Abstrak" value={abstract} />
      </>
    );
  }
}

function mapStateToProps({ detailEvent }) {
  return {
    detailEvent_data: getDetailEvent(detailEvent),
    detailEvent_error: getEventError(detailEvent),
    detailEvent_pending: getEventPending(detailEvent),
  };
}

export default connect(mapStateToProps)(withRouter(UmumEvent));
