import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Row } from "simple-flexbox";
import { Route, Switch } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import Button from "@material-ui/core/Button";
import UmumEvent from "./UmumEvent";
import Pembayaran from "./Pembayaran";
import Abstrak from "./Abstrak";
import EventModalApprove from "./EventModalApprove";
import EventModalReject from "./EventModalReject";
import { detailEvent } from "../../actions/event/event_action";
import {
  getEventPending,
  getDetailEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class DetailPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "umum",
      showApproveModal: false,
      showRejectModal: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailEvent } = this.props;
    const {
      match: {
        params: { id_event },
      },
    } = this.props;
    detailEvent(id_event);
  }

  setSelectedItem(item) {
    const {
      match: {
        params: { id_event, id_member },
      },
    } = this.props;

    this.setState({ selectedItem: item });
    this.props.history.push(
      `/event/participant-detail/${id_event}/${id_member}/${item}`
    );
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const {
      detailEvent_data,
      match: {
        params: { id_member, menu },
      },
    } = this.props;
    const { showApproveModal, showRejectModal } = this.state;
    let data = {};
    if (detailEvent_data && detailEvent_data.length !== 0) {
      const result = detailEvent_data[0].event_participants.find(({ id }) => {
        return id_member === `${id}`;
      });
      if (result) {
        data = result;
      }
    }

    const { acceptance_status, reject_payment_notes } = data;
    return (
      <ContentContainer>
        <EventModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <EventModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={data.fullname} />
            <StatusSquareComponent
              statusLabel={
                acceptance_status === 0
                  ? "Menunggu verifikasi"
                  : acceptance_status === 1
                  ? "Data Pembayaran Terverifikasi"
                  : reject_payment_notes
              }
              statusTitle={
                acceptance_status === 0
                  ? "Baru"
                  : acceptance_status === 1
                  ? "Terverifikasi"
                  : "Ditolak"
              }
              status={acceptance_status}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("umum")}
              menuName="Informasi Umum"
              isActive={this.state.selectedItem === "umum"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("abstrak")}
              menuName="Abstrak"
              isActive={this.state.selectedItem === "abstrak"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pembayaran")}
              menuName="Informasi Pembayaran"
              isActive={this.state.selectedItem === "pembayaran"}
            />

            {menu === "pembayaran" && acceptance_status === 0 && (
              <Row
                style={{ width: "100%", marginTop: 40 }}
                horizontal="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <Switch>
              <Route
                path="/event/participant-detail/:id_event/:id_member/umum"
                exact
                component={UmumEvent}
              />
              <Route
                path="/event/participant-detail/:id_event/:id_member/abstrak"
                exact
                component={Abstrak}
              />
              <Route
                path="/event/participant-detail/:id_event/:id_member/pembayaran"
                exact
                component={Pembayaran}
              />
            </Switch>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

function mapStateToProps({ detailEvent }) {
  return {
    detailEvent_data: getDetailEvent(detailEvent),
    detailEvent_error: getEventError(detailEvent),
    detailEvent_pending: getEventPending(detailEvent),
  };
}

export default connect(mapStateToProps, { detailEvent })(
  withRouter(DetailPPDS)
);
