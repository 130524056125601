/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import IconError from "../../assets/icon-error";
import InputComponent from "../../components/form/InputComponent";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
// import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { SIPList, editSIP } from "../../actions/pengajuan-sip/sip_action";
import {
  getSIPPending,
  getSIPList,
  getSIPError,
  getSIPEdit,
  getSIPEditPending,
} from "../../reducers/pengajuan-sip/sip_reducer";
import { connect } from "react-redux";
import { getUser } from "../../helpers/user";
import ModalApprovalSIP from "./Modal_approval_SIP";
import ModalRejectSIP from "./Modal_reject_SIP";
import ModalPenerbitanSurat from "./Modal_penerbitan_surat_SIP";
// import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import moment from "moment";
import localization from "moment/locale/id";
import FormContainer from "../../components/container/FormContainer";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
  renderInput,
  renderInputWithLabel,
  renderSelect,
} from "../../components/form/FormComponent";
import { listHospital } from "../../actions/master-data/hospital_action";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { memberList } from "../../actions/member/member_action";
import { getMemberList } from "../../reducers/member/member_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import { default_api } from "../../constants/default_api";

// import ModalSuratPermohonan from "./Modal_surat_permohonan";

function getMessageTitle(data) {
  if (data.status === 0) {
    return "Proses";
  }
  if (data.status === 1 && data.step === 2) {
    return "Disetujui";
  }
  if (data.status === 1 && data.step === 3) {
    return "Terverifikasi";
  }

  return "Ditolak";
}

function getMessageStatus(data) {
  if (data.status === 0 && data.step === 1) {
    return "Menunggu persetujuan Admin PABOI Cabang";
  }
  if (data.status === 0 && data.step === 2) {
    return "Menunggu persetujuan Ketua PABOI Cabang";
  }
  if (data.status === 1 && data.step === 3) {
    return "Telah disetujui oleh Ketua Paboi Cabang";
  }
  if (data.status === 2 && data.step === 1) {
    return "Ditolak oleh Admin Paboi Cabang";
  }
  if (data.status === 2 && data.step === 2) {
    return "Ditolak oleh Ketua Paboi Cabang";
  }
}

class DetailSIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDate: "",
      forRefresh: false,
      modalApprovalSIPShow: false,
      modalRejectSIPShow: false,
      modalPenerbitanSuratShow: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      modalSuratPermohonanShow: false,
      modal_title: "",
      imgUrl: "",
      id: "",
      isEdit: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    let param_SIPList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_SIPList.id = id;
    this.props.SIPList(param_SIPList);
  }

  handleOpenDocument(imgUrl, modal_title, folder = "") {
    if (modal_title === "Surat Rekomendasi") {
      window.open(
        default_api + "/member/download/sip_request/" + imgUrl + ".pdf"
      );
    } else {
      this.setState({ folder: folder });
      this.setState({ imgUrl: imgUrl });
      this.setState({ modal_title: modal_title });
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true });
      } else {
        this.setState({ modalDocumentImgShow: true });
      }
    }
  }

  // handleOpenLetter(id, modal_title) {
  //     this.setState({ id: id })
  //     this.setState({ modal_title: modal_title })
  //     this.setState({ modalSuratPermohonanShow: true })
  // }

  componentDidMount() {
    let param_SIPList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_SIPList.id = id;
    this.props.SIPList(param_SIPList);
    if (getUser().role_codes[0] === "member") {
      this.props.listHospital();
      this.props.listBranch({ length: 999 });
    }
    this.props.memberList();
  }

  shouldComponentRender() {
    const { SIPList_pending } = this.props;
    if (SIPList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
    // this.setState({ isEditFile: false });
    this.props.reset();
  };

  handleSubmit = (values) => {
    const { SIPList_data, editSIP, SIPList } = this.props;

    let date = new Date(SIPList_data.data[0].date).toISOString();

    const param = {
      date,
      branch_to: values.branch_to.value,
      hospital_id_to: values.hospital_id_to.value,
      id: SIPList_data.data[0].id,
      str_no: SIPList_data.data[0].str_no,
      sip_no: SIPList_data.data[0].sip_no,
      serkom_no: SIPList_data.data[0].serkom_no,
      attachments: values.file.map((item) => ({
        attachment_name: item.name,
        attachment_filename: item.value,
      })),
    };

    const callback = () => {
      SIPList();
      this.handleTriggerFormEdit();
    };
    editSIP(param, callback);
  };

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  };

  render() {
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder,
      isEdit,
    } = this.state;
    const user = getUser().role_codes[0];
    const user_branch = getUser().branch_id;
    const {
      SIPList_data,
      handleSubmit,
      editSIP_pending,
      listBranch_data,
      listHospital_data,
      initialValues,
    } = this.props;
    let data = [];
    let branchOptions = [];
    let hospitalOptions = [];
    let id,
      date,
      status,
      step,
      name,
      messageTitle,
      surat_pendukung_filename,
      message,
      request_code,
      imgUrl_reject,
      branch_from_id,
      reason,
      str_filename,
      ktp_filename,
      sip_filename,
      serkom_filename,
      member_id = "";
    if (SIPList_data) {
      data = SIPList_data.data[0];
      id = data.id;
      date = moment(data.date)
        .locale("id", localization)
        .format("DD MMMM YYYY");
      member_id = data.user_data.id;
      name = data.user_data.name;
      status = data.status;
      step = data.step;
      branch_from_id = data.branch_from;
      request_code = data.request_code;
      imgUrl_reject = data.reject_filename;
      reason = data.reject_notes;
      str_filename = data.str_filename;
      surat_pendukung_filename = data.surat_pendukung_filename;
      sip_filename = data.sip_filename;
      serkom_filename = data.serkom_filename;
      ktp_filename = data.ktp_filename;
      messageTitle = getMessageTitle(data);
      message = getMessageStatus(data);
    }
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    const count_practice =
      SIPList_data?.data?.length > 0 ? SIPList_data.data[0].count_practice : "";

    const CVfilename = getUser().id;

    return (
      <ContentContainer>
        <ModalApprovalSIP
          show={this.state.modalApprovalSIPShow}
          onHide={() => this.setState({ modalApprovalSIPShow: false })}
          id={id}
          handleRefresh={this.handleRefresh}
        />
        <ModalRejectSIP
          show={this.state.modalRejectSIPShow}
          onHide={() => this.setState({ modalRejectSIPShow: false })}
          id={id}
          handleRefresh={this.handleRefresh}
        />
        <ModalPenerbitanSurat
          show={this.state.modalPenerbitanSuratShow}
          onHide={() => this.setState({ modalPenerbitanSuratShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        {/* <ModalSuratPermohonan
                    show={this.state.modalSuratPermohonanShow}
                    onHide={() => this.setState({ modalSuratPermohonanShow: false })}
                    id={this.state.id}
                    modal_title={this.state.modal_title}
                /> */}
        <Row style={{ minHeight: "78vh" }}>
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={name} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={status}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl_reject}
                folder={"member_sip"}
                reason={reason}
              />
              {user === "admin_paboi_cabang" &&
                status === 0 &&
                step === 1 &&
                user_branch === branch_from_id && (
                  <Row style={{ width: "100%" }} horizontal="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.approveButton)}
                      onClick={() =>
                        this.setState({ modalApprovalSIPShow: true })
                      }
                    >
                      Verifikasi
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.rejectButton)}
                      onClick={() =>
                        this.setState({ modalRejectSIPShow: true })
                      }
                    >
                      Tolak
                    </Button>
                  </Row>
                )}

              {user !== "member" && status === 0 && step === 2 && ""}

              {user === "ketua_paboi_cabang" &&
                status === 0 &&
                step === 2 &&
                user_branch === branch_from_id && (
                  <Row style={{ width: "100%" }} horizontal="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.approveButton)}
                      onClick={() =>
                        this.setState({ modalApprovalSIPShow: true })
                      }
                    >
                      Verifikasi
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.rejectButton)}
                      onClick={() =>
                        this.setState({ modalRejectSIPShow: true })
                      }
                    >
                      Tolak
                    </Button>
                  </Row>
                )}
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <form
                onSubmit={handleSubmit(this.handleSubmit)}
                style={{ width: "100%" }}
              >
                <Row
                  vertical="center"
                  horizontal="space-between"
                  style={{ width: "100%", marginBottom: 20 }}
                >
                  <DetailTitleComponent
                    title="Informasi Pengajuan"
                    marginBottom={true}
                  />
                  {user === "member" && status === 2 && (
                    <EditButtonComponent
                      isEdit={isEdit}
                      triggerIsEdit={this.handleTriggerFormEdit}
                      isLoading={editSIP_pending}
                    />
                  )}
                </Row>
                <FormContainer label="Tanggal Pengajuan">
                  {/* Tanggal Component */}
                  <Field
                    name="date"
                    disabled
                    isDetail={!isEdit}
                    component={renderInput}
                  />
                </FormContainer>

                <FormContainer label="Urutan Pengajuan">
                  <Field
                    isDetail={true}
                    disabled
                    component={(field) =>
                      renderInput({
                        ...field,
                        input: { value: "Pengajuan SIP ke " + count_practice },
                      })
                    }
                    // component={() => (
                    //     <span>{"Pengajuan SIP ke " + count_practice}</span>
                    // )}
                  />
                </FormContainer>

                <FormContainer label="Cabang Tujuan">
                  <Field
                    name="branch_to"
                    isDetail={!isEdit}
                    placeholder="Pilih cabang tujuan"
                    options={branchOptions}
                    component={renderSelect}
                  />
                </FormContainer>
                <FormContainer label="Rumah Sakit Tujuan">
                  <Field
                    name="hospital_id_to"
                    isDetail={!isEdit}
                    placeholder="Pilih rumah sakit tujuan"
                    options={hospitalOptions}
                    isAsync
                    asyncUrl="master_data/hospital/find"
                    component={renderSelect}
                  />
                </FormContainer>
                <label className={css(styles.detailSectionName)}>Berkas</label>
                <DetailContentComponent
                  label="Surat Permohonan Tertulis"
                  value={
                    surat_pendukung_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            surat_pendukung_filename,
                            "Surat Permohonan Tertulis",
                            "sip_request_files"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas KTP"
                  value={
                    ktp_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            ktp_filename,
                            "KTP",
                            "member_supporting_files"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas STR"
                  value={
                    str_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            str_filename,
                            "STR",
                            "member_str"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas Serkom"
                  value={
                    serkom_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            serkom_filename,
                            "Serkom",
                            "member_serkom"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />

                {isEdit ? (
                  <FormContainer
                    label="Berkas"
                    contentValueStyle={{ margin: "20px 0" }}
                  >
                    <FieldArray
                      name="file"
                      isDetail={!isEdit}
                      component={DynamicFormComponent}
                      changeFileValue={this.changeFileValue}
                      initialValues={initialValues}
                      folderPath="sip_request_files"
                    />
                  </FormContainer>
                ) : (
                  <FieldArray
                    name="file"
                    isDetail={!isEdit}
                    component={DynamicFormComponent}
                    changeFileValue={this.changeFileValue}
                    initialValues={initialValues}
                    folderPath="sip_request_files"
                  />
                )}
                {status === 1 && (
                  <>
                    <label className={css(styles.detailSectionName)}>
                      Surat Lampiran
                    </label>
                    <DetailContentComponent
                      label="Surat Lampiran"
                      value={
                        id !== null ? (
                          <DetailContentLihatBerkas
                            onClick={() =>
                              this.handleOpenDocument(
                                id,
                                "Surat Rekomendasi",
                                "member_supporting_files"
                              )
                            }
                          />
                        ) : (
                          "-"
                        )
                      }
                    />
                  </>
                )}
              </form>
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  billDate: {
    color: "#495057",
    opacity: "0.7",
    marginBottom: 10,
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20,
  },
});

function validate(values) {
  const { branch_to, hospital_id_to, file } = values;
  const errors = {};
  const fileArrayErrors = [];
  if (!branch_to) {
    errors.branch_to = "Cabang tujuan wajib diisi";
  }
  if (!hospital_id_to) {
    errors.hospital_id_to = "Tempat praktik tujuan wajib diisi";
  }
  if (!file || !file.length) {
    errors.file = {
      _error: "Berkas tidak boleh kosong",
    };
  } else {
    file.forEach((item, index) => {
      if (!item) {
        fileArrayErrors[index] = "Berkas tidak boleh kosong";
      }
    });
  }
  if (fileArrayErrors.length) {
    errors.file = fileArrayErrors;
  }
  return errors;
}

DetailSIP = reduxForm({
  // a unique name for the form
  form: "DetailSIP",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(DetailSIP);

function mapStateToProps(state) {
  const { SIPList, editSIP, listHospital, listBranch, memberList } = state;
  let initialValues = {};
  const SIPList_data = getSIPList(SIPList);
  if (SIPList_data && SIPList_data !== 0) {
    const data = SIPList_data.data[0];
    initialValues = {
      date: moment(data.date).locale("id", localization).format("DD MMM YYYY"),
      branch_to: data.branch_to_data.name,
      name: data.user_data.name,
      hospital_id_to: {
        label: data.hospital_to_data.name,
        value: data.hospital_to_data.id,
      },
      branch_to: {
        label: data.branch_to_data.name,
        value: data.branch_to_data.id,
      },
      file: data.sip_request_attachments.map((item) => ({
        value: item.attachment_filename,
        name: item.attachment_name,
      })),
    };
  }
  return {
    SIPList_pending: getSIPPending(SIPList),
    SIPList_data: getSIPList(SIPList),
    SIPList_error: getSIPError(SIPList),
    editSIP_pending: getSIPEditPending(editSIP),
    editSIP_data: getSIPEdit(editSIP),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    memberList_data: getMemberList(memberList),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  SIPList,
  editSIP,
  listHospital,
  listBranch,
  memberList,
})(withRouter(DetailSIP));
