import {
    LIST_ADVERTISEMENT_PENDING,
    LIST_ADVERTISEMENT_SUCCESS,
    LIST_ADVERTISEMENT_ERROR,
    LIST_ACTIVE_ADVERTISEMENT_PENDING,
    LIST_ACTIVE_ADVERTISEMENT_SUCCESS,
    LIST_ACTIVE_ADVERTISEMENT_ERROR,
    DETAIL_ADVERTISEMENT_PENDING,
    DETAIL_ADVERTISEMENT_SUCCESS,
    DETAIL_ADVERTISEMENT_ERROR,
    ADD_ADVERTISEMENT_PENDING,
    ADD_ADVERTISEMENT_SUCCESS,
    ADD_ADVERTISEMENT_ERROR,
    SET_SELECTED_ADVERTISEMENT,
    EDIT_ADVERTISEMENT_PENDING,
    EDIT_ADVERTISEMENT_SUCCESS,
    EDIT_ADVERTISEMENT_ERROR,
    DELETE_ADVERTISEMENT_PENDING,
    DELETE_ADVERTISEMENT_SUCCESS,
    DELETE_ADVERTISEMENT_ERROR,
} from "../../actions/advertisement/advertisement_action";

const initialState = {
    pending: false,
    error: null,
};

export function listAdvertisement(
    state = { ...initialState, list_advertisement: null },
    action
) {
    switch (action.type) {
        case LIST_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                list_advertisement: action.data,
            };
        case LIST_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function listActiveAdvertisement(
    state = { ...initialState, list_active_advertisement: null },
    action
) {
    switch (action.type) {
        case LIST_ACTIVE_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_ACTIVE_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                list_active_advertisement: action.data,
            };
        case LIST_ACTIVE_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailAdvertisement(
    state = { ...initialState, detail_advertisement: null },
    action
) {
    switch (action.type) {
        case DETAIL_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_advertisement: action.data,
            };
        case DETAIL_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addAdvertisement(state = initialState, action) {
    switch (action.type) {
        case ADD_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedAdvertisement(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_ADVERTISEMENT:
            return action.value;
        default:
            return state;
    }
}

export function editAdvertisement(
    state = { ...initialState, editAdvertisement: null },
    action
) {
    switch (action.type) {
        case EDIT_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                editAdvertisement: action.data
            };
        case EDIT_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function deleteAdvertisement(
    state = { ...initialState, deleteAdvertisement: null },
    action
) {
    switch (action.type) {
        case DELETE_ADVERTISEMENT_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteAdvertisement: action.data
            };
        case DELETE_ADVERTISEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListAdvertisement = (state) => state.list_advertisement;
export const getListAdvertisementPending = (state) => state.pending;
export const getListAdvertisementError = (state) => state.error;
export const getListActiveAdvertisement = (state) => state.list_active_advertisement;
export const getListActiveAdvertisementPending = (state) => state.pending;
export const getListActiveAdvertisementError = (state) => state.error;
export const getDetailAdvertisement = (state) => state.detail_advertisement;
export const getSelectedAdvertisement = (state) => state.selectedAdvertisement;
export const getAdvertisementAddPending = (state) => state.pending;
export const getAdvertisementAdd = (state) => state.approvalAdvertisement;
export const getAdvertisementAddError = (state) => state.error;
export const getAdvertisementEditPending = (state) => state.pending;
export const getAdvertisementEdit = (state) => state.editAdvertisement;
export const getAdvertisementEditError = (state) => state.error;
export const getAdvertisementDeletePending = (state) => state.pending;
export const getAdvertisementDelete = (state) => state.deleteAdvertisement;
export const getAdvertisementDeleteError = (state) => state.error;