import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import "bootstrap/dist/css/bootstrap.css";
import * as loadingData from "../../assets/loading.json";
import * as doneData from "../../assets/approved.json";
import * as failedData from "../../assets/failed.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: doneData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const defaultOptions3 = {
    loop: false,
    autoplay: true,
    animationData: failedData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class Loading extends React.Component {
    render() {
        return (
            <div>
                {!this.props.done ? (
                    <FadeIn>
                        <div className="d-flex justify-content-center align-items-center">
                            <span>Uploading file</span>
                            <Lottie options={defaultOptions} height={40} width={40} />
                        </div>
                    </FadeIn>
                ) : (
                        <FadeIn>
                            {!this.props.failed ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span>Done</span>
                                    <Lottie options={defaultOptions2} height={40} width={40} />
                                </div>
                            ) : (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span style={{ marginRight: 10 }}>Gagal upload berkas</span>
                                        <Lottie options={defaultOptions3} height={15} width={15} />
                                    </div>
                                )
                            }
                        </FadeIn>
                    )}
            </div>
        );
    }
}