import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import SelectComponent from "../../components/form/SelectComponent";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList, getMemberPending
} from "../../reducers/member/member_reducer";

const styles = StyleSheet.create({
  container: {
    width: 220,
    marginLeft: 10,
    zIndex: 999
  },
});
class MessageBlastAddHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serkom: "",
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }

  componentDidMount() {
    this.props.listBranch({ length: 999999 })
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    return handleRefresh(value);
  }, 500);

  handleSearchChange(e) {
    this.setState({ serkom: e.target.value });
    this.delayedQuery({ search_text: e.target.value });
  }

  handleBranchChange = (e) => {
    const { handleRefresh } = this.props;
    if (e) {
      handleRefresh({ branch_id: e.value });
    } else {
      handleRefresh({ branch_id: null });
    }
  }

  render() {
    const { selectedMembers, listBranch_data, listMember_pending } = this.props;
    let branchOptions = [];
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    return (
      <Row
        style={{ padding: "5px 0" }}
        vertical="center"
        horizontal="space-between"
      >
        <div style={{ color: "#7F8388", fontSize: 16 }}>
          {selectedMembers} member dipilih
        </div>
        <>
          <div className={css(styles.container)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.serkom}
              placeholder="Cari nama atau ID member"
              startIcon={SearchIcon}
              disabled={listMember_pending}
              />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              isDisabled={listMember_pending}
              options={branchOptions}
              placeholder="Pilih Cabang"
              onChange={this.handleBranchChange}
            />
          </div>
        </>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  const { listBranch, memberList } = state;
  return {
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList)
  };
}

export default connect(mapStateToProps, { listBranch, memberList })(MessageBlastAddHeader);
