import {
    LIST_MESSAGE_BLAST_PENDING,
    LIST_MESSAGE_BLAST_SUCCESS,
    LIST_MESSAGE_BLAST_ERROR,
    ADD_MESSAGE_BLAST_PENDING,
    ADD_MESSAGE_BLAST_SUCCESS,
    ADD_MESSAGE_BLAST_ERROR,
    DETAIL_MESSAGE_BLAST_PENDING,
    DETAIL_MESSAGE_BLAST_SUCCESS,
    DETAIL_MESSAGE_BLAST_ERROR,
    UPLOAD_MESSAGE_BLAST_PENDING,
    UPLOAD_MESSAGE_BLAST_SUCCESS,
    UPLOAD_MESSAGE_BLAST_ERROR,
    SET_SELECTED_MESSAGE_BLAST,
} from "../../actions/message-blast/message_blast_action";

const initialState = {
    pending: false,
    error: null,
};

export function messageBlastList(state = { ...initialState, message_blastList: null }, action) {
    switch (action.type) {
        case LIST_MESSAGE_BLAST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_MESSAGE_BLAST_SUCCESS:
            return {
                ...state,
                pending: false,
                message_blastList: action.data,
            };
        case LIST_MESSAGE_BLAST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addMessageBlast(state = { ...initialState, addMessageBlast: null }, action) {
    switch (action.type) {
        case ADD_MESSAGE_BLAST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_MESSAGE_BLAST_SUCCESS:
            return {
                ...state,
                pending: false,
                addMessageBlast: action.data,
            };
        case ADD_MESSAGE_BLAST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedMessageBlast(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_MESSAGE_BLAST:
            return action.value;
        default:
            return state;
    }
}

export function detailMessageBlast(
    state = { ...initialState, detail_branch: null },
    action
) {
    switch (action.type) {
        case DETAIL_MESSAGE_BLAST_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_MESSAGE_BLAST_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_branch: action.data,
            };
        case DETAIL_MESSAGE_BLAST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function uploadMessageBlast(
    state = { ...initialState, uploadMessageBlast: null },
    action
  ) {
    switch (action.type) {
      case UPLOAD_MESSAGE_BLAST_PENDING:
        return {
          ...state,
          pending: true,
        };
      case UPLOAD_MESSAGE_BLAST_SUCCESS:
        return {
          ...state,
          pending: false,
          uploadMessageBlast: action.data,
        };
      case UPLOAD_MESSAGE_BLAST_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }

export const getMessageBlastPending = (state) => state.pending;
export const getDetailMessageBlast = (state) => state.detail_branch;
export const getMessageBlastError = (state) => state.error;
export const getMessageBlastListPending = (state) => state.pending;
export const getMessageBlastList = (state) => state.message_blastList;
export const getMessageBlastListError = (state) => state.error;
export const getMessageBlastAddPending = (state) => state.pending;
export const getMessageBlastAdd = (state) => state.addMessageBlast;
export const getMessageBlastAddError = (state) => state.error;
export const getSelectedMessageBlast = (state) => state.selectedMessageBlast;
export const getMessageBlastUploadPending = (state) => state.pending;
export const getMessageBlastUpload = (state) => state.uploadMessageBlast;
export const getMessageBlastUploadError = (state) => state.error;