import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { setSelectedHeader, setSelectedLinkHeader } from "../../actions/header/header_action";
import { default_url } from "../../constants/default_url";
import { getUser } from "../../helpers/user";
import { useDispatch } from "react-redux";

const PrivateRoute = ({ component: Component, head, link, roles, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let param = head ? head : [];
    let url_link = link ? link : [];
    dispatch(setSelectedHeader(param, url_link));
  }, [head, dispatch]);
  useEffect(() => {
    let url_link = link ? link : [];
    dispatch(setSelectedLinkHeader(url_link));
  }, [head, dispatch]);
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = getUser();

        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // const {
        //   currentUser: [role_code],
        // } = currentUser;
        // check if route is restricted by role
        if (
          roles &&
          roles.indexOf(currentUser.role_codes[0].toLowerCase()) === -1
        ) {
          // role not authorised so redirect to home page
          return (
            <Redirect
              to={{
                pathname: `/${default_url[currentUser.role_codes[0].toLowerCase()]
                  }`,
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
