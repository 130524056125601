import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListRegistrasi from "./List_registrasi";

class Registrasi extends Component {
  constructor(props) {
    super(props);
    this.state = { tes: "" };

    this.tes = this.tes.bind(this);
  }

  tes(e) {
    this.setState({ tes: e.target.value });
  }

  render() {
    return (
      <ContentContainer>
        <ListRegistrasi />
      </ContentContainer>
    );
  }
}

export default Registrasi;
