import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { memberList } from "../../actions/member/member_action";
import {
  renderInputWithLabel,
  renderDatepickerWithLabel2,
} from "../../components/form/FormComponent";
import {
  editMembership,
} from "../../actions/koperasi/membership_action";
import {
  getDetailMembership,
  getMembershipPending
} from "../../reducers/koperasi/membership_reducer";

class ModalDetailMembership extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
      // this.props.change("main_deposit", 0)
    }
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, editMembership, detailMembership_data } = this.props
    const { required_deposit } = values;
    const [{ id }] = detailMembership_data;
    const param = {
      id, required_deposit
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editMembership(param, callback);
  }

  formatAmount(input) {
    if (!input) return "";
    else input = input.toString()
    if (isNaN(parseInt(input[input.length - 1], 10))) {
      return "";
    } else input = Number(input)
    var reverse = input.toString().split('').reverse().join(''),
      ribuan = reverse.match(/\d{1,3}/g);
    ribuan = ribuan.join('.').split('').reverse().join('');
    return "Rp " + ribuan;
  }



  normalizeCurrency = (val) => {
    if (!val) {
      return ""
    }
    let result = val.replace("Rp ", "").split('.').join('');

    if (isNaN(parseInt(result, 10))) {
      return ""
    }
    return result
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      detailMembership_pending
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Detail Anggota</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailMembership_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <Field
                    name="date"
                    isDetail
                    placeholder="Masukkan tanggal pengajuan"
                    label="Tanggal Pengajuan"
                    component={renderDatepickerWithLabel2}
                  />
                  <Field
                    name="code"
                    type="text"
                    label='ID Koperasi'
                    placeholder="Masukkan id koperasi"
                    component={renderInputWithLabel}
                  />

                  <Field
                    disable
                    name="member"
                    label="Nama Dokter"
                    placeholder="Pilih Member"
                    component={renderInputWithLabel}
                  />
                  <Field
                    disable
                    name="main_deposit"
                    type="text"
                    label='Simpanan Pokok'
                    placeholder="Masukkan simpanan pokok"
                    format={this.formatAmount}
                    normalize={this.normalizeCurrency}
                    component={renderInputWithLabel}
                  />
                  <Field
                    disable
                    name="status"
                    label="Status Anggota"
                    placeholder="Pilih status anggota"
                    component={renderInputWithLabel}
                  />
                  {/* <Field
                    disable
                    name="payment_period"
                    label="Periode Pembayaran"
                    placeholder="Pilih periode pembayaran"
                    component={renderInputWithLabel}
                  />
                  <Field
                    disable
                    name="duration"
                    label="Durasi Pembayaran"
                    placeholder="Pilih durasi pembayaran"
                    component={renderInputWithLabel}
                  /> */}
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >

            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  const { member, main_deposit, required_deposit } = values;
  const errors = {};
  if (!main_deposit) {
    errors.main_deposit = 'Simpanan pokok harus diisi'
  }
  if (!required_deposit) {
    errors.required_deposit = "Simpanan wajib harus diisi"
  }
  if (!member) {
    errors.member = "Nama dokter harus diisi"
  }

  return errors;
}

ModalDetailMembership = reduxForm({
  // a unique name for the form
  form: "ModalDetailMembership",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalDetailMembership);

function mapStateToProps({ memberList, editMembership, detailMembership }) {

  let detailMembership_data = getDetailMembership(detailMembership);
  let initialValues = {};
  if (detailMembership_data && detailMembership_data.length !== 0) {
    const [{ member_data: { name }, join_date, status, code, main_deposit, required_deposit, payment_period, duration }] = detailMembership_data;
    initialValues = {
      member: name,
      date: join_date,
      code,
      status: status === 1 ? 'Aktif' : 'Tidak aktif',
      main_deposit,
      required_deposit,
      payment_period: payment_period !== 12 ? `${payment_period} Bulan` : `${payment_period / 12} Tahun`,
      duration: payment_period !== 12 ? `${duration} Bulan` : `${duration / 12} Tahun`
    }
  }

  return {
    editMembership_pending: getMembershipPending(editMembership),
    detailMembership_pending: getMembershipPending(detailMembership),
    detailMembership_data,
    initialValues
  };
}

export default connect(mapStateToProps, {
  memberList, editMembership
})(withRouter(ModalDetailMembership));
