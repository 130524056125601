import React from "react";
import { StyleSheet, css } from "aphrodite";
import { Row } from "simple-flexbox";
import { number } from "prop-types";
import IconCreate from "../../assets/icon-create";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import MenuItem from "@material-ui/core/MenuItem";
import IconDot from "../../assets/icon-dot";
import { getUser } from "../../helpers/user";

function StatusComponent(props) {
  const statusColor = ["#ff3232", "#2CB96A", "#ff8c32", "#727272", "#2CB96A"];
  const statusLabel = ["Nonaktif", "Aktif", ">65 Tahun", "Wafat", "-"];
  const { paboiId, status } = props;
  return (
    <Row onClick={props.onClick} className={css(styles.statusContainer)}>
      <Row
        className={css(styles.statusLeft)}
        style={{
          color: statusColor[status],
          borderColor: statusColor[status],
        }}
      >
        <span>ID {paboiId}</span>
      </Row>
      { getUser().role_codes[0] !== "root" ?
        <Row
          className={getUser().role_codes[0] === "root" ? css(styles.statusRightAdmin) : css(styles.statusRight)}
          style={{
            color: "#FFFFFF",
            borderColor: statusColor[status],
            backgroundColor: statusColor[status],
          }}
        >
          {statusLabel[status]}
        </Row>
        :
        <DetailButtonComponent
          button={(props) => (
            <Row
              onClick={props.onClick}
              className={
                status === 0
                  ? css(styles.detailMemberStatusNonactiveButton)
                  : status === 1
                    ? css(styles.detailMemberStatusActiveButton)
                    : status === 2
                      ? css(styles.detailMemberStatusPensionButton)
                      : css(styles.detailMemberStatusDepartedButton)
              }
            >
              <span
                className={css(styles.detailMemberStatusButtonFont)}
              >
                {status === 1
                  ? "Aktif"
                  : status === 2
                    ? ">65 Tahun"
                    : "Wafat"}
              </span>{" "}
              <IconCreate />
            </Row>
          )}
          horizontal="left"
        >
          <MenuItem
            onClick={props.statusActive}
          >
            <IconDot fill="#2CB96A" /> Aktif
          </MenuItem>
          {/* <MenuItem
            onClick={props.statusNonactive}
          >
            <IconDot fill="Red" /> Nonaktif
          </MenuItem> */}
          <MenuItem
            onClick={props.statusPensiun}
          >
            <IconDot fill="#F57C2B" /> > 65 Tahun
          </MenuItem>
          <MenuItem
            onClick={props.statusAlm}
          >
            <IconDot fill="#727272" />Wafat
          </MenuItem>
        </DetailButtonComponent>
      }
    </Row>
  );
}

const styles = StyleSheet.create({
  statusContainer: {
    width: "100%",
    marginTop: 20,
    marginBottom: 30,
  },
  statusLeft: {
    width: "60%",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderWidth: 2,
    padding: "10px 20px",
    borderStyle: "solid",
  },
  statusRight: {
    color: "#ffffff",
    width: "40%",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderWidth: 2,
    borderStyle: "solid",
    padding: "10px 20px"
  },
  statusRightAdmin: {
    color: "#ffffff",
    width: "40%",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderWidth: 2,
    borderStyle: "solid",
    padding: "10px 20px",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusPensionID: {
    padding: "10px 20px",
    border: "2px solid #ff8c32",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusDepartedID: {
    padding: "10px 20px",
    border: "2px solid #727272",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px 20px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusActiveButton: {
    padding: "10px 20px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusPensionButton: {
    padding: "10px 20px",
    backgroundColor: "#ff8c32",
    border: "2px solid #ff8c32",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusDepartedButton: {
    padding: "10px 20px",
    backgroundColor: "#727272",
    border: "2px solid #727272",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusPensionIDFont: {
    color: "#ff8c32",
  },
  detailMemberStatusDepartedIDFont: {
    color: "#727272",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  changeStatusButton: {
    padding: 0,
    fontSize: 14,
  },
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

StatusComponent.propsTypes = {
  status: number,
};
StatusComponent.defaultProps = {
  status: 1,
};
export default StatusComponent;
