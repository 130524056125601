import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastSuccess } from "../../components/toast/Toast";
import getHeader from "../header";

export const USER_CHANGE_PASSWORD_PENDING = 'USER_CHANGE_PASSWORD_PENDING'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR'

const USER_CHANGE_PASSWORD_URL = 'master_data/user/change_password';

export function userChangePassword(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(USER_CHANGE_PASSWORD_PENDING));
        API.post(USER_CHANGE_PASSWORD_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(USER_CHANGE_PASSWORD_SUCCESS, res.data));
                if (resolve) {
                    resolve({
                        data: res.data.data,
                    })
                }
                toastSuccess("Password baru anda berhasil dibuat, silahkan login kembali");
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(USER_CHANGE_PASSWORD_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}