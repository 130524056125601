import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import IconError from '../../assets/icon-error';
// import Button from "@material-ui/core/Button";
// import ClearIcon from "@material-ui/icons/Clear";
// import CheckIcon from "@material-ui/icons/Check";
import IconCreate from "../../assets/icon-create";
import Placeholder from "./skp_placeholder.js";
import FadeIn from "react-fade-in";
// import { submit } from "redux-form";

export default (props) => {
    const { activity_name, activity_type, realm_activity, skp, icon, skp_status,
        last_skp_admin, isAddDetail, isAdmin, isLoad, approval, submit_status,
        confirmation_date, editSKPModal, isEditPossible } = props;
    const Icon = icon;
    const bgColor = skp_status === 0 ? "#F57C2B" : skp_status === 1 ? "#2CB96A" : "#ED2A2A";
    return (
        <Column>
            <div className={css(styles.detailHeaderContainer)}>
                <Row>
                    <Column className={css(styles.detailHeaderActivityColumn)}>
                        <div className={css(styles.detailHeaderActivityColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Kegiatan
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad ? (
                                    <Placeholder title="" childwidth={"95%"} parentwidth={"95%"} />
                                ) :
                                    <FadeIn>
                                        {activity_name}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderActivityTypeColumn)}>
                        <div className={css(styles.detailHeaderActivityTypeColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Jenis
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad ? (
                                    <Placeholder title="" childwidth={"95%"} parentwidth={"95%"} />
                                ) :
                                    <FadeIn>
                                        {activity_type}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderRealmColumn)}>
                        <div className={css(styles.detailHeaderRealmColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                Ranah
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad ? (
                                    <Placeholder title="" childwidth={"95%"} parentwidth={"95%"} />
                                ) :
                                    <FadeIn>
                                        {realm_activity}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div className={css(styles.detailHeaderTitle)}>
                            Total SKP
                        </div>
                        <div className={css(styles.detailHeaderValue)}>
                            {isLoad ? (
                                <Placeholder title="" childwidth={"95%"} />
                            ) :
                                <FadeIn>
                                    {skp  ? skp.toFixed(2) : skp }
                                </FadeIn>
                            }
                        </div>
                    </Column>
                    {isEditPossible === true &&
                        <div className={css(styles.detailEditSKPColumn)} onClick={editSKPModal}>
                            <IconCreate height="20" width="20" />
                        </div>
                    }
                </Row>

            </div>
        </Column>
    )
}

const styles = StyleSheet.create({
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderActivityColumn: {
        margin: "20px 20px 20px 30px",
        width: "50%"
    },
    detailHeaderActivityColumnValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderActivityTypeColumn: {
        margin: 20,
        width: "10%"
    },
    detailHeaderActivityTypeColumnValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderRealmColumn: {
        margin: 20,
        width: "30%"
    },
    detailHeaderRealmColumnValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderSKPColumn: {
        margin: 20,
        width: "10%"
    },
    detailEditSKPColumn: {
        margin: "30px 0",
        width: "3%",
        background: "#495057 0% 0% no-repeat padding-box",
        borderRadius: "50%",
        padding: 7,
        ":hover": {
            cursor: "pointer"
        }
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
    detailStatusDesc: {
        color: "white",
        fontSize: 14,
        marginLeft: 20
    },
    approveButton: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        color: "black",
        marginRight: 10,
        boxShadow: "none",
    },
    rejectButton: {
        background: "transparent 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#FFFFFF",
        boxShadow: "none",
    },
})