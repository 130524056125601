import React, { Component } from "react";
import {
  renderInput,
  renderFileForm,
} from "../../components/form/FormComponent";
import { Field } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";
import Loading from "../global-components/LoadingComponent";
import DetailContentLihatBerkas from "../detail/DetailContentLihatBerkas";
import { uploadMutation } from "../../actions/mutation/mutation_action";
import { uploadEducation } from "../../actions/member/education_action";
import { uploadSIP } from "../../actions/pengajuan-sip/sip_action";

import {
  getMutationUploadPending,
  getMutationUpload,
  getMutationUploadError,
} from "../../reducers/mutation/mutation_reducer";
import {
  getSIPUpload,
  getSIPUploadError,
} from "../../reducers/pengajuan-sip/sip_reducer";
import {
  getPPDSUpload,
  getPPDSUploadError,
} from "../../reducers/manajemen-ppds/manajemen_ppds_reducer";
import {
  getEducationUpload,
  getEducationUploadError,
} from "../../reducers/member/education_reducer";
import { uploadTransrs } from "../../actions/transrs/transrs_action";
import { uploadPPDS } from "../../actions/manajemen-ppds/manajemen_ppds_action";
import {
  getTransrsUploadPending,
  getTransrsUpload,
  getTransrsUploadError,
} from "../../reducers/transrs/transrs_reducer";
import ModalDocumentImg from "../global-components/Modal_document_img";
import ModalDocumentPdf from "../global-components/Modal_document_pdf";
import DetailContentComponent from "../../components/detail/DetailContentComponent";

class DynamicFormComponent extends Component {
  state = {
    imgUrl: "",
    modal_title: "",
    folder: "",
    modalDocumentImgShow: false,
    modalDocumentPdfShow: false,
    data: [],
    title: [],
    isUpload: false,
  };

  handleOpenDocument = (
    imgUrl,
    modal_title,
    folder = this.props.folderPath
  ) => {
    this.setState({ folder: folder });
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true });
    } else {
      this.setState({ modalDocumentImgShow: true });
    }
  };

  handleUploadFile = (e) => {
    const {
      uploadMutation,
      uploadTransrs,
      uploadPPDS,
      uploadEducation,
      uploadSIP,
      folderPath,
      fields,
    } = this.props;
    if (e.target.files.length === 1) {
      const indexFile = e.target.name[5];
      const formName = e.target.name;
      let dataCopy = [...this.state.data];
      let formValues = {
        disabled: true,
        isDone: false,
        message: <Loading />,
        index: e.target.name,
      };
      this.setState({ data: [...dataCopy, formValues] });
      const callback = () => {
        if (this.props.uploadMutation_data) {
          formValues = {
            disabled: false,
            isDone: true,
            index: e.target.name,
            message: this.props.uploadMutation_data,
          };
          this.props.changeFileValue(
            formName + "value",
            this.props.uploadMutation_data
          );
          this.props.changeFileValue(
            formName + "name",
            this.state.title[e.target.name]
          );
        }
        if (this.props.uploadTransrs_data) {
          formValues = {
            disabled: false,
            isDone: true,
            index: e.target.name,
            message: this.props.uploadTransrs_data,
          };
          this.props.changeFileValue(
            formName + "value",
            this.props.uploadTransrs_data
          );
          this.props.changeFileValue(
            formName + "name",
            this.state.title[e.target.name]
          );
        }
        if (this.props.uploadPPDS_data) {
          formValues = {
            disabled: false,
            isDone: true,
            index: e.target.name,
            message: this.props.uploadPPDS_data,
            title: this.state.title[e.target.name],
          };
          this.props.changeFileValue(
            formName + "value",
            this.props.uploadPPDS_data
          );
          this.props.changeFileValue(
            formName + "name",
            this.state.title[e.target.name]
          );
        }
        if (this.props.uploadEducation_data) {
          formValues = {
            disabled: false,
            isDone: true,
            index: e.target.name,
            message: this.props.uploadEducation_data,
            title: this.state.title[e.target.name],
          };
          this.props.changeFileValue(
            formName + "value",
            this.props.uploadEducation_data
          );
          this.props.changeFileValue(
            formName + "name",
            this.state.title[e.target.name]
          );
        }
        if (this.props.uploadSIP_data) {
          formValues = {
            disabled: false,
            isDone: true,
            index: e.target.name,
            message: this.props.uploadSIP_data,
          };
          this.props.changeFileValue(
            formName + "value",
            this.props.uploadSIP_data
          );
          this.props.changeFileValue(
            formName + "name",
            this.state.title[e.target.name]
          );
        }
        this.setState({ data: [...dataCopy, formValues] });
      };

      const error = () => {
        let tempState = [...this.state.data];
        tempState.forEach((item, index) => {
          if (this.props.uploadEducation_error) {
            if (index > 0) {
              this.setState({ data: tempState });
              this.handleRemoveItem(index);
              fields.push();
            } else {
              fields.push();
              this.handleRemoveItem(index);
            }
          }
          if (this.props.uploadSIP_error) {
            if (index > 0) {
              this.setState({ data: tempState });
              this.handleRemoveItem(index);
              fields.push();
            } else {
              fields.push();
              this.handleRemoveItem(index);
            }
          }
          if (this.props.uploadMutation_error) {
            if (index > 0) {
              this.setState({ data: tempState });
              this.handleRemoveItem(index);
              fields.push();
            } else {
              fields.push();
              this.handleRemoveItem(index);
            }
          }
          if (this.props.uploadTransrs_error) {
            if (index > 0) {
              this.setState({ data: tempState });
              this.handleRemoveItem(index);
              fields.push();
            } else {
              fields.push();
              this.handleRemoveItem(index);
            }
          }
        });
      };
      switch (folderPath) {
        case "member_hospital_mutation":
          uploadTransrs(e.target.files[0], callback, error);
          break;
        case "ppds_supporting_files":
          uploadPPDS(e.target.files[0], callback);
          break;
        case "member_education":
          uploadEducation(e.target.files[0], callback, error);
          break;
        case "sip_request_files":
          uploadSIP(e.target.files[0], callback, error);
          break;
        default:
          uploadMutation(e.target.files[0], callback, error);
          break;
      }
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { initialValues, folderPath } = props;
    let update = {};
    if (initialValues && initialValues.file) {
      initialValues.file.forEach((item, index) => {
        update[`file[${index}]message`] = (
          <DetailContentLihatBerkas
            onClick={() =>
              this.handleOpenDocument(item.value, item.name, folderPath)
            }
          />
        );
      });
    }
    return update;
  }

  handleRemoveItem = (index) => {
    const { fields } = this.props;
    let tempState = [...this.state.data];
    let newState = [];
    fields.remove(index);
    if (tempState.length >= index + 1) {
      tempState.forEach((item, i) => {
        if (i !== index) {
          newState.push(item);
        }
      });
      this.setState({ data: newState });
    }
  };

  render() {
    const {
      fields,
      meta: { submitFailed, error },
      isDetail,
      initialValues,
      folderPath,
    } = this.props;
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder,
      data,
    } = this.state;
    return (
      <>
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        {submitFailed && error && (
          <Row
            style={{
              height: 40,
              color: "#ED2A2A",
              //   border: "1px solid #F9D7D7",
              borderRadius: 3,
              marginBottom: 20,
              backgroundColor: "#FFDEDE",
              //   opacity: 0.5,
            }}
            horizontal="center"
            vertical="center"
            className="error"
          >
            <span>
              <ErrorIcon style={{ marginRight: 10 }} />
              {error}
            </span>
          </Row>
        )}
        {fields.map((file, index) => {
          const indexData = data[index];
          let disabled, isDone, message;
          if (indexData) {
            disabled = indexData.disabled;
            isDone = indexData.isDone;
            message = indexData.message;
          }
          const title = isDone ? this.state.title[index] : `${file}value`;
          return (
            <Row style={{ marginTop: index === 0 ? 10 : 0 }} key={index}>
              <Column
                horizontal="stretch"
                key={index}
                style={{ width: isDetail ? "100%" : "90%" }}
              >
                {isDetail ? (
                  <DetailContentComponent
                    label={initialValues.file[index].name}
                    value={
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            initialValues.file[index].value,
                            initialValues.file[index].name,
                            folderPath
                          )
                        }
                      />
                    }
                  />
                ) : (
                  <>
                    <Field
                      isDetail={isDetail}
                      name={`${file}name`}
                      placeholder={"Masukkan nama berkas"}
                      component={renderInput}
                      onChange={(values) => {
                        this.state.title[file] = values.target.value;
                      }}
                    />
                    <div style={{ display: "none" }}>
                      <Field
                        disabled
                        name={`${file}name`}
                        component={renderInput}
                      />
                    </div>
                    {isDone ? (
                      <div style={{ margin: "5px 0px" }}>
                        {
                          <DetailContentLihatBerkas
                            onClick={() =>
                              this.handleOpenDocument(message, "Berkas")
                            }
                          />
                        }
                      </div>
                    ) : (
                      <div>
                        <Field
                          name={file}
                          type="file"
                          fileType="skp"
                          title="Berkas"
                          index={index}
                          disabled={this.state && disabled ? disabled : false}
                          isDone={this.state && isDone ? isDone : true}
                          message={this.state && message ? message : " "}
                          onChange={this.handleUploadFile}
                          component={renderFileForm}
                        />
                        <span style={{ float: "right" }}>
                          (Image atau PDF Max File 1 MB){" "}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Column>
              {!isDetail && (
                <CancelIcon
                  className={css(styles.closeButton)}
                  onClick={() => this.handleRemoveItem(index)}
                />
              )}
            </Row>
          );
        })}
        {!isDetail && (
          <Column
            horizontal="center"
            vertical="center"
            className={css(styles.buttonContainer)}
          >
            <div
              style={{
                width: "100%",
                borderBottom: "2px solid #EDEDEE",
                top: 18,
                position: "relative",
              }}
            />
            <div
              style={{
                padding: "0px 10px",
                backgroundColor: "white",
                zIndex: 0,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                // onClick={this.handleSubmit}
                startIcon={<AddIcon />}
                onClick={() => fields.push()}
              >
                Tambah Berkas
              </Button>
            </div>
          </Column>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  closeButton: {
    color: "#CDCFD1",
    margin: "auto",
    ":hover": {
      color: "#ED2A2A",
      cursor: "pointer",
    },
  },
  approveButton: {
    zIndex: 0,
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    paddingBottom: "10px",
  },
});

function mapStateToProps(state) {
  const {
    uploadMutation,
    uploadTransrs,
    uploadPPDS,
    uploadEducation,
    uploadSIP,
  } = state;
  return {
    uploadMutation_pending: getMutationUploadPending(uploadMutation),
    uploadMutation_data: getMutationUpload(uploadMutation),
    uploadMutation_error: getMutationUploadError(uploadMutation),
    uploadPPDS_data: getPPDSUpload(uploadPPDS),
    uploadPPDS_error: getPPDSUploadError(uploadPPDS),
    uploadEducation_data: getEducationUpload(uploadEducation),
    uploadEducation_error: getEducationUploadError(uploadEducation),
    uploadSIP_data: getSIPUpload(uploadSIP),
    uploadSIP_error: getSIPUploadError(uploadSIP),
    uploadTransrs_pending: getTransrsUploadPending(uploadTransrs),
    uploadTransrs_data: getTransrsUpload(uploadTransrs),
    uploadTransrs_error: getTransrsUploadError(uploadTransrs),
  };
}

export default connect(mapStateToProps, {
  uploadMutation,
  uploadEducation,
  uploadTransrs,
  uploadPPDS,
  uploadSIP,
})(DynamicFormComponent);
