import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { universityList } from "../../actions/master-data/university_action";
import {
    getUniversityListPending,
    getUniversityList,
    getUniversityListError,
} from "../../reducers/master-data/university_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderUniversitas from "./Header_universitas";
import ModalEditUniversitas from "./Modal_edit_universitas";
import ModalHapusUniversitas from "./Modal_hapus_universitas";
import { listCity } from "../../actions/master-data/city_action";
import { getCityListPending, getCityList, getCityListError } from "../../reducers/master-data/city_reducer";

class ListRegistrasi extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            id: null,
            search: "",
            modalEditUniversitasShow: false,
            modalHapusUniversitasShow: false,
            university_id: "",
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.setState({ id: null })
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleEditUniversitas(data) {
        const param_listUniversitas = {
            id: data.id
        };

        const callback = () => {
            this.setState({ university_id: data.id });
            this.setState({ modalEditUniversitasShow: true });
        }
        this.props.universityList(param_listUniversitas, callback);
    }

    handleDeleteUniversitas(data) {
        const param_listUniversitas = {
            id: data.id
        };

        const callback = () => {
            this.setState({ university_id: data.id });
            this.setState({ modalHapusUniversitasShow: true });
        }
        this.props.universityList(param_listUniversitas, callback);
    }

    componentDidMount() {
        let param_listCity = {
            page: 1,
            length: 999
        }
        this.props.listCity(param_listCity)
        if (this.props.match.params.id && this.state.id === null) {
            this.setState({ id: this.props.match.params.id })
        }
    }

    render() {
        const { universityList, universityList_data, cityList_data } = this.props;
        const { modalEditUniversitasShow, modalHapusUniversitasShow } = this.state;
        let data = [];
        let CityData = "";
        let records_total = "";
        let cityOption = [];
        let length = "";
        if (universityList_data) {
            data = universityList_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        if (cityList_data) {
            CityData = cityList_data.data;
            CityData.map((value, index) => {
                const city = { value: value.city_id, label: value.name }
                cityOption = [...cityOption, city]
                return city;
            })
        }
        return (
            <ContentContainer>
                <ModalEditUniversitas
                    show={modalEditUniversitasShow}
                    onHide={() => this.setState({ modalEditUniversitasShow: false })}
                    listCityOption={cityOption}
                    data={data}
                    handleRefresh={this.handleRefresh}
                />
                <ModalHapusUniversitas
                    show={modalHapusUniversitasShow}
                    onHide={() => this.setState({ modalHapusUniversitasShow: false })}
                    data={data}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderUniversitas handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Universitas"
                    columns={[
                        {
                            title: "No", field: "name",
                            render: (rowData) => {
                                
                                return <>{rowData.no}</>
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        { title: "Nama Universitas", field: "name" },
                        { title: "Kode Universitas", field: "code" },
                        { title: "Singkatan", field: "abbreviation" },
                        { title: "Kota", field: "city.name" },
                        { title: "Pengunggah", field: "upload_by", render: (rowData) => {
                            return (
                            <span style={{textTransform: rowData.upload_by.includes('ppds') ? 'Uppercase' : 'Capitalize'}}>
                                {rowData.upload_by}
                            </span>
                            )
                        }},
                        {
                            title: "",
                            render: (data) => {
                                return (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.handleEditUniversitas(data)}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem onClick={() => this.handleDeleteUniversitas(data)}>
                                            Delete
                                        </MenuItem>
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                id: this.state.id,
                            };
                            universityList(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.upload_by === "member") ? '#E9F8F0' : 'transparent'
                        })
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ universityList, listCity }) {
    return {
        universityList_pending: getUniversityListPending(universityList),
        universityList_data: getUniversityList(universityList),
        universityList_error: getUniversityListError(universityList),
        cityList_pending: getCityListPending(listCity),
        cityList_data: getCityList(listCity),
        cityList_error: getCityListError(listCity),
    };
}

export default connect(mapStateToProps, { universityList, listCity })(withRouter(ListRegistrasi));