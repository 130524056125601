import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import getHeader from "../header";

export const LIST_ROLE_PENDING = 'LIST_ROLE_PENDING'
export const LIST_ROLE_SUCCESS = 'LIST_ROLE_SUCCESS'
export const LIST_ROLE_ERROR = 'LIST_ROLE_ERROR'

const LIST_ROLE_URL = 'master_data/data_reference/find_role';

export function roleList(param = {}, resolve, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_ROLE_PENDING));
        API.post(LIST_ROLE_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_ROLE_SUCCESS, res.data));
                const { records_total } = res.data;
                if (resolve) {
                    resolve({
                        data: res.data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_ROLE_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}