import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";

export default (props) => {

    return (
        <div className={css(styles.subspesialisWrapper)}>
            <Column>
                <Row className={css(styles.tableHeader)}>
                    <Column style={{ width: 350 }}>
                        <span className={css(styles.subtitleFont)}>Paboi Cabang</span>
                        <span className={css(styles.tableFont)}>{props.cabang}</span>
                    </Column>
                    <Column style={{ width: 150 }}>
                        <span className={css(styles.subtitleFont)}>Jumlah Member Aktif</span>
                        <span className={css(styles.tableFont)}>{props.active}</span>
                    </Column>
                    <Column style={{ width: 150 }}>
                        <span className={css(styles.subtitleFont)}>Jumlah Member Nonaktif</span>
                        <span className={css(styles.tableFont)}>{props.non_active}</span>
                    </Column>
                </Row>
                <MaterialTable
                    columns={[
                        {
                            render: ({ no }) => {
                                return <span className={css(styles.tableNo)}>{no}</span>;
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            render: ({ subspecialist_name }) => (
                                <Column vertical="center">
                                    <span className={css(styles.subtitleFont)}>Subspesialis</span>
                                    <span className={css(styles.tableFont)}>{subspecialist_name}</span>
                                </Column>
                            ),
                            width: 500
                        },
                        {
                            render: ({ total }) => (
                                <Column vertical="center">
                                    <span className={css(styles.subtitleFont)}>Jumlah Dokter Subspesialis</span>
                                    <span className={css(styles.tableFont)}>{total}</span>
                                </Column>
                            ),
                        },
                    ]}
                    data={props.data}
                    options={{
                        pageSize: 3,
                        paging: false,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        maxBodyHeight: props.maxHeight ? props.maxHeight : 300,
                        tableHeader: false,
                        header: false,
                        headerStyle: {
                            zIndex: 0
                        }
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                    }}
                />
            </Column>
        </div>
    );
};

const styles = StyleSheet.create({
    subspesialisWrapper: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: 5,
        marginBottom: 10,
        paddingTop: 30,
        margin: 20
    },
    tableFont: {
        fontSize: 14,
        fontWeight: "bold"
    },
    subtitleFont: {
        fontSize: 10,
        color: "#495057",
        opacity: 0.6,
        marginBottom: 5
    },
    tableNo: {
        background: "#E5E5E6 0% 0% no-repeat padding-box",
        padding: "5px 8px",
        borderRadius: 5,
        opacity: 0.5
    },
    tableHeader: {
        paddingLeft: 95,
        paddingBottom: 15,
        borderBottom: "1px solid #5AB853"
    },
});