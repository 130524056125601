import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_IURAN_PENDING = "LIST_IURAN_PENDING";
export const LIST_IURAN_SUCCESS = "LIST_IURAN_SUCCESS";
export const LIST_IURAN_ERROR = "LIST_IURAN_ERROR";

export const DETAIL_IURAN_PENDING = "DETAIL_IURAN_PENDING";
export const DETAIL_IURAN_SUCCESS = "DETAIL_IURAN_SUCCESS";
export const DETAIL_IURAN_ERROR = "DETAIL_IURAN_ERROR";

export const APPROVAL_IURAN_PENDING = "APPROVAL_IURAN_PENDING";
export const APPROVAL_IURAN_SUCCESS = "APPROVAL_IURAN_SUCCESS";
export const APPROVAL_IURAN_ERROR = "APPROVAL_IURAN_ERROR";

export const ADD_IURAN_PENDING = "ADD_IURAN_PENDING";
export const ADD_IURAN_SUCCESS = "ADD_IURAN_SUCCESS";
export const ADD_IURAN_ERROR = "ADD_IURAN_ERROR";

export const SET_SELECTED_IURAN = "SET_SELECTED_IURAN";

const LIST_IURAN_URL = "member/fee/find";
const APPROVAL_IURAN_URL = "member/fee/payment_confirmation";
const ADD_IURAN_URL = "member/fee/add";

export function listIuran(param = {}, resolve, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_IURAN_PENDING));
    API.post(LIST_IURAN_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_IURAN_SUCCESS, data));
        const { records_total, data: response } = data;
        const result = response.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          ...item,
        }));
        if (resolve) {
          if (detail === true) {
            let detailIuran = data.data[0].journal_participants.map(
              (item, i) => ({
                ...item,
                no: i + 1 + (param.page - 1) * 10,
              })
            );
            resolve({
              data: detailIuran,
              page: param.page - 1,
              totalCount: detailIuran.length,
            });
          } else {
            resolve({
              data: result,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_IURAN_ERROR, error));
        toastError(error, "Gagal mendapatkan list IURAN, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function detailIuran(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_IURAN_PENDING));
    API.post(LIST_IURAN_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_IURAN_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_IURAN_ERROR, error));
        toastError(error, "Gagal mendapatkan list IURAN, ");
      });
  };
}

export function setSelectedIuran(value) {
  return {
    type: SET_SELECTED_IURAN,
    value: value,
  };
}

export function approvalIuran(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_IURAN_PENDING));
    API.post(APPROVAL_IURAN_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_IURAN_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status iuran");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_IURAN_ERROR, error));
        toastError(error, "Gagal mengubah status iuran, ");
      });
  };
}

export function addIuran(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_IURAN_PENDING));
    API.post(ADD_IURAN_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_IURAN_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan iuran");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_IURAN_ERROR, error));
        toastError(error, "Gagal menambahkan iuran, ");
      });
  };
}