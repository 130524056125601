const getHeader = () => {
  const user = JSON.parse(localStorage.getItem("user_paboi"));
  if (user){
    const { access_token } = user;
    return {
      headers: {
        Authorization: access_token,
      },
    };
  }
};

export default getHeader;
