import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../components/p2kb/HeaderDetailP2kbComponent";
import IconError from '../../assets/icon-error';
import IconDocumentP2KB from '../../assets/icon-document-p2kb';
import IconLaunch from '../../assets/icon-launch';
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { p2kbList } from "../../actions/p2kb/p2kb_action";
import {
    getP2kbListPending,
    getP2kbList,
    getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import ModalDetailDokumenSKP from "./Modal_detail_skp_document";
import ModalAcceptSkp from "./Modal_accept_skp";
import ModalRejectSkp from "./Modal_reject_skp";

class DetailP2kbApproval extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            modalDetailDokumenSKPShow: false,
            modalAcceptSKPShow: false,
            modalRejectSKPShow: false,
            imgUrl: "",
            modal_title: "",
            id: "",
            forRefresh: false
        }
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state){
        if (state){
            this.setState(state)
        }
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        this.setState({ modalDetailDokumenSKPShow: true })
    }

    componentDidMount() {
        const param = {
            id: this.props.match.params.id
        }
        this.props.p2kbList(param);
    }

    render() {
        const { p2kbList, p2kbList_data, match: { params: { id } } } = this.props;
        const role_codes = JSON.parse(localStorage.getItem("user_paboi")).data.role_codes[0];
        let data = [];
        let activity_name = "";
        let activity_type = "";
        let realm_activity = "";
        let skp = "";
        let skp_status = "";
        let last_skp_admin = "";
        let step = "";
        let isRoleCodesMatchStep = false;
        let stepOne = "admin_paboi_cabang";
        let stepTwo = "root";
        let stepThree = "cpd_cme";
        if (p2kbList_data) {
            data = p2kbList_data.data[0]
            activity_name = data.activity_data.name;
            activity_type = data.activity_data.activity_type;
            realm_activity = data.activity_data.realm_activity.name;
            skp = data.total_skp;
            skp_status = data.status;
            step = data.step;
            last_skp_admin = data.role_next_approve !== null ? data.role_next_approve.role_name : "CPD/CME";
            if (role_codes === stepOne && step === 1 && skp_status === 0) {
                isRoleCodesMatchStep = true;
            }
            if (role_codes === stepTwo && step === 2 && skp_status === 0) {
                isRoleCodesMatchStep = true;
            }
            if (role_codes === stepThree && step === 3 && skp_status === 0) {
                isRoleCodesMatchStep = true;
            }
        }
        let length = 1;
        let records_total = 1;
        return (
            <ContentContainer>
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalAcceptSkp
                    show={this.state.modalAcceptSKPShow}
                    onHide={() => this.setState({ modalAcceptSKPShow: false })}
                    id={data.id}
                    handleRefresh={this.handleRefresh}
                />
                <ModalRejectSkp
                    show={this.state.modalRejectSKPShow}
                    onHide={() => this.setState({ modalRejectSKPShow: false })}
                    id={data.id}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderDetailP2kbComponent
                    activity_name={activity_name}
                    activity_type={activity_type}
                    realm_activity={realm_activity}
                    skp={skp}
                    skp_status={skp_status}
                    last_skp_admin={last_skp_admin}
                    icon={IconError}
                    isAdmin={isRoleCodesMatchStep === true ? true : false}
                    acceptSKP={() => this.setState({ modalAcceptSKPShow: true })}
                    rejectSKP={() => this.setState({ modalRejectSKPShow: true })}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="P2kb"
                    columns={[
                        {
                            title: "No", field: "name",
                            render: (rowData) => {
                                return <>{rowData.no}</>
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        { title: "Tanggal Kegiatan", field: "activity_date" },
                        { title: "No SIP", field: "sip_no" },
                        { title: "Jumlah Pasien", field: "total_for_calculate_skp" },
                        { title: "Jumlah SKP", field: "total_skp" },
                        {
                            title: "Dokumen",
                            render: (data) => {
                                const attachmentSIPIndex = data.attachments.label === "SIP" ? 0 : 1;
                                const attachmentMedisIndex = data.attachments.label === "Medis" ? 1 : 0;
                                return (
                                    <DetailButtonComponent button={props => <Row onClick={props.onClick} className={css(styles.buttonDocHover)}><IconDocumentP2KB /></Row>}>
                                        <MenuItem onClick={() => this.handleOpenDocument(data.attachments[attachmentSIPIndex].filename, "SIP")}>
                                            <IconLaunch /> <span style={{ marginLeft: 10, paddingTop: 2 }}>SIP</span>
                                        </MenuItem>
                                        <MenuItem onClick={() => this.handleOpenDocument(data.attachments[attachmentMedisIndex].filename, "Medis")}>
                                            <IconLaunch /> <span style={{ marginLeft: 10, paddingTop: 2 }}>Medis</span>
                                        </MenuItem>
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                id: id
                            };
                            p2kbList(param, resolve, reject, true);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                            zIndex: 0
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}></div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        )
    }
}


const styles = StyleSheet.create({
    buttonDocHover: {
        ":hover": {
            cursor: "pointer"
        }
    }
})

function mapStateToProps({ p2kbList }) {
    return {
        p2kbList_pending: getP2kbListPending(p2kbList),
        p2kbList_data: getP2kbList(p2kbList),
        p2kbList_error: getP2kbListError(p2kbList),
    };
}

export default connect(mapStateToProps, { p2kbList })(withRouter(DetailP2kbApproval));