import React, { Component } from "react";
import { Row } from "simple-flexbox";
import FileComponent from "../../components/form/FileComponent";
import { StyleSheet, css } from "aphrodite";

import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";

import { addSupport } from "../../actions/support/support_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import DescContainer from "./DescContainer";

import {
  getSupportPending,
  getSupportError,
  getSupportIsEditFile,
} from "../../reducers/support/support_reducer";
import { getMemberList } from "../../reducers/member/member_reducer";
import { memberList } from "../../actions/member/member_action";
import { connect } from "react-redux";
import moment from "moment";
import MUIRichTextEditor from "mui-rte";
import draftToHtml from "draftjs-to-html";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

class SupportAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      fields: {},
      errors: {},
      type: "",
    };
    this.tableRef = React.createRef();

    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBerkasChange = this.handleBerkasChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectTypeChange = this.handleSelectTypeChange.bind(this);
    this.handleSelectRoleChange = this.handleSelectRoleChange.bind(this);
    this.handleSelectMemberTypeChange =
      this.handleSelectMemberTypeChange.bind(this);
  }

  shouldComponentRender() {
    const { regisupportationList_pending } = this.props;
    if (regisupportationList_pending === false) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.props.memberList();
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { addSupport_isEditFile, setIsEditFile } = this.props;
    if (addSupport_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!addSupport_isEditFile);
  }

  handleClick() {
    this.tableRef.current && this.tableRef.current.save();
  }
  handleSelectChange(value) {
    if (value) {
      this.setState({ member_id: value.value });
    } else {
      this.setState({ member_id: null });
    }
  }

  handleSelectRoleChange(value) {
    if (value) {
      this.setState({ forward_to: value.value });
    } else {
      this.setState({ forward_to: null });
    }
  }

  handleSelectTypeChange(value) {
    if (value) {
      this.setState({ types: value.value });
    } else {
      this.setState({ types: null });
    }
  }

  handleSelectMemberTypeChange(value) {
    if (value) {
      this.setState({ member_types: value.value });
    } else {
      this.setState({ member_types: null });
    }
  }

  handleValidation(editor) {
    const fileMaxSize = 5000000; // 5MB
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let member_types = this.state.member_types;

    if (!editor) {
      formIsValid = false;
      errors["editor"] = "Deskripsi tidak boleh kosong";
    }
    if (!fields.title) {
      formIsValid = false;
      errors["title"] = "Judul tidak boleh kosong";
    }
    if (!fields.berkas) {
      formIsValid = false;
      errors["berkas"] = "Berkas tidak boleh kosong";
    } else {
      if (fields.berkas.size > fileMaxSize) {
        formIsValid = false;
        errors["berkas"] = "File tidak boleh lebih dari 5 Mb";
      } else if (
        fields.berkas.type !== "application/pdf" &&
        !fields.berkas.type.startsWith("image/")
      ) {
        formIsValid = false;
        errors["berkas"] = "Format file hanya PDF dan Image";
      }
    }
    if (!member_types) {
      formIsValid = false;
      errors["member"] = "Teman Sejawat tidak boleh kosong";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleEditorChange(text) {
    if (text && this.state.errors.editor) {
      this.setState(({ errors }) => ({ errors: { ...errors, editor: false } }));
    }
  }

  handleInputChange(e) {
    const value = e.target.value;
    this.setState(({ fields }) => {
      return {
        fields: {
          ...fields,
          title: value,
        },
      };
    });
    if (value) {
      this.setState(({ errors }) => {
        return {
          errors: {
            ...errors,
            title: null,
          },
        };
      });
    }
  }

  handleBerkasChange(e) {
    const value = e.target.files[0];
    this.setState(({ fields }) => {
      return {
        fields: {
          ...fields,
          berkas: value,
        },
      };
    });
    this.setState(({ errors }) => {
      return {
        errors: {
          ...errors,
          berkas: null,
        },
      };
    });
  }

  handleSave(data) {
    const { addSupport } = this.props;
    data = JSON.parse(data);
    const result = draftToHtml(data);

    if (this.handleValidation(data.blocks[0].text)) {
      var param = {
        description: result,
        title: this.state.fields.title,
        file: this.state.fields.berkas,
        report_member_id: this.state.member_id,
        types: this.state.types,
      };
      if (this.state.types == 2) {
        param = {
          ...param,
          forward_to: this.state.forward_to,
        };
      }

      addSupport(param, () => this.props.history.push("/support"));
    }
  }

  render() {
    const { addSupport_pending, listMember_data } = this.props;
    let memberOptions = [];
    let typeOptions = [
      {
        value: 1,
        label: "Teknis",
      },
      {
        value: 2,
        label: "Profesional",
      },
    ];
    let typeMemberOptions = [
      {
        value: 1,
        label: "Ya",
      },
      {
        value: 2,
        label: "Tidak",
      },
    ];
    let roleOptions = [
      {
        value: 0,
        label: "Admin Cabang",
      },
      {
        value: 1,
        label: "Admin Pusat",
      },
    ];
    if (listMember_data) {
      memberOptions = listMember_data.data.map(
        ({ id, name, member_profile: { paboi_id } }) => ({
          label: `${name} - ${paboi_id}`,
          value: id,
        })
      );
    }

    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <div style={{ marginBottom: 12, color: "#495057" }}>
              Dilaporkan pada
            </div>
            <DetailNameComponent
              name={moment(new Date()).format("DD MMMM YYYY")}
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <Row
              vertical="center"
              horizontal="space-between"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <DetailNameComponent name="Informasi Laporan" />
              <Button
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                disabled={addSupport_pending}
                startIcon={
                  addSupport_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
                onClick={this.handleClick}
              >
                simpan
              </Button>
            </Row>

            <FormContainer label="Judul Pengajuan">
              <InputComponent name="title" onChange={this.handleInputChange} />
              <div
                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
              >
                {this.state.errors.title}
              </div>
            </FormContainer>

            <FormContainer label="Jenis Laporan">
              <SelectComponent
                name="type"
                onChange={this.handleSelectTypeChange}
                options={typeOptions}
              />
              <div
                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
              ></div>
            </FormContainer>
            {this.state.types === 2 && (
              <>
                <FormContainer label="Ditangani Oleh">
                  <SelectComponent
                    name="role"
                    onChange={this.handleSelectRoleChange}
                    options={roleOptions}
                  />
                  <div
                    style={{
                      color: "#ED2A2A",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  ></div>
                </FormContainer>
              </>
            )}

            <DescContainer label="Deskripsi">
              <div style={{ marginBottom: 30 }}>
                <MUIRichTextEditor
                  onChange={(event) =>
                    this.handleEditorChange(
                      event.getCurrentContent().getPlainText()
                    )
                  }
                  onSave={this.handleSave}
                  ref={this.tableRef}
                  label="Start typing..."
                  controls={[
                    "title",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "undo",
                    "redo",
                    "clear",
                  ]}
                  maxLength={1000}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                >
                  {this.state.errors.editor}
                </div>
              </div>
            </DescContainer>
            <FormContainer label="Teman Sejawat">
              <SelectComponent
                name="type"
                onChange={this.handleSelectMemberTypeChange}
                options={typeMemberOptions}
                value={this.state.fields.member_types}
              />
              <div
                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
              >
                {this.state.errors.member}
              </div>
            </FormContainer>
            {this.state.member_types == 1 && (
              <FormContainer label="Member Terkait">
                <SelectComponent
                  name="member"
                  onChange={this.handleSelectChange}
                  options={memberOptions}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                ></div>
              </FormContainer>
            )}
            <FormContainer label="Berkas Pendukung">
              <FileComponent
                type="file"
                fileType="support"
                onChange={this.handleBerkasChange}
                title="upload berkas"
                message="PDF atau Image maksimal 5000 kb"
                value={this.state.fields.berkas}
              />
              <div
                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
              >
                {this.state.errors.berkas}
              </div>
            </FormContainer>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
  },
});

function mapStateToProps(state) {
  const { addSupport, memberList } = state;

  return {
    addSupport_error: getSupportError(addSupport),
    addSupport_pending: getSupportPending(addSupport),
    addSupport_isEditFile: getSupportIsEditFile(addSupport),
    listMember_data: getMemberList(memberList),
  };
}

export default connect(mapStateToProps, {
  addSupport,
  memberList,
})(withRouter(SupportAdd));
