import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListUniversitas from "./List_universitas";

class Universitas extends Component {
    render() {
        return (
            <ContentContainer>
                <ListUniversitas />
            </ContentContainer>
        );
    }
}

export default Universitas;
