import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

function DetailNameComponent(props) {
  const { status, backgroundColor, style } = props;
  return (
    <span
      style={{ backgroundColor: backgroundColor, ...style }}
      className={css(styles.status)}
    >
      {status}
    </span>
  );
}

DetailNameComponent.propTypes = {
  name: string,
};

const styles = StyleSheet.create({
  status: {
    padding: 5,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 20,
    marginTop: 16,
    marginBottom: 56,
    // fontFamily: "Roboto",
    fontSize: 14,
    color: "white",
  },
});

export default DetailNameComponent;
