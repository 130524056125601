import React, { Component } from "react";
import { connect } from "react-redux";
import { listIuran } from "../../actions/iuran/iuran_action";
import {
  getListIuran,
  getListIuranError,
  getListIuranPending,
} from "../../reducers/iuran/iuran_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import ReportHeader from "./ReportHeader";
import moment from "moment";
import { getUser } from "../../helpers/user";
import localization from "moment/locale/id";

class Iuran extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: "",
      search_text: "",
      showModalAdd: false,
      year: null,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/iuran/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() { }

  render() {
    const { listIuran, listIuran_data } = this.props;
    const { search_text, year } = this.state;
    let data = [];
    let records_total = 0;
    if (listIuran_data) {
      data = listIuran_data.data;
      records_total = listIuran_data.records_total;
    }
    return (
      <ContentContainer>
        <ReportHeader role={getUser().role_codes[0]} handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "ID Transaksi",
              field: "transaction_number",
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tanggal Pembayaran",
              render: ({ transaction_date }) => {
                return moment(transaction_date).locale("id", localization).format("DD MMMM YYYY");
              },
            },
            {
              title: "Dana Aktual (Rp)",
              render: ({ amount }) => {
                return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                year: year,
              };
              listIuran(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listIuran }) {
  return {
    listIuran_data: getListIuran(listIuran),
    listIuran_error: getListIuranError(listIuran),
    listIuran_pending: getListIuranPending(listIuran),
  };
}

export default connect(mapStateToProps, {
  listIuran,
})(Iuran);
