import {
  LIST_MUTATION_PENDING,
  LIST_MUTATION_SUCCESS,
  LIST_MUTATION_ERROR,
  DETAIL_MUTATION_PENDING,
  DETAIL_MUTATION_SUCCESS,
  DETAIL_MUTATION_ERROR,
  EDIT_MUTATION_PENDING,
  EDIT_MUTATION_SUCCESS,
  EDIT_MUTATION_ERROR, 
  ADD_MUTATION_PENDING,
  ADD_MUTATION_SUCCESS,
  ADD_MUTATION_ERROR,
  UPLOAD_MUTATION_PENDING,
  UPLOAD_MUTATION_SUCCESS,
  UPLOAD_MUTATION_ERROR,
  APPROVE_MUTATION_PENDING,
  APPROVE_MUTATION_SUCCESS,
  APPROVE_MUTATION_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/mutation/mutation_action";

const initialState = {
  pending: false,
  error: null,
};

export function listMutation(state = { ...initialState, list_mutation: null }, action) {
  switch (action.type) {
    case LIST_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
        list_mutation: action.data,
      };
    case LIST_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailMutation(
  state = { ...initialState, detail_mutation: null },
  action
) {
  switch (action.type) {
    case DETAIL_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_mutation: action.data,
      };
    case DETAIL_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editMutation(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addMutation(state = initialState, action) {
  switch (action.type) {
    case ADD_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function uploadMutation(
  state = { ...initialState, uploadMutation: null },
  action
) {
  switch (action.type) {
    case UPLOAD_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
        uploadMutation: action.data,
      };
    case UPLOAD_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveMutation(state = initialState, action) {
  switch (action.type) {
    case APPROVE_MUTATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_MUTATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_MUTATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListMutation = (state) => state.list_mutation;
export const getDetailMutation = (state) => state.detail_mutation;
export const getMutationPending = (state) => state.pending;
export const getMutationError = (state) => state.error;
export const getMutationIsEditFile = (state) => state.isEditFile;
export const getMutationUploadPending = (state) => state.pending;
export const getMutationUpload = (state) => state.uploadMutation;
export const getMutationUploadError = (state) => state.error;