import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const INTEREST_LIST_PENDING = "INTEREST_LIST_PENDING";
export const INTEREST_LIST_SUCCESS = "INTEREST_LIST_SUCCESS";
export const INTEREST_LIST_ERROR = "INTEREST_LIST_ERROR";

const INTEREST_LIST_URL = "master_data/university_interest/find";

export function interestList(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(INTEREST_LIST_PENDING));
    API.post(INTEREST_LIST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        let data = res.data.data;
        dispatch(actionSuccess(INTEREST_LIST_SUCCESS, data));
      })
      .catch((error) => {
        dispatch(actionError(INTEREST_LIST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}
