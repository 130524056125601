import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_AUDIT_TRAIL_PENDING = "LIST_AUDIT_TRAIL_PENDING";
export const LIST_AUDIT_TRAIL_SUCCESS = "LIST_AUDIT_TRAIL_SUCCESS";
export const LIST_AUDIT_TRAIL_ERROR = "LIST_AUDIT_TRAIL_ERROR";

const LIST_AUDIT_TRAIL_URL = "audit_trail/find";

export function listAuditTrail(param = {}, resolve, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_AUDIT_TRAIL_PENDING));
    API.post(LIST_AUDIT_TRAIL_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_AUDIT_TRAIL_SUCCESS, data));
        const { records_total, data: response } = data;
        const result = response.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          ...item,
        }));
        if (resolve) {
          if (detail === true) {
            let detailAuditTrail = data.data[0].map(
              (item, i) => ({
                ...item,
                no: i + 1 + (param.page - 1) * 10,
              })
            );
            resolve({
              data: detailAuditTrail,
              page: param.page - 1,
              totalCount: detailAuditTrail.length,
            });
          } else {
            resolve({
              data: result,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_AUDIT_TRAIL_ERROR, error));
        toastError(error, "Gagal mendapatkan list audit trail, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}