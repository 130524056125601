import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_BENEFIT_PENDING = "LIST_BENEFIT_PENDING";
export const LIST_BENEFIT_SUCCESS = "LIST_BENEFIT_SUCCESS";
export const LIST_BENEFIT_ERROR = "LIST_BENEFIT_ERROR";

export const DETAIL_BENEFIT_PENDING = "DETAIL_BENEFIT_PENDING";
export const DETAIL_BENEFIT_SUCCESS = "DETAIL_BENEFIT_SUCCESS";
export const DETAIL_BENEFIT_ERROR = "DETAIL_BENEFIT_ERROR";

export const APPROVAL_BENEFIT_PENDING = "APPROVAL_BENEFIT_PENDING";
export const APPROVAL_BENEFIT_SUCCESS = "APPROVAL_BENEFIT_SUCCESS";
export const APPROVAL_BENEFIT_ERROR = "APPROVAL_BENEFIT_ERROR";

export const ADD_BENEFIT_PENDING = "ADD_BENEFIT_PENDING";
export const ADD_BENEFIT_SUCCESS = "ADD_BENEFIT_SUCCESS";
export const ADD_BENEFIT_ERROR = "ADD_BENEFIT_ERROR";

export const EDIT_BENEFIT_PENDING = "EDIT_BENEFIT_PENDING";
export const EDIT_BENEFIT_SUCCESS = "EDIT_BENEFIT_SUCCESS";
export const EDIT_BENEFIT_ERROR = "EDIT_BENEFIT_ERROR";

export const UPLOAD_BENEFIT_PENDING = "UPLOAD_BENEFIT_PENDING";
export const UPLOAD_BENEFIT_SUCCESS = "UPLOAD_BENEFIT_SUCCESS";
export const UPLOAD_BENEFIT_ERROR = "UPLOAD_BENEFIT_ERROR";

export const SET_SELECTED_BENEFIT = "SET_SELECTED_BENEFIT";

const LIST_BENEFIT_URL = "member/redeem_benefit/find";
const APPROVAL_BENEFIT_URL = "member/redeem_benefit/approve";
const ADD_BENEFIT_URL = "member/redeem_benefit/add";
const EDIT_BENEFIT_URL = "member/redeem_benefit/edit";
const UPLOAD_BENEFIT_URL = "image/upload/member_redeem_benefit";

export function listBenefit(param = {}, resolve, detail = false) {
    return (dispatch) => {
        dispatch(actionPending(LIST_BENEFIT_PENDING));
        API.post(LIST_BENEFIT_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_BENEFIT_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    if (detail === true) {
                        let detailBenefit = data.data[0].journal_participants.map(
                            (item, i) => ({
                                ...item,
                                no: i + 1 + (param.page - 1) * 10,
                            })
                        );
                        resolve({
                            data: detailBenefit,
                            page: param.page - 1,
                            totalCount: detailBenefit.length,
                        });
                    } else {
                        resolve({
                            data: result,
                            page: param.page - 1,
                            totalCount: records_total,
                        });
                    }
                }
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_BENEFIT_ERROR, error));
                toastError(error, "Gagal mendapatkan list BENEFIT, ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function detailBenefit(id = null) {
    const param = {
        id: id,
    };
    return (dispatch) => {
        dispatch(actionPending(DETAIL_BENEFIT_PENDING));
        API.post(LIST_BENEFIT_URL, param, getHeader())
            .then((res) => {
                dispatch(actionSuccess(DETAIL_BENEFIT_SUCCESS, res.data.data));
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DETAIL_BENEFIT_ERROR, error));
                toastError(error, "Gagal mendapatkan list BENEFIT, ");
            });
    };
}

export function setSelectedBenefit(value) {
    return {
        type: SET_SELECTED_BENEFIT,
        value: value,
    };
}

export function approvalBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(APPROVAL_BENEFIT_PENDING));
        API.post(APPROVAL_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(APPROVAL_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah status benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(APPROVAL_BENEFIT_ERROR, error));
                toastError(error, "Gagal mengubah status benefit, ");
            });
    };
}

export function addBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_BENEFIT_PENDING));
        API.post(ADD_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_BENEFIT_ERROR, error));
                toastError(error, "Gagal menambahkan benefit, ");
            });
    };
}

export function editBenefit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_BENEFIT_PENDING));
        API.post(EDIT_BENEFIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_BENEFIT_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah benefit");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_BENEFIT_ERROR, error));
                toastError(error, "Gagal mengubah benefit, ");
            });
    };
}

export function uploadBenefit(file, callback, error_flag) {
    return (dispatch) => {
      dispatch(actionPending(UPLOAD_BENEFIT_PENDING));
      let formData = new FormData();
      formData.append("file", file);
      API.post(UPLOAD_BENEFIT_URL, formData, getHeader())
        .then((res) => {
          dispatch(actionSuccess(UPLOAD_BENEFIT_SUCCESS, res.data.filename));
          if (callback) {
            callback();
          }
          return res.data.filename;
        })
        .catch((error) => {
          dispatch(actionError(UPLOAD_BENEFIT_ERROR, error));
          if (error_flag) {
            error_flag();
          }
        });
    };
  }