import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { string, number, array } from "prop-types";
import ReactTooltip from "react-tooltip";

const NumberInfo = ({ data, numberInfo, arrayData, onClick, stylesProps }) => {
  const random = Math.random().toString();
  return (
    <Row vertical="center">
      {numberInfo && numberInfo !== 0 ? (
        <>
          <div onClick={onClick} className={stylesProps ? css(styles.stylesProps) : css(styles.data)}>
            {data && data}
          </div>
          {numberInfo > 1 && (
            <>
              <div
                data-tip
                data-for={random}
                className={css(styles.numberInfo)}
              >
                {`+ ${numberInfo}`}
              </div>
              <ReactTooltip
                id={random}
                type="dark"
                place="right"
                effect="solid"
              >
                {arrayData.map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </ReactTooltip>
            </>
          )}
        </>
      ) : (
          <>-</>
        )}
    </Row>
  );
};

const styles = StyleSheet.create({
  data: {
    borderBottom: "1px solid #495057",
    marginRight: 10,
    ":hover": {
      color: "#2CB96A",
      borderColor: "#2CB96A",
      cursor: "pointer",
    },
  },
  dataA: {
    // borderBottom: "1px solid #495057",
    marginRight: 10,
    // ":hover": {
    //   color: "#2CB96A",
    //   borderColor: "#2CB96A",
    //   cursor: "pointer",
    // },
  },
  numberInfo: {
    padding: "2px 6px",
    fontSize: 12,
    backgroundColor: "#A4A7AB",
    color: "white",
    borderRadius: 3,
    ":hover": {
      cursor: "pointer",
    },
  },
});

NumberInfo.propsTypes = {
  data: string.isRequired,
  numberInfo: number.isRequired,
  arrayData: array.isRequired,
};
// NumberInfo.defaultProps = {
//   arrayData: [],
// };
export default NumberInfo;
