import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalIuran } from "../../actions/iuran/iuran_action";
import {
    getIuranApproval,
    getIuranApprovalPending,
    getIuranApprovalError,
} from "../../reducers/iuran/iuran_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
    renderDatepickerWithLabel
} from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class ModalApprovalIuran extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        let param = {
            id: this.props.id,
            payment_date: values.payment_date
        }
        const callback = () => {
            this.props.onHide();
            this.props.handleRefresh();
        }
        this.props.approvalIuran(param, callback);
    }

    render() {
        const { approvalIuran_pending, handleSubmit } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width-change-status"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Konfirmasi
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="payment_date"
                                    placeholder="Masukkan Tanggal Pembayaran"
                                    label="Tanggal Pembayaran"
                                    component={renderDatepickerWithLabel}
                                />
                            </>

                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={
                                    approvalIuran_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                Submit
                        </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function validate(values) {
    const { payment_date } = values;
    const errors = {}
    if (!payment_date) {
        errors.payment_date = "Tanggal pembayaran wajib diisi"
    }
    return errors;
}

ModalApprovalIuran = reduxForm({
    form: "ModalApprovalIuran",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalApprovalIuran)

function mapStateToProps({ approvalIuran }) {
    return {
        approvalIuran_pending: getIuranApprovalPending(approvalIuran),
        approvalIuran_data: getIuranApproval(approvalIuran),
        approvalIuran_error: getIuranApprovalError(approvalIuran),
    };
}

export default connect(mapStateToProps, { approvalIuran })(ModalApprovalIuran);