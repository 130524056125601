import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column,Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { memberChangePassword} from "../../actions/member/member_action";
import { useHistory } from "react-router-dom";

import {
    getMemberChangePassword,
    getMemberChangePasswordPending,
    getMemberChangePasswordError
  } from "../../reducers/member/member_reducer";

class ModalChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChangePassword = this.handleChangePassword.bind(this);

    }

    handleChangePassword() {
        const { memberChangePassword } = this.props;
        memberChangePassword();
    };
    render() {
        const folder = this.props.folder ? this.props.folder + "/" : "member_p2kb/";
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 50 }}>
                       Peringatan Ubah Password
                </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <p>Silakan klik tombol berikut untuk melakukan ubah password, link ubah password akan dikirim melalui email anda</p>
                    </Column>
                    <Column>
                    <Row horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}>
                    <Button
                            onClick={this.handleChangePassword}
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                        >
                            Ubah Password
                        </Button>
                    </Row>
                    </Column>
                    
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        padding: "0px 30px 0px 40px"
    },
    labelMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    imgDocument: {
        maxHeight: 400,
        maxWidth: 400,
        objectFit: "contain",
        marginRight: "auto",
        marginLeft: "auto"
    }
});

function mapStateToProps(state) {
    const { memberChangePassword } = state;
    return {
        memberChangePassword_pending: getMemberChangePasswordPending(memberChangePassword),
        memberChangePassword_data: getMemberChangePassword(memberChangePassword),
        memberChangePassword_error: getMemberChangePasswordError(memberChangePassword)
    }
}

export default connect(mapStateToProps, { memberChangePassword })(ModalChangePassword); 