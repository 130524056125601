import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { listStrKT, detailStrKT } from "../../actions/p2kb-kt/str_kt_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import ModalStrKTAdd from "./ModalStrKTAdd";
import {
    getStrKTPending,
    getListStrKT,
    getStrKTError,
} from "../../reducers/p2kb-kt/str_kt_reducer";
import {
    getStrPending,
    getListStr,
    getStrError,
} from "../../reducers/p2kb/str_reducer";
import { listStr } from "../../actions/p2kb/str_action";
import HeaderStrKT from "./HeaderStrKT";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";

class ListStrKT extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        if (getUser().role_codes[0] === "member") {
            this.state = {
                user_id: getUser().id,
                search: "",
                status: null,
                id: null
            };
        } else {
            this.state = {
                user_id: "",
                search: "",
                status: null,
                id: null
            };
        }
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
        this.detailRecord = this.detailRecord.bind(this);
        this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
    }

    componentDidMount() {
        this.props.listStr({ length: 99999999 })
        const id = this.props.match.params.id;
        if (id && this.state.id === null) {
            this.setState({ id: id })
        }
    }

    handleRefresh(state) {
        this.setState(state);
        this.setState({ id: null })
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    detailRecord(id) {
        if (getUser().role_codes[0] === "member") {
            this.props.history.push(`/member/strkt/detail/${id}`);
        } else {
            this.props.history.push(`/admin/strkt/detail/${id}`);
        }
    }

    handleOpenModalAdd(id) {
        this.setState({ id, modalAddShow: true });
    }

    handleOpenModalDelete(id) {
        this.setState({ id, modalDeleteShow: true });
    }

    handleOpenModalEdit(id, isEdit = false) {
        const { detailStr } = this.props;
        detailStr(id);
        this.setState({ id, isEdit });
        this.setState({ modalEditShow: true });
    }

    render() {
        let dataSTRKT = [];
        let strOptions = [];
        let records_total = 0;
        const { listStrKT, listStr_data, listStrKT_data } = this.props;
        if (listStrKT_data) {
            dataSTRKT = listStrKT_data.data;
            records_total = listStrKT_data.records_total;
        }
        const {
            id,
            modalAddShow,
        } = this.state;
        if (listStr_data && listStr_data.length !== 0) {
            strOptions = listStr_data.data.filter((item) => item.status === 1).map(({ str_no, id, status }) => ({
                label: str_no,
                value: id,
            }));
        }
        return (
            <ContentContainer>
                <ModalStrKTAdd
                    id={id}
                    show={modalAddShow}
                    onHide={() => this.setState({ modalAddShow: false })}
                    handleRefresh={this.handleRefresh}
                    strOptions={strOptions}
                />
                <HeaderStrKT
                    handleRefresh={this.handleRefresh}
                    handleOpenModalAdd={this.handleOpenModalAdd}
                />

                <MaterialTable
                    tableRef={this.tableRef}
                    title="Simple Action Preview"
                    columns={getUser().role_codes[0] === "member" ?
                        [
                            {
                                title: "No",
                                field: "name",
                                render: (rowData) => {
                                    return <>{rowData.no}</>;
                                },
                                width: 20,
                                headerStyle: {
                                    paddingLeft: 40,
                                },
                                cellStyle: {
                                    paddingLeft: 40,
                                },
                            },
                            {
                                title: "Nomor STR KT",
                                field: "str_kt_no",
                            },
                            {
                                title: "Tanggal Penetapan",
                                render: (data) => {
                                    return moment(data.start_date)
                                        .locale("id", localization)
                                        .format("DD MMMM YYYY");
                                },
                            },
                            {
                                title: "Tanggal Berakhir",
                                render: (data) => {
                                    return moment(data.end_date)
                                        .locale("id", localization)
                                        .format("DD MMMM YYYY");
                                },
                            },
                            {
                                title: "Status",
                                field: "status",
                                render: ({ status }) => {
                                    return (
                                        <TableStatusComponent
                                            status={
                                                status === 0
                                                    ? "Proses"
                                                    : status === 1
                                                        ? "Disetujui"
                                                        : "Ditolak"
                                            }
                                        />
                                    );
                                },
                            },
                            {
                                title: "",
                                render: ({ id }) => (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.detailRecord(id)}>
                                            Detail
                                    </MenuItem>
                                    </DetailButtonComponent>
                                ),
                            },
                        ]
                        :
                        [
                            {
                                title: "No",
                                field: "name",
                                render: (rowData) => {
                                    return <>{rowData.no}</>;
                                },
                                width: 20,
                                headerStyle: {
                                    paddingLeft: 40,
                                },
                                cellStyle: {
                                    paddingLeft: 40,
                                },
                            },
                            {
                                title: "Member",
                                field: "user_data.name",
                            },
                            {
                                title: "Nomor STR KT",
                                field: "str_kt_no",
                            },
                            {
                                title: "Tanggal Penetapan",
                                render: (data) => {
                                    return moment(data.start_date)
                                        .locale("id", localization)
                                        .format("DD MMMM YYYY");
                                },
                            },
                            {
                                title: "Tanggal Berakhir",
                                render: (data) => {
                                    return moment(data.end_date)
                                        .locale("id", localization)
                                        .format("DD MMMM YYYY");
                                },
                            },
                            {
                                title: "Status",
                                field: "status",
                                render: ({ status }) => {
                                    return (
                                        <TableStatusComponent
                                            status={
                                                status === 0
                                                    ? "Proses"
                                                    : status === 1
                                                        ? "Disetujui"
                                                        : "Ditolak"
                                            }
                                        />
                                    );
                                },
                            },
                            {
                                title: "Role",
                                field: "role_next_approve.role_name",
                                render: (rowData) => (
                                    rowData.role_next_approve ? rowData.role_next_approve.role_name : "Admin Pusat"
                                )
                            },
                            {
                                title: "",
                                render: ({ id }) => (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.detailRecord(id)}>
                                            Detail
                                        </MenuItem>
                                    </DetailButtonComponent>
                                ),
                            },
                        ]
                    }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                user_id: this.state.user_id,
                                status: this.state.status,
                                id: this.state.id,
                            };
                            listStrKT(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                            zIndex: 0,
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <td style={{ width: "100%", display: "block" }}>
                                <Row
                                    // vertical="center"
                                    horizontal="space-between"
                                    style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        // background: "red",
                                    }}
                                >
                                    <Row
                                        style={{
                                            // backgroundColor: "red",
                                            width: "50%",
                                        }}
                                        vertical="center"
                                    >
                                        <p>
                                            Menampilkan {dataSTRKT.length} dari {records_total} data
                                        </p>
                                    </Row>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <TablePagination {...props} />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                            </td>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listStrKT, listStr }) {
    return {
        listStrKT_data: getListStrKT(listStrKT),
        listStrKT_error: getStrKTError(listStrKT),
        listStrKT_pending: getStrKTPending(listStrKT),
        listStr_data: getListStr(listStr),
        listStr_error: getStrError(listStr),
        listStr_pending: getStrPending(listStr)
    };
}

export default connect(mapStateToProps, {
    listStrKT,
    detailStrKT,
    listStr
})(ListStrKT);