import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalBenefit } from "../../actions/benefit/benefit_action";
import {
  getBenefitApproval,
  getBenefitApprovalPending,
  getBenefitApprovalError,
} from "../../reducers/benefit/benefit_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInputWithLabel,
} from "../../components/form/FormComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class ModalRejectBenefit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { approvalBenefit, id, onHide } = this.props;
    const param = {
        id: id,
        is_approve: "false",
        reject_notes: values.reject_notes
    }
    const callback = () => {
      onHide();
      this.props.handleRefresh({ forRefresh: true });
    };
    approvalBenefit(param, callback);
  }

  render() {
    const { handleSubmit, approvalBenefit_pending } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="reject_notes"
                  placeholder="Masukkan alasan penolakan"
                  label="Alasan Penolakan"
                  component={renderInputWithLabel}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={
                  approvalBenefit_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                Submit
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function validate(values) {
  const { reject_notes } = values;
  const errors = {};
  if (!reject_notes) {
    errors.reject_notes = "Alasan penolakan wajib diisi";
  }
  return errors;
}

ModalRejectBenefit = reduxForm({
  form: "ModalRejectBenefit",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalRejectBenefit);

function mapStateToProps({ approvalBenefit }) {
  return {
    approvalBenefit_pending: getBenefitApprovalPending(approvalBenefit),
    approvalBenefit_data: getBenefitApproval(approvalBenefit),
    approvalBenefit_error: getBenefitApprovalError(approvalBenefit),
  };
}

export default connect(mapStateToProps, { approvalBenefit })(ModalRejectBenefit);
