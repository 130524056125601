import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { editHospital } from "../../actions/master-data/hospital_action";
import { renderInput, renderSelect } from "../../components/form/FormComponent";
import {
  getHospitalError,
  getDetailHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";

import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";

import {
  getProvinceList,
  getProvinceListPending,
} from "../../reducers/master-data/province_reducer";

class ModalHospitalEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { editHospital, onHide, id, handleRefresh } = this.props;
    const param = {
      id: id,
      name: values.name,
      code: values.code,
      class_type: values.class_type.value,
      address: values.address,
      phone: values.phone,
      email: values.email,
      province_id: values.province.value,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editHospital(param, callback);
  }

  render() {
    let provinceOption = [];
    const { listProvince_data } = this.props;
    if (listProvince_data) {
      provinceOption = listProvince_data.map((value, index) => {
        const province = { value: value.province_id, label: value.name };
        return province;
      });
    }
    const rsClassOption = [
      {
        label: "A",
        value: "A"
      },
      {
        label: "B",
        value: "B"
      },
      {
        label: "C",
        value: "C"
      },
      {
        label: "D",
        value: "D"
      },
      {
        label: "E",
        value: "E"
      },
    ];
    const {
      handleSubmit,
      show,
      onHide,
      editHospital_pending,
      detailHospital_pending,
      listProvince_pending,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit Rumah Sakit</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailHospital_pending || listProvince_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                  <>
                    <LabelInputVerticalComponent
                      label="Kode Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="code"
                      type="number"
                      placeholder="Masukkan kode RS"
                      component={renderInput}
                    />
                    <LabelInputVerticalComponent
                      label="Nama Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="name"
                      placeholder="Masukkan nama RS"
                      component={renderInput}
                    />
                    <LabelInputVerticalComponent
                      label="Kelas Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="class_type"
                      placeholder="Pilih Kelas RS"
                      options={rsClassOption}
                      component={renderSelect}
                    />
                    <LabelInputVerticalComponent
                      label="Alamat Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="address"
                      placeholder="Masukkan alamat RS"
                      component={renderInput}
                    />
                    <LabelInputVerticalComponent
                      label="Nomor Telepon Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="phone"
                      placeholder="Masukkan telepon RS"
                      component={renderInput}
                    />
                    <LabelInputVerticalComponent
                      label="Email Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="email"
                      placeholder="Masukkan email RS"
                      component={renderInput}
                    />
                    <LabelInputVerticalComponent
                      label="Provinsi Rumah Sakit"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="province"
                      placeholder="Pilih Provinsi"
                      options={provinceOption}
                      component={renderSelect}
                    />
                  </>
                )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editHospital_pending}
                className={css(styles.approveButton)}
                startIcon={
                  editHospital_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                      <CheckIcon />
                    )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { code, name, province, email, phone, address, class_type } = values;
  const errors = {};

  if (!name) {
    errors.name = "Nama RS tidak boleh kosong";
  }
  if (!code) {
    errors.code = "Kode RS tidak boleh kosong";
  }
  if (!class_type) {
    errors.class_type = "Tipe Kelas RS tidak boleh kosong";
  }
  if (!address) {
    errors.address = "Alamat RS tidak boleh kosong";
  }
  if (!phone) {
    errors.phone = "Nomor Telepon RS tidak boleh kosong";
  }
  if (!email) {
    errors.email = "Email RS tidak boleh kosong";
  }
  if (!province) {
    errors.province = "Provinsi RS tidak boleh kosong";
  }
  return errors;
}

ModalHospitalEdit = reduxForm({
  // a unique name for the form
  form: "ModalHospitalEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ModalHospitalEdit);

function mapStateToProps({ detailHospital, editHospital, provinceList }) {
  let initialValues = {};
  const detailHospital_data = getDetailHospital(detailHospital);
  if (detailHospital_data) {

    initialValues = {
      code: detailHospital_data[0].code,
      name: detailHospital_data[0].name,
      email: detailHospital_data[0].email,
      phone: detailHospital_data[0].phone,
      address: detailHospital_data[0].address
    };

    if (detailHospital_data[0].class_type) {
      initialValues = {
        ...initialValues,
        class_type: {
          value: detailHospital_data[0].class_type ? detailHospital_data[0].class_type : null,
          label: detailHospital_data[0].class_type
        },
      }
    }
    if (detailHospital_data[0].province) {
      initialValues = {
        ...initialValues,
        province: {
          value: detailHospital_data[0].province_id ? detailHospital_data[0].province_id : null,
          label: detailHospital_data[0].province?.name,
        },
      }
    }
  }
  return {
    detailHospital_pending: getHospitalPending(detailHospital),
    detailHospital_data: getDetailHospital(detailHospital),
    detailHospital_error: getHospitalError(detailHospital),
    editHospital_pending: getHospitalPending(editHospital),
    listProvince_data: getProvinceList(provinceList),
    listProvince_pending: getProvinceListPending(provinceList),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  editHospital,
})(withRouter(ModalHospitalEdit));
