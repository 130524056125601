import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import {
  getProvincePending,
  getListProvince,
} from "../../reducers/master-data/province_temp_reducer";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import RenderArray from "./BranchForm";
import { reduxForm, FieldArray, Field } from "redux-form";
import { addBranch } from "../../actions/master-data/branch_action";
import { renderInput, renderSelect } from "../../components/form/FormComponent";
import {
  // getBranchError,
  // getDetailBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";

class ModalBranchAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addBranch, onHide, handleRefresh } = this.props;
    let province = [];
    province.push(values.province.value)
    const param = {
      branch_provinces: province,
      name: values.name,
      city_id: values.city_id.value,
      phone: values.phone,
      email: values.email,
      office_address: values.office_address,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    addBranch(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addBranch_pending,
      detailBranch_pending,
      cityOptions,
      listProvice_data,
      names
    } = this.props;
    let province_options = [];
    if (listProvice_data) {
      const names_id = names ? names.map((item) => item && item.value) : [];
      province_options = listProvice_data.data
        .filter((item) => names_id.indexOf(item.province_id) === -1)
        .map((item) => ({
          value: item.province_id,
          label: item.name,
        }));

    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Cabang</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailBranch_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <Field
                    name="name"
                    placeholder="Masukkan nama cabang"
                    component={renderInput}
                  />
                  <Field
                    name="city_id"
                    placeholder="Pilih Kota"
                    options={cityOptions}
                    component={renderSelect}
                  />
                  <Field
                    name="office_address"
                    placeholder="Masukkan alamat sekretariat"
                    component={renderInput}
                  />
                  <Field
                    name="phone"
                    type="number"
                    placeholder="Masukkan no.telepon"
                    component={renderInput}
                  />
                  <Field
                    name="email"
                    type="email"
                    placeholder="Masukkan email"
                    component={renderInput}
                  />
                  <Field
                    name="province"
                    options={province_options}
                    placeholder="Pilih Provinsi"
                    component={renderSelect}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addBranch_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addBranch_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { province, email, name, city_id, phone, office_address } = values;
  const errors = {};
  const provinceArrayErrors = [];
  if (!province) {
    errors.province = "Provinsi tidak boleh kosong";
  }
  if (!name) {
    errors.name = "Nama cabang tidak boleh kosong";
  }
  if (!email) {
    errors.email = "Email tidak boleh kosong";
  }
  if (!phone) {
    errors.phone = "No.telpon tidak boleh kosong";
  }
  if (!office_address) {
    errors.office = "Alamat cabang tidak boleh kosong";
  }
  if (!city_id) {
    errors.city_id = "Kota tidak boleh kosong";
  }
  return errors;
}

ModalBranchAdd = reduxForm({
  // a unique name for the form
  form: " ",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalBranchAdd);

function mapStateToProps({ addBranch, listProvincetTemp }) {
  return {
    addBranch_pending: getBranchPending(addBranch),
    listProvice_pending: getProvincePending(listProvincetTemp),
    listProvice_data: getListProvince(listProvincetTemp),
  };
}

export default connect(mapStateToProps, {
  addBranch,
})(withRouter(ModalBranchAdd));
