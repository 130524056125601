import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { memberList } from "../../actions/member/member_action";
import {
  renderInputWithLabel,
  renderSelectWithLabel,
  renderDatepickerWithLabel2,
  renderDatepickerWithLabel
} from "../../components/form/FormComponent";
import {
  getMemberList, getMemberPending,
} from "../../reducers/member/member_reducer";
import {
  addMembership,
} from "../../actions/koperasi/membership_action";
import Modal_edit_status_member_aktif from "../member/Modal_edit_status_member_aktif";
class ModalAddMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      durationOptions: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
      // this.props.change("main_deposit", 0)
    }
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, addMembership } = this.props
    const { member: { value: user_id }, main_deposit, code, required_deposit, status, date } = values;
    const param = {
      user_id,
      main_deposit,
      required_deposit,
      status: status.value,
      code: code,
      join_date : date,

    };

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addMembership(param, callback);
  }

  handlePeriodChange = (e) => {
    if (e) {
      let i;
      let newDurationOptions = [];
      for (i = 1; i <= 60; i++) {
        if (i % e.value === 0) {
          if (e.value !== 12) {
            newDurationOptions = [...newDurationOptions, { label: i + ' Bulan', value: i }];
          } else {
            newDurationOptions = [...newDurationOptions, { label: i / 12 + ' Tahun', value: i }];
          }
        }
      }
      this.setState({ durationOptions: newDurationOptions })
    } else {
      this.setState({ durationOptions: [] })
      this.props.change("duration", null)
    }
  }

  formatAmount(input) {
    if (!input) return "";
    else input = input.toString()
    if (isNaN(parseInt(input, 10))) {
      return "";
    } else input = Number(input)
    var reverse = input.toString().split('').reverse().join(''),
      ribuan = reverse.match(/\d{1,3}/g);
    ribuan = ribuan.join('.').split('').reverse().join('');
    return "Rp " + ribuan;
  }

  normalizeCurrency = (val) => {
    if (!val) {
      return ""
    }
    let result = val.replace("Rp ", "").split('.').join('');
    if (isNaN(parseInt(result, 10))) {
      return ""
    }
    return result
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      listMember_pending,
      listMember_data,
      addMembership_pending,
      alreadyMembership
    } = this.props;
    let memberOptions = [];
    if (listMember_data) {
      memberOptions = listMember_data.data.filter(item => !alreadyMembership.includes(item.id))
        .map(({ id, name, member_profile: { paboi_id } }) => ({
          label: `${name} - ${paboi_id}`,
          value: id,
        }));
    }

    const statusOptions = [
      {
        label: "Aktif",
        value: 1
      },
      {
        label: "Tidak Aktif",
        value: 0
      },
    ]

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Anggota</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {listMember_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <Field
                    name="date"
                    placeholder="Masukkan tanggal pengajuan"
                    label="Tanggal Pengajuan"
                    component={renderDatepickerWithLabel}
                  />
                  <Field
                    name="code"
                    type="text"
                    label='ID Koperasi'
                    placeholder="Masukkan id koperasi"
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="member"
                    label="Nama Dokter"
                    placeholder="Pilih Member"
                    component={renderSelectWithLabel}
                    isAsync
                    asyncUrl="member/member/find"
                    options={memberOptions}
                  />
                  <Field
                    name="main_deposit"
                    type="text"
                    label='Simpanan Pokok'
                    placeholder="Masukkan simpanan pokok"
                    format={this.formatAmount}
                    normalize={this.normalizeCurrency}
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="status"
                    label="Status Anggota"
                    placeholder="Pilih status anggota"
                    component={renderSelectWithLabel}
                    options={statusOptions}
                  />
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addMembership_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addMembership_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  const { member, main_deposit, required_deposit, status } = values;
  const errors = {};
  if (!main_deposit) {
    errors.main_deposit = 'Iuran pokok harus diisi'
  }
  if (!member) {
    errors.member = "Nama dokter harus diisi"
  }
  if (!status) {
    errors.status = "Status anggota harus diisi"
  }

  return errors;
}

ModalAddMembership = reduxForm({
  // a unique name for the form
  form: "ModalAddMembership",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalAddMembership);

function mapStateToProps({ memberList, addMembership }) {
  return {
    addMembership_pending: getMemberPending(addMembership),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList)
  };
}

export default connect(mapStateToProps, {
  memberList, addMembership
})(withRouter(ModalAddMembership));
