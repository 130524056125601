import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { Paper } from "@material-ui/core";
import HeaderMember from "./Header_member";
import { getUser } from "../../helpers/user";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import PaginationComponent from "../../components/table/PaginationComponent";
import { p2kbListMember } from "../../actions/p2kb/p2kb_action";
import moment from "moment";
import {
  getP2kbMemberListPending,
  getP2kbMemberList,
  getP2kbMemberListError,
} from "../../reducers/p2kb/p2kb_reducer";

class ListMemberP2KB extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    localStorage.removeItem("start_date");
    localStorage.removeItem("isSerkom");
    localStorage.removeItem("id_serkom");
    localStorage.removeItem("id_member");
    localStorage.removeItem("end_date");
    this.state = {
      pagination: null,
      search: "",
      status: null,
      columns: [
        {
          title: "No",
          field: "name",
          render: (rowData) => {
            return <>{rowData.no}</>;
          },
          width: 20,
          headerStyle: {
            paddingLeft: 40,
          },
          cellStyle: {
            paddingLeft: 40,
          },
        },
        {
          title: "Tanggal",
          render: ({ latest_update }) => {
            let tanggal_masuk;
            const date = moment(latest_update).format("DD MMMM YYYY");
            return <>{date}</>;
          },
        },

        {
          title: "Nama",
          field: "name",
          render: (data) => {
            return data.user_data.name;
          },
        },
        { title: "No ID PABOI", field: "paboi_id" },
        {
          title: "SKP Disubmit",
          field: "total_skp",
          render: (data) => {
            let total_skp = "0";
            total_skp = data.total_skp ? data.total_skp.toFixed(2) : total_skp;
            return total_skp;
          },
        },
        {
          title: "Status",
          render: ({ min_status }) => {
            const status = min_status;
            return (
              <TableStatusComponent
                status={
                  status === 0
                    ? "Menunggu"
                    : status === 1
                    ? "Disetujui"
                    : status === 2
                    ? "Ditolak"
                    : "-"
                }
              />
            );
          },
        },
        {
          title: "Keterangan",
          render: (data) => {
            let ket = "-";
            data.min_status === 0
              ? (ket = "Menunggu disetujui ")
              : data.min_status === 1
              ? (ket = "Approved oleh ")
              : data.min_status === 2
              ? (ket = "Ditolak oleh ")
              : (ket = "-");
            data.min_status === 0 && data.min_submit_status === 0
              ? (ket = "-")
              : (ket += data.role_next_approve?.role_name);
            return ket;
          },
        },
        {
          title: "",
          render: (rowData) => {
            return (
              <DetailButtonComponent>
                <MenuItem onClick={() => this.detailRecord(rowData)}>
                  Detail
                </MenuItem>
              </DetailButtonComponent>
            );
          },
        },
      ],
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  detailRecord(data) {
    const id = data.user_id;
    localStorage.setItem("ranah_id", id);
    localStorage.setItem("id_member", id);
    localStorage.setItem("nama_member", data.user_data.name);
    this.props.history.push(`/admin-p2kb/member/${id}`);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange({ page: 0 });
  }

  componentDidMount() {
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  }

  shouldComponentRender() {
    const { memberList_pending } = this.props;
    if (memberList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { memberList, memberList_data, p2kbListMember, p2kbListMember_data } =
      this.props;
    let data = [];
    let records_total = 0;
    if (this.state.pagination) {
      data = this.state.pagination.data;
      records_total = this.state.pagination.totalCount;
    }

    return (
      <ContentContainer>
        <HeaderMember handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          title="Member"
          columns={this.state.columns}
          data={(query) => {
            const promise = new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 20,
                search_text: this.state.search,
                status: this.state.status,
                id: "",
                branch_id: getUser().branch_id,
              };

              p2kbListMember(param, resolve, reject);
            });
            promise.then((data) => {
              this.setState({ ...this.state, pagination: data });
            });
            return promise;
          }}
          options={{
            pageSize: 20,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ memberList, p2kbListMember }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    p2kbListMember_pending: getP2kbMemberListPending(p2kbListMember),
    p2kbListMember_data: getP2kbMemberList(p2kbListMember),
    p2kbListMember_error: getP2kbMemberListError(p2kbListMember),
  };
}

export default connect(mapStateToProps, { memberList, p2kbListMember })(
  withRouter(ListMemberP2KB)
);
