import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_EVENT_PENDING = "LIST_EVENT_PENDING";
export const LIST_EVENT_SUCCESS = "LIST_EVENT_SUCCESS";
export const LIST_EVENT_ERROR = "LIST_EVENT_ERROR";

export const DETAIL_EVENT_PENDING = "DETAIL_EVENT_PENDING";
export const DETAIL_EVENT_SUCCESS = "DETAIL_EVENT_SUCCESS";
export const DETAIL_EVENT_ERROR = "DETAIL_EVENT_ERROR";

export const ADD_EVENT_PENDING = "ADD_EVENT_PENDING";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_ERROR = "ADD_EVENT_ERROR";

export const EDIT_EVENT_PENDING = "EDIT_EVENT_PENDING";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";

export const DELETE_EVENT_PENDING = "DELETE_EVENT_PENDING";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const APPROVE_EVENT_PENDING = "APPROVE_EVENT_PENDING";
export const APPROVE_EVENT_SUCCESS = "APPROVE_EVENT_SUCCESS";
export const APPROVE_EVENT_ERROR = "APPROVE_EVENT_ERROR";

export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";

const LIST_EVENT_URL = "cms/event/find";
const EDIT_EVENT_URL = "cms/event/edit";
const DELETE_EVENT_URL = "cms/event/delete";
const APPROVE_EVENT_URL = "cms/event/approve_acceptance";
const ADD_EVENT_URL = "cms/event/add";

export function listEvent(param = {}, resolve, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_EVENT_PENDING));
    API.post(LIST_EVENT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_EVENT_SUCCESS, data));
        const { records_total, data: response } = data;
        const result = response.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          ...item,
        }));
        if (resolve) {
          if (detail === true) {
            let detailEvent = data.data[0].event_participants.map(
              (item, i) => ({
                ...item,
                no: i + 1 + (param.page - 1) * 10,
              })
            );
            resolve({
              data: detailEvent,
              page: param.page - 1,
              totalCount: detailEvent.length,
            });
          } else {
            resolve({
              data: result,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_EVENT_ERROR, error));
        toastError(error, "Gagal mendapatkan list EVENT, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addEvent(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_EVENT_PENDING));
    API.post(ADD_EVENT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_EVENT_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan EVENT");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_EVENT_ERROR, error));
        toastError(error, "Gagal menambahkan EVENT, ");
      });
  };
}

export function detailEvent(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_EVENT_PENDING));
    API.post(LIST_EVENT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_EVENT_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_EVENT_ERROR, error));
        toastError(error, "Gagal mendapatkan list EVENT, ");
      });
  };
}

export function editEvent(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_EVENT_PENDING));
    API.post(EDIT_EVENT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_EVENT_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah EVENT");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_EVENT_ERROR, error));
        toastError(error, "Gagal mengubah EVENT, ");
      });
  };
}

export function deleteEvent(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_EVENT_PENDING));
    API.post(DELETE_EVENT_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_EVENT_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus EVENT");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_EVENT_ERROR, error));
        toastError(error, "Gagal menghapus EVENT, ");
      });
  };
}

export function setSelectedEvent(value) {
  return {
    type: SET_SELECTED_EVENT,
    value: value,
  };
}

export function approveEvent(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_EVENT_PENDING));
    API.post(APPROVE_EVENT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_EVENT_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status EVENT");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_EVENT_ERROR, error));
        toastError(error, "Gagal mengubah status EVENT, ");
      });
  };
}
