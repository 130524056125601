import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment";
import localization from "moment/locale/id";

import { listEvent, detailEvent, setSelectedEvent } from "../../actions/event/event_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { provinceList } from "../../actions/master-data/province_action";

import {
  getEventPending,
  getListEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
import HeaderEvent from "./HeaderEvent";

class Event extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      event_date_start: "",
      event_date_end: "",
      id: null
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailEvent(data) {
    this.props.setSelectedEvent(data);
    this.props.history.push(`event/participant/${data.id}`);
  }

  componentDidMount() {
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id })
    }
  }

  render() {
    let data = [];
    let records_total = 0;
    const { listEvent, listEvent_data } = this.props;
    if (listEvent_data) {
      data = listEvent_data.data;
      records_total = listEvent_data.records_total;
    }

    return (
      <ContentContainer>
        <HeaderEvent
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "event_name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tgl Event",
              render: (data) => {
                return moment(data.event_start_date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            {
              title: "Nama Event",
              field: "event_name",
            },
            {
              title: "Total Peserta",
              field: "total_participants",
            },
            {
              title: "Peserta Hadir",
              field: "total_absent_participants",
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.handleDetailEvent(rowData)}>
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                event_date_start: this.state.event_date_start,
                event_date_end: this.state.event_date_end,
                id: this.state.id
              };
              listEvent(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listEvent, setSelectedEvent }) {
  return {
    listEvent_data: getListEvent(listEvent),
    listEvent_error: getEventError(listEvent),
    listEvent_pending: getEventPending(listEvent),
  };
}

export default connect(mapStateToProps, {
  listEvent,
  detailEvent,
  provinceList,
  setSelectedEvent
})(Event);
