import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import SelectComponent from "../../components/form/SelectComponent";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import InputComponent from "../../components/form/InputComponent";
import SearchIcon from "@material-ui/icons/Search";



const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
});
class IuranHeader extends Component {
  constructor() {
    super();
    this.state = {
      report: "",
      year: new Date()
    };

    this.delayedQuery = this.delayedQuery.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCabangChange = this.handleCabangChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleDateChange(e) {
    if (e) {
      this.setState({ year: e.value })
      this.props.handleRefresh({ year: e.value });
    } else {
      this.setState({ year: null })
      this.props.handleRefresh({ year: null });
    }
  }
  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
}

  handleCabangChange(e) {
    if (e) {
      this.props.handleRefresh({ branch_id: e.value });
    } else {
      this.props.handleRefresh({ branch_id: null });
    }
  }

  render() {
    const { listBranch_data } = this.props;
    let branchOptions = [];
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => {
        return { label: name, value: id };
      });
    }
    let yearOption = [];
    const currentYear = new Date().getFullYear();
    const lastTwentyYear = new Date().getFullYear() - 20;
    for (let i = lastTwentyYear; i <= currentYear; i++) {
      const year = { value: i, label: i }
      yearOption = [...yearOption, year]
    }
    return (
      <Row
        style={{
          padding: 24,
          paddingBottom: 30,
          backgroundColor: "#F8F9FB",
          zIndex: 20,
        }}
        horizontal="flex-end"
      >
        <Row>
          <div className={css(styles.container)}>
                        <InputComponent
                            onChange={this.handleSearchChange}
                            value={this.state.tes}
                            placeholder="Cari Nama Member"
                            startIcon={SearchIcon}
                        />
                    </div>
          <div className={css(styles.container)}>
            <SelectComponent
              placeholder="Pilih Cabang"
              options={branchOptions}
              onChange={this.handleCabangChange}
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps({ listBranch }) {
  return {
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
  };
}

export default connect(mapStateToProps, {})(withRouter(IuranHeader));
