import React from "react";
import { string } from "prop-types";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

function DetailMemberPengantarComponent(props) {
    const { pengantar } = props;
    return <Row className={(css(styles.detailPengantar))}>{pengantar}</Row>
}

DetailMemberPengantarComponent.propTypes = {
    pengantar: string,
};

const styles = StyleSheet.create({
    detailPengantar: {
        textAlign: "left",
        fontSize: 14,
        color: "#7F8388",
        marginTop: 10
    },
});

export default DetailMemberPengantarComponent;
