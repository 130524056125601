import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { editSip } from "../../actions/p2kb/sip_action";
import {
  renderInput,
  renderDatepicker,
} from "../../components/form/FormComponent";
import {
  getSipError,
  getDetailSip,
  getSipPending,
} from "../../reducers/p2kb/sip_reducer";

import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";

class ModalSipEdit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { editSip, onHide, id, handleRefresh } = this.props;
    const param = {
      id: id,
      sip_no: values.sip_no,
      start_date: values.start_date,
      end_date: values.end_date,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    editSip(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      editSubspesialis_pending,
      detailSip_pending,
      listProvince_pending,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Detail SIP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {detailSip_pending || listProvince_pending ? (
                <Row
                  style={{ height: 40, color: "#CDCFD1" }}
                  horizontal="center"
                  vertical="center"
                  className="error"
                >
                  Memuat data ...
                </Row>
              ) : (
                <>
                  <LabelInputVerticalComponent
                    label="Nomor SIP"
                    marginTop="20px"
                  ></LabelInputVerticalComponent>
                  <Field
                    name="sip_no"
                    type="text"
                    placeholder="Masukkan Nomor SIP"
                    component={renderInput}
                  />

                  <Row horizontal="space-between">
                    <div>
                      <LabelInputVerticalComponent
                        label="Tanggal Pengesahan"
                        marginTop="20px"
                      ></LabelInputVerticalComponent>
                      <Field
                        name="start_date"
                        type="text"
                        placeholder="Pilih RS"
                        component={renderDatepicker}
                      />
                    </div>

                    <div>
                      <LabelInputVerticalComponent
                        label="Berlaku Sampai"
                        marginTop="20px"
                      ></LabelInputVerticalComponent>
                      <Field
                        name="end_date"
                        type="text"
                        placeholder="Pilih RS"
                        component={renderDatepicker}
                      />
                    </div>
                  </Row>

                  {/* <Field
                    name="berkas"
                    type="file"
                    fileType="image" //image or pdf
                    title="upload berkas"
                    message="PNG atau JPG maksimal 500 kb"
                    component={renderFile}
                  /> */}
                </>
              )}
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={editSubspesialis_pending}
                className={css(styles.approveButton)}
                startIcon={
                  editSubspesialis_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { name } = values;
  const errors = {};

  if (!name) {
    errors.name = "Nama Subspesialis tidak boleh kosong";
  }
  return errors;
}

ModalSipEdit = reduxForm({
  // a unique name for the form
  form: "ModalSipEdit",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(ModalSipEdit);

function mapStateToProps({ detailSip, editSip }) {
  let initialValues = {};
  const detailSip_data = getDetailSip(detailSip);
  if (detailSip_data) {
    initialValues = {
      sip_no: detailSip_data[0].sip_no,
      start_date: detailSip_data[0].start_date,
      end_date: detailSip_data[0].end_date,
    };
  }
  return {
    detailSip_pending: getSipPending(detailSip),
    detailSip_data: getDetailSip(detailSip),
    detailSip_error: getSipError(detailSip),
    editSip_pending: getSipPending(editSip),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  editSip,
})(withRouter(ModalSipEdit));
