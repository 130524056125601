import React, { Component } from "react";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEventPending,
  getDetailEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
import moment from "moment";
import localization from "moment/locale/id";
class UmumEvent extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
  }

  render() {
    let result = {
      event_name: null,
      registration_no: null,
      registration_date: null,
      fullname: null,
      back_title: null,
      front_title: null,
      membership: null,
      institution: null,
      phone_number: null,
      email: null,
    };
    const {
      detailEvent_data,
      match: {
        params: { id_member },
      },
    } = this.props;

    if (detailEvent_data && detailEvent_data.length !== 0) {
      const member = detailEvent_data[0].event_participants.find(
        ({ id }) => id_member === `${id}`
      );
      if (member) {
        result.front_title = member.front_title;
        result.back_title = member.back_title;
        result.membership = member.membership;
        result.fullname = member.fullname;
        result.institution = member.institution ? member.institution : "-";
        result.phone_number = member.phone_number ? member.phone_number : "-";
        result.email = member.email ? member.email : "-";
        result.registration_date = moment(member.created_at)
          .locale("id", localization)
          .format("DD MMMM YYYY");
        result.registration_no = member.register_number;
      }
      const data = detailEvent_data[0];
      result.event_name = data.event_name;
    }

    const {
      event_name,
      registration_no,
      registration_date,
      fullname,
      back_title,
      front_title,
      membership,
      institution,
      phone_number,
      email,
    } = result;

    return (
      <>
        <DetailNameComponent name="Informasi Umum" />
        <DetailContentComponent label="Nama Event" value={event_name} />
        <DetailContentComponent label="No Registrasi" value={registration_no} />
        <DetailContentComponent
          label="Tanggal Registrasi"
          value={registration_date}
        />
        <DetailContentComponent label="Nama Lengkap" value={fullname} />
        <DetailContentComponent label="Gelar Depan" value={front_title} />
        <DetailContentComponent label="Gelar Belakang" value={back_title} />
        <DetailContentComponent label="Institusi" value={institution} />
        <DetailContentComponent label="No Telepon" value={phone_number} />
        <DetailContentComponent label="Email" value={email} />
        <DetailContentComponent label="Membership" value={membership} />
      </>
    );
  }
}

function mapStateToProps({ detailEvent }) {
  return {
    detailEvent_data: getDetailEvent(detailEvent),
    detailEvent_error: getEventError(detailEvent),
    detailEvent_pending: getEventPending(detailEvent),
  };
}

export default connect(mapStateToProps)(withRouter(UmumEvent));
