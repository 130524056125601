import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import IconError from '../../assets/icon-error';
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { listIuran } from "../../actions/iuran/iuran_action";
import {
    getListIuranPending,
    getListIuran,
    getListIuranError,
} from "../../reducers/iuran/iuran_reducer";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/id";
import { userList } from "../../actions/master-data/user_action";
import { getUserListPending, getUserList, getUserListError } from "../../reducers/master-data/user_reducer";
import ModalApprovalIuran from "./Modal_approval_iuran";
import { getUser } from "../../helpers/user";

class DetailIuran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalApprovalIuranShow: false,
            forRefresh: false
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        let param_listIuran = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listIuran.id = id;
        this.props.listIuran(param_listIuran);
    }

    componentDidMount() {
        let param_listIuran = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listIuran.id = id;
        this.props.listIuran(param_listIuran);
        this.props.userList({ length: 999 });
    }

    shouldComponentRender() {
        const { listIuran_pending } = this.props;
        if (listIuran_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const user = getUser().role_codes[0];
        const { listIuran_data } = this.props;
        let data = [];
        let title, status, messageTitle, message, imgUrl, id, payment_date, paboi_id, member_name, total, status_code = "";
        if (listIuran_data) {
            data = listIuran_data.data[0];
            id = data.id;
            status = data.status;
            status_code = data.status_code;
            paboi_id = data.member_data.member_profile.paboi_id;
            member_name = data.member_data.name;
            total = data.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            payment_date = data.payment_date !== null ? moment(data.payment_date).locale("id", localization).format("DD MMMM YYYY") : "-";
            title = moment(data.billing_date).locale("id", localization).format("DD MMMM YYYY");
            messageTitle = data.status_code === "unpaid" ? "Unpaid" : data.status_code === "paid"? "Paid" : data.status_code === "overdue" ? "Overdue": data.status_code === "late_payment"? "Late Payment" : "Undefined";
            message = status_code === "upnaid" ? "Menunggu pembayaran " : status_code === "paid" ? "Telah dibayar pada " + payment_date : status_code === "overdue" ? "Pembayaran diluar jatuh tempo! Jatuh tempo pada tanggal " + title : "Telah dibayar pada " + payment_date;
        }
        return (
            <ContentContainer>
                <Row style={{ minHeight: "78vh" }}>
                    <ModalApprovalIuran
                        show={this.state.modalApprovalIuranShow}
                        onHide={() => this.setState({ modalApprovalIuranShow: false })}
                        id={id}
                        handleRefresh={this.handleRefresh}
                    />
                    <Row className={(css(styles.leftDetailContainer))}>
                        <Column >
                            <span className={(css(styles.billDate))}>Tanggal tagihan</span>
                            <DetailNameComponent name={title} />
                            <DetailRegistrationStatusComponent
                                icon={IconError}
                                status={status}
                                messageTitle={messageTitle}
                                message={message}
                                imgUrl={imgUrl}
                            />
                        </Column>
                    </Row>
                    <Row className={(css(styles.rightDetailContainer))}>
                        <Column className={(css(styles.rightDetailColumn))}>
                            <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                <DetailTitleComponent title="Informasi Iuran" marginBottom={true} />
                                {user === "root" && status_code !== "paid" && status_code !== "late_payment" &&
                                    <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalApprovalIuranShow: true })}>Konfirmasi Pembayaran</Button>
                                }
                            </Row>
                            <div style={{ marginBottom: 20 }}></div>
                            {status === 0 && status === 2 ?
                                <>
                                    <DetailContentComponent label="Nama Dokter" value={member_name} />
                                    <DetailContentComponent label="ID Member" value={paboi_id} />
                                    <DetailContentComponent label="Total Pembayaran" value={"Rp " + total} />
                                    <DetailContentComponent label="Tanggal Pembayaran" value={payment_date === null ? "-" : payment_date} />
                                </>
                                :
                                <>
                                    <DetailContentComponent label="Nama Dokter" value={member_name} />
                                    <DetailContentComponent label="ID Member" value={paboi_id} />
                                    <DetailContentComponent label="Total Pembayaran" value={"Rp " + total} />
                                    <DetailContentComponent label="Tanggal Pembayaran" value={payment_date === null ? "-" : payment_date} />
                                </>
                            }
                        </Column>
                    </Row>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40
    },
    rightDetailContainer: {
        padding: 40,
        width: "70%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        marginLeft: 10,
        padding: 10
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginLeft: 10,
        color: "#495057",
        boxShadow: "none",
    },
    billDate: {
        color: "#495057",
        opacity: "0.7",
        marginBottom: 10
    }
});

function mapStateToProps({ listIuran, userList }) {
    return {
        listIuran_pending: getListIuranPending(listIuran),
        listIuran_data: getListIuran(listIuran),
        listIuran_error: getListIuranError(listIuran),
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
    };
}


export default connect(mapStateToProps, { listIuran, userList })(withRouter(DetailIuran));
