import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { detailMember, editMember } from "../../actions/member/member_action";

import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MUIRichTextEditor from "mui-rte";

import { ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "pengantar",
      data: "",
      html: "",
      isEdit: false,
    };
    this.tableRef = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
  }

  handleSave(data) {
    const {
      editMember,
      detailMember,
      detailMember_data: [{ member_profile, id, name, email }],
    } = this.props;

    // const user = getUser();
    const result = draftToHtml(JSON.parse(data));
    this.setState({ data: data });
    this.setState({ html: result });
    const param = {
      ...member_profile,
      id: id,
      name: name,
      primary_email: email,
      introduction: result,
    };
    const callback = () => {
      detailMember(id);
      this.handleTriggerFormEdit();
    };
    editMember(param, callback);
  }

  handleClick() {
    this.tableRef.current && this.tableRef.current.save();
  }

  createMarkup(html) {
    return { __html: html };
  }

  handleTriggerFormEdit() {
    // const { universityList, reset } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    // this.setState({ isEditFile: false });
    // reset();
    // universityList({ length: 999, is_adaptation: 0 });
  }

  render() {
    const { detailMember_data, editMember_pending } = this.props;
    const { isEdit } = this.state;

    let introduction = '';
    if (detailMember_data && detailMember_data.length !== 0) {
      introduction = detailMember_data[0].member_profile.introduction !== undefined ? detailMember_data[0].member_profile.introduction : '';
    }

    const blocksFromHtml = htmlToDraft(
      editMember_pending ? "Memuat..." : introduction ? introduction : ""
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = JSON.stringify(
      convertToRaw(ContentState.createFromBlockArray(contentBlocks, entityMap))
    );

    return (
      <>
        <Row
          vertical="center"
          horizontal="space-between"
          style={{ width: "100%", marginBottom: 20 }}
        >
          <DetailNameComponent name="Pengantar" />

          <EditButtonComponent
            isEdit={isEdit}
            triggerIsEdit={this.handleTriggerFormEdit}
            onSave={this.handleClick}
            isLoading={editMember_pending}
          />
        </Row>
        {isEdit ? (
          <MUIRichTextEditor
            onSave={this.handleSave}
            ref={this.tableRef}
            label="Maksimal 250 karakter"
            controls={[
              "title",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "undo",
              "redo",
              "clear",
            ]}
            maxLength={250}
            defaultValue={contentState}
          />
        ) : (
          <div
            className="pengantar"
            dangerouslySetInnerHTML={this.createMarkup(introduction)}
          />
        )}
      </>
    );
  }
}

function mapStateToProps({ detailMember, editMember }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    editMember_data: getDetailMember(detailMember),
    editMember_pending: getMemberPending(editMember),
  };
}

export default connect(mapStateToProps, { detailMember, editMember })(
  withRouter(Profile)
);
