import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
// import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";


import _ from "lodash";
import SelectComponent from "../../components/form/SelectComponent";


class PPDSHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ education: e });
    this.delayedQuery(e);
  }

  render() {
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }


    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
        </div>
        <Row>

          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleSearchChange}
              placeholder="Pilih Tahun"
              options={yearOptions}
              id="status"
            />
          </div>
        </Row>
      </Row>
    );
  }
}


const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  container: {
    width: 150,
    zIndex: 15,
    margin: 10,
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(PPDSHeader);
