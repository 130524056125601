import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import CardMember from "./CardMember";
import { getUser } from "../../helpers/user";

import { listTotalMember } from "../../actions/dashboard/cabang_action";
import {
  getDashboardData
} from "../../reducers/dashboard/cabang_reducer";
class CardMemberCabang extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.listTotalMember({ branch_id: getUser().branch_id });
  }

  render() {
    const { listTotalMember_data } = this.props;
    let member_active = 0,
      member_non_active = 0,
      member_pension = 0,
      total_member = 0,
      member_departed = 0;
    if (listTotalMember_data && listTotalMember_data.length !== 0) {
      [
        {
          member_active,
          member_non_active,
          member_pension,
          total_member,
          member_departed,
        },
      ] = listTotalMember_data;
    }
    return (
      <Row horizontal="space-between" style={{ marginBottom: 20 }}>
        <div style={{ padding: "0 10px", width: "20%" }}>
          <CardMember
            img_url="member-green.png"
            member_total={`${total_member}`}
            card_label="Total Member"
          />
        </div>
        <div style={{ padding: "0 10px", width: "20%" }}>
          <CardMember
            img_url="member-blue.png"
            member_total={`${member_active}`}
            card_label="Member Aktif"
          />
        </div>
        <div style={{ padding: "0 10px", width: "20%" }}>
          <CardMember
            img_url="member-yellow.png"
            member_total={`${member_non_active}`}
            card_label="Member Non-aktif"
          />
        </div>
        <div style={{ padding: "0 10px", width: "20%" }}>
          <CardMember
            img_url="member-red.png"
            member_total={`${member_pension}`}
            card_label="Retired Member"
          />
        </div>
        <div style={{ padding: "0 10px", width: "20%" }}>
          <CardMember
            img_url="image-abu.png"
            member_total={`${member_departed}`}
            card_label="Departed Member"
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps({ listTotalMember }) {
  return {
    listTotalMember_data: getDashboardData(listTotalMember),
  };
}

export default connect(mapStateToProps, {
  listTotalMember,
})(CardMemberCabang);
