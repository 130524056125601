import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import getHeader from "../header";

export const REALM_ACTIVITY_PENDING = 'REALM_ACTIVITY_PENDING'
export const REALM_ACTIVITY_SUCCESS = 'REALM_ACTIVITY_SUCCESS'
export const REALM_ACTIVITY_ERROR = 'REALM_ACTIVITY_ERROR'

const REALM_ACTIVITY_URL = 'master_data/realm_activity/find';

export function realmActivityList(param = {}, resolve, callback) {
    return dispatch => {
        dispatch(actionPending(REALM_ACTIVITY_PENDING));
        API.post(REALM_ACTIVITY_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(REALM_ACTIVITY_SUCCESS, res.data));
                const { records_total } = res.data;
                if (resolve) {
                    resolve({
                        data: res.data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(REALM_ACTIVITY_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}