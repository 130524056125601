import {
  LIST_EXPERIENCE_PENDING,
  LIST_EXPERIENCE_SUCCESS,
  LIST_EXPERIENCE_ERROR,
  DETAIL_EXPERIENCE_PENDING,
  DETAIL_EXPERIENCE_SUCCESS,
  DETAIL_EXPERIENCE_ERROR,
  EDIT_EXPERIENCE_PENDING,
  EDIT_EXPERIENCE_SUCCESS,
  EDIT_EXPERIENCE_ERROR,
  ADD_EXPERIENCE_PENDING,
  ADD_EXPERIENCE_SUCCESS,
  ADD_EXPERIENCE_ERROR,
  APPROVE_EXPERIENCE_PENDING,
  APPROVE_EXPERIENCE_SUCCESS,
  APPROVE_EXPERIENCE_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/member/experience_action";

const initialState = {
  pending: false,
  error: null,
};

export function listExperience(
  state = { ...initialState, list_experience: null },
  action
) {
  switch (action.type) {
    case LIST_EXPERIENCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_EXPERIENCE_SUCCESS:
      return {
        ...state,
        pending: false,
        list_experience: action.data,
      };
    case LIST_EXPERIENCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailExperience(
  state = { ...initialState, detail_experience: null },
  action
) {
  switch (action.type) {
    case DETAIL_EXPERIENCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_experience: action.data,
      };
    case DETAIL_EXPERIENCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editExperience(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_EXPERIENCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_EXPERIENCE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_EXPERIENCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addExperience(state = initialState, action) {
  switch (action.type) {
    case ADD_EXPERIENCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_EXPERIENCE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_EXPERIENCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveExperience(state = initialState, action) {
  switch (action.type) {
    case APPROVE_EXPERIENCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_EXPERIENCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListExperience = (state) => state.list_experience;
export const getDetailExperience = (state) => state.detail_experience;
export const getExperiencePending = (state) => state.pending;
export const getExperienceError = (state) => state.error;
export const getExperienceIsEditFile = (state) => state.isEditFile;
