import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
    detailStrKT,
    editStrKT,
    setIsEditFile,
} from "../../actions/p2kb-kt/str_kt_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import {
    getStrKTPending,
    getDetailStrKT,
    getStrKTError,
    getStrKTIsEditFile,
} from "../../reducers/p2kb-kt/str_kt_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
    renderInput,
    renderDate,
    renderFile,
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import StrKTModalApprove from "./StrKTModalApprove";
import StrKTModalReject from "./StrKTModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import moment from "moment";
import localization from "moment/locale/id";

class StrDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            isEditFile: false,
            showApproveModal: false,
            showRejectModal: false,
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
    }

    componentDidMount() {
        const { detailStrKT } = this.props;
        const {
            match: {
                params: { id },
            },
        } = this.props;
        detailStrKT(id);

        this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
        this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    shouldComponentRender() {
        const { registrationList_pending } = this.props;
        if (registrationList_pending === false) {
            return false;
        }
        return true;
    }

    handleTriggerFormEdit() {
        this.setState({ isEdit: !this.state.isEdit });
        this.setState({ isEditFile: false });
        this.props.reset();
    }

    handleTriggerEditFile() {
        const { editStrKT_isEditFile, setIsEditFile } = this.props;
        if (editStrKT_isEditFile) {
            this.props.change("berkas", null);
        }
        setIsEditFile(!editStrKT_isEditFile);
    }

    handleSubmit(values) {
        const { detailStrKT_data, editStrKT, detailStrKT } = this.props;
        const { berkas, str_kt_no } = values;
        let formData = new FormData();
        formData.append("id", detailStrKT_data[0].id);
        formData.append("str_no", detailStrKT_data[0].str_no);
        formData.append("str_kt_no", str_kt_no);
        if (berkas) {
            formData.append("file", berkas);
        }
        const callback = () => {
            detailStrKT();
            this.handleTriggerFormEdit();
        };
        editStrKT(formData, callback);
    }

    render() {
        const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

        const {
            detailStrKT_data,
            editStr_pending,
            editStrKT_isEditFile,
            handleSubmit,
            formValue
        } = this.props;
        const { isEdit, showApproveModal, showRejectModal } = this.state;
        let name, filename, status, reject_notes, str_role_code, reject_filename;

        if (detailStrKT_data && detailStrKT_data.length !== 0) {
            name = detailStrKT_data[0].user_data.name;
            filename = detailStrKT_data[0].filename;
            status = detailStrKT_data[0].status;
            reject_notes = detailStrKT_data[0].reject_notes;
            reject_filename = detailStrKT_data[0].reject_filename;
            str_role_code = detailStrKT_data[0].role_next_approve
                ? detailStrKT_data[0].role_next_approve.role_code
                : null;
        }

        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <ContentContainer>
                <StrKTModalApprove
                    show={showApproveModal}
                    onHide={() => this.setState({ showApproveModal: false })}
                />
                <StrKTModalReject
                    show={showRejectModal}
                    onHide={() => this.setState({ showRejectModal: false })}
                />
                <Row>
                    <LeftContainerComponent>
                        <DetailNameComponent name={name} />
                        <StatusSquareComponent
                            statusLabel={
                                status === 0
                                    ? "Menunggu verifikasi"
                                    : status === 1
                                        ? "Data STR Disetujui"
                                        : reject_notes
                            }
                            statusTitle={
                                status === 0
                                    ? "Proses"
                                    : status === 1
                                        ? "Disetujui"
                                        : "Ditolak"
                            }
                            status={status}
                            filename={reject_filename}
                            filepath="member_str"
                        />
                        {getUser().role_codes[0] === str_role_code && status === 0 && (
                            <Row style={{ width: "100%" }} horizontal="space-between">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.approveButton)}
                                    onClick={() => this.setState({ showApproveModal: true })}
                                >
                                    Verifikasi
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.rejectButton)}
                                    onClick={() => this.setState({ showRejectModal: true })}
                                >
                                    Tolak
                            </Button>
                            </Row>
                        )}
                    </LeftContainerComponent>
                    <RightContainerComponent>
                        <form
                            onSubmit={handleSubmit(this.handleSubmit)}
                            style={{ width: "100%" }}
                        >
                            <Row
                                vertical="center"
                                horizontal="space-between"
                                style={{ width: "100%", marginBottom: 20 }}
                            >
                                <DetailNameComponent name="Informasi STR KT" />
                                {status === 2 && getUser().role_codes[0] === "member" &&
                                    <EditButtonComponent
                                        isEdit={isEdit}
                                        triggerIsEdit={this.handleTriggerFormEdit}
                                        isLoading={editStr_pending}
                                    />
                                }
                            </Row>
                            <FormContainer label="No STR KT">
                                <Field
                                    isDetail={!isEdit}
                                    name="str_kt_no"
                                    placeholder="Masukkan no STR KT"
                                    component={renderInput}
                                />
                            </FormContainer>
                            {!isEdit ?
                                <DetailContentComponent
                                    label="Tanggal Penetapan"
                                    value={formValue.start_date ? moment(formValue.start_date).locale("id", localization).format("DD MMMM YYYY") : null}
                                />
                                :
                                <FormContainer label="Tanggal Penetapan">
                                    <Field
                                        isDetail={!isEdit}
                                        name="start_date"
                                        placeholder="Masukkan tanggal penetapan"
                                        component={renderDate}
                                    />
                                </FormContainer>
                            }
                            {!isEdit ?
                                <DetailContentComponent
                                    label="Tanggal Berakhir"
                                    value={formValue.end_date ? moment(formValue.end_date).locale("id", localization).format("DD MMMM YYYY") : null}
                                />
                                :
                                <FormContainer label="Tanggal Berakhir">
                                    <Field
                                        isDetail={!isEdit}
                                        name="end_date"
                                        placeholder="Masukkan tanggal berakhir"
                                        component={renderDate}
                                    />
                                </FormContainer>
                            }
                            <FormContainer label="Berkas">
                                <Field
                                    triggerEditFile={this.handleTriggerEditFile}
                                    isEditFile={editStrKT_isEditFile}
                                    isDetail={!isEdit}
                                    filename={filename}
                                    name="berkas"
                                    type="file"
                                    fileType="pdf/image"
                                    filePath="member_str"
                                    title="upload berkas"
                                    message="PNG atau JPG maksimal 500 kb"
                                    component={renderFile}
                                />
                            </FormContainer>
                            <DetailContentComponent
                                label="Status"
                                style={{
                                    color: statusColor[status],
                                    fontWeight: "bold",
                                }}
                                value={
                                    status === 0
                                        ? "Proses"
                                        : status === 1
                                            ? "Disetujui"
                                            : status === 2
                                                ? "Ditolak"
                                                : "-"
                                }
                            />
                        </form>
                    </RightContainerComponent>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        // marginRight: 10,
        boxShadow: "none",
        width: "45%",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        // marginRight: 10,
        color: "#495057",
        boxShadow: "none",
        width: "45%",
    },
});

function validate(values, props) {
    const { editStrKT_isEditFile } = props;
    const { str_kt_no, berkas } = values;
    const errors = {};
    if (!str_kt_no) {
        errors.str_kt_no = "Nomor STR KT wajib diisi";
    }
    if (editStrKT_isEditFile && !berkas) {
        errors.berkas = "Berkas STR KT wajib diisi";
    }

    return errors;
}

StrDetail = reduxForm({
    // a unique name for the form
    form: "StrKTEdit",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    shouldError: () => true,
    enableReinitialize: true,
})(StrDetail);

function mapStateToProps(state) {
    const { detailStrKT, editStrKT } = state;
    const selector = formValueSelector("StrKTEdit")
    const formValue = selector(state, "start_date", "end_date");
    let initialValues = {};
    const detailStrKT_data = getDetailStrKT(detailStrKT);
    if (detailStrKT_data && detailStrKT_data.length !== 0) {
        initialValues = {
            str_kt_no: detailStrKT_data[0].str_kt_no,
            start_date: detailStrKT_data[0].start_date,
            end_date: detailStrKT_data[0].end_date,
        };
    }

    return {
        detailStrKT_data: getDetailStrKT(detailStrKT),
        detailStrKT_error: getStrKTError(detailStrKT),
        detailStrKT_pending: getStrKTPending(detailStrKT),
        editStrKT_error: getStrKTError(editStrKT),
        editStrKT_pending: getStrKTPending(editStrKT),
        editStrKT_isEditFile: getStrKTIsEditFile(editStrKT),
        formValue,
        initialValues,
    };
}

export default connect(mapStateToProps, {
    detailStrKT,
    editStrKT,
    setIsEditFile,
})(withRouter(StrDetail));
