import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_UNIVERSITY_PENDING = 'LIST_UNIVERSITAS_PENDING'
export const LIST_UNIVERSITY_SUCCESS = 'LIST_UNIVERSITAS_SUCCESS'
export const LIST_UNIVERSITY_ERROR = 'LIST_UNIVERSITAS_ERROR'
export const EDIT_UNIVERSITY_PENDING = 'EDIT_UNIVERSITAS_PENDING'
export const EDIT_UNIVERSITY_SUCCESS = 'EDIT_UNIVERSITAS_SUCCESS'
export const EDIT_UNIVERSITY_ERROR = 'EDIT_UNIVERSITAS_ERROR'
export const ADD_UNIVERSITY_PENDING = 'ADD_UNIVERSITAS_PENDING'
export const ADD_UNIVERSITY_SUCCESS = 'ADD_UNIVERSITAS_SUCCESS'
export const ADD_UNIVERSITY_ERROR = 'ADD_UNIVERSITAS_ERROR'
export const DELETE_UNIVERSITY_PENDING = 'DELETE_UNIVERSITAS_PENDING'
export const DELETE_UNIVERSITY_SUCCESS = 'DELETE_UNIVERSITAS_SUCCESS'
export const DELETE_UNIVERSITY_ERROR = 'DELETE_UNIVERSITAS_ERROR'

const LIST_UNIVERSITY_URL = 'master_data/university/find';
const EDIT_UNIVERSITY_URL = 'master_data/university/edit';
const ADD_UNIVERSITY_URL = 'master_data/university/add';
const DELETE_UNIVERSITY_URL = 'master_data/university/delete';

export function universityList(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_UNIVERSITY_PENDING));
        API.post(LIST_UNIVERSITY_URL, param)
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_UNIVERSITY_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    ...item, no: i + 1 + (param.page - 1) * 10
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_UNIVERSITY_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}

export function editUniversity(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_UNIVERSITY_PENDING));
        API.post(EDIT_UNIVERSITY_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_UNIVERSITY_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah universitas");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_UNIVERSITY_ERROR, error));
                toastError(error, "Gagal mengubah universitas, ");
            });
    };
}

export function addUniversity(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_UNIVERSITY_PENDING));
        API.post(ADD_UNIVERSITY_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_UNIVERSITY_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambah universitas");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_UNIVERSITY_ERROR, error));
                toastError(error, "Gagal menambah universitas, ");
            });
    };
}

export function deleteUniversity(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_UNIVERSITY_PENDING));
        API.post(DELETE_UNIVERSITY_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_UNIVERSITY_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus universitas");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_UNIVERSITY_ERROR, error));
                toastError(error, "Gagal menghapus universitas, ");
            });
    };
}