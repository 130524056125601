import React, { Component } from "react";
import {
  renderSelect,
  renderTimePicker,
} from "../../components/form/FormComponent";
import { Field } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";
import {
  getListHospital,
  getHospitalPending,
  getHospitalError,
} from "../../reducers/master-data/hospital_reducer";

// import { useSelector } from "react-redux";

class RenderArray extends Component {
  render() {
    const {
      fields,
      meta: { submitFailed, error },
      small,
      isDetail,
    } = this.props;

    let dayOption = [
      {
        label: "Senin",
        value: "1",
      },
      {
        label: "Selasa",
        value: "2",
      },
      {
        label: "Rabu",
        value: "3",
      },
      {
        label: "Kamis",
        value: "4",
      },
      {
        label: "Jumat",
        value: "5",
      },
      {
        label: "Sabtu",
        value: "6",
      },
      {
        label: "Minggu",
        value: "7",
      },
    ];

    return (
      <>
        {submitFailed && error && (
          <Row
            style={{
              height: 40,
              color: "#ED2A2A",
              //   border: "1px solid #F9D7D7",
              borderRadius: 3,
              marginBottom: 20,
              backgroundColor: "#FFDEDE",
              //   opacity: 0.5,
            }}
            horizontal="center"
            vertical="center"
            className="error"
          >
            <span>
              <ErrorIcon style={{ marginRight: 10 }} />
              {error}
            </span>
          </Row>
        )}
        {fields.map((hospital, index) => (
          <Row horizontal="space-between" key={index}>
            <div
              className={css(
                styles.Selectcontainer,
                small && styles.SelectcontainerSmall
              )}
            >
              <Field
                isDetail={isDetail}
                name={`${hospital}.day`}
                type="text"
                placeholder="Pilih Hari"
                options={dayOption}
                component={renderSelect}
              />
            </div>
            <div
              className={css(
                styles.Selectcontainer,
                small && styles.SelectcontainerSmall
              )}
            >
              <Field
                isDetail={isDetail}
                name={`${hospital}.time_start`}
                defaultValue="07:00"
                placeholder="Masukkan Jam Mulai Praktik"
                component={renderTimePicker}
              />
            </div>
            <div
              className={css(
                styles.Selectcontainer,
                small && styles.SelectcontainerSmall
              )}
            >
              <Field
                isDetail={isDetail}
                name={`${hospital}.time_end`}
                defaultValue="18:00"
                placeholder="Masukkan Jam Akhir Praktik"
                component={renderTimePicker}
              />
            </div>

            {!isDetail && (
              <CancelIcon
                className={css(styles.closeButton)}
                onClick={() => fields.remove(index)}
              />
            )}
          </Row>
        ))}
        {!isDetail && (
          <Column
            horizontal="center"
            vertical="center"
            className={css(styles.buttonContainer)}
          >
            <div
              style={{
                width: "100%",
                borderBottom: "2px solid #EDEDEE",
                top: 18,
                position: "relative",
              }}
            />
            <div
              style={{
                padding: "0px 10px",
                backgroundColor: "white",
                zIndex: 0,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                // onClick={this.handleSubmit}
                startIcon={<AddIcon />}
                onClick={() => fields.push()}
              >
                Tambah Jadwal
              </Button>
            </div>
          </Column>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  closeButton: {
    color: "#CDCFD1",
    marginTop: 7,
    ":hover": {
      color: "#ED2A2A",
    },
  },
  Selectcontainer: { width: "210px" },
  SelectcontainerSmall: { width: "120px" },
  approveButton: {
    zIndex: 0,
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    paddingBottom: "10px",
  },
});

function mapStateToProps({ listHospital }) {
  return {
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listHospital_error: getHospitalError(listHospital),
  };
}

export default connect(mapStateToProps)(RenderArray);
