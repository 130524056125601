import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import { detailMember } from "../../actions/member/member_action";
import { listCity } from "../../actions/master-data/city_action";
import { getUser } from "../../helpers/user";
import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProfileAvatar from "./ProfileAvatar";
import ProfilePPDS from "./ProfilePPDS";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  listSubspesialis
} from "../../actions/master-data/subspesialis_action";
import {
  getSubspesialisPending,
  getListSubspesialis,
  getSubspesialisError,
} from "../../reducers/master-data/subspesialis_reducer";
import { universityList } from "../../actions/master-data/university_action";
import {
  getUniversityListPending,
  getUniversityList,
} from "../../reducers/master-data/university_reducer";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum", data: null };
    // this.ref = React.createRef(TMUIRichTextEditorRef, null);
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    const { detailMember, listCity, universityList } = this.props;
    const id = getUser().id;
    detailMember(id);
    listCity({ length: 999999 });
    universityList({ length: 999999 });
  }
  setSelectedItem(item) {
    this.setState({ selectedItem: item });
    this.props.history.push(`/manajemen-ppds/member-profile/${item}`);
  }

  render() {
    const { detailMember_data } = this.props;
    let profile_img = '';
    if (detailMember_data) {
      profile_img = detailMember_data[0].profile_img
    }
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <ProfileAvatar img={profile_img} />
          </LeftContainerComponent>
          <RightContainerComponent>
            <ProfilePPDS></ProfilePPDS>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function mapStateToProps({ detailMember, listSubspesialis, universityList }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    listSubspesialis_data: getListSubspesialis(listSubspesialis),
    listSubspesialis_error: getSubspesialisError(listSubspesialis),
    listSubspesialis_pending: getSubspesialisPending(listSubspesialis),
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
  };
}

export default connect(mapStateToProps, {
  detailMember,
  listCity,
  listBranch,
  listSubspesialis,
  universityList,
})(withRouter(Profile));
