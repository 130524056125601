import {
    LIST_NOTIFICATION_PENDING,
    LIST_NOTIFICATION_SUCCESS,
    LIST_NOTIFICATION_ERROR,
    READ_NOTIFICATION_PENDING,
    READ_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_ERROR,
} from "../../actions/notification/notification_action";

const initialState = {
    pending: false,
    error: null
};

export function notificationList(
    state = { ...initialState, notificationList: null },
    action
) {
    switch (action.type) {
        case LIST_NOTIFICATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_NOTIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                notificationList: action.data,
            };
        case LIST_NOTIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function readNotification(
    state = { ...initialState, readNotification: null },
    action
) {
    switch (action.type) {
        case READ_NOTIFICATION_PENDING:
            return {
                ...state,
                pending: true,
            };
        case READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                readNotification: action.data,
            };
        case READ_NOTIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getNotificationListPending = (state) => state.pending;
export const getNotificationList = (state) => state.notificationList;
export const getNotificationListError = (state) => state.error;
export const getNotificationReadPending = (state) => state.pending;
export const getNotificationRead = (state) => state.readNotification;
export const getNotificationReadError = (state) => state.error;