import React from "react";
import SelectComponent from "./SelectComponent";
import InputComponent from "./InputComponent";
import LabelInputVerticalComponent from "../global-components/LabelInputVerticalComponent";
import FileComponent from "./FileComponent";
import SKPFileComponent from "./SKPFileComponent";
import FileIconComponent from "./FileIconComponent";
import { StyleSheet, css } from "aphrodite";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Row } from "simple-flexbox";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import ReactTooltip from "react-tooltip";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { default_api } from "../../constants/default_api";


export function renderInput(field) {
  field.input.value = typeof field.input.value === 'object' && field.input.name.includes('custom') ? field.input.value.section_name : field.input.value

  return (
    <>
      {field.isDetail ? (
        field.input.value
      ) : (
          <div className={css(styles.formContainer)}>
            <InputComponent
              {...field.input}
              type={field.type}
              disabled={field.disabled}
              placeholder={field.placeholder}
              startIcon={field.startIcon}
            />
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}
export function renderInputAktivasi(field) {
  field.input.value = typeof field.input.value === 'object' && field.input.name.includes('custom') ? field.input.value.section_name : field.input.value

  return (
    <>
      {field.isDetail ? (
        field.input.value
      ) : (
          <div className={css(styles.formContainer)}>
            <InputComponent
              {...field.input}
              type={field.type}
              disabled={field.disabled}
              placeholder={field.placeholder}
              startIcon={field.startIcon}
              maxLength ={200}
            />
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}
export function renderInputSosmed(field) {
  const StartIcon = field.startIcon;
  const random = Math.random().toString();

  return (
    <>
      {field.isDetail ? (
        <>
          <Row
            vertical="center"
            style={{
              position: "relative",
              width: "100%",
              height: 30,
              // backgroundColor: "red",
            }}
            data-tip
            data-for={random}
          >
            <StartIcon size="2x" />
          </Row>
          <ReactTooltip id={random} type="dark" place="bottom" effect="solid">
            {field.input.value ? field.input.value : "-"}
          </ReactTooltip>
        </>
      ) : (
          <div className={css(styles.formContainer)}>
            <InputComponent
              {...field.input}
              type={field.type}
              disabled={field.disabled}
              placeholder={field.placeholder}
              startIcon={field.startIcon}
            />
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}

export function renderInputWithLabel(field) {
  return (
    <div className={css(styles.formContainer)}>
      <LabelInputVerticalComponent label={field.label}>
        <InputComponent
          {...field.input}
          type={field.type}
          disabled={field.disable}
          placeholder={field.placeholder}
          isTextarea={field.isTextarea}
          onChange={field.input.onChange}
        />
      </LabelInputVerticalComponent>
      <div className={css(styles.errorContainer)}>
        {field.meta.touched ? field.meta.error : " "}
      </div>
    </div>
  );
}

export function renderFile(field) {
  const {
    filename,
    input: { onChange, value },
    meta: { touched, error },
    fileType,
    message,
    title,
    isDetail,
    isEditFile,
    filePath,
    isSuratRekomen,
    triggerEditFile,
    buttonName,
  } = field;

  let result = null;
  if (isDetail) {
    if (filename) {
      const link = isSuratRekomen ? `${default_api}/member/download/${filePath}/${filename}.pdf` : `${default_api}/image/show/${filePath}/${filename}`
      result = (
        <Row>
          <a
            href={link}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <Row
              horizontal="center"
              vertical="center"
              className={css(styles.attachment)}
            >
              <AttachFileIcon fontSize="small" />
              {buttonName ? buttonName : 'berkas'}
            </Row>
          </a>
        </Row>
      );
    } else {
      result = (
        <Row
          style={{ width: 200 }}
          horizontal="center"
          vertical="center"
          className={css(styles.attachment)}
        >
          <AttachFileIcon fontSize="small" />
          Tidak ada berkas
        </Row>
      );
    }
  } else {
    if (filename) {
      if (isEditFile) {
        result = (
          <Row vertical="center" horizontal="space-between">
            <div style={{ width: "90%" }}>
              <div className={css(styles.formContainer)}>
                <FileComponent
                  type="file"
                  fileType={fileType}
                  onChange={(e) => onChange(e.target.files[0])}
                  value={value}
                  message={message}
                  title={title}
                />

                <div className={css(styles.errorContainer)}>
                  {touched ? error : " "}
                </div>
              </div>
            </div>
            <CancelIcon
              onClick={triggerEditFile}
              style={{ color: "#495057", cursor: "pointer" }}
            />
          </Row>
        );
      } else {
        result = (
          <Row>
            <a
              href={`${default_api}/image/show/${filePath}/${filename}`}
              style={{ textDecoration: "none" }}
              taget="_blank"
            >
              <Row
                horizontal="center"
                vertical="center"
                className={css(styles.attachment)}
              >
                <AttachFileIcon fontSize="small" />
                berkas
              </Row>
            </a>
            <EditIcon
              onClick={triggerEditFile}
              style={{ color: "#495057", cursor: "pointer", marginLeft: 10 }}
            />
          </Row>
        );
      }
    } else {
      result = (
        <>
          <div className={css(styles.formContainer)}>
            <FileComponent
              type="file"
              fileType={fileType}
              onChange={(e) => onChange(e.target.files[0])}
              value={value}
              message={message}
              title={title}
            />

            <div className={css(styles.errorContainer)}>
              {touched ? error : " "}
            </div>
          </div>
        </>
      );
    }
  }
  return result;
}

export function renderFileForm(field) {
  const {
    filename,
    input: { onChange, value, name },
    meta: { touched, error },
    fileType,
    message,
    title,
    disabled,
    isDone,
    isDetail,
    isEditFile,
    filePath,
    triggerEditFile,
  } = field;

  let result = null;
  // let value = field.input.value
  // if (field.input.value) {
  //   value = field.input.value[0]
  // }
  if (isDetail) {
    if (filename) {
      result = (
        <Row>
          <a
            href={`${default_api}/image/show/${filePath}/${filename}`}
            style={{ textDecoration: "none" }}
            taget="_blank"
          >
            <Row
              horizontal="center"
              vertical="center"
              className={css(styles.attachment)}
            >
              <AttachFileIcon fontSize="small" />
              berkas
            </Row>
          </a>
        </Row>
      );
    } else {
      result = (
        <Row
          style={{ width: 200 }}
          horizontal="center"
          vertical="center"
          className={css(styles.attachment)}
        >
          <AttachFileIcon fontSize="small" />
          Tidak ada berkas
        </Row>
      );
    }
  } else {
    if (filename) {
      if (isEditFile) {
        result = (
          <Row vertical="center" horizontal="space-between">
            <div style={{ width: "90%" }}>
              <div className={css(styles.formContainer)}>
                <SKPFileComponent
                  type="file"
                  fileType={fileType}
                  onChange={(e) => onChange(0)}
                  value={value}
                  message={message}
                  title={title}
                  name={name}
                />

                <div className={css(styles.errorContainer)}>
                  {touched ? error : " "}
                </div>
              </div>
            </div>
            <CancelIcon
              onClick={triggerEditFile}
              style={{ color: "#495057", cursor: "pointer" }}
            />
          </Row>
        );
      } else {
        result = (
          <Row>
            <a
              href={`${default_api}/image/show/${filePath}/${filename}`}
              style={{ textDecoration: "none" }}
              taget="_blank"
            >
              <Row
                horizontal="center"
                vertical="center"
                className={css(styles.attachment)}
              >
                <AttachFileIcon fontSize="small" />
                berkas
              </Row>
            </a>
            <EditIcon
              onClick={triggerEditFile}
              style={{ color: "#495057", cursor: "pointer", marginLeft: 10 }}
            />
          </Row>
        );
      }
    } else {
      result = (
        <>
          <div className={css(styles.formContainer)}>
            <SKPFileComponent
              type="file"
              fileType={fileType}
              onChange={(e) => onChange(e)}
              isDone={isDone}
              disabled={disabled}
              value={value}
              message={message}
              title={title}
              name={name}
            />

            <div className={css(styles.errorContainer)}>
              {touched ? error : " "}
            </div>
          </div>
        </>
      );
    }
  }
  return result;
}

export function renderFile2({
  filename,
  filePath,
  input: { onChange, value },
  meta: { touched, error },
  fileType,
  message,
  title,
  isDetail,
}) {
  return (
    <>
      {isDetail ? (
        <a
          href={`${default_api}/image/show/${filePath}/${filename}`}
          style={{ textDecoration: "none" }}
          taget="_blank"
        >
          <Row
            horizontal="center"
            vertical="center"
            className={css(styles.attachment)}
          >
            <AttachFileIcon fontSize="small" />
            berkas
          </Row>
        </a>
      ) : (
          <div className={css(styles.formContainer)}>
            <FileComponent
              type="file"
              fileType={fileType}
              onChange={(e) => onChange(e.target.files[0])}
              value={value}
              message={message}
              title={title}
            />

            <div className={css(styles.errorContainer)}>
              {touched ? error : " "}
            </div>
          </div>
        )}
    </>
  );
}

export function renderFilePractice({
  filename,
  input: { onChange, value },
  meta: { touched, error },
  fileType,
  message,
  title,
  isDetail,
}) {
  return (
    <>
      {isDetail ? (
        <a
          href={`${default_api}/image/show/member_practice_place/${filename}`}
          style={{ textDecoration: "none" }}
          taget="_blank"
        >
          <Row
            horizontal="center"
            vertical="center"
            className={css(styles.attachment)}
          >
            <AttachFileIcon fontSize="small" />
            berkas
          </Row>
        </a>
      ) : (
          <div className={css(styles.formContainer)}>
            <FileComponent
              type="file"
              fileType={fileType}
              onChange={(e) => onChange(e.target.files[0])}
              value={value}
              message={message}
              title={title}
            />

            <div className={css(styles.errorContainer)}>
              {touched ? error : " "}
            </div>
          </div>
        )}
    </>
  );
}

export function renderSelect(field) {
  const handleBlur = () => {
    setTimeout(() => {
      const { input } = field;
      input.onBlur(input.value);
    }, 1);
  };
  return (
    <>
      {field.isDetail ? (
        field.input.value.label
      ) : (
          <div className={css(styles.formContainer)}>
            <SelectComponent
              {...field.input}
              onChange={(value) => field.input.onChange(value)}
              placeholder={field.placeholder}
              onBlur={handleBlur}
              options={field.options}
              isSearchable={true}
              isClearable={field.isClearable}
              isMulti={field.isMulti}
              isDisabled={field.isDisabled}
              isAsync={field.isAsync}
              asyncUrl={field.asyncUrl}
            />
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : " "}
            </div>
          </div>
        )}
    </>
  );
}

export function renderSelectWithLabel(field) {
  const handleBlur = () => {
    setTimeout(() => {
      const { input } = field;
      input.onBlur(input.value);
    }, 1);
  };
  return (
    <div className={css(styles.formContainer)}>
      <LabelInputVerticalComponent label={field.label}>
        <SelectComponent
          {...field.input}
          onChange={(value) => field.input.onChange(value)}
          onBlur={handleBlur}
          options={field.options}
          isSearchable={true}
          isClearable={field.isClearable}
          isMulti={field.isMulti}
          isDisabled={field.isDisabled}
          placeholder={field.placeholder}
          isAsync={field.isAsync}
          asyncUrl={field.asyncUrl}
        />
      </LabelInputVerticalComponent>
      <div className={css(styles.errorContainer)}>
        {field.meta.touched ? field.meta.error : " "}
      </div>
    </div>
  );
}

export function renderDatepicker(field) {
  let value = field.input.value;
  if (value) {
    if (
      typeof value == "string" &&
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(
        value
      )
    ) {
      value = moment(field.input.value, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
  }
  return (
    <>
      {field.isDetail ? (
        field.input.value
      ) : (
          <div className={css(styles.formContainer)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                {...field.input}
                // margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                disabled={field.disabled}
                value={value ? value : new Date()}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}

export function renderDatepickerWithLabel(field) {
  return (
    <>
      {field.isDetail ? (
        field.input.value
      ) : (
          <div
            className={css(styles.formContainer)}
            style={{ display: "contents" }}
          >
            <span>{field.label}</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                {...field.input}
                margin="normal"
                id="date-picker-dialog"
                maxDate={field.maxDate}
                disabled={field.disabled}
                format="dd/MM/yyyy"
                value={field.input.value ? field.input.value : new Date()}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}

export function renderDatepickerWithLabel2(field) {
  let value = moment(field.input.value ? field.input.value : new Date()).format(
    "DD/MM/YYYY"
  );
  const today = new Date();
  const renderInput = (props) => (
    <LabelInputVerticalComponent label={field.label}>
      <InputComponent
        type="text"
        // onChange={field.onChange}
        readOnly
        onClick={props.onClick}
        id={props.id}
        value={props.value}
        disabled={props.disabled}
        {...props.inputProps}
        endIcon={InsertInvitationIcon}
        className={styles.dateInput}
      />
    </LabelInputVerticalComponent>
  );

  return (
    <>
      {field.isDetail ? (
        <div className={css(styles.formContainer)}>
          <LabelInputVerticalComponent label={field.label}>
            <InputComponent
              {...field.input}
              disabled
              value={value}
              placeholder={field.placeholder}
              endIcon={InsertInvitationIcon}
            />
          </LabelInputVerticalComponent>
        </div>
      ) : (
          <div
            className={css(styles.formContainer)}
            style={{ display: "contents" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disabled={field.disabled}
                {...field.input}
                // defaultValue={new Date()}
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={value ? value : moment(today).format("DD/MM/YYYY")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                TextFieldComponent={renderInput}
              />
            </MuiPickersUtilsProvider>
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : ""}
            </div>
          </div>
        )}
    </>
  );
}

export function renderFileSkp(field) {
  const {
    input: { onChange, value, name },
    meta: { touched, error },
    fileType,
    message,
    title
  } = field;

  let result = (
    <>
      <div className={css(styles.formContainer)}>
        <FileIconComponent
          type="file"
          fileType={fileType}
          onChange={(e) => onChange(e)}
          value={value}
          message={message}
          title={title}
          name={name}
        />

        <div className={css(styles.errorContainer)}>
          {touched ? error : " "}
        </div>
      </div>
    </>
  );
  return result;
}
export function renderDate(field) {
  const handleBlur = () => {
    setTimeout(() => {
      const { input } = field;
      input.onBlur(input.value);
    }, 1);
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        {field.isDetail ? (
          formatDate(field.input.value)
        ) : (
            <div className={css(styles.formContainer)}>
              <KeyboardDatePicker
                // placeholder="2002/01/12"
                {...field.input}
                value={field.input.value ? field.input.value : new Date()}
                onChange={(value) => field.input.onChange(value)}
                format="dd-MM-yyyy"
                onBlur={handleBlur}
              />
              <div className={css(styles.errorContainer)}>
                {field.meta.touched ? field.meta.error : " "}
              </div>
            </div>
          )}
      </>
    </MuiPickersUtilsProvider>
  );
}

export function renderTimePicker(field) {
  // const handleBlur = () => {
  //   setTimeout(() => {
  //     const { input } = field;
  //     input.onBlur(input.value);
  //   }, 1);
  // };
  return (
    <>
      {field.isDetail ? (
        field.input.value
      ) : (
          <div className={css(styles.timePickerContainer)}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                label=""
                {...field.input}
                value={
                  field.input.value
                    ? new Date().setHours(
                      field.input.value.split(":")[0],
                      field.input.value.split(":")[1]
                    )
                    : new Date().setHours("00", "00")
                }
                onChange={(value) =>
                  field.input.onChange(
                    `${("0" + value.getHours()).slice(-2)}:${(
                      "0" + value.getMinutes()
                    ).slice(-2)}`
                  )
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                placeholder="testing"
              />
            </MuiPickersUtilsProvider>
            <div className={css(styles.errorContainer)}>
              {field.meta.touched ? field.meta.error : " "}
            </div>
          </div>
        )}
    </>
  );
}

const styles = StyleSheet.create({
  timePickerContainer: {
    marginBottom: 10,
    marginTop: -10,
  },
  attachment: {
    width: 100,
    height: 30,
    border: "1px solid #2CB96A",
    borderRadius: 3,
    color: "#2CB96A",
  },
  errorContainer: { color: "#ED2A2A", marginTop: 5 },
  formContainer: { margin: "5px 0" },
  dateInput: {
    ":hover": {
      cursor: "pointer",
    },
    color: "transparent",
    textShadow: "0 0 0 #2CB96A",
  },
});
