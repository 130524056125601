import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from "@material-ui/icons/Clear";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import IconError from '../../assets/icon-error';
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { listBenefit } from "../../actions/benefit/benefit_action";
import { getListBenefitPending, getListBenefit, getListBenefitError } from "../../reducers/benefit/benefit_reducer";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/id";
import { userList } from "../../actions/master-data/user_action";
import { getUserListPending, getUserList, getUserListError } from "../../reducers/master-data/user_reducer";
import { getUser } from "../../helpers/user";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocument from "./Modal_document";
import ModalDocumentPDF from "./Modal_document_pdf";
import ModalApproveBenefit from "./Modal_approve_benefit";
import ModalRejectBenefit from "./Modal_reject_benefit";

class DetailBenefit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forRefresh: false,
            modalDetailDokumenPDFShow: false,
            modalDetailDokumenShow: false,
            modalApproveBenefitShow: false,
            modalRejectBenefitShow: false,
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        let param_listBenefit = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listBenefit.id = id;
        this.props.listBenefit(param_listBenefit);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenPDFShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    componentDidMount() {
        let param_listBenefit = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listBenefit.id = id;
        this.props.listBenefit(param_listBenefit);
    }

    shouldComponentRender() {
        const { listBenefit_pending } = this.props;
        if (listBenefit_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const user = getUser().role_codes[0];
        const { listBenefit_data } = this.props;
        let data = [];
        let status, messageTitle, id, message, imgUrl, member_name, redeem_date, benefit_name, redeem_total, filename, alasan, paboi_id, step, role_next_approve, isRoleMatch = "";
        if (listBenefit_data) {
            data = listBenefit_data.data[0];
            id = data.id;
            status = data.status;
            member_name = data.member_data.name;
            paboi_id = data.member_data.member_profile.paboi_id;
            redeem_date = data.redeem_date !== null ? moment(data.redeem_date).locale("id", localization).format("DD MMMM YYYY") : "-";
            benefit_name = data.benefit_data.name;
            redeem_total = data.total_redeem === 0 ? "-" : "Rp " + data.total_redeem.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            filename = data.filename;
            alasan = data.reject_notes;
            step = data.step;
            role_next_approve = data.role_next_approve !== null ? data.role_next_approve.role_name : "Ketua Koperasi";
            messageTitle = status === 0 ? "Proses" : status === 1 ? "Disetujui" : "Ditolak";
            message = status === 0 && step === 1 ? "Menunggu verifikasi dari " + role_next_approve : status === 0 && step !== 1 ? "Menunggu approval dari " + role_next_approve : status === 1 ? "Pengajuan benefit diterima pada " + redeem_date + " oleh " + role_next_approve : "Pengajuan benefit ditolak pada " + redeem_date + " oleh " + role_next_approve;
            isRoleMatch = data.role_next_approve !== null ? user === data.role_next_approve.role_code : false;
        }
        return (
            <ContentContainer>
                <Row style={{ minHeight: "78vh" }}>
                    <ModalDocumentPDF
                        show={this.state.modalDetailDokumenPDFShow}
                        onHide={() => this.setState({ modalDetailDokumenPDFShow: false })}
                        imgUrl={this.state.imgUrl}
                        modal_title={this.state.modal_title}
                    />
                    <ModalDocument
                        show={this.state.modalDetailDokumenShow}
                        onHide={() => this.setState({ modalDetailDokumenShow: false })}
                        imgUrl={this.state.imgUrl}
                        modal_title={this.state.modal_title}
                    />
                    <ModalApproveBenefit
                        show={this.state.modalApproveBenefitShow}
                        onHide={() => this.setState({ modalApproveBenefitShow: false })}
                        handleRefresh={this.handleRefresh}
                        id={id}
                    />
                    <ModalRejectBenefit
                        show={this.state.modalRejectBenefitShow}
                        onHide={() => this.setState({ modalRejectBenefitShow: false })}
                        handleRefresh={this.handleRefresh}
                        id={id}
                    />
                    <Row className={(css(styles.leftDetailContainer))}>
                        <Column >
                            <DetailNameComponent name={member_name} />
                            <DetailRegistrationStatusComponent
                                icon={IconError}
                                status={status}
                                messageTitle={messageTitle}
                                message={message}
                                imgUrl={imgUrl}
                                reason={alasan}
                                isWithoutImage={true}
                            />
                        </Column>
                    </Row>
                    <Row className={(css(styles.rightDetailContainer))}>
                        <Column className={(css(styles.rightDetailColumn))}>
                            <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                <DetailTitleComponent title="Informasi Benefit" marginBottom={true} />
                                {isRoleMatch && status === 0 &&
                                    <Row>
                                        <Button variant="contained" startIcon={<ClearIcon />} className={css(styles.rejectButton)} onClick={() => this.setState({ modalRejectBenefitShow: true })}>Tolak</Button>
                                        <Button variant="contained" color="primary" startIcon={<CheckIcon />} className={css(styles.approveButton)} onClick={() => this.setState({ modalApproveBenefitShow: true })}>{step === 1 ? "Verifikasi" : "Setuju"}</Button>
                                    </Row>
                                }
                            </Row>
                            <div style={{ marginBottom: 20 }}></div>
                            {user !== "member" ?
                                <>
                                    <DetailContentComponent label="Tanggal Redeem" value={redeem_date === null ? "-" : redeem_date} />
                                    <DetailContentComponent label="Nama Dokter" value={member_name} />
                                    <DetailContentComponent label="ID Member" value={paboi_id} />
                                    <DetailContentComponent label="Nama Benefit" value={benefit_name} />
                                    <DetailContentComponent label="Total Redeem" value={redeem_total} />
                                    <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Benefit")} />} />
                                </>
                                :
                                <>
                                    <DetailContentComponent label="Tanggal Redeem" value={redeem_date === null ? "-" : redeem_date} />
                                    <DetailContentComponent label="Nama Benefit" value={benefit_name} />
                                    <DetailContentComponent label="Total Redeem" value={redeem_total} />
                                    <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Benefit")} />} />
                                </>
                            }
                        </Column>
                    </Row>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40
    },
    rightDetailContainer: {
        padding: 40,
        width: "70%"
    },
    rightDetailColumn: {
        width: "100%"
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        marginLeft: 10,
        padding: 10
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginLeft: 10,
        color: "#495057",
        boxShadow: "none",
    },
    billDate: {
        color: "#495057",
        opacity: "0.7",
        marginBottom: 10
    }
});

function mapStateToProps({ listBenefit, userList }) {
    return {
        listBenefit_pending: getListBenefitPending(listBenefit),
        listBenefit_data: getListBenefit(listBenefit),
        listBenefit_error: getListBenefitError(listBenefit),
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
    };
}


export default connect(mapStateToProps, { listBenefit, userList })(withRouter(DetailBenefit));
