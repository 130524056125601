import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { memberForgotPassword } from "../../actions/guests/member_forgot_password_action";
import { getMemberForgotPassword, getMemberForgotPasswordPending, getMemberForgotPasswordError } from "../../reducers/guests/member_forgot_password_reducer"
import Button from "@material-ui/core/Button";
import { reduxForm, Field } from "redux-form";
import { renderInput } from "../../components/form/FormComponent";
import IconInput from "../../assets/icon-input";
import Grid from '@material-ui/core/Grid';
import ModalFailed from "../../components/auth/ModalFailed";
import ModalSuccess from "../../components/auth/ModalSuccess";

class forgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFailedShow: false,
            modalSuccessShow: false,
            email: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(values) {
        const { memberForgotPassword, memberForgotPassword_data, memberForgotPassword_error } = this.props;
        const param_forgotPassword = {
            email: values.emailID
        };
        this.setState({email: values.emailID})
        memberForgotPassword(param_forgotPassword);
    }

    componentDidUpdate(prevProps, prevState){
        const {memberForgotPassword_data, memberForgotPassword_error} = this.props;
        console.log([prevProps])
        if (prevProps.memberForgotPassword_data !== memberForgotPassword_data){
           
           if (prevState.modalSuccessShow === false){
               this.setState({modalSuccessShow: true})
           }
        }else if (prevProps.memberForgotPassword_error !== memberForgotPassword_error){
            if (prevState.modalFailedShow === false){
                this.setState({modalFailedShow: true})
            }
        }
    }
    render() {
        const { handleSubmit, memberForgotPassword_data, memberForgotPassword_error } = this.props;
      
        return (
            <Grid container>
                <Grid item xs={false} sm={false} md={4} lg={4} className={css(styles.sideContainer)}></Grid>
                <Grid item xs={10} sm={10} md={7} lg={7} className={css(styles.forgotPasswordContainer)}>
                    <Column>
                        <Row>
                            <img src={require('../../assets/ic_paboi.png')} className={css(styles.logo)} alt="logo-paboi" />
                        </Row>
                        <span className={css(styles.forgotPasswordTitle)}>Lupa Password</span>
                        <span className={css(styles.forgotPasswordDesc)}>Masukkan email atau ID PABOI</span>
                        <hr className={css(styles.forgotPasswordSeparator)} />
                        <form
                            onSubmit={handleSubmit(this.handleSubmit)}
                            className={css(styles.forgotPasswordForm)}
                        >
                            <Field
                                name="emailID"
                                placeholder="Email atau ID PABOI"
                                component={renderInput}
                            />
                            <Row style={{ justifyContent: "space-between" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.approveButton)}
                                >
                                    <IconInput /> Submit
                                </Button>
                                <Column>
                                    <span className={css(styles.daftarLabel)}>Sudah punya akun?</span>
                                    <span onClick={() => this.props.history.push('/login')} className={css(styles.inputLinkDaftar)}>Login</span>
                                </Column>
                            </Row>
                        </form>
                    </Column>
                </Grid>
                <ModalFailed show={this.state.modalFailedShow} onHide={()=>this.setState({modalFailedShow: false})}></ModalFailed>
                <ModalSuccess show={this.state.modalSuccessShow} onHide={()=>this.setState({modalSuccessShow: false})} email={this.state.email}></ModalSuccess>
            </Grid >
        )
    }
}

const styles = StyleSheet.create({
    sideContainer: {
        height: "100vh",
        background: "transparent linear-gradient(180deg, #2CB96A 0%, #43C767 66%, #63DB62 100%) 0% 0% no-repeat padding-box"
    },
    forgotPasswordContainer: {
        margin: "auto",
    },
    forgotPasswordTitle: {
        color: "#04AA59",
        fontSize: 36,
        fontWeight: "bold",
        margin: 15
    },
    forgotPasswordDesc: {
        fontSize: 16,
        margin: "0 15px"
    },
    forgotPasswordSeparator: {
        width: 400,
        margin: "20px 15px",
        borderTop: "2px solid rgba(0,0,0,0.1)",
        '@media (max-width: 480px)': {
            width: "100%"
        }
    },
    forgotPasswordForm: {
        width: 400,
        marginLeft: 15,
        '@media (max-width: 480px)': {
            width: "100%"
        }

    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        margin: "15px 0"
    },
    inputMargin: {
        margin: "15px 0"
    },
    inputLinkDaftar: {
        fontSize: 16,
        color: "black",
        textDecoration: "none",
        marginTop: 5,
        textAlign: "right",
        ":hover": {
            cursor: "pointer"
        }
    },
    daftarLabel: {
        marginTop: 15,
        textAlign: "right"
    }
})

function validate(values) {
    const { emailID } = values;
    const errors = {};
    if (!emailID) {
        errors.emailID = "Email atau ID PABOI tidak boleh kosong"
    }
    return errors;
}

forgotPassword = reduxForm({
    // a unique name for the form
    form: "forgotPassword",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    shouldError: () => true,
})(forgotPassword);

function mapStateToProps({ memberForgotPassword }) {
    return {
        memberForgotPassword_pending: getMemberForgotPasswordPending(memberForgotPassword),
        memberForgotPassword_data: getMemberForgotPassword(memberForgotPassword),
        memberForgotPassword_error: getMemberForgotPasswordError(memberForgotPassword)
    }
}

export default connect(mapStateToProps, { memberForgotPassword })(forgotPassword);