import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_FINAL_PAPER_PENDING = "LIST_FINAL_PAPER_PENDING";
export const LIST_FINAL_PAPER_SUCCESS = "LIST_FINAL_PAPER_SUCCESS";
export const LIST_FINAL_PAPER_ERROR = "LIST_FINAL_PAPER_ERROR";

export const DETAIL_FINAL_PAPER_PENDING = "DETAIL_FINAL_PAPER_PENDING";
export const DETAIL_FINAL_PAPER_SUCCESS = "DETAIL_FINAL_PAPER_SUCCESS";
export const DETAIL_FINAL_PAPER_ERROR = "DETAIL_FINAL_PAPER_ERROR";

export const ADD_FINAL_PAPER_PENDING = "ADD_FINAL_PAPER_PENDING";
export const ADD_FINAL_PAPER_SUCCESS = "ADD_FINAL_PAPER_SUCCESS";
export const ADD_FINAL_PAPER_ERROR = "ADD_FINAL_PAPER_ERROR";

export const EDIT_FINAL_PAPER_PENDING = "EDIT_FINAL_PAPER_PENDING";
export const EDIT_FINAL_PAPER_SUCCESS = "EDIT_FINAL_PAPER_SUCCESS";
export const EDIT_FINAL_PAPER_ERROR = "EDIT_FINAL_PAPER_ERROR";

export const DELETE_FINAL_PAPER_PENDING = "DELETE_FINAL_PAPER_PENDING";
export const DELETE_FINAL_PAPER_SUCCESS = "DELETE_FINAL_PAPER_SUCCESS";
export const DELETE_FINAL_PAPER_ERROR = "DELETE_FINAL_PAPER_ERROR";

export const SET_SELECTED_FINAL_PAPER = "SET_SELECTED_FINAL_PAPER";

const LIST_FINAL_PAPER_URL = "cms/collegium_final_paper/find";
const ADD_FINAL_PAPER_URL = "cms/collegium_final_paper/add";
const EDIT_FINAL_PAPER_URL = "cms/collegium_final_paper/edit";
const DELETE_FINAL_PAPER_URL = "cms/collegium_final_paper/delete";

export function listFinalPaper(param = {}, resolve, detail = false) {
    return (dispatch) => {
        dispatch(actionPending(LIST_FINAL_PAPER_PENDING));
        API.post(LIST_FINAL_PAPER_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_FINAL_PAPER_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    resolve({
                        data: result,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_FINAL_PAPER_ERROR, error));
                toastError(error, "Gagal mendapatkan list final paper ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function detailFinalPaper(id = null) {
    const param = {
        id: id,
    };
    return (dispatch) => {
        dispatch(actionPending(DETAIL_FINAL_PAPER_PENDING));
        API.post(LIST_FINAL_PAPER_URL, param, getHeader())
            .then((res) => {
                dispatch(actionSuccess(DETAIL_FINAL_PAPER_SUCCESS, res.data.data));
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DETAIL_FINAL_PAPER_ERROR, error));
                toastError(error, "Gagal mendapatkan list final paper ");
            });
    };
}

export function setSelectedFinalPaper(value) {
    return {
        type: SET_SELECTED_FINAL_PAPER,
        value: value,
    };
}

export function addFinalPaper(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_FINAL_PAPER_PENDING));
        API.post(ADD_FINAL_PAPER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_FINAL_PAPER_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan final paper");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_FINAL_PAPER_ERROR, error));
                toastError(error, "Gagal menambahkan final paper, ");
            });
    };
}

export function editFinalPaper(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_FINAL_PAPER_PENDING));
        API.post(EDIT_FINAL_PAPER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_FINAL_PAPER_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah final paper");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_FINAL_PAPER_ERROR, error));
                toastError(error, "Gagal mengubah final paper, ");
            });
    };
}

export function deleteFinalPaper(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_FINAL_PAPER_PENDING));
        API.post(DELETE_FINAL_PAPER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_FINAL_PAPER_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus final paper");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_FINAL_PAPER_ERROR, error));
                toastError(error, "Gagal menghapus final paper, ");
            });
    };
}