import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: -2,
      lng: 115
    },
    zoom: 4.6
  };

  render() {
    const { dataMember } = this.props;
    return (
      <div style={{ height: '72vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCwjA-8-L9MPjI_h8lCkrTdBNcS0xbjQiE' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {dataMember !== null ?
            dataMember.data.map((item, idx) => (
              <Marker
                lat={item.latitude}
                lng={item.longitude}
                text={idx + 1}
                color="red"
              />
            )
            ) : ''}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;