import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import {
  listActivity,
  detailActivity,
} from "../../../actions/master-data/activity_action";
import {
  getActivityPending,
  getDetailActivity,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbPending,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDocument from "../../../components/modal/ModalDocument";
import Loading from "../../../components/global-components/LoadingComponent";

class KajianIlmiahModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocument: false,
      berkas: null,
      berkas_disabled: false,
      isDoneUploading: true,
      filename: "",
      spek_berkas: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeValues = this.handleChangeValues.bind(this);
    this.handleChangeLastActivity = this.handleChangeLastActivity.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { reset, show, selectedSKP_data } = this.props;

    if (prevProps.show !== show && show === true) {
      reset();
      if (selectedSKP_data.berkas) {
        this.setState({
          berkas: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(selectedSKP_data.berkas, "Daftar Hadir")
              }
            />
          ),
        });
      }
    }
  }

  handleOpenDocument(filename, modalTitle) {
    this.setState({
      filename: filename,
      modalTitle: modalTitle,
      showDocument: true,
    });
  }

  handleChangeLastActivity(values) {
    const { change } = this.props;
    change("values_id", null);
    change("skp_total", null);
  }

  handleChangeValues(values) {
    if (values) {
      const { skp_must_be_multipied, value_multipied, value_skp } = values;
      if (skp_must_be_multipied === 0) {
        this.props.change("skp_total", value_skp);
      } else {
        this.props.change("skp_total", Math.round(value_skp * value_multipied));
      }
    } else {
      this.props.change("skp_total", null);
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({
      isDoneUploading: false,
      berkas_disabled: true,
      [formName]: <Loading />,
    });

    const callback = () => {
      this.setState({ isDoneUploading: true, berkas_disabled: false });
      if (this.props.uploadP2kb_data) {
        this.props.change(formName, this.props.uploadP2kb_data);
        this.setState({ berkas: <Loading done={true} /> });
        setTimeout(
          () =>
            this.setState({
              [formName]: (
                <DetailContentLihatBerkas
                  onClick={() =>
                    this.handleOpenDocument(
                      this.props.formValue.berkas,
                      "Daftar Hadir"
                    )
                  }
                />
              ),
            }),
          2000
        );
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true, berkas_disabled: false });
      if (this.props.uploadP2kb_error) {
        this.props.change([formName], null);
        this.setState({
          [formName]: <Loading done={true} failed={true} />,
        });
      }
    };
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas", values.target.files[0]);
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, handleEditSkp, editDetailP2kb } = this.props;
    const {
      id,
      activity_date,
      last_activity,
      values_id,
      theme,
      berkas,
      status,
    } = values;
    values.hasEdit = true;

    if (status) {
      const param = {
        id,
        activity_date: activity_date,
        last_activity_skp_detail_id: last_activity.value,
        values_id: values_id.value,
        theme,
        attachments: [
          {
            label: "Daftar Hadir & Portofolio",
            filename: berkas,
          },
        ],
      };

      const call = () => {
        onHide();
        handleRefresh();
      };
      editDetailP2kb(param, call);
    } else {
      handleEditSkp(values);
      onHide();
    }
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      uploadP2kb_pending,
      formValue: { last_activity },
      detailActivity_data,
      editP2kb_pending,
    } = this.props;
    const { berkas, showDocument, filename, modalTitle } = this.state;

    let lastActivityOptions = [];
    let valuesOptions = [];
    if (detailActivity_data && detailActivity_data.lenght !== 0) {
      lastActivityOptions = detailActivity_data[0].childrens.map(
        ({ id, values, name }) => ({ value: id, label: name, values: values })
      );
    }
    if (last_activity) {
      let selectedOption = lastActivityOptions.find(
        (item) => item.value === last_activity.value
      );

      if (selectedOption) {
        valuesOptions = selectedOption.values.map((item) => ({
          ...item,
          value: item.id,
        }));
      }
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDocument
          show={showDocument}
          onHide={() => this.setState({ showDocument: false })}
          filepath="member_p2kb"
          filename={filename}
          modalTitle={modalTitle}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan tanggal kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="theme"
                  placeholder="Masukkan tema kegiatan"
                  label="Tema Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="last_activity"
                  placeholder="Pilih tingkat"
                  label="Tingkat"
                  options={lastActivityOptions}
                  onChange={this.handleChangeLastActivity}
                  component={renderSelectWithLabel}
                />

                <Field
                  name="values_id"
                  placeholder="Pilih status"
                  label="Status"
                  options={valuesOptions}
                  onChange={this.handleChangeValues}
                  component={renderSelectWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="berkas"
                  fileType="skp"
                  title="daftar hadir"
                  disabled={this.state.berkas_disabled}
                  isDone={this.state.isDoneUploading}
                  message={berkas ? berkas : "PDF, PNG, JPG maksimal 1 MB"}
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending || editP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const fileType = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
  const { activity_date, last_activity, values_id, theme, berkas, spek_berkas } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!last_activity) {
    errors.last_activity = "Tingkat kegiatan wajib diisi";
  }
  if (!theme) {
    errors.theme = "Tema kegiatan wajib diisi";
  }
  if (!values_id) {
    errors.values_id = "Status kegiatan wajib diisi";
  }

  if (!berkas) {
    errors.berkas = "Berkas daftar hadir wajib diisi";
  }
  else{
    if (spek_berkas && (spek_berkas.type !== fileType[0] && spek_berkas.type !== fileType[1] && spek_berkas.type !== fileType[2] && spek_berkas.type !== fileType[3])){
      errors.berkas = "Format file hanya bisa PDF, PNG, JPG"
    }
    else if (spek_berkas && (spek_berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 1 MB"
    }
  }
  return errors;
}

KajianIlmiahModalEdit = reduxForm({
  form: "KajianIlmiahModalEdit",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(KajianIlmiahModalEdit);

function mapStateToProps(state) {
  const {
    listActivity,
    uploadP2kb,
    detailActivity,
    selectedSKP,
    editDetailP2kb,
  } = state;
  const selector = formValueSelector("KajianIlmiahModalEdit");
  const formValue = selector(state, "last_activity", "berkas", "spek_berkas");
  let initialValues = {};
  if (selectedSKP) {
    initialValues = selectedSKP;
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    detailActivity_data: getDetailActivity(detailActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    editP2kb_pending: getP2kbPending(editDetailP2kb),

    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    selectedSKP_data: selectedSKP,
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  listActivity,
  detailActivity,
  uploadP2kb,
  editDetailP2kb,
})(withRouter(KajianIlmiahModalEdit));
