import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_KOPERASI_PENDING = "LIST_KOPERASI_PENDING";
export const LIST_KOPERASI_SUCCESS = "LIST_KOPERASI_SUCCESS";
export const LIST_KOPERASI_ERROR = "LIST_KOPERASI_ERROR";

export const DETAIL_KOPERASI_PENDING = "DETAIL_KOPERASI_PENDING";
export const DETAIL_KOPERASI_SUCCESS = "DETAIL_KOPERASI_SUCCESS";
export const DETAIL_KOPERASI_ERROR = "DETAIL_KOPERASI_ERROR";

export const APPROVAL_KOPERASI_PENDING = "APPROVAL_KOPERASI_PENDING";
export const APPROVAL_KOPERASI_SUCCESS = "APPROVAL_KOPERASI_SUCCESS";
export const APPROVAL_KOPERASI_ERROR = "APPROVAL_KOPERASI_ERROR";

export const ADD_KOPERASI_PENDING = "ADD_KOPERASI_PENDING";
export const ADD_KOPERASI_SUCCESS = "ADD_KOPERASI_SUCCESS";
export const ADD_KOPERASI_ERROR = "ADD_KOPERASI_ERROR";

// export const FIND_SHU_BEFORE_KOPERASI_PENDING = "FIND_SHU_BEFORE_KOPERASI_PENDING";
// export const FIND_SHU_BEFORE_KOPERASI_SUCCESS = "FIND_SHU_BEFORE_KOPERASI_SUCCESS";
// export const FIND_SHU_BEFORE_KOPERASI_ERROR = "FIND_SHU_BEFORE_KOPERASI_ERROR";

export const FIND_SHU_KOPERASI_PENDING = "FIND_SHU_KOPERASI_PENDING";
export const FIND_SHU_KOPERASI_SUCCESS = "FIND_SHU_KOPERASI_SUCCESS";
export const FIND_SHU_KOPERASI_ERROR = "FIND_SHU_KOPERASI_ERROR";

export const UPDATE_SHU_KOPERASI_PENDING = "UPDATE_SHU_KOPERASI_PENDING";
export const UPDATE_SHU_KOPERASI_SUCCESS = "UPDATE_SHU_KOPERASI_SUCCESS";
export const UPDATE_SHU_KOPERASI_ERROR = "UPDATE_SHU_KOPERASI_ERROR";

export const MAIN_DEPOSIT_PENDING = "MAIN_DEPOSIT_PENDING";
export const MAIN_DEPOSIT_SUCCESS = "MAIN_DEPOSIT_SUCCESS";
export const MAIN_DEPOSIT_ERROR = "MAIN_DEPOSIT_ERROR";

export const SET_SELECTED_KOPERASI = "SET_SELECTED_KOPERASI";

const LIST_KOPERASI_URL = "member/cooperative/find";
const APPROVAL_KOPERASI_URL = "member/cooperative/payment_confirmation";
const ADD_KOPERASI_URL = "member/cooperative/add";
// const FIND_SHU_BEFORE_KOPERASI_URL = "member/cooperative/find_shu_before";
const FIND_SHU_KOPERASI_URL = "member/cooperative/find_shu";
const UPDATE_SHU_KOPERASI_URL = "member/cooperative/update_shu";
const MAIN_DEPOSIT_URL = "member/cooperative/get_total_main_deposit";

export function listKoperasi(param = {}, resolve, detail = false) {
    return (dispatch) => {
        dispatch(actionPending(LIST_KOPERASI_PENDING));
        API.post(LIST_KOPERASI_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_KOPERASI_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    if (detail === true) {
                        let detailKoperasi = data.data[0].journal_participants.map(
                            (item, i) => ({
                                ...item,
                                no: i + 1 + (param.page - 1) * 10,
                            })
                        );
                        resolve({
                            data: detailKoperasi,
                            page: param.page - 1,
                            totalCount: detailKoperasi.length,
                        });
                    } else {
                        let detailKoperasi = response.map(
                            (item, i) => ({
                                ...item,
                                no: i + 1 + (param.page - 1) * 10,
                                amount_running_new: i !== 0 ?  ( response[i+1] ? response[i+1].amount : 0 ) + item.amount : response.map(o => o.amount).reduce((a, c) => { return a + c }) ,
                            })
                        );
                        resolve({
                            data: detailKoperasi,
                            page: param.page - 1,
                            totalCount: records_total,
                        });
                    }
                }
                // return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_KOPERASI_ERROR, error));
                toastError(error, "Gagal mendapatkan list koperasi, ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function detailKoperasi(id = null) {
    const param = {
        id: id,
    };
    return (dispatch) => {
        dispatch(actionPending(DETAIL_KOPERASI_PENDING));
        API.post(LIST_KOPERASI_URL, param, getHeader())
            .then((res) => {
                dispatch(actionSuccess(DETAIL_KOPERASI_SUCCESS, res.data.data));
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DETAIL_KOPERASI_ERROR, error));
                toastError(error, "Gagal mendapatkan list koperasi, ");
            });
    };
}

export function setSelectedKoperasi(value) {
    return {
        type: SET_SELECTED_KOPERASI,
        value: value,
    };
}

export function approvalKoperasi(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(APPROVAL_KOPERASI_PENDING));
        API.post(APPROVAL_KOPERASI_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(APPROVAL_KOPERASI_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah status koperasi");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(APPROVAL_KOPERASI_ERROR, error));
                toastError(error, "Gagal mengubah status koperasi, ");
            });
    };
}

export function addKoperasi(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_KOPERASI_PENDING));
        API.post(ADD_KOPERASI_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_KOPERASI_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan koperasi");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_KOPERASI_ERROR, error));
                toastError(error, "Gagal menambahkan koperasi, ");
            });
    };
}

export function findSHU(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(FIND_SHU_KOPERASI_PENDING));
        API.post(FIND_SHU_KOPERASI_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(FIND_SHU_KOPERASI_SUCCESS, res.data));
                const { records_total, data: response } = res.data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    resolve({
                        data: result,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(FIND_SHU_KOPERASI_ERROR, error));
            });
    };
}
export function updateSHU(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(UPDATE_SHU_KOPERASI_PENDING));
        API.post(UPDATE_SHU_KOPERASI_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(UPDATE_SHU_KOPERASI_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengupdate SHU!");
                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(UPDATE_SHU_KOPERASI_ERROR, error));
            });
    };
}
export function mainDeposit(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(MAIN_DEPOSIT_PENDING));
        API.post(MAIN_DEPOSIT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(MAIN_DEPOSIT_SUCCESS, res.data));
                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(MAIN_DEPOSIT_ERROR, error));
            });
    };
}