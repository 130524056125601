import React, { Component } from "react";
import { connect } from "react-redux";
import CardLaporanDanaSHU from "../../components/dasboard/CardLaporanDanaSHU";
import CardKoperasiPokok from "../../components/dasboard/CardKoperasiPokok";
import CardKoperasiWajib from "../../components/dasboard/CardKoperasiWajib";
import CardKoperasiCash from "../../components/dasboard/CardKoperasiCash";
import CardKoperasi from "../../components/dasboard/CardKoperasi"
class DashboardAdminKoperasi extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <>
                <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
                    <CardKoperasiPokok />
                    <CardKoperasiWajib />
                    <CardKoperasiCash />
                </div>
                <div style={{ width: "100%", marginTop: 20, display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
                    <CardKoperasi />
                </div>
                <CardLaporanDanaSHU />
            </>
        )
    }
}

function mapStateToProps() {
    return {

    };
}

export default connect(mapStateToProps, {

})(DashboardAdminKoperasi);
