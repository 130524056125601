import React, { Component } from "react";
import "react-dates/initialize";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { p2kbList } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
} from "../../reducers/p2kb/p2kb_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderP2kb from "./Header_p2kb";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import HeaderListP2kbComponent from "../../components/p2kb/HeaderListP2kbComponent";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import { getUser } from "../../helpers/user";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import { detailP2kb, dateP2kb } from "../../actions/p2kb/p2kb_action";
import { getDetailP2kb, getDateP2kb } from "../../reducers/p2kb/p2kb_reducer";
import PaginationComponent from "../../components/table/PaginationComponent";

class ListP2kb extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      modalEditP2kbShow: false,
      modalHapusP2kbShow: false,
      p2kb_id: "",
      status: "",
      activity_type: "",
      isLoad: true,
      id: null,
      focusedInput: false,
      isFirstLayer: true,
      start_date: localStorage.getItem("start_date")
        ? localStorage.getItem("start_date")
        : moment().format("YYYY-MM-DD"),
      end_date: localStorage.getItem("end_date")
        ? localStorage.getItem("end_date")
        : moment().format("YYYY-MM-DD"),
      last_reject_notes: "",
      last_reject_filename: "",
      str_active: null,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    let param2 = {
      isFirstLayer: true,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    new Promise((callback) => {
      this.props.p2kbList(param2, callback);
    });
    localStorage.setItem("end_date", moment().format("YYYY-MM-DD"));
  }

  onFocusChange(data) {
    this.setState({ focused: data.focused });
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailP2kb(data) {
    const isFirstLayer = this.state.isFirstLayer;
    let realm_activity = null;
    let values = null;
    if (!isFirstLayer) {
      realm_activity = JSON.parse(
        localStorage.getItem("skp_add_data_temp_first")
      );
      values = {
        ...values,
        activity_type: this.state.activity_type,
        realm_activity,
      };
    } else {
      values = {
        activity: "",
        activity_type: "",
        realm_activity: {
          label: data.name,
          value: data.id,
        },
      };
    }
    const tindakanOperasi = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dc",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dd",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363de",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363df",
    ];
    const pelayananMedis = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dg",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dh",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363di",
    ];
    const pertemuanAuditor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dl"];
    const membacaJurnal = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dn"];
    const penelusuranInformasi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363do"];
    const acaraIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dj"];
    const seminar = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dp",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dq",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dr",
    ];
    const kajianIlmiah = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dk"];
    const edukasiPasien = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dm"];
    const menghadiriKongres = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ds"];
    const menulisIlmiah = [
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dw",
      "ba0a85d1-9af2-4eb8-83ef-7029ac9363dx",
    ];
    const menulisBuku = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dy"];
    const membimbingMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eg"];
    const mengajarMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ee"];
    const mengikutiPelatihan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dt"];
    const kepengurusanProfesi = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dv"];
    const pengabdianMasyarakat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363du"];
    const penelitianDipublikasikan = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ec"];
    const penyeliaSupervisor = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ed"];
    const wawancaraKajianElektronik = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363dz"];
    const menciptakanAlat = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eb"];
    const praktekMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ef"];
    const membuatVideo = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363ea"];
    const mengujiMahasiswa = ["ba0a85d1-9af2-4eb8-83ef-7029ac9363eh"];

    if (isFirstLayer) {
      localStorage.setItem("skp_add_data_temp_first", JSON.stringify(values));
      localStorage.setItem("nama_ranah", values.realm_activity.label);
      localStorage.setItem("realm_activity_id", JSON.stringify(data.id));
      this.props.history.push("p2kb/add/");
    } else {
      if (tindakanOperasi.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/tindakan-operasi/${data.id}`);
      }
      if (pelayananMedis.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/pelayanan-medis/${data.id}`);
      }
      if (pertemuanAuditor.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/pertemuan-auditor/${data.id}`);
      }
      if (membacaJurnal.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/membaca-jurnal/${data.id}`);
      }
      if (penelusuranInformasi.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(
          `p2kb/details/penelusuran-informasi/${data.id}`
        );
      }
      if (acaraIlmiah.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/acara-ilmiah/${data.id}`);
      }

      if (seminar.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/seminar/${data.id}`);
      }
      if (kepengurusanProfesi.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/kepengurusan-profesi/${data.id}`);
      }
      if (penelitianDipublikasikan.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(
          `p2kb/details/penelitian-dipublikasikan/${data.id}`
        );
      }
      if (mengikutiPelatihan.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/pelatihan/${data.id}`);
      }
      if (penyeliaSupervisor.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/penyelia-supervisor/${data.id}`);
      }
      if (kajianIlmiah.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/kajian-ilmiah/" + data.activity_data.id + "/" + data.id
        );
      }
      if (menghadiriKongres.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/menghadiri-kongres/" +
            data.activity_data.id +
            "/" +
            data.id
        );
      }
      if (edukasiPasien.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/edukasi-pasien/" + data.activity_data.id + "/" + data.id
        );
      }
      if (menulisIlmiah.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/menulis-ilmiah/" + data.activity_data.id + "/" + data.id
        );
      }
      if (menulisBuku.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/menulis-buku/" + data.activity_data.id + "/" + data.id
        );
      }
      if (membimbingMahasiswa.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/membimbing-mahasiswa/" +
            data.activity_data.id +
            "/" +
            data.id
        );
      }
      if (mengajarMahasiswa.includes(data.activity_data.id)) {
        this.props.history.push(
          "p2kb/details/mengajar-mahasiswa/" +
            data.activity_data.id +
            "/" +
            data.id
        );
      }
      if (pengabdianMasyarakat.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(
          `p2kb/details/pengabdian-masyarakat/${data.id}`
        );
      }
      if (wawancaraKajianElektronik.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/kajian-elektronik/${data.id}`);
      }
      if (menciptakanAlat.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/menciptakan-alat/${data.id}`);
      }
      if (praktekMahasiswa.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/praktek-mahasiswa/${data.id}`);
      }
      if (membuatVideo.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/membuat-video/${data.id}`);
      }
      if (mengujiMahasiswa.includes(data.activity_data.id)) {
        localStorage.setItem("skp_detail", JSON.stringify(values));
        this.props.history.push(`p2kb/details/menguji-mahasiswa/${data.id}`);
      }
    }
  }

  componentDidMount() {
    localStorage.removeItem("tempSKPData");
    localStorage.removeItem("skp_edit_detail_skp_seminar");
    localStorage.removeItem("skp_edit_detail_skp_acara_ilmiah");
    localStorage.removeItem("skp_edit_detail_skp_tindakan_operasi");
    let param = {
      id: getUser().id,
    };
    new Promise((callback) => {
      setTimeout(() => this.setState({ isLoad: false }), 2000);
      this.props.memberList(param, callback);
    });
    let param2 = {
      isFirstLayer: true,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    new Promise((callback) => {
      this.props.p2kbList(param2, callback);
    });

    const url = window.location.href;
    const ID = url.substring(url.lastIndexOf("/") + 1);
    const isID = url.substring(url.lastIndexOf("/") + 1) !== "skp";
    if (isID && this.state.id === null) {
      this.setState({ id: ID });
    }
    const callbackFilter = () => {
      if (this.props.dateP2kb_data.start_date !== null) {
        const dateFilter = moment(this.props.dateP2kb_data.start_date).format(
          "YYYY-MM-DD"
        );
        this.setState({ start_date: dateFilter });
        localStorage.setItem("start_date", dateFilter);
      } else {
        this.setState({ start_date: moment().format("YYYY-MM-DD") });
        localStorage.setItem("start_date", moment().format("YYYY-MM-DD"));
      }
    };
    this.props.dateP2kb(
      { isFindDate: true, user_id: getUser().id },
      callbackFilter
    );
  }

  render() {
    const { p2kbList, p2kbList_data, memberList_data } = this.props;
    let data = [];
    let records_total = "";
    let length = "";
    let outstanding_skp = null;
    let current_skp = null;
    let user = "-";
    let status = null;
    let submit_skp = 0;
    let submit_status = null;
    let total_skp_approved = 0;
    let member_list_data = null;
    let last_reject_notes = null;
    let last_reject_filename = null;
    let status_arr = [];
    let submit_status_arr = [];
    let min = null;
    let max = null;
    let min_sub = null;
    let max_sub = null;
    let str_active = null;
    let all_reject_is_edit_already = true;
    if (p2kbList_data) {
      data = p2kbList_data;
      records_total = data.records_total;
      length = data.data.length;

      all_reject_is_edit_already = data.all_reject_is_edit_already;

      status = p2kbList_data.data[0]?.min_status;
      if (p2kbList_data.total_not_submitted) {
        if (p2kbList_data.total_not_submitted.length > 0) {
          // submit_status = p2kbList_data.total_submitted.total_submitted.submit_status ? p2kbList_data.total_submitted.total_submitted.submit_status : "-";
        }
      }
      p2kbList_data.data.forEach((item, index) => {
        if (item.min_status !== null) {
          status_arr.push(item.min_status);
        }

        if (item.min_submit_status !== null) {
          submit_status_arr.push(item.min_submit_status);
        }

        // submit_skp += item.total_skp_grouped
      });
      if (p2kbList_data?.total_terkumpul?.total_terkumpul !== null) {
        submit_skp = p2kbList_data?.total_terkumpul?.total_terkumpul;
      }
      if (data.total_skp_approve) {
        if (data.total_skp_approve.length > 0) {
          total_skp_approved = data.total_skp_approve[0].total_skp_approved;
          outstanding_skp = 250 - (total_skp_approved + submit_skp);
          outstanding_skp = outstanding_skp > 0 ? outstanding_skp : 0;
        }
      }
      if (p2kbList_data?.total_submitted?.role_next_approve !== null) {
        user = p2kbList_data.total_submitted?.role_next_approve?.role_name;
      }

      if (status_arr) {
        min = Math.min(...status_arr);
        max = Math.max(...status_arr);
        if (max === 2) {
          status = 2;
        } else {
          status = min;
        }
      }
      if (submit_status_arr) {
        min_sub = Math.min(...submit_status_arr);
        submit_status = min_sub;
      }
      if (p2kbList_data.str_active) {
        str_active = p2kbList_data.str_active;
      }
      if (p2kbList_data?.reject_notes !== null) {
        last_reject_notes = p2kbList_data?.reject_notes?.last_reject_notes;
        last_reject_filename =
          p2kbList_data?.reject_notes?.last_reject_filename;
      }
    }
    return (
      <ContentContainer>
        <HeaderListP2kbComponent
          isLoad={this.state.isLoad}
          current_skp={current_skp ? current_skp : "-"}
          outstanding_skp={outstanding_skp}
          user={user ? user : "-"}
          status={status}
          submit_status={submit_status}
          submit_skp={submit_skp ? submit_skp.toFixed(2) : submit_skp}
          total_skp_approved={total_skp_approved ? total_skp_approved.toFixed(2) : 0}
          all_reject_is_edit_already={all_reject_is_edit_already}
        />

        <HeaderP2kb
          isFirstLayer={true}
          handleRefresh={this.handleRefresh}
          status={status}
          submit_status={submit_status}
          last_reject_notes={last_reject_notes}
          last_reject_filename={last_reject_filename}
          submit_skp={submit_skp}
          str_active={str_active}
          all_reject_is_edit_already={all_reject_is_edit_already}
        />
        {localStorage.getItem("start_date") !== null && (
          <MaterialTable
            tableRef={this.tableRef}
            title="P2kb"
            columns={[
              {
                title: "No",
                field: "name",
                render: (rowData) => {
                  return <>{rowData.no}</>;
                },
                width: 20,
                headerStyle: {
                  paddingLeft: 40,
                },
                cellStyle: {
                  paddingLeft: 40,
                },
              },
              { title: "Ranah", field: "name" },
              {
                title: "Total SKP",
                field: "total_skp_grouped",
                render: (data) => {
                  let total_skp_grouped = "0";
                  total_skp_grouped = data.total_skp_grouped
                    ? data.total_skp_grouped.toFixed(2)
                    : total_skp_grouped;
                  return total_skp_grouped;
                },
              },
              {
                title: "",
                render: (data) => {
                  return (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleDetailP2kb(data)}>
                        Detail
                      </MenuItem>
                      {/* <MenuItem onClick={() => this.handleDeleteP2kb(data)}>
                                            Delete
                                        </MenuItem> */}
                    </DetailButtonComponent>
                  );
                },
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let param = {
                  page: query.page + 1,
                  length: 10,
                  isFirstLayer: true,
                  search_text: this.state.search,
                  status: this.state.status,
                  activity_type: this.state.activity_type,
                  id: this.state.id,
                  start_date: localStorage.getItem("start_date"),
                  end_date: this.state.end_date,
                };
                p2kbList(param, resolve, reject);
              })
            }
            options={{
              paginationType: "stepped",
              pageSize: 10,
              pageSizeOptions: [],
              showTitle: false,
              search: false,
              headerStyle: {
                backgroundColor: "#F8F9FB",
                zIndex: 0,
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak ada data",
              },
            }}
            components={{
              Toolbar: () => <div />,
              Container: (props) => <Paper {...props} elevation={0} />,
              Pagination: (props) => (
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{ paddingRight: 20, paddingLeft: 20 }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                      borderBottom: "1px solid rgba(224, 224, 224)",
                    }}
                    vertical="center"
                  >
                    <p>
                      {" "}
                      Menampilkan {length} dari {records_total} data{" "}
                    </p>
                  </Row>
                  <TablePagination
                    {...props}
                    color="primary"
                    labelRowsPerPage={
                      <div style={{ fontSize: 14 }}>
                        {props.labelRowsPerPage}
                      </div>
                    }
                    labelDisplayedRows={(row) => (
                      <div style={{ fontSize: 14, color: "red" }}>
                        {/* {props.labelDisplayedRows(row)} */}
                      </div>
                    )}
                    SelectProps={{
                      style: {
                        fontSize: 20,
                      },
                    }}
                  />
                </Row>
              ),
            }}
          />
        )}
      </ContentContainer>
    );
  }
}

function mapStateToProps({
  p2kbList,
  memberList,
  listStr,
  detailP2kb,
  dateP2kb,
}) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    dateP2kb_data: getDateP2kb(dateP2kb),
    detailP2kb_data: getDetailP2kb(detailP2kb),
  };
}

export default connect(mapStateToProps, {
  p2kbList,
  memberList,
  listStr,
  detailP2kb,
  dateP2kb,
})(withRouter(ListP2kb));
