import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from '../../assets/canvas/canvasjs.react';
import { connect } from "react-redux";
import { listOrthopaedi } from '../../actions/dashboard/kolegium_action'
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer'
import SelectComponent from "../../components/form/SelectComponent";
import { universityList } from "../../actions/master-data/university_action";
import {
    getUniversityListPending,
    getUniversityList,
} from "../../reducers/master-data/university_reducer";


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class CardOrthopaedi extends Component {
    constructor() {
        super();
        this.state = {
            university_id: '',
        };
        this.handleUniversityChange = this.handleUniversityChange.bind(this);
    }

    componentDidMount() {
        console.log(this.props)
        this.props.listOrthopaedi()
    }

    handleUniversityChange(e) {
        console.log(e)
        console.log(this.props)
        if (e) {
            this.props.listOrthopaedi({ university_id: e.value })
        } else {
            this.props.listOrthopaedi();
        }
    }

    render() {

        const { listOrthopaedi_data, listUniversity_data
        } = this.props
        let data = [], result = null
        let universityOptions = [];
        if (listUniversity_data) {
            universityOptions = listUniversity_data.data.map((item) => ({
                label: item.name,
                value: item.id,
                city: item.city.name,
            }));
            universityOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
        }

        if (listOrthopaedi_data) {

            data = listOrthopaedi_data.map(({ month, total }) => ({ x: new Date(2000, month), y: total }))

            const options = {
                animationEnabled: true,
                height: 300,
                axisX: {
                    valueFormatString: "MMM",

                },
                axisY: {
                    includeZero: true,
                    gridThickness: 0,
                },
                data: [
                    {
                        xValueFormatString: "MMMM",
                        type: "splineArea",
                        lineColor: "#5AB853",
                        color: '#C9E8D4',
                        lineThickness: 8,
                        markerColor: 'white',
                        markerBorderColor: '#5AB853',
                        markerBorderThickness: 4,
                        markerSize: 10,
                        dataPoints: data
                    },
                ]
            }
            result = (
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
            )
        }

        return (
            <div style={{ width: "100%", height: '50%', paddingBottom: 10 }}>

                <Column style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'white'

                }}>

                    {/* <div className={css(styles.cardHeader)}></div> */}


                    {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                    <Row className={css(styles.wrapperTop, styles.borderBottom)}
                        style={{ width: "100%", padding: 20, backgroundColor: "white" }}
                        vertical="center"
                        horizontal="space-between">


                        <label className={css(styles.titleLabel)}> PPDS Orthopaedi</label>
                        <Row>
                            <div style={{ width: 250, margin: "0 10px" }}>
                                <SelectComponent
                                    options={universityOptions}
                                    isRounded
                                    onChange={this.handleUniversityChange}
                                    placeholder="Pilih Universitas"
                                />
                            </div>
                        </Row>

                    </Row>
                    <Column horizontal='center' className={css(styles.wrapper,)} style={{ width: "100%", padding: 20 }}>
                        {result}

                    </Column>

                </Column>
            </div>

        )
    }
}

function mapStateToProps({
    listOrthopaedi, universityList }) {
    return {
        listOrthopaedi_data: getDashboardData(listOrthopaedi),
        listUniversity_data: getUniversityList(universityList),
        listUniversity_pending: getUniversityListPending(universityList),
    };
}

export default connect(mapStateToProps, {
    listOrthopaedi,
    universityList
})(CardOrthopaedi);



const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: 'bold',
        margin: "5px 0"
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
    wrapper: {
        padding: 15,
        // height: 400,
        backgroundColor: 'white'
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    cardHeader: {
        backgroundColor: "#45A3E7",
        height: 10,
        width: "100%"
    }
});