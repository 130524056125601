import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ExportButton from "../../assets/icon-document-p2kb";

import { StyleSheet, css } from "aphrodite";

import { default_api } from "../../constants/default_api";

class ReportHeader extends Component {

  handleDownload() {
    window.open(default_api + "/image/show/shared/Format Import Data Laporan Kopotis.xlsx")
  };
  render() {
    const { handleShowModalAdd, lastUpdate } = this.props;
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB", justifyContent: "space-between" }}
      >
        <div style={{ paddingRight: 24 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleShowModalAdd}
          >
            Import Data
          </Button>
        </div>
        <div>
          <Button onClick={this.handleDownload} style={{ backgroundColor: "rgb(239, 240, 242)" }}>
            <ExportButton fill="#626568" /><span className={css(styles.exportButton)}>Unduh Format</span>
          </Button>
        </div>
      </Row>
    );
  }
}
const styles = StyleSheet.create({
  exportButton: {
    alignItems: "center",
    display: "flex",
    marginLeft: 10,
    color: "#626568",

  }
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(ReportHeader);
