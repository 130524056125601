import React from "react";

export default (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 2 24 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ""}
      d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z"
      fill={props.fill || "#FFFFFF"}
    />
  </svg>
);
