import {
    LIST_TRANSRS_PENDING,
    LIST_TRANSRS_SUCCESS,
    LIST_TRANSRS_ERROR,
    EDIT_TRANSRS_PENDING,
    EDIT_TRANSRS_SUCCESS,
    EDIT_TRANSRS_ERROR,
    APPROVAL_TRANSRS_PENDING,
    APPROVAL_TRANSRS_SUCCESS,
    APPROVAL_TRANSRS_ERROR,
    ADD_TRANSRS_PENDING,
    ADD_TRANSRS_SUCCESS,
    ADD_TRANSRS_ERROR,
    DETAIL_TRANSRS_PENDING,
    DETAIL_TRANSRS_SUCCESS,
    DETAIL_TRANSRS_ERROR,
    UPLOAD_TRANSRS_PENDING,
    UPLOAD_TRANSRS_SUCCESS,
    UPLOAD_TRANSRS_ERROR,
    SET_SELECTED_TRANSRS,
} from "../../actions/transrs/transrs_action";

const initialState = {
    pending: false,
    error: null,
};

export function transrsList(state = { ...initialState, transrsList: null }, action) {
    switch (action.type) {
        case LIST_TRANSRS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_TRANSRS_SUCCESS:
            return {
                ...state,
                pending: false,
                transrsList: action.data,
            };
        case LIST_TRANSRS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addTransrs(state = { ...initialState, addTransrs: null }, action) {
    switch (action.type) {
        case ADD_TRANSRS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_TRANSRS_SUCCESS:
            return {
                ...state,
                pending: false,
                addTransrs: action.data,
            };
        case ADD_TRANSRS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editTransrs(state = { ...initialState, editTransrs: null }, action) {
    switch (action.type) {
        case EDIT_TRANSRS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_TRANSRS_SUCCESS:
            return {
                ...state,
                pending: false,
                editTransrs: action.data,
            };
        case EDIT_TRANSRS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedTransrs(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_TRANSRS:
            return action.value;
        default:
            return state;
    }
}

export function approvalTransrs(
    state = { ...initialState, approvalTransrs: null },
    action
) {
    switch (action.type) {
        case APPROVAL_TRANSRS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVAL_TRANSRS_SUCCESS:
            return {
                ...state,
                pending: false,
                approvalTransrs: action.data,
            };
        case APPROVAL_TRANSRS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailTransrs(
    state = { ...initialState, detail_branch: null },
    action
) {
    switch (action.type) {
        case DETAIL_TRANSRS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_TRANSRS_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_branch: action.data,
            };
        case DETAIL_TRANSRS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function uploadTransrs(
    state = { ...initialState, uploadTransrs: null },
    action
  ) {
    switch (action.type) {
      case UPLOAD_TRANSRS_PENDING:
        return {
          ...state,
          pending: true,
        };
      case UPLOAD_TRANSRS_SUCCESS:
        return {
          ...state,
          pending: false,
          uploadTransrs: action.data,
        };
      case UPLOAD_TRANSRS_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      default:
        return state;
    }
  }

export const getTransrsPending = (state) => state.pending;
export const getDetailTransrs = (state) => state.detail_branch;
export const getTransrsError = (state) => state.error;
export const getTransrsListPending = (state) => state.pending;
export const getTransrsList = (state) => state.transrsList;
export const getTransrsListError = (state) => state.error;
export const getTransrsAddPending = (state) => state.pending;
export const getTransrsAdd = (state) => state.addTransrs;
export const getTransrsAddError = (state) => state.error;
export const getTransrsEditPending = (state) => state.pending;
export const getTransrsEdit = (state) => state.editTransrs;
export const getTransrsEditError = (state) => state.error;
export const getTransrsApprovalPending = (state) => state.pending;
export const getTransrsApproval = (state) => state.approvalTransrs;
export const getTransrsApprovalError = (state) => state.error;
export const getSelectedTransrs = (state) => state.selectedTransrs;
export const getTransrsUploadPending = (state) => state.pending;
export const getTransrsUpload = (state) => state.uploadTransrs;
export const getTransrsUploadError = (state) => state.error;