import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderSelectWithLabel, renderDatepickerWithLabel, renderInputWithLabel, renderFileForm
} from "../../../components/form/FormComponent";
import { listActivity } from "../../../actions/master-data/activity_action";
import { getListActivity, getActivityPending, getActivityError } from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
    getP2kbUploadPending,
    getP2kbUpload,
    getP2kbUploadError,
    getP2kbEditDetailPending,
    getP2kbEditDetail,
    getP2kbEditDetailError,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "../Modal_document_pdf";
import ModalDetailDokumen from "../Modal_document";
import Loading from "../../../components/global-components/LoadingComponent";

class ModalEditDetailSKP_KepengurusanProfesi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
            skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
            modalDetailDokumenSKPShow: false,
            modalDetailDokumenShow: false,
            berkas: "",
            berkas_button: false,
            isDoneUploading: true,
            imgUrl: "",
            modal_title: "",
            selected_activity: null,
            selected_scale: null,
            selected_peran: null,
            selected_duration: null,
            spek_berkas: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleChangeScale = this.handleChangeScale.bind(this);
        this.handleChangePosition = this.handleChangePosition.bind(this);
        this.handleChangeSAL = this.handleChangeSAL.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    handleOpenDocument(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        if (imgUrl.split('.')[1] === "pdf") {
            this.setState({ modalDetailDokumenSKPShow: true })
        } else {
            this.setState({ modalDetailDokumenShow: true })
        }
    }

    handleChangeScale(values) {
        this.props.change('last_activity_skp_detail_id', null);
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangePosition(values) {
        this.props.change('values_id', null);
        this.props.change('skp_total', null);
    }

    handleChangeSAL(values) {
        const { listActivity_data, change } = this.props;
        let {
            scale,
            last_activity_skp_detail_id
        } = this.props.formValue;
        let selected_activity = listActivity_data.data.find(item => item.id === this.state.tempSKPData.activity.value);
        let selected_scale = selected_activity.childrens.find(item => item.id === scale.value);
        let selected_position = selected_scale.childrens.find(item => item.id === last_activity_skp_detail_id.value);
        let selected_sal = selected_position.values.find(item => item.id === values.value);
        change('skp_total', selected_sal.value_skp);
    }

    handleUploadFile(values) {
        const formName = values.target.name;
        // if (formName === "berkas_sip") {
        //     this.setState({ berkas_sip: "Berkas SIP berhasil diunggah" })
        //     this.props.change("berkas_sip", "dvorak");
        // } else if (formName === "berkas") {
        //     this.setState({ berkas: "Berkas Medis berhasil diunggah" })
        //     this.props.change("berkas", "qwerty");
        // }
        // if (values.target.files[0].size < 2000000) {
        this.setState({ isDoneUploading: false })
        this.setState({ berkas_button: true })
        if (formName === "berkas") {
            this.setState({ berkas: <Loading /> })
        }
        const callback = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_button: false })
            if (this.props.uploadP2kb_data) {
                if (formName === "berkas") {
                    this.props.change("berkas", this.props.uploadP2kb_data);
                    this.setState({ berkas: <Loading done={true} /> })
                    setTimeout(() => this.setState({ berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas, "Keterangan Dokumen")} /> }), 2000)
                }
            }
        }
        const error = () => {
            this.setState({ isDoneUploading: true })
            this.setState({ berkas_button: false })
            if (this.props.uploadP2kb_error) {
                if (formName === "berkas") {
                    this.props.change("berkas", null);
                    this.setState({ berkas: <Loading done={true} failed={true} /> })
                }
            }
        }
        this.props.uploadP2kb(values.target.files[0], callback, error);
        this.props.change("spek_berkas", values.target.files[0]);
        // } else {
        //     if (formName === "berkas") {
        //         this.setState({ berkas: "Batas maksimal ukuran file 2 MB!" })
        //     }
        // }
    }

    handleSubmit(values) {
        const { handleEditDetail, onHide, handleRefresh, editDetailP2kb, formValue } = this.props;
        if (formValue.isDetail && formValue.status && formValue.status !== 0) {
            const param = {
                id: values.id,
                activity_date: values.activity_date,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                values_id: values.values_id.value,
                management_name: values.management_name,
                period: values.period.value,
                attachments: [
                    {
                        label: "Keterangan Dokumen",
                        filename: values.berkas
                    }
                ]
            }
            const callback = () => {
                onHide();
                handleRefresh();
            }
            editDetailP2kb(param, callback);
        } else {
            let details_tobesent = {
                activity_date: values.activity_date,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id.value,
                values_id: values.values_id.value,
                management_name: values.management_name,
                period: values.period.value,
                hasEdit: true,
                attachments: [
                    {
                        label: "Keterangan Dokumen",
                        filename: values.berkas
                    }
                ]
            }
            let details_tobeshown = {
                ...details_tobesent,
                skp: values.skp_total,
                last_activity_skp_detail_id: values.last_activity_skp_detail_id,
                values_id: values.values_id,
                scale: values.scale,
                period: values.period.value,
                no: this.props.formValue.no,
                total_skp: values.skp_total,
                activity_id: this.props.formValue.activity_id
            }
            handleEditDetail(details_tobeshown, details_tobesent);
            onHide();
        }
    }

    updateState() {
        if (this.state.berkas === "") {
            if (this.props.formValue.berkas) {
                this.setState({ berkas: <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(this.props.formValue.berkas, "Keterangan Dokumen")} /> })
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.updateState();
    }

    componentDidMount() {
        this.props.listActivity({ length: 999 });
    }

    render() {
        const { show,
            onHide,
            handleSubmit,
            uploadP2kb_pending,
            listActivity_data,
            formValue
        } = this.props;
        let {
            scale,
            last_activity_skp_detail_id
        } = this.props.formValue;
        let selected_scale, selected_position, selected_activity = "";
        let positionOptions, scaleOptions, SALOptions, yearOptions = [];
        let currentYear = new Date().getFullYear();
        for (let i = currentYear; i >= 2000; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        if (listActivity_data) {
            if (formValue.activity_id) {
                selected_activity = listActivity_data.data.find(item => item.id === formValue.activity_id);
                scaleOptions = selected_activity.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            } else {
                let activity_id_skp =
                    this.state.tempSKPData !== null && this.state.tempSKPData.activity !== null
                        ? this.state.tempSKPData.activity.value
                        : this.state.skp_detail.activity_id;
                activity_id_skp = activity_id_skp === undefined ? this.state.skp_detail.activity_id : activity_id_skp;
                console.log(activity_id_skp)
                selected_activity = listActivity_data.data.find(item => item.id === activity_id_skp);
                scaleOptions = selected_activity.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            }
            if (scale) {
                selected_scale = selected_activity.childrens.find(item => item.id === scale.value)
                positionOptions = selected_scale.childrens.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
            }
            if (last_activity_skp_detail_id) {
                selected_position = selected_scale.childrens.find(item => item.id === last_activity_skp_detail_id.value)
                SALOptions = selected_position.values.map((item) => ({
                    label: item.label,
                    value: item.id
                }));
            }
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <ModalDetailDokumenSKP
                    show={this.state.modalDetailDokumenSKPShow}
                    onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <ModalDetailDokumen
                    show={this.state.modalDetailDokumenShow}
                    onHide={() => this.setState({ modalDetailDokumenShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="activity_date"
                                    placeholder="Masukkan Tanggal Kegiatan"
                                    label="Tanggal Kegiatan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="management_name"
                                    placeholder="Masukkan Nama Kepengurusan"
                                    label="Nama Kepengurusan"
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="scale"
                                    placeholder="Pilih Skala Pertemuan"
                                    label="Skala Pertemuan"
                                    options={scaleOptions}
                                    onChange={this.handleChangeScale}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="last_activity_skp_detail_id"
                                    placeholder="Pilih Jabatan"
                                    label="Jabatan"
                                    options={positionOptions}
                                    onChange={this.handleChangePosition}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="values_id"
                                    placeholder="Pilih Scientific Activities Level"
                                    label="Scientific Activities Level"
                                    options={SALOptions}
                                    onChange={this.handleChangeSAL}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <div className={css(styles.detailText)}>
                                    <span className={css(styles.spanDetail)}><strong>DO:</strong> Scientific activities directly related to Orthopaedic Surgeon</span>
                                </div>
                                <div className={css(styles.detailText)}>
                                    <span className={css(styles.spanDetail)}><strong>DM:</strong> Scientific activities NOT directly related but relevant to medical practice of Orthopaedic Surgeon</span><br></br>
                                </div>
                                <div className={css(styles.detailText)}>
                                    <span className={css(styles.spanDetail)}><strong>N:</strong> Scientific activities that are not related to medical practice to the approval of CME/CPD committee</span><br></br>
                                </div>

                                <Field
                                    name="period"
                                    placeholder="Masukkan Periode"
                                    label="Periode"
                                    options={yearOptions}
                                    isClearable={false}
                                    component={renderSelectWithLabel}
                                />
                                <Field
                                    name="skp_total"
                                    placeholder="Jumlah SKP"
                                    label="Jumlah SKP"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="berkas"
                                    type="file"
                                    fileType="skp"
                                    title="Keterangan Dokumen"
                                    disabled={this.state.berkas_button}
                                    isDone={this.state.isDoneUploading}
                                    message={this.props.formValue.berkas === undefined ? "Image atau PDF maksimal 1 MB" : this.state.berkas === null ? "Image atau PDF maksimal 1 MB" : this.state.berkas}
                                    onChange={this.handleUploadFile}
                                    component={renderFileForm}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
    detailText: {
        fontSize: 11,
        marginBottom: 5
    },
    spanDetail: {
        marginBottom: 5
    }
})

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { activity_date, management_name, last_activity_skp_detail_id, values_id, period, berkas, scale, spek_berkas } = values;
    const errors = {}
    if (!activity_date) {
        errors.activity_date = "Tanggal kegiatan wajib diisi"
    }
    if (!management_name) {
        errors.management_name = "Nama kepengurusan wajib diisi"
    }
    if (!last_activity_skp_detail_id) {
        errors.last_activity_skp_detail_id = "Jabatan wajib diisi"
    }
    if (!values_id) {
        errors.values_id = "Scientific Activities Level wajib diisi"
    }
    if (!period) {
        errors.period = "Periode wajib diisi"
    }
    if (!scale) {
        errors.scale = "Skala Pertemuan wajib diisi"
    }
    if (!berkas) {
        errors.berkas = "Berkas wajib diisi"
    }
    else{
        if (spek_berkas && (!spek_berkas.type.match(typeImage) && spek_berkas.type !== fileType)){
            errors.berkas = "Format file hanya bisa Image atau PDF"
        }
        else if (spek_berkas && (spek_berkas.size > maxFileSize)){
            errors.berkas = "File lebih dari 1 MB"
        }
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddDetailSKP_KepengurusanProfesi'));

ModalEditDetailSKP_KepengurusanProfesi = reduxForm({
    form: "ModalEditDetailSKP_KepengurusanProfesi",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalEditDetailSKP_KepengurusanProfesi)

function mapStateToProps(state) {
    const { listActivity, uploadP2kb, selectedSKP, editDetailP2kb } = state;
    const selector = formValueSelector("ModalEditDetailSKP_KepengurusanProfesi");
    const formValue = selector(state, "skp_total", "scale", "last_activity_skp_detail_id", "values_id", "berkas", "no", "activity_id", "isDetail", "id", "status", "spek_berkas")
    let initialValues = {};
    let form_json = null;
    if (selectedSKP) {
        if (selectedSKP.isEditProfesi) {
            if (!selectedSKP.isDetail) {
                initialValues = {
                    activity_date: selectedSKP.activity_date,
                    management_name: selectedSKP.management_name,
                    scale: {
                        label: selectedSKP.scale.label,
                        value: selectedSKP.scale.value,
                    },
                    last_activity_skp_detail_id: {
                        label: selectedSKP.last_activity_skp_detail_id.label,
                        value: selectedSKP.last_activity_skp_detail_id.value
                    },
                    values_id: {
                        label: selectedSKP.values_id.label,
                        value: selectedSKP.values_id.value,
                    },
                    period: {
                        label: selectedSKP.period,
                        value: selectedSKP.period
                    },
                    skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                    berkas: selectedSKP.attachments[0].filename,
                    no: selectedSKP.no
                }
            } else {
                if (selectedSKP.form_json) {
                    form_json = JSON.parse(selectedSKP.form_json)
                    initialValues = {
                        status: selectedSKP.status,
                        isDetail: true,
                        id: selectedSKP.id,
                        activity_id: selectedSKP.mst_activity_skp_type_detail_data ? selectedSKP.mst_activity_skp_type_detail_data.activity_id : selectedSKP.activity_id,
                        activity_date: selectedSKP.activity_date,
                        management_name: form_json.management_name,
                        scale: {
                            label: selectedSKP.mst_activity_skp_type_detail_data.parent.name,
                            value: selectedSKP.mst_activity_skp_type_detail_data.parent.id,
                        },
                        last_activity_skp_detail_id: {
                            label: selectedSKP.mst_activity_skp_type_detail_data.name,
                            value: selectedSKP.mst_activity_skp_type_detail_data.id
                        },
                        values_id: {
                            label: selectedSKP.activity_skp_type_value_data.label,
                            value: selectedSKP.activity_skp_type_value_data.id
                        },
                        period: {
                            label: form_json.period,
                            value: form_json.period
                        },
                        skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                        berkas: selectedSKP.attachments[0].filename,
                        no: selectedSKP.no
                    }
                } else {
                    initialValues = {
                        status: selectedSKP.status,
                        isDetail: true,
                        id: selectedSKP.id,
                        activity_id: selectedSKP.mst_activity_skp_type_detail_data ? selectedSKP.mst_activity_skp_type_detail_data.activity_id : selectedSKP.activity_id,
                        activity_date: selectedSKP.activity_date,
                        management_name: selectedSKP.management_name,
                        scale: {
                            label: selectedSKP.scale.label,
                            value: selectedSKP.scale.value,
                        },
                        last_activity_skp_detail_id: {
                            label: selectedSKP.last_activity_skp_detail_id.label,
                            value: selectedSKP.last_activity_skp_detail_id.value
                        },
                        values_id: {
                            label: selectedSKP.values_id.label,
                            value: selectedSKP.values_id.value
                        },
                        period: {
                            label: selectedSKP.period.label,
                            value: selectedSKP.period.value
                        },
                        skp_total: selectedSKP.skp ? selectedSKP.skp : selectedSKP.total_skp,
                        berkas: selectedSKP.attachments[0].filename,
                        no: selectedSKP.no
                    }

                }
            }
        }
    }
    return {
        listActivity_pending: getActivityPending(listActivity),
        listActivity_data: getListActivity(listActivity),
        listActivity_error: getActivityError(listActivity),
        uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
        uploadP2kb_data: getP2kbUpload(uploadP2kb),
        uploadP2kb_error: getP2kbUploadError(uploadP2kb),
        editDetailP2kb_pending: getP2kbEditDetailPending(editDetailP2kb),
        editDetailP2kb_data: getP2kbEditDetail(editDetailP2kb),
        editDetailP2kb_error: getP2kbEditDetailError(editDetailP2kb),
        formValue,
        initialValues
    }
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, editDetailP2kb })(withRouter(ModalEditDetailSKP_KepengurusanProfesi));