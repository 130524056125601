import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { Route, Switch } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import StatusComponent from "../../components/global-components/StatusComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import UmumPPDS from "./UmumPPDS";
import PendidikanPPDS from "./PendidikanPPDS";
import InformasiPPDS from "./InformasiPPDS";
import { detailPpds } from "../../actions/member/ppds_action";
import { withRouter } from "react-router-dom";

import {
  getPpdsPending,
  getDetailPpds,
  getPpdsError,
} from "../../reducers/member/ppds_reducer";
import { connect } from "react-redux";

class DetailPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailPpds } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailPpds(id);
  }

  setSelectedItem(item) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ selectedItem: item });
    this.props.history.push(`/ppds/detail/${id}/${item}`);
  }
  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { detailPpds_data } = this.props;
    let data = {};
    if (detailPpds_data && detailPpds_data.data.length !== 0) {
      data = detailPpds_data.data[0];
    }
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={data.name} />
            <StatusComponent backgroundColor="#2CB96A" status="Aktif" />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("umum")}
              menuName="Informasi Umum"
              isActive={this.state.selectedItem === "umum"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pendidikan")}
              menuName="Pendidikan Asal"
              isActive={this.state.selectedItem === "pendidikan"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("informasi")}
              menuName="Informasi PPDS"
              isActive={this.state.selectedItem === "informasi"}
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <Switch>
              <Route path="/ppds/detail/:id/umum" exact component={UmumPPDS} />
              <Route
                path="/ppds/detail/:id/pendidikan"
                exact
                component={PendidikanPPDS}
              />
              <Route
                path="/ppds/detail/:id/informasi"
                exact
                component={InformasiPPDS}
              />
            </Switch>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function mapStateToProps({ detailPpds }) {
  return {
    detailPpds_data: getDetailPpds(detailPpds),
    detailPpds_error: getPpdsError(detailPpds),
    detailPpds_pending: getPpdsPending(detailPpds),
  };
}

export default connect(mapStateToProps, { detailPpds })(withRouter(DetailPPDS));
