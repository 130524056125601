import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

function DetailMemberSubspecialistComponent(props) {
  const { subspecialist } = props;
  return <h3 className={css(styles.detailMemberSubspecialist)}> {subspecialist} </h3>;
}

DetailMemberSubspecialistComponent.propTypes = {
  subspecialist: string,
};

const styles = StyleSheet.create({
  detailMemberSubspecialist: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#7F8388",
    marginTop: 20
  },
});

export default DetailMemberSubspecialistComponent;