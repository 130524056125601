import React, { Component } from "react";
import { Row } from 'simple-flexbox'
import { connect } from "react-redux";
import CardDokterSubspesialis from "../../components/dasboard/CardDokterSubspesialis";
import CardRegistrasiPPDS from "../../components/dasboard/CardRegistrasiPPDS";
import CardKelulusanPaboi from "../../components/dasboard/CardKelulusanPaboi";
import CardOrthopaedi from "../../components/dasboard/CardOrthopaedi";
import CardPertumbuhanOrtho from "../../components/dasboard/CardPertumbuhanOrtho";
import CardMaps from "../../components/dasboard/CardMapsKolegium";
import CardPPDS from "../../components/dasboard/CardPPDS";
import CardPenerimaanPPDS from "../../components/dasboard/CardPenerimaanPPDS";

import { listSubspecialist, listCandidate, listGraduated, listOrthopaedi } from '../../actions/dashboard/kolegium_action'
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer'
class DashboardAdminPusat extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }



    render() {
        return (
            <>
                <Row alignItems='stretch' style={{ marginBottom: 30 }}>
                    <div style={{ width: '60%', paddingRight: 20, }}>
                        <div style={{ backgroundColor: "#FFC156", height: 10, width: "100%" }}></div>
                        <CardPenerimaanPPDS />
                        <div style={{ backgroundColor: "#00A4ED", height: 10, width: "100%" }}></div>
                        <CardPertumbuhanOrtho />
                    </div>
                    <div style={{ width: "40%" }}>
                        <CardDokterSubspesialis />
                    </div>
                </Row>

                {/* <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '100%', }}>
                        <div style={{ height: "35%", }}>
                            <div style={{ backgroundColor: "#FFC156", height: 10, width: "100%" }}></div>
                            <CardPenerimaanPPDS />
                        </div>
                    </div>
                </Row> */}
                {/* <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '100%', }}>
                        <div style={{ height: "35%", }}>
                            <div style={{ backgroundColor: "#5AB853", height: 10, width: "100%" }}></div>
                            <CardOrthopaedi />
                        </div>
                    </div>
                </Row>
                 */}{/* <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '100%', }}>
                        <div style={{ height: "35%", }}>

                        </div>
                    </div>
                </Row> */}
                <Row horizontal="space-between" alignItems="stretch" style={{ marginBottom: 20 }}>
                    <div style={{ padding: "0 10px", width: '100%', }}>
                        <div style={{ height: "35%", }}>
                            <div style={{ backgroundColor: "#5AB853", height: 10, width: "100%" }}></div>
                            <CardPPDS />
                        </div>
                    </div>
                </Row>
            </>
        )
    }
}

function mapStateToProps({
    listCandidate,
    listGraduated,
    listOrthopaedi,
    listSubspecialist }) {
    return {
        listCandidate_data: getDashboardData(listCandidate),
        listGraduated_data: getDashboardData(listGraduated),
        listOrthopaedi_data: getDashboardData(listOrthopaedi),
    };
}

export default connect(mapStateToProps, {
    listSubspecialist, listCandidate, listGraduated, listOrthopaedi
})(DashboardAdminPusat);
