import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastSuccess } from "../../components/toast/Toast";

export const MEMBER_VERIFY_PASSWORD_PENDING = 'MEMBER_VERIFY_PASSWORD_PENDING'
export const MEMBER_VERIFY_PASSWORD_SUCCESS = 'MEMBER_VERIFY_PASSWORD_SUCCESS'
export const MEMBER_VERIFY_PASSWORD_ERROR = 'MEMBER_VERIFY_PASSWORD_ERROR'

const MEMBER_VERIFY_PASSWORD_URL = 'auth/verify_password';

export function memberVerifyPassword(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(MEMBER_VERIFY_PASSWORD_PENDING));
        API.post(MEMBER_VERIFY_PASSWORD_URL, param)
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(MEMBER_VERIFY_PASSWORD_SUCCESS, res.data));
                if (resolve) {
                    resolve({
                        data: res.data.data,
                    })
                }
                toastSuccess("Password baru anda berhasil dibuat");
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(MEMBER_VERIFY_PASSWORD_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}