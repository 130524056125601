import {
    LIST_UNIVERSITY_PENDING,
    LIST_UNIVERSITY_SUCCESS,
    LIST_UNIVERSITY_ERROR,
    EDIT_UNIVERSITY_PENDING,
    EDIT_UNIVERSITY_SUCCESS,
    EDIT_UNIVERSITY_ERROR,
    DELETE_UNIVERSITY_PENDING,
    DELETE_UNIVERSITY_SUCCESS,
    DELETE_UNIVERSITY_ERROR,
    ADD_UNIVERSITY_PENDING,
    ADD_UNIVERSITY_SUCCESS,
    ADD_UNIVERSITY_ERROR,
} from "../../actions/master-data/university_action";

const initialState = {
    pending: false,
    error: null
};

export function universityList(
    state = { ...initialState, universityList: null },
    action
) {
    switch (action.type) {
        case LIST_UNIVERSITY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_UNIVERSITY_SUCCESS:
            return {
                ...state,
                pending: false,
                universityList: action.data,
            };
        case LIST_UNIVERSITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addUniversity(
    state = { ...initialState, addUniversity: null },
    action
) {
    switch (action.type) {
        case ADD_UNIVERSITY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_UNIVERSITY_SUCCESS:
            return {
                ...state,
                pending: false,
                addUniversity: action.data,
            };
        case ADD_UNIVERSITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editUniversity(
    state = { ...initialState, editUniversity: null },
    action
) {
    switch (action.type) {
        case EDIT_UNIVERSITY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_UNIVERSITY_SUCCESS:
            return {
                ...state,
                pending: false,
                editUniversity: action.data,
            };
        case EDIT_UNIVERSITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function deleteUniversity(
    state = { ...initialState, deleteUniversity: null },
    action
) {
    switch (action.type) {
        case DELETE_UNIVERSITY_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_UNIVERSITY_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteUniversity: action.data,
            };
        case DELETE_UNIVERSITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getUniversityListPending = (state) => state.pending;
export const getUniversityList = (state) => state.universityList;
export const getUniversityListError = (state) => state.error;
export const getUniversityAddPending = (state) => state.pending;
export const getUniversityAdd = (state) => state.addUniversity;
export const getUniversityAddError = (state) => state.error;
export const getUniversityEditPending = (state) => state.pending;
export const getUniversityEdit = (state) => state.editUniversity;
export const getUniversityEditError = (state) => state.error;
export const getUniversityDeletePending = (state) => state.pending;
export const getUniversityDelete = (state) => state.deleteUniversity;
export const getUniversityDeleteError = (state) => state.error;