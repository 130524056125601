import {
  LIST_SUBSPESIALIS_PENDING,
  LIST_SUBSPESIALIS_SUCCESS,
  LIST_SUBSPESIALIS_ERROR,
  DETAIL_SUBSPESIALIS_PENDING,
  DETAIL_SUBSPESIALIS_SUCCESS,
  DETAIL_SUBSPESIALIS_ERROR,
  EDIT_SUBSPESIALIS_PENDING,
  EDIT_SUBSPESIALIS_SUCCESS,
  EDIT_SUBSPESIALIS_ERROR,
  DELETE_SUBSPESIALIS_PENDING,
  DELETE_SUBSPESIALIS_SUCCESS,
  DELETE_SUBSPESIALIS_ERROR,
  ADD_SUBSPESIALIS_PENDING,
  ADD_SUBSPESIALIS_SUCCESS,
  ADD_SUBSPESIALIS_ERROR,
} from "../../actions/master-data/subspesialis_action";

const initialState = {
  pending: false,
  error: null,
};

export function listSubspesialis(
  state = { ...initialState, list_subspesialis: null },
  action
) {
  switch (action.type) {
    case LIST_SUBSPESIALIS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SUBSPESIALIS_SUCCESS:
      return {
        ...state,
        pending: false,
        list_subspesialis: action.data,
      };
    case LIST_SUBSPESIALIS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailSubspesialis(
  state = { ...initialState, detail_subspesialis: null },
  action
) {
  switch (action.type) {
    case DETAIL_SUBSPESIALIS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_SUBSPESIALIS_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_subspesialis: action.data,
      };
    case DETAIL_SUBSPESIALIS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editSubspesialis(state = initialState, action) {
  switch (action.type) {
    case EDIT_SUBSPESIALIS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SUBSPESIALIS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_SUBSPESIALIS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addSubspesialis(state = initialState, action) {
  switch (action.type) {
    case ADD_SUBSPESIALIS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_SUBSPESIALIS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_SUBSPESIALIS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteSubspesialis(state = initialState, action) {
  switch (action.type) {
    case DELETE_SUBSPESIALIS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SUBSPESIALIS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_SUBSPESIALIS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListSubspesialis = (state) => state.list_subspesialis;
export const getDetailSubspesialis = (state) => state.detail_subspesialis;
export const getSubspesialisPending = (state) => state.pending;
export const getSubspesialisError = (state) => state.error;
