import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { Paper } from "@material-ui/core";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import HeaderMember from "./Header_member";
import PaginationComponent from "../../components/table/PaginationComponent";

class ListMember extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      branch_id: null,
      status: null,
      columns: [
        {
          title: "No",
          field: "name",
          render: (rowData) => {
            return <>{rowData.no}</>;
          },
          width: 20,
          headerStyle: {
            paddingLeft: 40,
          },
          cellStyle: {
            paddingLeft: 40,
          },
        },
        { title: "Nama", field: "name" },
        { title: "No ID PABOI", field: "member_profile.paboi_id" },
        {
          title: "Tempat Praktek",
          render: ({ member_profile }) => {
            const office_address = member_profile ? member_profile.office_address : null;
            if (member_profile && office_address !== null && office_address !== "") {
              return office_address
            } else {
              return "-"
            }
          }
        },
        {
          title: "Cabang",
          render: ({ branch_data }) => {
            const branch_name = branch_data ? branch_data.name : null;
            if (branch_data && branch_name !== null && branch_name !== "") {
              return branch_name
            } else {
              return "-"
            }
          }
        },
        { title: "Total SKP", field: "member_profile.total_skp" },
        {
          title: "Status",
          field: "member_status",
          width: "12%",
          render: ({ member_status }) => {
            return (
              <TableStatusComponent
                status={
                  member_status === 0
                    ? "Nonaktif"
                    : member_status === 1
                      ? "Aktif"
                      : member_status === 2
                        ? ">65 Tahun"
                        : "Wafat"
                }
              />
            );
          },
        },
        {
          title: "",
          render: (rowData) => {
            return (
              <DetailButtonComponent>
                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                  Detail
                </MenuItem>
              </DetailButtonComponent>
            );
          },
        },
      ],
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
   }

  detailRecord(id) {
    this.props.history.push(`/member/detail/profile/${id}/pengantar`);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  shouldComponentRender() {
    const { memberList_pending } = this.props;
    if (memberList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { memberList, memberList_data } = this.props;
    let data = [];
    let records_total = "";
    let length = "";
    if (memberList_data) {
      data = memberList_data;
      records_total = data.records_total;
      length = data.data.length;
    }
    return (
      <ContentContainer>
        <HeaderMember handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          title="Member"
          columns={this.state.columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                status: this.state.status,
                branch: this.state.branch,
                id: "",
                branch_id: this.state.branch_id,
              };
              memberList(param, resolve, reject);
            })
          }
          options={{
            tableLayout: "auto",
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ memberList }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
  };
}

export default connect(mapStateToProps, { memberList })(withRouter(ListMember));
