import React, { Component } from "react";
import { Row } from "simple-flexbox";

import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
// import { formValueSelector } from "redux-form"; // ES6

import {
  detailMutation,
  editMutation,
  setIsEditFile,
} from "../../actions/mutation/mutation_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getMutationPending,
  getDetailMutation,
  getMutationError,
  getMutationIsEditFile,
} from "../../reducers/mutation/mutation_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { listHospital } from "../../actions/master-data/hospital_action";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import MutationModalApprove from "./MutationModalApprove";
import MutationModalReject from "./MutationModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import { default_api } from "../../constants/default_api";


class MutationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { detailMutation } = this.props;
    const { listHospital, listBranch } = this.props;
    listHospital();
    listBranch();
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailMutation(id);
  }

  shouldComponentRender() {
    const { regimutationationList_pending } = this.props;
    if (regimutationationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editMutation_isEditFile, setIsEditFile } = this.props;
    if (editMutation_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editMutation_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title, folder = "") {
    if (modal_title === "CV") {
      window.open(default_api + "/member/download/cv/" + imgUrl + ".pdf");
    } else {
      this.setState({ folder: folder })
      this.setState({ imgUrl: imgUrl })
      this.setState({ modal_title: modal_title })
      if (imgUrl.split('.')[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true })
      } else {
        this.setState({ modalDocumentImgShow: true })
      }
    }
  }

  handleSubmit(values) {
    const { detailMutation_data, editMutation, detailMutation } = this.props;
    const {
      new_address,
      berkas,
      hospital_id_to,
      file,
      branch_to: { value: branch_to },
    } = values;
    const param = {
      id: detailMutation_data[0].id,
      new_address,
      branch_to,
      type_address: 1,
      surat_pendukung_filename: detailMutation_data[0].surat_pendukung_filename,
      attachments: file.map((item) => ({
        attachment_name: item.name,
        attachment_filename: item.value,
      }))
    };

    if (hospital_id_to) {
      param.hospital_id_to = hospital_id_to.value;
    }
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailMutation();
      this.handleTriggerFormEdit();
    };
    editMutation(param, callback);
  }

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  }

  render() {
    // const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];
    const {
      detailMutation_data,
      editMutation_pending,
      // editMutation_isEditFile,
      handleSubmit,
      listHospital_data,
      listBranch_data,
      initialValues
    } = this.props;
    const {
      isEdit,
      showApproveModal,
      showRejectModal,
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder
    } = this.state;

    let status,
      request_code,
      mutation_role_code,
      mutation_role_name,
      last_reject_notes,
      last_reject_filename,
      ktp_filename,
      str_filename,
      sip_filename,
      serkom_filename,
      surat_pendukung_filename,
      step,
      id,
      updated_at;

    let hospitalOptions = [],
      branchOptions = [];

    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    if (detailMutation_data && detailMutation_data.length !== 0) {
      [
        {
          request_code,
          status,
          updated_at,
          last_reject_notes,
          last_reject_filename,
          ktp_filename,
          str_filename,
          sip_filename,
          surat_pendukung_filename,
          step,
          id,
          serkom_filename,
        },
      ] = detailMutation_data;
      mutation_role_code = detailMutation_data[0].role_next_approve
        ? detailMutation_data[0].role_next_approve.role_code
        : null;
      mutation_role_name = detailMutation_data[0].role_next_approve
        ? detailMutation_data[0].role_next_approve.role_name
        : null;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    return (
      <ContentContainer>
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <MutationModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <MutationModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <div style={{ marginBottom: 12, color: "#495057" }}>No Surat</div>
            <DetailNameComponent name={request_code} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "telah diajukan pada " +
                  moment(updated_at).format("DD MMMM YYYY")
                  : status === 1
                    ? "telah disetujui pada " +
                    moment(updated_at).format("DD MMMM YYYY")
                    : "Pengajuan mutasi anggota telah ditolak. Ditolak pada " +
                    moment(updated_at).format("DD MMMM YYYY")
              }
              statusTitle={
                status === 0
                  ? (step === 1 || step === 2) ? "Diproses oleh " + mutation_role_name + " Asal" : "Diproses oleh " + mutation_role_name + " Tujuan"
                  : status === 1
                    ? "Disetujui"
                    : "Ditolak oleh " + mutation_role_name
              }
              status={status}
              filename={last_reject_filename}
              filepath="member_branch_mutation"
              reject_notes={last_reject_notes}
            />
            {getUser().role_codes[0] === mutation_role_code && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Mutasi Cabang" />
                {status === 2 &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editMutation_pending}
                  />
                }
              </Row>
              <FormContainer label="Tanggal Pengajuan">
                <Field
                  name="created_at"
                  disabled
                  isDetail={!isEdit}
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="ID Member">
                <Field
                  name="paboi_id"
                  disabled
                  isDetail={!isEdit}
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Cabang Asal">
                <Field
                  name="branch_from"
                  disabled
                  isDetail={!isEdit}
                  placeholder="Masukkan Cabang Asal"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Cabang Tujuan">
                <Field
                  isDetail={!isEdit}
                  name="branch_to"
                  placeholder="Pilih cabang tujuan"
                  options={branchOptions}
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Tempat Praktik Tujuan">
                <Field
                  isDetail={!isEdit}
                  name="hospital_id_to"
                  placeholder="Pilih termpat praktik tujuan"
                  options={hospitalOptions}
                  isAsync
                  asyncUrl="master_data/hospital/find"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Alamat Rumah Baru">
                <Field
                  isDetail={!isEdit}
                  name="new_address"
                  placeholder="Masukkan alamat rumah"
                  component={renderInput}
                />
              </FormContainer>
              <label className={css(styles.detailSectionName)}>Berkas</label>
              <DetailContentComponent label="Surat Permohonan Tertulis" value={surat_pendukung_filename !== null ? <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(surat_pendukung_filename, "Surat Permohonan Tertulis", "member_branch_mutation")} /> : "-"} />
              <DetailContentComponent label="Berkas KTP" value={ktp_filename !== null ? <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(ktp_filename, "KTP", "member_supporting_files")} /> : "-"} />
              <DetailContentComponent label="Berkas CV" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(getUser().id, "CV")} />} />
              <DetailContentComponent label="Berkas STR" value={str_filename !== null ? <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(str_filename, "STR", "member_str")} /> : "-"} />
              <DetailContentComponent label="Berkas SIP" value={sip_filename !== null ? <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(sip_filename, "SIP", "member_sip")} /> : "-"} />
              <DetailContentComponent label="Berkas Serkom" value={serkom_filename !== null ? <DetailContentLihatBerkas onClick={() => this.handleOpenDocument(serkom_filename, "Serkom", "member_serkom")} /> : "-"} />
              {isEdit ?
                <FormContainer
                  label="Berkas"
                  contentValueStyle={{ margin: "20px 0" }}
                >
                  <FieldArray
                    name="file"
                    isDetail={!isEdit}
                    component={DynamicFormComponent}
                    changeFileValue={this.changeFileValue}
                    initialValues={initialValues}
                    folderPath="member_branch_mutation"
                  />
                </FormContainer>
                :
                <FieldArray
                  name="file"
                  isDetail={!isEdit}
                  component={DynamicFormComponent}
                  changeFileValue={this.changeFileValue}
                  initialValues={initialValues}
                  folderPath="member_branch_mutation"
                />
              }
              {status === 1 && getUser().role_codes[0] === "member" && (
                <>
                  <label className={css(styles.detailSectionName)}>Lampiran</label>
                  <FormContainer label="Surat Lampiran 1">
                    <Field
                      isDetail={!isEdit}
                      isSuratRekomen={true}
                      filename={id}
                      filePath="mutation_request/member"
                      name="berkas"
                      type="file"
                      component={renderFile}
                    />
                  </FormContainer>
                </>
              )}
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20
  }
});

function validate(values, { editMutation_isEditFile }) {
  const {
    sip_no,
    str_no,
    new_address,
    berkas,
    branch_to,
    // file
  } = values;
  const errors = {};
  // const fileArrayErrors = [];
  if (!branch_to) {
    errors.branch_to = "Cabang tujuan wajib diisi";
  }
  if (sip_no || str_no) {
    if (!sip_no) {
      errors.sip_no = "Nomor SIP wajib diisi";
    }
    if (!str_no) {
      errors.str_no = "Nomor STR wajib diisi";
    }
  }
  if (new_address) {
    if (!new_address) {
      errors.new_address = "Alamat Baru wajib diisi";
    }
  }
  if (editMutation_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  // if (!file || !file.length) {
  //   errors.file = {
  //     _error: "Berkas tidak boleh kosong"
  //   }
  // } else {
  //   file.forEach((item, index) => {
  //     if (!item) {
  //       fileArrayErrors[index] = "Berkas tidak boleh kosong"
  //     }
  //   })
  // }
  // if (fileArrayErrors.length) {
  //   errors.file = fileArrayErrors;
  // }
  return errors;
}

MutationDetail = reduxForm({
  // a unique name for the form
  form: "MutationDetail",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(MutationDetail);

function mapStateToProps(state) {
  const { detailMutation, editMutation, listHospital, listBranch } = state;
  let initialValues = {};
  const detailMutation_data = getDetailMutation(detailMutation);
  if (detailMutation_data && detailMutation_data.length !== 0) {
    const data = detailMutation_data[0];
    initialValues = {
      created_at: moment(data.created_at).locale("id", localization).format("DD MMM YYYY"),
      branch_from: data.branch_from_data.name,
      paboi_id: data.member_data.member_profile.paboi_id,
      name: data.member_data.name,
      hospital_id_to: {
        label: data.hospital_to_data.name,
        value: data.hospital_to_data.id
      },
      branch_to: {
        label: data.branch_to_data.name,
        value: data.branch_to_data.id
      },
      new_address: data.new_address,
      file: data.branch_mutation_attachments.map(item => ({
        value: item.attachment_filename,
        name: item.attachment_name
      }))
    }
  }

  return {
    detailMutation_data: getDetailMutation(detailMutation),
    detailMutation_error: getMutationError(detailMutation),
    detailMutation_pending: getMutationPending(detailMutation),
    editMutation_error: getMutationError(editMutation),
    editMutation_pending: getMutationPending(editMutation),
    editMutation_isEditFile: getMutationIsEditFile(editMutation),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailMutation,
  editMutation,
  setIsEditFile,
  listHospital,
  listBranch,
})(withRouter(MutationDetail));
