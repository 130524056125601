import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { deleteAdvertisement } from "../../actions/advertisement/advertisement_action";
import { getAdvertisementDelete } from "../../reducers/advertisement/advertisement_reducer";
class ModalDeleteAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    deleteAdvertisement() {
        const { deleteAdvertisement, onHide, handleRefresh } = this.props;
        const param = {
            id: this.props.id
        }

        const callback = () => {
            onHide();
            handleRefresh();
        }
        deleteAdvertisement(param, callback);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Hapus Iklan
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menghapus iklan ini?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<ClearIcon />}
                            onClick={this.props.onHide}
                        >
                            Tidak
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<CheckIcon />}
                            onClick={() => this.deleteAdvertisement()}
                        >
                            Ya
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

function mapStateToProps({ deleteAdvertisement }) {
    return {
        deleteAdvertisement: getAdvertisementDelete(deleteAdvertisement),
    };
}


export default connect(mapStateToProps, { deleteAdvertisement })(ModalDeleteAdvertisement);