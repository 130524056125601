import React from "react"
import ContentLoader from "react-content-loader"

export default (props) => {
  const { parentwidth, childwidth, parentheight, childheight } = props;
  return (
    <ContentLoader
      speed={2}
      width={parentwidth ? parentwidth : 100}
      height={parentheight ? parentheight : 25}
      // viewBox="0 0 100 30"
      backgroundColor="#f3f3f3"
      foregroundColor="#FFFFFF"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width={childwidth ? childwidth : 50} height={childheight ? childheight : 25} />
    </ContentLoader>
  )
}