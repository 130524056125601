import {
    LIST_PPDS_PENDING,
    LIST_PPDS_SUCCESS,
    LIST_PPDS_ERROR,
    LIST_PPDSGO_PENDING,
    LIST_PPDSGO_SUCCESS,
    LIST_PPDSGO_ERROR,
    LIST_PPDSACCEPTANCE_PENDING,
    LIST_PPDSACCEPTANCE_SUCCESS,
    LIST_PPDSACCEPTANCE_ERROR,


} from "../../actions/dashboard/ppds_action";

const initialState = {
    pending: false,
    error: null,
};

export function listPPDSGrafik(
    state = { ...initialState, list_PPDSGrafik: null },
    action
) {
    switch (action.type) {
        case LIST_PPDS_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_PPDS_SUCCESS:
            return {
                ...state,
                pending: false,
                list_PPDSGrafik: action.data.data,
            };
        case LIST_PPDS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function listPPDSAcceptance(
    state = { ...initialState, list_PPDSAcceptance: null },
    action
) {
    switch (action.type) {
        case LIST_PPDSACCEPTANCE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_PPDSACCEPTANCE_SUCCESS:
            return {
                ...state,
                pending: false,
                list_PPDSAcceptance: action.data,
            };
        case LIST_PPDSACCEPTANCE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function listPPDSGO(
    state = { ...initialState, list_PPDSGO: null },
    action
) {
    switch (action.type) {
        case LIST_PPDSGO_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_PPDSGO_SUCCESS:
            return {
                ...state,
                pending: false,
                list_PPDSGO: action.data,
            };
        case LIST_PPDSGO_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListPPDSGrafik = (state) => state.list_PPDSGrafik;
export const getListPPDSGO = (state) => state.list_PPDSGO;
export const getlistPPDSAcceptance = (state) => state.list_PPDSAcceptance;
export const getListPPDSPending = (state) => state.pending;
export const getListPPDSError = (state) => state.error;
