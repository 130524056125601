import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { provinceList } from "../../actions/master-data/province_action";
import {
  getProvinceListPending,
  getProvinceList,
  getProvinceListError,
} from "../../reducers/master-data/province_reducer";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 150,
    marginLeft: 10,
    zIndex: 999
  },
  provinceContainer: {
    width: 200,
    marginLeft: 10,
    zIndex: 999
  },
});
class HeaderHospital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      class_type: "",
      province_id: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClassTypeChange = this.handleClassTypeChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {
    let param_provinceList = {
      length: 999,
    };
    this.props.provinceList(param_provinceList);
  }

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
    // this.props.handleRefresh({ search: e.target.value });
  }

  // handleSearchChange = _.debounce((e) => {
  // }, 1000);

  handleClassTypeChange(value) {
    // this.setState({ faculty: value });
    if (value) {
      this.props.handleRefresh({ class_type: value.value });
    } else {
      this.props.handleRefresh({ class_type: "" });
    }
  }

  handleProvinceChange(value) {
    // this.setState({ faculty: value });
    if (value) {
      this.props.handleRefresh({ province_id: value.value });
    } else {
      this.props.handleRefresh({ province_id: "" });
    }
  }

  render() {
    let provinceOption = [];
    let classOption = [
      {
        label: "Kelas A",
        value: "A",
      },
      {
        label: "Kelas B",
        value: "B",
      },
      {
        label: "Kelas C",
        value: "C",
      },
      {
        label: "Kelas D",
        value: "D",
      },
    ];
    const { provinceList_data } = this.props;
    if (provinceList_data) {
      provinceOption = provinceList_data.map((value, index) => {
        const province = { value: value.province_id, label: value.name };
        return province;
      });
    }
    const { handleOpenModalAdd } = this.props;
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={handleOpenModalAdd}
          >
            tambah rs
          </Button>
        </div>
        <Row>
          <div className={css(styles.container)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.tes}
              placeholder="Cari Nama RS"
              startIcon={SearchIcon}
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              options={classOption}
              placeholder="Kelas RS"
              onChange={this.handleClassTypeChange}
            />
          </div>
          <div className={css(styles.provinceContainer)}>
            <SelectComponent
              options={provinceOption}
              placeholder="Provinsi"
              onChange={this.handleProvinceChange}
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps({ provinceList }) {
  return {
    provinceList_pending: getProvinceListPending(provinceList),
    provinceList_data: getProvinceList(provinceList),
    provinceList_error: getProvinceListError(provinceList),
  };
}

export default connect(mapStateToProps, { provinceList })(HeaderHospital);
