import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const REGISTRATION_LIST_PENDING = 'REGISTRATION_LIST_PENDING'
export const REGISTRATION_LIST_SUCCESS = 'REGISTRATION_LIST_PENDING_SUCCESS'
export const REGISTRATION_LIST_ERROR = 'REGISTRATION_LIST_PENDING_ERROR'
export const REGISTRATION_VERIFICATION_PENDING = 'REGISTRATION_VERIFICATION_PENDING'
export const REGISTRATION_VERIFICATION_SUCCESS = 'REGISTRATION_VERIFICATION_PENDING_SUCCESS'
export const REGISTRATION_VERIFICATION_ERROR = 'REGISTRATION_VERIFICATION_PENDING_ERROR'
export const PPDS_STATUS_APPROVAL_PENDING = 'REGISTRATION_VERIFICATION_PENDING'
export const PPDS_STATUS_APPROVAL_SUCCESS = 'REGISTRATION_VERIFICATION_PENDING_SUCCESS'
export const PPDS_STATUS_APPROVAL_ERROR = 'REGISTRATION_VERIFICATION_PENDING_ERROR'

const REGISTRATION_LIST_URL = 'member/register_sp2/find';
const REGISTRATION_VERIFICATION_URL = 'member/register_sp2/approve';
const PPDS_STATUS_APPROVAL_URL = 'member/register_sp2/change_status_ppds';

export function registrationList(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(REGISTRATION_LIST_PENDING));
        API.post(REGISTRATION_LIST_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(REGISTRATION_LIST_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item, status_registration_label: item.status_registration === 0 ? "New" : item.status_registration === 1 ? "Approved" : "Reject",
                    ...item, records_total: res.data.records_total,
                    ...item, prodi_tujuan: item.ppds_destination_university_data[0].university_data.abbreviation + '/' + item.ppds_destination_university_data[0].period_selection_month + '/' + item.ppds_destination_university_data[0].period_selection_year,
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(REGISTRATION_LIST_ERROR, error));
                toastError(error, "Gagal mendapatkan list registrasi");
            })
    };
}

export function registrationVerification(param = {}, callback) {
    return dispatch => {
        dispatch(actionPending(REGISTRATION_VERIFICATION_PENDING));
        const config = {
            headers: { 'content-type': 'multipart/form-data', 'Authorization': getHeader().headers.Authorization }
        }
        API.post(REGISTRATION_VERIFICATION_URL, param, config)
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                let data = res.data.data
                dispatch(actionSuccess(REGISTRATION_VERIFICATION_SUCCESS, data));
                if (callback) {
                    callback();
                }
                toastSuccess("Berhasil mengubah status registrasi");
            })
            .catch(error => {
                dispatch(actionError(REGISTRATION_VERIFICATION_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}

export function ppdsStatusApproval(param = {}, callback) {
    return dispatch => {
        dispatch(actionPending(PPDS_STATUS_APPROVAL_PENDING));
        API.post(PPDS_STATUS_APPROVAL_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                let data = res.data.data
                dispatch(actionSuccess(PPDS_STATUS_APPROVAL_SUCCESS, data));
                if (callback) {
                    callback();
                }
                toastSuccess("Berhasil mengubah status ppds");
            })
            .catch(error => {
                dispatch(actionError(PPDS_STATUS_APPROVAL_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}