import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { experienceList } from "../../actions/master-data/experience_action";
import { getExperienceListPending, getExperienceList, getExperienceListError } from "../../reducers/master-data/experience_reducer";
import Button from "@material-ui/core/Button";
import ModalAddPengalaman from "./Modal_add_pengalaman";
import { listCity } from "../../actions/master-data/city_action";
import { getCityListPending, getCityList, getCityListError } from "../../reducers/master-data/city_reducer";
import AddIcon from "@material-ui/icons/Add";

class HeaderPengalaman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            status: "",
            modalAddPengalamanShow: false,
        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleChangeSearch(e) {
        if (e !== null) {
            this.setState({ search: e.target.value });
            this.props.handleRefresh({ search: e.target.value });
        }
    }

    handleChangeStatus(e) {
        if (e !== null) {
            this.setState({ status: e.value });
            this.props.handleRefresh({ status: e.value });
        } else {
            this.props.handleRefresh({ status: "" });
        }
    }

    handleRefresh(state) {
        this.props.handleRefresh(state)
    }

    componentDidMount() {
        let param_listCity = {
            page: 1,
            length: 999
        }
        this.props.listCity(param_listCity)
    }

    render() {
        const { modalAddPengalamanShow } = this.state;
        const statusOption = [
            { value: 0, label: "Baru" },
            { value: 1, label: "Terverifikasi" },
            { value: 2, label: "Ditolak" },
        ];
        const { cityList_data } = this.props;
        let cityOption = [];
        if (cityList_data) {
            let data = cityList_data.data;
            data.map((value, index) => {
                const city = { value: value.city_id, label: value.name }
                cityOption = [...cityOption, city]
                return city;
            })
        }
        return (
            <Row
                style={{ padding: 20, paddingBottom: 30, backgroundColor: "#F8F9FB", alignItems: "center" }}
                horizontal="space-between"
            >
                <ModalAddPengalaman
                    show={modalAddPengalamanShow}
                    onHide={() => this.setState({ modalAddPengalamanShow: false })}
                    cityOption={cityOption}
                    handleRefresh={this.handleRefresh}
                />
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={css(styles.approveButton)}
                        startIcon={<AddIcon />}
                        onClick={() => this.setState({ modalAddPengalamanShow: true })}
                    >
                        Tambah Pengalaman
                </Button>
                </div>
                <Row>
                    <div className={css(styles.container)}>
                        <InputComponent
                            onChange={this.handleChangeSearch}
                            value={this.state.search}
                            placeholder="Cari Nama Institusi"
                            startIcon={SearchIcon}
                            id="search"
                        />
                    </div>
                    <div className={css(styles.container)}>
                        <SelectComponent
                            onChange={this.handleChangeStatus}
                            placeholder="Status"
                            options={statusOption}
                            id="status"
                        />
                    </div>
                </Row>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        marginRight: 10,
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    container: {
        width: 150,
        margin: 10,
        zIndex: 999
    },
});

function mapStateToProps({ experienceList, listCity }) {
    return {
        experienceList_pending: getExperienceListPending(experienceList),
        experienceList_data: getExperienceList(experienceList),
        experienceList_error: getExperienceListError(experienceList),
        cityList_pending: getCityListPending(listCity),
        cityList_data: getCityList(listCity),
        cityList_error: getCityListError(listCity),
    };
}

export default connect(mapStateToProps, { experienceList, listCity })(HeaderPengalaman);