import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListMember from "./List_member";

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = { tes: "" };
    this.tes = this.tes.bind(this);
  }

  tes(e) {
    this.setState({ tes: e.target.value });
  }

  render() {
    return (
      <ContentContainer>
        <ListMember />
      </ContentContainer>
    );
  }
}

export default Member;
