import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { listTotalFeeIn } from '../../actions/dashboard/cabang_action'
import { getDashboardData } from '../../reducers/dashboard/cabang_reducer'
import { connect } from "react-redux";
import CountUp from 'react-countup';
import { getUser } from '../../helpers/user'
import CanvasJSReact from '../../assets/canvas/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class CardDanaEstimasi extends Component {

    componentDidMount() {
        const { listTotalFeeIn } = this.props
        listTotalFeeIn({ branch_id: getUser().branch_id })

    }

    render() {
        let fee_in = 0, outstanding = 0, estimation_total = 0;
        let total_result = 0;
        if (fee_in > 0 && estimation_total > 0){
            total_result = Math.round(fee_in / estimation_total * 100);
        }
        const { listEstimationFee_data } = this.props
        let chart;


        if (listEstimationFee_data) {

            ({ fee_in, outstanding, estimation_total } = listEstimationFee_data)

            const options = {
                animationEnabled: true,
                height: 180,
                width: 180,
                backgroundColor: 'transparent',
                subtitles: [{
                    text: `${total_result}%`,
                    verticalAlign: "center",
                    horizontalAlign: "center",
                    textAlign: 'center',
                    fontSize: 30,
                    fontWeight: 'bold',
                    dockInsidePlotArea: true,
                    fontFamily: "Circular Std Book",
                }],
                data: [{
                    type: "doughnut",
                    dataPoints: [
                        { name: "Masuk", y: fee_in, color: '#F6C269' },
                        { name: "Outstanding", y: outstanding, color: '#FCEEDA' },

                    ]
                }]
            }
            chart = (
                <CanvasJSChart options={options} />
            )

        }
        return (
            <Column style={{
                width: '100%', boxShadow: "0px 3px 6px #00000029",
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                height: '100%'

            }}>
                <div className={css(styles.cardHeader)}></div>
                <Column className={css(styles.wrapper,)} style={{ width: "100%", }}>
                    <div style={{ height: 80, }}>
                        <label className={css(styles.titleLabel)}> Jumlah Dana Masuk<br /> Iuran Anggota</label>
                    </div>

                    <Row style={{ width: "100%", height: '100%' }} horizontal="center">
                        <div style={{ width: 180 }}>
                            {chart}
                        </div>



                    </Row>
                </Column>

                <Column style={{ width: "100%", padding: 15, }} vertical="space-around">
                    <Row style={{ marginBottom: 20 }} horizontal='space-between' vertical="center">
                        <Row vertical="center">
                            <div style={{ height: 20, width: 20, marginRight: 10, backgroundColor: "#F6C269" }} />
                            <div className={css(styles.cardLabel)}>Dana Masuk</div>
                        </Row>
                        <span style={{ fontSize: 20, fontWeight: 'bold', color: '#F6C269' }}>Rp <CountUp end={fee_in} separator="." duration={1} /></span>
                    </Row>
                    <Row style={{}} horizontal='space-between' vertical="center">
                        <Row vertical="center">
                            <div style={{ height: 20, width: 20, marginRight: 10, backgroundColor: "#FCEEDA" }} />
                            <div className={css(styles.cardLabel)}>Dana Outstanding</div>
                        </Row>
                        <span style={{ fontSize: 20, fontWeight: 'bold', color: '#F6C269' }}>Rp <CountUp end={outstanding} separator="." duration={1} /></span>
                    </Row>
                </Column>
            </Column>

        );
    }
}
function mapStateToProps({ listTotalFeeIn }) {
    return {
        listEstimationFee_data: getDashboardData(listTotalFeeIn),
    };
}

export default connect(mapStateToProps, {
    listTotalFeeIn
})(CardDanaEstimasi);


const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: 'bold',
        margin: "5px 0"
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
    wrapper: {
        padding: 15,
        height: 240
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    cardHeader: {
        backgroundColor: "#F6C269",
        height: 10,
        width: "100%"
    }
});