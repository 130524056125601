import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, reset } from "redux-form";
import {
    renderDatepickerWithLabel, renderInputWithLabel
} from "../../components/form/FormComponent";
import { memberList } from "../../actions/member/member_action";
import { getMemberListPending, getMemberList, getMemberListError } from "../../reducers/member/member_reducer";
import { addIuran, listIuran } from "../../actions/iuran/iuran_action";
import { getIuranAdd, getIuranAddPending, getIuranAddError } from "../../reducers/iuran/iuran_reducer";

class ModalAddIuran extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMemberID = this.handleMemberID.bind(this);
    }

    handleMemberID(e) {
        const value = e.target.value
        if (value !== undefined && value !== null && value !== "") {
            if (value.length === 11) {
                const callback = () => {
                    if (this.props.memberList_data && this.props.memberList_data.data.length > 0) {
                        this.props.change('doctor_name', this.props.memberList_data.data[0].name);
                        this.props.change('user_id', this.props.memberList_data.data[0].id);
                    } else {
                        this.props.change('doctor_name', 'ID Member tidak ditemukan');
                    }
                }
                this.props.memberList({ length: 999999, paboi_id: value }, callback)
                this.props.change('doctor_name', 'Loading...')
            } else {
                this.props.change('doctor_name', null)
            }
        }
    }

    handleSubmit(values) {
        let param = {
            user_id: values.user_id,
            billing_date: values.billing_date,
            total: values.total.match(/\d+/g).join('')
        }
        const callback = () => {
            this.props.onHide();
            this.props.handleRefresh();
        }
        this.props.addIuran(param, callback);
    }

    toRupiah(value) {
        if (value) {
            if (/\d+/g.test(value)) {
                const reformatted = value.match(/\d+/g).join('');
                return reformatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            }
        }
    };

    render() {
        const { show,
            onHide,
            handleSubmit,
            uploadP2kb_pending
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Tagihan Iuran</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <Field
                                    name="billing_date"
                                    placeholder="Masukkan Tanggal Tagihan"
                                    label="Tanggal Tagihan"
                                    component={renderDatepickerWithLabel}
                                />
                                <Field
                                    name="member_id"
                                    placeholder="Masukkan ID Member"
                                    label="ID Member"
                                    onChange={this.handleMemberID}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="doctor_name"
                                    placeholder="Isikan ID Member untuk mendapatkan nama dokter"
                                    label="Nama Dokter"
                                    disable={true}
                                    component={renderInputWithLabel}
                                />
                                <Field
                                    name="total"
                                    placeholder="Masukkan Total Pembayaran"
                                    label="Total Pembayaran"
                                    normalize={this.toRupiah}
                                    component={renderInputWithLabel}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={uploadP2kb_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    uploadP2kb_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
})

function validate(values) {
    const { billing_date, member_id, total, doctor_name } = values;
    const errors = {}
    if (!billing_date) {
        errors.billing_date = "Tanggal Tagihan wajib diisi"
    }
    if (!member_id) {
        errors.member_id = "ID Member wajib diisi"
    }
    if (member_id && doctor_name === "ID Member tidak ditemukan") {
        errors.member_id = "ID Member tidak ditemukan"
    }
    if (member_id && member_id.length > 11) {
        errors.member_id = "ID PABOI terdiri dari 11 digit angka"
    }
    if (!total) {
        errors.total = "Total Pembayaran wajib diisi"
    }
    return errors;
}

const afterSubmit = (result, dispatch) => dispatch(reset('ModalAddIuran'));

ModalAddIuran = reduxForm({
    form: "ModalAddIuran",
    validate: validate,
    shouldError: () => true,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(ModalAddIuran)

function mapStateToProps(state) {
    const { memberList, addIuran } = state;
    const selector = formValueSelector("ModalAddIuran");
    const formValue = selector(state, "member_id, user_id")
    return {
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        addIuran_pending: getIuranAddPending(addIuran),
        addIuran_data: getIuranAdd(addIuran),
        addIuran_error: getIuranAddError(addIuran),
        formValue
    }
}

export default connect(mapStateToProps, { memberList, addIuran, listIuran })(withRouter(ModalAddIuran));