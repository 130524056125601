export const SET_SELECTED_HEADER = "SET_SELECTED_HEADER";
export const SET_SELECTED_LINK_HEADER = "SET_SELECTED_LINK_HEADER";

export function setSelectedHeader(value) {
  return {
    type: SET_SELECTED_HEADER,
    value: value,
  };
}

export function setSelectedLinkHeader(value) {
  return {
    type: SET_SELECTED_LINK_HEADER,
    value: value,
  };
}

