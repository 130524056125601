import React, { Component } from "react";
import { connect } from "react-redux";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import DatePicker from 'react-date-picker';

import SelectComponent from "../form/SelectComponent";
import PeopleIcon from "@material-ui/icons/People";
import NumberFormat from "react-number-format";
import { listRequiredDeposit } from "../../actions/dashboard/koperasi_action";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  getRequiredDepositData,
  getRequiredDepositPending,
  getRequiredDepositError,
} from "../../reducers/dashboard/koperasi_reducer";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";


class CardKoperasiWajib extends Component {
  state = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    start_date: moment(new Date(new Date().getFullYear(), 0, 1)).format('yyyy-MM-DD'),
    end_date: moment(new Date(new Date().getFullYear(), 11, 31)).format('yyyy-MM-DD'),
  };

  onYearChange = (year) => {
    const { listRequiredDeposit } = this.props;
    const yearSelected = year ? year : new Date().getFullYear();
    this.setState({ year: yearSelected });
    listRequiredDeposit({ year: yearSelected, month: this.state.month });
  };

  onMonthChange = (month) => {
    const { listRequiredDeposit } = this.props;
    const monthSelected = month ? month : null;
    this.setState({ month: monthSelected });
    listRequiredDeposit({ month: monthSelected, year: this.state.year });
  };

  componentDidMount() {
    const { listRequiredDeposit } = this.props;
    const { year, month } = this.state;
    let param = { start_date: this.state.start_date,
      end_date: this.state.end_date
};
    // listRequiredDeposit({ year: year, month: month });
    listRequiredDeposit(param);
  }

  handleStartDateChange = (e) =>{
    const { listRequiredDeposit } = this.props;
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.setState({ start_date: date });
    } else {
      this.setState({ start_date: null });
    }
    let param = { start_date: date,
      end_date: this.state.end_date
    };
    listRequiredDeposit(param);
  }

  handleEndDateChange = (e) => {
    const { listRequiredDeposit } = this.props;
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.setState({ end_date: date });
    } else {
      this.setState({ end_date: null });
    }
    let param = { start_date: this.state.start_date,
      end_date: date
    };
    listRequiredDeposit(param);
  }

  render() {
    const { listRequiredDeposit_data } = this.props;
    const { year, month } = this.state;
    let total_required_deposit = null;
    let total_member_required_deposit = null;
    if (listRequiredDeposit_data) {
      total_required_deposit =
        listRequiredDeposit_data[0].total_required_deposit || 0;
      total_member_required_deposit = listRequiredDeposit_data[0].total_member;
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 2019; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    var bulan = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const bulanOptions = bulan.map((item, i) => ({
      label: item,
      value: i + 1,
    }));
    return (
      <div style={{ padding: "0 10px", width: "33.33%" }}>
        <Column
          style={{
            width: "100%",
            boxShadow: "0px 3px 6px #00000029",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <div className={css(styles.cardHeader)}></div>
          <Row
            className={css(styles.wrapperTop, styles.borderBottom)}
            style={{ width: "100%" }}
            vertical="center"
          >
            <Row
              vertical="center"
              horizontal="center"
              style={{
                height: 48,
                width: 48,
                backgroundColor: "#F6C269",
                borderRadius: 8,
                marginRight: 20,
              }}
            >
              <PeopleIcon style={{ fontSize: 28, color: "#FCEFD7" }} />
            </Row>
            <div style={{}}>
              <label className={css(styles.titleLabel)}>
                {" "}
                Simpanan Wajib
                <br /> Anggota
              </label>
            </div>
          </Row>
          <Column
            className={css(styles.wrapper, styles.borderBottom)}
            style={{ width: "100%", padding: 20 }}
          >
            <Row style={{ width: "100%" }}>
              <div
                style={{ margin: "auto", padding: "12px 5px", width: "50%" }}
              >
                {/* <SelectComponent
                  options={bulanOptions}
                  isRounded
                  placeholder="Pilih Bulan"
                  defaultValue={{ value: month, label: bulan[month - 1] }}
                  onChange={(e) => this.onMonthChange(e?.value)}
                /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
                {/* Tanggal Awal */}
            <KeyboardDatePicker
              margin="dense"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={this.state.start_date}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={(e) => this.handleStartDateChange(e)}
            />
          </MuiPickersUtilsProvider>
              </div>
              <div
                style={{ margin: "auto", padding: "12px 5px", width: "50%" }}
              >
                {/* <SelectComponent
                  options={yearOptions}
                  isRounded
                  placeholder="Pilih tahun"
                  defaultValue={{ value: year, label: year }}
                  onChange={(e) => this.onYearChange(e?.value)}
                /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
                {/* Tanggal Awal */}
                    <KeyboardDatePicker
                      
                      margin="dense"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      value={this.state.end_date}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={(e) => this.handleEndDateChange(e)}
                    />
                  </MuiPickersUtilsProvider>
              </div>
   
              
            </Row>
            <div
              style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}
            >
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                prefix="Rp "
                displayType={"text"}
                value={total_required_deposit}
              />
            </div>
            <div
              style={{ color: "#49505799", textAlign: "center", padding: 12 }}
            >
              {" "}
              Dibayar oleh{" "}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                displayType={"text"}
                value={total_member_required_deposit}
              />{" "}
              Anggota
            </div>
          </Column>
        </Column>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    padding: 15,
    // height: 180
  },
  wrapperTop: {
    padding: 24,
    // height: 180
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#F6C269",
    height: 10,
    width: "100%",
  },
});

function mapStateToProps({ listRequiredDeposit }) {
  return {
    listRequiredDeposit_data: getRequiredDepositData(listRequiredDeposit),
    listRequiredDeposit_error: getRequiredDepositError(listRequiredDeposit),
    listRequiredDeposit_pending: getRequiredDepositPending(listRequiredDeposit),
  };
}

export default connect(mapStateToProps, { listRequiredDeposit })(
  CardKoperasiWajib
);
