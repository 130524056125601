import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { userList } from "../../actions/master-data/user_action";
import {
    getUserListPending,
    getUserList,
    getUserListError,
} from "../../reducers/master-data/user_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderUser from "./Header_pengguna";
import ModalEditPengguna from "./Modal_edit_pengguna";
import ModalHapusPengguna from "./Modal_hapus_pengguna";

class ListUser extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            role_code: null,
            modalEditPenggunaShow: false,
            modalHapusPenggunaShow: false
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleEditPengguna(data) {
        const param_listPengguna = {
            id: data.id
        };

        const callback = () => {
            this.setState({ user_id: data.id });
            this.setState({ modalEditPenggunaShow: true });
        }
        this.props.userList(param_listPengguna, callback);
    }

    handleDeletePengguna(data) {
        const param_listPengguna = {
            id: data.id
        };

        const callback = () => {
            this.setState({ user_id: data.id });
            this.setState({ modalHapusPenggunaShow: true });
        }
        this.props.userList(param_listPengguna, callback);
    }

    render() {
        const { userList, userList_data } = this.props;
        const { modalEditPenggunaShow, modalHapusPenggunaShow, search, role_code } = this.state
        let data = [];
        let records_total = "";
        let length = "";
        if (userList_data) {
            data = userList_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        return (
            <ContentContainer>
                <ModalEditPengguna
                    show={modalEditPenggunaShow}
                    onHide={() => this.setState({ modalEditPenggunaShow: false })}
                    data={data}
                    handleRefresh={this.handleRefresh}
                />
                <ModalHapusPengguna
                    show={modalHapusPenggunaShow}
                    onHide={() => this.setState({ modalHapusPenggunaShow: false })}
                    data={data}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderUser handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Pengguna"
                    columns={
                        [
                            {
                                title: "No", field: "name",
                                render: (rowData) => {
                                    return <>{rowData.no}</>
                                },
                                width: 20,
                                headerStyle: {
                                    paddingLeft: 40,
                                },
                                cellStyle: {
                                    paddingLeft: 40,
                                },
                            },
                            { title: "Nama", field: "name" },
                            { title: "Email", field: "email" },
                            { title: "Role", field: "role_names[0]" },
                            { title: "Cabang", field: "branch_name" },
                            {
                                title: "",
                                render: (rowData) => {
                                    return (
                                        <DetailButtonComponent>
                                            <MenuItem onClick={() => this.handleEditPengguna(rowData)}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => this.handleDeletePengguna(rowData)}>
                                                Delete
                                            </MenuItem>
                                        </DetailButtonComponent>
                                    );
                                },
                            },
                        ]
                    }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: search,
                                role_code: role_code
                            };
                            userList(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ userList }) {
    return {
        userList_pending: getUserListPending(userList),
        userList_data: getUserList(userList),
        userList_error: getUserListError(userList),
    };
}

export default connect(mapStateToProps, { userList })(withRouter(ListUser));