import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { listEstimationFee } from '../../actions/dashboard/pusat_action'
import { getDashboardData } from '../../reducers/dashboard/pusat_reducer'
import { connect } from "react-redux";
import CountUp from 'react-countup';


class CardDanaEstimasi extends Component {

    componentDidMount() {
        const { listEstimationFee } = this.props
        listEstimationFee()
    }

    render() {
        let fee = 0, total_fee = 0, total_member = 0
        const { listEstimationFee_data } = this.props
        if (listEstimationFee_data) {
            ({ fee, total_fee, total_member } = listEstimationFee_data)
        }
        return (
            <Column style={{
                width: '100%', boxShadow: "0px 3px 6px #00000029",
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
            }}>
                <div className={css(styles.cardHeader)}></div>
                <Column className={css(styles.wrapper, styles.borderBottom)} style={{ width: "100%" }}>
                    <div style={{ height: 80, }}>
                        <div className={css(styles.titleLabel)}>Jumlah Estimasi Dana <br /> Iuran Anggota</div>
                        <div style={{ color: "#49505799", fontWeight: 'bold' }}> Secara Nasional</div>
                    </div>
                    <Column style={{ height: 100 }} vertical="center" horizontal='center'> <span style={{ fontSize: 28, fontWeight: 'bold', }}>Rp <CountUp end={total_fee} separator="." duration={1} /></span> </Column>
                </Column>
                <Column className={css(styles.wrapper,)} style={{ width: "100%" }} vertical="space-around">
                    <Row style={{}} horizontal='space-between' vertical="center">
                        <div>
                            <div className={css(styles.cardLabel)}>Member Aktif</div>
                            <div style={{ color: "#49505799" }}> Member Aktif Nasional</div>
                        </div>
                        <span style={{ fontSize: 20, fontWeight: 'bold', color: '#5AB853' }}> <CountUp end={total_member} separator="." duration={1} /></span>
                    </Row>
                    <Row style={{}} horizontal='space-between' vertical="center">
                        <div>
                            <div className={css(styles.cardLabel)}>Dana Iuran</div>
                            <div style={{ color: "#49505799" }}> Dana Iuran Per Tahun</div>
                        </div>
                        <span style={{ fontSize: 20, fontWeight: 'bold', color: '#5AB853' }}>Rp <CountUp end={fee} separator="." duration={1} /></span>
                    </Row>

                </Column>

            </Column>
        );
    }
}
function mapStateToProps({ listEstimationFee_pusat }) {
    return {
        listEstimationFee_data: getDashboardData(listEstimationFee_pusat),
    };
}

export default connect(mapStateToProps, {
    listEstimationFee
})(CardDanaEstimasi);

const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: 'bold',
        margin: "5px 0"
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
    wrapper: {
        padding: 15,
        height: 180
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    cardHeader: {
        backgroundColor: "#5AB853 ",
        height: 10,
        width: "100%"
    }
});