import {
    LIST_AUDIT_TRAIL_PENDING,
    LIST_AUDIT_TRAIL_SUCCESS,
    LIST_AUDIT_TRAIL_ERROR,
} from "../../actions/audit-trail/audit_trail_action";

const initialState = {
    pending: false,
    error: null,
};

export function listAuditTrail(
    state = { ...initialState, list_audit_trail: null },
    action
) {
    switch (action.type) {
        case LIST_AUDIT_TRAIL_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_AUDIT_TRAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                list_audit_trail: action.data,
            };
        case LIST_AUDIT_TRAIL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListAuditTrail = (state) => state.list_audit_trail;
export const getAuditTrailPending = (state) => state.pending;
export const getAuditTrailError = (state) => state.error;