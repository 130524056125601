import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import moment from "moment";
import localization from "moment/locale/id";
import { listAuditTrail } from "../../actions/audit-trail/audit_trail_action";
import ContentContainer from "../../components/container/ContainerComponent";
import {
    getAuditTrailPending,
    getListAuditTrail,
    getAuditTrailError,
} from "../../reducers/audit-trail/audit_trail_reducer";
import HeaderAuditTrail from "./HeaderAuditTrail";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { roleList } from "../../actions/master-data/role_action";
import { getRoleListPending, getRoleList, getRoleListError } from "../../reducers/master-data/role_reducer";

class AuditTrail extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            date: null,
            role_code: null,
            status: null
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    componentDidMount() {
        this.props.roleList({ length: 999999 });
    }

    render() {
        let data = [];
        let roleOptions = [];
        let records_total = 0;
        const { listAuditTrail, listAuditTrail_data, roleList_data } = this.props;
        if (listAuditTrail_data) {
            data = listAuditTrail_data.data;
            records_total = listAuditTrail_data.records_total;
        }
        if (roleList_data) {
            let data = roleList_data.data;
            data.map((value) => {
                const role = { value: value.role_code, label: value.role_name }
                roleOptions = [...roleOptions, role]
                return role;
            })
        }
        return (
            <ContentContainer>
                <HeaderAuditTrail
                    handleRefresh={this.handleRefresh}
                    handleOpenModalAdd={this.handleOpenModalAdd}
                    roleOptions={roleOptions}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Simple Action Preview"
                    columns={[
                        {
                            title: "No",
                            field: "journal_name",
                            render: (rowData) => {
                                return <>{rowData.no}</>;
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 30,
                            },
                            cellStyle: {
                                paddingLeft: 30,
                            },
                        },
                        {
                            title: "Waktu",
                            render: (data) => {
                                return moment(data.created_at)
                                    .locale("id", localization)
                                    .format("DD MMMM YYYY hh:mm");
                            },
                        },
                        {
                            title: "Nama Pengguna",
                            field: "name",
                        },
                        {
                            title: "Role",
                            field: "role_name",
                        },
                        {
                            title: "Aktivitas",
                            field: "message",
                        },
                        {
                            title: "Status",
                            render: (data) => {
                                return (
                                    <TableStatusComponent
                                        status={
                                            data.status === "200"
                                                ? "Success"
                                                : "Failed"
                                        }
                                    />
                                );
                            },
                            width: 210,
                        },
                    ]
                    }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                date: this.state.date,
                                role_code: this.state.role_code,
                                status: this.state.status
                            };
                            listAuditTrail(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                            // width: 10,
                            // paddingLeft: "40px",
                            // paddingRight: "40px",
                        }, //change header padding
                        // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <td style={{ width: "100%", display: "block" }}>
                                <Row
                                    // vertical="center"
                                    horizontal="space-between"
                                    style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        // background: "red",
                                    }}
                                >
                                    <Row
                                        style={{
                                            // backgroundColor: "red",
                                            width: "50%",
                                        }}
                                        vertical="center"
                                    >
                                        <p>
                                            Menampilkan {data.length} dari {records_total} data
                                        </p>
                                    </Row>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <TablePagination {...props} />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                            </td>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listAuditTrail, roleList }) {
    return {
        listAuditTrail_data: getListAuditTrail(listAuditTrail),
        listAuditTrail_error: getAuditTrailError(listAuditTrail),
        listAuditTrail_pending: getAuditTrailPending(listAuditTrail),
        roleList_pending: getRoleListPending(roleList),
        roleList_data: getRoleList(roleList),
        roleList_error: getRoleListError(roleList),
    };
}

export default connect(mapStateToProps, {
    listAuditTrail, roleList
})(AuditTrail);