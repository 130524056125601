import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { messageBlastList } from "../../actions/message-blast/message_blast_action";
import {
  getMessageBlastPending,
  getMessageBlastList,
  getMessageBlastError,
} from "../../reducers/message-blast/message_blast_reducer";
import { connect } from "react-redux";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDokumenMessageBlast from "./Modal_document_img";
import ModalDokumenPDFMessageBlast from "./Modal_document_pdf";
import moment from "moment";
import localization from "moment/locale/id";

class DetailMessageBlast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forRefresh: false,
      modal_title: "",
      imgUrl: "",
      id: "",
      modalDokumenMessageBlastShow: false,
      modalDokumenPDFMessageBlastShow: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    let param_messageBlastList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_messageBlastList.id = id;
    this.props.messageBlastList(param_messageBlastList);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "png" || imgUrl.split(".")[1] === "jpg") {
      this.setState({ modalDokumenMessageBlastShow: true });
    } else {
      this.setState({ modalDokumenPDFMessageBlastShow: true });
    }
  }

  createMarkup(html) {
    return { __html: html };
  }

  componentDidMount() {
    let param_messageBlastList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_messageBlastList.id = id;
    this.props.messageBlastList(param_messageBlastList);
  }

  shouldComponentRender() {
    const { messageBlastList_pending } = this.props;
    if (messageBlastList_pending === false) {
      return false;
    }
    return true;
  }

  render() {
    const { messageBlastList_data } = this.props;
    let data = [];
    let tanggal,
      deskripsi,
      filename,
      judul,
      member,
      non_member = "";
    if (messageBlastList_data) {
      data = messageBlastList_data.data[0];
      judul = data.title;
      tanggal = data.created_at;
      member = data.message_blast_users;
      non_member = data.message_blast_non_users;
      deskripsi = data.description;
      filename = data.message_blast_attachments[0]
        ? data.message_blast_attachments[0].attachment_filename
        : "";
    }
    return (
      <ContentContainer>
        <ModalDokumenMessageBlast
          show={this.state.modalDokumenMessageBlastShow}
          onHide={() => this.setState({ modalDokumenMessageBlastShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDokumenPDFMessageBlast
          show={this.state.modalDokumenPDFMessageBlastShow}
          onHide={() =>
            this.setState({ modalDokumenPDFMessageBlastShow: false })
          }
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Row style={{ minHeight: "78vh" }}>
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <DetailNameComponent name={judul} />
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <Row horizontal="space-between" style={{ alignItems: "center" }}>
                <DetailTitleComponent
                  title="Detail Message Blast"
                  marginBottom={true}
                />
              </Row>
              <div style={{ marginBottom: 10 }}></div>
              <DetailContentComponent
                label="Tanggal"
                value={moment(tanggal)
                  .locale("id", localization)
                  .format("DD MMMM YYYY")}
              />
              <DetailContentComponent
                label="Member"
                alignItems="baseline"
                style={{ maxHeight: 125, overflowY: "scroll" }}
                value={
                  member && member.length > 0
                    ? member.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {idx + 1}. {item.member_data.name}
                            <br />
                            <br />
                          </React.Fragment>
                        );
                      })
                    : "-"
                }
              />
              <DetailContentComponent
                label="Non Member"
                alignItems="baseline"
                style={{ maxHeight: 125, overflowY: "scroll" }}
                value={
                  non_member && non_member.length > 0
                    ? non_member.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {idx + 1}. {item.email}
                            <br />
                            <br />
                          </React.Fragment>
                        );
                      })
                    : "-"
                }
              />
              <DetailContentComponent
                label="Deskripsi"
                alignItems="baseline"
                value={
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(deskripsi)}
                  ></div>
                }
              />
              <DetailContentComponent
                label="Berkas Message Blast"
                alignItems="baseline"
                value={
                  filename ? (
                    <DetailContentLihatBerkas
                      display="inline"
                      onClick={() =>
                        this.handleOpenDocument(
                          filename,
                          "Berkas Message Blast"
                        )
                      }
                    />
                  ) : (
                    "-"
                  )
                }
              />
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginLeft: 10,
    padding: 10,
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginLeft: 10,
    color: "#495057",
    boxShadow: "none",
  },
  billDate: {
    color: "#495057",
    opacity: "0.7",
    marginBottom: 10,
  },
});

function mapStateToProps({ messageBlastList }) {
  return {
    messageBlastList_data: getMessageBlastList(messageBlastList),
    messageBlastList_error: getMessageBlastError(messageBlastList),
    messageBlastList_pending: getMessageBlastPending(messageBlastList),
  };
}

export default connect(mapStateToProps, { messageBlastList })(
  withRouter(DetailMessageBlast)
);
