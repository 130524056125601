import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { universityList } from "../../actions/master-data/university_action";
import {
  getUniversityListPending,
  getUniversityList,
  getUniversityListError,
} from "../../reducers/master-data/university_reducer";
import { registrationList } from "../../actions/registrasi/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
} from "../../reducers/registrasi/registrasi_reducer";
import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 150,
    margin: 10,
  },
});
class HeaderPPDS extends Component {
  constructor(props) {
    super(props);
    this.handleChangeNama = this.handleChangeNama.bind(this);
    this.handleChangeFKAsal = this.handleChangeFKAsal.bind(this);
    this.handleChangeFKTujuan = this.handleChangeFKTujuan.bind(this);
    this.handleChangeTahun = this.handleChangeTahun.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;
    return handleRefresh(value);
  }, 500);

  handleChangeNama(e) {
    if (e !== null) {
      this.setState({ nama: e.target.value });
      this.delayedQuery({ search: e.target.value });
    } else {
      this.setState({ nama: e.target.value });
      this.delayedQuery({ search: "" });
    }
  }

  handleChangeStatus(e) {
    if (e !== null) {
      this.props.handleRefresh({ status: e.value });
    } else {
      this.props.handleRefresh({ status: "" });
    }
  }

  handleChangeFKAsal(e) {
    if (e !== null) {
      this.props.handleRefresh({ fkasal: e.value });
    } else {
      this.props.handleRefresh({ fkasal: "" });
    }
  }

  handleChangeFKTujuan(e) {
    if (e !== null) {
      this.props.handleRefresh({ fktujuan: e.value });
    } else {
      this.props.handleRefresh({ fktujuan: "" });
    }
  }

  handleChangeTahun(e) {
    if (e !== null) {
      this.props.handleRefresh({ tahun: e.value });
    } else {
      this.props.handleRefresh({ tahun: "" });
    }
  }

  componentDidMount() {
    let param_universityList = {
      page: 1,
      length: 999,
      search_text: "",
      id: "",
    };
    this.props.universityList(param_universityList);
  }

  render() {
    const { universityList_data } = this.props;
    let facultyOption = [];
    let yearOption = [];
    const statusOption = [
      { value: 0, label: "Baru" },
      { value: 1, label: "Terverifikasi" },
      { value: 2, label: "Ditolak" },
    ];
    const currentYear = new Date().getFullYear();
    const lastTwentyYear = new Date().getFullYear() - 10;
    for (let i = lastTwentyYear; i <= currentYear; i++) {
      const year = { value: i, label: i };
      yearOption = [...yearOption, year];
    }
    if (universityList_data) {
      let data = universityList_data.data;
      data.map((value, index) => {
        const faculty = { value: value.id, label: value.name };
        facultyOption = [...facultyOption, faculty];
        return faculty;
      });
    }
    return (
      <Row
        style={{
          padding: 20,
          paddingBottom: 30,
          backgroundColor: "#F8F9FB",
          zIndex: 999,
        }}
        horizontal="flex-end"
      >
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleChangeNama}
            placeholder="Cari Nama"
            startIcon={SearchIcon}
            id="nama"
          />
        </div>
        <div className={css(styles.container)}>
          <SelectComponent
            onChange={this.handleChangeStatus}
            placeholder="Status"
            options={statusOption}
            id="registrasi"
          />
        </div>
        <div className={css(styles.container)}>
          <SelectComponent
            onChange={this.handleChangeFKAsal}
            placeholder="FK Asal"
            options={facultyOption}
            id="fkasal"
          />
        </div>
        <div className={css(styles.container)}>
          <SelectComponent
            onChange={this.handleChangeTahun}
            placeholder="Tahun"
            options={yearOption}
            id="tahun"
          />
        </div>
        <div className={css(styles.container)}>
          <SelectComponent
            onChange={this.handleChangeFKTujuan}
            placeholder="Prodi Tujuan"
            options={facultyOption}
            id="fktujuan"
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps({ universityList, registrationList }) {
  return {
    universityList_pending: getUniversityListPending(universityList),
    universityList_data: getUniversityList(universityList),
    universityList_error: getUniversityListError(universityList),
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
  };
}

export default connect(mapStateToProps, { universityList, registrationList })(
  HeaderPPDS
);
