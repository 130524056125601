import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from '../../assets/canvas/canvasjs.react';
import { connect } from "react-redux";
import { listCandidate } from '../../actions/dashboard/kolegium_action'
import SelectComponent from "../../components/form/SelectComponent";
import { getDashboardData } from '../../reducers/dashboard/kolegium_reducer'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class CardRegistrasiPPDS extends Component {
    constructor() {
        super();
        this.state = {
            year: new Date().getFullYear()
        };
        this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    }

    componentDidMount() {
        this.props.listCandidate({ year: this.state.year })
    }

    handleYearSelectChange(e) {
        if (e) {
            this.setState({ year: e.value });
            this.props.listCandidate({ year: e.value })
        } else {
            this.setState({ year: new Date().getFullYear() });
            this.props.listCandidate({ year: new Date().getFullYear() })
        }
    }

    render() {

        const { listCandidate_data } = this.props
        let data = [], result = null
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }

        if (listCandidate_data) {

            data = listCandidate_data.map(({ year, total }) => ({ x: new Date(year, 0), y: total }))

            const options = {
                animationEnabled: true,
                height: 300,
                axisX: {
                    valueFormatString: "YYYY",

                },
                axisY: {
                    includeZero: true,
                    gridThickness: 0,
                },
                data: [
                    {
                        xValueFormatString: "YYYY",
                        type: "splineArea",
                        lineColor: "#45A3E7",
                        color: '#E4F3FB',
                        lineThickness: 8,
                        markerColor: 'white',
                        markerBorderColor: '#45A3E7',
                        markerBorderThickness: 4,
                        markerSize: 10,
                        dataPoints: data
                    },
                ]
            }
            result = (
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
            )
        }

        return (
            <div style={{ width: "100%", height: '50%', paddingBottom: 10 }}>

                <Column style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'white'

                }}>

                    <div className={css(styles.cardHeader)}></div>


                    {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                    <Row className={css(styles.wrapperTop, styles.borderBottom)} horizontal="space-between" style={{ width: "100%", padding: 20, backgroundColor: 'white' }} vertical='center'>


                        <label className={css(styles.titleLabel)}> Registrasi Calon PPDS</label>
                        <Row>
                            <div style={{ width: 150, margin: "0 10px" }}>
                                <SelectComponent
                                    options={yearOptions}
                                    isRounded
                                    onChange={this.handleYearSelectChange}
                                    placeholder="Pilih Tahun"
                                />
                            </div>
                        </Row>
                    </Row>
                    <Column horizontal='center' className={css(styles.wrapper,)} style={{ width: "100%", padding: 20 }}>
                        {result}

                    </Column>

                </Column>
            </div>

        )
    }
}

function mapStateToProps({
    listCandidate }) {
    return {
        listCandidate_data: getDashboardData(listCandidate),
    };
}

export default connect(mapStateToProps, {
    listCandidate,
})(CardRegistrasiPPDS);



const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: 'bold',
        margin: "5px 0"
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4
    },
    wrapper: {
        padding: 15,
        // height: 400,
        backgroundColor: 'white'
    },
    borderBottom: {
        borderBottom: "1px solid #eeee"
    },
    cardHeader: {
        backgroundColor: "#45A3E7",
        height: 10,
        width: "100%"
    }
});