import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { getUser } from "../../helpers/user";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { experienceList } from "../../actions/master-data/experience_action";
import {
    getExperienceListPending,
    getExperienceList,
    getExperienceListError,
} from "../../reducers/master-data/experience_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderPengalaman from "./HeaderPengalaman";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import ModalHapusPengalaman from "./Modal_delete_pengalaman";

class ListPengalaman extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            status: "",
            modalEditPengalamanShow: false,
            modalHapusPengalamanShow: false,
            experience_id: "",
            id: null,
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.setState({ id: null });
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleDetailPengalaman(id) {
        this.props.history.push(`/experience/detail/${id}`)
    }

    handleDeletePengalaman(data) {
        const param_listPengalaman = {
            id: data.id
        };

        const callback = () => {
            this.setState({ training_id: data.id });
            this.setState({ modalHapusPengalamanShow: true });
            this.handleRefresh();
        }
        this.props.universityList(param_listPengalaman, callback);
    }

    componentDidMount() {
        const url = window.location.href;
        const ID = url.substring(url.lastIndexOf('/') + 1);
        const isID = url.substring(url.lastIndexOf('/') + 1) !== "member-experience";
        if (isID && this.state.id === null) {
            this.setState({ id: ID })
        }
    }

    render() {
        const { experienceList, experienceList_data } = this.props;
        const { modalHapusPengalamanShow } = this.state;
        let data = [];
        let records_total = "";
        let length = "";
        if (experienceList_data) {
            data = experienceList_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        return (
            <ContentContainer>
                <ModalHapusPengalaman
                    show={modalHapusPengalamanShow}
                    onHide={() => this.setState({ modalHapusPengalamanShow: false })}
                    data={data}
                    handleRefresh={this.handleRefresh}
                />
                <HeaderPengalaman handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Pengalaman"
                    columns={[
                        {
                            title: "No", field: "name",
                            render: (rowData) => {
                                return <>{rowData.no}</>
                            },
                            width: 20,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        { title: "Nama Institusi", field: "institution" },
                        { title: "Jabatan", field: "position" },
                        { title: "Kota", field: "city" },
                        { title: "Tahun", field: "year" },
                        {
                            title: "Status",
                            field: "status",
                            render: ({ status }) => {
                                return (
                                    <TableStatusComponent
                                        status={status === 0 ? "Baru" : status === 1 ? "Terverifikasi" : "Ditolak"}
                                    />
                                );
                            },
                        },
                        {
                            title: "",
                            render: (data) => {
                                return (
                                    <DetailButtonComponent>
                                        <MenuItem onClick={() => this.handleDetailPengalaman(data.id)}>
                                            Detail
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => this.handleDeletePengalaman(data.id)}>
                                            Delete
                                        </MenuItem> */}
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                user_id: getUser().id,
                                status: this.state.status
                            };
                            experienceList(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        pageSizeOptions: [],
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ experienceList }) {
    return {
        experienceList_pending: getExperienceListPending(experienceList),
        experienceList_data: getExperienceList(experienceList),
        experienceList_error: getExperienceListError(experienceList)
    };
}

export default connect(mapStateToProps, { experienceList })(withRouter(ListPengalaman));