import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_TRAINING_PENDING = "LIST_TRAINING_PENDING";
export const LIST_TRAINING_SUCCESS = "LIST_TRAINING_SUCCESS";
export const LIST_TRAINING_ERROR = "LIST_TRAINING_ERROR";
export const EDIT_TRAINING_PENDING = "EDIT_TRAINING_PENDING";
export const EDIT_TRAINING_SUCCESS = "EDIT_TRAINING_SUCCESS";
export const EDIT_TRAINING_ERROR = "EDIT_TRAINING_ERROR";
export const ADD_TRAINING_PENDING = "ADD_TRAINING_PENDING";
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
export const ADD_TRAINING_ERROR = "ADD_TRAINING_ERROR";
export const DETAIL_TRAINING_PENDING = "DETAIL_TRAINING_PENDING";
export const DETAIL_TRAINING_SUCCESS = "DETAIL_TRAINING_SUCCESS";
export const DETAIL_TRAINING_ERROR = "DETAIL_TRAINING_ERROR";
export const DELETE_TRAINING_PENDING = "DELETE_TRAINING_PENDING";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const DELETE_TRAINING_ERROR = "DELETE_TRAINING_ERROR";
export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_TRAINING";

const LIST_TRAINING_URL = "member/training/find";
const EDIT_TRAINING_URL = "member/training/edit";
const ADD_TRAINING_URL = "member/training/add";
const DELETE_TRAINING_URL = "member/training/delete";
const UPLOAD_TRAINING_URL = "image/upload/member_training";

export function trainingList(param = {}, resolve, reject, callback) {
  return (dispatch) => {
    dispatch(actionPending(LIST_TRAINING_PENDING));
    API.post(LIST_TRAINING_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_TRAINING_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 10,
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_TRAINING_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function editTraining({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_TRAINING_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_TRAINING_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_TRAINING_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_TRAINING_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah pelatihan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_TRAINING_ERROR, error));
        toastError(error, "Gagal mengubah pelatihan, ");
      });
  };
}

export function addTraining({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_TRAINING_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_TRAINING_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        return API.post(ADD_TRAINING_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_TRAINING_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan pelatihan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_TRAINING_ERROR, error));
        toastError(error, "Gagal menambahkan pelatihan, ");
      });
  };
}

export function deleteTraining(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_TRAINING_PENDING));
    API.post(DELETE_TRAINING_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_TRAINING_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengHAPUS pelatihan");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_TRAINING_ERROR, error));
        toastError(error, "Gagal mengHAPUS pelatihan, ");
      });
  };
}

export function detailTraining(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_TRAINING_PENDING));
    API.post(LIST_TRAINING_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_TRAINING_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_TRAINING_ERROR, error));
        toastError(error, "Gagal mendapatkan list TRAINING, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}
