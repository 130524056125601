import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { findSHU } from "../../actions/koperasi/koperasi_action";
import { getSHUKoperasiPending, getSHUKoperasi, getSHUKoperasiError } from "../../reducers/koperasi/koperasi_reducer";
import PaginationComponent from '../../components/table/PaginationComponent';
import { Paper } from "@material-ui/core";
import HeaderSHU from "./ShuMemberHeader";
import moment from "moment";
import localization from "moment/locale/id";

class SHUMember extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            status: "",
            year: "",
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    render() {
        let records_total, length = "";
        const { findSHU, findSHU_data } = this.props;
        let data = [];
        if (findSHU_data) {
            data = findSHU_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        return (
            <ContentContainer>
                <HeaderSHU handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Member"
                    columns={[
                        {
                            title: "ID Transaksi",
                            field: "transaction_number",
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            title: "Tanggal Pembayaran",
                            render: (data) => {
                                return moment(data.transaction_date).locale("id", localization).format("DD MMMM YYYY");
                            }
                        },
                        {
                            title: "Dana SHU",
                            render: (data) => {
                                let total;
                                if (data.amount && data.amount >= 0 && data.amount !== null) {
                                    total = data.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                        {
                            title: "Total Pembayaran",
                            render: (data) => {
                                let total;
                                if (data.amount_running && data.amount_running >= 0 && data.amount_running !== null) {
                                    total = data.amount_running.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                                    return "Rp " + total;
                                } else {
                                    return "-";
                                }
                            }
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                id: "",
                                status: this.state.status,
                                year: this.state.year,
                                user_id: this.props.match?.params?.id,
                            };
                            findSHU(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={length}
                                records_total={records_total}
                                {...props}
                            />
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const { findSHU, selectedMembership } = state;
    return {
        findSHU_pending: getSHUKoperasiPending(findSHU),
        findSHU_data: getSHUKoperasi(findSHU),
        findSHU_error: getSHUKoperasiError(findSHU),
        selectedMembership: selectedMembership
    };
}

export default connect(mapStateToProps, { findSHU })(withRouter(SHUMember));