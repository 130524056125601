import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import SelectComponent from "../form/SelectComponent";
import { connect } from "react-redux";
import CountUp from "react-countup";
import { listAllocation as listAllocationCabang } from "../../actions/dashboard/cabang_action";
import { getUser } from "../../helpers/user";
import {
  listAllocation
} from "../../actions/dashboard/pusat_action";
import { getDashboardData } from "../../reducers/dashboard/pusat_reducer";
import { listBranch } from "../../actions/master-data/branch_action";
import { getListBranch } from "../../reducers/master-data/branch_reducer";

class CardDanaPusat extends Component {
  constructor() {
    super();
    this.state = {
      branch_id: null,
      duration: 2,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { listAllocation, listAllocationCabang } = this.props;
    listAllocation();
    listAllocationCabang({ branch_id: getUser().branch_id });
  }

  handleSelectChange(e) {
    this.setState({ duration: 0.1 });
    this.setState({ branch_id: e });
    const { listAllocationCabang } = this.props;
    if (e) {
      listAllocationCabang({ branch_id: e.value });
    } else {
      listAllocationCabang();
    }
  }
  render() {
    let allocation_total_foundation = 0,
      allocation_total_paboi = 0,
      actual = 0,
      branchOption = [];

    const { listAllocation_data, listBranch_data } = this.props;
    let { duration } = this.state;
    if (listAllocation_data) {
      ({
        allocation_total_paboi,
        allocation_total_foundation,
      } = listAllocation_data);
    }
    if (listBranch_data) {
      branchOption = listBranch_data.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    }
    const { listAllocation_data_cabang } = this.props;
    if (listAllocation_data_cabang) {
      ({ actual } = listAllocation_data_cabang);
    }
    return (
      <>
        <Column
          style={{
            width: "100%",
            height: "100%",
            boxShadow: "0px 3px 6px #00000029",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <div className={css(styles.cardHeader)}></div>
          <Column
            className={css(styles.wrapper, styles.borderBottom)}
            style={{ width: "100%" }}
          >
            <div>
              <div className={css(styles.titleLabel)}>Alokasi Dana Cabang</div>
            </div>
            <div style={{ margin: "0 auto", width: 200, padding: "10px 0" }}>
              <SelectComponent
                options={branchOption}
                isRounded
                value={this.state.branch_id}
                onChange={this.handleSelectChange}
                placeholder="Pilih Cabang"
              />
            </div>
            <Column
              style={{ height: 100 }}
              vertical="center"
              horizontal="center"
            >
              <span style={{ fontSize: 28, fontWeight: "bold" }}>
                Rp <CountUp end={actual} separator="." duration={duration} />
              </span>{" "}
            </Column>
          </Column>
          <Column
            className={css(styles.wrapper)}
            style={{ width: "100%" }}
            vertical="space-around"
          >
            <Row style={{}} horizontal="space-between" vertical="center">
              <div>
                <div className={css(styles.cardLabel)}>
                  Alokasi Dana <br /> Pusat Aktual
                </div>
              </div>
              <span
                style={{ fontSize: 20, fontWeight: "bold", color: "#5AB853" }}
              >
                Rp{" "}
                <CountUp
                  end={allocation_total_paboi}
                  separator="."
                  duration={duration}
                />
              </span>
            </Row>

            <Row style={{}} horizontal="space-between" vertical="center">
              <div>
                <div className={css(styles.cardLabel)}>
                  Alokasi Dana
                  <br /> Yayasan
                </div>
              </div>
              <span
                style={{ fontSize: 20, fontWeight: "bold", color: "#5AB853" }}
              >
                {" "}
                <CountUp
                  end={allocation_total_foundation}
                  separator="."
                  duration={duration}
                />
              </span>
            </Row>
          </Column>
        </Column>
      </>
    );
  }
}
function mapStateToProps({ listAllocation_pusat, listBranch, listAllocation }) {
  return {
    listAllocation_data: getDashboardData(listAllocation_pusat),
    listAllocation_data_cabang: getDashboardData(listAllocation),

    listBranch_data: getListBranch(listBranch),
  };
}

export default connect(mapStateToProps, {
  listAllocation,
  listBranch,
  listAllocationCabang,
})(CardDanaPusat);

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0 20px",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    padding: 15,
    // backgroundColor: "red",
    height: 180,
    // height: "100%",
  },
  wrapper2: {
    padding: 15,
    // backgroundColor: "red",
    height: 180,
    // height: "100%",
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#45A3E7",
    height: 10,
    width: "100%",
  },
});
