import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_SUBSPECIALIST_PENDING = "LIST_SUBSPECIALIST_PENDING";
export const LIST_SUBSPECIALIST_SUCCESS = "LIST_SUBSPECIALIST_SUCCESS";
export const LIST_SUBSPECIALIST_ERROR = "LIST_SUBSPECIALIST_ERROR";

export const LIST_CANDIDATE_PENDING = "LIST_CANDIDATE_PENDING";
export const LIST_CANDIDATE_SUCCESS = "LIST_CANDIDATE_SUCCESS";
export const LIST_CANDIDATE_ERROR = "LIST_CANDIDATE_ERROR";

export const LIST_GRADUATED_PENDING = "LIST_GRADUATED_PENDING";
export const LIST_GRADUATED_SUCCESS = "LIST_GRADUATED_SUCCESS";
export const LIST_GRADUATED_ERROR = "LIST_GRADUATED_ERROR";

export const LIST_ORTHOPAEDI_PENDING = "LIST_ORTHOPAEDIPENDING";
export const LIST_ORTHOPAEDI_SUCCESS = "LIST_ORTHOPAEDISUCCESS";
export const LIST_ORTHOPAEDI_ERROR = "LIST_ORTHOPAEDIERROR";


const LIST_SUBSPECIALIST_URL = "dashboard/kolegium/subspecialist_doctor";
const LIST_CANDIDATE_URL = "dashboard/kolegium/candidate_ppds";
const LIST_ORTHOPAEDI_URL = "dashboard/kolegium/ppds_orthopaedi";
const LIST_GRADUATED_URL = "dashboard/kolegium/member_graduated";

export function listSubspecialist(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_SUBSPECIALIST_PENDING));
        API.post(LIST_SUBSPECIALIST_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_SUBSPECIALIST_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_SUBSPECIALIST_ERROR, error));
                toastError(error, "Gagal mendapatkan data SUBSPECIALIST, ");
            });
    };
}

export function listCandidate(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_CANDIDATE_PENDING));
        API.post(LIST_CANDIDATE_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_CANDIDATE_SUCCESS, data)); 
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_CANDIDATE_ERROR, error));
                toastError(error, "Gagal mendapatkan data CANDIDATE PPDS , ");
            });
    };
}
export function listOrthopaedi(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ORTHOPAEDI_PENDING));
        API.post(LIST_ORTHOPAEDI_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ORTHOPAEDI_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ORTHOPAEDI_ERROR, error));
                toastError(error, "Gagal mendapatkan data PPDS ORTHOPAEDI , ");
            });
    };
}
export function listGraduated(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_GRADUATED_PENDING));
        API.post(LIST_GRADUATED_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_GRADUATED_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_GRADUATED_ERROR, error));
                toastError(error, "Gagal mendapatkan data GRADUATED MEMBER , ");
            });
    };
}



