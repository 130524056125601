import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { memberList, changeMemberStatus } from "../../actions/member/member_action";
import { getMemberListPending, getMemberList, getMemberListError, getChangeMemberStatusPending, getChangeMemberStatus, getChangeMemberStatusError } from "../../reducers/member/member_reducer";

class ModalEditStatusMemberAktif extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    editStatus(id) {
        const { changeMemberStatus, onHide } = this.props;
        const param_changeMemberStatus = {
            id: id,
            status: 1
        };

        const callback = () => {
            onHide();
            window.location.reload();
        }
        changeMemberStatus(param_changeMemberStatus, callback)
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                name={this.props.name}
                status={this.props.status}
                dialogClassName="modal-width-change-status"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Konfirmasi
                    </p>
                </Modal.Header>
                <Modal.Body>
                    {this.props.status === 1 ?
                        <Column className={css(styles.bodyModalFalse)}>
                            <span>Status {this.props.name} sudah Aktif!</span>
                        </Column>
                        :
                        <>
                            <Column className={css(styles.bodyModal)}>
                                <span>Apakah Anda yakin akan mengubah status {this.props.name} menjadi Aktif?</span>
                            </Column>
                            <Row
                                horizontal="flex-end"
                                vertical="center"
                                className={css(styles.footerModal)}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.approveButton)}
                                    startIcon={<CheckIcon />}
                                    onClick={() => this.editStatus(this.props.id)}
                                >
                                    YA
                            </Button>
                            </Row>
                        </>}
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModalFalse: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40
    },
});

function mapStateToProps({ memberList, changeMemberStatus }) {
    return {
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        changeMemberStatus_pending: getChangeMemberStatusPending(changeMemberStatus),
        changeMemberStatus_data: getChangeMemberStatus(changeMemberStatus),
        changeMemberStatus_error: getChangeMemberStatusError(changeMemberStatus),
    };
}


export default connect(mapStateToProps, { memberList, changeMemberStatus })(ModalEditStatusMemberAktif);
