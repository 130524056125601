import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { approvalBenefit } from "../../actions/benefit/benefit_action";
import {
  getBenefitApproval,
  getBenefitApprovalPending,
  getBenefitApprovalError,
} from "../../reducers/benefit/benefit_reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

class ModalApproveBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  benefitApproval(id) {
    const { approvalBenefit, onHide } = this.props;
    const param_approval = {
      id: id,
      is_approve: "true",
    };
    const callback = () => {
      onHide();
      this.props.handleRefresh({ forRefresh: true });
    };
    approvalBenefit(param_approval, callback);
  }

  render() {
    const { approvalBenefit_pending } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            <span>Apakah anda yakin ingin menyetujui penukaran benefit?</span>
          </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={
                approvalBenefit_pending ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                    <CheckIcon />
                  )
              }
              onClick={() => this.benefitApproval(this.props.id)}
            >
              YA
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function mapStateToProps({ approvalBenefit }) {
  return {
    approvalBenefit_pending: getBenefitApprovalPending(approvalBenefit),
    approvalBenefit_data: getBenefitApproval(approvalBenefit),
    approvalBenefit_error: getBenefitApprovalError(approvalBenefit),
  };
}

export default connect(mapStateToProps, { approvalBenefit })(ModalApproveBenefit);