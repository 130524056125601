import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { changeStatusGraduation } from "../../actions/member/ppds_action";
import {
  getPpdsError,
  getPpdsPending,
} from "../../reducers/member/ppds_reducer";
import { detailPpds } from "../../actions/member/ppds_action";

class ModalPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_date: new Date(),
      graduated_date: new Date(),
    };

    this.handleGraduatedChange = this.handleGraduatedChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleGraduatedChange(date) {
    this.setState({ graduated_date: date });
  }

  handleSubmit() {
    const {
      changeStatusGraduation,
      onHide,
      id,
      history,
    } = this.props;
    const { graduated_date } = this.state;
    const param = {
      id,
      is_graduate: "true",
      graduated_date,
    };

    const callback = () => {
      onHide();
      // detailPpds(id);
      history.push("/member");
    };
    changeStatusGraduation(param, callback);
  }

  render() {
    const { graduated_date } = this.state;
    const { changeStatusGraduation_pending } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          // size="lg"
          // centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <p style={{ fontSize: 20, fontWeight: 600 }}>
              Konfirmasi Kelulusan
            </p>
          </Modal.Header>
          <Modal.Body>
            <Column className={css(styles.bodyModal)}>
              <KeyboardDatePicker
                style={{ width: "100%" }}
                margin="normal"
                id="date-picker-dialog"
                label="Tanggal Lulus"
                format="dd/MM/yyyy"
                value={graduated_date}
                onChange={this.handleGraduatedChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={changeStatusGraduation_pending}
                className={css(styles.approveButton)}
                onClick={this.handleSubmit}
                startIcon={
                  changeStatusGraduation_pending ? (
                    <CircularProgress size={14} color="default" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                SUBMIT
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

function mapStateToProps({ chanchangeStatusGraduation }) {
  return {
    changeStatusGraduation_error: getPpdsError(chanchangeStatusGraduation),
    changeStatusGraduation_pending: getPpdsPending(chanchangeStatusGraduation),
  };
}

export default connect(mapStateToProps, { changeStatusGraduation, detailPpds })(
  withRouter(ModalPPDS)
);
