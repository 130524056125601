import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  addMessageBlast,
  uploadMessageBlast,
} from "../../actions/message-blast/message_blast_action";
import {
  getMessageBlastAdd,
  getMessageBlastAddPending,
  getMessageBlastAddError,
  getMessageBlastUpload,
  getMessageBlastUploadPending,
  getMessageBlastUploadError,
} from "../../reducers/message-blast/message_blast_reducer";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import MUIRichTextEditor from "mui-rte";
import MessageBlastAddHeaderComponent from "../../components/message-blast/MessageBlastAddHeaderComponent";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import { toast } from "react-toastify";
import ArrowForwardIcon from "../../assets/icon-arrow-forward";
import ArrowBackIcon from "../../assets/icon-arrow-back";
import InputComponent from "../../components/form/InputComponent";
import draftToHtml from "draftjs-to-html";
import FileComponent from "../../components/form/FileComponent";
import MultiInputComponent from "../../components/form/MultiInputComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDokumenMessageBlast from "./Modal_document_img";
import { default_api } from "../../constants/default_api";

class ModalAddMessageBlast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_text: "",
      branch_id: null,
      isSelection: true,
      selected_member: [],
      selected_non_member: [],
      errors: {},
      fields: {},
      berkas_message: null,
      modalDokumenMessageBlastShow: false,
    };
    this.tableRef = React.createRef();
    this.checkMember = this.checkMember.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBerkasChange = this.handleBerkasChange.bind(this);
    this.handleNonMember = this.handleNonMember.bind(this);
    this.handleOpenDocument = this.handleOpenDocument.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //     if (prevProps.show !== this.props.show && this.props.show === true) {
  //         this.props.reset();
  //         this.props.memberList();
  //         this.setState({ isSelection: true });
  //     }
  // }

  handleNonMember(value) {
    this.setState({ selected_non_member: value });
  }

  handleClick() {
    this.tableRef.current && this.tableRef.current.save();
  }

  handleEditorChange(text) {
    if (text && this.state.errors.editor) {
      this.setState(({ errors }) => ({ errors: { ...errors, editor: false } }));
    }
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDokumenMessageBlastShow: true });
  }

  handleValidation(editor) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!editor) {
      formIsValid = false;
      errors["editor"] = "Deskripsi tidak boleh kosong";
    }
    if (!fields.title) {
      formIsValid = false;
      errors["title"] = "Judul tidak boleh kosong";
    }
    // if (!fields.url) {
    //     formIsValid = false;
    //     errors["url"] = "Link berkas tidak boleh kosong";
    // }
    // if (!fields.berkas) {
    //     formIsValid = false;
    //     errors["berkas"] = "Berkas tidak boleh kosong";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleBerkasChange(e) {
    if (e) {
      const value = e.target.files;
      this.setState({ berkas_message: "Uploading..." });
      const callback = () => {
        this.setState({
          berkas_message: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  this.props.uploadMessageBlast_data,
                  "Berkas"
                )
              }
            />
          ),
        });
        this.setState(({ fields }) => {
          return {
            fields: {
              ...fields,
              berkas: value,
            },
          };
        });
        this.setState(({ errors }) => {
          return {
            errors: {
              ...errors,
              berkas: null,
            },
          };
        });
      };
      this.props.uploadMessageBlast(value[0], callback);
    }
  }

  validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  handleInputChange(e) {
    let value = e.target.value;
    const formName = e.target.name;
    if (formName !== "url") {
      this.setState(({ fields }) => {
        return {
          fields: {
            ...fields,
            [formName]: value,
          },
        };
      });
      if (value) {
        this.setState(({ errors }) => {
          return {
            errors: {
              ...errors,
              [formName]: null,
            },
          };
        });
      }
    } else {
      if (!this.validateUrl(value)) {
        value = "http://" + value;
        if (!this.validateUrl(value)) {
          let errors = { ...this.state.errors };
          const formIsValid = false;
          errors["url"] =
            "Format URL harus sesuai, contoh: admin.paboi.co.id, google.com";
          this.setState({ errors: errors });
          return formIsValid;
        }
      }
      this.setState(({ fields }) => {
        return {
          fields: {
            ...fields,
            [formName]: value,
          },
        };
      });
      if (value) {
        this.setState(({ errors }) => {
          return {
            errors: {
              ...errors,
              [formName]: null,
            },
          };
        });
      }
    }
  }

  handleSave(data) {
    const { addMessageBlast, handleRefresh, onHide, uploadMessageBlast_data } =
      this.props;
    const { fields, selected_member, selected_non_member } = this.state;
    let formData = new FormData();
    data = JSON.parse(data);
    let result = draftToHtml(data);
    if (uploadMessageBlast_data) {
      const fileLink = `<a href="${fields.url}"><img border="0" alt="Berkas" style="max-width:720px; max-height:720px" src="${default_api}/image/show/message_blast_attachment/${uploadMessageBlast_data}">`;
      result += fileLink;
    }
    const callback = () => {
      onHide();
      handleRefresh();
    };
    if (this.handleValidation(data.blocks[0].text)) {
      formData.append("title", fields.title);
      formData.append("description", result);
      if (selected_member.length > 0) {
        selected_member.map((item) => formData.append("users[]", item.id));
      }
      if (selected_non_member.length > 0) {
        selected_non_member.map((item) =>
          formData.append("non_users[]", item.value)
        );
      }
      for (let key in fields.berkas) {
        if (fields.berkas.hasOwnProperty(key)) {
          formData.append("files[]", fields.berkas[key]);
        }
      }
      if (fields.url && fields.url !== "") {
        formData.append("url", fields.url);
      }
      addMessageBlast(formData, callback);
    }
  }

  checkMember() {
    const { selected_member, selected_non_member } = this.state;
    if (
      selected_member.length > 0 ||
      (selected_non_member !== null && selected_non_member.length > 0)
    ) {
      this.setState({ isSelection: false });
    } else {
      toast.error("Masukkan penerima terlebih dahulu!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  backToSelection = () => {
    this.setState({ isSelection: true });
    this.handleRefresh({ null: null });
  };

  handleRefresh(state) {
    const { search_text, branch_id } = this.state;
    this.setState(state);
    if (Object.keys(state)[0] === "search_text") {
      this.props.memberList({
        search_text: state.search_text,
        branch_id: branch_id,
        length: 9999999,
      });
    } else if (Object.keys(state)[0] === "branch_id") {
      this.props.memberList({
        branch_id: state.branch_id,
        search_text: search_text,
        length: 9999999,
      });
    } else {
      this.props.memberList({
        branch_id: null,
        search_text: null,
        length: 9999999,
      });
    }
  }

  render() {
    const {
      show,
      onHide,
      addMessageBlast_pending,
      listMember_data,
      listMember_pending,
      // memberList
    } = this.props;
    const {
      selected_member,
      selected_non_member,
      isSelection,
      berkas_message,
      // search_text,
      // branch_id
    } = this.state;
    let data = [];
    // let records_total = 0;
    if (listMember_data) {
      let result = listMember_data.data.map((item, i) => {
        return {
          no: i + 1,
          ...item,
          tableData: {
            checked: selected_member.find(({ id }) => id === item.id) != null,
          },
        };
      });
      data = isSelection ? result : selected_member;
      // records_total = listMember_data.records_total;
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width-message"
      >
        <ModalDokumenMessageBlast
          show={this.state.modalDokumenMessageBlastShow}
          onHide={() => this.setState({ modalDokumenMessageBlastShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Message Blast</p>
        </Modal.Header>
        <Modal.Body>
          <Column
            vertical="center"
            horizontal="stretch"
            className={css(styles.bodyModal)}
          >
            {isSelection ? (
              <>
                <label className={css(styles.nonMemberSectionLabel)}>
                  Non Member
                </label>
                <MultiInputComponent
                  handleNonMember={this.handleNonMember}
                  propsValue={selected_non_member}
                />
                <label className={css(styles.memberSectionLabel)}>Member</label>
                <MessageBlastAddHeaderComponent
                  selectedMembers={selected_member.length}
                  handleRefresh={this.handleRefresh}
                />
                <MaterialTable
                  tableRef={this.tableRef}
                  columns={[
                    {
                      title: "No",
                      field: "no",
                      width: 20,
                      headerStyle: {
                        paddingLeft: 20,
                      },
                      cellStyle: {
                        paddingLeft: 20,
                      },
                    },

                    { title: "Nama", field: "name" },
                    { title: "No ID", field: "member_profile.paboi_id" },
                    {
                      title: "Cabang",
                      render: (rowData) => {
                        return rowData.branch_data
                          ? rowData.branch_data.name
                          : "-";
                      },
                    },
                  ]}
                  // data={(query) =>
                  //     new Promise((resolve, reject) => {
                  //         let param = {
                  //             page: query.page + 1,
                  //             length: 5,
                  //             search_text: search_text,
                  //             branch_id: branch_id
                  //         };
                  //         memberList(param, resolve, reject);
                  //     })
                  data={data}
                  isLoading={listMember_pending}
                  options={{
                    selection: this.state.isSelection,
                    pageSize: 5,
                    paginationType: "stepped",
                    pageSizeOptions: [],
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "Tidak ada data",
                    },
                  }}
                  components={{
                    Toolbar: () => <div />,
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Pagination: (props) => <PaginationComponent {...props} />,
                  }}
                  onSelectionChange={(members) => {
                    // const member = members.map(({ id }) => id);
                    this.setState({ selected_member: members });
                  }}
                />
              </>
            ) : (
              <>
                <label style={{ marginBottom: 10 }}>Judul</label>
                <InputComponent
                  name="title"
                  placeholder="Masukkan Judul"
                  onChange={this.handleInputChange}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                >
                  {this.state.errors.title}
                </div>
                <label style={{ marginTop: 5, marginBottom: 0 }}>
                  Deskripsi
                </label>
                <MUIRichTextEditor
                  onChange={(event) =>
                    this.handleEditorChange(
                      event.getCurrentContent().getPlainText()
                    )
                  }
                  onSave={this.handleSave}
                  ref={this.tableRef}
                  label="Masukkan Deskripsi"
                  controls={[
                    "title",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "undo",
                    "redo",
                    "clear",
                    "link",
                  ]}
                  maxLength={1000}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                >
                  {this.state.errors.editor}
                </div>
                <label style={{ marginBottom: 10 }}>Link Berkas</label>
                <InputComponent
                  name="url"
                  placeholder="ex: admin.paboi.co.id"
                  onChange={this.handleInputChange}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                >
                  {this.state.errors.url}
                </div>
                <FileComponent
                  type="file"
                  fileType="image"
                  onChange={this.handleBerkasChange}
                  title="upload berkas"
                  message={
                    berkas_message === null
                      ? "Maksimal 5000 KB"
                      : berkas_message
                  }
                  value={this.state.fields.berkas}
                />
                <div
                  style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                >
                  {this.state.errors.berkas}
                </div>
              </>
            )}
          </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            {isSelection ? (
              <Button
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                onClick={() => this.checkMember()}
                startIcon={<ArrowForwardIcon />}
              >
                Lanjut
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  startIcon={<ArrowBackIcon fill="#000000" />}
                  onClick={() => this.backToSelection()}
                >
                  Kembali
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={this.handleClick}
                  startIcon={
                    addMessageBlast_pending ? (
                      <CircularProgress size={14} color="secondary" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                >
                  Kirim
                </Button>
              </>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  memberSectionLabel: {
    marginTop: 10,
    fontWeight: "bold",
  },
  nonMemberSectionLabel: {
    fontWeight: "bold",
  },
});

// function validate(values) {
//     const { member, message_title, berkas } = values;
//     const errors = {};
//     if (!member) {
//         errors.member = "Member tidak boleh kosong!";
//     }
//     if (!message_title) {
//         errors.message_title = "Judul tidak boleh kosong!";
//     }
//     const berkasArrayErrors = [];
//     if (!berkas || !berkas.length) {
//         errors.berkas = "Berkas tidak boleh kosong!";
//     } else {
//         berkas.forEach((item, index) => {
//             if (!item) {
//                 berkasArrayErrors[index] = "Berkas tidak boleh kosong!"
//             }
//         });
//     }
//     return errors;
// }

// ModalAddMessageBlast = reduxForm({
//     form: "ModalAddMessageBlast",
//     validate: validate,
//     shouldError: () => true,
//     enableReinitialize: true,
// })(ModalAddMessageBlast);

function mapStateToProps({ addMessageBlast, memberList, uploadMessageBlast }) {
  return {
    addMessageBlast_pending: getMessageBlastAddPending(addMessageBlast),
    addMessageBlast_data: getMessageBlastAdd(addMessageBlast),
    addMessageBlast_error: getMessageBlastAddError(addMessageBlast),
    uploadMessageBlast_pending:
      getMessageBlastUploadPending(uploadMessageBlast),
    uploadMessageBlast_data: getMessageBlastUpload(uploadMessageBlast),
    uploadMessageBlast_error: getMessageBlastUploadError(uploadMessageBlast),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList),
  };
}

export default connect(mapStateToProps, {
  addMessageBlast,
  uploadMessageBlast,
  memberList,
})(withRouter(ModalAddMessageBlast));
