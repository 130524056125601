import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";
import CardSupport from "../../components/dasboard/CardSupport";
import CardDanaEstimasi from "../../components/dasboard/CardDanaEstimasiCabang";
import CardDanaCabang from "../../components/dasboard/CardDanaCabang";
import CardDanaMasuk from "../../components/dasboard/CardDanaMasukCabang";
import CardMemberCabang from "../../components/dasboard/CardMemberCabang";
import CardMaps from "../../components/dasboard/CardMapsCabang";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getDashboardData
} from "../../reducers/dashboard/cabang_reducer";
class DashboardAdminCabang extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <CardMemberCabang />
        <Row
          horizontal="space-between"
          alignItems="stretch"
          style={{ marginBottom: 20 }}
        >
          <div style={{ padding: "0 10px", width: "75%" }}>
            {/* < CardSebaranAnggota /> */}
            <CardMaps />
          </div>
          <div style={{ padding: "0 10px", width: "25%" }}>
            <CardSupport />
          </div>
        </Row>

        <Row style={{ marginBottom: 20 }} alignItems="stretch">
          <div style={{ padding: "0 10px", width: "33.33%" }}>
            <CardDanaEstimasi />
          </div>
          <div style={{ padding: "0 10px", width: "33.33%" }}>
            <CardDanaMasuk />
          </div>
          <div style={{ padding: "0 10px", width: "33.33%" }}>
            <CardDanaCabang />
          </div>
        </Row>
      </>
    );
  }
}

function mapStateToProps({
  listCandidate,
  listGraduated,
  listOrthopaedi,
  listSubspecialist,
}) {
  return {
    listCandidate_data: getDashboardData(listCandidate),
    listGraduated_data: getDashboardData(listGraduated),
    listOrthopaedi_data: getDashboardData(listOrthopaedi),
  };
}

export default connect(mapStateToProps, {
  listBranch,
})(DashboardAdminCabang);
