import {
    LIST_IURAN_PENDING,
    LIST_IURAN_SUCCESS,
    LIST_IURAN_ERROR,
    DETAIL_IURAN_PENDING,
    DETAIL_IURAN_SUCCESS,
    DETAIL_IURAN_ERROR,
    ADD_IURAN_PENDING,
    ADD_IURAN_SUCCESS,
    ADD_IURAN_ERROR,
    SET_SELECTED_IURAN,
    APPROVAL_IURAN_PENDING,
    APPROVAL_IURAN_SUCCESS,
    APPROVAL_IURAN_ERROR,
} from "../../actions/iuran/iuran_action";

const initialState = {
    pending: false,
    error: null,
};

export function listIuran(
    state = { ...initialState, list_iuran: null },
    action
) {
    switch (action.type) {
        case LIST_IURAN_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_IURAN_SUCCESS:
            return {
                ...state,
                pending: false,
                list_iuran: action.data,
            };
        case LIST_IURAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailIuran(
    state = { ...initialState, detail_iuran: null },
    action
) {
    switch (action.type) {
        case DETAIL_IURAN_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_IURAN_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_iuran: action.data,
            };
        case DETAIL_IURAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addIuran(state = initialState, action) {
    switch (action.type) {
        case ADD_IURAN_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_IURAN_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case ADD_IURAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedIuran(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_IURAN:
            return action.value;
        default:
            return state;
    }
}

export function approvalIuran(
    state = { ...initialState, approvalIuran: null },
    action
) {
    switch (action.type) {
        case APPROVAL_IURAN_PENDING:
            return {
                ...state,
                pending: true,
            };
        case APPROVAL_IURAN_SUCCESS:
            return {
                ...state,
                pending: false,
                approvalIuran: action.data
            };
        case APPROVAL_IURAN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListIuran = (state) => state.list_iuran;
export const getListIuranPending = (state) => state.pending;
export const getListIuranError = (state) => state.error;
export const getDetailIuran = (state) => state.detail_iuran;
export const getSelectedSKP = (state) => state.selectedSKP;
export const getIuranApprovalPending = (state) => state.pending;
export const getIuranApproval = (state) => state.approvalIuran;
export const getIuranApprovalError = (state) => state.error;
export const getIuranAddPending = (state) => state.pending;
export const getIuranAdd = (state) => state.approvalIuran;
export const getIuranAddError = (state) => state.error;