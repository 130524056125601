import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import { getRegistrationListPending, getRegistrationList, getRegistrationListError } from "../../reducers/registrasi_sp2/registrasi_reducer";
import { connect } from "react-redux";
import { default_api } from "../../constants/default_api";

class ModalDetailBuktiPembayaran extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Pembayaran
            </p>
          <Column className={css(styles.bodyModal)}>
          </Column>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            <img src={default_api + "/image/show/ppds_payment/" + this.props.imgUrl} alt="bukti-pembayaran" className={css(styles.imgBuktiPembayaran)}></img>
          </Column>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10
  },
  imgBuktiPembayaran: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto"
  }
});

function mapStateToProps({ registrationList }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
  };
}


export default connect(mapStateToProps, { registrationList })(withRouter(ModalDetailBuktiPembayaran));
