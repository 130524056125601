import {
    LIST_ROLE_PENDING,
    LIST_ROLE_SUCCESS,
    LIST_ROLE_ERROR,

} from "../../actions/master-data/role_action";

const initialState = {
    pending: false,
    error: null
};

export function roleList(
    state = { ...initialState, roleList: null },
    action
) {
    switch (action.type) {
        case LIST_ROLE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_ROLE_SUCCESS:
            return {
                ...state,
                pending: false,
                roleList: action.data,
            };
        case LIST_ROLE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getRoleListPending = (state) => state.pending;
export const getRoleList = (state) => state.roleList;
export const getRoleListError = (state) => state.error;