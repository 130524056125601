import API from "../API";
import { actionPending, actionSuccess, actionError } from '../index';
import { toast } from 'react-toastify';
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_USER_PENDING = 'LIST_USER_PENDING'
export const LIST_USER_SUCCESS = 'LIST_USER_SUCCESS'
export const LIST_USER_ERROR = 'LIST_USER_ERROR'
export const EDIT_USER_PENDING = 'EDIT_USER_PENDING'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'
export const ADD_USER_PENDING = 'ADD_USER_PENDING'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_ERROR = 'ADD_USER_ERROR'
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const CHANGE_IMAGE_PROFILE_PENDING = 'CHANGE_IMAGE_PROFILE_PENDING'
export const CHANGE_IMAGE_PROFILE_SUCCESS = 'CHANGE_IMAGE_PROFILE_SUCCESS'
export const CHANGE_IMAGE_PROFILE_ERROR = 'CHANGE_IMAGE_PROFILE_ERROR'

const LIST_USER_URL = 'master_data/user/find';
const EDIT_USER_URL = 'master_data/user/edit';
const ADD_USER_URL = 'master_data/user/add';
const DELETE_USER_URL = 'master_data/user/delete';
const CHANGE_IMAGE_PROFILE_URL = 'master_data/user/change_image_profile'
const CHANGE_IMAGE_PROFILE_PPDS = 'master_data/user/change_image_profile_ppds'

export function userList(param = {}, resolve, reject, callback) {
    return dispatch => {
        dispatch(actionPending(LIST_USER_PENDING));
        API.post(LIST_USER_URL, param, getHeader())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(actionSuccess(LIST_USER_SUCCESS, res.data));
                const { records_total } = res.data;
                let data = res.data.data.map((item, i) => ({
                    ...item, no: i + 1 + (param.page - 1) * 10,
                    ...item, branch_name: item.branch_id === null ? "-" : item.branch_data.name
                }));
                if (resolve) {
                    resolve({
                        data: data,
                        page: param.page - 1,
                        totalCount: records_total
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(actionError(LIST_USER_ERROR, error));
                if (error.response && error.response.status === 500) {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.error(error.toString(), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    };
}

export function editUser(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_USER_PENDING));
        API.post(EDIT_USER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_USER_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah user");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_USER_ERROR, error));
                toastError(error, "Gagal mengubah user, ");
            });
    };
}

export function addUser(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_USER_PENDING));
        API.post(ADD_USER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_USER_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambah user");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_USER_ERROR, error));
                toastError(error, "Gagal menambah user, ");
            });
    };
}

export function deleteUser(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_USER_PENDING));
        API.post(DELETE_USER_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_USER_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus user");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_USER_ERROR, error));
                toastError(error, "Gagal menghapus user, ");
            });
    };
}

export function changeImageProfile(param = {}, resolve, callback) {
    const api = param.isPPDS ? CHANGE_IMAGE_PROFILE_PPDS : CHANGE_IMAGE_PROFILE_URL
    return (dispatch) => {
        dispatch(actionPending(CHANGE_IMAGE_PROFILE_PENDING));
        const config = {
            headers: { 'content-type': 'multipart/form-data', 'Authorization': getHeader().headers.Authorization }
        }
        API.post(api, param, config)
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(CHANGE_IMAGE_PROFILE_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah foto profil");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(CHANGE_IMAGE_PROFILE_ERROR, error));
                toastError(error, "Gagal mengubah foto profil, ");
            });
    };
}