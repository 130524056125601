import {
  INTEREST_LIST_PENDING,
  INTEREST_LIST_SUCCESS,
  INTEREST_LIST_ERROR,
} from "../../actions/master-data/interest_action.js";

const initialState = {
  pending: false,
  error: null,
};

export function interestList(
  state = { ...initialState, interestList: null },
  action
) {
  switch (action.type) {
    case INTEREST_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case INTEREST_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        interestList: action.data,
      };
    case INTEREST_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getInterestListPending = (state) => state.pending;
export const getInterestList = (state) => state.interestList;
export const getInterestListError = (state) => state.error;
