import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastError = (error, msg) => {
  let errMessage = "";
  if (error.response && error.response.status === 500) {
    errMessage = error.response.data.message;
  } else {
    errMessage = error.toString();
  }
  toast.error(msg + errMessage, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastSuccess = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const toastWarning = (msg) => {
  toast.warn(msg, {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
