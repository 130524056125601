import {
  LIST_PROVINCE_PENDING,
  LIST_PROVINCE_SUCCESS,
  LIST_PROVINCE_ERROR,
} from "../../actions/master-data/province_temp_action";

const initialState = {
  pending: false,
  list_province: null,
  detail_province: null,
  error: null,
};

export function listProvince(state = initialState, action) {
  switch (action.type) {
    case LIST_PROVINCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PROVINCE_SUCCESS:
      return {
        ...state,
        pending: false,
        list_province: action.data,
      };
    case LIST_PROVINCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListProvince = (state) => state.list_province;
export const getDetailProvince = (state) => state.detail_province;
export const getProvincePending = (state) => state.pending;
export const getProvinceError = (state) => state.error;
