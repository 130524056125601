import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { login, logout } from "../../actions/login/login_action";
import Button from "@material-ui/core/Button";
import { reduxForm, Field } from "redux-form";
import { renderInput } from "../../components/form/FormComponent";
import IconInput from "../../assets/icon-input";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';

class newLogin extends Component {
  constructor(props) {
    super(props);
    this.props.logout();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isPPDS: false
    }
  }

  handleSubmit(values) {
    const { login } = this.props;
    login(values.emailID, values.password, this.state.isPPDS);
  }

  handleChange(target){
    this.setState({isPPDS: !this.state.isPPDS});
  }

  render() {
    const { handleSubmit, loggingIn } = this.props;
    return (
      <Grid container className={css(styles.body)}>
        <Grid item xs={false} sm={false} md={6} lg={6} className={css(styles.sideContainer)}></Grid>
        <Grid item xs={10} sm={10} md={6} lg={6} className={css(styles.loginContainer)}>
          <Column>
            <Row>
              <img
                src={require("../../assets/ic_paboi.png")}
                className={css(styles.logo)}
                alt="logo-paboi"
              />
            </Row>
            <span className={css(styles.loginTitle)}>Login ke PABOI</span>
            <span className={css(styles.loginDesc)}>
              Masukkan email dan password Anda
            </span>
            <hr className={css(styles.loginSeparator)} />
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              className={css(styles.loginForm)}
            >
              <Field
                name="emailID"
                placeholder="Email atau ID PABOI"
                component={renderInput}
              />
              <Field
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="current-password"
                component={renderInput}
                className={css(styles.inputMargin)}
              />
              <input type="checkbox" onChange={this.handleChange}/>
              <span className={css(styles.daftarLabel)}>
                 Login sebagai PPDS
              </span>
              <Row horizontal="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loggingIn}
                  className={css(styles.approveButton)}
                  startIcon={
                    loggingIn ? (
                      <CircularProgress size={14} color="secondary" style={{ marginRight: 10 }} />
                    ) : (
                        <IconInput />
                      )
                  }
                >
                  login
                </Button>

                <Column>
                  <span className={css(styles.daftarLabel)}>
                    Belum punya akun?
                  </span>
                  <span
                    onClick={() => this.props.history.push("/register")}
                    className={css(styles.inputLinkDaftar)}
                  >
                    Daftar
                  </span>
                </Column>
              </Row>
            </form>
            <Row>
              <span
                onClick={() => this.props.history.push("/forgot-password")}
                className={css(styles.inputLink)}
              >
                Lupa password?
              </span>
            </Row>
          </Column>
        </Grid>
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    backgroundImage: "url(/bg_login_v1.png)",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  sideContainer: {
    // width: "30%",
    // height: "100vh",
    // background:
    //   "transparent linear-gradient(180deg, #2CB96A 0%, #43C767 66%, #63DB62 100%) 0% 0% no-repeat padding-box",
  },
  loginContainer: {
    // width: "auto",
    margin: "auto",
  },
  loginTitle: {
    color: "#04AA59",
    fontSize: 36,
    fontWeight: "bold",
    margin: 15,
  },
  loginDesc: {
    fontSize: 16,
    margin: "0 15px",
  },
  loginSeparator: {
    maxWidth: 400,
    margin: "20px 15px",
    borderTop: "2px solid rgba(0,0,0,0.1)",
    '@media (max-width: 480px)': {
      width: "100%"
    }
  },
  loginForm: {
    width: 400,
    marginLeft: 15,
    '@media (max-width: 480px)': {
      width: "100%"
    }
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    margin: "15px 0",
  },
  inputMargin: {
    margin: "15px 0",
  },
  inputLink: {
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    marginLeft: 15,
    marginTop: 30,
    ":hover": {
      cursor: "pointer",
    },
  },
  inputLinkDaftar: {
    fontSize: 16,
    color: "black",
    textDecoration: "none",
    // marginLeft: 250,
    marginTop: 5,
    textAlign: "right",
    ":hover": {
      cursor: "pointer",
    },
  },
  daftarLabel: {
    // marginLeft: 15,
    marginTop: 15,
    textAlign: "right",
  },
});

function validate(values) {
  const { emailID, password } = values;
  const errors = {};
  if (!emailID) {
    errors.emailID = "Email atau ID PABOI tidak boleh kosong";
  }
  if (!password) {
    errors.password = "Password tidak boleh kosong";
  }
  return errors;
}

newLogin = reduxForm({
  // a unique name for the form
  form: "login",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(newLogin);

function mapStateToProps({ authentication: { login, loggingIn } }) {
  return {
    login,
    loggingIn,
  };
}

export default connect(mapStateToProps, { login, logout })(newLogin);
