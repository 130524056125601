import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_STR_KT_PENDING = "LIST_STR_KT_PENDING";
export const LIST_STR_KT_SUCCESS = "LIST_STR_KT_SUCCESS";
export const LIST_STR_KT_ERROR = "LIST_STR_KT_ERROR";

export const DETAIL_STR_KT_PENDING = "DETAIL_STR_KT_PENDING";
export const DETAIL_STR_KT_SUCCESS = "DETAIL_STR_KT_SUCCESS";
export const DETAIL_STR_KT_ERROR = "DETAIL_STR_KT_ERROR";

export const ADD_STR_KT_PENDING = "ADD_STR_KT_PENDING";
export const ADD_STR_KT_SUCCESS = "ADD_STR_KT_SUCCESS";
export const ADD_STR_KT_ERROR = "ADD_STR_KT_ERROR";

export const EDIT_STR_KT_PENDING = "EDIT_STR_KT_PENDING";
export const EDIT_STR_KT_SUCCESS = "EDIT_STR_KT_SUCCESS";
export const EDIT_STR_KT_ERROR = "EDIT_STR_KT_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_STR_KT";
export const APPROVE_STR_KT_PENDING = "APPROVE_STR_KT_PENDING";
export const APPROVE_STR_KT_SUCCESS = "APPROVE_STR_KT_SUCCESS";
export const APPROVE_STR_KT_ERROR = "APPROVE_STR_KT_ERROR";

const LIST_STR_KT_URL = "member/str_kt/find";
const EDIT_STR_KT_URL = "member/str_kt/edit";
const ADD_STR_KT_URL = "member/str_kt/add";
const APPROVE_STR_KT_URL = "member/str_kt/approve";

export function listStrKT(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_STR_KT_PENDING));
    API.post(LIST_STR_KT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_STR_KT_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_STR_KT_ERROR, error));
        toastError(error, "Gagal mendapatkan list STR KT, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addStrKT({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_STR_KT_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    formData.append("str_no", restParam.str_no);
    formData.append("str_kt_no", restParam.str_kt_no);
    API.post(ADD_STR_KT_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_STR_KT_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan STR KT");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_STR_KT_ERROR, error));
        toastError(error, "Gagal menambahkan STR KT, ");
      });
  };
}

export function detailStrKT(id = null, callback) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_STR_KT_PENDING));
    API.post(LIST_STR_KT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_STR_KT_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_STR_KT_ERROR, error));
        toastError(error, "Gagal mendapatkan list STR KT, ");
      });
  };
}

export function editStrKT(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_STR_KT_PENDING));
    API.post(EDIT_STR_KT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_STR_KT_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah STR KT");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_STR_KT_ERROR, error));
        toastError(error, "Gagal mengubah STR KT, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveStrKT(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_STR_KT_PENDING));
    API.post(APPROVE_STR_KT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_STR_KT_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status STR KT");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_STR_KT_ERROR, error));
        toastError(error, "Gagal mengubah status STR KT, ");
      });
  };
}