import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailRegistrationStatusComponent from "../../components/detail/DetailRegistrationStatusComponent";
import IconError from "../../assets/icon-error";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
// import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { transrsList } from "../../actions/transrs/transrs_action";
import {
  getTransrsPending,
  getTransrsList,
  getTransrsError,
} from "../../reducers/transrs/transrs_reducer";
import { connect } from "react-redux";
import { getUser } from "../../helpers/user";
import ModalApprovalTransRS from "./Modal_approval_transrs";
import ModalRejectTransRS from "./Modal_reject_transrs";
import ModalPenerbitanSurat from "./Modal_penerbitan_surat_transrs";
// import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import moment from "moment";
import localization from "moment/locale/id";
import { editTransrs } from "../../actions/transrs/transrs_action";
import {
  getTransrsEdit,
  getTransrsEditPending,
} from "../../reducers/transrs/transrs_reducer";
import FormContainer from "../../components/container/FormContainer";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import { listHospital } from "../../actions/master-data/hospital_action";
import { listBranch } from "../../actions/master-data/branch_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { memberList } from "../../actions/member/member_action";
import { getMemberList } from "../../reducers/member/member_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { default_api } from "../../constants/default_api";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import { Adb } from "@material-ui/icons";
// import ModalSuratPermohonan from "./Modal_surat_permohonan";

class DetailTransRS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forRefresh: false,
      modalApprovalTransRSShow: false,
      modalRejectTransRSShow: false,
      modalPenerbitanSuratShow: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      modalSuratPermohonanShow: false,
      modal_title: "",
      imgUrl: "",
      id: "",
      isEdit: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    let param_transrsList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_transrsList.id = id;
    this.props.transrsList(param_transrsList);
  }

  handleOpenDocument(imgUrl, modal_title, folder = "") {
    if (modal_title === "CV") {
      window.open(default_api + "/member/download/cv/" + imgUrl + ".pdf");
    } else {
      this.setState({ folder: folder });
      this.setState({ imgUrl: imgUrl });
      this.setState({ modal_title: modal_title });
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true });
      } else {
        this.setState({ modalDocumentImgShow: true });
      }
    }
  }

  // handleOpenLetter(id, modal_title) {
  //     this.setState({ id: id })
  //     this.setState({ modal_title: modal_title })
  //     this.setState({ modalSuratPermohonanShow: true })
  // }

  componentDidMount() {
    let param_transrsList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_transrsList.id = id;
    this.props.transrsList(param_transrsList);
    if (getUser().role_codes[0] === "member") {
      this.props.listHospital();
      this.props.listBranch({ length: 999 });
    }
    this.props.memberList();
  }

  shouldComponentRender() {
    const { transrsList_pending } = this.props;
    if (transrsList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
    // this.setState({ isEditFile: false });
    this.props.reset();
  };

  handleSubmit = (values) => {
    const { transrsList_data, editTransrs, transrsList } = this.props;
    const param = {
      branch_to: values.branch_to.value,
      hospital_id_to: values.hospital_id_to.value,
      id: transrsList_data.data[0].id,
      str_no: transrsList_data.data[0].str_no,
      sip_no: transrsList_data.data[0].sip_no,
      serkom_no: transrsList_data.data[0].serkom_no,
      surat_pendukung_filename:
        transrsList_data.data[0].surat_pendukung_filename,
      attachments: values.file.map((item) => ({
        attachment_name: item.name,
        attachment_filename: item.value,
      })),
    };
    const callback = () => {
      transrsList();
      this.handleTriggerFormEdit();
    };
    editTransrs(param, callback);
  };

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  };

  render() {
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder,
      isEdit,
    } = this.state;
    const user = getUser().role_codes[0];
    const user_branch = getUser().branch_id;
    const {
      transrsList_data,
      handleSubmit,
      editTransrs_pending,
      listBranch_data,
      listHospital_data,
      initialValues,
    } = this.props;
    let data = [];
    let branchOptions = [];
    let hospitalOptions = [];
    let id,
      filename,
      status,
      messageTitle,
      message,
      request_code,
      imgUrl_reject,
      branch_from_id,
      branch_to_id,
      reason,
      surat_pendukung_filename,
      str_filename,
      ktp_filename,
      sip_filename,
      serkom_filename,
      member_id = "",
      mutation_role_code,
      mutation_role_name,
      step;
    if (transrsList_data) {
      data = transrsList_data.data[0];
      id = data.id;
      member_id = data.member_data.id;
      status = data.status;
      step = data.step;
      branch_from_id = data.branch_from;
      branch_to_id = data.branch_to;
      request_code = data.request_code;
      imgUrl_reject = data.last_reject_filename;
      reason = data.last_reject_notes;
      filename = data.id;
      str_filename = data.str_filename;
      sip_filename = data.sip_filename;
      serkom_filename = data.serkom_filename;
      ktp_filename = data.ktp_filename;
      surat_pendukung_filename = data.surat_pendukung_filename;
      mutation_role_code = data.role_next_approve
        ? data.role_next_approve.role_code
        : null;
      mutation_role_name = data.role_next_approve
        ? data.role_next_approve.role_name
        : null;
      messageTitle =
        data.status === 0
          ? "Menunggu Verifikasi"
          : data.status === 1
          ? "Disetujui"
          : "Ditolak";
      // message = status === 0 & step === 1 ? "Trans RS sedang diproses oleh Admin PABOI Cabang" : status === 1 ? "Telah disetujui oleh Ketua PABOI Cabang" : step === 1 ? "Telah ditolak oleh Admin PABOI Cabang" : "Telah ditolak oleh Ketua PABOI Cabang";

      message =
        (status === 0) & (step === 1)
          ? "Trans RS sedang diproses oleh Admin PABOI Cabang Asal"
          : step === 2 && status === 0
          ? "Trans RS sedang diproses oleh Ketua PABOI Cabang Asal"
          : step === 3 && status === 0
          ? "Trans RS sedang diproses oleh Admin PABOI Cabang Tujuan"
          : step === 4 && status === 0
          ? "Trans RS sedang diproses oleh Ketua PABOI Cabang Tujuan"
          : status === 1
          ? "Telah disetujui oleh Ketua PABOI Cabang"
          : step === 1
          ? "Telah ditolak oleh Admin PABOI Cabang"
          : "Telah ditolak oleh Ketua PABOI Cabang";
    }
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }
    const CVfilename = getUser().id;
    return (
      <ContentContainer>
        <ModalApprovalTransRS
          show={this.state.modalApprovalTransRSShow}
          onHide={() => this.setState({ modalApprovalTransRSShow: false })}
          id={id}
          handleRefresh={this.handleRefresh}
        />
        <ModalRejectTransRS
          show={this.state.modalRejectTransRSShow}
          onHide={() => this.setState({ modalRejectTransRSShow: false })}
          id={id}
          handleRefresh={this.handleRefresh}
        />
        <ModalPenerbitanSurat
          show={this.state.modalPenerbitanSuratShow}
          onHide={() => this.setState({ modalPenerbitanSuratShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        {/* <ModalSuratPermohonan
                    show={this.state.modalSuratPermohonanShow}
                    onHide={() => this.setState({ modalSuratPermohonanShow: false })}
                    id={this.state.id}
                    modal_title={this.state.modal_title}
                /> */}
        <Row style={{ minHeight: "78vh" }}>
          <Row className={css(styles.leftDetailContainer)}>
            <Column>
              <span className={css(styles.billDate)}>Kode Request</span>
              <DetailNameComponent name={request_code} />
              <DetailRegistrationStatusComponent
                icon={IconError}
                status={status}
                messageTitle={messageTitle}
                message={message}
                imgUrl={imgUrl_reject}
                folder={"member_hospital_mutation"}
                reason={reason}
              />
              {getUser().role_codes[0] === mutation_role_code &&
                status === 0 &&
                (step === 1 || step === 2) &&
                user_branch === branch_from_id && (
                  <Row style={{ width: "100%" }} horizontal="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.approveButton)}
                      onClick={() =>
                        this.setState({ modalApprovalTransRSShow: true })
                      }
                    >
                      Verifikasi
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.rejectButton)}
                      onClick={() =>
                        this.setState({ modalRejectTransRSShow: true })
                      }
                    >
                      Tolak
                    </Button>
                  </Row>
                )}
              {getUser().role_codes[0] === mutation_role_code &&
                status === 0 &&
                (step === 3 || step === 4) &&
                user_branch === branch_to_id && (
                  <Row style={{ width: "100%" }} horizontal="space-between">
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.approveButton)}
                      onClick={() =>
                        this.setState({ modalApprovalTransRSShow: true })
                      }
                    >
                      Verifikasi
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={css(styles.rejectButton)}
                      onClick={() =>
                        this.setState({ modalRejectTransRSShow: true })
                      }
                    >
                      Tolak
                    </Button>
                  </Row>
                )}
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <form
                onSubmit={handleSubmit(this.handleSubmit)}
                style={{ width: "100%" }}
              >
                <Row
                  vertical="center"
                  horizontal="space-between"
                  style={{ width: "100%", marginBottom: 20 }}
                >
                  <DetailTitleComponent
                    title="Informasi Trans RS"
                    marginBottom={true}
                  />
                  {user === "member" && status === 2 && (
                    <EditButtonComponent
                      isEdit={isEdit}
                      triggerIsEdit={this.handleTriggerFormEdit}
                      isLoading={editTransrs_pending}
                    />
                  )}
                </Row>
                <FormContainer label="Tanggal Pengajuan">
                  <Field
                    name="created_at"
                    disabled
                    isDetail={!isEdit}
                    component={renderInput}
                  />
                </FormContainer>
                {user !== "member" && (
                  <FormContainer label="Nama Dokter">
                    <Field
                      name="name"
                      disabled
                      isDetail={!isEdit}
                      component={renderInput}
                    />
                  </FormContainer>
                )}
                <FormContainer label="ID Member">
                  <Field
                    name="paboi_id"
                    disabled
                    isDetail={!isEdit}
                    component={renderInput}
                  />
                </FormContainer>
                <FormContainer label="Cabang Asal">
                  <Field
                    name="branch_from"
                    disabled
                    isDetail={!isEdit}
                    component={renderInput}
                  />
                </FormContainer>
                <FormContainer label="Cabang Tujuan">
                  <Field
                    name="branch_to"
                    isDetail={!isEdit}
                    placeholder="Pilih cabang tujuan"
                    options={branchOptions}
                    component={renderSelect}
                  />
                </FormContainer>
                <FormContainer label="Tempat Praktik Tujuan">
                  <Field
                    name="hospital_id_to"
                    isDetail={!isEdit}
                    placeholder="Pilih tempat praktik tujuan"
                    options={hospitalOptions}
                    isAsync
                    asyncUrl="master_data/hospital/find"
                    component={renderSelect}
                  />
                </FormContainer>
                <label className={css(styles.detailSectionName)}>Berkas</label>
                <DetailContentComponent
                  label="Surat Permohonan Tertulis"
                  value={
                    surat_pendukung_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            surat_pendukung_filename,
                            "Surat Permohonan Tertulis",
                            "member_hospital_mutation"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas KTP"
                  value={
                    ktp_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            ktp_filename,
                            "KTP",
                            "member_supporting_files"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas CV"
                  value={
                    <DetailContentLihatBerkas
                      onClick={() =>
                        this.handleOpenDocument(
                          user !== "member" ? member_id : CVfilename,
                          "CV"
                        )
                      }
                    />
                  }
                />
                <DetailContentComponent
                  label="Berkas STR"
                  value={
                    str_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            str_filename,
                            "STR",
                            "member_str"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas SIP"
                  value={
                    sip_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            sip_filename,
                            "SIP",
                            "member_sip"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <DetailContentComponent
                  label="Berkas Serkom"
                  value={
                    serkom_filename !== null ? (
                      <DetailContentLihatBerkas
                        onClick={() =>
                          this.handleOpenDocument(
                            serkom_filename,
                            "Serkom",
                            "member_serkom"
                          )
                        }
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                {isEdit ? (
                  <FormContainer
                    label="Berkas"
                    contentValueStyle={{ margin: "20px 0" }}
                  >
                    <FieldArray
                      name="file"
                      isDetail={!isEdit}
                      component={DynamicFormComponent}
                      changeFileValue={this.changeFileValue}
                      initialValues={initialValues}
                      folderPath="member_hospital_mutation"
                    />
                  </FormContainer>
                ) : (
                  <FieldArray
                    name="file"
                    isDetail={!isEdit}
                    component={DynamicFormComponent}
                    changeFileValue={this.changeFileValue}
                    initialValues={initialValues}
                    folderPath="member_hospital_mutation"
                  />
                )}
                {status === 1 && getUser().role_codes[0] !== "member" && (
                  <>
                    <label className={css(styles.detailSectionName)}>
                      Lampiran
                    </label>
                    <FormContainer label="Surat Lampiran 1">
                      <Field
                        isDetail={!isEdit}
                        isSuratRekomen={true}
                        filename={filename}
                        filePath="hospital_mutation_request"
                        name="berkas"
                        type="file"
                        component={renderFile}
                      />
                    </FormContainer>
                    <FormContainer label="Surat Lampiran 2">
                      <Field
                        isDetail={!isEdit}
                        isSuratRekomen={true}
                        filename={filename}
                        filePath="hospital_mutation_request/member"
                        name="berkas"
                        type="file"
                        component={renderFile}
                      />
                    </FormContainer>
                  </>
                )}
                {status === 1 && getUser().role_codes[0] === "member" && (
                  <>
                    <label className={css(styles.detailSectionName)}>
                      Lampiran
                    </label>
                    <FormContainer label="Surat Lampiran 1">
                      <Field
                        isDetail={!isEdit}
                        isSuratRekomen={true}
                        filename={filename}
                        filePath="hospital_mutation_request/member"
                        name="berkas"
                        type="file"
                        component={renderFile}
                      />
                    </FormContainer>
                  </>
                )}
              </form>
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  billDate: {
    color: "#495057",
    opacity: "0.7",
    marginBottom: 10,
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20,
  },
});

function validate(values) {
  const {
    branch_to,
    hospital_id_to,
    // file
  } = values;
  const errors = {};
  // const fileArrayErrors = [];
  if (!branch_to) {
    errors.branch_to = "Cabang tujuan wajib diisi";
  }
  if (!hospital_id_to) {
    errors.hospital_id_to = "Tempat praktik tujuan wajib diisi";
  }
  // if (!file || !file.length) {
  //     errors.file = {
  //         _error: "Berkas tidak boleh kosong"
  //     }
  // } else {
  //     file.forEach((item, index) => {
  //         if (!item) {
  //             fileArrayErrors[index] = "Berkas tidak boleh kosong"
  //         }
  //     })
  // }
  // if (fileArrayErrors.length) {
  //     errors.file = fileArrayErrors;
  // }
  return errors;
}

DetailTransRS = reduxForm({
  // a unique name for the form
  form: "DetailTransRS",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(DetailTransRS);

function mapStateToProps(state) {
  const { transrsList, editTransrs, listHospital, listBranch, memberList } =
    state;
  let initialValues = {};
  const transrsList_data = getTransrsList(transrsList);
  if (transrsList_data && transrsList_data !== 0) {
    const data = transrsList_data.data[0];
    initialValues = {
      created_at: moment(data.created_at)
        .locale("id", localization)
        .format("DD MMM YYYY"),
      branch_from: data.branch_from_data.name,
      paboi_id: data.member_data.member_profile.paboi_id,
      name: data.member_data.name,
      hospital_id_to: {
        label: data.hospital_to_data.name,
        value: data.hospital_to_data.id,
      },
      branch_to: {
        label: data.branch_to_data.name,
        value: data.branch_to_data.id,
      },
      file: data.hospital_mutation_attachments.map((item) => ({
        value: item.attachment_filename,
        name: item.attachment_name,
      })),
    };
  }
  return {
    transrsList_pending: getTransrsPending(transrsList),
    transrsList_data: getTransrsList(transrsList),
    transrsList_error: getTransrsError(transrsList),
    editTransrs_pending: getTransrsEditPending(editTransrs),
    editTransrs_data: getTransrsEdit(editTransrs),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    memberList_data: getMemberList(memberList),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  transrsList,
  editTransrs,
  listHospital,
  listBranch,
  memberList,
})(withRouter(DetailTransRS));
