import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const BRANCHMAN_LIST_PENDING = "BRANCHMAN_LIST_PENDING";
export const BRANCHMAN_LIST_SUCCESS = "BRANCHMAN_LIST_SUCCESS";
export const BRANCHMAN_LIST_ERROR = "BRANCHMAN_LIST_ERROR";
export const ADD_BRANCHMAN_SUCCESS = "ADD_BRANCHMAN_SUCCESS";
export const ADD_BRANCHMAN_ERROR = "ADD_BRANCHMAN_ERROR";
export const ADD_BRANCHMAN_PENDING = "ADD_BRANCHMAN_PENDING";

const BRANCHMAN_LIST_URL = "branch_management/find";
const ADD_BRANCHMAN_URL = "branch_management/add";

export function branchmanList(param = {}, callback) {
  return (dispatch) => {
    dispatch(actionPending(BRANCHMAN_LIST_PENDING));
    API.post(BRANCHMAN_LIST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(BRANCHMAN_LIST_SUCCESS, res.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(BRANCHMAN_LIST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function addBranchman(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_BRANCHMAN_PENDING));
    API.post(ADD_BRANCHMAN_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_BRANCHMAN_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah pengurus cabang");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_BRANCHMAN_ERROR, error));
        toastError(error, "Gagal mengubah pengurus cabang, ");
      });
  };
}