import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Placeholder from "./skp_placeholder.js";
import FadeIn from "react-fade-in";

export default (props) => {
    const { current_skp, submit_skp, submit_status, total_skp_approved, outstanding_skp, isLoad, user, status } = props;
    return (
        <Column>
            <div className={css(styles.detailHeaderContainer)}>
                <Row>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div className={css(styles.detailHeaderSKPColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                SKP Terkumpul
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && current_skp === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {submit_skp}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div className={css(styles.detailHeaderSKPColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                SKP Outstanding
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && current_skp === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {outstanding_skp}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div className={css(styles.detailHeaderSKPColumnValue)}>
                            <div className={css(styles.detailHeaderTitle)}>
                                SKP Approved
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && current_skp === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {total_skp_approved}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                    <Column className={css(styles.detailHeaderSKPColumn)}>
                        <div>
                            <div className={css(styles.detailHeaderTitle)}>
                                Status Berkas
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && current_skp === null ? (
                                    <Placeholder title="" />
                                ) :
                                    <FadeIn>
                                        {status === 0 && submit_status === 0
                                            ? "Draft"
                                            : status === 0 && submit_status === 1
                                                ? "Menunggu disetujui"
                                                : status === 1
                                                    ? "Approved oleh "
                                                    : status === 2 ? "Ditolak" : "-"} {status === 0 && submit_status === 0 ? '' : user}
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
        </Column>
    )
}

const styles = StyleSheet.create({
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderSKPColumn: {
        margin: "20px 20px 20px 30px",
        width: "25%"
    },
    detailHeaderSKPColumnValue: {
        borderRight: "2px solid rgb(112, 112, 112, 0.2)"
    },
    detailHeaderSKPOutstandingColumn: {
        margin: "20px 50px",
        width: "15%"
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
})