import {
    LIST_TRAINING_PENDING,
    LIST_TRAINING_SUCCESS,
    LIST_TRAINING_ERROR,
    EDIT_TRAINING_PENDING,
    EDIT_TRAINING_SUCCESS,
    EDIT_TRAINING_ERROR,
    ADD_TRAINING_PENDING,
    ADD_TRAINING_SUCCESS,
    ADD_TRAINING_ERROR,
    DELETE_TRAINING_PENDING,
    DELETE_TRAINING_SUCCESS,
    DELETE_TRAINING_ERROR,
    SET_IS_EDIT_FILE
} from "../../actions/master-data/training_actions";

const initialState = {
    pending: false,
    error: null
};

export function trainingList(
    state = { ...initialState, trainingList: null },
    action
) {
    switch (action.type) {
        case LIST_TRAINING_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_TRAINING_SUCCESS:
            return {
                ...state,
                pending: false,
                trainingList: action.data,
            };
        case LIST_TRAINING_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addTraining(
    state = { ...initialState, addTraining: null },
    action
) {
    switch (action.type) {
        case ADD_TRAINING_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_TRAINING_SUCCESS:
            return {
                ...state,
                pending: false,
                addTraining: action.data,
            };
        case ADD_TRAINING_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function editTraining(
    state = { ...initialState, isEditFile: false },
    action
  ) {
    switch (action.type) {
      case EDIT_TRAINING_PENDING:
        return {
          ...state,
          pending: true,
        };
      case EDIT_TRAINING_SUCCESS:
        return {
          ...state,
          pending: false,
        };
      case EDIT_TRAINING_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };
      case SET_IS_EDIT_FILE:
        return {
          ...state,
          isEditFile: action.value,
        };
      default:
        return state;
    }
  }

export function deleteTraining(
    state = { ...initialState, deleteTraining: null },
    action
) {
    switch (action.type) {
        case DELETE_TRAINING_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_TRAINING_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteTraining: action.data,
            };
        case DELETE_TRAINING_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getTrainingListPending = (state) => state.pending;
export const getTrainingList = (state) => state.trainingList;
export const getTrainingListError = (state) => state.error;
export const getTrainingAddPending = (state) => state.pending;
export const getTrainingAdd = (state) => state.addTraining;
export const getTrainingAddError = (state) => state.error;
export const getTrainingEditPending = (state) => state.pending;
export const getTrainingEdit = (state) => state.editTraining;
export const getTrainingEditError = (state) => state.error;
export const getTrainingDeletePending = (state) => state.pending;
export const getTrainingDelete = (state) => state.deleteTraining;
export const getTrainingDeleteError = (state) => state.error;
export const getTrainingIsEditFile = (state) => state.isEditFile;
