import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { listFinalPaper } from "../../actions/cms-final-paper/cms_final_paper_action";
import {
    getListFinalPaper,
    getListFinalPaperPending,
    getListFinalPaperError
} from "../../reducers/cms-final-paper/cms_final_paper_reducer";
import { connect } from "react-redux";

class DetailFinalPaper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forRefresh: false,
            id: "",
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
    }

    componentDidMount() {
        let param_listFinalPaper = {
            page: 1,
            length: 1,
            search_text: "",
            id: ""
        };
        const { match: { params: { id } } } = this.props;
        param_listFinalPaper.id = id;
        this.props.listFinalPaper(param_listFinalPaper);
    }

    shouldComponentRender() {
        const { listFinalPaper_pending } = this.props;
        if (listFinalPaper_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const { listFinalPaper_data } = this.props;
        let data = [];
        let title, author, university, event, year = "";
        if (listFinalPaper_data) {
            data = listFinalPaper_data.data[0];
            title = data.title_paper;
            author = data.author_paper;
            university = data.univ_paper;
            event = data.event_data.event_name;
            year = data.year;
        }

        return (
            <ContentContainer>
                <Row style={{ minHeight: "78vh" }}>
                    <Row className={(css(styles.leftDetailContainer))}>
                        <Column >
                            <DetailNameComponent name={title} />
                        </Column>
                    </Row>
                    <Row className={(css(styles.rightDetailContainer))}>
                        <Column className={(css(styles.rightDetailColumn))}>
                            <Row horizontal="space-between" style={{ alignItems: "center" }}>
                                <DetailTitleComponent title="Detail Final Paper" marginBottom={true} />
                            </Row>
                            <div style={{ marginBottom: 10 }}></div>
                            <DetailContentComponent label="Nama Penulis" value={author} />
                            <DetailContentComponent label="Universitas" value={university} />
                            <DetailContentComponent label="Event" value={event} />
                            <DetailContentComponent label="Tahun" value={year} />
                        </Column>
                    </Row>
                </Row>
            </ContentContainer>
        );
    }
}

const styles = StyleSheet.create({
    leftDetailContainer: {
        width: "30%",
        background: "#F8F9FB 0% 0% no-repeat padding-box",
        borderRight: "2px solid rgba(192,192,192, 0.5)",
        opacity: 1,
        padding: 40
    },
    rightDetailContainer: {
        padding: 40,
        width: "70%"
    },
    rightDetailColumn: {
        width: "100%"
    },
});

function mapStateToProps({ listFinalPaper }) {
    return {
        listFinalPaper_data: getListFinalPaper(listFinalPaper),
        listFinalPaper_error: getListFinalPaperError(listFinalPaper),
        listFinalPaper_pending: getListFinalPaperPending(listFinalPaper),
    };
}


export default connect(mapStateToProps, { listFinalPaper })(withRouter(DetailFinalPaper));