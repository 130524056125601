import React, { Component } from "react";
import { connect } from "react-redux";
import { listPPDS } from "../../actions/manajemen-ppds/manajemen_ppds_action";
import { universityList } from "../../actions/master-data/university_action";
import { listCity } from "../../actions/master-data/city_action";
import {
  getListEducation,
  getEducationError,
  getEducationPending,
} from "../../reducers/member/education_reducer";
import {
  getUniversityList,
  getUniversityListPending,
} from "../../reducers/master-data/university_reducer";
import {
  getCityListPending,
  getCityList
} from "../../reducers/master-data/city_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import PPDSHeader from "./PPDSHeader";
import PPDSModalAdd from "./PPDSModalAdd";
import PPDSModalDelete from "./PPDSModalDelete";
import { getUser } from "../../helpers/user";

class ListManajemenPPDS extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/manajemen-ppds/detail/${id}`);
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    const { universityList, listCity, match } = this.props;
    const param = {
      page: 1,
      length: 999,
    };
    universityList(param);
    listCity(param);
    if (match.params.id && this.state.id === null) {
      this.setState({ id: match.params.id })
    }
  }

  render() {
    const { listPPDS, listPPDS_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listPPDS_data) {
      data = listPPDS_data.data;
      records_total = listPPDS_data.records_total;
    }
    return (
      <ContentContainer>
        <PPDSHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <PPDSModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <PPDSModalDelete
          show={modalDeleteShow}
          id={this.state.id}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Universitas",
              field: "university_to",
            },
            {
              title: "Negara Asal",
              render: ({ country }) => {
                return (
                  <>{country}</>
                );
              },
            },
            {
              title: "Kota",
              field: "city",
            },
            {
              title: "Jenjang Pendidikan",
              field: "education_stage",
            },
            {
              title: "Gelar",
              field: "degree",
            },
            {
              title: "Tahun Lulus",
              field: "graduated",
            },
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(id)}>
                      Detail
                    </MenuItem>
                    <MenuItem onClick={() => this.handleOpenModalDelete(id)}>
                      Hapus
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                user_id: getUser().id,
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                id: id
              };
              listPPDS(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ universityList, listPPDS, listCity }) {
  return {
    listPPDS_data: getListEducation(listPPDS),
    listPPDS_error: getEducationError(listPPDS),
    listPPDS_pending: getEducationPending(listPPDS),
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    listCity_data: getCityList(listCity),
    listCity_pending: getCityListPending(listCity),
  };
}

export default connect(mapStateToProps, {
  listPPDS,
  universityList,
  listCity
})(ListManajemenPPDS);
