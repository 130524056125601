import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from "../../assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import { listPPDSGO } from "../../actions/dashboard/ppds_action";
import Button from "@material-ui/core/Button";
import {
    getListPPDSGO,
    getListPPDSPending,
} from "../../reducers/ppds/ppds_reducer";
import SelectComponent from "../../components/form/SelectComponent";
import { universityList } from "../../actions/master-data/university_action";
import LineChart from "../../components/chart/LineChart";
import {
    getUniversityListPending,
    getUniversityList,
} from "../../reducers/master-data/university_reducer";


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CardPPDS extends Component {
    constructor() {
        super();
        this.state = {
            university_id: '',
            is_actual: false,
            year: new Date().getFullYear()
        };
        this.handleActualChange = this.handleActualChange.bind(this);
        this.handleUniversityChange = this.handleUniversityChange.bind(this);
        this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    }
    componentDidMount() {
        this.props.universityList({ length: 999 })
        this.props.listPPDSGO({
            url: 'dashboard/kolegium/growth_orthopaedi',
            is_actual: this.state.is_actual,
            year: this.state.year,
            university_id: this.state.university_id
        });
    }

    handleActualChange() {
        let value = !this.state.is_actual;
        this.setState({ is_actual: value })

        this.props.listPPDSGO({
            is_actual: value,
            year: this.state.year,
            university_id: this.state.university_id
        });
    }

    handleYearSelectChange(e) {
        if (e) {
            this.setState({ year: e.value });
            this.props.listPPDSGO({
                year: e.value,
                is_actual: this.state.is_actual,
                university_id: this.state.university_id
            });
        } else {
            this.props.listPPDSGO({
                year: new Date().getFullYear(),
                is_actual: this.state.is_actual,
                university_id: this.state.university_id
            });
        }
    }

    handleUniversityChange(e) {
        if (e) {
            this.setState({ university_id: e.value });
            this.props.listPPDSGO({
                is_actual: this.state.is_actual,
                year: this.state.year,
                university_id: e.value
            });
        } else {
            this.props.listPPDSGO({
                is_actual: this.state.is_actual,
                year: this.state.year,
            });
        }
    }

    componentDidUpdate() {
        const {
            listPPDSGO_data,
            listPPDS_pending,
        } = this.props;
    }

    render() {
        const {
            listPPDSGO_data,
            listUniversity_data,
            listPPDS_pending,
        } = this.props;
        let data = [],
            branchOptions = [],
            result = null;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        let universityOptions = [];
        if (listUniversity_data) {
            universityOptions = listUniversity_data.data.map((item) => ({
                label: item.name,
                value: item.id,
                city: item.city.name,
            }));
            universityOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
        }
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }

        if (listPPDSGO_data) {
            var yearArray = []
            var totalArray = []
            var tempArray = []
            listPPDSGO_data.map(({ year, total }) => {
                tempArray.push({
                    year: year,
                    total: total
                })
            });
            tempArray.sort((a, b) => a.year - b.year);
            tempArray.map((data, idx) => {
                totalArray.push(data.total)
                yearArray.push(data.year)
            })

            const labelsB = yearArray;
            const datasetsB = [
                {
                    label: "Total",
                    data: totalArray,
                    fill: false,
                    borderColor: "#00a6cf"
                },
            ];
            const optionsB = {
                yTickDisplay: true,
                xGridDisplay: false,
            };

            const options = {
                animationEnabled: true,
                height: 300,
                axisX: {
                    valueFormatString: "YYYY",

                },
                axisY: {
                    includeZero: true,
                    gridThickness: 0,
                },
                data: [
                    {
                        xValueFormatString: "YYYY",
                        type: "splineArea",
                        lineColor: "#FFC156",
                        color: '#FCEBCD',
                        lineThickness: 8,
                        markerColor: 'white',
                        markerBorderColor: '#FFC156',
                        markerBorderThickness: 4,
                        markerSize: 10,
                        dataPoints: data
                    },
                ]
            }


            result = <LineChart type="growth" labels={labelsB} datasets={datasetsB} options={optionsB} />;
        }

        return (
            <div style={{ width: "100%", height: '50%', paddingBottom: 10 }}>
                <Column
                    style={{
                        boxShadow: "0px 3px 6px #00000029",
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        height: "100%",
                        minHeight: 300,
                        width: "100%",
                        backgroundColor: "white",
                    }}
                >

                    {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                    <Row
                        className={css(styles.wrapperTop, styles.borderBottom)}
                        style={{ width: "50%", padding: 20, backgroundColor: "white" }}
                        vertical="center"
                    >
                        <label className={css(styles.titleLabel)}>
                            {" "}
              Pertumbuhan Orthopaedi
            </label>
                        <Row>
                            <div style={{ width: 160, margin: "0px" }}>
                                <Button className={this.state.is_actual ? css(styles.activeFilter) : ''}
                                    onClick={this.handleActualChange}>
                                    AKTUAL
                                </Button>
                                <Button className={!this.state.is_actual ? css(styles.activeFilter) : ''}
                                    onClick={this.handleActualChange}>
                                    Prediksi
                                    </Button>
                            </div>
                            <div style={{ width: 150, margin: "0 3px" }}>
                                <SelectComponent
                                    options={universityOptions}
                                    isRounded
                                    onChange={this.handleUniversityChange}
                                    placeholder="Pilih Universitas"
                                />
                            </div>
                            <div style={{ width: 120, margin: "0 3px" }}>
                                <SelectComponent
                                    options={yearOptions}
                                    isRounded
                                    onChange={this.handleYearSelectChange}
                                    placeholder="Pilih Tahun"
                                />
                            </div>
                        </Row>
                    </Row>
                    <Column
                        className={css(styles.wrapper)}
                        style={{
                            width: "100%",
                            padding: "20px 30px",
                            backgroundColor: "red",
                        }}
                    >
                        {result}
                    </Column>
                </Column>
            </div>
        );
    }
}

function mapStateToProps({ listPPDSGO, universityList }) {
    return {
        listPPDSGO_data: getListPPDSGO(listPPDSGO),
        listPPDS_pending: getListPPDSPending(listPPDSGO),
        listUniversity_data: getUniversityList(universityList),
        listUniversity_pending: getUniversityListPending(universityList),

    };
}

export default connect(mapStateToProps, {
    listPPDSGO,
    universityList
})(CardPPDS);

const styles = StyleSheet.create({
    titleLabel: {
        color: "#495057",
        fontSize: 18,
        fontWeight: "bold",
        margin: "5px 0",
    },
    cardLabel: {
        color: "#495057",
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 4,
    },
    activeFilter: {
        backgroundColor: "#EFF0F2"
    },
    wrapper: {
        // padding: 15,
        // height: 400,
        backgroundColor: "white",
    },
    borderBottom: {
        borderBottom: "1px solid #eeee",
    },
    cardHeader: {
        backgroundColor: "#5AB853",
        height: 10,
        width: "100%",
    },
});
