import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { Route, Switch } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import { detailMember } from "../../actions/member/member_action";
import { listCity } from "../../actions/master-data/city_action";
import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProfileAvatar from "./ProfileAvatar";
import ProfilePengantar from "./ProfilePengantar";
import ProfilePendukung from "./ProfilePendukung";
import ProfileDataDiri from "./ProfileDataDiri";
import { listBranch } from "../../actions/master-data/branch_action";
import { listSubspesialis } from "../../actions/master-data/subspesialis_action";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum", data: null };
    // this.ref = React.createRef(TMUIRichTextEditorRef, null);
    this.tableRef = React.createRef();
  }
  componentDidMount() {
    const {
      detailMember,
      match: {
        params: { id },
      },
    } = this.props;

    detailMember(id);
    listSubspesialis({ length: 999999 });
  }
  setSelectedItem(item) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ selectedItem: item });
    this.props.history.push(`/member/detail/profile/${id}/${item}`);
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <ProfileAvatar />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pengantar")}
              menuName="Pengantar"
              isActive={this.state.selectedItem === "pengantar"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("profile")}
              menuName="Profile"
              isActive={this.state.selectedItem === "profile"}
            />
            <DetailMenuComponent
              onClick={() => this.setSelectedItem("pendukung")}
              menuName="Berkas Pendukung"
              isActive={this.state.selectedItem === "pendukung"}
            />
          </LeftContainerComponent>
          <RightContainerComponent>
            <Switch>
              <Route
                path={`/member/detail/profile/${id}/pengantar`}
                exact
                component={ProfilePengantar}
              />
              <Route
                path={`/member/detail/profile/${id}/profile`}
                exact
                component={ProfileDataDiri}
              />
              <Route
                path="/member/detail/profile/:id/pendukung"
                exact
                component={ProfilePendukung}
              />
              {/* <Route
                path="/ppds/detail/:id/pendidikan"
                exact
                component={PendidikanPPDS}
              />
              <Route
                path="/member-profile/profile"
                exact
                component={InformasiPPDS}
              /> */}
            </Switch>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function mapStateToProps({ detailMember, listSubspesialis }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
  };
}

export default connect(mapStateToProps, {
  detailMember,
  listCity,
  listBranch,
  listSubspesialis,
})(withRouter(Profile));
