import {
  LIST_REPORT_PENDING,
  LIST_REPORT_SUCCESS,
  LIST_REPORT_ERROR,
  LIST_TRANSFER_PENDING,
  LIST_TRANSFER_SUCCESS,
  LIST_TRANSFER_ERROR,
  IMPORT_REPORT_PENDING,
  IMPORT_REPORT_SUCCESS,
  IMPORT_REPORT_ERROR,
  MASTER_REPORT_PENDING,
  MASTER_REPORT_SUCCESS,
  MASTER_REPORT_ERROR,
} from "../../actions/report/report_action";

const initialState = {
  pending: false,
  error: null,
};

export function listReport(
  state = { ...initialState, list_report: null },
  action
) {
  switch (action.type) {
    case LIST_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        list_report: action.data,
      };
    case LIST_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listTransfer(
  state = { ...initialState, list_transfer: null },
  action
) {
  switch (action.type) {
    case LIST_TRANSFER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_TRANSFER_SUCCESS:
      return {
        ...state,
        pending: false,
        list_transfer: action.data,
      };
    case LIST_TRANSFER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function importReport(
  state = { ...initialState, importReport: null },
  action
) {
  switch (action.type) {
    case IMPORT_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case IMPORT_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        importReport: action.data,
      };
    case IMPORT_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function masterReport(
  state = { ...initialState, master_report: null },
  action
) {
  switch (action.type) {
    case MASTER_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MASTER_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        master_report: action.data,
      };
    case MASTER_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListReport = (state) => state.list_report;
export const getDetailReport = (state) => state.detail_report;
export const getReportPending = (state) => state.pending;
export const getReportError = (state) => state.error;
export const getListTransfer = (state) => state.list_transfer;
export const getDetailTransfer = (state) => state.detail_transfer;
export const getTransferPending = (state) => state.pending;
export const getTransferError = (state) => state.error;
export const getImportReport = (state) => state.importReport;
export const getImportReportPending = (state) => state.pending;
export const getImportReportError = (state) => state.error;
export const getMasterReport = (state) => state.master_report;
