import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";

import SearchIcon from "@material-ui/icons/Search";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
});
class HeaderSubspesialis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
    // this.props.handleRefresh({ search: e.target.value });
  }

  // handleSearchChange = _.debounce((e) => {
  // }, 1000);

  render() {
    const { handleOpenModalAdd } = this.props;
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={handleOpenModalAdd}
          >
            tambah subspesialis
          </Button>
        </div>
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleSearchChange}
            value={this.state.tes}
            placeholder="Cari Nama Subspesialis"
            startIcon={SearchIcon}
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(HeaderSubspesialis);
