import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDetailP2kbComponent from "../../components/p2kb/HeaderDetailP2kbComponent";
import IconError from "../../assets/icon-error";
import IconDocumentP2KB from "../../assets/icon-document-p2kb";
import IconLaunch from "../../assets/icon-launch";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { getUser } from "../../helpers/user";

import {
  p2kbList,
  detailP2kb,
  setSelectedSKP,
  rejectDetailP2kb,
} from "../../actions/p2kb/p2kb_action";
import { detailActivity } from "../../actions/master-data/activity_action";
import moment from "moment";
import {
  getP2kbListPending,
  getP2kbList,
  getDetailP2kb,
  getP2kbListError,
  getP2kbPending,
} from "../../reducers/p2kb/p2kb_reducer";
import ModalAcceptSkp from "./Modal_accept_skp";
import ModalRejectSkp from "./Modal_reject_skp";
import DetailRejectModal from "../../components/modal/DetailRejectModalP2kb";
import MenulisBukuModalDetail from "../member-p2kb/menulis-buku/MenulisBukuModalDetail";
import ModalDocument from "../../components/modal/ModalDocument";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";

class Skp_menulis_buku_approval extends Component {
  constructor() {
    super();
    this.state = {
      showDocument: false,
      filename: "",
      modalTitle: "",
      showModalAccept: false,
      showModalReject: false,
      showDetailReject: false,
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : '',
      id: null,
    };
    this.handleShowModalDetail = this.handleShowModalDetail.bind(this);
  }

  handleOpenDocument(filename, modalTitle) {
    this.setState({
      filename: filename,
      modalTitle: modalTitle,
      showDocument: true,
    });
  }

  handleShowModalDetail(data) {
    this.props.setSelectedSKP(data);
    this.setState({ showModalDetail: true });
  }

  handleShowModalReject(id) {
    this.setState({ id: id });
    this.setState({ showModalReject: true });
  }

  handleShowRevision(data) {
    this.setState({
      reason: data.last_reject_notes,
      imgUrlRejectSKP: data.last_reject_filename,
      showDetailReject: true,
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { skp_id },
      },
      detailP2kb,
    } = this.props;
    const params = {
      activity_id: skp_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      user_id: localStorage.getItem('user_id_skp'),
      serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',
    }
    detailP2kb(params);
  }

  render() {
    const {
      detailP2kb_data,
      match: {
        params: { skp_id },
      },
    } = this.props;
    const params = {
      activity_id: skp_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      serkom_id: localStorage.getItem('isSerkom') ? localStorage.getItem('id_serkom') : '',
    }
    const { showDocument, filename, modalTitle, showDetailReject } = this.state;
    const role_code = getUser().role_codes[0];

    let data = [];
    let activity_name,
      activity_type,
      realm_activity,
      skp_status,
      role_next_approve,
      total_skp,
      last_reject_notes,
      last_reject_filename,
      role_name;
    let stepOne = "admin_paboi_cabang";
    let stepTwo = "ketua_paboi_cabang";
    let stepThree = "root";
    let stepFour = "ketua_paboi_pusat";
    let stepFive = "cpd_cme";
    let step = "";
    let isRoleCodesMatchStep = false;
    if (detailP2kb_data && detailP2kb_data.length !== 0) {
      let arrayData = [];
      this.props.detailP2kb_data.map((item, index) => {
        item.p2kb_details.map((data, idx) => {
          let details = {
            no: idx + 1,
            id: data.id,
            activity_date: data.activity_date,
            ...JSON.parse(data.form_json),
            publication_year: { label: JSON.parse(data.form_json).publication_year, value: JSON.parse(data.form_json).publication_year },
            last_activity: { label: data.mst_activity_skp_type_detail_data.name, value: data.mst_activity_skp_type_detail_data.id },
            values_id: { label: data.activity_skp_type_value_data.label, value: data.activity_skp_type_value_data.id },
            skp_total: data.total_skp,
            total_skp_eksternal: data.total_skp_eksternal,
            berkas: data.attachments[0].filename,
            status: data.status,
          }
          arrayData.push(details)
        })
      })
      data = arrayData;
      ({
        activity_data: {
          name: activity_name,
          activity_type,
          realm_activity: { name: realm_activity },
        },
        status: skp_status,
        role_next_approve,
        total_skp,
        last_reject_notes,
        last_reject_filename,
      } = detailP2kb_data[0]);
      step = detailP2kb_data[0].step;
      if (role_next_approve) {
        ({ role_name } = role_next_approve);
      }
      if (role_code === stepOne && step === 1 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_code === stepTwo && step === 2 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_code === stepThree && step === 3 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_code === stepFour && step === 4 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
      if (role_code === stepFive && step === 5 && skp_status === 0) {
        isRoleCodesMatchStep = true;
      }
    }

    return (
      <ContentContainer>
        <MenulisBukuModalDetail
          show={this.state.showModalDetail}
          onHide={() => this.setState({ showModalDetail: false })}
        />
        <ModalDocument
          show={showDocument}
          onHide={() => this.setState({ showDocument: false })}
          filepath="member_p2kb"
          filename={filename}
          modalTitle={modalTitle}
        />
        <ModalAcceptSkp
          show={this.state.showModalAccept}
          onHide={() => this.setState({ showModalAccept: false })}
          id={skp_id}
          handleRefresh={() => this.props.detailP2kb(params)}
        />
        <ModalRejectSkp
          show={this.state.showModalReject}
          onHide={() => this.setState({ showModalReject: false })}
          id={this.state.id}
          handleRefresh={() => this.props.detailP2kb(params)}
        />
        <DetailRejectModal
          show={showDetailReject}
          onHide={() => this.setState({ showDetailReject: false })}
          reason={last_reject_notes}
          filepath="member_p2kb"
          filename={last_reject_filename}
        />
        {!this.props.detailP2kb_pending && (
          <HeaderDetailP2kbComponent
            activity_name={activity_name}
            activity_type={activity_type}
            realm_activity={realm_activity}
            skp={total_skp}
            skp_status={skp_status}
            last_skp_admin={role_name ? role_name : "CPD/CME"}
            icon={IconError}
            isAdmin={
              isRoleCodesMatchStep === true
                ? role_code.includes("ketua") || role_code.includes("cpd")
                  ? "ketua"
                  : "admin"
                : false
            }
            approval={
              data.role_next_approve
                ? data.role_next_approve.role_code.includes("ketua") ||
                  data.role_next_approve.role_code.includes("cpd")
                  ? "Persetujuan"
                  : "Verifikasi"
                : "Persetujuan"
            }
            acceptSKP={() => this.setState({ showModalAccept: true })}
            rejectSKP={() => this.setState({ showModalReject: true })}
          />
        )}

        <MaterialTable
          isLoading={this.props.detailP2kb_pending}
          title="P2kb"
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Tanggal Kegiatan",
              render: ({ activity_date }) =>
                moment(activity_date).format("DD MMMM YYYY"),
            },
            { title: "Judul Buku", field: "title_of_book" },
            { title: "Tingkat", field: "last_activity.label" },
            { title: "peranan", field: "values_id.label" },
            { title: "Jumlah SKP", field: "skp_total" },

            {
              title: "Dokumen",
              render: (data) => {
                return (
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHover)}
                      >
                        <IconDocumentP2KB fill="Black" />
                      </Row>
                    )}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(data.berkas, "Berkas Bukti")
                      }
                    >
                      <IconLaunch />
                      <span style={{ marginLeft: 10, paddingTop: 2 }}>
                        Berkas Bukti
                      </span>
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Menunggu"
                        : status === 1
                          ? "Approved"
                          : "Revisi"
                    }
                  />
                );
              },
            },
            {
              render: (data) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.handleShowModalDetail(data)}>
                      Detail
                  </MenuItem>

                    {/* {data.status === 2 ? (
                      <MenuItem onClick={() => this.handleShowRevision(data)}>
                        Alasan Revisi
                      </MenuItem>
                    ) : (
                      isRoleCodesMatchStep && (
                        <MenuItem
                          onClick={() => this.handleShowModalReject(data.id)}
                        >
                          Revisi
                        </MenuItem>
                      )
                    )} */}
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={data}
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              zIndex: 0,
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p>
                    Menampilkan {10} dari {data.length} data{" "}
                  </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}></div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
  rejectButton: {
    background: "#EAEBED",
    marginLeft: 10,
    marginRight: 20,
    textAlign: "center",
    color: "#495057",
    boxShadow: "none",
  },
  submitButtonContainer: {
    padding: 20,
    textAlign: "left",
  },
});

function mapStateToProps({ p2kbList, detailP2kb }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    detailP2kb_data: getDetailP2kb(detailP2kb),
    detailP2kb_pending: getP2kbPending(detailP2kb),
  };
}

export default connect(mapStateToProps, {
  detailP2kb,
  p2kbList,
  setSelectedSKP,
  detailActivity,
  rejectDetailP2kb,
})(withRouter(Skp_menulis_buku_approval));
