import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailSip,
  editSip,
  setIsEditFile,
} from "../../actions/p2kb/sip_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import {
  getSipPending,
  getDetailSip,
  getSipError,
  getSipIsEditFile,
} from "../../reducers/p2kb/sip_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import {
  getPracticePending,
  getDetailPractice,
  getPracticeError,
} from "../../reducers/sub-menu/practice_reducer";
import { detailPractice } from "../../actions/sub-menu/practice_action";
import { renderInput, renderFile } from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import SipModalApprove from "./SipModalApprove";
import SipModalReject from "./SipModalReject";
import SipModalActivation from "./SipModalActivation";
import SipModalDeactivation from "./SipModalDeactivation";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import RenderArray from "./PracticeForm";
import localization from "moment/locale/id";
// import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";

class SipDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      showActivationModal: false,
      showDeactivationModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailSip, detailPractice } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailSip(id);
    detailPractice();

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseActivationModal =
      this.handleCloseActivationModal.bind(this);
    this.handleCloseDeactivationModal =
      this.handleCloseDeactivationModal.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editSip_isEditFile, setIsEditFile } = this.props;
    if (editSip_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editSip_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true });
    } else {
      this.setState({ modalDocumentImgShow: true });
    }
  }

  handleSubmit(values) {
    const { detailSip_data, editSip, detailSip } = this.props;

    const { berkas, institution, sip_type, year } = values;
    const param = {
      id: detailSip_data[0].id,
      filename: detailSip_data[0].filename,
      institution,
      sip_type,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailSip();
      this.handleTriggerFormEdit();
    };
    editSip(param, callback);
  }

  handleCloseActivationModal() {
    this.setState({ showActivationModal: false });
  }

  handleCloseDeactivationModal() {
    this.setState({ showDeactivationModal: false });
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];
    const editSip_isEditFile = this.props;

    const { detailSip_data, handleSubmit } = this.props;
    const {
      isEdit,
      showApproveModal,
      showRejectModal,
      showActivationModal,
      showDeactivationModal,
    } = this.state;
    let id,
      name,
      member_branch,
      filename,
      status,
      reject_notes,
      sip_role_code,
      reject_filename,
      start_date,
      end_date,
      sip_type,
      member_active_sip,
      activation_notes,
      deactivation_notes;

    if (detailSip_data && detailSip_data.length !== 0) {
      id = detailSip_data[0].id;
      name = detailSip_data[0].user_data.name;
      member_branch = detailSip_data[0].user_data.branch_id;
      filename = detailSip_data[0].filename;
      status = detailSip_data[0].status;
      reject_notes = detailSip_data[0].reject_notes;
      reject_filename = detailSip_data[0].reject_filename;
      sip_role_code = detailSip_data[0].role_next_approve
        ? detailSip_data[0].role_next_approve.role_code
        : null;
      start_date = detailSip_data[0].start_date;
      end_date = detailSip_data[0].end_date;
      sip_type = detailSip_data[0].sip_type;
      member_active_sip = detailSip_data[0].member_active_sip;
      activation_notes = detailSip_data[0].activation_notes;
      deactivation_notes = detailSip_data[0].deactivation_notes;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    function checkStatus() {
      if (status === 0) return "Baru";

      if (status === 1) {
        if (sip_type === 1) return "Terverifikasi - Aktif";
        if (sip_type === 0) return "Terverifikasi - Nonaktif";

        return "Terverifikasi";
      }

      return "Ditolak";
    }

    let catatan = "";
    if (checkStatus().includes("- Aktif")) catatan = activation_notes;
    if (checkStatus().includes("- Nonaktif")) catatan = deactivation_notes;

    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_sip"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_sip"
        />
        <SipModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <SipModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <SipModalActivation
          show={showActivationModal}
          onHide={() => this.handleCloseActivationModal}
          memberName={name}
          id={id}
        />
        <SipModalDeactivation
          show={showDeactivationModal}
          onHide={() => this.handleCloseDeactivationModal}
          memberName={name}
          id={id}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                  ? "Data SIP Terverifikasi"
                  : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                  ? sip_type === 1
                    ? "Terverifikasi - Aktif"
                    : sip_type === 0
                    ? "Terverifikasi - Nonaktif"
                    : "Terverifikasi"
                  : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_sip"
            />
            {getUser().role_codes[0] === "admin_paboi_cabang" &&
              getUser().branch_id === member_branch &&
              status === 0 && (
                <Row style={{ width: "100%" }} horizontal="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.approveButton)}
                    onClick={() => this.setState({ showApproveModal: true })}
                  >
                    Verifikasi
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.rejectButton)}
                    onClick={() => this.setState({ showRejectModal: true })}
                  >
                    Tolak
                  </Button>
                </Row>
              )}

            {getUser().role_codes[0] === "admin_paboi_cabang" &&
              getUser().branch_id === member_branch &&
              status === 1 &&
              sip_type === 0 && (
                <Row style={{ width: "100%" }} horizontal="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.aktivasiButton)}
                    disabled={member_active_sip < 3 ? false : true}
                    onClick={() => this.setState({ showActivationModal: true })}
                  >
                    Aktivasi
                  </Button>
                </Row>
              )}
            {getUser().role_codes[0] === "admin_paboi_cabang" &&
              getUser().branch_id === member_branch &&
              status === 1 &&
              sip_type === 1 && (
                <Row style={{ width: "100%" }} horizontal="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.nonaktifButton)}
                    disabled={member_active_sip === 3 ? false : true}
                    onClick={() =>
                      this.setState({ showDeactivationModal: true })
                    }
                  >
                    Nonaktif
                  </Button>
                </Row>
              )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi SIP" />
                {/* <EditButtonComponent
                  isEdit={isEdit}
                  triggerIsEdit={this.handleTriggerFormEdit}
                  isLoading={editSip_pending}
                /> */}
              </Row>
              <FormContainer label="No SIP">
                <Field
                  isDetail={!isEdit}
                  name="sip_no"
                  placeholder="Masukkan no SIP"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Nama Rumah Sakit">
                <Field
                  isDetail={!isEdit}
                  name="hospital_name"
                  placeholder="Masukkan no SIP"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer
                label="Jam Praktik"
                contentValueStyle={{ margin: "20px 0" }}
              >
                <FieldArray
                  isDetail={!isEdit}
                  name="schedule"
                  placeholder="Masukkan Jadwal Baru"
                  component={RenderArray}
                  small
                />
              </FormContainer>
              <DetailContentComponent
                label="Tanggal Penetapan"
                value={moment(start_date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY")}
              />
              <DetailContentComponent
                label="Tanggal Berakhir"
                value={moment(end_date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY")}
              />
              {/* <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Berkas SIP")} />} /> */}
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editSip_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_sip"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                    ? "Terverifikasi"
                    : status === 2
                    ? "Ditolak"
                    : "-"
                }
              />

              {/* catatan */}
              <DetailContentComponent label="Catatan" value={catatan} />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  aktivasiButton: {
    background: "#F57C2B 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
    textTransform: "capitalize",
  },
  nonaktifButton: {
    background: "#ED2A2A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
    textTransform: "capitalize",
  },
});

function validate(values, props) {
  const { editSip_isEditFile } = props;
  const { institution, sip_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!sip_type) {
    errors.sip_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editSip_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

SipDetail = reduxForm({
  // a unique name for the form
  form: "SipModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SipDetail);

function mapStateToProps(state) {
  const day = [
    "0",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];
  const { detailSip, editSip, detailPractice } = state;
  let initialValues = {};
  let jadwal = [];
  const detailSip_data = getDetailSip(detailSip);
  const detailPractice_data = getDetailPractice(detailPractice);
  if (detailSip_data && detailSip_data.length !== 0) {
    if (detailSip_data[0].member_practice_place_days.lenght !== 0) {
      jadwal = detailSip_data[0].member_practice_place_days;
    }
    initialValues = {
      sip_no: detailSip_data[0].sip_no,
      start_date: detailSip_data[0].start_date,
      end_date: detailSip_data[0].end_date,
      hospital_name: detailSip_data[0].hospital_data.name,
      schedule: jadwal.map((item) => ({
        day: { value: item.day_code, label: day[item.day_code] },
        time_start: item.start_time,
        // format="mm:ss";
        time_end: item.end_time,
      })),
    };
    // if (detailPractice_data && detailPractice_data.length !== 0) {
    //   const idx = detailPractice_data.map(function (e) { return e.id; }).indexOf(detailSip_data[0].member_practice_place_id);
    //   initialValues = {
    //     ...initialValues,
    //     schedule: detailPractice_data[idx].member_practice_place_days.map(
    //       (item) => ({
    //         day: { value: item.day_code, label: day[item.day_code] },
    //         time_start: item.start_time,
    //         // format="mm:ss";
    //         time_end: item.end_time,
    //       })
    //     ),
    //   }
    // }
  }

  return {
    detailSip_data: getDetailSip(detailSip),
    detailSip_error: getSipError(detailSip),
    detailSip_pending: getSipPending(detailSip),
    detailPractice_data: getDetailPractice(detailPractice),
    detailPractice_error: getPracticeError(detailPractice),
    detailPractice_pending: getPracticePending(detailPractice),
    editSip_error: getSipError(editSip),
    editSip_pending: getSipPending(editSip),
    editSip_isEditFile: getSipIsEditFile(editSip),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailSip,
  editSip,
  detailPractice,
  setIsEditFile,
})(withRouter(SipDetail));
