import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";

import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 150,
    marginLeft: 10,
  },
});
class HeaderJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      start_date: "",
      end_date: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    return this.props.handleRefresh({ search: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleStartDateChange(e) {
    if (e) {
      this.props.handleRefresh({ start_date: e.target.value });
    } else {
      this.props.handleRefresh({ start_date: "" });
    }
  }

  handleEndDateChange(e) {
    if (e) {
      this.props.handleRefresh({ end_date: e.target.value });
    } else {
      this.props.handleRefresh({ end_date: "" });
    }
  }

  // handleSearchChange = _.debounce((e) => {
  // }, 1000);

  render() {
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="flex-end"
      >
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleSearchChange}
            value={this.state.tes}
            placeholder="Cari Judul Journal"
            startIcon={SearchIcon}
          />
        </div>
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleStartDateChange}
            type="date"
            value={this.state.tes}
            placeholder="Cari Nama Journal"
            label="Tanggal Mulai"
          />
        </div>
        <div className={css(styles.container)}>
          <InputComponent
            onChange={this.handleEndDateChange}
            type="date"
            value={this.state.tes}
            placeholder="Cari Nama Journal"
            label="Tanggal Akhir"
          />
        </div>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(HeaderJournal);
