import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_MESSAGE_BLAST_PENDING = "LIST_MESSAGE_BLAST_PENDING";
export const LIST_MESSAGE_BLAST_SUCCESS = "LIST_MESSAGE_BLAST_SUCCESS";
export const LIST_MESSAGE_BLAST_ERROR = "LIST_MESSAGE_BLAST_ERROR";
export const ADD_MESSAGE_BLAST_PENDING = "ADD_MESSAGE_BLAST_PENDING";
export const ADD_MESSAGE_BLAST_SUCCESS = "ADD_MESSAGE_BLAST_SUCCESS";
export const ADD_MESSAGE_BLAST_ERROR = "ADD_MESSAGE_BLAST_ERROR";
export const DETAIL_MESSAGE_BLAST_PENDING = "DETAIL_MESSAGE_BLAST_PENDING";
export const DETAIL_MESSAGE_BLAST_SUCCESS = "DETAIL_MESSAGE_BLAST_SUCCESS";
export const DETAIL_MESSAGE_BLAST_ERROR = "DETAIL_MESSAGE_BLAST_ERROR";
export const UPLOAD_MESSAGE_BLAST_PENDING = "UPLOAD_MESSAGE_BLAST_PENDING";
export const UPLOAD_MESSAGE_BLAST_SUCCESS = "UPLOAD_MESSAGE_BLAST_SUCCESS";
export const UPLOAD_MESSAGE_BLAST_ERROR = "UPLOAD_MESSAGE_BLAST_ERROR";
export const SET_SELECTED_MESSAGE_BLAST = "SET_SELECTED_MESSAGE_BLAST";

const LIST_MESSAGE_BLAST_URL = "message_blast/find";
const ADD_MESSAGE_BLAST_URL = "message_blast/add";
const UPLOAD_MESSAGE_BLAST_URL = "image/upload/message_blast_attachment";

export function messageBlastList(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_MESSAGE_BLAST_PENDING));
    API.post(LIST_MESSAGE_BLAST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_MESSAGE_BLAST_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * param.length,
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_MESSAGE_BLAST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function addMessageBlast(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_MESSAGE_BLAST_PENDING));
    API.post(ADD_MESSAGE_BLAST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_MESSAGE_BLAST_SUCCESS, res.data.data));
        toastSuccess("Berhasil membroadcast pesan");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_MESSAGE_BLAST_ERROR, error));
        toastError(error, "Gagal membroadcast pesan, ");
      });
  };
}

export function uploadMessageBlast(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_MESSAGE_BLAST_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_MESSAGE_BLAST_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_MESSAGE_BLAST_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_MESSAGE_BLAST_ERROR, error));
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function setSelectedMessageBlast(value) {
  return {
    type: SET_SELECTED_MESSAGE_BLAST,
    value: value,
  };
}

export function detailMessageBlast(id = null, callback) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_MESSAGE_BLAST_PENDING));
    API.post(LIST_MESSAGE_BLAST_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_MESSAGE_BLAST_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_MESSAGE_BLAST_ERROR, error));
        toastError(error, "Gagal mendapatkan detail message blast, ");
      });
  };
}