import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

const Input = (props) => (
  <components.Input {...props} onPaste={props.selectProps.onPaste} />
);

// const components = {
//   DropdownIndicator: null,
// };

const createOption = (label) => ({
  label,
  value: label,
});

export default class CreatableInputOnly extends Component {
  state = {
    inputValue: "",
    value: [],
    isValidEmail: true,
    isPaste: false,
  };
  handleChange = (value, actionMeta) => {
    if (value !== null) {
      this.setState({ value });
    } else {
      this.setState({ value: [] });
    }
    this.props.handleNonMember(value);
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    const newAddedValue = createOption(inputValue);
    const isEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        inputValue
      );
    if (!inputValue) return;
    // switch (event.key) {
    //     case 'Enter':
    //     case 'Tab':
    //         if (isEmail) {
    //             this.setState({
    //                 inputValue: '',
    //                 value: [...value, newAddedValue],
    //             });
    //             this.props.handleNonMember([...value, newAddedValue]);
    //             this.setState({ isValidEmail: true })
    //             event.preventDefault();
    //         } else {
    //             this.setState({ isValidEmail: false })
    //         }
    //         break;
    //     default:
    // }
    switch (event.charCode || event.keyCode) {
      case 9:
      case 13:
      case 32:
        if (isEmail) {
          this.setState({
            inputValue: "",
            value: [...value, newAddedValue],
          });
          this.props.handleNonMember([...value, newAddedValue]);
          this.setState({ isValidEmail: true });
          event.preventDefault();
        } else {
          this.setState({ isValidEmail: false });
        }
        break;
      default:
    }
  };
  handlePaste = (event) => {
    this.setState({ isPaste: true });
    let tempVal = event.clipboardData.getData("Text");
    let isValid = true;
    let tempArr = [];
    const { value } = this.state;
    tempVal.split(" ").forEach((item) => {
      const newAddedValue = createOption(item);
      const isEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          item
        );
      if (!item) return;
      if (isEmail) {
        tempArr.push(newAddedValue);
      } else {
        isValid = false;
      }
    });
    let flags = {};
    let allValue = [...value, ...tempArr];
    const newValue = allValue.filter(function (entry) {
      if (flags[entry.value]) {
        return false;
      }
      flags[entry.value] = true;
      return true;
    });

    this.setState({
      inputValue: "",
      value: newValue,
    });
    this.props.handleNonMember(newValue);
    this.setState({ isValidEmail: isValid });
    event.preventDefault();
  };
  render() {
    const { inputValue, value, isValidEmail, isPaste } = this.state;
    console.log(value);
    const { propsValue } = this.props;
    return (
      <>
        <CreatableSelect
          components={{ Input }}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onPaste={this.handlePaste}
          placeholder="Masukkan email non member"
          value={
            propsValue && Object.keys(propsValue).length > 0
              ? propsValue
              : value
          }
        />
        {!isValidEmail &&
          (!isPaste ? (
            <span style={{ color: "red", marginTop: 5 }}>
              Masukkan alamat email dengan format yang benar. Ex:
              paboi@gmail.com
            </span>
          ) : (
            <span style={{ color: "red", marginTop: 5 }}>
              Terdapat email tidak valid.
            </span>
          ))}
      </>
    );
  }
}
