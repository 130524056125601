import {
  PROVINCE_LIST_PENDING,
  PROVINCE_LIST_SUCCESS,
  PROVINCE_LIST_ERROR,
} from "../../actions/master-data/province_action";

const initialState = {
  pending: false,
  error: null,
};

export function provinceList(
  state = { ...initialState, provinceList: null },
  action
) {
  switch (action.type) {
    case PROVINCE_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        provinceList: action.data,
      };
    case PROVINCE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getProvinceListPending = (state) => state.pending;
export const getProvinceList = (state) => state.provinceList;
export const getProvinceListError = (state) => state.error;
