import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { renderInputWithLabel } from "../../components/form/FormComponent";
import { listActivity } from "../../actions/master-data/activity_action";
import {
  getListActivity,
  getActivityPending,
  getActivityError,
} from "../../reducers/master-data/activity_reducer";
import { p2kbList, uploadP2kb } from "../../actions/p2kb/p2kb_action";
import {
  getP2kbListPending,
  getP2kbList,
  getP2kbListError,
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbUploadError,
} from "../../reducers/p2kb/p2kb_reducer";
// import moment from 'moment';
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumenSKP from "./Modal_document_pdf";
import ModalDetailDokumen from "./Modal_document";

class ModalDetailSKP_Pelatihan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSKPData: JSON.parse(localStorage.getItem("skp_add_data_temp")),
      skp_detail: JSON.parse(localStorage.getItem("skp_detail")),
      modalDetailDokumenSKPShow: false,
      modalDetailDokumenShow: false,
      berkas_sertifikat: "",
      imgUrl: "",
      modal_title: "",
      selected_activity: null,
      selected_scale: null,
      selected_peran: null,
      selected_duration: null,
    };
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDetailDokumenSKPShow: true })
    } else {
      this.setState({ modalDetailDokumenShow: true })
    }
  }

  componentDidMount() {
    this.props.listActivity({ length: 999 });
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDetailDokumenSKP
          show={this.state.modalDetailDokumenSKPShow}
          onHide={() => this.setState({ modalDetailDokumenSKPShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <ModalDetailDokumen
          show={this.state.modalDetailDokumenShow}
          onHide={() => this.setState({ modalDetailDokumenShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
        />
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Detail SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            // onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="activity_date"
                  placeholder="Masukkan Tanggal Kegiatan"
                  label="Tanggal Kegiatan"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="material_title"
                  placeholder="Judul Materi"
                  label="Judul Materi"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="magazine_name"
                  placeholder="Nama Majalah"
                  label="Nama Majalah"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="number"
                  placeholder="Volume atau Nomor"
                  label="Volume atau Nomor"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="publication_year"
                  placeholder="Tahun Terbit"
                  label="Tahun Terbit"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="issn_number"
                  placeholder="Nomor ISSN"
                  label="Nomor ISSN"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="publisher"
                  placeholder="Penerbit"
                  label="Penerbit"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="type_of_magazine"
                  placeholder="Jenis Majalah"
                  label="Jenis Majalah"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP"
                  label="Jumlah SKP"
                  disable={true}
                  component={renderInputWithLabel}
                />
                <span style={{ marginBottom: 20, marginTop: 5 }}>
                  Berkas Pendukung
                </span>
                {
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        this.props.formValue.berkas_medis,
                        "Pernyataan Lulus Dari Jurnal"
                      )
                    }
                  />
                }
              </>
            </Column>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

ModalDetailSKP_Pelatihan = reduxForm({
  form: "ModalDetailSKP_Pelatihan",
  shouldError: () => true,
  enableReinitialize: true,
})(ModalDetailSKP_Pelatihan);

function mapStateToProps(state) {
  const { listActivity, uploadP2kb, p2kbList, selectedSKP } = state;
  const selector = formValueSelector("ModalDetailSKP_Pelatihan");
  const formValue = selector(
    state,
    "skp_total",
    "sk_idi",
    "pertemuan",
    "last_activity_skp_detail_id",
    "values_id",
    "berkas_medis"
  );
  let initialValues = {};
  if (selectedSKP) {
    let result = JSON.parse(selectedSKP.form_json);
    initialValues = {
      activity_date: selectedSKP.activity_date,
      material_title: result.material_title,
      magazine_name: result.magazine_name,
      number: result.number,
      publication_year: result.publication_year,
      issn_number: result.issn_number,
      publisher: result.publisher,
      type_of_magazine: result.type_of_magazine,
      skp_total: selectedSKP.total_skp,
      berkas_medis: selectedSKP.attachments[0].filename,
    };
  }

  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    p2kbList_error: getP2kbListError(p2kbList),
    listActivity_pending: getActivityPending(listActivity),
    listActivity_data: getListActivity(listActivity),
    listActivity_error: getActivityError(listActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    uploadP2kb_error: getP2kbUploadError(uploadP2kb),
    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, { listActivity, uploadP2kb, p2kbList })(
  withRouter(ModalDetailSKP_Pelatihan)
);
