import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
    zIndex: 99,
  },
});
class HeaderApreciation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience: "",
      status: null,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() {}

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ experience: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleStatusChange(value) {
    if (value) {
      this.props.handleRefresh({ status: value.value });
    } else {
      this.props.handleRefresh({ status: null });
    }
  }

  render() {
    let statusOption = [
      { value: 0, label: "Baru" },
      { value: 1, label: "Terverifikasi" },
      { value: 2, label: "Ditolak" },
    ];
    return (
      <Row
        style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="space-between"
      >
        <div>
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            className={css(styles.approveButton)}
            startIcon={<AddIcon />}
            onClick={handleShowModalAdd}
          >
            tambah pelatihan
          </Button> */}
        </div>
        <Row>
          <div className={css(styles.container)}>
            <InputComponent
              onChange={this.handleSearchChange}
              value={this.state.experience}
              placeholder="Cari Nama, Nomor STR"
              startIcon={SearchIcon}
            />
          </div>
          <div className={css(styles.container)}>
            <SelectComponent
              onChange={this.handleStatusChange}
              placeholder="Status"
              options={statusOption}
              id="status"
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HeaderApreciation);
