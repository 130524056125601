import {
  LIST_HOSPITAL_PENDING,
  LIST_HOSPITAL_SUCCESS,
  LIST_HOSPITAL_ERROR,
  DETAIL_HOSPITAL_PENDING,
  DETAIL_HOSPITAL_SUCCESS,
  DETAIL_HOSPITAL_ERROR,
  EDIT_HOSPITAL_PENDING,
  EDIT_HOSPITAL_SUCCESS,
  EDIT_HOSPITAL_ERROR,
  DELETE_HOSPITAL_PENDING,
  DELETE_HOSPITAL_SUCCESS,
  DELETE_HOSPITAL_ERROR,
  ADD_HOSPITAL_PENDING,
  ADD_HOSPITAL_SUCCESS,
  ADD_HOSPITAL_ERROR,
  LIST_PROVINCE_PENDING,
  LIST_PROVINCE_SUCCESS,
  LIST_PROVINCE_ERROR,
} from "../../actions/master-data/hospital_action";

const initialState = {
  pending: false,
  listHospital: null,
  detailHospital: null,
  error: null,
};

export function listHospital(
  state = { ...initialState, listHospital: null },
  action
) {
  switch (action.type) {
    case LIST_HOSPITAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_HOSPITAL_SUCCESS:
      return {
        ...state,
        pending: false,
        listHospital: action.data,
      };
    case LIST_HOSPITAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailHospital(state = initialState, action) {
  switch (action.type) {
    case DETAIL_HOSPITAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_HOSPITAL_SUCCESS:
      return {
        ...state,
        pending: false,
        detailHospital: action.data,
      };
    case DETAIL_HOSPITAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editHospital(state = initialState, action) {
  switch (action.type) {
    case EDIT_HOSPITAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_HOSPITAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_HOSPITAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addHospital(state = initialState, action) {
  switch (action.type) {
    case ADD_HOSPITAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_HOSPITAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_HOSPITAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteHospital(state = initialState, action) {
  switch (action.type) {
    case DELETE_HOSPITAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_HOSPITAL_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_HOSPITAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listProvince(
  state = { ...initialState, listProvince: null },
  action
) {
  switch (action.type) {
    case LIST_PROVINCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PROVINCE_SUCCESS:
      return {
        ...state,
        pending: false,
        listProvince: action.data,
      };
    case LIST_PROVINCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListHospital = (state) => state.listHospital;
export const getDetailHospital = (state) => state.detailHospital;
export const getEditHospital = (state) => state.editHospital;
export const getDeleteHospital = (state) => state.deleteHospital;
export const getHospitalPending = (state) => state.pending;
export const getHospitalError = (state) => state.error;

export const getProvinceError = (state) => state.listProvince;
