import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import { StyleSheet, css } from "aphrodite";
import SearchIcon from "@material-ui/icons/Search";
import { p2kbList } from "../../actions/p2kb/p2kb_action";
import { getP2kbListPending, getP2kbList, getP2kbListError } from "../../reducers/p2kb/p2kb_reducer";

class HeaderDetailP2KB extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleChangeActivity = this.handleChangeActivity.bind(this);
    }

    handleChangeSearch(e) {
        if (e !== null) {
            this.props.handleRefresh({ search: e.target.value });
        } else {
            this.props.handleRefresh({ search: "" });
        }
    }

    handleChangeStatus(e) {
        if (e !== null) {
            this.props.handleRefresh({ status: e.value });
        } else {
            this.props.handleRefresh({ status: "" });
        }
    }

    handleChangeActivity(e) {
        if (e !== null) {
            this.props.handleRefresh({ activity_type: e.value });
        } else {
            this.props.handleRefresh({ activity_type: "" })
        }
    }

    render() {
        const activityOption = [
            { value: 1, label: "Internal" },
            { value: 2, label: "Eksternal" },
            { value: 3, label: "Pribadi" },
        ];
        const statusOption = [
            { value: 0, label: "Menunggu" },
            { value: 1, label: "Approved" },
            { value: 2, label: "Revisi" },
        ];
        return (
            <Row
                style={{ padding: "10px 0", backgroundColor: "#FFFFFF", alignItems: "center" }}
                horizontal="flex-end"
            >
                <Row>
                    <div className={css(styles.container)}>
                        <InputComponent
                            onChange={this.handleChangeSearch}
                            placeholder="Cari Kegiatan"
                            startIcon={SearchIcon}
                            id="search"
                        />
                    </div>
                    <div className={css(styles.container)}>
                        <SelectComponent
                            onChange={this.handleChangeActivity}
                            placeholder="Jenis Kegiatan"
                            options={activityOption}
                            id="jenisKegiatan"
                        />
                    </div>
                    <div className={css(styles.container)}>
                        <SelectComponent
                            onChange={this.handleChangeStatus}
                            placeholder="Status"
                            options={statusOption}
                            id="status"
                        />
                    </div>
                </Row>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        marginRight: 10,
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    container: {
        width: 150,
        margin: 10,
    },
});

function mapStateToProps({ p2kbList }) {
    return {
        P2kbList_pending: getP2kbListPending(p2kbList),
        P2kbList_data: getP2kbList(p2kbList),
        P2kbList_error: getP2kbListError(p2kbList),
    };
}

export default connect(mapStateToProps, { p2kbList })(HeaderDetailP2KB);