import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailSerkom,
  editSerkom,
  setIsEditFile,
} from "../../actions/serkom/serkom_action";
import { listStr } from "../../actions/p2kb/str_action";
import {
  getStrPending,
  getListStr,
  getStrError,
} from "../../reducers/p2kb/str_reducer";

import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getSerkomPending,
  getDetailSerkom,
  getSerkomError,
  getSerkomIsEditFile,
} from "../../reducers/serkom/serkom_reducer";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList, getMemberPending,
} from "../../reducers/member/member_reducer";
import ModalDocumentNotfound from "../admin-p2kb/Modal_document_notfound";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderDate,
  renderFile,
  renderSelect
} from "../../components/form/FormComponent";
import moment from "moment";
import localization from "moment/locale/id";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import { getUser } from "../../helpers/user";

class SerkomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentNotfoundShow: false,
      modalDocumentPdfShow: false,
      modal_title: "",
      imgUrl: ""
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { detailSerkom, memberList } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailSerkom(id);
    memberList();

    const callback = () => {
      const data = this.props.listStr_data.data;
      let strApproved = data.filter(item => item.status === 1);
      if (strApproved.length > 0) {
        this.setState({ user_str: strApproved[0].filename });
      }
    }
    this.props.listStr({ user_id: localStorage.getItem('user_id_str') }, callback);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editSerkom_isEditFile, setIsEditFile } = this.props;
    if (editSerkom_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editSerkom_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title, folder = "") {
    this.setState({ folder: folder })
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl !== null) {
      if (imgUrl.split('.')[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true })
      } else {
        this.setState({ modalDocumentImgShow: true })
      }
    } else {
      this.setState({ modalDocumentNotfoundShow: true })
    }
  }

  handleSubmit(values) {
    const { detailSerkom_data, editSerkom } = this.props;
    const { sk_no, start_date, end_date, berkas, member } = values;
    let formData = new FormData();
    formData.append("id", detailSerkom_data[0].id);
    formData.append("user_id", member.value);
    formData.append("sk_no", sk_no);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    if (berkas) {
      formData.append("file", berkas);
    }
    const callback = () => {
      detailSerkom();
      this.handleTriggerFormEdit();
    };
    editSerkom(formData, callback);
  }

  render() {
    const role = getUser().role_codes[0];
    const {
      detailSerkom_data,
      listStr_data,
      handleSubmit,
      editSerkom_pending,
      initialValues,
      change,
      listMember_data
    } = this.props;
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modal_title,
      imgUrl,
      folder,
      isEdit,
    } = this.state;
    let name, filename, health_information_image, scan_ktp_image, additional_file, str_file;

    if (detailSerkom_data && detailSerkom_data.length !== 0) {
      name = detailSerkom_data[0].user_data.name;
      scan_ktp_image = detailSerkom_data[0].user_data.member_profile.scan_ktp_image;
      additional_file = detailSerkom_data[0].user_data.member_profile.additional_file_image;
      health_information_image = detailSerkom_data[0].user_data.member_profile.health_information_image;
      filename = detailSerkom_data[0].filename;
    }

    if (listStr_data && listStr_data.data.length !== 0) {
      if (listStr_data.data[0].filename) {
        str_file = listStr_data.data[0].filename
      }
      else {
        str_file = null;
      }
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    if (initialValues && isEdit) {
      change("start_date", detailSerkom_data[0].start_date)
      change("end_date", detailSerkom_data[0].end_date)
    }
    let memberOptions = [];
    if (listMember_data) {
      memberOptions = listMember_data.data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    }

    return (
      <ContentContainer>
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentNotfound
          show={this.state.modalDocumentNotfoundShow}
          onHide={() => this.setState({ modalDocumentNotfoundShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          member_name={name}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal={role === "member" ? "flex-start" : "space-between"}
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Serkom" />
                {role === "admin_kolegium" &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editSerkom_pending}
                  />
                }
              </Row>
              <FormContainer label="Nama Member">
                <Field
                  isDetail={!isEdit}
                  name="member"
                  placeholder="Pilih Member"
                  options={memberOptions}
                  isAsync
                  asyncUrl="member/member/find"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="No. Surat Serkom">
                <Field
                  isDetail={!isEdit}
                  name="sk_no"
                  disabled
                  placeholder="Masukkan no. surat serkom"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Tanggal Penetapan">
                <Field
                  isDetail={!isEdit}
                  name="start_date"
                  placeholder="Masukkan tanggal penetapan"
                  component={!isEdit ? renderInput : renderDate}
                />
              </FormContainer>
              <FormContainer label="Tanggal Berakhir">
                <Field
                  isDetail={!isEdit}
                  name="end_date"
                  placeholder="Masukkan tanggal berakhir"
                  component={!isEdit ? renderInput : renderDate}
                />
              </FormContainer>
              {!isEdit ?
                <>
                  <DetailContentComponent label="Kartu Identitas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(scan_ktp_image, "Kartu Identitas", "member_supporting_files")} />} />
                  <DetailContentComponent label="Surat Keterangan Sehat" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(health_information_image, "Surat Keterangan Sehat", "member_supporting_files")} />} />
                  <DetailContentComponent label="Pas Foto" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(additional_file, "Pas Foto", "member_supporting_files")} />} />
                  <DetailContentComponent label="STR" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(str_file, "STR", "member_str")} />} />
                  <DetailContentComponent label="Berkas Serkom" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Serkom", "member_serkom")} />} />
                </>
                :
                <FormContainer label="Berkas">
                  <Field
                    name="berkas"
                    type="file"
                    fileType="skp"
                    title="Upload berkas"
                    message="PNG atau JPG maksimal 5000 KB"
                    component={renderFile}
                  />
                </FormContainer>
              }
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function validate(values, props) {
  const { editSerkom_isEditFile } = props;
  const { no_sk, start_date, end_date, berkas } = values;
  const errors = {};
  if (!no_sk) {
    errors.no_sk = "No SK wajib diisi";
  }
  if (!start_date) {
    errors.start_date = "Tanggal penetapan wajib diisi";
  }
  if (!end_date) {
    errors.end_date = "Tanggal berakhir wajib diisi";
  }
  if (editSerkom_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

SerkomDetail = reduxForm({
  // a unique name for the form
  form: "SerkomDetail",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SerkomDetail);

function mapStateToProps(state) {
  const { detailSerkom, editSerkom, memberList, listStr } = state;
  let initialValues = {};
  const detailSerkom_data = getDetailSerkom(detailSerkom);
  if (detailSerkom_data && detailSerkom_data.length !== 0) {
    initialValues = {
      sk_no: detailSerkom_data[0].sk_no,
      start_date: moment(new Date(detailSerkom_data[0].start_date + 'T00:00:00')).locale("id", localization).format("DD MMM YYYY"),
      end_date: moment(new Date(detailSerkom_data[0].end_date + 'T00:00:00')).locale("id", localization).format("DD MMM YYYY"),
      member: {
        label: detailSerkom_data[0].user_data.name,
        value: detailSerkom_data[0].user_data.id
      }
    };
  }

  return {
    detailSerkom_data: getDetailSerkom(detailSerkom),
    detailSerkom_error: getSerkomError(detailSerkom),
    detailSerkom_pending: getSerkomPending(detailSerkom),
    editSerkom_error: getSerkomError(editSerkom),
    editSerkom_pending: getSerkomPending(editSerkom),
    editSerkom_isEditFile: getSerkomIsEditFile(editSerkom),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList),
    listStr_data: getListStr(listStr),
    listStr_error: getStrError(listStr),
    listStr_pending: getStrPending(listStr),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailSerkom,
  listStr,
  editSerkom,
  setIsEditFile,
  memberList
})(withRouter(SerkomDetail));
