import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { registrationList, ppdsStatusApproval } from "../../actions/registrasi/registrasi_action";
import { getRegistrationListPending, getRegistrationList, getRegistrationListError, getppdsStatusApproval, getppdsStatusApprovalPending, getppdsStatusApprovalError } from "../../reducers/registrasi/registrasi_reducer";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

class ModalKonfirmasiPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      young_member_id: "",
    };
    this.handleYoungmemberChange = this.handleYoungmemberChange.bind(this);
  }

  handleYoungmemberChange(e) {
    this.setState({ young_member_id: e.target.value })
  }

  approvePPDSStatus(id) {
    const { onHide, ppdsStatusApproval } = this.props;
    let param_ppdsStatusApproval = {
      id: id,
      is_approve: "true",
      young_member_id: '1',
      university_approved_id: this.props.optionValue,
      university_before: this.props.university_before,
      graduated: this.props.graduated,
    }
    let param_registrationList = {
      page: 1,
      length: 1,
      search_text: "",
      id: id
    };
    const callback = () => {
      onHide();
      registrationList(param_registrationList);
      this.props.history.push(`/registrasi`)
    };
    ppdsStatusApproval(param_ppdsStatusApproval, callback)
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Konfirmasi Penerimaan PPDS
            </p>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            Apakah anda yakin akan melakukan konfirmasi kelulusan?
          </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={
                this.props.getppdsStatusApprovalPending ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <CheckIcon />
                )
              }
              onClick={() => this.approvePPDSStatus(this.props.id)}
            >
              Ya
              </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10
  },
});

function mapStateToProps({ registrationList, ppdsStatusApproval }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    ppdsStatusApproval_pending: getppdsStatusApprovalPending(ppdsStatusApproval),
    ppdsStatusApproval_data: getppdsStatusApproval(ppdsStatusApproval),
    ppdsStatusApproval_error: getppdsStatusApprovalError(ppdsStatusApproval),
  };
}


export default connect(mapStateToProps, { registrationList, ppdsStatusApproval })(withRouter(ModalKonfirmasiPPDS));
