import React, { Component } from "react";
import { connect } from "react-redux";
import { listMutation } from "../../actions/mutation/mutation_action";
import {
  getListMutation,
  getMutationError,
  getMutationPending,
} from "../../reducers/mutation/mutation_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import MutationHeader from "./MutationHeader";
import MutationModalAdd from "./MutationModalAdd";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { listHospital } from "../../actions/master-data/hospital_action";
import { listBranch } from "../../actions/master-data/branch_action";
import { detailMember } from "../../actions/member/member_action";
import { getUser } from "../../helpers/user";
import moment from "moment";

class Mutation extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/member/mutation/detail/${id}`);
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    const { listHospital, listBranch, detailMember } = this.props;
    listHospital();
    listBranch({ length: 9999 });
    const id = getUser().id;
    detailMember(id);
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id })
    }
  }

  render() {
    const { listMutation, listMutation_data } = this.props;
    const { search_text, showModalAdd } = this.state;
    let data = [];
    let records_total = 0;
    if (listMutation_data) {
      data = listMutation_data.data;
      records_total = listMutation_data.records_total;
    }
    return (
      <ContentContainer>
        <MutationHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <MutationModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 40,

              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "No Pengajuan",
              field: "request_code",
            },
            {
              title: "Tanggal Pengajuan",

              render: ({ created_at }) => moment(created_at).format("DD MMMM YYYY"),
            },
            // {
            //   title: "Cabang Asal",
            //   render: ({ branch_from_data }) =>
            //     branch_from_data ? branch_from_data.name : "-",
            // },
            {
              title: "Cabang Tujuan",
              field: "branch_to_data.name",
            },
            // {
            //   title: "User",
            //   headerStyle: {
            //     minWidth: 140,
            //   },
            //   field: "role_next_approve",
            //   render: ({ role_next_approve }) =>
            //     role_next_approve ? role_next_approve.role_name : "Admin Pusat",
            // },
            {
              title: "Status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Proses"
                        : status === 1
                          ? "Disetujui"
                          : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => (
                <DetailButtonComponent>
                  <MenuItem onClick={() => this.detailRecord(id)}>
                    Detail
                  </MenuItem>
                </DetailButtonComponent>
              ),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                id: this.state.id
              };
              listMutation(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listMutation }) {
  return {
    listMutation_data: getListMutation(listMutation),
    listMutation_error: getMutationError(listMutation),
    listMutation_pending: getMutationPending(listMutation),
  };
}

export default connect(mapStateToProps, {
  listMutation,
  listHospital,
  listBranch,
  detailMember,
})(Mutation);
