import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_PPDS_PENDING = "LIST_PPDS_PENDING_ADMIN";
export const LIST_PPDS_SUCCESS = "LIST_PPDS_SUCCESS_ADMIN";
export const LIST_PPDS_ERROR = "LIST_PPDS_ERROR_ADMIN";
export const LIST_PPDSACCEPTANCE_PENDING = "LIST_PPDSACCEPTANCE_PENDING_ADMIN";
export const LIST_PPDSACCEPTANCE_SUCCESS = "LIST_PPDSACCEPTANCE_SUCCESS_ADMIN";
export const LIST_PPDSACCEPTANCE_ERROR = "LIST_PPDSACCEPTANCE_ERROR_ADMIN";
export const LIST_PPDSGO_PENDING = "LIST_PPDSGO_PENDING_ADMIN";
export const LIST_PPDSGO_SUCCESS = "LIST_PPDSGO_SUCCESS_ADMIN";
export const LIST_PPDSGO_ERROR = "LIST_PPDSGO_ERROR_ADMIN";

const LIST_PPDS_URL = "dashboard/admin/univ_tren/";
const LIST_PPDSGO_URL = "dashboard/kolegium/growth_orthopaedi";
const LIST_PPDSACCEPTANCE_URL = "dashboard/kolegium/ppds_acceptance";

export function listPPDSGrafik(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_PPDS_PENDING));
        API.post(LIST_PPDS_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_PPDS_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_PPDS_ERROR, error));
                toastError(error, "Gagal mendapatkan data PPDS, ");
            });
    };
}

//ACCEPTANCE PPDS
export function listPPDSAcceptance(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_PPDSACCEPTANCE_PENDING));
        API.post(LIST_PPDSACCEPTANCE_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_PPDSACCEPTANCE_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_PPDSACCEPTANCE_ERROR, error));
                toastError(error, "Gagal mendapatkan data PPDS, ");
            });
    };
}

//GROWTH ORTHOPAEDI
export function listPPDSGO(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_PPDSGO_PENDING));
        API.post(LIST_PPDSGO_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_PPDSGO_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_PPDSGO_ERROR, error));
                toastError(error, "Gagal mendapatkan data PPDS, ");
            });
    };
}

