import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { editTransrs } from "../../actions/transrs/transrs_action";
import { renderSelectWithLabel, renderInputWithLabel } from "../../components/form/FormComponent";
import {
    getTransrsAdd,
    getTransrsAddPending,
    getTransrsAddError
} from "../../reducers/transrs/transrs_reducer";

class ModalTransRSEdit extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { editTransrs, onHide, handleRefresh, formValue } = this.props;
        const param = {
            branch_to: values.branch.value,
            hospital_id_to: values.hospital.value,
            str_no: values.str,
            id: formValue
        };
        const callback = () => {
            onHide();
            handleRefresh();
        };
        editTransrs(param, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            editTransrs_pending,
            branchOptions,
            hospitalOptions
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Ubah Trans RS</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            {(
                                <>
                                    <Field
                                        label="Cabang Tujuan"
                                        name="branch"
                                        placeholder="Pilih Cabang Tujuan"
                                        options={branchOptions}
                                        component={renderSelectWithLabel}
                                    />
                                    <Field
                                        label="RS Tujuan"
                                        name="hospital"
                                        placeholder="Pilih RS Tujuan"
                                        options={hospitalOptions}
                                        component={renderSelectWithLabel}
                                    />
                                    <Field
                                        name="str"
                                        placeholder="STR"
                                        label="No STR"
                                        disable={true}
                                        component={renderInputWithLabel}
                                    />
                                </>
                            )}
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={editTransrs_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    editTransrs_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function validate(values) {
    const { branch, hospital, str } = values;
    const errors = {};
    if (!branch) {
        errors.branch = "Cabang tujuan tidak boleh kosong!";
    }
    if (!hospital) {
        errors.hospital = "Rumah sakit tujuan tidak boleh kosong!";
    }
    if (!str) {
        errors.str = "Nomor STR tidak boleh kosong!";
    }
    return errors;
}

ModalTransRSEdit = reduxForm({
    form: "ModalTransRSEdit",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalTransRSEdit);

function mapStateToProps(state) {
    const { editTransrs, selectedTransrs } = state;
    const selector = formValueSelector("ModalTransRSEdit");
    const formValue = selector(state, "id");
    let initialValues = {};
    if (selectedTransrs) {
        initialValues = {
            branch: {
                label: selectedTransrs.branch_to_data.name,
                value: selectedTransrs.branch_to_data.id
            },
            hospital: {
                label: selectedTransrs.hospital_to_data.name,
                value: selectedTransrs.hospital_to_data.id
            },
            str: selectedTransrs.str_no,
            id: selectedTransrs.id
        }
    }
    return {
        editTransrs_pending: getTransrsAddPending(editTransrs),
        editTransrs_data: getTransrsAdd(editTransrs),
        editTransrs_error: getTransrsAddError(editTransrs),
        initialValues,
        formValue
    };
}

export default connect(mapStateToProps, {
    editTransrs,
})(withRouter(ModalTransRSEdit));
