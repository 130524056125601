import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { memberList } from "../../actions/member/member_action";
import { getMemberListPending, getMemberList, getMemberListError } from "../../reducers/member/member_reducer";
import { changeImageProfile } from "../../actions/master-data/user_action";
import { getChangeImageProfilePending, getChangeImageProfile, getChangeImageProfileError } from "../../reducers/master-data/user_reducer";
import DetailMemberPhoto from "../../components/detail/DetailMemberPhoto";
import { reduxForm, Field } from "redux-form";
import { renderInput, renderFile } from "../../components/form/FormComponent";

class ModalEditBioMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.upload = this.upload.bind(this);
    }

    upload(e) {
        const { changeImageProfile } = this.props;
        let formData = new FormData();
        if (e !== null) {
            this.setState({ file: URL.createObjectURL(e) });
            formData.append('file', e);
        }
        changeImageProfile(formData);
    }

    handleSubmit(values) {
        // const { userChangePassword, onHide } = this.props;
        // const param_userChangePassword = {
        //     password: values.newpass,
        //     confirmation_password: values.newpassConf
        // }
        // const callback = () => {
        //     onHide();
        //     this.props.history.push('/login')
        // }
        // userChangePassword(param_userChangePassword, callback);
    }

    render() {
        const { handleSubmit, show, onHide, id, name, status, img_profile } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                id={id}
                name={name}
                status={status}
                img_profile={img_profile}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Edit Bio
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column className={css(styles.bodyModal)}>
                            <Row className={css(styles.photoComponent)}>
                                {(img_profile === null && this.state.file === null) ? <DetailMemberPhoto width="150px" height="150px" />
                                    : this.state.file === null && img_profile !== null ? <DetailMemberPhoto filename={img_profile} folder="user_profile" source="api" width="150px" height="150px" />
                                        : <DetailMemberPhoto filename={this.state.file} source="local" width="150px" height="150px" />}
                            </Row>
                            <Field
                                name="memberPhoto"
                                type="file"
                                fileType="image" //image or pdf
                                title="Edit Photo"
                                message="PNG atau JPG maksimal 2 MB"
                                onChange={(e) => this.upload(e)}
                                component={renderFile}
                            />
                            <Field
                                label="Nama"
                                name="name"
                                placeholder="Masukkan nama anda"
                                component={renderInput}
                            />
                            <Field
                                label="Subspecialis"
                                name="subspesialis"
                                placeholder="Masukkan subspesialis"
                                component={renderInput}
                            />
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={<CheckIcon />}
                            >
                                SIMPAN
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModalFalse: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40
    },
    photoComponent: {
        justifyContent: "center",
        marginBottom: 20
    }
});

function validate(values) {
    const { name, subspesialis } = values;
    const errors = {};
    if (!name) {
        errors.name = "Nama tidak boleh kosong"
    }
    if (!subspesialis) {
        errors.subspesialis = "Subspesialis tidak boleh kosong"
    }
    return errors;
}

ModalEditBioMember = reduxForm({
    form: "ModalEditBioMember",
    validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalEditBioMember);

function mapStateToProps({ memberList, changeImageProfile }) {
    return {
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        changeImageProfile_pending: getChangeImageProfilePending(changeImageProfile),
        changeImageProfile_data: getChangeImageProfile(changeImageProfile),
        changeImageProfile_error: getChangeImageProfileError(changeImageProfile),
    };
}

export default connect(mapStateToProps, { memberList, changeImageProfile })(ModalEditBioMember);