import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
  // renderSelectWithLabel,
  renderDatepickerWithLabel,
  renderInputWithLabel,
  renderFileForm,
} from "../../../components/form/FormComponent";
import {
  listActivity,
  detailActivity,
} from "../../../actions/master-data/activity_action";
import {
  getActivityPending,
  getDetailActivity,
} from "../../../reducers/master-data/activity_reducer";
import { uploadP2kb, editDetailP2kb } from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbUploadPending,
  getP2kbUpload,
  getP2kbPending,
} from "../../../reducers/p2kb/p2kb_reducer";
import DetailContentLihatBerkas from "../../../components/detail/DetailContentLihatBerkas";
import ModalDocument from "../../../components/modal/ModalDocument";
import Loading from "../../../components/global-components/LoadingComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

class EdukasiPasienModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocument: false,
      last_activity_skp_detail_id: "",
      values_id: "",
      berkas: null,
      berkas_disabled: false,
      isDoneUploading: true,
      skp: 0,
      filename: "",
      modal_title: "",
      selected_activity: "",
      spek_berkas: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeValues = this.handleChangeValues.bind(this);
    this.handleChangeLastActivity = this.handleChangeLastActivity.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleChangeTingkat = this.handleChangeTingkat.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { reset, show } = this.props;
    if (prevProps.show !== show && show === true) {
      reset();
      if (this.props.initialValues.berkas) {
        this.setState({
          berkas: (
            <DetailContentLihatBerkas
              onClick={() =>
                this.handleOpenDocument(
                  this.props.formValue.berkas,
                  "Daftar Hadir"
                )
              }
            />
          ),
        });
      }
    }
  }

  handleOpenDocument(filename, modalTitle) {
    this.setState({
      filename: filename,
      modalTitle: modalTitle,
      showDocument: true,
    });
  }

  handleChangeTingkat(values) {
    const { change } = this.props;
    change("last_activity", null);
    change("values_id", null);
  }
  handleChangeLastActivity(values) {
    const { change } = this.props;
    change("values_id", null);
  }

  handleChangeValues(values) {
    if (values) {
      const { skp_must_be_multipied, value_multipied, value_skp } = values;
      if (skp_must_be_multipied === 0) {
        this.props.change("skp_total", value_skp);
      } else {
        this.props.change("skp_total", Math.round(value_skp * value_multipied));
      }
    }
  }

  handleUploadFile(values) {
    const formName = values.target.name;
    this.setState({
      isDoneUploading: false,
      berkas_disabled: true,
      [formName]: <Loading />,
    });

    const callback = () => {
      this.setState({ isDoneUploading: true, berkas_disabled: false });
      if (this.props.uploadP2kb_data) {
        this.props.change(formName, this.props.uploadP2kb_data);
        this.setState({ berkas: <Loading done={true} /> });
        setTimeout(
          () =>
            this.setState({
              [formName]: (
                <DetailContentLihatBerkas
                  onClick={() =>
                    this.handleOpenDocument(
                      this.props.formValue.berkas,
                      "Daftar Hadir"
                    )
                  }
                />
              ),
            }),
          2000
        );
      }
    };
    const error = () => {
      this.setState({ isDoneUploading: true, berkas_disabled: false });
      if (this.props.uploadP2kb_error) {
        this.props.change([formName], null);
        this.setState({
          [formName]: <Loading done={true} failed={true} />,
        });
      }
    };
    this.props.uploadP2kb(values.target.files[0], callback, error);
    this.props.change("spek_berkas", values.target.files[0]);
  }

  handleSubmit(values) {
    const { onHide, handleRefresh, handleEditSkp, editDetailP2kb } = this.props;
    const { id, status, ...item } = values;
    values.hasEdit = true;

    if (status) {
      const param = {
        id,
        attachments: [
          {
            label: "Daftar Hadir & Portofolio",
            filename: item.berkas,
          },
        ],
        ...item,
      };

      const call = () => {
        onHide();
        handleRefresh();
      };
      editDetailP2kb(param, call);
    } else {
      handleEditSkp(values);
      onHide();
    }
  }

  render() {
    const {
      show,
      onHide,
      handleSubmit,
      uploadP2kb_pending,
      editP2kb_pending,
    } = this.props;
    const { berkas, showDocument, filename, modalTitle } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDocument
          show={showDocument}
          onHide={() => this.setState({ showDocument: false })}
          filepath="member_p2kb"
          filename={filename}
          modalTitle={modalTitle}
        />

        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Edit SKP</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="activity_date"
                  placeholder="Masukkan tanggal kegiatan"
                  label="Tanggal Kegiatan"
                  component={renderDatepickerWithLabel}
                />
                <Field
                  name="counseling_theme"
                  placeholder="Masukkan tema kegiatan"
                  label="Tema Kegiatan"
                  component={renderInputWithLabel}
                />

                <Field
                  name="activity_place"
                  placeholder="Masukkan tempat kegiatan"
                  label="Tempat Kegiatan"
                  component={renderInputWithLabel}
                />
                <Field
                  name="activity_duration"
                  placeholder="Masukkan lama kegiatan"
                  label="Lama Kegiatan"
                  component={renderInputWithLabel}
                  type="number"
                />

                <Field
                  name="total_audience"
                  placeholder="Masukkan jumlah peserta"
                  label="Jumlah peserta"
                  component={renderInputWithLabel}
                  type="number"
                />

                <Field
                  name="skp_total"
                  placeholder="Jumlah SKP 1"
                  label="Jumlah SKP"
                  disable
                  component={renderInputWithLabel}
                  type="number"
                />

                <Field
                  name="berkas"
                  type="file"
                  title="Daftar Hadir"
                  disabled={this.state.berkas_disabled}
                  isDone={this.state.isDoneUploading}
                  message={berkas ? berkas : "PDF, PNG, JPG maksimal 1 MB"}
                  onChange={this.handleUploadFile}
                  component={renderFileForm}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={uploadP2kb_pending}
                className={css(styles.approveButton)}
                startIcon={
                  uploadP2kb_pending || editP2kb_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 1000000; //1MB
  const fileType = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
  const {
    activity_date,
    counseling_theme,
    activity_place,
    activity_duration,
    total_audience,
    berkas,
    spek_berkas
  } = values;
  const errors = {};
  if (!activity_date) {
    errors.activity_date = "Tanggal kegiatan wajib diisi";
  }
  if (!counseling_theme) {
    errors.counseling_theme = "Tema kegiatan wajib diisi";
  }
  if (!activity_place) {
    errors.activity_place = "Tempat kegiatan wajib diisi";
  }
  if (!activity_duration) {
    errors.activity_duration = "Lama kegiatan wajib diisi";
  } else {
    if (activity_duration > 24) {
      errors.activity_duration = "Lama kegiatan maksimal 24 jam";
    } else if (activity_duration < 1) {
      errors.activity_duration = "Lama kegiatan tidak valid";
    }
  }
  if (!total_audience) {
    errors.total_audience = "Jumlah Peserta wajib diisi";
  } else {
    if (total_audience < 1) {
      errors.total_audience = "Jumlah peserta tidak valid";
    }
  }
  if (!berkas) {
    errors.berkas = "Berkas daftar hadir wajib diisi";
  }
  else{
    if (spek_berkas && (spek_berkas.type !== fileType[0] && spek_berkas.type !== fileType[1] && spek_berkas.type !== fileType[2]) && spek_berkas.type !== fileType[3]){
      errors.berkas = "Format file hanya bisa PDF, PNG, JPG"
    }
    else if (spek_berkas && (spek_berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 1 MB"
    }
  }
  return errors;
}

EdukasiPasienModalEdit = reduxForm({
  form: "EdukasiPasienModalEdit",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EdukasiPasienModalEdit);

function mapStateToProps(state) {
  const {
    listActivity,
    uploadP2kb,
    detailActivity,
    selectedSKP,
    editDetailP2kb,
  } = state;
  const selector = formValueSelector("EdukasiPasienModalEdit");
  const formValue = selector(state, "last_activity", "tingkat", "berkas", "spek_berkas");
  let initialValues = {};
  if (selectedSKP) {
    initialValues = selectedSKP;
  }
  return {
    listActivity_pending: getActivityPending(listActivity),
    detailActivity_data: getDetailActivity(detailActivity),
    uploadP2kb_pending: getP2kbUploadPending(uploadP2kb),
    uploadP2kb_data: getP2kbUpload(uploadP2kb),
    editP2kb_pending: getP2kbPending(editDetailP2kb),

    formValue,
    initialValues,
  };
}

export default connect(mapStateToProps, {
  listActivity,
  detailActivity,
  uploadP2kb,
  editDetailP2kb,
})(withRouter(EdukasiPasienModalEdit));
