import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";

import { listCity } from "../../actions/master-data/city_action";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailExperience,
  editExperience,
  setIsEditFile,
} from "../../actions/member/experience_action";
import { withRouter } from "react-router-dom";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import FormContainer from "../../components/container/FormContainer";
import {
  getExperiencePending,
  getDetailExperience,
  getExperienceError,
  getExperienceIsEditFile,
} from "../../reducers/member/experience_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderSelectWithLabel,
  renderFile
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import ExperienceModalApprove from "./ExperienceModalApprove";
import ExperienceModalReject from "./ExperienceModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import {
  getCityListPending,
  getCityList
} from "../../reducers/master-data/city_reducer";
import { getUser } from "../../helpers/user";

class ExperienceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailExperience, listCity } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailExperience(id);
    listCity();

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editExperience_isEditFile, setIsEditFile } = this.props;
    if (editExperience_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editExperience_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true })
    } else {
      this.setState({ modalDocumentImgShow: true })
    }
  }

  handleSubmit(values) {
    const {
      detailExperience_data,
      editExperience,
      detailExperience,
    } = this.props;

    const { berkas, institution, position, experience_type, year, city_id } = values;
    const param = {
      id: detailExperience_data[0].id,
      filename: detailExperience_data[0].filename,
      institution,
      position,
      experience_type,
      city: city_id.value,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailExperience();
      this.handleTriggerFormEdit();
    };
    editExperience(param, callback);
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailExperience_data,
      editExperience_pending,
      editExperience_isEditFile,
      listCity_data,
      handleSubmit,
    } = this.props;
    const { isEdit, showApproveModal, showRejectModal } = this.state;
    let name, filename, status, reject_notes, reject_filename;

    if (detailExperience_data && detailExperience_data.length !== 0) {
      name = detailExperience_data[0].member_data.name;
      filename = detailExperience_data[0].filename;
      status = detailExperience_data[0].status;
      reject_notes = detailExperience_data[0].reject_notes;
      reject_filename = detailExperience_data[0].reject_filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    let cityOptions = [];
    if (listCity_data) {
      cityOptions = listCity_data.data.map((item) => ({
        label: item.name,
        value: item.name
      }));
    }

    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_experience"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_experience"
        />
        <ExperienceModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <ExperienceModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data Pengalaman Terverifikasi"
                    : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                    ? "Terverifikasi"
                    : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_experience"
            />
            {status === 0 && getUser().role_codes[0] !== "admin_paboi_cabang" && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Pengalaman" />
                {getUser().role_codes[0] !== "admin_paboi_cabang" &&
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editExperience_pending}
                  />
                }
              </Row>
              <FormContainer label="Nama Institusi">
                <Field
                  isDetail={!isEdit}
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Jabatan">
                <Field
                  isDetail={!isEdit}
                  name="position"
                  placeholder="Masukkan Nama Pelatihan"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Kota">
                {isEdit ? <Field
                  name="city_id"
                  placeholder="Pilih Kota"
                  options={cityOptions}
                  component={renderSelect}
                />
                  : <Field
                    isDetail={!isEdit}
                    name="city"
                    placeholder="Masukkan Nama Pelatihan"
                    component={renderInput}
                  />
                }
              </FormContainer>
              <FormContainer label="Tahun">
                <Field
                  isDetail={!isEdit}
                  options={yearOptions}
                  name="year"
                  placeholder="Masukkan tahun"
                  component={renderSelect}
                />
              </FormContainer>
              {isEdit ? <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editExperience_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  filePath="member_experience"
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer> :
                filename === null ?
                  <span>Belum ada foto</span>
                  :
                  <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Berkas Pengalaman")} />} />
              }
              {/* <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editExperience_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_experience"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer> */}
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : status === 2
                        ? "Ditolak"
                        : "-"
                }
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

function validate(values, props) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { editExperience_isEditFile } = props;
  const { institution, experience_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!experience_type) {
    errors.experience_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editExperience_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  else{
    if (berkas){
      if (berkas.type !== imageType[0] && berkas.type !== imageType[1]){
        errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
      }
      else if (berkas.size > maxFileSize){
        errors.berkas = "File lebih dari 500KB"
      }
    }
  }

  return errors;
}

ExperienceDetail = reduxForm({
  // a unique name for the form
  form: "ExperienceModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ExperienceDetail);

function mapStateToProps(state) {
  const { detailExperienceMember, editExperienceMember, listCity } = state;
  let initialValues = {};
  const detailExperience_data = getDetailExperience(detailExperienceMember);
  if (detailExperience_data && detailExperience_data.length !== 0) {
    initialValues = {
      institution: detailExperience_data[0].institution,
      city: detailExperience_data[0].city,
      city_id: {
        label: detailExperience_data[0].city ? detailExperience_data[0].city : null,
        value: detailExperience_data[0].city ? detailExperience_data[0].city : null
      },

      position: detailExperience_data[0].position,
      year: {
        label: `${detailExperience_data[0].year}`,
        value: detailExperience_data[0].year,
      },
    };
  }

  return {
    detailExperience_data: getDetailExperience(detailExperienceMember),
    detailExperience_error: getExperienceError(detailExperienceMember),
    detailExperience_pending: getExperiencePending(detailExperienceMember),
    editExperience_error: getExperienceError(editExperienceMember),
    editExperience_pending: getExperiencePending(editExperienceMember),
    editExperience_isEditFile: getExperienceIsEditFile(editExperienceMember),
    listCity_data: getCityList(listCity),
    listCity_pending: getCityListPending(listCity),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailExperience,
  listCity,
  editExperience,
  setIsEditFile,
})(withRouter(ExperienceDetail));
