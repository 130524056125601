import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  listHospital,
  detailHospital,
} from "../../actions/master-data/hospital_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { provinceList } from "../../actions/master-data/province_action";
import ModalHospitalAdd from "./ModalHospitalAdd";
import ModalHospitalDelete from "./ModalHospitalDelete";
import ModalHospitalEdit from "./ModalHospitalEdit";
import {
  getHospitalPending,
  getListHospital,
  getHospitalError,
} from "../../reducers/master-data/hospital_reducer";
import HeaderHospital from "./HeaderHospital";

class Hospital extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      class_type: "",
      province_id: "",
      id: null
    };
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleOpenModalAdd = this.handleOpenModalAdd.bind(this);
    this.handleOpenModalEdit = this.handleOpenModalEdit.bind(this);
  }

  componentDidMount() {
    provinceList({ length: 999 });
    const id = this.props.match.params.id;
    if (id && this.state.id === null) {
      this.setState({ id: id })
    }
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleOpenModalAdd(id) {
    this.setState({ id, modalAddShow: true });
  }

  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleOpenModalEdit(id, isEdit = false) {
    const { detailHospital } = this.props;
    detailHospital(id);
    this.setState({ id, isEdit });
    this.setState({ modalEditShow: true });
  }

  render() {
    let data = [];
    let records_total = 0;
    const { listHospital, listHospital_data } = this.props;
    if (listHospital_data) {
      data = listHospital_data.data;
      records_total = listHospital_data.records_total;
    }
    const {
      id,
      isEdit,
      modalEditShow,
      modalDeleteShow,
      modalAddShow,
    } = this.state;
    return (
      <ContentContainer>
        <ModalHospitalAdd
          id={id}
          show={modalAddShow}
          onHide={() => this.setState({ modalAddShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalHospitalDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <ModalHospitalEdit
          id={id}
          show={modalEditShow}
          onHide={() => this.setState({ modalEditShow: false })}
          handleRefresh={this.handleRefresh}
          isEdit={isEdit}
        />
        <HeaderHospital
          handleRefresh={this.handleRefresh}
          handleOpenModalAdd={this.handleOpenModalAdd}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Kode RS",
              field: "code",
            },
            {
              title: "Nama RS",
              field: "name",
            },
            {
              title: "Kelas RS",
              field: "class_type",
            },
            {
              title: "Provinsi",
              field: "province",
            },
            {
              title: "Alamat",
              render: ({ address }) => address ? address : "-"
            },
            {
              title: "Pengunggah",
              field: "upload_by"
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() => this.handleOpenModalEdit(rowData.id)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.handleOpenModalDelete(rowData.id)}
                    >
                      Delete
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                class_type: this.state.class_type,
                province_id: this.state.province_id,
                id: this.state.id,
              };
              listHospital(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
            rowStyle: rowData => ({
              backgroundColor: (rowData.upload_by === "member") ? '#E9F8F0' : 'transparent'
            })
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listHospital }) {
  return {
    listHospital_data: getListHospital(listHospital),
    listHospital_error: getHospitalError(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
  };
}

export default connect(mapStateToProps, {
  listHospital,
  detailHospital,
  provinceList,
})(withRouter(Hospital));
