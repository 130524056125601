import React, { Component } from "react";
import { connect } from "react-redux";
// import { StyleSheet, css } from "aphrodite";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { listBenefit, setSelectedBenefit } from "../../actions/benefit/benefit_action";
import { getListBenefitPending, getListBenefit, getListBenefitError } from "../../reducers/benefit/benefit_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import HeaderBenefit from "./Header_benefit";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";
import ModalEditBenefit from "./Modal_edit_benefit";
import HeaderBenefitTotalSHU from "./Header_benefit_total_shu";
import { memberList } from "../../actions/member/member_action";
import { listKoperasi } from "../../actions/koperasi/koperasi_action";
import { getMemberListPending, getMemberList, getMemberListError } from "../../reducers/member/member_reducer";
import { getListKoperasiPending, getListKoperasi, getListKoperasiError } from "../../reducers/koperasi/koperasi_reducer";



class ListBenefit extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            id: null,
            search: "",
            status: "",
            isLoad: true,
            modalEditBenefitShow: false,
            columns: [
                {
                    title: "No", field: "name",
                    render: (rowData) => {
                        return <>{rowData.no}</>
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                {
                    title: "Tanggal Redeem",
                    render: (data) => {
                        return moment(data.redeem_date).locale("id", localization).format("DD MMMM YYYY");
                    }
                },
                { title: "Nama Dokter", field: "member_data.name" },
                { title: "ID Member", field: "member_data.member_profile.paboi_id" },
                { title: "Nama Benefit", field: "benefit_data.name" },
                {
                    title: "Total Redeem",
                    render: (data) => {
                        const total = data.total_redeem.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                        return "Rp " + total;
                    }
                },
                {
                    title: "Status",
                    render: (data) => {
                        return (
                            <TableStatusComponent
                                status={
                                    data.status === 0
                                        ? "Proses"
                                        : data.status === 1
                                            ? "Disetujui"
                                            : "Ditolak"
                                }
                            />
                        );
                    },
                    width: 210,
                },
                {
                    title: "",
                    render: (rowData) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                                    Detail
                                </MenuItem>
                            </DetailButtonComponent>
                        );
                    },
                },
            ],
            columnsMember: [
                {
                    title: "No", field: "name",
                    render: (rowData) => {
                        return <>{rowData.no}</>
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                {
                    title: "Tanggal Redeem",
                    render: (data) => {
                        return moment(data.redeem_date).locale("id", localization).format("DD MMMM YYYY");
                    }
                },
                { title: "Nama Benefit", field: "benefit_data.name" },
                {
                    title: "Total Redeem",
                    render: (data) => {
                        const total = data.total_redeem.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                        return "Rp " + total;
                    }
                },
                {
                    title: "Status",
                    render: (data) => {
                        return (
                            <TableStatusComponent
                                status={
                                    data.status === 0
                                        ? "Proses"
                                        : data.status === 1
                                            ? "Disetujui"
                                            : "Ditolak"
                                }
                            />
                        );
                    },
                    width: 210,
                },
                {
                    title: "",
                    render: (rowData) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                                    Detail
                                </MenuItem>
                                {rowData.status === 2 &&
                                    <MenuItem onClick={() => this.handleEditBenefit(rowData)}>
                                        Edit
                                    </MenuItem>
                                }
                            </DetailButtonComponent>
                        );
                    },
                },
            ]
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    detailRecord(id) {
        this.props.history.push(`/benefit/${id}`);
    }

    handleRefresh(state) {
        this.setState(state);
        this.setState({ id: null })
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleEditBenefit(data) {
        data = { ...data, isEditBenefit: true }
        this.props.setSelectedBenefit(data);
        this.setState({ modalEditBenefitShow: true })
    }

    componentDidMount() {
        let param_listBenefit = {
            page: 1,
            length: 10,
            search_text: "",
            id: "",
        };
        this.props.listBenefit(param_listBenefit);
        let param_member = {
            id: getUser().id,
            length: 99999
        }
        new Promise((callback) => {
            setTimeout(() => this.setState({ isLoad: false }), 2000);
            this.props.memberList(param_member, callback);
        });
        if (this.props.match.params.id && this.state.id === null) {
            this.setState({ id: this.props.match.params.id })
        }
        this.props.listKoperasi();
    }

    shouldComponentRender() {
        const { listBenefit_pending } = this.props;
        if (listBenefit_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const user = getUser().role_codes[0];
        const { listBenefit, listBenefit_data, memberList_data, listKoperasi, listKoperasi_data, } = this.props;
        let data = [];
        let records_total = "";
        let length = "";
        let total_shu = null;
        let shu = 0;
        if (listBenefit_data) {
            data = listBenefit_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        if (memberList_data && memberList_data.records_total > 0 && user === "member") {
            total_shu = 0;
            if (memberList_data.data[0].member_profile.total_shu != null){
                total_shu = memberList_data.data[0].member_profile.total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
        }

        if (listKoperasi_data) {
            shu = listKoperasi_data.total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        }
        return (
            <ContentContainer>
                {user === "member" &&
                    <HeaderBenefitTotalSHU
                        isLoad={this.state.isLoad}
                        total_shu={total_shu}
                        shu = {shu}
                    />
                }
                <HeaderBenefit handleRefresh={this.handleRefresh} />
                <ModalEditBenefit
                    show={this.state.modalEditBenefitShow}
                    onHide={() => this.setState({ modalEditBenefitShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Benefit"
                    columns={user === "member" ? this.state.columnsMember : this.state.columns}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                id: this.state.id,
                                status: this.state.status
                            };
                            listBenefit(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <Row
                                // vertical="center"
                                horizontal="space-between"
                                style={{ paddingRight: 20, paddingLeft: 20 }}
                            >
                                <Row
                                    style={{
                                        // backgroundColor: "red",
                                        width: "50%",
                                        borderBottom: "1px solid rgba(224, 224, 224)",
                                    }}
                                    vertical="center"
                                >
                                    <p> Menampilkan {length} dari {records_total} data </p>
                                </Row>
                                <TablePagination
                                    {...props}
                                    color="primary"
                                    labelRowsPerPage={
                                        <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                                    }
                                    labelDisplayedRows={(row) => (
                                        <div style={{ fontSize: 14, color: "red" }}>
                                            {/* {props.labelDisplayedRows(row)} */}
                                        </div>
                                    )}
                                    SelectProps={{
                                        style: {
                                            fontSize: 20,
                                        },
                                    }}
                                />
                            </Row>
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listBenefit, memberList, listKoperasi }) {
    return {
        listBenefit_pending: getListBenefitPending(listBenefit),
        listBenefit_data: getListBenefit(listBenefit),
        listBenefit_error: getListBenefitError(listBenefit),
        memberList_pending: getMemberListPending(memberList),
        memberList_data: getMemberList(memberList),
        memberList_error: getMemberListError(memberList),
        listKoperasi_pending: getListKoperasiPending(listKoperasi),
        listKoperasi_data: getListKoperasi(listKoperasi),
        listKoperasi_error: getListKoperasiError(listKoperasi),
    };
}

export default connect(mapStateToProps, { listBenefit, setSelectedBenefit, memberList, listKoperasi })(withRouter(ListBenefit));