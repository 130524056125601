import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { approvalSIP } from "../../actions/pengajuan-sip/sip_action";
import {
    getSIPAdd,
    getSIPAddPending,
    getSIPAddError
} from "../../reducers/pengajuan-sip/sip_reducer";

class ModalApprovalSIP extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { approvalSIP, onHide, handleRefresh, id } = this.props;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("is_approve", "true");
        const callback = () => {
            onHide();
            handleRefresh();
        };
        approvalSIP(formData, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            approvalSIP_pending
        } = this.props;
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        for (let i = currentYear; i >= 1957; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                cityOption={this.props.cityOption}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi Pengajuan SIP</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            Apakah Anda yakin ingin menyetujuinya?
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={approvalSIP_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    approvalSIP_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                YA
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

ModalApprovalSIP = reduxForm({
    form: "ModalApprovalSIP",
    // validate: validate,
    shouldError: () => true,
    enableReinitialize: true,
})(ModalApprovalSIP);

function mapStateToProps({ approvalSIP }) {
    return {
        approvalSIP_pending: getSIPAddPending(approvalSIP),
        approvalSIP_data: getSIPAdd(approvalSIP),
        approvalSIP_error: getSIPAddError(approvalSIP),
    };
}

export default connect(mapStateToProps, {
    approvalSIP,
})(withRouter(ModalApprovalSIP));