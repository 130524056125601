import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { addTransrs } from "../../actions/transrs/transrs_action";
import {
  renderSelectWithLabel,
  renderInputWithLabel,
  renderDatepickerWithLabel2,
} from "../../components/form/FormComponent";
import {
  getTransrsAdd,
  getTransrsAddPending,
  getTransrsAddError,
} from "../../reducers/transrs/transrs_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import FileValidation from "../../components/form/FileValidation";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import ModalDetailDokumenTxt from "../../components/global-components/Modal_document_txt";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import {
  memberList,
  memberP2kbExisting,
} from "../../actions/member/member_action";
import { default_api } from "../../constants/default_api";
import {
  getMemberList,
  getMemberP2kbExistingPending,
  getMemberP2kbExisting,
} from "../../reducers/member/member_reducer";
import { getUser } from "../../helpers/user";
import moment from "moment";
// import { ModalPopUpFile } from "../../components/modal/ModalPopUpFile"

class ModalTransRSAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: "",
      modal_title: "",
      folder: "",
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      modalDocumentTxtShow: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpenDocument = (imgUrl, modal_title, folder = "") => {
    if (modal_title === "CV") {
      window.open(default_api + "/member/download/cv/" + imgUrl + ".pdf");
    } else {
      this.setState({ folder: folder });
      this.setState({ imgUrl: imgUrl });
      this.setState({ modal_title: modal_title });
      if (imgUrl.split(".")[1] === "pdf") {
        this.setState({ modalDocumentPdfShow: true });
      } else {
        this.setState({ modalDocumentImgShow: true });
      }
    }
  };

  handleDownloadPdf = async () => {
    const res = await fetch(
      `${default_api}/image/show/shared/format-permohonan-tertulis.pdf`
    );
    const fileExt = res.headers.get("Content-Type");
    if (fileExt == "application/pdf") {
      window.location.href = `${default_api}/image/show/shared/format-permohonan-tertulis.pdf`;
    } else {
      this.setState({ modalDocumentTxtShow: true });
    }
  };

  handleSubmit(values) {
    const { addTransrs, onHide, handleRefresh, memberP2kbExisting_data } =
      this.props;
    const param = {
      branch_to: values.branch.value,
      hospital_id_to: values.hospital.value,
      file: values.berkas,
      str_no: values.str,
      attachments: values.file
        ? values.file.map((item) => ({
            attachment_name: item.name,
            attachment_filename: item.value,
          }))
        : "",
    };
    if (memberP2kbExisting_data.member_serkom !== null) {
      param.serkom_no = memberP2kbExisting_data.member_serkom.sk_no;
    }

    if (memberP2kbExisting_data.member_str !== null) {
      param.str_no = memberP2kbExisting_data.member_str.str_no;
    }

    if (memberP2kbExisting_data.member_sip !== null) {
      param.sip_no = memberP2kbExisting_data.member_sip.sip_no;
    }
    const callback = () => {
      onHide();
      handleRefresh();
    };
    addTransrs(param, callback);
  }

  componentDidMount() {
    if (getUser().role_codes[0] === "member") {
      this.props.memberList({ id: getUser().id });
      this.props.memberP2kbExisting({ id: getUser().id });
    }
  }

  isValidMoreThanSixMonth = (date) => {
    const sixMonthsAfterCurrentDate = moment(new Date()).add(6, "months");
    return moment(date).isSameOrAfter(sixMonthsAfterCurrentDate);
  };

  changeFileValue = (formName, value) => {
    this.props.change(formName, value);
  };

  render() {
    const {
      modalDocumentImgShow,
      modalDocumentPdfShow,
      modalDocumentTxtShow,
      modal_title,
      imgUrl,
      folder,
    } = this.state;
    const {
      handleSubmit,
      show,
      onHide,
      change,
      addTransrs_pending,
      branchOptions,
      hospitalOptions,
      memberList_data,
      memberP2kbExisting_data,
    } = this.props;
    let hasSIP,
      hasSTR,
      hasSerkom,
      hasKTP = false;
    const hasCV = true;
    const CVfilename = getUser().id;

    let SIPfilename,
      STRfilename,
      SerkomFilename,
      KTPfilename = null;
    if (getUser().role_codes[0] === "member") {
      change("memberID", getUser().member_profile.paboi_id);
    }
    if (memberList_data) {
      const data = memberList_data.data[0];
      KTPfilename = data.member_profile.scan_ktp_image;
      hasKTP = KTPfilename !== null ? true : false;
      change(
        "currentBranch",
        data.branch_data ? data.branch_data.name : "Belum ada cabang"
      );
    }
    if (memberP2kbExisting_data) {
      const data = memberP2kbExisting_data;
      if (data.member_str !== null) {
        change("str", data.member_str.str_no);
        hasSTR = true;
        STRfilename = data.member_str.filename;
      }
      if (data.member_sip !== null) {
        hasSIP = true;
        SIPfilename = data.member_sip.filename;
      }
      if (data.member_serkom !== null) {
        hasSerkom = true;
        SerkomFilename = data.member_serkom.filename;
      }
    }

    function parseHttpHeaders(httpHeaders) {
      return httpHeaders
        .split("\n")
        .map((x) => x.split(/: */, 2))
        .filter((x) => x[0])
        .reduce((ac, x) => {
          ac[x[0]] = x[1];
          return ac;
        }, {});
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        cityOption={this.props.cityOption}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalDocumentImg
          show={modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />
        <ModalDocumentPdf
          show={modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={imgUrl}
          modal_title={modal_title}
          folder={folder}
        />

        <ModalDetailDokumenTxt
          show={modalDocumentTxtShow}
          imgUrl={imgUrl}
          onHide={() => this.setState({ modalDocumentTxtShow: false })}
          modal_title={modal_title}
        />

        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Pengajuan Trans RS</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              {
                <>
                  <Field
                    name="date"
                    isDetail
                    placeholder="Masukkan tanggal pengajuan"
                    label="Tanggal Pengajuan"
                    component={renderDatepickerWithLabel2}
                  />
                  <Field
                    name="memberID"
                    label="No ID Member"
                    disable={true}
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="currentBranch"
                    label="Cabang Asal"
                    disable={true}
                    component={renderInputWithLabel}
                  />
                  <Field
                    name="str"
                    label="No STR"
                    disable={true}
                    component={renderInputWithLabel}
                  />
                  <Field
                    label="Cabang Tujuan"
                    name="branch"
                    placeholder="Pilih Cabang Tujuan"
                    options={branchOptions}
                    component={renderSelectWithLabel}
                  />
                  <Field
                    label="RS Tujuan"
                    name="hospital"
                    placeholder="Pilih RS Tujuan"
                    options={hospitalOptions}
                    isAsync
                    asyncUrl="master_data/hospital/find"
                    component={renderSelectWithLabel}
                  />

                  <a
                    className="openModalPopup"
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      // this.handleOpenPopUp()
                      this.handleDownloadPdf();
                    }}
                  >
                    <label className={css(styles.labelMargin)}>
                      Format Permohonan Tertulis
                    </label>
                  </a>

                  <Field
                    name="berkas"
                    label="Berkas"
                    type="file"
                    fileType="image" //image or pdf
                    title="upload berkas"
                    message="PNG atau JPG maksimal 500 kb"
                    component={renderFile}
                  />
                  <span style={{ marginBottom: 10 }}>Berkas</span>
                  <FileValidation
                    label="KTP"
                    hasFile={hasKTP}
                    openFile={() =>
                      this.handleOpenDocument(
                        KTPfilename,
                        "KTP",
                        "member_supporting_files"
                      )
                    }
                  />
                  <FileValidation
                    label="CV"
                    hasFile={hasCV}
                    openFile={() => this.handleOpenDocument(CVfilename, "CV")}
                  />
                  <FileValidation
                    label="STR"
                    hasFile={hasSTR}
                    openFile={() =>
                      this.handleOpenDocument(STRfilename, "STR", "member_str")
                    }
                  />
                  {hasSIP ? (
                    <FileValidation
                      label="SIP"
                      hasFile={hasSIP}
                      openFile={() =>
                        this.handleOpenDocument(
                          SIPfilename,
                          "SIP",
                          "member_sip"
                        )
                      }
                    />
                  ) : (
                    <label className={css(styles.labelMargin)}>SIP</label>
                  )}

                  {hasSerkom ? (
                    <FileValidation
                      label="Serkom"
                      hasFile={hasSerkom}
                      openFile={() =>
                        this.handleOpenDocument(
                          SerkomFilename,
                          "Serkom",
                          "member_serkom"
                        )
                      }
                    />
                  ) : (
                    <label className={css(styles.labelMargin)}>Serkom</label>
                  )}
                  <FieldArray
                    name="file"
                    component={DynamicFormComponent}
                    changeFileValue={this.changeFileValue}
                    folderPath="member_hospital_mutation"
                  />
                </>
              }
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addTransrs_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addTransrs_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  labelMargin: {
    marginTop: "10px",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"];
  const { branch, hospital, str, file, berkas } = values;
  const errors = {};
  // const fileArrayErrors = [];
  if (!branch) {
    errors.branch = "Cabang tujuan tidak boleh kosong!";
  }
  if (!hospital) {
    errors.hospital = "Rumah sakit tujuan tidak boleh kosong!";
  }
  if (!str) {
    errors.str = "Nomor STR tidak boleh kosong!";
  }
  if (!berkas) {
    errors.berkas = "Surat permohonan tertulis tidak boleh kosong!";
  }
  if (
    values.berkas &&
    values.berkas.type !== imageType[0] &&
    values.berkas.type !== imageType[1]
  ) {
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG";
  } else if (values.berkas && values.berkas.size > maxFileSize) {
    errors.berkas = "File lebih dari 500KB";
  }
  // if (!file || !file.length) {
  //     errors.file = {
  //         _error: "Berkas tidak boleh kosong"
  //     }
  // } else {
  //     file.forEach((item, index) => {
  //         if (!item) {
  //             fileArrayErrors[index] = "Berkas tidak boleh kosong"
  //         }
  //     })
  // }
  // if (fileArrayErrors.length) {
  //     errors.file = fileArrayErrors;
  // }
  return errors;
}

ModalTransRSAdd = reduxForm({
  form: "ModalAddTransRS",
  validate: validate,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalTransRSAdd);

function mapStateToProps({ addTransrs, memberList, memberP2kbExisting }) {
  return {
    addTransrs_pending: getTransrsAddPending(addTransrs),
    addTransrs_data: getTransrsAdd(addTransrs),
    addTransrs_error: getTransrsAddError(addTransrs),
    memberList_data: getMemberList(memberList),
    memberP2kbExisting_pending:
      getMemberP2kbExistingPending(memberP2kbExisting),
    memberP2kbExisting_data: getMemberP2kbExisting(memberP2kbExisting),
  };
}

export default connect(mapStateToProps, {
  addTransrs,
  memberList,
  memberP2kbExisting,
})(withRouter(ModalTransRSAdd));
