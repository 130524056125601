import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { getUser } from "../../helpers/user";
import Placeholder from "../../components/p2kb/skp_placeholder";
import FadeIn from "react-fade-in";
import IconRefresh from "../../assets/icon-refresh";
import moment from "moment";
import localization from "moment/locale/id";
import { findSHU } from "../../actions/koperasi/koperasi_action";
import { getSHUKoperasiPending, getSHUKoperasi, getSHUKoperasiError } from "../../reducers/koperasi/koperasi_reducer";
class HeaderSHU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            status: "",
            isLoad: false
        };
        this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
        this.handleSync = this.handleSync.bind(this);
    }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search: value });
    }, 500);

    handleYearSelectChange(e) {
        if (e) {
            this.props.handleRefresh({
                year: e.value
            });
        } else {
            this.props.handleRefresh({
                year: null
            });
        }
    }

    handleSync() {
        this.setState({ isLoad: true });
        const callback = () => {
            this.setState({ isLoad: false });
            this.delayedQuery();
        }
        this.props.findSHU({ length: 10 }, callback);
    }

    render() {
        const { findSHU_data } = this.props;
        const user = getUser().role_codes[0];
        let currentYear = new Date().getFullYear();
        let yearOptions = [];
        let lastUpdate;
        let totalMainDeposit, totalRequiredDeposit, shu = "";
        for (let i = currentYear; i >= 1900; i--) {
            yearOptions = [...yearOptions, { label: i, value: i }];
        }
        if (findSHU_data) {
            lastUpdate = findSHU_data.latest_update;
            totalRequiredDeposit = findSHU_data.total_cooperation.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            totalMainDeposit = findSHU_data.total_membership.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            shu = findSHU_data.total_shu.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        }
        return (
            <Column>
                {user === "member" &&
                    <Column>
                        <div className={css(styles.detailHeaderContainer)}>
                            <Row>
                                <Column className={css(styles.detailHeaderBenefitColumn)}>
                                    <div>
                                        <div className={css(styles.detailHeaderTitle)}>
                                            Simpanan Wajib
                                        </div>
                                        <div className={css(styles.detailHeaderValue)}>
                                            <span className={css(styles.totalSHUValue)}>Rp {totalRequiredDeposit}</span>
                                        </div>
                                    </div>
                                </Column>
                                <Column className={css(styles.detailHeaderBenefitColumn)}>
                                    <div>
                                        <div className={css(styles.detailHeaderTitle)}>
                                            Simpanan Pokok
                                        </div>
                                        <div className={css(styles.detailHeaderValue)}>
                                            <span className={css(styles.totalSHUValue)}>Rp {totalMainDeposit}</span>
                                        </div>
                                    </div>
                                </Column>
                                <Column className={css(styles.detailHeaderBenefitColumn)}>
                                    <div>
                                        <div className={css(styles.detailHeaderTitle)}>
                                            SHU
                                        </div>
                                        <div className={css(styles.detailHeaderValue)}>
                                            <span className={css(styles.totalSHUValue)}>Rp {shu}</span>
                                        </div>
                                    </div>
                                </Column>
                            </Row>
                        </div>
                    </Column>
                }
                <Row
                    style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                    horizontal={"space-between"}
                >
                    {/* {user === "root" &&
                        <Row>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                startIcon={<AddIcon />}
                                onClick={() => this.setState({ modalCheckSHUShow: true })}
                            >
                                tambah tagihan
                            </Button>
                        </Row>
                    } */}
                    {user === "member" &&
                        <Row>
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.refreshButton)}
                                startIcon={<IconRefresh />}
                                onClick={this.handleSync}
                            >
                                Refresh
                            </Button>
                            <div className={css(styles.syncDiv)}>
                                {this.state.isLoad || lastUpdate === null ? (
                                    <Placeholder childwidth={200} parentwidth={200} title="" />
                                ) :
                                    <FadeIn>
                                        <label className={css(styles.syncLabel)}>
                                            {moment(lastUpdate)
                                                .locale("id", localization)
                                                .format("dddd, DD MMMM YYYY HH:mm")}
                                        </label>
                                    </FadeIn>
                                }
                            </div>
                        </Row>
                    }
                </Row >
            </Column>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: 150,
        marginLeft: 10,
        zIndex: 999
    },
    refreshButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    syncDiv: {
        paddingTop: 5
    },
    syncLabel: {
        paddingTop: 7
    },
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderBenefitColumn: {
        margin: "20px 20px 20px 30px",
        width: "15%"
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
    totalSHUValue: {
        color: "#43C57C"
    }
});

function mapStateToProps(state) {
    const { findSHU } = state;
    return {
        findSHU_pending: getSHUKoperasiPending(findSHU),
        findSHU_data: getSHUKoperasi(findSHU),
        findSHU_error: getSHUKoperasiError(findSHU),
    };
}

export default connect(mapStateToProps, { findSHU })(HeaderSHU);