import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import ModalAddAdvertisement from "./Modal_add_advertisement";

const styles = StyleSheet.create({
    container: {
        width: 150,
        marginLeft: 10,
    },
});
class HeaderAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_text: "",
            status: "",
            modalAddAdvertisementShow: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search_text: value });
    }, 500);

    handleRefresh() {
        this.delayedQuery("");
    }

    handleSearchChange(e) {
        this.setState({ search_text: e.target.value });
        this.delayedQuery(e.target.value);
    }

    render() {
        return (
            <Row
                style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="space-between"
            >
                <ModalAddAdvertisement
                    show={this.state.modalAddAdvertisementShow}
                    onHide={() => this.setState({ modalAddAdvertisementShow: false })}
                    handleRefresh={this.handleRefresh}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={css(styles.approveButton)}
                    startIcon={<AddIcon />}
                    onClick={() => this.setState({ modalAddAdvertisementShow: true })}
                >
                    tambah iklan
                </Button>
                <div className={css(styles.container)}>
                    <InputComponent
                        onChange={this.handleSearchChange}
                        value={this.state.tes}
                        placeholder="Cari Iklan"
                        startIcon={SearchIcon}
                    />
                </div>
            </Row>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {})(HeaderAdvertisement);