import React, { Component } from "react";
import { renderInputWithLabel } from "../../components/form/FormComponent";
import { Field } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";

class RenderArray extends Component {
    render() {
        const {
            fields,
            meta: { submitFailed, error },
        } = this.props;

        return (
            <>
                {submitFailed && error && (
                    <Row
                        style={{
                            height: 38,
                            color: "#ED2A2A",
                            borderRadius: 3,
                            marginTop: 5,
                            marginBottom: 10,
                            backgroundColor: "#FFDEDE",
                        }}
                        horizontal="center"
                        vertical="center"
                    >
                        <span>
                            <ErrorIcon style={{ marginRight: 10 }} />
                            {error}
                        </span>
                    </Row>
                )}
                {fields.map((specificRemarks, index) => (
                    <Row
                        horizontal="space-between"
                        key={index}
                        style={{ marginBottom: 5 }}
                    >
                        <div className={css(styles.Selectcontainer)}>
                            <Field
                                name={specificRemarks}
                                placeholder="Masukkan specific remarks"
                                isTextarea={true}
                                component={renderInputWithLabel}
                            />
                        </div>
                        <CancelIcon
                            className={css(styles.closeButton)}
                            onClick={() => fields.remove(index)}
                        />
                    </Row>
                ))}

                <Column
                    horizontal="center"
                    vertical="center"
                    className={css(styles.buttonContainer)}
                >
                    <div
                        style={{
                            width: "100%",
                            borderBottom: "2px solid #EDEDEE",
                            top: 18,
                            position: "relative",
                        }}
                    />
                    <div
                        style={{ padding: "0px 10px", backgroundColor: "white", zIndex: 0 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            // onClick={this.handleSubmit}
                            startIcon={<AddIcon />}
                            onClick={() => fields.push()}
                        >
                            Tambah
                        </Button>
                    </div>
                </Column>
            </>
        );
    }
}

const styles = StyleSheet.create({
    closeButton: {
        color: "#CDCFD1",
        marginTop: 7,
        ":hover": {
            color: "#ED2A2A",
        },
    },
    Selectcontainer: { width: "100%" },
    approveButton: {
        zIndex: 0,
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
});

function mapStateToProps() {
}

export default connect(mapStateToProps)(RenderArray);