import React, { Component } from "react";
import { Row } from "simple-flexbox";

import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";

import {
  detailTraining,
  editTraining,
  setIsEditFile,
} from "../../actions/member/training_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import {
  getTrainingPending,
  getDetailTraining,
  getTrainingError,
  getTrainingIsEditFile,
} from "../../reducers/member/training_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { getUser } from "../../helpers/user";
import {
  renderInput,
  renderFile,
  renderSelect,
} from "../../components/form/FormComponent";
import Button from "@material-ui/core/Button";
import TrainingModalApprove from "./TrainingModalApprove";
import TrainingModalReject from "./TrainingModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
import EditButtonComponent from "../../components/form/EditButtonComponent";

class TrainingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailTraining } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailTraining(id);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editTraining_isEditFile, setIsEditFile } = this.props;
    if (editTraining_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editTraining_isEditFile);
  }

  handleSubmit(values) {
    const { detailTraining_data, editTraining, detailTraining } = this.props;

    const { berkas, institution, training_type, year, name } = values;
    const param = {
      id: detailTraining_data[0].id,
      filename: detailTraining_data[0].filename,
      institution,
      name,
      training_type,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailTraining();
      this.handleTriggerFormEdit();
    };
    editTraining(param, callback);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true });
    } else {
      this.setState({ modalDocumentImgShow: true });
    }
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailTraining_data,
      editTraining_pending,
      handleSubmit,
      editTraining_isEditFile,
    } = this.props;
    const { isEdit, showApproveModal, showRejectModal } = this.state;
    let name, filename, status, reject_notes, reject_filename;

    if (detailTraining_data && detailTraining_data.length !== 0) {
      name = detailTraining_data[0].member_data.name;
      filename = detailTraining_data[0].filename;
      status = detailTraining_data[0].status;
      reject_notes = detailTraining_data[0].reject_notes;
      reject_filename = detailTraining_data[0].reject_filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_training"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_training"
        />
        <TrainingModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <TrainingModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                  ? "Data Pelatihan Terverifikasi"
                  : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                  ? "Terverifikasi"
                  : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_training"
            />
            {getUser().role_codes[0] === "root" && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  Tolak
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Pelatihan" />
                {getUser().role_codes[0] === "member" && status === 2 && (
                  <>
                    <EditButtonComponent
                      isEdit={isEdit}
                      triggerIsEdit={this.handleTriggerFormEdit}
                      isLoading={editTraining_pending}
                    />
                  </>
                )}
                {getUser().role_codes[0] === "root" && (
                  <>
                    <EditButtonComponent
                      isEdit={isEdit}
                      triggerIsEdit={this.handleTriggerFormEdit}
                      isLoading={editTraining_pending}
                    />
                  </>
                )}
              </Row>
              <FormContainer label="Nama Institusi">
                <Field
                  isDetail={!isEdit}
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Nama Pelatihan">
                <Field
                  isDetail={!isEdit}
                  name="name"
                  placeholder="Masukkan Nama Pelatihan"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Tahun">
                <Field
                  isDetail={!isEdit}
                  options={yearOptions}
                  name="year"
                  placeholder="Masukkan tahun"
                  component={renderSelect}
                />
              </FormContainer>
              {isEdit ? (
                <FormContainer label="Berkas">
                  <Field
                    triggerEditFile={this.handleTriggerEditFile}
                    isEditFile={editTraining_isEditFile}
                    isDetail={!isEdit}
                    filename={filename}
                    filePath="member_training"
                    name="berkas"
                    type="file"
                    fileType="image" //image or pdf
                    title="upload berkas"
                    message="PNG atau JPG maksimal 500 kb"
                    component={renderFile}
                  />
                </FormContainer>
              ) : filename === null ? (
                <span>Belum ada foto</span>
              ) : (
                <DetailContentComponent
                  label="Berkas"
                  value={
                    <DetailContentLihatBerkas
                      onClick={() =>
                        this.handleOpenDocument(filename, "Berkas Pelatihan")
                      }
                    />
                  }
                />
              )}
              {/* <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editTraining_isEditFile}
                  isDetail={!isEdit}
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  filePath="member_training"
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer> */}
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                    ? "Terverifikasi"
                    : status === 2
                    ? "Ditolak"
                    : "-"
                }
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
  detailSectionName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20,
  },
});

function validate(values, props) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"];
  const { editTraining_isEditFile } = props;
  const { institution, training_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!training_type) {
    errors.training_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editTraining_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  } else {
    if (
      values.berkas &&
      values.berkas.type !== imageType[0] &&
      values.berkas.type !== imageType[1]
    ) {
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG";
    } else if (values.berkas && values.berkas.size > maxFileSize) {
      errors.berkas = "File lebih dari 500KB";
    }
  }

  return errors;
}

TrainingDetail = reduxForm({
  // a unique name for the form
  form: "TrainingModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(TrainingDetail);

function mapStateToProps(state) {
  const { detailTrainingMember, editTrainingMember } = state;
  let initialValues = {};
  const detailTraining_data = getDetailTraining(detailTrainingMember);
  if (detailTraining_data && detailTraining_data.length !== 0) {
    initialValues = {
      institution: detailTraining_data[0].institution,
      name: detailTraining_data[0].name,
      year: {
        label: `${detailTraining_data[0].year}`,
        value: detailTraining_data[0].year,
      },
    };
  }

  return {
    detailTraining_data: getDetailTraining(detailTrainingMember),
    detailTraining_error: getTrainingError(detailTrainingMember),
    detailTraining_pending: getTrainingPending(detailTrainingMember),
    editTraining_error: getTrainingError(editTrainingMember),
    editTraining_pending: getTrainingPending(editTrainingMember),
    editTraining_isEditFile: getTrainingIsEditFile(editTrainingMember),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailTraining,
  editTraining,
  setIsEditFile,
})(withRouter(TrainingDetail));
