import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import { revisionJournal } from "../../actions/journal/journal_action";
import {
    getJournalRevision,
    getJournalRevisionPending,
    getJournalRevisionError,
} from "../../reducers/journal/journal_reducer";
import { connect } from "react-redux";
import { getListJournal } from "../../reducers/journal/journal_reducer";

class ModalDetailRevisionJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { listJournal_data } = this.props;
        let data, revision, specificRemarks, latestRevision;
        let improve = [];
        if (listJournal_data) {
            data = listJournal_data.data[0];
            revision = data.journal_revisions;
            latestRevision = revision.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
            specificRemarks = latestRevision.journal_revision_remarks;
            improve = [...improve, latestRevision.method ? "Methods" : null, latestRevision.result ? "Results" : null, latestRevision.discussion ? "Discussion" : null];
            improve = improve.filter(item => item !== null);
        }
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                id={this.props.id}
                reviewer_id={this.props.reviewer_id}
                dialogClassName="modal-width"
                // centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Revisi Spesifik</p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        {specificRemarks && specificRemarks.map((value, index) => {
                            return (
                                <Column className={css(styles.speceficRemarksDiv)}>
                                    {value.remarks.split("\n").map((item, idx) => <span className={css(styles.specificRemarksContent)} key={idx}>{item}</span>)}
                                </Column>
                            )
                        })}
                        {improve && improve.length > 0 &&
                            <Column className={css(styles.improveDiv)}>
                                <label className={css(styles.improveTitle)}>
                                    Here are {improve.length === 1 ? 'one' : improve.length === 2 ? 'two' : improve.length === 3 ? 'three' : ""} {improve.length > 1 ? 'things' : 'thing'} to improve
                                </label>
                                <Column>
                                    {improve.map((item, idx) => <span className={css(styles.improveContent)}> {idx + 1}. {item}</span>)}
                                </Column>
                            </Column>
                        }
                    </Column>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    revisionButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    speceficRemarksDiv: {
        borderBottom: "2px solid #EDEDEE",
        padding: "20px 0px",
        width: "100%"
    },
    specificRemarksContent: {
        color: "#222D17"
    },
    improveDiv: {
        padding: "20px 0px"
    },
    improveTitle: {
        fontSize: 16,
        color: "#2C2C2C",
        fontWeight: "bold",
        marginBottom: 15
    },
    improveContent: {
        color: "#222D17",
        marginBottom: 10
    }
});

function mapStateToProps({ approvalJournal, revisionJournal, listJournal }) {
    return {
        approvalRevisionJournal_pending: getJournalRevisionPending(revisionJournal),
        approvalRevisionJournal_data: getJournalRevision(revisionJournal),
        approvalRevisionJournal_error: getJournalRevisionError(revisionJournal),
        listJournal_data: getListJournal(listJournal),
    };
}

export default connect(mapStateToProps, { revisionJournal })(ModalDetailRevisionJournal);