import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
class EducationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ education: e.target.value });
    this.delayedQuery(e.target.value);
  }

  render() {
    const windowWidth = window.innerWidth;
    return (
      <Row
        style={{ padding: 20, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
        horizontal="flex-end"
      >
        {windowWidth <= 768 ?
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid xs={0} sm={6}></Grid>
                <Grid xs={12} sm={6}>
                  <InputComponent
                    onChange={this.handleSearchChange}
                    value={this.state.education}
                    placeholder="Cari nama member"
                    startIcon={SearchIcon}
                  />
                </Grid>
              </Grid>

            </AccordionDetails>
          </Accordion>
          :
          <Grid container>
            <Grid xs={false} sm={6} md={9} item={true}></Grid>
            <Grid xs={12} sm={6} md={3} item={true}>
              <InputComponent
                onChange={this.handleSearchChange}
                value={this.state.education}
                placeholder="Cari nama member"
                startIcon={SearchIcon}
              />
            </Grid>
          </Grid>
        }
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(EducationHeader);
