import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_ADVERTISEMENT_PENDING = "LIST_ADVERTISEMENT_PENDING";
export const LIST_ADVERTISEMENT_SUCCESS = "LIST_ADVERTISEMENT_SUCCESS";
export const LIST_ADVERTISEMENT_ERROR = "LIST_ADVERTISEMENT_ERROR";

export const LIST_ACTIVE_ADVERTISEMENT_PENDING = "LIST_ACTIVE_ADVERTISEMENT_PENDING";
export const LIST_ACTIVE_ADVERTISEMENT_SUCCESS = "LIST_ACTIVE_ADVERTISEMENT_SUCCESS";
export const LIST_ACTIVE_ADVERTISEMENT_ERROR = "LIST_ACTIVE_ADVERTISEMENT_ERROR";

export const DETAIL_ADVERTISEMENT_PENDING = "DETAIL_ADVERTISEMENT_PENDING";
export const DETAIL_ADVERTISEMENT_SUCCESS = "DETAIL_ADVERTISEMENT_SUCCESS";
export const DETAIL_ADVERTISEMENT_ERROR = "DETAIL_ADVERTISEMENT_ERROR";

export const ADD_ADVERTISEMENT_PENDING = "ADD_ADVERTISEMENT_PENDING";
export const ADD_ADVERTISEMENT_SUCCESS = "ADD_ADVERTISEMENT_SUCCESS";
export const ADD_ADVERTISEMENT_ERROR = "ADD_ADVERTISEMENT_ERROR";

export const EDIT_ADVERTISEMENT_PENDING = "EDIT_ADVERTISEMENT_PENDING";
export const EDIT_ADVERTISEMENT_SUCCESS = "EDIT_ADVERTISEMENT_SUCCESS";
export const EDIT_ADVERTISEMENT_ERROR = "EDIT_ADVERTISEMENT_ERROR";

export const DELETE_ADVERTISEMENT_PENDING = "DELETE_ADVERTISEMENT_PENDING";
export const DELETE_ADVERTISEMENT_SUCCESS = "DELETE_ADVERTISEMENT_SUCCESS";
export const DELETE_ADVERTISEMENT_ERROR = "DELETE_ADVERTISEMENT_ERROR";

export const SET_SELECTED_ADVERTISEMENT = "SET_SELECTED_ADVERTISEMENT";

const LIST_ADVERTISEMENT_URL = "advtsment/find";
const LIST_ACTIVE_ADVERTISEMENT_URL = "advtsment/find_active";
const ADD_ADVERTISEMENT_URL = "advtsment/add";
const EDIT_ADVERTISEMENT_URL = "advtsment/edit";
const DELETE_ADVERTISEMENT_URL = "advtsment/delete";

export function listAdvertisement(param = {}, resolve) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ADVERTISEMENT_PENDING));
        API.post(LIST_ADVERTISEMENT_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ADVERTISEMENT_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    resolve({
                        data: result,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal mendapatkan list iklan, ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function listActiveAdvertisement(param = {}, resolve) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ACTIVE_ADVERTISEMENT_PENDING));
        API.post(LIST_ACTIVE_ADVERTISEMENT_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ACTIVE_ADVERTISEMENT_SUCCESS, data));
                const { records_total, data: response } = data;
                const result = response.map((item, i) => ({
                    no: i + 1 + (param.page - 1) * 10,
                    ...item,
                }));
                if (resolve) {
                    resolve({
                        data: result,
                        page: param.page - 1,
                        totalCount: records_total,
                    });
                }
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ACTIVE_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal mendapatkan list iklan, ");
                if (resolve) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                }
            });
    };
}

export function detailAdvertisement(id = null) {
    const param = {
        id: id,
    };
    return (dispatch) => {
        dispatch(actionPending(DETAIL_ADVERTISEMENT_PENDING));
        API.post(LIST_ADVERTISEMENT_URL, param, getHeader())
            .then((res) => {
                dispatch(actionSuccess(DETAIL_ADVERTISEMENT_SUCCESS, res.data.data));
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DETAIL_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal mendapatkan list iklan, ");
            });
    };
}

export function setSelectedAdvertisement(value) {
    return {
        type: SET_SELECTED_ADVERTISEMENT,
        value: value,
    };
}

export function addAdvertisement(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(ADD_ADVERTISEMENT_PENDING));
        API.post(ADD_ADVERTISEMENT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(ADD_ADVERTISEMENT_SUCCESS, res.data.data));
                toastSuccess("Berhasil menambahkan iklan");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(ADD_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal menambahkan iklan, ");
            });
    };
}

export function editAdvertisement(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(EDIT_ADVERTISEMENT_PENDING));
        API.post(EDIT_ADVERTISEMENT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(EDIT_ADVERTISEMENT_SUCCESS, res.data.data));
                toastSuccess("Berhasil mengubah iklan");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(EDIT_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal mengubah iklan, ");
            });
    };
}

export function deleteAdvertisement(param = {}, resolve, callback) {
    return (dispatch) => {
        dispatch(actionPending(DELETE_ADVERTISEMENT_PENDING));
        API.post(DELETE_ADVERTISEMENT_URL, param, getHeader())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(actionSuccess(DELETE_ADVERTISEMENT_SUCCESS, res.data.data));
                toastSuccess("Berhasil menghapus iklan");

                if (resolve) {
                    resolve();
                }
                if (callback) {
                    callback();
                }
                return res;
            })
            .catch((error) => {
                dispatch(actionError(DELETE_ADVERTISEMENT_ERROR, error));
                toastError(error, "Gagal menghapus iklan, ");
            });
    };
}