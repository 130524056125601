import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { listIuran } from "../../actions/iuran/iuran_action";
import { getListIuran, getListIuranPending, getListIuranError } from "../../reducers/iuran/iuran_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import PaginationComponent from '../../components/table/PaginationComponent';
import { Paper } from "@material-ui/core";
import HeaderIuran from "./Header_iuran";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";

class ListIuran extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            search: "",
            status: "",
            columns: [
                {
                    title: "No", field: "name",
                    render: (rowData) => {
                        return <>{rowData.no}</>
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                {
                    title: "Tanggal Tagihan",
                    render: (data) => {
                        return moment(data.billing_date).locale("id", localization).format("DD MMMM YYYY");
                    }
                },
                { title: "Nama Dokter", field: "member_data.name" },
                { title: "ID Koperasi", field: "member_data.member_profile.paboi_id" },
                {
                    title: "Total Pembayaran",
                    render: (data) => {
                        const total = data.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                        return "Rp " + total;
                    }
                },
                {
                    title: "Tanggal Pembayaran",
                    render: (data) => {
                        if (data.payment_date !== null) {
                            return moment(data.payment_date).locale("id", localization).format("DD MMMM YYYY");
                        } else {
                            return "-";
                        }
                    }
                },
                {
                    title: "Status",
                    render: (data) => {
                        return (
                            <TableStatusComponent
                                status={
                                    data.status === 0
                                        ? "Unpaid"
                                        : data.status === 1
                                            ? "Paid"
                                            : data.status === 2
                                                ? "Overdue"
                                                : "Late Payment"
                                }
                            />
                        );
                    },
                    width: 210,
                },
                {
                    title: "",
                    render: (rowData) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                                    Detail
                                </MenuItem>
                            </DetailButtonComponent>
                        );
                    },
                },
            ],
            columnsMember: [
                {
                    title: "No", field: "name",
                    render: (rowData) => {
                        return <>{rowData.no}</>
                    },
                    width: 20,
                    headerStyle: {
                        paddingLeft: 40,
                    },
                    cellStyle: {
                        paddingLeft: 40,
                    },
                },
                {
                    title: "Tanggal Tagihan",
                    render: (data) => {
                        return moment(data.created_at).locale("id", localization).format("DD MMMM YYYY");
                    }
                },
                {
                    title: "Total Pembayaran",
                    render: (data) => {
                        const total = data.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                        return "Rp " + total;
                    }
                },
                {
                    title: "Status",
                    render: (data) => {
                        return (
                            <TableStatusComponent
                                status={
                                    data.status === 0
                                        ? "Unpaid"
                                        : data.status === 1
                                            ? "Paid"
                                            : data.status === 2
                                                ? "Overdue"
                                                : "Late Payment"
                                }
                            />
                        );
                    },
                    width: 210,
                },
                {
                    title: "",
                    render: (rowData) => {
                        return (
                            <DetailButtonComponent>
                                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                                    Detail
                                </MenuItem>
                            </DetailButtonComponent>
                        );
                    },
                },
            ]
        };
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    detailRecord(id) {
        this.props.history.push(`/iuran/${id}`);
    }

    handleRefresh(state) {
        this.setState(state);
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    componentDidMount() {
        let param_listIuran = {
            page: 1,
            length: 10,
            search_text: "",
            id: "",
        };
        this.props.listIuran(param_listIuran);
    }

    shouldComponentRender() {
        const { listIuran_pending } = this.props;
        if (listIuran_pending === false) {
            return false;
        }
        return true;
    }

    render() {
        const user = getUser().role_codes[0];
        const { listIuran, listIuran_data } = this.props;
        let data = [];
        let records_total = "";
        let length = "";
        if (listIuran_data) {
            data = listIuran_data;
            records_total = data.records_total;
            length = data.data.length;
        }
        return (
            <ContentContainer>
                <HeaderIuran handleRefresh={this.handleRefresh} />
                <MaterialTable
                    tableRef={this.tableRef}
                    title="Member"
                    columns={user === "root" ? this.state.columns : this.state.columnsMember}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                                search_text: this.state.search,
                                id: "",
                                status: this.state.status
                            };
                            listIuran(param, resolve, reject);
                        })
                    }
                    options={{
                        paginationType: "stepped",
                        pageSize: 10,
                        showTitle: false,
                        search: false,
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={length}
                                records_total={records_total}
                                {...props}
                            />
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ listIuran }) {
    return {
        listIuran_pending: getListIuranPending(listIuran),
        listIuran_data: getListIuran(listIuran),
        listIuran_error: getListIuranError(listIuran),
    };
}

export default connect(mapStateToProps, { listIuran })(withRouter(ListIuran));