import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import SelectComponent from "../../components/form/SelectComponent";
import _ from "lodash";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const styles = StyleSheet.create({
    filterPeran: {
        width: 200,
        marginLeft: 10,
        zIndex: 999
    },
    filterStatus: {
        width: 150,
        marginLeft: 10,
        zIndex: 999
    },
});
class HeaderAuditTrail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            date: new Date(),
            status: null
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }
    componentDidMount() { }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search: value });
    }, 500);

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
        this.delayedQuery(e.target.value);
    }

    handleDateChange(e) {
        const date = moment(e).format('yyyy-MM-DD');
        if (e) {
            this.props.handleRefresh({ date: date });
            this.setState({ date: date });
        } else {
            this.props.handleRefresh({ date: "" });
            this.setState({ date: "" });
        }
    }

    handleStatusChange(e) {
        if (e) {
            this.props.handleRefresh({ status: e.value });
        } else {
            this.props.handleRefresh({ status: "" });
        }
    }

    handleRoleChange(e) {
        if (e) {
            this.props.handleRefresh({ role_code: e.value });
        } else {
            this.props.handleRefresh({ role_code: "" });
        }
    }

    render() {
        const statusOptions = [
            {
                label: "Success",
                value: 200
            },
            {
                label: "Failed",
                value: 500
            }
        ]
        return (
            <Row
                style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="flex-end"
            >
                <div className={css(styles.filterPeran)}>
                    <InputComponent
                        onChange={this.handleSearchChange}
                        value={this.state.search}
                        placeholder="Cari Nama Pengguna"
                        startIcon={SearchIcon}
                    />
                </div>
                <div className={css(styles.filterPeran)}>
                    <SelectComponent
                        options={this.props.roleOptions}
                        placeholder="Peran"
                        onChange={this.handleRoleChange}
                    />
                </div>
                <div className={css(styles.filterStatus)}>
                    <SelectComponent
                        options={statusOptions}
                        placeholder="Status"
                        onChange={this.handleStatusChange}
                    />
                </div>
                <div style={{ marginLeft: 10, width: 150 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
                        <KeyboardDatePicker
                            margin="dense"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            value={this.state.date}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            onChange={this.handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </Row >
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(HeaderAuditTrail);