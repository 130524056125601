import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../../components/container/ContainerComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IconDocumentP2KB from "../../../assets/icon-document-p2kb";
import IconLaunch from "../../../assets/icon-launch";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { StyleSheet, css } from "aphrodite";
import DetailButtonComponent from "../../../components/global-components/DetailButtonComponent";
import HeaderDetailP2kbComponent from "../../../components/p2kb/HeaderDetailP2kbComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

import { detailActivity } from "../../../actions/master-data/activity_action";
import IconError from "../../../assets/icon-error";
import ModalDocument from "../../../components/modal/ModalDocument";
import ModalDeleteSKP from "../ModalDeleteSKP";
import {
  p2kbList,
  editP2kb,
  detailP2kb,
  deleteP2kb,
  setSelectedSKP,
} from "../../../actions/p2kb/p2kb_action";
import {
  getP2kbDeletePending,
  getP2kbDelete,
  getP2kbDeleteError,
  getP2kbListPending,
  getP2kbList,
  getDetailP2kb,
  getP2kbListError,
  getP2kbEditPending,
  getP2kbEdit,
  getP2kbEditError,
  getP2kbPending,
} from "../../../reducers/p2kb/p2kb_reducer";
import moment from "moment";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import MembimbingMahasiswaModalAdd from "./MembimbingMahasiswaModalAdd";
import ModalRejectSKP from "../Modal_reject_skp";
import PaginationComponent from "../../../components/table/PaginationComponent";
import { toast } from "react-toastify";
import MembimbingMahasiswaModalEdit from "./MembimbingMahasiswaModalEdit";
import MembimbingMahasiswaModalDetail from "./MembimbingMahasiswaModalDetail";
import TableStatusComponent from "../../../components/global-components/TableStatusComponent";

class MembimbingMahasiswaDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      showDocument: false,
      showModalEdit: false,
      showModalDetail: false,
      showModalReject: false,
      ModalDeleteSKPShow: false,
      DeletedData: null,
      hasEdit: false,
      start_date: localStorage.getItem('start_date') ? localStorage.getItem('start_date') : '',
      end_date: localStorage.getItem('end_date') ? localStorage.getItem('end_date') : '',
      filename: "",
      modalTitle: "",
      data: [],
      p2kb_details: [],
      setData: () => {
        if (
          this.props.detailP2kb_data &&
          this.props.detailP2kb_data.length !== 0 && this.props.detailP2kb_data !== undefined
        ) {
          let arrayData = [];
          this.props.detailP2kb_data.map((item, index) => {
            item.p2kb_details.map((data, idx) => {
              let details = {
                no: idx + 1,
                id: data.id,
                last_reject_filename: data.last_reject_filename,
                last_reject_notes: data.last_reject_notes,
                activity_date: data.activity_date,
                last_activity: { label: data.mst_activity_skp_type_detail_data.name, value: data.mst_activity_skp_type_detail_data.id },
                values_id: { label: data.activity_skp_type_value_data.label, value: data.activity_skp_type_value_data.id },
                skp_total: data.total_skp,
                total_skp_eksternal: data.total_skp_eksternal,
                berkas: data.attachments[0].filename,
                ...JSON.parse(data.form_json),
                status: data.status,
              }
              arrayData.push(details)
            })
          })
          const newData = this.props.detailP2kb_data[0].p2kb_details.map(
            (item, index) => {
              const {
                id,
                activity_date,
                form_json,
                activity_skp_type_value_data: {
                  label: values_label,
                  id: values_value,
                },
                mst_activity_skp_type_detail_data: {
                  name: last_activity_name,
                  id: last_activity_id,
                },
                attachments: [{ filename }],
                total_skp,
                status,
                last_reject_filename,
                last_reject_notes,
              } = item;

              return {
                no: index + 1,
                id,
                activity_date,
                values_id: {
                  label: values_label,
                  value: values_value,
                },
                last_activity: {
                  label: last_activity_name,
                  value: last_activity_id,
                },
                skp_total: total_skp,
                berkas: filename,
                last_reject_filename,
                last_reject_notes,
                ...JSON.parse(form_json),
                status,
              };
            }
          );
          this.setState({ data: arrayData, dataTemp: newData });
        }
        else {
          this.props.history.push(`/p2kb/add`);
        }
      },
    };

    this.handleEditSkp = this.handleEditSkp.bind(this);
    this.handleShowModalEdit = this.handleShowModalEdit.bind(this);
    this.handleDeleteSkp = this.handleDeleteSkp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowReject = this.handleShowReject.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleAddSkp = this.handleAddSkp.bind(this);
  }

  handleAddSkp(values) {
    let result = {
      ...values,
      no: this.state.data.length + 1,
    };
    this.setState(({ data }) => ({
      data: [...data, result],
    }));
    this.setState({ hasEdit: true })
  }

  isEqual(oldData, newData) {
    if (oldData.length !== newData.length) {
      return false;
    } else {
      for (var i = 0; i < oldData.length; i++)
        if (JSON.stringify(oldData[i]) !== JSON.stringify(newData[i])) {
          return false;
        }
      return true;
    }
  }
  handleShowModalEdit(data) {
    this.props.setSelectedSKP(data);
    this.setState({ showModalEdit: true });
  }
  handleShowModalDetail(data) {
    this.props.setSelectedSKP(data);
    this.setState({ showModalDetail: true });
  }

  handleEditSkp(values) {
    if (values.hasEdit) {
      this.setState({ hasEdit: true })
    }
    const result = this.state.data.map((item) => {
      if (values.no === item.no) {
        return values;
      }
      return item;
    });
    this.setState({ data: result });
  }
  handleOpenModalDelete(data) {
    this.setState({  ModalDeleteSKPShow : true });
    this.setState({DeletedData: data});
  }

  handleDeleteSkp(data) {
    if (data.id && !this.state.hasEdit) {
      const param = {
        id: data.id
      }
      const callback = () => {
        this.handleRefresh()
      };
      this.props.deleteP2kb(param, callback);
    }
    else {
      const result = this.state.data
        .filter((item) => data.no !== item.no)
        .map((item, i) => ({ ...item, no: i + 1 }));
      this.setState({ data: result });
    }
  }

  handleSubmit() {
    const { data, setData } = this.state;
    const {
      match: {
        params: { activity_id, skp_id },
      },
      detailP2kb,
      editP2kb,
    } = this.props;
    if (this.isEqual(this.state.data, this.state.dataTemp)) {
      toast.error(
        "Tidak ada perubahan data! untuk melakukan submit ulang pastikan ubah data terlebih dahulu",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } else {
      const p2kb_details = data.map(
        ({
          activity_date,
          last_activity: { value: last_activity_skp_detail_id },
          values_id: { value: values_id },
          institution_name,
          letter_number,
          berkas,
        }) => ({
          activity_date,
          last_activity_skp_detail_id,
          values_id,
          institution_name,
          letter_number,
          attachments: [
            {
              label: "Dokumen Bukti",
              filename: berkas,
            },
          ],
        })
      );

      const param = {
        id: skp_id,
        activity_id: activity_id,
        p2kb_details,
      };

      const callback = () => {
        this.props.history.push(`/skp`);
      };
      editP2kb(param, callback);
    }
  }

  handleOpenDocument(filename, modalTitle) {
    this.setState({
      filename: filename,
      modalTitle: modalTitle,
      showDocument: true,
    });
  }
  handleShowReject(data) {
    this.setState({
      reason: data.last_reject_notes,
      imgUrlRejectSKP: data.last_reject_filename,
      showModalReject: true,
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { activity_id },
      },
      detailActivity,
    } = this.props;
    detailActivity(activity_id);
    this.handleRefresh();
  }

  handleRefresh() {
    const {
      match: {
        params: { activity_id, skp_id },
      },
    } = this.props;
    const { setData } = this.state;
    const params = {
      activity_id: activity_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }
    this.props.detailP2kb(params, setData);
  }
  render() {
    const {
      detailP2kb_data,
      detailP2kb_pending,
      p2kbEdit_pending,
    } = this.props;
    const { showDocument, filename, modalTitle } = this.state;
    let { data } = this.state;
    let activity_name,
      activity_type,
      realm_activity,
      role_next_approve,
      skp_status,
      last_skp_admin,
      confirmation_date,
      last_rejected_date,
      is_reject = null,
      submit_status;
    if (detailP2kb_data && detailP2kb_data.length !== 0) {
      ({
        activity_data: {
          name: activity_name,
          activity_type,
          realm_activity: { name: realm_activity },
        },
        status: skp_status,
        role_next_approve,
        last_rejected_date,
        submit_status,
      } = detailP2kb_data[0]);

      is_reject = skp_status === 2 ? true : false;
      last_skp_admin = role_next_approve
        ? role_next_approve.role_name
        : "CPD/CME";
      if (is_reject === true) {
        confirmation_date =
          "Ditolak pada " + moment(last_rejected_date).format("DD/MM/YYYY");
      } else {
        confirmation_date =
          "Diajukan pada " + moment(data.updated_at).format("DD/MM/YYYY");
      }
    }

    let skp_total = 0;
    if (this.state.data.length !== 0) {
      skp_total = this.state.data
        .map(({ skp_total }) => skp_total)
        .reduce(function (a, b) {
          return a + b;
        });
    }
    return (
      <ContentContainer>
        <MembimbingMahasiswaModalEdit
          show={this.state.showModalEdit}
          onHide={() => this.setState({ showModalEdit: false })}
          handleEditSkp={this.handleEditSkp}
          handleRefresh={this.handleRefresh}
        />
        <MembimbingMahasiswaModalAdd
          show={this.state.showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleAddSkp={this.handleAddSkp}
        />
        <MembimbingMahasiswaModalDetail
          show={this.state.showModalDetail}
          onHide={() => this.setState({ showModalDetail: false })}
        />
        <ModalDocument
          show={showDocument}
          onHide={() => this.setState({ showDocument: false })}
          filepath="member_p2kb"
          filename={filename}
          modalTitle={modalTitle}
        />

        <HeaderDetailP2kbComponent
          activity_name={activity_name}
          activity_type={activity_type}
          realm_activity={realm_activity}
          skp={skp_total}
          skp_status={skp_status}
          last_skp_admin={last_skp_admin}
          icon={IconError}
          isReject={is_reject}
          confirmation_date={confirmation_date}
          isAdmin={false}
          isLoad={detailP2kb_pending}
          approval={
            data.role_next_approve
              ? data.role_next_approve.role_code.includes("ketua") ||
                data.role_next_approve.role_code.includes("cpd")
                ? "Persetujuan"
                : "Verifikasi"
              : "Persetujuan"
          }
          submit_status={submit_status}
        />
        <ModalDeleteSKP
          show={this.state.ModalDeleteSKPShow}
          onHide={() => this.setState({ ModalDeleteSKPShow: false})}
          deleteSKP={() => {
            if(this.state.DeletedData!=null){
              this.handleDeleteSkp(this.state.DeletedData)
            }                   
          }}
        />

        <ModalRejectSKP
          show={this.state.showModalReject}
          onHide={() => this.setState({ showModalReject: false })}
          reason={this.state.reason}
          imgUrl={this.state.imgUrlRejectSKP}
        />
        {submit_status === 0 && (
          <div className={css(styles.submitButtonContainer)}>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              onClick={() => this.setState({ showModalAdd: true })}
              startIcon={<AddIcon />}
            >
              Tambah
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              onClick={this.handleSubmit}
              // startIcon={<CheckIcon />}
              disabled={p2kbEdit_pending && !this.state.hasEdit}
              startIcon={
                p2kbEdit_pending ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              simpan
            </Button>
          </div>
        )}
        <MaterialTable
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Tanggal Kegiatan",
              render: ({ activity_date }) =>
                moment(activity_date).format("DD MMMM YYYY"),
            },

            { title: "Nama Institusi", field: "institution_name" },
            { title: "Mahasiswa Bimbingan", field: "last_activity.label" },
            { title: "Pembimbing", field: "values_id.label" },
            { title: "Jumlah SKP", field: "skp_total" },
            {
              title: "Dokumen",
              render: (data) => {
                return (
                  <DetailButtonComponent
                    button={(props) => (
                      <Row
                        onClick={props.onClick}
                        className={css(styles.buttonDocHover)}
                      >
                        <IconDocumentP2KB fill="Black" />
                      </Row>
                    )}
                  >
                    <MenuItem
                      onClick={() =>
                        this.handleOpenDocument(data.berkas, "Berkas Bukti")
                      }
                    >
                      <IconLaunch />
                      <span style={{ marginLeft: 10, paddingTop: 2 }}>
                        Berkas Bukti
                      </span>
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === undefined
                        ? "Draft"
                        : status === 0 && submit_status === 0
                          ? "Draft"
                          : status === 0 && submit_status === 1
                            ? "Menunggu"
                            : status === 1
                              ? "Approved"
                              : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              render: (data) => {
                return submit_status === 1 ? (
                  data.status === 2 ? (
                    <DetailButtonComponent>
                      <MenuItem onClick={() => this.handleShowModalEdit(data)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => this.handleOpenModalDelete(data)}>
                        Hapus
                      </MenuItem>
                      {/* <MenuItem onClick={() => this.handleShowReject(data)}>
                        Alasan Revisi
                      </MenuItem> */}
                    </DetailButtonComponent>
                  ) : (
                    <DetailButtonComponent>
                      <MenuItem
                        onClick={() => this.handleShowModalDetail(data)}
                      >
                        Detail
                      </MenuItem>
                    </DetailButtonComponent>
                  )
                ) : (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.handleShowModalEdit(data)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => this.handleShowModalDetail(data)}>
                      Detail
                    </MenuItem>
                    <MenuItem onClick={() => this.handleDeleteSkp(data)}>
                      Hapus
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          data={data}
          isLoading={detailP2kb_pending}
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={10}
                records_total={data.length}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  buttonDocHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
  submitButtonContainer: {
    padding: 20,
    textAlign: "left",
  },
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
    marginRight: 10,
  },
  rejectButton: {
    background: "#EAEBED",
    marginLeft: 10,
    marginRight: 20,
    textAlign: "center",
    color: "#495057",
    boxShadow: "none",
  },
});

function mapStateToProps({ p2kbList, editP2kb, deleteP2kb, detailP2kb }) {
  return {
    p2kbList_pending: getP2kbListPending(p2kbList),
    p2kbList_data: getP2kbList(p2kbList),
    detailP2kb_data: getDetailP2kb(detailP2kb),
    detailP2kb_pending: getP2kbPending(detailP2kb),
    p2kbList_error: getP2kbListError(p2kbList),
    p2kbEdit_pending: getP2kbEditPending(editP2kb),
    p2kbEdit_data: getP2kbEdit(editP2kb),
    p2kbEdit_error: getP2kbEditError(editP2kb),
    p2kbDelete_pending: getP2kbDeletePending(deleteP2kb),
    p2kbDelete_data: getP2kbDelete(deleteP2kb),
    p2kbDelete_error: getP2kbDeleteError(deleteP2kb),
  };
}

export default connect(mapStateToProps, {
  p2kbList,
  editP2kb,
  detailP2kb,
  deleteP2kb,
  setSelectedSKP,
  detailActivity,
})(withRouter(MembimbingMahasiswaDetail));
