import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";

export const PROVINCE_LIST_PENDING = "UNIVERSITAS_LIST_PENDING";
export const PROVINCE_LIST_SUCCESS = "UNIVERSITAS_LIST_SUCCESS";
export const PROVINCE_LIST_ERROR = "UNIVERSITAS_LIST_ERROR";

const PROVINCE_LIST_URL = "master_data/data_reference/find_province";

export function provinceList(param = {}) {
  return (dispatch) => {
    dispatch(actionPending(PROVINCE_LIST_PENDING));
    API.post(PROVINCE_LIST_URL, param)
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(PROVINCE_LIST_SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(PROVINCE_LIST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}
