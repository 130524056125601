import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addAppreciation } from "../../actions/member/appreciation_action";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import { getAppreciationPending } from "../../reducers/member/appreciation_reducer";

class ModalAppreciationAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addAppreciation, onHide, handleRefresh } = this.props;
    const param = {
      file: values.berkas,
      institution: values.institution,
      appreciation_type: values.appreciation_type,
      year: values.year.value,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addAppreciation(param, callback);
  }

  render() {
    const { handleSubmit, show, onHide, addAppreciation_pending } = this.props;

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Penghargaan</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <Field
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
                <Field
                  name="appreciation_type"
                  placeholder="Masukkan jenis penghargaan"
                  component={renderInput}
                />
                <Field
                  name="year"
                  placeholder="Pilih tahun"
                  component={renderSelect}
                  options={yearOptions}
                />
                <Field
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addAppreciation_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addAppreciation_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { appreciation_type, institution, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!appreciation_type) {
    errors.appreciation_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tahun penghargaan wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  return errors;
}

ModalAppreciationAdd = reduxForm({
  // a unique name for the form
  form: "AppreciationModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalAppreciationAdd);

function mapStateToProps({ addAppreciation }) {
  return {
    addAppreciation_pending: getAppreciationPending(addAppreciation),
  };
}

export default connect(mapStateToProps, {
  addAppreciation,
})(withRouter(ModalAppreciationAdd));
