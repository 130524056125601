import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import { getUser } from "../../helpers/user";
import ModalAddMessageBlast from "./Modal_add_MessageBlast";
import { history } from "../../helpers/history";



const styles = StyleSheet.create({
    container: {
        width: 200,
        marginLeft: 10,
    },
});
class HeaderMessageBlast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            modalAddMessageBlastShow: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }
    componentDidMount() { }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search: value });
    }, 500);

    handleRefresh() {
        this.delayedQuery("");
    }

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
        this.delayedQuery(e.target.value);
    }

    addMessage() {
        history.push(`/message-blast/add/`);
    }

    render() {
        const user = getUser().role_codes[0];
        return (
            <Row
                style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="space-between"
            >
                <ModalAddMessageBlast
                    show={this.state.modalAddMessageBlastShow}
                    onHide={() => this.setState({ modalAddMessageBlastShow: false })}
                    handleRefresh={this.handleRefresh}
                    memberOptions={this.props.memberOptions}
                    memberData={this.props.memberData}
                />
                {user === "root" &&
                    <Button
                        variant="contained"
                        color="primary"
                        className={css(styles.approveButton)}
                        startIcon={<AddIcon />}
                        onClick={() => this.addMessage()}
                    >
                        Kirim Message Blast
                    </Button>
                }
                <div className={css(styles.container)}>
                    <InputComponent
                        onChange={this.handleSearchChange}
                        value={this.state.tes}
                        placeholder="Cari Message Blast"
                        startIcon={SearchIcon}
                    />
                </div>
            </Row>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(HeaderMessageBlast);