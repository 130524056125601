import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addMutation } from "../../actions/mutation/mutation_action";
import {
  getListHospital,
  getHospitalPending,
} from "../../reducers/master-data/hospital_reducer";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import {
  renderInputWithLabel,
  renderSelectWithLabel,
  renderFile,
} from "../../components/form/FormComponent";
import { getMutationPending } from "../../reducers/mutation/mutation_reducer";

class ModalMutationAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addMutation, onHide, handleRefresh } = this.props;

    const {
      sip_no,
      berkas,
      hospital_id_to: { value: hospital_id_to },
      branch_to: { value: branch_to },
    } = values;
    const param = {
      sip_no,
      file: berkas,
      hospital_id_to,
      branch_to,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };
    addMutation(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addMutation_pending,
      listHospital_data,
      listBranch_data,
    } = this.props;
    let hospitalOptions = [],
      branchOptions = [];

    if (listHospital_data && listHospital_data.length !== 0) {
      hospitalOptions = listHospital_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Pengajuan Mutasi Anggota
          </p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <Field
                name="hospital_id_to"
                placeholder="Pilih tempat praktek tujuan"
                label="Tempat Praktek Tujuan"
                options={hospitalOptions}
                component={renderSelectWithLabel}
              />
              <Field
                name="branch_to"
                placeholder="Pilih cabang tujuan"
                label="Cabang Tujuan"
                options={branchOptions}
                component={renderSelectWithLabel}
              />
              <Field
                name="sip_no"
                placeholder="No SIP"
                label="No SIP"
                component={renderInputWithLabel}
              />

              <Field
                name="berkas"
                type="file"
                fileType="pdf" //image or pdf
                title="upload berkas"
                message="PNG atau JPG maksimal 500 kb"
                component={renderFile}
              />
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addMutation_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addMutation_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const { sip_no, berkas, hospital_id_to, branch_to } = values; 
  const errors = {};
  if (!hospital_id_to) {
    errors.hospital_id_to = "Tempat Praktek tujuan wajib diisi";
  }
  if (!branch_to) {
    errors.branch_to = "Cabang tujuan wajib diisi";
  }
  if (!sip_no) {
    errors.sip_no = "Nomor SIP wajib diisi";
  }
  if (!berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  return errors;
}

ModalMutationAdd = reduxForm({
  // a unique name for the form
  form: "MutationModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalMutationAdd);

function mapStateToProps({ addMutation, listHospital, listBranch }) {
  return {
    addMutation_pending: getMutationPending(addMutation),
    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
  };
}

export default connect(mapStateToProps, {
  addMutation,
})(withRouter(ModalMutationAdd));
