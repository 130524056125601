import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CanvasJSReact from "../../assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import { listPPDSGrafik } from "../../actions/dashboard/ppds_action";
import {
  getListPPDSGrafik,
  getListPPDSPending,
} from "../../reducers/ppds/ppds_reducer";
import BarChart from "../../components/chart/BarChart";
import SelectComponent from "../../components/form/SelectComponent";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CardPPDS extends Component {
  constructor() {
    super();
    this.state = {
      branch_id: null,
      duration: 2,
      start_year: new Date().getFullYear(),
      end_year: new Date().getFullYear(),

    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleStartYearSelectChange = this.handleStartYearSelectChange.bind(this);
    this.handleEndYearSelectChange = this.handleEndYearSelectChange.bind(this);

  }
  componentDidMount() {
    this.props.listPPDSGrafik({ year: this.state.year });
  }

  handleSelectChange(e) {
    this.setState({ duration: 0.1 });
    if (e) {
      this.setState({ branch_id: e.value });
      this.props.listPPDSGrafik({ year: e.value });
    } else {
      this.props.listPPDSGrafik();
    }
  }

  handleStartYearSelectChange(e) {
    if (e) {
      this.setState({ start_year: e.value });
      this.props.listPPDSGrafik({
        start_year: e.value,
        end_year: this.state.end_year
      });
    } else {
      this.setState({ start_year: '' });
      this.props.listPPDSGrafik();
    }
  }

  handleEndYearSelectChange(e) {
    if (e) {
      this.setState({ end_year: e.value });
      this.props.listPPDSGrafik({
        start_year: this.state.start_year,
        end_year: e.value,
      });
    } else {
      this.setState({ end_year: '' });
      this.props.listPPDSGrafik();
    }
  }

  componentDidUpdate() {
    const {
      listPPDS_data,
      listPPDS_pending,
    } = this.props;
  }

  render() {
    const {
      listPPDS_data,
      listPPDS_pending,
    } = this.props;
    let data = [],
      branchOptions = [],
      result = null;
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }

    if (listPPDS_data) {
      var nameArray = []
      var totalArray = []
      listPPDS_data.map(({ count, name }) => {
        totalArray.push(count)
        nameArray.push(name)
      });

      const labelsB = nameArray;
      const datasetsB = [
        {
          label: "Total",
          data: totalArray,
          fill: true,
          borderColor: '#59B752',
          backgroundColor: '#59B752',
        },
      ];
      const optionsB = {
        yTickDisplay: true,
        xGridDisplay: false,
      };

      const options = {
        animationEnabled: true,
        height: 300,
        axisX: {
          valueFormatString: "YYYY",

        },
        axisY: {
          includeZero: true,
          gridThickness: 0,
        },
        data: [
          {
            xValueFormatString: "YYYY",
            type: "splineArea",
            lineColor: "#FFC156",
            color: '#FCEBCD',
            lineThickness: 8,
            markerColor: 'white',
            markerBorderColor: '#FFC156',
            markerBorderThickness: 4,
            markerSize: 10,
            dataPoints: data
          },
        ]
      }

      result = <BarChart labels={labelsB} datasets={datasetsB} options={optionsB} />

    }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Column
          style={{
            boxShadow: "0px 3px 6px #00000029",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "100%",
            minHeight: 300,
            width: "100%",
            backgroundColor: "white",
          }}
        >

          {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          <Row
            className={css(styles.wrapperTop, styles.borderBottom)}
            style={{ width: "100%", padding: 20, backgroundColor: "white" }}
            vertical="center"
            horizontal="space-between"
          >
            <label className={css(styles.titleLabel)}>
              {" "}
              Grafik Penerimaan Universitas
            </label>
            <Row>
              <div style={{ width: 200, margin: "0 10px" }}>
                <SelectComponent
                  options={yearOptions}
                  isRounded
                  onChange={this.handleStartYearSelectChange}
                  placeholder="Pilih Tahun Awal"
                />
              </div>
              <div style={{ width: 200, margin: "0 10px" }}>
                <SelectComponent
                  options={yearOptions}
                  isRounded
                  onChange={this.handleEndYearSelectChange}
                  placeholder="Pilih Tahun Akhir"
                />
              </div>
            </Row>
          </Row>
          <Column
            className={css(styles.wrapper)}
            style={{
              width: "100%",
              padding: "20px 30px",
              backgroundColor: "red",
            }}
          >
            {result}
          </Column>
        </Column>
      </div>
    );
  }
}

function mapStateToProps({ listPPDSGrafik }) {
  return {
    listPPDS_data: getListPPDSGrafik(listPPDSGrafik),
    listPPDS_pending: getListPPDSPending(listPPDSGrafik),
  };
}

export default connect(mapStateToProps, {
  listPPDSGrafik
})(CardPPDS);

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    // padding: 15,
    // height: 400,
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#5AB853",
    height: 10,
    width: "100%",
  },
});
