import React, { Component } from "react";
import { connect } from "react-redux";
import { listSerkom } from "../../actions/serkom/serkom_action";
import {
  getListSerkom,
  getSerkomError,
  getSerkomPending,
} from "../../reducers/serkom/serkom_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import SerkomHeader from "./SerkomHeader";
import SerkomModalAdd from "./SerkomModalAdd";
import SerkomModalDelete from "./SerkomModalDelete";
import moment from "moment";
import localization from "moment/locale/id";
import { getUser } from "../../helpers/user";
class Serkom extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
    localStorage.removeItem('user_id_str')
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(data) {
    console.log(data)
    if (getUser().role_codes[0] === "member") {
      this.props.history.push(`/serkom/detail/${data}`);
    } else {
      localStorage.setItem('user_id_str', data.user_data.id)
      this.props.history.push(`/member/serkom/detail/${data.id}`);
    }
  }

  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id })
    }
  }

  render() {
    const { listSerkom, listSerkom_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listSerkom_data) {
      data = listSerkom_data.data;
      records_total = listSerkom_data.records_total;
    }
    return (
      <ContentContainer>
        <SerkomHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <SerkomModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <SerkomModalDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={getUser().role_codes[0] === "member" ?
            [{
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nomor SK",
              field: "sk_no",
            },
            {
              title: "Tanggal penetapan",
              render: ({ start_date }) => {
                return moment(start_date).locale("id", localization).format("DD MMMM YYYY");
              }
            },
            {
              title: "Tanggal berakhir",
              render: ({ end_date }) => {
                return moment(end_date).locale("id", localization).format("DD MMMM YYYY");
              }
            },
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(id)}>
                      Detail
                    </MenuItem>
                    {/* <MenuItem onClick={() => this.handleOpenModalDelete(id)}>
                      Delete
                    </MenuItem> */}
                  </DetailButtonComponent>
                );
              },
            },
            ]
            :
            [{
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },

            {
              title: "Nama Member",
              field: "user_data.name",
            },
            {
              title: "Nomor SK",
              field: "sk_no",
            },
            {
              title: "Tanggal penetapan",
              render: ({ start_date }) => {
                return moment(start_date).locale("id", localization).format("DD MMMM YYYY");
              }
            },
            {
              title: "Tanggal berakhir",
              render: ({ end_date }) => {
                return moment(end_date).locale("id", localization).format("DD MMMM YYYY");
              }
            },
            {
              title: "",
              render: (data) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(data)}>
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
            ]
          }
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                id: this.state.id
              };
              listSerkom(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSerkom }) {
  return {
    listSerkom_data: getListSerkom(listSerkom),
    listSerkom_error: getSerkomError(listSerkom),
    listSerkom_pending: getSerkomPending(listSerkom),
  };
}

export default connect(mapStateToProps, {
  listSerkom,
})(Serkom);
