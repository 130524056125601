import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import ClearIcon from "@material-ui/icons/Clear";

import {
  approveSupport,
  detailSupport,
} from "../../actions/support/support_action";

import {
  getSupportPending,
  getDetailSupport,
} from "../../reducers/support/support_reducer";
import { reduxForm, Field } from "redux-form";
import {
  renderSelectWithLabel
} from "../../components/form/FormComponent";
import { getUser } from "../../helpers/user";

class SupportModalProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_date: new Date(),
      graduated_date: new Date(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit({ level }) { 
    let value;

    if (level) {
      value = level.value;
    } else {
      value = this.props.detailSupport_data[0].level;
    }
    const { approveSupport, detailSupport, onHide } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const callback = () => {
      onHide();
      detailSupport(id);
    };
    let param = { id: id, level: value, type: "process" };
    approveSupport(param, callback);
  }

  render() {
    const role_code = getUser().role_codes[0];
    const { show, onHide, approveSupport_pending, handleSubmit } = this.props;
    const levelOption = [
      {
        label: "Ringan",
        value: 1,
        color: "yellow",
      },
      {
        label: "Sedang",
        value: 2,
        color: "orange",
      },
      {
        label: "Berat",
        value: 3,
        color: "red",
      },
    ];
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column className={css(styles.bodyModal)}>
              {/* <p style={{ marginTop: 20 }}>
                Silahkan konfirmasi status penganan terlebih dahulu
              </p> */}
              {/* <span className={css(styles.labelMargin)}>Status Penanganan</span> */}
              <>
                  <Field
                    name="level"
                    placeholder="Pilih status penganan"
                    label="Status Penanganan"
                    component={renderSelectWithLabel}
                    options={levelOption}
                  />
                {/*{role_code !== "sekretaris_depoi" ? (
                  <Field
                    name="level"
                    placeholder="Pilih status penganan"
                    label="Status Penanganan"
                    component={renderSelectWithLabel}
                    options={levelOption}
                  />
                ) : (
                  <p>Apakah Anda yakin ingin memproses laporan ini?</p>
                )} */}
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                variant="contained"
                color="primary"
                className={css(styles.rejectButton)}
                startIcon={<ClearIcon />}
                onClick={this.props.onHide}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={approveSupport_pending}
                className={css(styles.approveButton)}
                startIcon={
                  approveSupport_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <CheckIcon />
                  )
                }
              >
                {role_code !== "sekretaris_depoi" ? "submit" : "ya"}
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

function validate(values) {
  const role_code = getUser().role_codes[0];

  const { level } = values;
  const errors = {};

  if (!level && role_code !== "sekretaris_depoi") {
    errors.level = "Status Penanganan wajib diisi";
  }
  // if (!reject_file) {
  //   errors.reject_file = "berkas wajib diisi";
  // }

  return errors;
}

SupportModalProcess = reduxForm({
  // a unique name for the form
  form: "SupportModalProcess",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SupportModalProcess);

function mapStateToProps({ approveSupport, detailSupport }) {
  return {
    approveSupport_pending: getSupportPending(approveSupport),
    detailSupport_data: getDetailSupport(detailSupport),
  };
}

export default connect(mapStateToProps, {
  approveSupport,
  detailSupport,
})(withRouter(SupportModalProcess));
