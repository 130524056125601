import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Placeholder from "../../components/p2kb/skp_placeholder";
import FadeIn from "react-fade-in";

export default (props) => {
    const { total_shu, isLoad, shu } = props;
    return (
        <Column>
            <div className={css(styles.detailHeaderContainer)}>
                <Row>
                    <Column className={css(styles.detailHeaderBenefitColumn)}>
                        <div>
                            <div className={css(styles.detailHeaderTitle)}>
                                Saldo SHU
                            </div>
                            <div className={css(styles.detailHeaderValue)}>
                                {isLoad && total_shu === null && shu === 0 ? (
                                    <Placeholder title="" parentwidth={200} childwidth={200} />
                                ) :
                                    <FadeIn>
                                        <span className={css(styles.totalSHUValue)}>Rp {shu}</span>
                                    </FadeIn>
                                }
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
        </Column>
    )
}

const styles = StyleSheet.create({
    detailHeaderContainer: {
        backgroundColor: "#EFF0F2"
    },
    detailHeaderBenefitColumn: {
        margin: "20px 20px 20px 30px",
        width: "15%"
    },
    detailHeaderTitle: {
        color: "#495057",
        opacity: 0.7,
        fontSize: 14,
        marginBottom: 20
    },
    detailHeaderValue: {
        color: "Black",
        fontSize: 16
    },
    totalSHUValue: {
        color: "#43C57C"
    }
})