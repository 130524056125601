import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import SelectComponent from "../../components/form/SelectComponent";
import {
  getListBranch,
  getBranchPending,
} from "../../reducers/master-data/branch_reducer";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Placeholder from "../../components/p2kb/skp_placeholder";
import FadeIn from "react-fade-in";
import IconRefresh from "../../assets/icon-refresh";
import moment from "moment";
import localization from "moment/locale/id";
import { listReport } from "../../actions/report/report_action";
import {
  getListReport,
  getReportError,
  getReportPending,
} from "../../reducers/report/report_reducer";
import { getUser } from "../../helpers/user";


const styles = StyleSheet.create({
  container: {
    width: 200,
    marginLeft: 10,
  },
  refreshButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  syncDiv: {
    paddingTop: 5
  },
  syncLabel: {
    paddingTop: 7
  },
});
class HeaderApreciation extends Component {
  constructor() {
    super();
    this.state = {
      report: "",
      year: new Date(),
      isLoad: false
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delayedQuery = this.delayedQuery.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCabangChange = this.handleCabangChange.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleSync = this.handleSync.bind(this);
  }
  componentDidMount() { }

  delayedQuery = _.debounce((value) => {
    const { handleRefresh } = this.props;

    return handleRefresh({ search_text: value });
  }, 500);

  handleSearchChange(e) {
    this.setState({ report: e.target.value });
    this.delayedQuery(e.target.value);
  }

  handleDateChange(e) {
    if (e) {
      this.setState({ year: e.value })
      this.props.handleRefresh({ year: e.value });
    } else {
      this.setState({ year: null })
      this.props.handleRefresh({ year: null });
    }
  }

  handleCabangChange(e) {
    if (e) {
      this.props.handleRefresh({ branch_id: e.value });
    } else {
      this.props.handleRefresh({ branch_id: null });
    }
  }

  handleSync() {
    this.setState({ isLoad: true });
    const callback = () => {
      this.setState({ isLoad: false });
      this.delayedQuery();
    }
    this.props.listReport({ length: 10 }, callback);
  }

  handleImport() {
    toast.error("Fitur ini belum tersedia", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    const { listBranch_data } = this.props;
    let branchOptions = [];
    if (listBranch_data && listBranch_data.length !== 0) {
      branchOptions = listBranch_data.data.map(({ name, id }) => {
        return { label: name, value: id };
      });
    }
    let yearOption = [];
    const currentYear = new Date().getFullYear();
    const lastTwentyYear = new Date().getFullYear() - 20;
    for (let i = currentYear; i >= 1900; i--) {
      yearOption = [...yearOption, { label: i, value: i }];
    }
    return (
      <Row
        style={{
          padding: 24,
          paddingBottom: 30,
          backgroundColor: "#F8F9FB",
          zIndex: 20,
        }}
        horizontal="space-between"
      >
        <Row>
          <Button
            variant="contained"
            color="primary"
            className={css(styles.refreshButton)}
            startIcon={<IconRefresh />}
            onClick={this.handleSync}
          >
            Refresh
          </Button>
          <div className={css(styles.syncDiv)}>
            {this.state.isLoad ? (
              <Placeholder childwidth={200} parentwidth={200} title="" />
            ) :
              <FadeIn>
                <label className={css(styles.syncLabel)}>
                  {moment(new Date())
                    .locale("id", localization)
                    .format("dddd, DD MMMM YYYY HH:mm")}
                </label>
              </FadeIn>
            }
          </div>
        </Row>

        <Row>
          {/* <div className={css(styles.container)}>
            <SelectComponent
              placeholder="Pilih Cabang"
              options={branchOptions}
              onChange={this.handleCabangChange}
            />
          </div> */}

          {getUser().role_codes[0] !== "ketua_paboi_cabang" && getUser().role_codes[0] !== "admin_paboi_cabang" &&
            <div className={css(styles.container)}>
              <SelectComponent
                placeholder="Pilih Cabang"
                options={branchOptions}
                onChange={this.handleCabangChange}
              />
            </div>
          }
          {getUser().role_codes[0] === "ketua_paboi_cabang" || getUser().role_codes[0] === "admin_paboi_cabang" ?
            <div className={css(styles.container)}>
              <InputComponent
                onChange={this.handleSearchChange}
                value={this.state.report}
                placeholder="Masukan kata kunci"
                startIcon={SearchIcon}
              />
            </div>
            : ""
          }
          <div className={css(styles.container)}>
            <SelectComponent
              placeholder="Pilih Tahun"
              options={yearOption}
              onChange={this.handleDateChange}
            />
          </div>
        </Row>
      </Row>
    );
  }
}

function mapStateToProps({ listBranch, listReport }) {
  return {
    listBranch_data: getListBranch(listBranch),
    listBranch_pending: getBranchPending(listBranch),
    listReport_data: getListReport(listReport),
    listReport_error: getReportError(listReport),
    listReport_pending: getReportPending(listReport),
  };
}

export default connect(mapStateToProps, { listReport })(withRouter(HeaderApreciation));
