import React, { Component } from "react";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import {
  detailAppreciation,
  editAppreciation,
  setIsEditFile,
} from "../../actions/member/appreciation_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getAppreciationPending,
  getDetailAppreciation,
  getAppreciationError,
  getAppreciationIsEditFile,
} from "../../reducers/member/appreciation_reducer";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import { getUser } from "../../helpers/user";

// import RenderFile from "../../components/form/FormComponent2";

class AppreciationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false, isEditFile: false };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailAppreciation } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailAppreciation(id);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editAppreciation_isEditFile, setIsEditFile } = this.props;
    if (editAppreciation_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editAppreciation_isEditFile);
  }

  handleSubmit(values) {
    const {
      detailAppreciation_data,
      editAppreciation,
      detailAppreciation,
    } = this.props;

    const { berkas, institution, appreciation_type, year } = values;
    const param = {
      id: detailAppreciation_data[0].id,
      filename: detailAppreciation_data[0].filename,
      institution,
      appreciation_type,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailAppreciation();
      this.handleTriggerFormEdit();
    };
    editAppreciation(param, callback);
  }

  render() {
    const {
      detailAppreciation_data,
      editAppreciation_pending,
      editAppreciation_isEditFile,
      handleSubmit,
    } = this.props;
    const { isEdit } = this.state;
    let name = null;
    let filename = null;

    if (detailAppreciation_data && detailAppreciation_data.length !== 0) {
      name = detailAppreciation_data[0].member_data.name;
      filename = detailAppreciation_data[0].filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Penghargaan" />
                {getUser().role_codes[0] !== "admin_paboi_cabang" &&
                <EditButtonComponent
                  isEdit={isEdit}
                  triggerIsEdit={this.handleTriggerFormEdit}
                  isLoading={editAppreciation_pending}
                />
              }
              </Row>
              <FormContainer label="Nama Institusi">
                <Field
                  isDetail={!isEdit}
                  name="institution"
                  placeholder="Masukkan nama institusi"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Jenis Penghargaan">
                <Field
                  isDetail={!isEdit}
                  name="appreciation_type"
                  placeholder="Masukkan jenis penghargaan"
                  component={renderInput}
                />
              </FormContainer>
              <FormContainer label="Tahun">
                <Field
                  isDetail={!isEdit}
                  options={yearOptions}
                  name="year"
                  placeholder="Masukkan tahun"
                  component={renderSelect}
                />
              </FormContainer>
              <FormContainer label="Berkas">
                <Field
                  triggerEditFile={this.handleTriggerEditFile}
                  isEditFile={editAppreciation_isEditFile}
                  isDetail={!isEdit}
                  filePath="member_appreciation"
                  filename={filename}
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </FormContainer>
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

function validate(values, props) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { editAppreciation_isEditFile } = props;
  const { institution, appreciation_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!appreciation_type) {
    errors.appreciation_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editAppreciation_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }
  else{
    if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
    }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 500KB"
    }
  }


  return errors;
}

AppreciationDetail = reduxForm({
  // a unique name for the form
  form: "AppreciationModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(AppreciationDetail);

function mapStateToProps(state) {
  const { detailAppreciation, editAppreciation } = state;
  let initialValues = {};
  const detailAppreciation_data = getDetailAppreciation(detailAppreciation);
  if (detailAppreciation_data && detailAppreciation_data.length !== 0) {
    initialValues = {
      institution: detailAppreciation_data[0].institution,
      appreciation_type: detailAppreciation_data[0].appreciation_type,
      year: {
        label: `${detailAppreciation_data[0].year}`,
        value: detailAppreciation_data[0].year,
      },
    };
  }
  return {
    detailAppreciation_data: getDetailAppreciation(detailAppreciation),
    detailAppreciation_error: getAppreciationError(detailAppreciation),
    detailAppreciation_pending: getAppreciationPending(detailAppreciation),
    editAppreciation_error: getAppreciationError(editAppreciation),
    editAppreciation_pending: getAppreciationPending(editAppreciation),
    editAppreciation_isEditFile: getAppreciationIsEditFile(editAppreciation),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailAppreciation,
  editAppreciation,
  setIsEditFile,
})(withRouter(AppreciationDetail));
