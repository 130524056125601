import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_P2KB_PENDING = "LIST_P2KB_PENDING";
export const LIST_P2KB_SUCCESS = "LIST_P2KB_SUCCESS";
export const LIST_P2KB_ERROR = "LIST_P2KB_ERROR";
export const EDIT_P2KB_PENDING = "EDIT_P2KB_PENDING";
export const EDIT_P2KB_SUCCESS = "EDIT_P2KB_SUCCESS";
export const EDIT_P2KB_ERROR = "EDIT_P2KB_ERROR";
export const DELETE_P2KB_PENDING = "DELETE_P2KB_PENDING";
export const DELETE_P2KB_SUCCESS = "DELETE_P2KB_SUCCESS";
export const DELETE_P2KB_ERROR = "DELETE_P2KB_ERROR";

export const ADD_P2KB_PENDING = "ADD_P2KB_PENDING";
export const ADD_P2KB_SUCCESS = "ADD_P2KB_SUCCESS";
export const ADD_P2KB_ERROR = "ADD_P2KB_ERROR";
export const APPROVAL_P2KB_PENDING = "APPROVAL_P2KB_PENDING";
export const APPROVAL_P2KB_SUCCESS = "APPROVAL_P2KB_SUCCESS";
export const APPROVAL_P2KB_ERROR = "APPROVAL_P2KB_ERROR";
export const DETAIL_P2KB_PENDING = "APPROVAL_P2KB_PENDING";
export const DETAIL_P2KB_SUCCESS = "APPROVAL_P2KB_SUCCESS";
export const DETAIL_P2KB_ERROR = "APPROVAL_P2KB_ERROR";
export const UPLOAD_P2KB_PENDING = "UPLOAD_P2KB_PENDING";
export const UPLOAD_P2KB_SUCCESS = "UPLOAD_P2KB_SUCCESS";
export const UPLOAD_P2KB_ERROR = "UPLOAD_P2KB_ERROR";
export const SET_IS_EDIT_FILE_P2KB = "SET_IS_EDIT_FILE_P2KB";
export const SET_SELECTED_SKP = "SET_SELECTED_SKP";
export const APPROVAL_ALL_P2KB_PENDING = "APPROVAL_ALL_P2KB_PENDING";
export const APPROVAL_ALL_P2KB_SUCCESS = "APPROVAL_ALL_P2KB_SUCCESS";
export const APPROVAL_ALL_P2KB_ERROR = "APPROVAL_ALL_P2KB_ERROR";
export const SUBMIT_ALL_P2KB_PENDING = "SUBMIT_ALL_P2KB_PENDING";
export const SUBMIT_ALL_P2KB_SUCCESS = "SUBMIT_ALL_P2KB_SUCCESS";
export const SUBMIT_ALL_P2KB_ERROR = "SUBMIT_ALL_P2KB_ERROR";
export const EDIT_DETAIL_P2KB_PENDING = "EDIT_DETAIL_P2KB_PENDING";
export const EDIT_DETAIL_P2KB_SUCCESS = "EDIT_DETAIL_P2KB_SUCCESS";
export const EDIT_DETAIL_P2KB_ERROR = "EDIT_DETAIL_P2KB_ERROR";
export const REJECT_DETAIL_P2KB_PENDING = "REJECT_DETAIL_P2KB_PENDING";
export const REJECT_DETAIL_P2KB_SUCCESS = "REJECT_DETAIL_P2KB_SUCCESS";
export const REJECT_DETAIL_P2KB_ERROR = "REJECT_DETAIL_P2KB_ERROR";
export const LIST_MEMBER_P2KB_PENDING = "LIST_MEMBER_P2KB_PENDING";
export const LIST_MEMBER_P2KB_SUCCESS = "LIST_MEMBER_P2KB_SUCCESS";
export const LIST_MEMBER_P2KB_ERROR = "LIST_MEMBER_P2KB_ERROR";
export const DATE_P2KB_PENDING = "DATE_P2KB_PENDING";
export const DATE_P2KB_SUCCESS = "DATE_P2KB_SUCCESS";
export const DATE_P2KB_ERROR = "DATE_P2KB_ERROR";

const LIST_P2KB_URL = "member/p2kb/find";
const LIST_RANAH_URL = "member/p2kb/find_group";
const LIST_MEMBER_P2KB_URL = "member/p2kb/find_member";
const EDIT_P2KB_URL = "member/p2kb/edit";
const ADD_P2KB_URL = "member/p2kb/add";
const DELETE_P2KB_URL = "member/p2kb/delete";
const APPROVAL_P2KB_URL = "member/p2kb/approve_all";
const UPLOAD_P2KB_URL = "image/upload/member_p2kb";
const APPROVAL_ALL_P2KB_URL = "member/p2kb/approve_all";
const SUBMIT_ALL_P2KB_URL = "member/p2kb/submit";
const EDIT_DETAIL_P2KB_URL = "member/p2kb/edit_detail";
const REJECT_DETAIL_P2KB_URL = "member/p2kb/reject_all";
const FILTER_DATE_P2KB_URL = "member/p2kb/filter_date";

export function p2kbListMember(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    const api = LIST_MEMBER_P2KB_URL;
    dispatch(actionPending(LIST_MEMBER_P2KB_PENDING));
    API.post(api, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_MEMBER_P2KB_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 20,
          ...item,
          role_name:
            item.step === 1
              ? "Admin PABOI Cabang"
              : item.step === 2
              ? "Admin Pusat"
              : "CPD/CME",
        }));
        if (resolve) {
          if (detail) {
            let detailSKP = data[0].p2kb_details.map((item, i) => ({
              ...item,
              no: i + 1 + (param.page - 1) * 10,
            }));
            resolve({
              data: detailSKP,
              page: param.page - 1,
              totalCount: records_total,
            });
          } else {
            resolve({
              data: data,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_MEMBER_P2KB_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function p2kbList(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    const api = param.isFirstLayer ? LIST_RANAH_URL : LIST_P2KB_URL;
    dispatch(actionPending(LIST_P2KB_PENDING));
    API.post(api, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_P2KB_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 10,
          ...item,
          role_name:
            item.step === 1
              ? "Admin PABOI Cabang"
              : item.step === 2
              ? "Admin Pusat"
              : "CPD/CME",
        }));
        if (resolve) {
          if (detail) {
            let detailSKP = data[0].p2kb_details.map((item, i) => ({
              ...item,
              no: i + 1 + (param.page - 1) * 10,
            }));
            resolve({
              data: detailSKP,
              page: param.page - 1,
              totalCount: records_total,
            });
          } else {
            resolve({
              data: data,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_P2KB_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function p2kbListRanah(param = {}, resolve, callback, detail = false) {
  return (dispatch) => {
    dispatch(actionPending(LIST_P2KB_PENDING));
    API.post(LIST_RANAH_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(LIST_P2KB_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param.page - 1) * 10,
          ...item,
          role_name:
            item.step === 1
              ? "Admin PABOI Cabang"
              : item.step === 2
              ? "Admin Pusat"
              : "CPD/CME",
        }));
        if (resolve) {
          if (detail) {
            let detailSKP = data[0].p2kb_details.map((item, i) => ({
              ...item,
              no: i + 1 + (param.page - 1) * 10,
            }));
            resolve({
              data: detailSKP,
              page: param.page - 1,
              totalCount: records_total,
            });
          } else {
            resolve({
              data: data,
              page: param.page - 1,
              totalCount: records_total,
            });
          }
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_P2KB_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function editP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_P2KB_PENDING));
    API.post(EDIT_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_P2KB_ERROR, error));
        toastError(error, "Gagal mengubah p2kb, ");
      });
  };
}

export function deleteP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_P2KB_PENDING));
    API.post(DELETE_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_P2KB_ERROR, error));
        toastError(error, "Gagal menghapus p2kb, ");
      });
  };
}

export function addP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_P2KB_PENDING));
    API.post(ADD_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambah p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_P2KB_ERROR, error));
        toastError(error, "Gagal menambah p2kb, ");
      });
  };
}

export function approvalP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_P2KB_PENDING));
    API.post(APPROVAL_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_P2KB_ERROR, error));
        toastError(error, "Gagal mengubah status p2kb, ");
      });
  };
}

export function uploadP2kb(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_P2KB_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_P2KB_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_P2KB_SUCCESS, res.data.filename));
        // toastSuccess("Berhasil mengupload berkas");
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_P2KB_ERROR, error));
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE_P2KB,
    value: value,
  };
}

export function setSelectedSKP(value) {
  return {
    type: SET_SELECTED_SKP,
    value: value,
  };
}

export function detailP2kb(param = {}, callback) {
  return (dispatch) => {
    //    const api = param.isFindDate && (window.location.pathname === '/skp' && (window.location.pathname.includes('/admin-p2kb/member') || path)) ? FILTER_DATE_P2KB_URL : LIST_P2KB_URL
    dispatch(actionPending(DETAIL_P2KB_PENDING));
    API.post(LIST_P2KB_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_P2KB_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_P2KB_ERROR, error));
        toastError(error, "Gagal mendapatkan detail P2KB, ");
      });
  };
}

export function dateP2kb(param = {}, callback) {
  return (dispatch) => {
    dispatch(actionPending(DATE_P2KB_PENDING));
    API.post(FILTER_DATE_P2KB_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DATE_P2KB_SUCCESS, res.data.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DATE_P2KB_ERROR, error));
        toastError(error, "Gagal mendapatkan detail P2KB, ");
      });
  };
}
export function detailP2kbSerkom(user_id = null, serkom_id = null) {
  const param = {
    user_id,
    serkom_id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_P2KB_PENDING));
    API.post(LIST_P2KB_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_P2KB_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_P2KB_ERROR, error));
        toastError(error, "Gagal mendapatkan detail P2KB, ");
      });
  };
}

export function approvalAllP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVAL_ALL_P2KB_PENDING));
    API.post(APPROVAL_ALL_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(APPROVAL_ALL_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVAL_ALL_P2KB_ERROR, error));
        toastError(error, "Gagal mengubah status p2kb, ");
      });
  };
}

export function submitAllP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(SUBMIT_ALL_P2KB_PENDING));
    API.post(SUBMIT_ALL_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(SUBMIT_ALL_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil mensubmit p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(SUBMIT_ALL_P2KB_ERROR, error));
        toastError(error, "Gagal mensubmit p2kb, ");
      });
  };
}

export function editDetailP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_DETAIL_P2KB_PENDING));
    API.post(EDIT_DETAIL_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_DETAIL_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_DETAIL_P2KB_ERROR, error));
        toastError(error, "Gagal mengubah p2kb, ");
      });
  };
}

export function rejectDetailP2kb(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(REJECT_DETAIL_P2KB_PENDING));
    API.post(REJECT_DETAIL_P2KB_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(REJECT_DETAIL_P2KB_SUCCESS, res.data.data));
        toastSuccess("Berhasil menolak p2kb");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(REJECT_DETAIL_P2KB_ERROR, error));
        toastError(error, "Gagal menolak p2kb, ");
      });
  };
}
