import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import DetailMemberNameComponent from "../../components/detail/DetailMemberNameComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import MenuItem from "@material-ui/core/MenuItem";
import DownloadCVComponent from "../../components/global-components/DownloadCVComponent";
import DetailMemberSubspecialist from "../../components/detail/DetailMemberSubspecialist";
import DetailMemberStatusComponent from "../../components/detail/DetailMemberStatusComponent";
import DetailMenuComponent from "../../components/detail/DetailMenuComponent";
import DetailTitleComponent from "../../components/detail/DetailTitleComponent";
import DetailMemberPengantarComponent from "../../components/detail/DetailContentPengantarComponent";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberListPending,
  getMemberList,
  getMemberListError,
} from "../../reducers/member/member_reducer";
import { connect } from "react-redux";
import IconCreate from "../../assets/icon-create";
import IconDot from "../../assets/icon-dot";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ModalEditStatusMemberAktif from "./Modal_edit_status_member_aktif";
import ModalEditStatusMemberNonaktif from "./Modal_edit_status_member_nonaktif";
import ModalEditStatusMemberPensiun from "./Modal_edit_status_member_pensiun";
import ModalEditStatusMemberAlm from "./Modal_edit_status_member_alm";
import DetailMemberPhoto from "../../components/detail/DetailMemberPhoto";
import IconCV from "../../assets/icon-downloadcv";
import { default_api } from "../../constants/default_api";

class DetailMemberPengantar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalEditStatusMemberAktifShow: false,
      ModalEditStatusMemberNonaktifShow: false,
      ModalEditStatusMemberPensiunShow: false,
      ModalEditStatusMemberAlmShow: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    let param_memberList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    // this.props.memberList(param_memberList);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_memberList.id = id;
    this.props.memberList(param_memberList);
  }

  profile(id) {
    this.props.history.push(`/member/detail/profile/${id}`);
  }

  p2kb(id) {
    this.props.history.push(`/member/detail/p2kb/${id}`);
  }

  unduhCV(id) {
    window.open(
      default_api + "/member/download/cv/" + id + ".pdf"
    );
  }

  componentDidMount() {
    let param_memberList = {
      page: 1,
      length: 1,
      search_text: "",
      id: "",
    };
    this.props.memberList(param_memberList);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    param_memberList.id = id;
    this.props.memberList(param_memberList);
  }

  shouldComponentRender() {
    const { memberList_pending } = this.props;
    if (memberList_pending === false) {
      return false;
    }
    return true;
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    const { memberList_data } = this.props;
    const {
      ModalEditStatusMemberAktifShow,
      ModalEditStatusMemberNonaktifShow,
      ModalEditStatusMemberPensiunShow,
      ModalEditStatusMemberAlmShow,
    } = this.state;
    let data = [];
    let name = "";
    let title = "";
    let status = "";
    let paboi_id = "";
    let introduction = "";
    let id = "";
    let img_profile = "";
    if (memberList_data) {
      data = memberList_data.data[0];
      id = data.id;
      name = data.name;
      title = data.member_profile.title;
      status = data.member_profile.status;
      paboi_id = data.member_profile.paboi_id;
      img_profile = data.img_profile;
      introduction = data.member_profile.introduction;
      introduction = (
        <div dangerouslySetInnerHTML={this.createMarkup(introduction)}></div>
      );
    }
    return (
      <ContentContainer>
        <ModalEditStatusMemberAktif
          show={ModalEditStatusMemberAktifShow}
          onHide={() =>
            this.setState({ ModalEditStatusMemberAktifShow: false })
          }
          id={id}
          name={name}
          status={status}
          handleRefresh={this.handleRefresh}
        />
        <ModalEditStatusMemberNonaktif
          show={ModalEditStatusMemberNonaktifShow}
          onHide={() =>
            this.setState({ ModalEditStatusMemberNonaktifShow: false })
          }
          id={id}
          name={name}
          status={status}
          handleRefresh={this.handleRefresh}
        />
        <ModalEditStatusMemberPensiun
          show={ModalEditStatusMemberPensiunShow}
          onHide={() =>
            this.setState({ ModalEditStatusMemberPensiunShow: false })
          }
          id={id}
          name={name}
          status={status}
          handleRefresh={this.handleRefresh}
        />
        <ModalEditStatusMemberAlm
          show={ModalEditStatusMemberAlmShow}
          onHide={() => this.setState({ ModalEditStatusMemberAlmShow: false })}
          id={id}
          name={name}
          status={status}
          handleRefresh={this.handleRefresh}
        />
        <Row>
          <Row className={css(styles.leftDetailContainer)}>
            <Column className={css(styles.leftDetailColumn)}>
              {img_profile === null ? (
                <DetailMemberPhoto />
              ) : (
                <DetailMemberPhoto
                  filename={img_profile}
                  folder="user_profile"
                  source="api"
                />
              )}
              <DownloadCVComponent
                button={(props) => (
                  <Row
                    onClick={props.onClick}
                    className={css(styles.clickableIcon)}
                  >
                    <IconCV />
                  </Row>
                )}
              >
                <MenuItem onClick={() => this.unduhCV(id)}>Unduh CV</MenuItem>
              </DownloadCVComponent>
              <DetailMemberNameComponent name={name} />
              <DetailMemberSubspecialist subspecialist={title} />
              <DetailMemberStatusComponent>
                <Row
                  className={
                    status === 0
                      ? css(styles.detailMemberStatusNonactiveID)
                      : status === 1
                        ? css(styles.detailMemberStatusActiveID)
                        : status === 2
                          ? css(styles.detailMemberStatusPensionID)
                          : css(styles.detailMemberStatusDepartedID)
                  }
                >
                  <span
                    className={
                      status === 0
                        ? css(styles.detailMemberStatusNonactiveIDFont)
                        : status === 1
                          ? css(styles.detailMemberStatusActiveIDFont)
                          : status === 2
                            ? css(styles.detailMemberStatusPensionIDFont)
                            : css(styles.detailMemberStatusDepartedIDFont)
                    }
                  >
                    ID {paboi_id}
                  </span>
                </Row>
                <DetailButtonComponent
                  button={(props) => (
                    <Row
                      onClick={props.onClick}
                      className={
                        status === 0
                          ? css(styles.detailMemberStatusNonactiveButton)
                          : status === 1
                            ? css(styles.detailMemberStatusActiveButton)
                            : status === 2
                              ? css(styles.detailMemberStatusPensionButton)
                              : css(styles.detailMemberStatusDepartedButton)
                      }
                    >
                      <span
                        className={css(styles.detailMemberStatusButtonFont)}
                      >
                        {status === 0
                          ? "Nonaktif"
                          : status === 1
                            ? "Aktif"
                            : status === 2
                              ? ">65 Tahun"
                              : "Alm"}
                      </span>{" "}
                      <IconCreate />
                    </Row>
                  )}
                  horizontal="left"
                >
                  <MenuItem
                    onClick={() =>
                      this.setState({ ModalEditStatusMemberAktifShow: true })
                    }
                  >
                    <IconDot fill="#2CB96A" /> Aktif
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.setState({ ModalEditStatusMemberNonaktifShow: true })
                    }
                  >
                    <IconDot fill="Red" /> Nonaktif
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.setState({ ModalEditStatusMemberPensiunShow: true })
                    }
                  >
                    <IconDot fill="#F57C2B" /> >65 Tahun
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.setState({ ModalEditStatusMemberAlmShow: true })
                    }
                  >
                    <IconDot fill="#727272" />
                    Alm
                  </MenuItem>
                </DetailButtonComponent>
              </DetailMemberStatusComponent>
              <DetailMenuComponent menuName="Pengantar" isActive={true} />
              <DetailMenuComponent
                menuName="Profile"
                onClick={() => this.profile(id)}
              />
            </Column>
          </Row>
          <Row className={css(styles.rightDetailContainer)}>
            <Column className={css(styles.rightDetailColumn)}>
              <DetailTitleComponent title="Pengantar" />
              <DetailMemberPengantarComponent pengantar={introduction} />
            </Column>
          </Row>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  leftDetailContainer: {
    width: "30%",
    background: "#F8F9FB 0% 0% no-repeat padding-box",
    borderRight: "2px solid rgba(192,192,192, 0.5)",
    opacity: 1,
    padding: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "block",
  },
  rightDetailContainer: {
    padding: 40,
    width: "70%",
  },
  rightDetailColumn: {
    width: "100%",
  },
  leftDetailColumn: {
    justifyContent: "center",
    alignItems: "center",
  },
  detailMemberStatusNonactiveID: {
    padding: "10px 20px",
    border: "2px solid #ff3232",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusActiveID: {
    padding: "10px 20px",
    border: "2px solid #2CB96A",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusPensionID: {
    padding: "10px 20px",
    border: "2px solid #ff8c32",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusDepartedID: {
    padding: "10px 20px",
    border: "2px solid #727272",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  detailMemberStatusNonactiveButton: {
    padding: "10px 20px",
    backgroundColor: "#ff3232",
    border: "2px solid #ff3232",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusActiveButton: {
    padding: "10px 20px",
    backgroundColor: "#2CB96A",
    border: "2px solid #2CB96A",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusPensionButton: {
    padding: "10px 20px",
    backgroundColor: "#ff8c32",
    border: "2px solid #ff8c32",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusDepartedButton: {
    padding: "10px 20px",
    backgroundColor: "#727272",
    border: "2px solid #727272",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    height: "100%",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  detailMemberStatusNonactiveIDFont: {
    color: "#ff3232",
  },
  detailMemberStatusActiveIDFont: {
    color: "#2CB96A",
  },
  detailMemberStatusPensionIDFont: {
    color: "#ff8c32",
  },
  detailMemberStatusDepartedIDFont: {
    color: "#727272",
  },
  detailMemberStatusButtonFont: {
    color: "#FFFFFF",
    marginRight: 20,
  },
  changeStatusButton: {
    padding: 0,
    fontSize: 14,
  },
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ memberList }) {
  return {
    memberList_pending: getMemberListPending(memberList),
    memberList_data: getMemberList(memberList),
    memberList_error: getMemberListError(memberList),
  };
}

export default connect(mapStateToProps, { memberList })(
  withRouter(DetailMemberPengantar)
);
