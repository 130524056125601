import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_EDUCATION_PENDING = "LIST_EDUCATION_PENDING";
export const LIST_EDUCATION_SUCCESS = "LIST_EDUCATION_SUCCESS";
export const LIST_EDUCATION_ERROR = "LIST_EDUCATION_ERROR";

export const DETAIL_EDUCATION_PENDING = "DETAIL_EDUCATION_PENDING";
export const DETAIL_EDUCATION_SUCCESS = "DETAIL_EDUCATION_SUCCESS";
export const DETAIL_EDUCATION_ERROR = "DETAIL_EDUCATION_ERROR";

export const ADD_EDUCATION_PENDING = "ADD_EDUCATION_PENDING";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_ERROR = "ADD_EDUCATION_ERROR";

export const EDIT_EDUCATION_PENDING = "EDIT_EDUCATION_PENDING";
export const EDIT_EDUCATION_SUCCESS = "EDIT_EDUCATION_SUCCESS";
export const EDIT_EDUCATION_ERROR = "EDIT_EDUCATION_ERROR";

export const APPROVE_EDUCATION_PENDING = "APPROVE_EDUCATION_PENDING";
export const APPROVE_EDUCATION_SUCCESS = "APPROVE_EDUCATION_SUCCESS";
export const APPROVE_EDUCATION_ERROR = "APPROVE_EDUCATION_ERROR";

export const UPLOAD_EDUCATION_PENDING = "UPLOAD_EDUCATION_PENDING";
export const UPLOAD_EDUCATION_SUCCESS = "UPLOAD_EDUCATION_SUCCESS";
export const UPLOAD_EDUCATION_ERROR = "UPLOAD_EDUCATION_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_EDUCATION";

const LIST_EDUCATION_URL = "member/education/find";
const UPLOAD_EDUCATION_URL = "image/upload/member_education";
const EDIT_EDUCATION_URL = "member/education/edit";
const ADD_EDUCATION_URL = "member/education/add";
const APPROVE_EDUCATION_URL = "member/education/approve";

export function listEducation(param = {}, resolve) {
  // param.user_id = getUser().id;
  return (dispatch) => {
    dispatch(actionPending(LIST_EDUCATION_PENDING));
    API.post(LIST_EDUCATION_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_EDUCATION_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * param.length,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_EDUCATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list pendidikan, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addEducation({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_EDUCATION_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(ADD_EDUCATION_URL, restParam, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_EDUCATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan pendidikan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_EDUCATION_ERROR, error));
        toastError(error, "Gagal menambahkan pendidikan, ");
      });
  };
}

export function editEducation({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_EDUCATION_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_EDUCATION_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // return null;
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_EDUCATION_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_EDUCATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah pendidikan");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_EDUCATION_ERROR, error));
        toastError(error, "Gagal mengubah pendidikan, ");
      });
  };
}

export function detailEducation(id = null) {
  const param = {
    // user_id: getUser().id,
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_EDUCATION_PENDING));
    API.post(LIST_EDUCATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_EDUCATION_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_EDUCATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list pendidikan, ");
      });
  };
}

export function approveEducation(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_EDUCATION_PENDING));
    API.post(APPROVE_EDUCATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_EDUCATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status pendidikan");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_EDUCATION_ERROR, error));
        toastError(error, "Gagal mengubah status pendidikan, ");
      });
  };
}

export function uploadEducation(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_EDUCATION_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_EDUCATION_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_EDUCATION_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_EDUCATION_ERROR, error));
        toastError(error, "Gagal upload berkas, ");
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}
