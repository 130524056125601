import React, { Component } from "react";
import { connect } from "react-redux";
import { listTraining } from "../../actions/member/training_action";
import {
  getListTraining,
  getTrainingError,
  getTrainingPending,
} from "../../reducers/member/training_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import TrainingHeader from "./TrainingHeader";
import TrainingModalAdd from "./TrainingModalAdd";
import TrainingModalDelete from "./TrainingModalDelete";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";

class Training extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      showModalAdd: false,
      showModalDelete: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/admin/member-training/detail/${id}`);
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    const url = window.location.href;
    const ID = url.substring(url.lastIndexOf('/') + 1);
    const isID = url.substring(url.lastIndexOf('/') + 1) !== "member-training";
    if (isID && this.state.id === null) {
      this.setState({ id: ID })
    }
  }

  render() {
    const { listTraining, listTraining_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listTraining_data) {
      data = listTraining_data.data;
      records_total = listTraining_data.records_total;
    }
    return (
      <ContentContainer>
        <TrainingHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <TrainingModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <TrainingModalDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Member",
              field: "member_data.name",
            },
            {
              title: "Id Paboi",
              field: "member_data.member_profile.paboi_id",
            },
            {
              title: "Nama Institusi",
              field: "institution",
            },
            {
              title: "Nama Pelatihan",
              field: "name",
            },
            {
              title: "Tahun",
              field: "year",
            },
            {
              title: "Status",
              field: "status",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={
                      status === 0
                        ? "Baru"
                        : status === 1
                          ? "Terverifikasi"
                          : "Ditolak"
                    }
                  />
                );
              },
            },
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(id)}>
                      Detail
                    </MenuItem>
                    {/* <MenuItem onClick={() => this.handleOpenModalDelete(id)}>
                      Delete
                    </MenuItem> */}
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                id: this.state.id,
              };
              listTraining(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listTrainingMember }) {
  return {
    listTraining_data: getListTraining(listTrainingMember),
    listTraining_error: getTrainingError(listTrainingMember),
    listTraining_pending: getTrainingPending(listTrainingMember),
  };
}

export default connect(mapStateToProps, {
  listTraining,
})(Training);
