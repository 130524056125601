import React from "react";
import { StyleSheet, css } from "aphrodite";
import { default_api } from "../../constants/default_api";


function DetailPhotoComponent(props) {
  const { filename, folder, source, width, height } = props;
  let handleWidth = width !== undefined ? width : "100px";
  let handleHeight = height !== undefined ? height : "100px";
  const profileImageUrl = default_api + "/image/show/" + folder + '/' + filename;
  return (
    <>
      {source === "local" ? <img src={filename} alt="profil" width={handleWidth} height={handleHeight} className={css(styles.image)} />
        : source === "api" ? <img src={profileImageUrl} alt="profil" width={handleWidth} height={handleHeight} className={css(styles.image)}></img>
          : <img src={require('../../assets/profil_picture.png')} alt="profil" width={handleWidth} height={handleHeight} className={css(styles.image)}></img>
      }
    </>
  )
}

const styles = StyleSheet.create({
  image: {
    borderRadius: "50%",
    objectFit: "contain"
  }
});

export default DetailPhotoComponent;