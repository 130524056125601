import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";

import moment from "moment";
import localization from "moment/locale/id";

import { listEvent, detailEvent } from "../../actions/event/event_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import { provinceList } from "../../actions/master-data/province_action";

import {
  getEventPending,
  getListEvent,
  getEventError,
} from "../../reducers/event/event_reducer";
import HeaderListPesertaEvent from "../../components/event/HeaderListPesertaEventComponent";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";

class Participant extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailEventParticipants(data) {
    const { history } = this.props;
    const { id, event_id } = data;
    history.push(`/event/participant-detail/${event_id}/${id}/umum`);
  }

  render() {
    let data = [];
    let records_total = 0;
    let id = "";
    let url = window.location.href.split("/");
    let event_name,
      event_date,
      total_participants,
      event_start_date = "";
    const { listEvent, listEvent_data, selectedEvent } = this.props;
    if (listEvent_data) {
      data = listEvent_data.data[0];
      event_name = data.event_name;
      event_start_date = data.event_start_date;
      event_date =
        moment(data.event_start_date)
          .locale("id", localization)
          .format("DD MMM YYYY hh:mm") +
        " - " +
        moment(data.event_end_date)
          .locale("id", localization)
          .format("DD MMM YYYY hh:mm");
      total_participants = data.total_participants;
      records_total = data.total_participants;
    }
    if (selectedEvent) {
      id = selectedEvent.id;
    } else {
      id = url[5];
    }

    return (
      <ContentContainer>
        <HeaderListPesertaEvent
          event_name={event_name}
          event_date={event_date}
          total_participants={total_participants}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "No Registrasi",
              render: (data) => {
                if (data.register_number !== "") {
                  return data.register_number;
                } else {
                  return "-";
                }
              },
            },
            {
              title: "Tanggal Registrasi",
              render: (data) => {
                return moment(data.created_at)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            {
              title: "Nama Lengkap",
              field: "fullname",
            },
            {
              title: "Membership",
              field: "membership",
            },
            {
              title: "Status",
              render: (data) => {
                var today = new Date();
                let status_label = "";
                if (data.acceptance_status > 0) {
                  if (today > new Date(event_start_date)) {
                    status_label =
                      data.attendance_status === 0
                        ? "Belum Hadir"
                        : data.attendance_status === 1
                        ? "Hadir"
                        : "Tidak Hadir";
                  } else {
                    status_label =
                      data.acceptance_status === 0
                        ? "Menunggu Konfirmasi"
                        : data.acceptance_status === 1
                        ? "Terverifikasi"
                        : "Ditolak";
                  }
                } else {
                  status_label = "Menunggu Konfirmasi";
                }
                return <TableStatusComponent status={status_label} />;
              },
              width: 250
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() =>
                        this.handleDetailEventParticipants(rowData)
                      }
                    >
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve) => {
              let param = {
                page: query.page + 1,
                length: 10,
                id: id,
              };
              listEvent(param, resolve, true);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps(state) {
  const { listEvent, selectedEvent } = state;
  return {
    listEvent_data: getListEvent(listEvent),
    listEvent_error: getEventError(listEvent),
    listEvent_pending: getEventPending(listEvent),
    selectedEvent: selectedEvent,
  };
}

export default connect(mapStateToProps, {
  listEvent,
  detailEvent,
  provinceList,
})(withRouter(Participant));
