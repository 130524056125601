import React, { Component, createRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import IconDownload from "../../assets/icon-download";
import { default_api } from "../../constants/default_api";


class ModalDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: this.props.filename,
      ref: createRef(null),
    };
    this.handleDownload = this.handleDownload.bind(this);
    this.updateIframeSrc = this.updateIframeSrc.bind(this);
  }

  handleDownload() {
    const { filename, filepath } = this.props;
    window.open(
      `${default_api}/image/show/${filepath}/${filename}`
    );
  }

  componentDidUpdate(prevProps) {
    const { filename, show } = this.props;
    const { intervalId } = this.state;

    if (
      prevProps.show !== show &&
      filename &&
      filename.split(".")[1] === "pdf"
    ) {
      if (show === true) {
        const intervalId = setInterval(() => {
          this.updateIframeSrc();
        }, 3000);
        this.setState({ intervalId: intervalId });
      } else {
        clearInterval(intervalId);
      }
    }
  }

  updateIframeSrc() {
    const { ref } = this.state;
    if (ref.current) {
      ref.current.src = `https://docs.google.com/gview?url=${default_api}/image/show/${this.props.filepath}/${this.props.filename}&embedded=true`;
    }
  }

  render() {
    const { filename, show, onHide, modalTitle, filepath } = this.props;

    let document = <p>Memuat Berkas</p>;

    if (filename) {
      if (filename.split(".")[1] === "pdf") {
        document = (
          <iframe
            ref={this.state.ref}
            src={`https://docs.google.com/gview?url=${default_api}/image/show/${filepath}/${this.props.filename}&embedded=true`}
            width="100%"
            height="500px"
            title="pdf"
            onLoad={() => clearInterval(this.state.intervalId)}
          ></iframe>
        );
      } else {
        document = (
          <img
            src={`${default_api}/image/show/${filepath}/${filename}`}
            alt={`berkas ${filepath}`}
            className={css(styles.image)}
          ></img>
        );
      }
    }
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Column>
            <p style={{ fontSize: 20, fontWeight: 600 }}>{modalTitle}</p>
          </Column>
        </Modal.Header>
        <Modal.Body>
          <Column className={css(styles.bodyModal)}>
            {document ? document : <p>data tidak ditemukan</p>}
          </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              onClick={() => this.setState({ filename: this.props.filename })}
            >
              Refresh
            </Button>
            {filename && (
              <Button
                onClick={this.handleDownload}
                variant="contained"
                color="primary"
                className={css(styles.approveButton)}
                startIcon={<IconDownload />}
              >
                Download
              </Button>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    padding: "0px 40px",
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  image: {
    maxHeight: 400,
    maxWidth: 400,
    objectFit: "contain",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

export default ModalDocument;
