import React from "react";
import { StyleSheet, css } from "aphrodite";
import avatarImage from "../../assets/avatar.png";
import { default_api } from "../../constants/default_api";


function AvatarPPDSComponent(props) {
    const { image, isPPDS } = props;
    const imageUrl = `${default_api}/image/show/ppds_supporting_files/` + image;
    return (
        <>
            <img
                src={image ? imageUrl : avatarImage}
                alt="profil"
                width="100%"
                height="100%"
                className={css(styles.image)}
            />
        </>
    );
}

const styles = StyleSheet.create({
    image: {
        borderRadius: "50%",
        objectFit: "contain",
    },
});

AvatarPPDSComponent.propsTypes = {
    // image: node,
};
AvatarPPDSComponent.defaultProps = {
    // image: avatarImage,
};

export default AvatarPPDSComponent;
