import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

function NameComponent(props) {
  const { name } = props;
  return <h3 className={css(styles.detailName)}> {name} </h3>;
}

NameComponent.propTypes = {
  name: string,
};

const styles = StyleSheet.create({
  detailName: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: 800,
    color: "#495057",
    marginTop: 20,
    marginBottom: 0,
  },
});

NameComponent.propsTypes = {
  name: string,
};
NameComponent.defaultProps = {
  name: "Paboi Member",
};

export default NameComponent;
