import React from "react";
import { string } from "prop-types";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

function DetailTitleComponent(props) {
    const { title, marginBottom } = props;
    const isTitleWithButton = marginBottom ? true : false;
    return (
        <Row>
            {isTitleWithButton === true ?
                <span className={css(styles.detailTitleWithButton)}> {title} </span> :
                <span className={css(styles.detailTitle)}> {title} </span> 
            }
        </Row>
    )
}

DetailTitleComponent.propTypes = {
    title: string,
};

const styles = StyleSheet.create({
    detailTitle: {
        textAlign: "center",
        fontSize: 20,
        fontWeight: "bold",
        color: "Black",
        marginBottom: 30
    },
    detailTitleWithButton: {
        textAlign: "center",
        fontSize: 20,
        fontWeight: "bold",
        color: "Black",
        marginBottom: 0
    }
});

export default DetailTitleComponent;