import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Row } from "simple-flexbox";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { formValueSelector } from "redux-form"; // ES6
import { listHospital } from "../../actions/master-data/hospital_action";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import {
  detailPractice,
  editPractice,
  setIsEditFile,
} from "../../actions/sub-menu/practice_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import {
  getPracticePending,
  getDetailPractice,
  getPracticeError,
  getPracticeIsEditFile,
} from "../../reducers/sub-menu/practice_reducer";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import {
  renderInput,
  renderFile,
  renderSelect,
  renderFilePractice,
} from "../../components/form/FormComponent";
import {
  getListHospital,
  getHospitalPending,
  getHospitalError,
} from "../../reducers/master-data/hospital_reducer";

import { getUser } from "../../helpers/user";

import Button from "@material-ui/core/Button";
import RenderArray from "./PracticeForm";

import ModalPracticeApprove from "./ModalPracticeApprove";
import ModalPracticeReject from "./ModalPracticeReject";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";

// import RenderFile from "../../components/form/FormComponent2";

class PracticeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      modalApproveShow: false,
      modalRejectShow: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
    };
    // this.handleAdaptaionChange = this.handleAdaptaionChange.bind(this);
    // this.handleUniversityChange = this.handleUniversityChange.bind(this);
    // this.handleDegreeChange = this.handleDegreeChange.bind(this);
  }

  componentDidMount() {
    const { detailPractice } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    detailPractice(id);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    const { listHospital, reset } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    reset();
    listHospital();
  }

  handleTriggerEditFile() {
    const { editPractice_isEditFile, setIsEditFile } = this.props;
    if (editPractice_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editPractice_isEditFile);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl })
    this.setState({ modal_title: modal_title })
    if (imgUrl.split('.')[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true })
    } else {
      this.setState({ modalDocumentImgShow: true })
    }
  }

  handleSubmit(values) {
    const { editPractice, detailPractice, detailPractice_data } = this.props;
    const { phone, email } = values;

    const {
      match: {
        params: { id },
      },
    } = this.props;
    const param = {
      id: id,
      hospital_name:
        values.hospital_name.value === "other"
          ? values.other_hospital
          : values.hospital_name.label,
      phone: phone,
      email: email,
      file: values.berkas,
      filename: detailPractice_data[0].filename,
      member_practice_place_days: values.hospital.map((item) => ({
        day_code: item.day.value,
        start_time: item.time_start,
        end_time: item.time_end,
      })),
    };

    const callback = () => {
      detailPractice(id);
      this.handleTriggerFormEdit();
    };
    editPractice(param, callback);
  }

  render() {
    const { modalApproveShow, modalRejectShow } = this.state;
    const {
      detailPractice_data,
      editPractice_pending,
      editPractice_isEditFile,
      listHospital_data,
      handleSubmit,
      formValue
    } = this.props;
    const { strata, degreeOptions, isEdit } = this.state;
    let name = null;
    let filename = null;
    let id = null;
    let status = null;
    let reject_notes = null;
    let reject_filename = null;
    if (detailPractice_data && detailPractice_data.length !== 0) {
      name = detailPractice_data[0].member_data.name;
      filename = detailPractice_data[0].filename;
      id = detailPractice_data[0].id;
      status = detailPractice_data[0].status;
      reject_notes = detailPractice_data[0].reject_notes;
      reject_filename = detailPractice_data[0].reject_filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    // let degreeOptions = [];
    let hospitalOptions = [];
    if (listHospital_data) {
      hospitalOptions = listHospital_data.data.map((item) => ({
        value: item.id,
        label: item.name,
        phone: item.phone,
        email: item.email,
      }));
      hospitalOptions.splice(0, 0, { label: "Lainnya...", value: "other" })
    }
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: `${i}` }];
    }

    return (
      <>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_practice_place"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_practice_place"
        />
        <ModalPracticeApprove
          id_practice={id}
          show={modalApproveShow}
          onHide={() => this.setState({ modalApproveShow: false })}
        />

        <ModalPracticeReject
          id_practice={id}
          show={modalRejectShow}
          onHide={() => this.setState({ modalRejectShow: false })}
        />

        <ContentContainer>
          <Row>
            <LeftContainerComponent>
              <DetailNameComponent name={name} />
              {/* <DetailRegistrationStatusComponent
                icon={IconError}
                message={message}
                messageTitle={messageTitle}
                imgUrl={reject_filename}
                reason={reject_notes}
                status={status}
              /> */}
              <StatusSquareComponent
                statusLabel={
                  status === 0
                    ? "Menunggu verifikasi"
                    : status === 1
                      ? "Data Praktik Terverifikasi"
                      : reject_notes
                }
                statusTitle={
                  status === 0
                    ? "Baru"
                    : status === 1
                      ? "Terverifikasi"
                      : "Ditolak"
                }
                status={status}
                filename={reject_filename}
                filepath="member_practice_place"
              />

              {getUser().role_codes[0] === "root" && status === 0 && (
                <Row style={{ width: "100%" }} horizontal="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.approveButton)}
                    onClick={() => this.setState({ modalApproveShow: true })}
                  >
                    VERIFIKASI
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={css(styles.rejectButton)}
                    onClick={() => this.setState({ modalRejectShow: true })}
                  >
                    TOLAK
                  </Button>
                </Row>
              )}
            </LeftContainerComponent>
            <RightContainerComponent>
              <form
                onSubmit={handleSubmit(this.handleSubmit)}
                style={{ width: "100%" }}
              >
                <Row
                  vertical="center"
                  horizontal="space-between"
                  style={{ width: "100%", marginBottom: 20 }}
                >
                  <DetailNameComponent name="Informasi Tempat Praktik" />
                  {getUser().role_codes[0] === "member" && status === 2 && (
                    <>
                      <EditButtonComponent
                        isEdit={isEdit}
                        triggerIsEdit={this.handleTriggerFormEdit}
                        isLoading={editPractice_pending}
                      />
                    </>
                  )}
                  {getUser().role_codes[0] === "root" && (
                    <>
                      <EditButtonComponent
                        isEdit={isEdit}
                        triggerIsEdit={this.handleTriggerFormEdit}
                        isLoading={editPractice_pending}
                      />
                    </>
                  )}
                </Row>
                <FormContainer label="Nama RS">
                  <Field
                    isDetail={!isEdit}
                    name="hospital_name"
                    placeholder="Pilih RS"
                    isAsync
                    asyncUrl="master_data/hospital/find"
                    options={hospitalOptions}
                    component={renderSelect}
                  />
                </FormContainer>
                {formValue && formValue.hospital_name && formValue.hospital_name.value === "other" &&
                  <FormContainer label="Nama RS">
                    <Field
                      isDetail={!isEdit}
                      name="other_hospital"
                      placeholder="Masukkan Nama RS"
                      component={renderInput}
                    />
                  </FormContainer>
                }
                {!isEdit &&
                  <>
                    <FormContainer label="Status">
                      <Field
                        isDetail
                        name="status"
                        placeholder="Pilih jenjang pendidikan"
                        component={renderInput}
                        onChange={this.handleDegreeChange}
                        options={hospitalOptions}
                      />
                    </FormContainer>
                    <FormContainer label="Tipe RS">
                      <Field
                        isDetail
                        name="class_type"
                        placeholder="Pilih jenjang pendidikan"
                        component={renderSelect}
                        onChange={this.handleDegreeChange}
                        options={hospitalOptions}
                      />
                    </FormContainer>
                    <FormContainer label="Alamat RS">
                      <Field
                        isDetail
                        name="address"
                        placeholder="Masukkan Alamat"
                        component={renderSelect}
                        options={strata ? degreeOptions[strata] : []}
                      />
                    </FormContainer>
                  </>
                }
                <FormContainer label="Nomor Telepon">
                  <Field
                    isDetail={!isEdit}
                    name="phone"
                    placeholder="Masukkan nomor telepon"
                    component={renderInput}
                    options={yearOptions}
                  />
                </FormContainer>
                <FormContainer label="Email">
                  <Field
                    isDetail={!isEdit}
                    name="email"
                    placeholder="Masukkkan Email"
                    component={renderInput}
                    options={yearOptions}
                  />
                </FormContainer>
                <FormContainer
                  label="Jam Praktik"
                  contentValueStyle={{ margin: "20px 0" }}
                >
                  <FieldArray
                    isDetail={!isEdit}
                    name="hospital"
                    placeholder="Masukkan Jadwal Baru"
                    component={RenderArray}
                    small
                  />
                </FormContainer>
                {getUser().role_codes[0] === "root" && isEdit ? <FormContainer label="Berkas">
                  < Field
                    triggerEditFile={this.handleTriggerEditFile}
                    isEditFile={editPractice_isEditFile}
                    isDetail={!isEdit}
                    filename={filename}
                    filePath="member_practice_place"
                    name="berkas"
                    type="file"
                    fileType="image" //image or pdf
                    title="upload berkas"
                    message="PNG atau JPG maksimal 500 kb"
                    component={renderFile}
                  />
                </FormContainer> :
                  filename === null ?
                    <span>Belum ada foto</span>
                    :
                    <DetailContentComponent label="Berkas" value={<DetailContentLihatBerkas onClick={() => this.handleOpenDocument(filename, "Berkas Tempat Praktik")} />} />
                }
                {getUser().role_codes[0] === "member" &&
                  < FormContainer label="Berkas">
                    <Field
                      triggerEditFile={this.handleTriggerEditFile}
                      isEditFile={editPractice_isEditFile}
                      isDetail={!isEdit}
                      filename={filename}
                      name="berkas"
                      type="file"
                      fileType="image" //image or pdf
                      title="upload berkas"
                      message="PNG atau JPG maksimal 500 kb"
                      component={renderFilePractice}
                    />
                  </FormContainer>
                }
              </form>
            </RightContainerComponent>
          </Row>
        </ContentContainer>
      </>
    );
  }
}

function validate(values, props) {
  
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { editPractice_isEditFile } = props;
  const { hospital, hospital_name, berkas } = values;
  const errors = {};
  const hospitalArrayErrors = [];
  if (!hospital || !hospital.length) {
    errors.hospital = { _error: "Jadwal tidak boleh kosong" };
  } else {
    hospital.forEach((item, index) => {
      const hospitalErrors = {};
      if (!item || !item.day) {
        hospitalErrors.day = "Tentukan hari praktik";
        hospitalArrayErrors[index] = hospitalErrors;
      }
      if (!item || !item.time_start) {
        hospitalErrors.time_start = "Tentukan waktu mulai";
        hospitalArrayErrors[index] = hospitalErrors;
      }
      if (!item || !item.time_end) {
        hospitalErrors.time_end = "Tentukan waktu selesai";
        hospitalArrayErrors[index] = hospitalErrors;
      }
    });
  }
  if (hospitalArrayErrors.length) {
    errors.hospital = hospitalArrayErrors;
  }
  if (!hospital_name) {
    errors.hospital_name = "Rumah Sakit tidak boleh kosong";
  }
  if (!values.berkas && editPractice_isEditFile === true){
    errors.berkas = "Berkas wajib disi!"
  }
  else{
    if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
      errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
    }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
      errors.berkas = "File lebih dari 500KB"
    }
  }
  return errors;
}

PracticeDetail = reduxForm({
  // a unique name for the form
  form: "PracticeDetail",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(PracticeDetail);

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    marginRight: 10,
    boxShadow: "none",
    width: "210px",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    color: "#495057",
    boxShadow: "none",
    width: "210px",
  },
});

function mapStateToProps(state) {
  const day = [
    "0",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];
  const { detailPractice, editPractice, listHospital } = state;
  let initialValues = {};
  const detailPractice_data = getDetailPractice(detailPractice);
  if (detailPractice_data && detailPractice_data.length !== 0) {
    initialValues = {
      hospital_name: {
        label: detailPractice_data[0].hospital_data.name,
        value: detailPractice_data[0].hospital_data.id,
      },
      class_type: {
        label: detailPractice_data[0].hospital_data.class_type,
        value: detailPractice_data[0].hospital_data.class_type,
      },
      address: {
        label:
          detailPractice_data[0].hospital_data.address == null
            ? "Alamat Belum Dimasukkan"
            : detailPractice_data[0].hospital_data.address,
        value: detailPractice_data[0].hospital_data.address,
      },
      phone: detailPractice_data[0].phone,
      email: detailPractice_data[0].email,
      status:
        detailPractice_data[0].status === 0
          ? "Baru"
          : detailPractice_data[0].status === 1
            ? "Terverifikasi"
            : "Ditolak",
      hospital: detailPractice_data[0].member_practice_place_days.map(
        (item) => ({
          day: { value: item.day_code, label: day[item.day_code] },
          time_start: item.start_time,
          // format="mm:ss";
          time_end: item.end_time,
        })
      ),
    };
  }

  const selector = formValueSelector("PracticeDetail");
  const formValue = selector(state, "university_to", "is_adaptation", "hospital_name");

  return {
    detailPractice_data: getDetailPractice(detailPractice),
    detailPractice_error: getPracticeError(detailPractice),
    detailPractice_pending: getPracticePending(detailPractice),
    editPractice_error: getPracticeError(editPractice),
    editPractice_pending: getPracticePending(editPractice),
    editPractice_isEditFile: getPracticeIsEditFile(editPractice),
    initialValues,

    listHospital_data: getListHospital(listHospital),
    listHospital_pending: getHospitalPending(listHospital),
    listHospital_error: getHospitalError(listHospital),
    formValue,
  };
}

export default connect(mapStateToProps, {
  detailPractice,
  editPractice,
  setIsEditFile,
  listHospital,
})(withRouter(PracticeDetail));
