import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import {
  deletePPDS
} from "../../actions/manajemen-ppds/manajemen_ppds_action";


class PPDSModalDelete extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
     const { deletePPDS, handleRefresh, id, onHide } = this.props;
     const callback = () => {
       onHide();
       handleRefresh();
     };
     deletePPDS(id, callback);
  }

  render() {
    const { show, onHide, deletePPDS_pending } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Hapus PPDS</p>
        </Modal.Header>
        <Modal.Body>
          <Column
            vertical="center"
            horizontal="stretch"
            className={css(styles.bodyModal)}
          >
            <p style={{ marginTop: 20 }}>
              Apakah Anda yakin ingin menghapus data pendidikan ini ?
            </p>
          </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              startIcon={<ClearIcon />}
              onClick={this.props.onHide}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={deletePPDS_pending}
              className={css(styles.approveButton)}
              onClick={this.handleSubmit}
              startIcon={
                deletePPDS_pending ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                    <CheckIcon />
                  )
              }
            >
              Ya
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { deletePPDS} = state;
  const selector = formValueSelector("PPDSModalAdd");
  const formValue = selector(state, "university_to", "is_adaptation", "education_stage");

  return {
    formValue,
  };
}


const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
});

export default connect(mapStateToProps, {
  deletePPDS,
})(withRouter(PPDSModalDelete));
