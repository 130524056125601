import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { memberRegister } from "../../actions/guests/member_register_action";
import { getMemberRegister, getMemberRegisterPending, getMemberRegisterError } from "../../reducers/guests/member_register_reducer"
import Button from "@material-ui/core/Button";
import { reduxForm, Field } from "redux-form";
import { renderInput } from "../../components/form/FormComponent";
import IconInput from "../../assets/icon-input";
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from "@material-ui/core";

class register extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        const { memberRegister } = this.props;
        const param_register = {
            email: values.emailID
        };
        memberRegister(param_register);
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <Grid container>
                <Grid item xs={false} sm={false} md={4} lg={4} className={css(styles.sideContainer)}></Grid>
                <Grid item xs={10} sm={10} md={7} lg={7} className={css(styles.registerContainer)}>
                    <Column>
                        <Row>
                            <img src={require('../../assets/ic_paboi.png')} className={css(styles.logo)} alt="logo-paboi" />
                        </Row>
                        <span className={css(styles.registerTitle)}>Daftar ke PABOI</span>
                        <span className={css(styles.registerDesc)}>Masukkan email atau ID PABOI</span>
                        <hr className={css(styles.registerSeparator)} />
                        <form
                            onSubmit={handleSubmit(this.handleSubmit)}
                            className={css(styles.registerForm)}
                        >
                            <Field
                                name="emailID"
                                placeholder="Email atau ID PABOI"
                                component={renderInput}
                            />
                            <Row style={{ justifyContent: "space-between" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={css(styles.approveButton)}
                                    disabled={this.props.memberRegister_pending}
                                    startIcon={
                                        this.props.memberRegister_pending ? (
                                            <CircularProgress size={14} color="secondary" />
                                        ) : (
                                                <IconInput />
                                            )
                                    }
                                >
                                    Daftar
                                </Button>
                                <Column>
                                    <span className={css(styles.daftarLabel)}>Sudah punya akun?</span>
                                    <span onClick={() => this.props.history.push('/login')} className={css(styles.inputLinkDaftar)}>Login</span>
                                </Column>
                            </Row>
                        </form>
                    </Column>
                </Grid>
            </Grid >
        )
    }
}

const styles = StyleSheet.create({
    sideContainer: {
        height: "100vh",
        background: "transparent linear-gradient(180deg, #2CB96A 0%, #43C767 66%, #63DB62 100%) 0% 0% no-repeat padding-box"
    },
    registerContainer: {
        margin: "auto",
    },
    registerTitle: {
        color: "#04AA59",
        fontSize: 36,
        fontWeight: "bold",
        margin: 15
    },
    registerDesc: {
        fontSize: 16,
        margin: "0 15px"
    },
    registerSeparator: {
        width: 400,
        margin: "20px 15px",
        borderTop: "2px solid rgba(0,0,0,0.1)",
        '@media (max-width: 480px)': {
            width: "100%"
        }
    },
    registerForm: {
        width: 400,
        marginLeft: 15,
        '@media (max-width: 480px)': {
            width: "100%"
        }
    },
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
        margin: "15px 0"
    },
    inputMargin: {
        margin: "15px 0"
    },
    inputLinkDaftar: {
        fontSize: 16,
        color: "black",
        textDecoration: "none",
        marginTop: 5,
        textAlign: "right",
        ":hover": {
            cursor: "pointer"
        }
    },
    daftarLabel: {
        marginTop: 15,
        textAlign: "right"
    }
})

function validate(values) {
    const { emailID } = values;
    const errors = {};
    if (!emailID) {
        errors.emailID = "Email atau ID PABOI tidak boleh kosong"
    }
    return errors;
}

register = reduxForm({
    // a unique name for the form
    form: "register",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    shouldError: () => true,
})(register);

function mapStateToProps({ memberRegister }) {
    return {
        memberRegister_pending: getMemberRegisterPending(memberRegister),
        memberRegister_data: getMemberRegister(memberRegister),
        memberRegister_error: getMemberRegisterError(memberRegister)
    }
}

export default connect(mapStateToProps, { memberRegister })(register);