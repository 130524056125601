import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";

import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_PPDS_PENDING = "LIST_PPDS_PENDING";
export const LIST_PPDS_SUCCESS = "LIST_PPDS_SUCCESS";
export const LIST_PPDS_ERROR = "LIST_PPDS_ERROR";

export const DETAIL_PPDS_PENDING = "DETAIL_PPDS_PENDING";
export const DETAIL_PPDS_SUCCESS = "DETAIL_PPDS_SUCCESS";
export const DETAIL_PPDS_ERROR = "DETAIL_PPDS_ERROR";

export const ADD_PPDS_PENDING = "ADD_PPDS_PENDING";
export const ADD_PPDS_SUCCESS = "ADD_PPDS_SUCCESS";
export const ADD_PPDS_ERROR = "ADD_PPDS_ERROR";

export const EDIT_PPDS_PENDING = "EDIT_PPDS_PENDING";
export const EDIT_PPDS_SUCCESS = "EDIT_PPDS_SUCCESS";
export const EDIT_PPDS_ERROR = "EDIT_PPDS_ERROR";

export const DELETE_PPDS_PENDING = "DELETE_PPDS_PENDING";
export const DELETE_PPDS_SUCCESS = "DELETE_PPDS_SUCCESS";
export const DELETE_PPDS_ERROR = "DELETE_PPDS_ERROR";

export const CHANGE_STATUS_GRADUATION_PPDS_PENDING =
  "CHANGE_STATUS_GRADUATION_PPDS_PENDING";
export const CHANGE_STATUS_GRADUATION_PPDS_SUCCESS =
  "CHANGE_STATUS_GRADUATION_PPDS_SUCCESS";
export const CHANGE_STATUS_GRADUATION_PPDS_ERROR =
  "CHANGE_STATUS_GRADUATION_PPDS_ERROR";

const LIST_PPDS_URL = "member/ppds_sp2/find";
const CHANGE_STATUS_GRADUATION_PPDS_URL =
  "member/ppds_sp2/change_status_graduation";
const EDIT_PPDS_URL = "member/ppds_sp2/edit";
const DELETE_PPDS_URL = "url";
const ADD_PPDS_URL = "url";

export function listPpds(param = {}, resolve, reject, callback) {
  return (dispatch) => {
    dispatch(actionPending(LIST_PPDS_PENDING));
    API.post(LIST_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          //   throw error;
          throw res.error;
        }
        dispatch(actionSuccess(LIST_PPDS_SUCCESS, res.data));
        // toastSuccess("Berhasil mendapatkan list PPDS");
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          no: i + 1 + (param.page - 1) * 10,
          name: item.name,
          kolegium_no: item.kolegium_no,
          npa_idi: item.npa_idi,
          university_approved_data: item.university_approved_data,
          university_before_data: item.university_before_data,
          exam_date:
            item.ppds_destination_university_data[0].period_selection_year +
            "-" +
            item.ppds_destination_university_data[0].period_selection_month,
          fk_ppds: item.university_approved_data?.abbreviation,
          status: item.status_active,
          status_graduated: item.status_graduated,
          id: item.id,
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
        // return res;
      })
      .catch((error) => {
        // if (resolve) {
        //   // resolve();
        //   resolve({
        //     data: {},
        //     page: 0,
        //     totalCount: 0,
        //   });
        // }
        // reject();
        resolve({ data: [], page: 0, totalCount: 0 });
        dispatch(actionError(LIST_PPDS_ERROR, error));
        toastError(error, "Gagal mendapatkan list PPDS, ");
      });
  };
}

export function addPpds(values, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_PPDS_PENDING));
    API.post(ADD_PPDS_URL, values, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan PPDS");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_PPDS_ERROR, error));
        toastError(error, "Gagal menambahkan PPDS, ");
      });
  };
}

export function detailPpds(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_PPDS_PENDING));
    API.post(LIST_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }

        dispatch(actionSuccess(DETAIL_PPDS_SUCCESS, res.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_PPDS_ERROR, error));
        toastError(error, "Gagal mendapatkan detail PPDS, ");
      });
  };
}

export function editPpds(param = {}, resolve, callback, action) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_PPDS_PENDING));
    API.post(EDIT_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_PPDS_SUCCESS, res.data.data));

        if (action === "edit_peminatan") {
          toastSuccess("Berhasil merubah data peminatan!");
        } else {
          toastSuccess("Berhasil mengubah PPDS");
        }

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_PPDS_ERROR, error));
        toastError(error, "Gagal mengubah PPDS, ");
      });
  };
}
export function changeStatusGraduation(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(CHANGE_STATUS_GRADUATION_PPDS_PENDING));
    API.post(CHANGE_STATUS_GRADUATION_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(
          actionSuccess(CHANGE_STATUS_GRADUATION_PPDS_SUCCESS, res.data.data)
        );
        toastSuccess("Berhasil mengubah Status Kelulusan PPDS");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(CHANGE_STATUS_GRADUATION_PPDS_ERROR, error));
        toastError(error, "Gagal mengubah Status Kelulusan PPDS, ");
      });
  };
}

export function deletePpds(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_PPDS_PENDING));
    API.post(DELETE_PPDS_URL, { id: id }, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(DELETE_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus PPDS");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_PPDS_ERROR, error));
        toastError(error, "Gagal menghapus PPDS, ");
      });
  };
}
