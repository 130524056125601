import React, { Component } from "react";
import { Row } from "simple-flexbox";
import InputComponent from "../../components/form/InputComponent";
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import { getUser } from "../../helpers/user";
import ModalAddFinalPaper from "./Modal_Add_FinalPaper";

const styles = StyleSheet.create({
    container: {
        width: 150,
        marginLeft: 10,
    },
});
class HeaderFinalPaper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            modalAddFinalPaperShow: false
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.delayedQuery = this.delayedQuery.bind(this);
    }
    componentDidMount() { }

    delayedQuery = _.debounce((value) => {
        return this.props.handleRefresh({ search: value });
    }, 500);

    handleRefresh() {
        this.delayedQuery("");
    }

    handleSearchChange(e) {
        this.setState({ search: e.target.value });
        this.delayedQuery(e.target.value);
    }

    render() {
        const user = getUser().role_codes[0];
        return (
            <Row
                style={{ padding: 24, paddingBottom: 30, backgroundColor: "#F8F9FB" }}
                horizontal="space-between"
            >
                <ModalAddFinalPaper
                    show={this.state.modalAddFinalPaperShow}
                    onHide={() => this.setState({ modalAddFinalPaperShow: false })}
                    handleRefresh={this.handleRefresh}
                    // memberOptions={this.props.memberOptions}
                    // memberData={this.props.memberData}
                />
                {user === "admin_kolegium" &&
                    <Button
                        variant="contained"
                        color="primary"
                        className={css(styles.approveButton)}
                        startIcon={<AddIcon />}
                        onClick={() => this.setState({ modalAddFinalPaperShow: true })}
                    >
                        Tambah Final Paper
                    </Button>
                }
                <div className={css(styles.container)}>
                    <InputComponent
                        onChange={this.handleSearchChange}
                        value={this.state.tes}
                        placeholder="Cari Final Paper"
                        startIcon={SearchIcon}
                    />
                </div>
            </Row>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(HeaderFinalPaper);