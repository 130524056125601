import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_MEMBERSHIP_PENDING = "LIST_MEMBERSHIP_PENDING";
export const LIST_MEMBERSHIP_SUCCESS = "LIST_MEMBERSHIP_SUCCESS";
export const LIST_MEMBERSHIP_ERROR = "LIST_MEMBERSHIP_ERROR";

export const DETAIL_MEMBERSHIP_PENDING = "DETAIL_MEMBERSHIP_PENDING";
export const DETAIL_MEMBERSHIP_SUCCESS = "DETAIL_MEMBERSHIP_SUCCESS";
export const DETAIL_MEMBERSHIP_ERROR = "DETAIL_MEMBERSHIP_ERROR";

export const ADD_MEMBERSHIP_PENDING = "ADD_MEMBERSHIP_PENDING";
export const ADD_MEMBERSHIP_SUCCESS = "ADD_MEMBERSHIP_SUCCESS";
export const ADD_MEMBERSHIP_ERROR = "ADD_MEMBERSHIP_ERROR";

export const EDIT_MEMBERSHIP_PENDING = "EDIT_MEMBERSHIP_PENDING";
export const EDIT_MEMBERSHIP_SUCCESS = "EDIT_MEMBERSHIP_SUCCESS";
export const EDIT_MEMBERSHIP_ERROR = "EDIT_MEMBERSHIP_ERROR";

export const DELETE_MEMBERSHIP_PENDING = "DELETE_MEMBERSHIP_PENDING";
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS";
export const DELETE_MEMBERSHIP_ERROR = "DELETE_MEMBERSHIP_ERROR";

export const SET_SELECTED_MEMBERSHIP = "SET_SELECTED_MEMBERSHIP";

const LIST_MEMBERSHIP_URL = "cooperative_membership/find";
const EDIT_MEMBERSHIP_URL = "cooperative_membership/edit";
const DELETE_MEMBERSHIP_URL = "cooperative_membership/delete";
const ADD_MEMBERSHIP_URL = "cooperative_membership/add";

export function listMembership(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_MEMBERSHIP_PENDING));
    API.post(LIST_MEMBERSHIP_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_MEMBERSHIP_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_MEMBERSHIP_ERROR, error));
        toastError(error, "Gagal mendapatkan list MEMBERSHIP, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addMembership(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_MEMBERSHIP_PENDING));
    API.post(ADD_MEMBERSHIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_MEMBERSHIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambah membership");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_MEMBERSHIP_ERROR, error));
        toastError(error, "Gagal menambahkan MEMBERSHIP, ");
      });
  };
}

export function detailMembership(id = null, callback) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_MEMBERSHIP_PENDING));
    API.post(LIST_MEMBERSHIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_MEMBERSHIP_SUCCESS, res.data.data));
        if (callback) {
          callback()
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_MEMBERSHIP_ERROR, error));
        toastError(error, "Gagal mendapatkan list MEMBERSHIP, ");
      });
  };
}

export function editMembership(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_MEMBERSHIP_PENDING));
    API.post(EDIT_MEMBERSHIP_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_MEMBERSHIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah MEMBERSHIP");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_MEMBERSHIP_ERROR, error));
        toastError(error, "Gagal mengubah MEMBERSHIP, ");
      });
  };
}

export function deleteMembership(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_MEMBERSHIP_PENDING));
    API.post(DELETE_MEMBERSHIP_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_MEMBERSHIP_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus MEMBERSHIP");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_MEMBERSHIP_ERROR, error));
        toastError(error, "Gagal menghapus MEMBERSHIP, ");
      });
  };
}

export function setSelectedMembership(value) {
  return {
    type: SET_SELECTED_MEMBERSHIP,
    value: value,
  };
}