import { NavLink } from "react-router-dom";
import { Chart } from "chart.js";
import React, { useState, useRef, useEffect } from 'react';
import _uniqueId from 'lodash/uniqueId';

const LineChart = ({
    height = 250,
    labels = ["Jan", "Feb", "March"],
    options = {
        yTickDisplay: true,
    },
    type = 'kolegium',
    datasets = [
        {
            label: "Sales",
            data: [86, 67, 91],
            fill: false,
        }
    ],
}) => {

    const chartRef = useRef(null)
    const [id] = useState(_uniqueId('linechart-'));


    useEffect(() => {
        const myChartRef = chartRef.current.getContext("2d");

        // const {height: height} = myChartRef.canvas;

        datasets.forEach(elem => {
            if (elem.fill) {

                let bgStartColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, '.6)') : "rgba(0, 166, 207, .6)";
                let bgStopColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, '0)') : "rgba(0, 166, 207, 0)";

                let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height);
                bgGradient.addColorStop(0, bgStartColor);
                bgGradient.addColorStop(1, bgStopColor);

                elem.backgroundColor = bgGradient;
            }
        });

        if (window.growth != undefined)
            window.growth.destroy();



        window.growth = new Chart(myChartRef, {
            type: "line",
            data: {
                //Bring in data
                labels: labels,
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'bottom',
                    align: 'start',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            display: "yTickDisplay" in options ? options.yTickDisplay : true,
                            stepSize: 10,
                        },
                        gridLines: {
                            display: "yGridDisplay" in options ? options.yGridDisplay : true,
                            // drawOnChartArea: "xdrawOnChartArea" in options ? options.xdrawOnChartArea : true,
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            display: "xTickDisplay" in options ? options.xTickDisplay : true,
                            stepSize: 10,
                        },
                        gridLines: {
                            display: "xGridDisplay" in options ? options.xGridDisplay : true,
                            // drawOnChartArea: "ydrawOnChartArea" in options ? options.ydrawOnChartArea : true,
                        },
                    }],
                }
            }
        });
    });

    return (
        <div style={{ height: height + 'px' }}>
            <canvas
                id={id}
                ref={chartRef}
            />
        </div>
    );
};

export default LineChart;
