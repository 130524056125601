import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addStrKT, listStrKT } from "../../actions/p2kb-kt/str_kt_action";
import {
    renderInput,
    renderSelect,
    renderDatepicker,
    renderFile,
} from "../../components/form/FormComponent";
import {
    getStrKTPending,
    getListStrKT
} from "../../reducers/p2kb-kt/str_kt_reducer";
import {
    getStrPending,
    getListStr,
    getStrError,
} from "../../reducers/p2kb/str_reducer";
import { listStr } from "../../actions/p2kb/str_action";
import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";

class ModalStrKTAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strOptions: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSTRChange = this.handleSTRChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && this.props.show === true) {
            this.props.reset();
        }
    }

    handleSTRChange(e) {
        const { listStr_data, listStr, change } = this.props;
        if (e) {
            const value = e.value;
            if (value !== undefined && value !== null && value !== "") {
                const callback = () => {
                    if (listStr_data) {
                        let idx = listStr_data.data.map(function (e) { return e.id; }).indexOf(value);
                        change("start_date", listStr_data.data[idx].start_date);
                        change("end_date", listStr_data.data[idx].end_date);
                    }
                }
                listStr({}, callback);
            }
        }
    }

    handleSubmit(values) {
        const { addStrKT, onHide, handleRefresh } = this.props;
        const param = {
            str_no: values.str_no.label,
            str_kt_no: values.str_kt_no,
            file: values.berkas,
        };
        const callback = () => {
            onHide();
            handleRefresh();
        };
        addStrKT(param, callback);
    }

    render() {
        const {
            handleSubmit,
            show,
            onHide,
            addStrKT_pending
        } = this.props;
        if (this.state.strOptions === '' && this.props.strOptions.length > 0) {
            this.setState({
                strOptions: this.props.strOptions
            })
        }
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-width"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah STR KT</p>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={handleSubmit(this.handleSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Column
                            vertical="center"
                            horizontal="stretch"
                            className={css(styles.bodyModal)}
                        >
                            <>
                                <LabelInputVerticalComponent
                                    label="Nomor STR"
                                    marginTop="20px"
                                ></LabelInputVerticalComponent>
                                <Field
                                    name="str_no"
                                    type="text"
                                    onChange={this.handleSTRChange}
                                    placeholder="Pilih STR"
                                    options={this.state.strOptions}
                                    component={renderSelect}
                                />
                                <LabelInputVerticalComponent
                                    label="Nomor STR KT"
                                    marginTop="20px"
                                ></LabelInputVerticalComponent>
                                <Field
                                    name="str_kt_no"
                                    type="text"
                                    placeholder="Masukkan Nomor STR KT"
                                    component={renderInput}
                                />

                                <Row horizontal="space-between">
                                    <div>
                                        <LabelInputVerticalComponent
                                            label="Tanggal Pengesahan"
                                            marginTop="20px"
                                        ></LabelInputVerticalComponent>
                                        <Field
                                            name="start_date"
                                            type="text"
                                            disabled={true}
                                            component={renderDatepicker}
                                        />
                                    </div>

                                    <div>
                                        <LabelInputVerticalComponent
                                            label="Berlaku Sampai"
                                            marginTop="20px"
                                        ></LabelInputVerticalComponent>
                                        <Field
                                            name="end_date"
                                            type="text"
                                            disabled={true}
                                            component={renderDatepicker}
                                        />
                                    </div>
                                </Row>

                                <Field
                                    name="berkas"
                                    type="file"
                                    fileType="pdf/image"
                                    title="upload berkas"
                                    message="PDF atau Image (Max File 1 MB)"
                                    component={renderFile}
                                />
                            </>
                        </Column>
                        <Row
                            horizontal="flex-end"
                            vertical="center"
                            className={css(styles.footerModal)}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={addStrKT_pending}
                                className={css(styles.approveButton)}
                                startIcon={
                                    addStrKT_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                        <CheckIcon />
                                    )
                                }
                            >
                                simpan
                            </Button>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
    Selectcontainer: { width: "190px" },
});

function validate(values) {
    const maxFileSize = 1000000; //1MB
    const typeImage = /image/
    const fileType = "application/pdf"
    const { str_no, str_kt_no, start_date, end_date, berkas } = values;
    const errors = {};
    if (!str_no) {
        errors.str_no = "Nomor STR tidak boleh kosong";
    }
    if (!str_kt_no) {
        errors.str_kt_no = "Nomor STR KT tidak boleh kosong";
    }
    if (!start_date) {
        errors.start_date = "Tanggal Pengesahan STR tidak boleh kosong";
    }
    if (!end_date) {
        errors.end_date = "Tanggal Akhir STR tidak boleh kosong";
    }
    if (!berkas) {
        errors.berkas = "Berkas Wajib Disertakan";
    }
    if (values.berkas && (!values.berkas.type.match(typeImage) && values.berkas.type !== fileType)){
        errors.berkas = "Format file hanya bisa PDF atau Image"
    }
    else if (values.berkas && (values.berkas.size > maxFileSize)){
        errors.berkas = "File lebih dari 1 MB"
    }
    return errors;
}

ModalStrKTAdd = reduxForm({
    // a unique name for the form
    form: "ModalStrKTAdd",
    validate: validate,
    // keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    shouldError: () => true,
})(ModalStrKTAdd);

function mapStateToProps({ addStrKT, listStrKT, listStr }) {
    return {
        addStrKT_pending: getStrKTPending(addStrKT),
        listStrKT_data: getListStrKT(listStrKT),
        listStr_data: getListStr(listStr),
        listStr_error: getStrError(listStr),
        listStr_pending: getStrPending(listStr)
    };
}

export default connect(mapStateToProps, {
    addStrKT,
    listStrKT,
    listStr
})(withRouter(ModalStrKTAdd));
