import {
  LIST_PRACTICE_PENDING,
  LIST_PRACTICE_SUCCESS,
  LIST_PRACTICE_ERROR,
  DETAIL_PRACTICE_PENDING,
  DETAIL_PRACTICE_SUCCESS,
  DETAIL_PRACTICE_ERROR,
  EDIT_PRACTICE_PENDING,
  EDIT_PRACTICE_SUCCESS,
  EDIT_PRACTICE_ERROR,
  ADD_PRACTICE_PENDING,
  ADD_PRACTICE_SUCCESS,
  ADD_PRACTICE_ERROR,
  SET_IS_EDIT_FILE,
  CHANGE_STATUS_PRACTICE_PENDING,
  CHANGE_STATUS_PRACTICE_SUCCESS,
  CHANGE_STATUS_PRACTICE_ERROR,
} from "../../actions/sub-menu/practice_action";

const initialState = {
  pending: false,
  error: null,
};

export function listPractice(
  state = { ...initialState, list_practice: null },
  action
) {
  switch (action.type) {
    case LIST_PRACTICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
        list_practice: action.data,
      };
    case LIST_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailPractice(
  state = { ...initialState, detail_practice: null },
  action
) {
  switch (action.type) {
    case DETAIL_PRACTICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_practice: action.data,
      };
    case DETAIL_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editPractice(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_PRACTICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addPractice(state = initialState, action) {
  switch (action.type) {
    case ADD_PRACTICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function changeStatusPractice(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STATUS_PRACTICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_STATUS_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case CHANGE_STATUS_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListPractice = (state) => state.list_practice;
export const getDetailPractice = (state) => state.detail_practice;
export const getPracticePending = (state) => state.pending;
export const getPracticeError = (state) => state.error;
export const getPracticeIsEditFile = (state) => state.isEditFile;
