const maps = [
    {
        "name": "ACEH",
        "province_id": "11",
        "dot": [10, 16]
    },
    {
        "name": "SUMATERA UTARA",
        "province_id": "12",
        "dot": [46, 58]
    },
    {
        "name": "SUMATERA BARAT",
        "province_id": "13",
        "dot": [100, 79]
    },
    {
        "name": "RIAU",
        "province_id": "14",
        "dot": [83, 100]
    },
    {
        "name": "JAMBI",
        "province_id": "15",
        "dot": [109,116]
    },
    {
        "name": "SUMATERA SELATAN",
        "province_id": "16",
        "dot": [139, 138]
    },
    {
        "name": "BENGKULU",
        "province_id": "17",
        "dot": [144,102]
    },
    {
        "name": "LAMPUNG",
        "province_id": "18",
        "dot": [161,152]
    },
    {
        "name": "KEPULAUAN BANGKA BELITUNG",
        "province_id": "19",
        "dot": [121,174]
    },
    {
        "name": "KEPULAUAN RIAU",
        "province_id": "21",
        "dot": [73, 143]
    },
    {
        "name": "DKI JAKARTA",
        "province_id": "31",
        "dot": [180, 178]
    },
    {
        "name": "JAWA BARAT",
        "province_id": "32",
        "dot": [194, 189]
    },
    {
        "name": "JAWA TENGAH",
        "province_id": "33",
        "dot": [199, 231]
    },
    {
        "name": "DI YOGYAKARTA",
        "province_id": "34",
        "dot": [209, 236]
    },
    {
        "name": "JAWA TIMUR",
        "province_id": "35",
        "dot": [206, 270]
    },
    {
        "name": "BANTEN",
        "province_id": "36",
        "dot": [185, 168]
    },
    {
        "name": "BALI",
        "province_id": "51",
        "dot": [215, 308]
    },
    {
        "name": "NUSA TENGGARA BARAT",
        "province_id": "52",
        "dot": [218, 342]
    },
    {
        "name": "NUSA TENGGARA TIMUR",
        "province_id": "53",
        "dot": [218, 419]
    },
    {
        "name": "KALIMANTAN BARAT",
        "province_id": "61",
        "dot": [90, 248]
    },
    {
        "name": "KALIMANTAN TENGAH",
        "province_id": "62",
        "dot": [109, 282]
    },
    {
        "name": "KALIMANTAN SELATAN",
        "province_id": "63",
        "dot": [132, 313]
    },
    {
        "name": "KALIMANTAN TIMUR",
        "province_id": "64",
        "dot": [74, 328]
    },
    {
        "name": "KALIMANTAN UTARA",
        "province_id": "65",
        "dot": [45, 342]
    },
    {
        "name": "SULAWESI UTARA",
        "province_id": "71",
        "dot": [73, 453]
    },
    {
        "name": "SULAWESI TENGAH",
        "province_id": "72",
        "dot": [109, 409]
    },
    {
        "name": "SULAWESI SELATAN",
        "province_id": "73",
        "dot": [150, 380]
    },
    {
        "name": "SULAWESI TENGGARA",
        "province_id": "74",
        "dot": [150, 414]
    },
    {
        "name": "GORONTALO",
        "province_id": "75",
        "dot": [73, 426]
    },
    {
        "name": "SULAWESI BARAT",
        "province_id": "76",
        "dot": [119, 373]
    },
    {
        "name": "MALUKU",
        "province_id": "81",
        "dot": [142, 521]
    },
    {
        "name": "MALUKU UTARA",
        "province_id": "82",
        "dot": [78,517]
    },
    {
        "name": "PAPUA BARAT",
        "province_id": "91",
        "dot": [110, 593]
    },
    {
        "name": "PAPUA",
        "province_id": "94",
        "dot": [143, 670]
    }
]

export default maps