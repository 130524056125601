/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { StyleSheet, css } from "aphrodite";
import { Column, Row } from "simple-flexbox";
import { connect } from "react-redux";

import AvatarButtonComponent from "../../components/profile/AvatarButtonComponent";
import AvatarPPDSComponent from "../../components/profile/AvatarPPDSComponent";
import NameComponent from "../../components/profile/NameComponent";
import TitleComponent from "../../components/profile/TitleComponent";
import StatusComponent from "../../components/profile/StatusComponent";
import IconCV from "../../assets/icon-downloadcv";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getDetailMember,
  getMemberPending,
} from "../../reducers/member/member_reducer";
import { number } from "prop-types";
import { detailMember } from "../../actions/member/member_action";
import ModalEditBio from "./Modal_edit_bio_member";
import { default_api } from "../../constants/default_api";

class ProfileAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalEditBioShow: false,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  unduhCV(id) {
    window.open(default_api + "/member/download/cv/" + id + ".pdf");
  }

  handleRefresh() {
    window.location.reload();
  }

  render() {
    let kolegium_no, young_member_id, name, id, title, status_active, profile_img;

    const { detailMember_data } = this.props;

    if (detailMember_data && detailMember_data.length > 0) {
      [
        {
          name,
          status_active,
          profile_img,
          kolegium_no,
          young_member_id,
          id,
        },
      ] = detailMember_data;
    }


    return (
      <Column style={{ width: "100%" }} horizontal="center">
        <div
          style={{
            width: 100,
            height: 100,
            position: "relative",
          }}
        >
          <ModalEditBio
            show={this.state.ModalEditBioShow}
            onHide={() => this.setState({ ModalEditBioShow: false })}
            handleRefresh={this.handleRefresh}
            id={id}
            name={name}
            img_profile={profile_img}
          />
          <AvatarPPDSComponent image={profile_img} />
          <AvatarButtonComponent
            style={{ position: "absolute", top: 70, left: 70 }}
            button={({ onClick }) => (
              <Row onClick={onClick} className={css(styles.clickableIcon)}>
                <IconCV />
              </Row>
            )}
          >
            <MenuItem onClick={() => this.setState({ ModalEditBioShow: true })}>
              Edit
            </MenuItem>
          </AvatarButtonComponent>
        </div>
        <NameComponent name={name} />
        <TitleComponent title={title} />
        <StatusComponent paboiId={young_member_id} status={status_active} />
      </Column>
    );
  }
}

const styles = StyleSheet.create({
  clickableIcon: {
    ":hover": {
      cursor: "pointer",
    },
  },
});

function mapStateToProps({ detailMember }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
  };
}

export default connect(mapStateToProps)(ProfileAvatar);
