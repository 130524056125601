import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { addAdvertisement } from "../../actions/advertisement/advertisement_action";
import {
    getAdvertisementAdd,
    getAdvertisementAddPending,
    getAdvertisementAddError
} from "../../reducers/advertisement/advertisement_reducer";
import MUIRichTextEditor from "mui-rte";
import InputComponent from "../../components/form/InputComponent";
import draftToHtml from "draftjs-to-html";
import FileComponent from "../../components/form/FileComponent";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalBannerPreview from "./Modal_banner_preview";

class ModalAddAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_text: "",
            errors: {},
            fields: {},
            start_period: new Date(),
            end_period: new Date(),
            banner_preview: null,
            modalBannerPreviewShow: false
        }
        this.tableRef = React.createRef();
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBerkasChange = this.handleBerkasChange.bind(this);
        this.handleStartPeriod = this.handleStartPeriod.bind(this);
        this.handleEndPeriod = this.handleEndPeriod.bind(this);
        this.handleOpenBanner = this.handleOpenBanner.bind(this);
    }

    handleClick() {
        this.tableRef.current && this.tableRef.current.save();
    }

    handleEditorChange(text) {
        if (text && this.state.errors.editor) {
            this.setState(({ errors }) => ({ errors: { ...errors, editor: false } }));
        }
    }

    handleValidation(editor) {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!editor) {
            formIsValid = false;
            errors["editor"] = "Deskripsi tidak boleh kosong";
        }
        if (!fields.title) {
            formIsValid = false;
            errors["title"] = "Judul Iklan tidak boleh kosong";
        }
        if (!fields.berkas) {
            formIsValid = false;
            errors["berkas"] = "Banner tidak boleh kosong";
        }
        if (!fields.instance_name) {
            formIsValid = false;
            errors["instance_name"] = "Nama Instansi tidak boleh kosong";
        }
        if (!fields.start_period) {
            formIsValid = false;
            errors["start_period"] = "Periode awal tidak boleh kosong";
        }
        if (!fields.end_period) {
            formIsValid = false;
            errors["end_period"] = "Periode akhir tidak boleh kosong";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleBerkasChange(e) {
        const value = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = function (e) {
            this.setState({ banner_preview: [reader.result] })
        }.bind(this);
        reader.readAsDataURL(value);
        this.setState(({ fields }) => {
            return {
                fields: {
                    ...fields,
                    berkas: value,
                },
            };
        });
        this.setState(({ errors }) => {
            return {
                errors: {
                    ...errors,
                    berkas: null,
                },
            };
        });
    }

    handleOpenBanner(imgUrl, modal_title) {
        this.setState({ imgUrl: imgUrl })
        this.setState({ modal_title: modal_title })
        this.setState({ modalBannerPreviewShow: true })
    }

    validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    handleInputChange(e) {
        let value = e.target.value;
        const formName = e.target.name;
        if (formName !== "url") {
            this.setState(({ fields }) => {
                return {
                    fields: {
                        ...fields,
                        [formName]: value,
                    },
                };
            });
            if (value) {
                this.setState(({ errors }) => {
                    return {
                        errors: {
                            ...errors,
                            [formName]: null,
                        },
                    };
                });
            }
        } else {
            if (!this.validateUrl(value)) {
                value = "http://" + value;
                if (!this.validateUrl(value)) {
                    let errors = { ...this.state.errors };
                    const formIsValid = false;
                    errors["url"] = "Format URL harus sesuai, contoh: admin.paboi.co.id, google.com";
                    this.setState({ errors: errors });
                    return formIsValid;
                }
            }
            this.setState(({ fields }) => {
                return {
                    fields: {
                        ...fields,
                        [formName]: value,
                    },
                };
            });
            if (value) {
                this.setState(({ errors }) => {
                    return {
                        errors: {
                            ...errors,
                            [formName]: null,
                        },
                    };
                });
            }
        }
    }

    handleStartPeriod(date) {
        this.setState(({ fields }) => {
            return {
                fields: {
                    ...fields,
                    start_period: date,
                },
            };
        });
        if (date) {
            this.setState(({ errors }) => {
                return {
                    errors: {
                        ...errors,
                        start_period: null,
                    },
                };
            });
        }
        this.setState({ start_period: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") })
    }

    handleEndPeriod(date) {
        this.setState(({ fields }) => {
            return {
                fields: {
                    ...fields,
                    end_period: date,
                },
            };
        });
        if (date) {
            this.setState(({ errors }) => {
                return {
                    errors: {
                        ...errors,
                        end_period: null,
                    },
                };
            });
        }
        this.setState({ end_period: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") })
    }

    handleSave(data) {
        const { addAdvertisement, handleRefresh, onHide } = this.props;
        const { fields } = this.state;
        let formData = new FormData();
        data = JSON.parse(data);
        const result = draftToHtml(data);
        const callback = () => {
            onHide();
            handleRefresh();
        }
        if (this.handleValidation(data.blocks[0].text)) {
            formData.append("title", fields.title);
            formData.append("description", result);
            formData.append("start_period", fields.start_period);
            formData.append("end_period", fields.end_period);
            formData.append("instance_name", fields.instance_name);
            formData.append("banner_file", fields.berkas);
            if (fields.url && fields.url !== "") {
                formData.append("url", fields.url);
            }
            addAdvertisement(formData, callback);
        }
    }

    handleRefresh(value) {
        this.props.memberList({ search_text: value });
    }

    render() {
        const {
            show,
            onHide,
            addAdvertisement_pending,
        } = this.props;
        return (
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-width"
            >
                <ModalBannerPreview
                    show={this.state.modalBannerPreviewShow}
                    onHide={() => this.setState({ modalBannerPreviewShow: false })}
                    imgUrl={this.state.imgUrl}
                    modal_title={this.state.modal_title}
                />
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah Iklan</p>
                </Modal.Header>
                <Modal.Body>
                    <Column
                        vertical="center"
                        horizontal="stretch"
                        className={css(styles.bodyModal)}
                    >
                        <>
                            <label style={{ marginBottom: 10 }}>Nama Instansi</label>
                            <InputComponent name="instance_name" placeholder="Masukkan Nama Instansi" onChange={this.handleInputChange} />
                            <div
                                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                            >
                                {this.state.errors.instance_name}
                            </div>
                            <label style={{ marginBottom: 10 }}>Judul Iklan</label>
                            <InputComponent name="title" placeholder="Masukkan Judul Iklan" onChange={this.handleInputChange} />
                            <div
                                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                            >
                                {this.state.errors.title}
                            </div>
                            <label style={{ marginBottom: 10 }}>URL</label>
                            <InputComponent name="url" placeholder="ex: admin.paboi.co.id" onChange={this.handleInputChange} />
                            <div
                                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                            >
                                {this.state.errors.url}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15 }}>
                                <div style={{ width: 210 }}>
                                    <label style={{ marginTop: 5, marginBottom: 0, display: "block" }}>Periode Awal</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            value={this.state.start_period}
                                            onChange={this.handleStartPeriod}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div
                                        style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                                    >
                                        {this.state.errors.start_period}
                                    </div>
                                </div>
                                <div style={{ width: 210 }}>
                                    <label style={{ marginTop: 5, marginBottom: 0, display: "block" }}>Periode Akhir</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            value={this.state.end_period}
                                            minDate={this.state.start_period}
                                            minDateMessage="Periode akhir harus lebih dari periode awal"
                                            onChange={this.handleEndPeriod}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <div
                                        style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                                    >
                                        {this.state.errors.end_period}
                                    </div>
                                </div>
                            </div>
                            <label style={{ marginTop: 5, marginBottom: 0 }}>Deskripsi</label>
                            <MUIRichTextEditor
                                onChange={(event) =>
                                    this.handleEditorChange(
                                        event.getCurrentContent().getPlainText()
                                    )
                                }
                                onSave={this.handleSave}
                                ref={this.tableRef}
                                label="Masukkan Deskripsi"
                                controls={[
                                    "title",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "undo",
                                    "redo",
                                    "clear",
                                ]}
                                maxLength={1000}
                            />
                            <div
                                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                            >
                                {this.state.errors.editor}
                            </div>
                            <FileComponent
                                type="file"
                                fileType="image"
                                onChange={this.handleBerkasChange}
                                title="upload banner"
                                message={this.state.banner_preview ? <DetailContentLihatBerkas label="Preview Iklan" onClick={() => this.handleOpenBanner(this.state.banner_preview, "Preview Iklan")} /> : "Ratio Banner 16:4"}
                                value={this.state.fields.berkas}
                            />
                            <div
                                style={{ color: "#ED2A2A", paddingTop: 5, paddingBottom: 5 }}
                            >
                                {this.state.errors.berkas}
                            </div>
                        </>

                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                className={css(styles.approveButton)}
                                onClick={this.handleClick}
                                startIcon={
                                    addAdvertisement_pending ? (
                                        <CircularProgress size={14} color="secondary" />
                                    ) : (
                                            <CheckIcon />
                                        )
                                }
                            >
                                Simpan
                            </Button>
                        </>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    footerModal: {
        backgroundColor: "#FBFBFD",
        padding: "20px 40px",
    },
    bodyModal: {
        padding: "0px 40px 20px",
        width: "100%",
    },
});

function mapStateToProps({ addAdvertisement }) {
    return {
        addAdvertisement_pending: getAdvertisementAddPending(addAdvertisement),
        addAdvertisement_data: getAdvertisementAdd(addAdvertisement),
        addAdvertisement_error: getAdvertisementAddError(addAdvertisement),
    };
}

export default connect(mapStateToProps, {
    addAdvertisement
})(withRouter(ModalAddAdvertisement));
