import React, { Component } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import ContentContainer from "../../components/container/ContainerComponent";
import DownloadIcon from "../../assets/icon-download";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import moment from "moment";
import localization from "moment/locale/id";
import MasterReportHeader from "./MasterReportHeader";
import MasterReportUpload from "./MasterReportUpload";
import { masterReport } from "../../actions/report/report_action";
import { getMasterReport } from "../../reducers/report/report_reducer";
import { default_api } from "../../constants/default_api";


class MasterIuran extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            showModalAdd: false
        };
    }

    handleRefresh = (state) => {
        if (state) {
            this.setState(state);
        }
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleShowModalAdd = () => {
        this.setState({ showModalAdd: true });
    }

    render() {
        const { masterReport, masterReport_data } = this.props
        const { showModalAdd } = this.state;
        let data = [];
        let records_total = 0;
        let lastUpdate = '';
        if (masterReport_data) {
            records_total = masterReport_data.records_total;
            lastUpdate = moment(masterReport_data.data[0]?.created_at).locale("id", localization).format("DD MMMM YYYY");
        }

        return (
            <ContentContainer>
                <MasterReportUpload
                    show={showModalAdd}
                    onHide={() => this.setState({ showModalAdd: false })}
                    handleRefresh={this.handleRefresh}
                />
                <MasterReportHeader
                    lastUpdate={lastUpdate}
                    handleRefresh={this.handleRefresh}
                    handleShowModalAdd={this.handleShowModalAdd}
                />
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={[
                        {
                            title: "No",
                            field: "no",
                        },
                        {
                            title: "Nama File",
                            field: "name_converted",
                            render: (data) => {
                                let converted_name = data.name_converted.split('/');
                                return converted_name[4];
                            }
                        },
                        {
                            title: "Tanggal Unggah",
                            render: (data) => {
                                return moment(data.created_at).locale("id", localization).format("DD MMMM YYYY");
                            },
                            width: 250,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },
                        {
                            title: "Waktu Unggah",
                            render: (data) => {
                                return moment(data.created_at).locale("id", localization).format("HH:mm");
                            },
                            width: 250,
                            headerStyle: {
                                paddingLeft: 40,
                            },
                            cellStyle: {
                                paddingLeft: 40,
                            },
                        },

                        {
                            title: "",
                            render: (rowData) => {
                                return (
                                    <DetailButtonComponent>
                                        <MenuItem>
                                            <a
                                                href={`${default_api}${rowData.name_converted}`}
                                                style={{ textDecoration: "none", color: '#000000' }}
                                                target="_blank" rel="noopener noreferrer">
                                                <DownloadIcon fill="#000000" /> Unduh Data
                                            </a>
                                        </MenuItem>
                                    </DetailButtonComponent>
                                );
                            },
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            let param = {
                                page: query.page + 1,
                                length: 10,
                            };
                            masterReport(param, resolve, reject);
                        })
                    }
                    options={{
                        pageSize: 10,
                        paginationType: "stepped",
                        pageSizeOptions: [],
                        headerStyle: {
                            backgroundColor: "#F8F9FB",
                        },
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "Tidak ada data",
                        },
                    }}
                    components={{
                        Toolbar: () => <div />,
                        Container: (props) => <Paper {...props} elevation={0} />,
                        Pagination: (props) => (
                            <PaginationComponent
                                records={data?.length}
                                records_total={records_total}
                                {...props}
                            />
                        ),
                    }}
                />
            </ContentContainer>
        );
    }
}

function mapStateToProps({ masterReport }) {
    return {
        masterReport_data: getMasterReport(masterReport)
    }
}

export default connect(mapStateToProps, { masterReport })(MasterIuran);
