import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_SUPPORT_PENDING = "LIST_SUPPORT_PENDING";
export const LIST_SUPPORT_SUCCESS = "LIST_SUPPORT_SUCCESS";
export const LIST_SUPPORT_ERROR = "LIST_SUPPORT_ERROR";

export const DETAIL_SUPPORT_PENDING = "DETAIL_SUPPORT_PENDING";
export const DETAIL_SUPPORT_SUCCESS = "DETAIL_SUPPORT_SUCCESS";
export const DETAIL_SUPPORT_ERROR = "DETAIL_SUPPORT_ERROR";

export const ADD_SUPPORT_PENDING = "ADD_SUPPORT_PENDING";
export const ADD_SUPPORT_SUCCESS = "ADD_SUPPORT_SUCCESS";
export const ADD_SUPPORT_ERROR = "ADD_SUPPORT_ERROR";

export const EDIT_SUPPORT_PENDING = "EDIT_SUPPORT_PENDING";
export const EDIT_SUPPORT_SUCCESS = "EDIT_SUPPORT_SUCCESS";
export const EDIT_SUPPORT_ERROR = "EDIT_SUPPORT_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_SUPPORT";
export const APPROVE_SUPPORT_PENDING = "APPROVE_SUPPORT_PENDING";
export const APPROVE_SUPPORT_SUCCESS = "APPROVE_SUPPORT_SUCCESS";
export const APPROVE_SUPPORT_ERROR = "APPROVE_SUPPORT_ERROR";

const LIST_SUPPORT_URL = "member/support/find";
const EDIT_SUPPORT_URL = "member/support/edit";
const ADD_SUPPORT_URL = "member/support/add";
const APPROVE_SUPPORT_URL = "member/support/finish";
const PROCESS_SUPPORT_URL = "member/support/process";
const FORWARD_SUPPORT_URL = "member/support/forward";
const UPLOAD_SUPPORT_URL = "image/upload/member_support";

export function listSupport(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SUPPORT_PENDING));
    API.post(LIST_SUPPORT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SUPPORT_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_SUPPORT_ERROR, error));
        toastError(error, "Gagal mendapatkan list support, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addSupport({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SUPPORT_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_SUPPORT_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;

        return API.post(ADD_SUPPORT_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_SUPPORT_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan support");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SUPPORT_ERROR, error));
        toastError(error, "Gagal menambahkan support, ");
      });
  };
}

export function editSupport({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SUPPORT_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_SUPPORT_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_SUPPORT_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_SUPPORT_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah support");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SUPPORT_ERROR, error));
        toastError(error, "Gagal mengubah support, ");
      });
  };
}

export function detailSupport(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SUPPORT_PENDING));
    API.post(LIST_SUPPORT_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SUPPORT_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SUPPORT_ERROR, error));
        toastError(error, "Gagal mendapatkan list support, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveSupport({ type, file, ...param }, callback) {
  console.log(type)
  let URL = APPROVE_SUPPORT_URL;
  if (type === "process") {
    URL = PROCESS_SUPPORT_URL;
  } else if (type === "forward") {
    URL = FORWARD_SUPPORT_URL;
  }
  return (dispatch) => {
    dispatch(actionPending(APPROVE_SUPPORT_PENDING));
    if (type === 'solve') {
      var formData = new FormData();
      formData.append("file", file);
      API.post(UPLOAD_SUPPORT_URL, formData, getHeader())
        .then((res) => {
          return res.data.filename;
        })
        .then((filename) => {
          param.filename = filename;
          return API.post(URL, param, getHeader())
        })
        .then((res) => {
          dispatch(actionSuccess(APPROVE_SUPPORT_SUCCESS, res.data.data));
          toastSuccess("Berhasil mengubah status support");
          callback();
          return res;
        })
        .catch((error) => {
          dispatch(actionError(APPROVE_SUPPORT_ERROR, error));
          toastError(error, "Gagal mengubah status support, ");
        });
    }
    else {
      API.post(URL, param, getHeader())
        .then((res) => {
          dispatch(actionSuccess(APPROVE_SUPPORT_SUCCESS, res.data.data));
          toastSuccess("Berhasil mengubah status support");
          callback();
          return res;
        })
        .catch((error) => {
          dispatch(actionError(APPROVE_SUPPORT_ERROR, error));
          toastError(error, "Gagal mengubah status support, ");
        });
    }
  }
}
