import React, { Component } from "react";
import { connect } from "react-redux";
import { listSerkomAdmin } from "../../actions/serkom/serkomAdmin_action";
import {
  getListSerkomAdmin,
  getSerkomAdminError,
  getSerkomAdminPending,
} from "../../reducers/serkom/serkomAdmin_reducer";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import SerkomHeader from "./SerkomHeader";
import SerkomModalAdd from "./SerkomModalAdd";
import SerkomModalDelete from "./SerkomModalDelete";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";

class Serkom extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: null,
      search_text: "",
      status: null,
      showModalAdd: false,
      showModalDelete: false,
    };
    localStorage.removeItem("isSerkom");
    localStorage.removeItem("id_member");
    localStorage.removeItem("id_serkom");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this);
    this.detailRecord = this.detailRecord.bind(this);
    this.handleOpenModalDelete = this.handleOpenModalDelete.bind(this);
  }

  onchange(e) {
    this.setState({ input: e.target.value });
  }

  handleRefresh(state) {
    if (state) {
      this.setState(state);
    }
    this.setState({ id: null });
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/admin/serkom/detail/${id}`);
  }
  handleOpenModalDelete(id) {
    this.setState({ id, modalDeleteShow: true });
  }

  handleShowModalAdd() {
    this.setState({ showModalAdd: true });
  }

  componentDidMount() {
    if (this.props.match.params.id && this.state.id === null) {
      this.setState({ id: this.props.match.params.id });
    }
  }

  render() {
    const { listSerkomAdmin, listSerkom_data } = this.props;
    const { search_text, showModalAdd, modalDeleteShow, id } = this.state;
    let data = [];
    let records_total = 0;
    if (listSerkom_data) {
      data = listSerkom_data.data;
      records_total = listSerkom_data.records_total;
    }
    console.log(listSerkom_data);
    return (
      <ContentContainer>
        <SerkomHeader
          handleRefresh={this.handleRefresh}
          handleShowModalAdd={this.handleShowModalAdd}
        />
        <SerkomModalAdd
          show={showModalAdd}
          onHide={() => this.setState({ showModalAdd: false })}
          handleRefresh={this.handleRefresh}
        />
        <SerkomModalDelete
          id={id}
          show={modalDeleteShow}
          onHide={() => this.setState({ modalDeleteShow: false })}
          handleRefresh={this.handleRefresh}
        />
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: "No",
              field: "no",
              width: 20,
              headerStyle: {
                paddingLeft: 40,
              },
              cellStyle: {
                paddingLeft: 40,
              },
            },
            {
              title: "Tanggal Pengajuan",
              field: "date",
              render: ({ date }) => {
                return moment(date)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            { title: "Nomor Surat", field: "reference_number" },
            { title: "Lulusan", field: "serkom_members.length" },
            {
              title: "Status",
              field: "is_redeem",
              render: ({ status }) => {
                return (
                  <TableStatusComponent
                    status={status === 0 ? "Menunggu" : "Terverifikasi"}
                  />
                );
              },
            },
            ...(getUser().role_codes[0] !== "ketua_kolegium"
              ? [
                  {
                    title: "User",
                    field: "role_next_approve",
                    render: ({ role_next_approve }) =>
                      role_next_approve
                        ? role_next_approve.role_name
                        : "Ketua Kolegium",
                  },
                ]
              : []),
            {
              title: "",
              render: ({ id }) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem onClick={() => this.detailRecord(id)}>
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: search_text,
                status: this.state.status,
                id: this.state.id,
              };
              listSerkomAdmin(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <PaginationComponent
                records={data.length}
                records_total={records_total}
                {...props}
              />
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ listSerkomAdmin }) {
  return {
    listSerkom_data: getListSerkomAdmin(listSerkomAdmin),
    listSerkom_error: getSerkomAdminError(listSerkomAdmin),
    listSerkom_pending: getSerkomAdminPending(listSerkomAdmin),
  };
}

export default connect(mapStateToProps, {
  listSerkomAdmin,
})(Serkom);
