import React from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import IconAttachfile from "../../assets/icon-attachfile";

export default ({ title, value, message, error, fileType, ...resProps }) => {
    // const handleOpen = () => {
    //     let file = value;
    //     let fr = new FileReader();
    //     fr.readAsDataURL(file);

    //     var blob = new Blob([file], { type: "application/pdf" });
    //     var link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = value.name;
    //     link.click();
    // };
    return (
        <Row horizontal="space-between" vertical="center">
            <Button
                variant="outlined"
                component="label"
                // color="primary"
                className={css(styles.uploadButton)}
                startIcon={<IconAttachfile />}
            >
                {title}
                <input
                    accept={
                        fileType === "image"
                            ? "image/jpg, image/jpeg, image/png"
                            : fileType === "pdf"
                                ? ".pdf"
                                : "FILE"
                    }
                    {...resProps}
                    style={{ display: "none" }}
                />
            </Button>
        </Row>
    );
};

const styles = StyleSheet.create({
    uploadButton: {
        boxShadow: "none",
    },
});
