import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_MUTATION_PENDING = "LIST_MUTATION_PENDING";
export const LIST_MUTATION_SUCCESS = "LIST_MUTATION_SUCCESS";
export const LIST_MUTATION_ERROR = "LIST_MUTATION_ERROR";

export const DETAIL_MUTATION_PENDING = "DETAIL_MUTATION_PENDING";
export const DETAIL_MUTATION_SUCCESS = "DETAIL_MUTATION_SUCCESS";
export const DETAIL_MUTATION_ERROR = "DETAIL_MUTATION_ERROR";

export const ADD_MUTATION_PENDING = "ADD_MUTATION_PENDING";
export const ADD_MUTATION_SUCCESS = "ADD_MUTATION_SUCCESS";
export const ADD_MUTATION_ERROR = "ADD_MUTATION_ERROR";

export const EDIT_MUTATION_PENDING = "EDIT_MUTATION_PENDING";
export const EDIT_MUTATION_SUCCESS = "EDIT_MUTATION_SUCCESS";
export const EDIT_MUTATION_ERROR = "EDIT_MUTATION_ERROR";

export const UPLOAD_MUTATION_PENDING = "UPLOAD_MUTATION_PENDING";
export const UPLOAD_MUTATION_SUCCESS = "UPLOAD_MUTATION_SUCCESS";
export const UPLOAD_MUTATION_ERROR = "UPLOAD_MUTATION_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_MUTATION";
export const APPROVE_MUTATION_PENDING = "APPROVE_MUTATION_PENDING";
export const APPROVE_MUTATION_SUCCESS = "APPROVE_MUTATION_SUCCESS";
export const APPROVE_MUTATION_ERROR = "APPROVE_MUTATION_ERROR";

const LIST_MUTATION_URL = "member/branch_mutation/find";
const EDIT_MUTATION_URL = "member/branch_mutation/edit";
const ADD_MUTATION_URL = "member/branch_mutation/add";
const APPROVE_MUTATION_URL = "member/branch_mutation/approve";
const UPLOAD_MUTATION_URL = "image/upload/member_branch_mutation";

export function listMutation(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_MUTATION_PENDING));
    API.post(LIST_MUTATION_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_MUTATION_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_MUTATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list mutasi, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addMutation({ berkas, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_MUTATION_PENDING));
    var formData = new FormData();
    formData.append("file", berkas);
    API.post(UPLOAD_MUTATION_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.surat_pendukung_filename = filename;
        return API.post(ADD_MUTATION_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_MUTATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan mutasi");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_MUTATION_ERROR, error));
        toastError(error, "Gagal menambahkan mutasi, ");
      });
  };
}

export function editMutation(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_MUTATION_PENDING));
    API.post(EDIT_MUTATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_MUTATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah mutasi");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_MUTATION_ERROR, error));
        toastError(error, "Gagal mengubah mutasi, ");
      });
  };
}

export function uploadMutation(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_MUTATION_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_MUTATION_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_MUTATION_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_MUTATION_ERROR, error));
        toastError(error, "Gagal upload berkas, ");
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function detailMutation(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_MUTATION_PENDING));
    API.post(LIST_MUTATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_MUTATION_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_MUTATION_ERROR, error));
        toastError(error, "Gagal mendapatkan list mutasi, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveMutation(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_MUTATION_PENDING));
    API.post(APPROVE_MUTATION_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_MUTATION_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status mutasi");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_MUTATION_ERROR, error));
        toastError(error, "Gagal mengubah status mutasi, ");
      });
  };
}
