import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListMember from "./List_member";

class MemberP2KB extends Component {
    render() {
        return (
            <ContentContainer>
                <ListMember />
            </ContentContainer>
        );
    }
}

export default MemberP2KB;
