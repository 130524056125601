import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import IconDownload from "../../assets/icon-download";
import IconRefresh from "../../assets/icon-refresh";
import { default_api } from "../../constants/default_api";


class ModalDetailDokumenPDFSKP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: this.props.imgUrl
        };
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleDownload() {
        window.open(default_api + "/image/show/member_p2kb/" + this.props.imgUrl)
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Column>
                        <p style={{ fontSize: 20, fontWeight: 600 }}>
                            {this.props.modal_title}
                        </p>
                    </Column>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <iframe src={`https://docs.google.com/gview?url=${default_api}/image/show/member_p2kb/` + this.props.imgUrl + "&embedded=true"} width="100%" height="500px" title="pdf"></iframe>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.rejectButton)}
                            startIcon={<IconRefresh />}
                            onClick={() => this.setState({ imgUrl: this.props.imgUrl })}
                        >
                            Refresh
                                </Button>
                        <Button
                            onClick={this.handleDownload}
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={<IconDownload />}
                        >
                            Download
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        padding: "0px 40px"
    },
    labelMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    imgDocument: {
        maxHeight: 400,
        maxWidth: 400,
        objectFit: "contain",
        marginRight: "auto",
        marginLeft: "auto"
    }
});

export default ModalDetailDokumenPDFSKP