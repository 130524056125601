import {
  BRANCH_LIST_PENDING,
  BRANCH_LIST_SUCCESS,
  BRANCH_LIST_ERROR,
  LIST_BRANCH_PENDING,
  LIST_BRANCH_SUCCESS,
  LIST_BRANCH_ERROR,
  LIST_DISTRIBUTION_PENDING,
  LIST_DISTRIBUTION_SUCCESS,
  LIST_DISTRIBUTION_ERROR,
  DETAIL_BRANCH_PENDING,
  DETAIL_BRANCH_SUCCESS,
  DETAIL_BRANCH_ERROR,
  EDIT_BRANCH_PENDING,
  EDIT_BRANCH_SUCCESS,
  EDIT_BRANCH_ERROR,
  DELETE_BRANCH_PENDING,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_ERROR,
  ADD_BRANCH_PENDING,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_ERROR,
} from "../../actions/master-data/branch_action";

const initialState = {
  pending: false,
  error: null,
};

export function branchList(
  state = { ...initialState, branchList: null },
  action
) {
  switch (action.type) {
    case BRANCH_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case BRANCH_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        branchList: action.data,
      };
    case BRANCH_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const initialState2 = {
  pending: false,
  list_branch: null,
  detail_branch: null,
  distribution: null,
  error: null,
};

export function listBranch(state = initialState2, action) {
  switch (action.type) {
    case LIST_BRANCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_BRANCH_SUCCESS:
      return {
        ...state,
        pending: false,
        list_branch: action.data,
      };
    case LIST_BRANCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function listMemberDistribution(state = initialState2, action) {
  switch (action.type) {
    case LIST_DISTRIBUTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        pending: false,
        distribution: action.data,
      };
    case LIST_DISTRIBUTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailBranch(state = initialState2, action) {
  switch (action.type) {
    case DETAIL_BRANCH_PENDING:
      return {
        ...state,
        detail_branch: null,
        pending: true,
      };
    case DETAIL_BRANCH_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_branch: action.data,
      };
    case DETAIL_BRANCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editBranch(state = initialState2, action) {
  switch (action.type) {
    case EDIT_BRANCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_BRANCH_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_BRANCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function addBranch(state = initialState2, action) {
  switch (action.type) {
    case ADD_BRANCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_BRANCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteBranch(state = initialState2, action) {
  switch (action.type) {
    case DELETE_BRANCH_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_BRANCH_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getBranchListPending = (state) => state.pending;
export const getBranchList = (state) => state.branchList;
export const getBranchListError = (state) => state.error;

export const getDistributionPending = (state) => state.pending;
export const getDistributionList = (state) => state.distribution;
export const getDistributionError = (state) => state.error;

export const getListBranch = (state) => state.list_branch;
export const getDetailBranch = (state) => state.detail_branch;
export const getBranchPending = (state) => state.pending;
export const getBranchError = (state) => state.error;
