import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_STR_PENDING = "LIST_STR_PENDING";
export const LIST_STR_SUCCESS = "LIST_STR_SUCCESS";
export const LIST_STR_ERROR = "LIST_STR_ERROR";

export const DETAIL_STR_PENDING = "DETAIL_STR_PENDING";
export const DETAIL_STR_SUCCESS = "DETAIL_STR_SUCCESS";
export const DETAIL_STR_ERROR = "DETAIL_STR_ERROR";

export const ADD_STR_PENDING = "ADD_STR_PENDING";
export const ADD_STR_SUCCESS = "ADD_STR_SUCCESS";
export const ADD_STR_ERROR = "ADD_STR_ERROR";

export const EDIT_STR_PENDING = "EDIT_STR_PENDING";
export const EDIT_STR_SUCCESS = "EDIT_STR_SUCCESS";
export const EDIT_STR_ERROR = "EDIT_STR_ERROR";

export const DELETE_STR_PENDING = "DELETE_STR_PENDING";
export const DELETE_STR_SUCCESS = "DELETE_STR_SUCCESS";
export const DELETE_STR_ERROR = "DELETE_STR_ERROR";

export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_STR";
export const APPROVE_STR_PENDING = "APPROVE_STR_PENDING";
export const APPROVE_STR_SUCCESS = "APPROVE_STR_SUCCESS";
export const APPROVE_STR_ERROR = "APPROVE_STR_ERROR";

const LIST_STR_URL = "member/str/find";
const EDIT_STR_URL = "member/str/edit";
const DELETE_STR_URL = "member/str/delete";
const ADD_STR_URL = "member/str/add";
const APPROVE_STR_URL = "member/str/approve";
const UPLOAD_STR_URL = "image/upload/member_str";

export function listStr(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_STR_PENDING));
    API.post(LIST_STR_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_STR_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_STR_ERROR, error));
        toastError(error, "Gagal mendapatkan list STR, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addStr({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_STR_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_STR_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        formData.append("str_no", restParam.str_no);
        formData.append("start_date", restParam.start_date);
        formData.append("end_date", restParam.end_date);
        return API.post(ADD_STR_URL, formData, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_STR_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan STR");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_STR_ERROR, error));
        toastError(error, "Gagal menambahkan STR, ");
      });
  };
}

export function detailStr(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_STR_PENDING));
    API.post(LIST_STR_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_STR_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_STR_ERROR, error));
        toastError(error, "Gagal mendapatkan list STR, ");
      });
  };
}

export function editStr(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_STR_PENDING));
    API.post(EDIT_STR_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_STR_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah STR");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_STR_ERROR, error));
        toastError(error, "Gagal mengubah STR, ");
      });
  };
}

export function deleteStr(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_STR_PENDING));
    API.post(DELETE_STR_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_STR_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus STR");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_STR_ERROR, error));
        toastError(error, "Gagal menghapus STR, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveStr(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_STR_PENDING));
    API.post(APPROVE_STR_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_STR_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status STR");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_STR_ERROR, error));
        toastError(error, "Gagal mengubah status STR, ");
      });
  };
}
