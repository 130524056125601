import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  messageBlastList,
  detailMessageBlast,
  setSelectedMessageBlast,
} from "../../actions/message-blast/message_blast_action";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import ContentContainer from "../../components/container/ContainerComponent";
import {
  getMessageBlastPending,
  getMessageBlastList,
  getMessageBlastError,
} from "../../reducers/message-blast/message_blast_reducer";
import HeaderMessageBlast from "./HeaderrMessageBlast";
import moment from "moment";
import localization from "moment/locale/id";
import { memberList } from "../../actions/member/member_action";
import {
  getMemberList,
  getMemberPending,
} from "../../reducers/member/member_reducer";

class MessageBlast extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  handleDetailMessageBlast(data) {
    this.props.history.push(`message-blast/detail/${data.id}`);
    window.location.reload();
  }

  componentDidMount() {
    // this.props.memberList({ length: 9999999999 });
  }

  render() {
    let data = [];
    let memberData;
    let records_total = 0;
    const { messageBlastList, messageBlastList_data, listMember_data } =
      this.props;
    if (messageBlastList_data) {
      data = messageBlastList_data.data;
      records_total = messageBlastList_data.records_total;
    }
    let memberOptions = [];
    if (listMember_data) {
      memberData = listMember_data.data;
      memberOptions = listMember_data.data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    }
    return (
      <ContentContainer>
        <HeaderMessageBlast
          handleRefresh={this.handleRefresh}
          memberOptions={memberOptions}
          memberData={memberData}
        />
        <MaterialTable
          tableRef={this.tableRef}
          title="Simple Action Preview"
          columns={[
            {
              title: "No",
              field: "messageBlast_name",
              render: (rowData) => {
                return <>{rowData.no}</>;
              },
              width: 20,
              headerStyle: {
                paddingLeft: 30,
              },
              cellStyle: {
                paddingLeft: 30,
              },
            },
            {
              title: "Tanggal",
              render: (data) => {
                return moment(data.created_at)
                  .locale("id", localization)
                  .format("DD MMMM YYYY");
              },
            },
            {
              title: "Judul",
              field: "title",
            },
            {
              title: "",
              render: (rowData) => {
                return (
                  <DetailButtonComponent>
                    <MenuItem
                      onClick={() => this.handleDetailMessageBlast(rowData)}
                    >
                      Detail
                    </MenuItem>
                  </DetailButtonComponent>
                );
              },
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
              };
              messageBlastList(param, resolve, reject);
            })
          }
          options={{
            pageSize: 10,
            paginationType: "stepped",
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
              // width: 10,
              // paddingLeft: "40px",
              // paddingRight: "40px",
            }, //change header padding
            // cellStyle: { paddingLeft: "40px", paddingRight: "40px" },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Tidak ada data",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <td style={{ width: "100%", display: "block" }}>
                <Row
                  // vertical="center"
                  horizontal="space-between"
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    // background: "red",
                  }}
                >
                  <Row
                    style={{
                      // backgroundColor: "red",
                      width: "50%",
                    }}
                    vertical="center"
                  >
                    <p>
                      Menampilkan {data.length} dari {records_total} data
                    </p>
                  </Row>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <TablePagination {...props} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
              </td>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ messageBlastList, memberList }) {
  return {
    messageBlastList_data: getMessageBlastList(messageBlastList),
    messageBlastList_error: getMessageBlastError(messageBlastList),
    messageBlastList_pending: getMessageBlastPending(messageBlastList),
    listMember_pending: getMemberPending(memberList),
    listMember_data: getMemberList(memberList),
  };
}

export default connect(mapStateToProps, {
  messageBlastList,
  detailMessageBlast,
  setSelectedMessageBlast,
  memberList,
})(MessageBlast);
