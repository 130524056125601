import React from "react";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import CountUp from 'react-countup';


export default (props) => {
    const { subspecialist, total, bar } = props;
    return (
        <Column style={{ marginBottom: 20 }}>
            <label className={css(styles.subspecialist)}>{subspecialist}</label>
            <div style={{ width: '100%', overflow: "hidden", }}>
                <Row>
                    <div className={css(styles.bar)} style={{ width: `${bar}%`, marginRight: 10 }}></div>
                    <span className={css(styles.total)}><CountUp end={total} separator="." duration={1} /></span>
                </Row>
            </div>
        </Column>
    );
};

const translateKeyframes = {
    '0%': {
        marginLeft: '-100%',
    },

    '100%': {
        marginLeft: '0',
    },


};
const styles = StyleSheet.create({
    subspecialist: {
        color: "#4D4D4D"
    },
    total: {
        color: "#2CB96A",
        opacity: 0.8
    },
    bar: {
        height: 15,
        background: "#2CB96A 0% 0% no-repeat padding-box",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        transition: "width 2s",
        animationName: [translateKeyframes],
        animationDuration: "2s"
    }
});