import {
  LIST_SIP_PENDING,
  LIST_SIP_SUCCESS,
  LIST_SIP_ERROR,
  DETAIL_SIP_PENDING,
  DETAIL_SIP_SUCCESS,
  DETAIL_SIP_ERROR,
  EDIT_SIP_PENDING,
  EDIT_SIP_SUCCESS,
  EDIT_SIP_ERROR,
  DELETE_SIP_PENDING,
  DELETE_SIP_SUCCESS,
  DELETE_SIP_ERROR,
  ADD_SIP_PENDING,
  ADD_SIP_SUCCESS,
  ADD_SIP_ERROR,
  APPROVE_SIP_PENDING,
  APPROVE_SIP_SUCCESS,
  APPROVE_SIP_ERROR,
  SET_IS_EDIT_FILE,
  CHANGE_SIP_TYPE_PENDING,
  CHANGE_SIP_TYPE_SUCCESS,
  CHANGE_SIP_TYPE_ERROR,
} from "../../actions/p2kb/sip_action";

const initialState = {
  pending: false,
  error: null,
};

export function listSip(state = { ...initialState, list_sip: null }, action) {
  switch (action.type) {
    case LIST_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
        list_sip: action.data,
      };
    case LIST_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailSip(
  state = { ...initialState, detail_sip: null },
  action
) {
  switch (action.type) {
    case DETAIL_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_sip: action.data,
      };
    case DETAIL_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editSip(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addSip(state = initialState, action) {
  switch (action.type) {
    case ADD_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteSip(state = initialState, action) {
  switch (action.type) {
    case DELETE_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function approveSip(state = initialState, action) {
  switch (action.type) {
    case APPROVE_SIP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_SIP_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case APPROVE_SIP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function changeSipType(
  state = { ...initialState, changeSipType: null },
  action
) {
  switch (action.type) {
    case CHANGE_SIP_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_SIP_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        changeSipType: action.data
      };
    case CHANGE_SIP_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListSip = (state) => state.list_sip;
export const getDetailSip = (state) => state.detail_sip;
export const getSipPending = (state) => state.pending;
export const getSipError = (state) => state.error;
export const getSipIsEditFile = (state) => state.isEditFile;
export const getChangeSipType = (state) => state.changeSipType;
export const getChangeSipTypePending = (state) => state.pending;
export const getChangeSipTypeError = (state) => state.error;