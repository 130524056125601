import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

class ModalDetailDokumenSKP extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Column>
                        <p style={{ fontSize: 20, fontWeight: 600 }}>
                            {this.props.modal_title}
                        </p>
                    </Column>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>{this.props.member_name} belum mengunggah berkas {this.props.modal_title} </span>
                    </Column>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        padding: "0px 40px 40px 40px"
    },
    labelMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    imgDocument: {
        maxHeight: 400,
        maxWidth: 400,
        objectFit: "contain",
        marginRight: "auto",
        marginLeft: "auto"
    }
});

export default ModalDetailDokumenSKP