import React, { Component } from "react";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import { detailPpds } from "../../actions/member/ppds_action";
import { withRouter } from "react-router-dom";

import {
  getPpdsPending,
  getDetailPpds,
  getPpdsError,
} from "../../reducers/member/ppds_reducer";
import { connect } from "react-redux";

class DetailPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: "umum" };
  }

  render() {
    const { detailPpds_data } = this.props;
    let result = {
      ip_s1: null,
      ip_profesi: null,
      ip_cumulation: null,
      status_ppds: null,
      abbreviation: null,
      name: null,
      graduated_date: null,
    };
    if (detailPpds_data && detailPpds_data.data.length !== 0) {
      const data = detailPpds_data.data[0];
      result.ip_s1 = data.ip_s1;
      result.ip_cumulation = data.ip_cumulation;
      result.ip_profesi = data.ip_profesi;
      result.abbreviation = data.university_before_data
        ? data.university_before_data.abbreviation
        : "";
      result.name = data.university_before_data
        ? data.university_before_data.name
        : "";
      result.graduated_date = data.graduated;
      result.id = data.id;
    }

    const {
      ip_s1,
      ip_profesi,
      ip_cumulation,
      abbreviation,
      name,
      graduated_date,
    } = result;

    return (
      <>
        <DetailNameComponent name="Pendidikan Asal" />
        <DetailContentComponent
          label="Prodi Asal"
          // value={`${name} - ${abbreviation}`}
          value={`${name === "-" ? "" : name} - ${
            abbreviation === "-" ? "" : abbreviation
          }`}
        />
        <DetailContentComponent
          label="Tahun Kelulusan"
          value={graduated_date ? graduated_date : "-"}
        />
        <DetailContentComponent label="Index Prestasi S1" value={ip_s1} />
        <DetailContentComponent
          label="Index Prestasi Profesi"
          value={ip_profesi}
        />
        <DetailContentComponent
          label="Index Prestasi Kumulatif"
          value={ip_cumulation}
        />
      </>
    );
  }
}

function mapStateToProps({ detailPpds }) {
  return {
    detailPpds_data: getDetailPpds(detailPpds),
    detailPpds_error: getPpdsError(detailPpds),
    detailPpds_pending: getPpdsPending(detailPpds),
  };
}

export default connect(mapStateToProps, { detailPpds })(withRouter(DetailPPDS));
