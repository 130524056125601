import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import getHeader from "../header";

export const LIST_EXPERIENCE_PENDING = "LIST_EXPERIENCE_PENDING";
export const LIST_EXPERIENCE_SUCCESS = "LIST_EXPERIENCE_SUCCESS";
export const LIST_EXPERIENCE_ERROR = "LIST_EXPERIENCE_ERROR";
export const EDIT_EXPERIENCE_PENDING = "EDIT_EXPERIENCE_PENDING";
export const EDIT_EXPERIENCE_SUCCESS = "EDIT_EXPERIENCE_SUCCESS";
export const EDIT_EXPERIENCE_ERROR = "EDIT_EXPERIENCE_ERROR";
export const ADD_EXPERIENCE_PENDING = "ADD_EXPERIENCE_PENDING";
export const ADD_EXPERIENCE_SUCCESS = "ADD_EXPERIENCE_SUCCESS";
export const ADD_EXPERIENCE_ERROR = "ADD_EXPERIENCE_ERROR";
export const DETAIL_EXPERIENCE_PENDING = "DETAIL_EXPERIENCE_PENDING";
export const DETAIL_EXPERIENCE_SUCCESS = "DETAIL_EXPERIENCE_SUCCESS";
export const DETAIL_EXPERIENCE_ERROR = "DETAIL_EXPERIENCE_ERROR";
export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_EXPERIENCE";

export const APPROVE_EXPERIENCE_PENDING = "APPROVE_EXPERIENCE_PENDING";
export const APPROVE_EXPERIENCE_SUCCESS = "APPROVE_EXPERIENCE_SUCCESS";
export const APPROVE_EXPERIENCE_ERROR = "APPROVE_EXPERIENCE_ERROR";

const LIST_EXPERIENCE_URL = "member/experience/find";
const EDIT_EXPERIENCE_URL = "member/experience/edit";
const ADD_EXPERIENCE_URL = "member/experience/add";
const UPLOAD_EXPERIENCE_URL = "image/upload/member_experience";
const APPROVE_EXPERIENCE_URL = "member/experience/approve";

export function listExperience(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_EXPERIENCE_PENDING));
    API.post(LIST_EXPERIENCE_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_EXPERIENCE_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_EXPERIENCE_ERROR, error));
        toastError(error, "Gagal mendapatkan list pengalaman, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function editExperience({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_EXPERIENCE_PENDING));
    new Promise((resolve, reject) => {
      if (file) {
        var formData = new FormData();
        formData.append("file", file);
        API.post(UPLOAD_EXPERIENCE_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename) {
          restParam.filename = filename;
        }
        return API.post(EDIT_EXPERIENCE_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_EXPERIENCE_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah pengalaman");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_EXPERIENCE_ERROR, error));
        toastError(error, "Gagal mengubah pengalaman, ");
      });
  };
}

export function addExperience({ file, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_EXPERIENCE_PENDING));
    var formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_EXPERIENCE_URL, formData, getHeader())
      .then((res) => {
        return res.data.filename;
      })
      .then((filename) => {
        restParam.filename = filename;
        return API.post(ADD_EXPERIENCE_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(ADD_EXPERIENCE_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan pengalaman");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_EXPERIENCE_ERROR, error));
        toastError(error, "Gagal menambahkan pengalaman, ");
      });
  };
}

export function detailExperience(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_EXPERIENCE_PENDING));
    API.post(LIST_EXPERIENCE_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_EXPERIENCE_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_EXPERIENCE_ERROR, error));
        toastError(error, "Gagal mendapatkan list pengalaman, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}

export function approveExperience(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_EXPERIENCE_PENDING));
    API.post(APPROVE_EXPERIENCE_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_EXPERIENCE_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status pengalaman");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_EXPERIENCE_ERROR, error));
        toastError(error, "Gagal mengubah status pengalaman, ");
      });
  };
}
