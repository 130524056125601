import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailContentComponent from "../../components/detail/DetailContentComponent";
import EditButtonComponent from "../../components/form/EditButtonComponent";

import DetailNameComponent from "../../components/detail/DetailNameComponent";
import { formValueSelector } from "redux-form";
import { universityList } from "../../actions/master-data/university_action";

import {
  detailEducation,
  editEducation,
  setIsEditFile,
} from "../../actions/member/education_action";
import { withRouter } from "react-router-dom";
import FormContainer from "../../components/container/FormContainer";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import {
  getEducationPending,
  getDetailEducation,
  getEducationError,
  getEducationIsEditFile,
} from "../../reducers/member/education_reducer";
import DynamicFormComponent from "../../components/form/DynamicFormComponent";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import {
  renderInput,
  renderSelect,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getUniversityListPending,
  getUniversityList,
} from "../../reducers/master-data/university_reducer";
import Button from "@material-ui/core/Button";
import EducationModalApprove from "./EducationModalApprove";
import EducationModalReject from "./EducationModalReject";
import { getUser } from "../../helpers/user";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDocumentImg from "../../components/global-components/Modal_document_img";
import ModalDocumentPdf from "../../components/global-components/Modal_document_pdf";
// import RenderFile from "../../components/form/FormComponent2";

class EducationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      strata: null,
      showApproveModal: false,
      showRejectModal: false,
      modalDocumentImgShow: false,
      modalDocumentPdfShow: false,
      imgUrl: null,
      modal_title: null,
      degreeOptions: {
        S1: [{ label: "dr.", value: "dr." }],
        S2: [{ label: "Sp.(OT)", value: "Sp.(OT)" }],
        S3: [{ label: "Dr.", value: "Dr." }],
        Fellowship: [{ label: "K.", value: "K." }],
      },
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdaptaionChange = this.handleAdaptaionChange.bind(this);
    this.handleUniversityChange = this.handleUniversityChange.bind(this);
    this.handleDegreeChange = this.handleDegreeChange.bind(this);
  }

  componentDidMount() {
    const { detailEducation } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    detailEducation(id);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    const { universityList, reset } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    reset();
    universityList({ length: 999, is_adaptation: 0 });
  }

  handleTriggerEditFile() {
    const { editEducation_isEditFile, setIsEditFile } = this.props;
    if (editEducation_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editEducation_isEditFile);
  }

  handleAdaptaionChange(value) {
    const { universityList, change } = this.props;
    const param = {
      length: 999,
      is_adoption: value ? value.value : undefined,
    };
    universityList(param);
    change("university_to", null);
    change("other_university_to", null);
    change("city", null);
  }

  handleUniversityChange(value) {
    const { change } = this.props;
    if (value && value.city) {
      change("city", value.city);
    } else {
      change("city", null);
    }
  }

  handleDegreeChange(value) {
    const { change } = this.props;
    const { degreeOptions } = this.state;
    if (value) {
      this.setState({ strata: value.value });
      change("degree", degreeOptions[value.value][0]);
    } else {
      change("degree", null);
      this.setState({ strata: null });
    }
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    if (imgUrl.split(".")[1] === "pdf") {
      this.setState({ modalDocumentPdfShow: true });
    } else {
      this.setState({ modalDocumentImgShow: true });
    }
  }

  handleSubmit(values) {
    const { editEducation, detailEducation, detailEducation_data } = this.props;
    const {
      is_adaptation,
      university_from,
      country,
      university_to,
      other_university_to,
      city,
      education_stage,
      graduated,
      berkas,
      degree,
    } = values;

    let param = {
      id: detailEducation_data[0].id,
      filename: detailEducation_data[0].filename,
      city,
      degree: degree.value,
      education_stage: education_stage.value,
      graduated: graduated.value,
      is_adaptation: is_adaptation.value,
      file: berkas,
      university_to:
        university_to.value === "other"
          ? other_university_to
          : university_to.label,
    };

    if (is_adaptation.value === 1) {
      param = {
        ...param,
        university_from: university_from,
        country,
      };
    }
    const callback = () => {
      detailEducation();
      this.handleTriggerFormEdit();
    };
    editEducation(param, callback);
  }

  render() {
    const statusColor = ["#F57C2B", "#2CB96A", "#ED2A2A"];

    const {
      detailEducation_data,
      editEducation_isEditFile,
      editEducation_pending,
      listUniversity_data,
      handleSubmit,
      initialValues,
      formValue: { is_adaptation, university_to },
    } = this.props;
    const { strata, degreeOptions, isEdit, showApproveModal, showRejectModal } =
      this.state;
    let name = null;
    let filename = null;
    let status = null;
    let reject_notes = null;
    let reject_filename = null;
    if (detailEducation_data && detailEducation_data.length !== 0) {
      name = detailEducation_data[0].member_data.name;
      filename = detailEducation_data[0].filename;
      status = detailEducation_data[0].status;
      reject_notes = detailEducation_data[0].reject_notes;
      reject_filename = detailEducation_data[0].reject_filename;
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    // let degreeOptions = [];
    let universityOptions = [];
    let educationStageOptions = [
      { label: "S1", value: "S1" },
      { label: "S2", value: "S2" },
      { label: "S3", value: "S3" },
      { label: "Fellowship", value: "Fellowship" },
    ];
    let adaptationOptions = [
      { label: "ya", value: 1 },
      { label: "Tidak", value: 0 },
    ];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: `${i}` }];
    }
    if (listUniversity_data) {
      universityOptions = listUniversity_data.data.map((item) => ({
        label: item.name,
        value: item.name,
        city: item.city.name,
      }));
      universityOptions = [
        ...universityOptions,
        { label: "Lainnya...", value: "other" },
      ];
    }

    return (
      <ContentContainer>
        <ModalDocumentImg
          show={this.state.modalDocumentImgShow}
          onHide={() => this.setState({ modalDocumentImgShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_education"
        />
        <ModalDocumentPdf
          show={this.state.modalDocumentPdfShow}
          onHide={() => this.setState({ modalDocumentPdfShow: false })}
          imgUrl={this.state.imgUrl}
          modal_title={this.state.modal_title}
          folder="member_education"
        />
        <EducationModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <EducationModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <DetailNameComponent name={name} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                  ? "Data Pendidikan Terverifikasi"
                  : reject_notes
              }
              statusTitle={
                status === 0
                  ? "Baru"
                  : status === 1
                  ? "Terverifikasi"
                  : "Ditolak"
              }
              status={status}
              filename={reject_filename}
              filepath="member_education"
            />
            {getUser().role_codes[0] === "admin_kolegium" && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Verifikasi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.rejectButton)}
                  onClick={() => this.setState({ showRejectModal: true })}
                >
                  GAGAL
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <form
              onSubmit={handleSubmit(this.handleSubmit)}
              style={{ width: "100%" }}
            >
              <Row
                vertical="center"
                horizontal="space-between"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <DetailNameComponent name="Informasi Pendidikan" />
                {getUser().role_codes[0] !== "admin_paboi_cabang" && (
                  <EditButtonComponent
                    isEdit={isEdit}
                    triggerIsEdit={this.handleTriggerFormEdit}
                    isLoading={editEducation_pending}
                  />
                )}
              </Row>
              <FormContainer label="Adaptasi">
                <Field
                  isDetail={!isEdit}
                  name="is_adaptation"
                  placeholder="Pilih jenis adaptasi"
                  options={adaptationOptions}
                  onChange={this.handleAdaptaionChange}
                  component={renderSelect}
                />
              </FormContainer>
              {is_adaptation && is_adaptation.value === 1 && (
                <>
                  <FormContainer label="Universitas Asal">
                    <Field
                      isDetail={!isEdit}
                      name="university_from"
                      placeholder="Masukkan Universitas Asal"
                      component={renderInput}
                    />
                  </FormContainer>
                  <FormContainer label="Negara">
                    <Field
                      isDetail={!isEdit}
                      name="country"
                      placeholder="Masukkan negara"
                      component={renderInput}
                    />
                  </FormContainer>
                </>
              )}
              <FormContainer
                label={`Universitas ${
                  is_adaptation && is_adaptation.value ? "Adaptasi" : "Tujuan"
                }`}
              >
                <Field
                  isDetail={!isEdit}
                  name="university_to"
                  placeholder={`Pilih universitas ${
                    is_adaptation && is_adaptation.value ? "adaptasi" : "tujuan"
                  }`}
                  options={universityOptions}
                  onChange={this.handleUniversityChange}
                  component={renderSelect}
                />
              </FormContainer>

              {university_to && university_to.value === "other" && (
                <FormContainer
                  isDetail={!isEdit}
                  label={`Universitas ${
                    is_adaptation && is_adaptation.value ? "adaptasi" : "tujuan"
                  }`}
                >
                  <Field
                    isDetail={!isEdit}
                    name="other_university_to"
                    placeholder={`Masukkan universitas ${
                      is_adaptation && is_adaptation.value
                        ? "adaptasi"
                        : "tujuan"
                    }`}
                    component={renderInput}
                  />
                </FormContainer>
              )}

              <FormContainer label="Kota">
                <Field
                  isDetail={!isEdit}
                  name="city"
                  placeholder="Masukkan kota"
                  component={renderInput}
                  disabled={!(university_to && university_to.value === "other")}
                />
              </FormContainer>
              <FormContainer label="Jenjang Pendidikan">
                <Field
                  isDetail={!isEdit}
                  name="education_stage"
                  placeholder="Pilih jenjang pendidikan"
                  component={renderSelect}
                  onChange={this.handleDegreeChange}
                  options={educationStageOptions}
                />
              </FormContainer>
              <FormContainer label="Gelar">
                <Field
                  isDetail={!isEdit}
                  name="degree"
                  placeholder="Pilih gelar"
                  component={renderSelect}
                  options={strata ? degreeOptions[strata] : []}
                />
              </FormContainer>
              <FormContainer label="Tahun Lulus">
                <Field
                  isDetail={!isEdit}
                  name="graduated"
                  placeholder="Pilih tahun lulus"
                  component={renderSelect}
                  options={yearOptions}
                />
              </FormContainer>
              {isEdit ? (
                <FormContainer
                  label="Berkas"
                  contentValueStyle={{ margin: "20px 0" }}
                >
                  <FieldArray
                    name="file"
                    isDetail={!isEdit}
                    component={DynamicFormComponent}
                    changeFileValue={this.changeFileValue}
                    initialValues={initialValues}
                    folderPath="member_education"
                  />
                </FormContainer>
              ) : (
                <FieldArray
                  name="file"
                  isDetail={!isEdit}
                  component={DynamicFormComponent}
                  changeFileValue={this.changeFileValue}
                  initialValues={initialValues}
                  folderPath="member_education"
                />
              )}
              <DetailContentComponent
                label="Status"
                style={{
                  color: statusColor[status],
                  fontWeight: "bold",
                }}
                value={
                  status === 0
                    ? "Baru"
                    : status === 1
                    ? "Terverifikasi"
                    : status === 2
                    ? "Ditolak"
                    : "-"
                }
              />
            </form>
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

function validate(values, { editEducation_isEditFile }) {
  const {
    is_adaptation,
    university_from,
    country,
    university_to,
    other_university_to,
    city,
    education_stage,
    graduated,
    degree,
    berkas,
    file,
  } = values;
  const fileArrayErrors = [];
  const errors = {};
  if (!is_adaptation) {
    errors.is_adaptation = "Jenis adaptasi wajib diisi";
  } else {
    if (is_adaptation.value === 1) {
      if (!university_from) {
        errors.university_from = "Universitas asal wajib diisi  ";
      }
      if (!country) {
        errors.country = "Negara universitas asal wajib diisi  ";
      }
    }
  }
  if (!university_to) {
    errors.university_to = `universitas ${
      is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "tujuan"
    } wajib diisi`;
  } else {
    if (university_to.value === "other") {
      if (!other_university_to) {
        errors.other_university_to = `universitas ${
          is_adaptation && is_adaptation.value === 1 ? "adaptasi" : "tujuan"
        } wajib diisi`;
      }
    }
  }
  if (!city) {
    errors.city = "Kota wajib diisi";
  }
  if (!education_stage) {
    errors.education_stage = "Jenjang pendidikan wajib diisi";
  }
  if (!graduated) {
    errors.graduated = "Tahun lulus wajib diisi";
  }

  if (!degree) {
    errors.degree = "Gelar pendidikan wajib diisi";
  }
  if (editEducation_isEditFile && !berkas) {
    errors.berkas = "Berkas pendidikan wajib diisi";
  }
  if (file && file.length) {
    file.forEach((item, index) => {
      if (!item) {
        fileArrayErrors[index] = "Berkas tidak boleh kosong";
      }
    });
  }
  if (fileArrayErrors.length) {
    errors.file = fileArrayErrors;
  }

  return errors;
}

EducationDetail = reduxForm({
  // a unique name for the form
  form: "EducationDetail",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(EducationDetail);

function mapStateToProps(state) {
  const { detailEducation, editEducation, universityList } = state;
  let initialValues = {};
  const detailEducation_data = getDetailEducation(detailEducation);
  if (detailEducation_data && detailEducation_data.length !== 0) {
    initialValues = {
      is_adaptation: {
        label: detailEducation_data[0].is_adaptation === 1 ? "Ya" : "Tidak",
        value: detailEducation_data[0].is_adaptation,
      },
      university_to: {
        label: detailEducation_data[0].university_to,
        value: detailEducation_data[0].university_to,
      },
      education_stage: {
        label: detailEducation_data[0].education_stage,
        value: detailEducation_data[0].education_stage,
      },
      city: detailEducation_data[0].city,
      university_from: detailEducation_data[0].university_from,
      country: detailEducation_data[0].country,
      graduated: {
        label: `${detailEducation_data[0].graduated}`,
        value: detailEducation_data[0].graduated,
      },
      degree: {
        label: `${detailEducation_data[0].degree}`,
        value: detailEducation_data[0].degree,
      },
      file: detailEducation_data[0].education_attachments.map((item) => ({
        value: item.attachment_filename,
        name: item.attachment_name,
      })),
    };
  }

  const selector = formValueSelector("EducationDetail");
  const formValue = selector(state, "university_to", "is_adaptation");

  return {
    detailEducation_data: getDetailEducation(detailEducation),
    detailEducation_error: getEducationError(detailEducation),
    detailEducation_pending: getEducationPending(detailEducation),
    editEducation_error: getEducationError(editEducation),
    editEducation_pending: getEducationPending(editEducation),
    editEducation_isEditFile: getEducationIsEditFile(editEducation),
    initialValues,
    listUniversity_data: getUniversityList(universityList),
    listUniversity_pending: getUniversityListPending(universityList),
    formValue,
  };
}

export default connect(mapStateToProps, {
  detailEducation,
  editEducation,
  setIsEditFile,
  universityList,
})(withRouter(EducationDetail));
