import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_SUBSPESIALIS_PENDING = "LIST_SUBSPESIALIS_PENDING";
export const LIST_SUBSPESIALIS_SUCCESS = "LIST_SUBSPESIALIS_SUCCESS";
export const LIST_SUBSPESIALIS_ERROR = "LIST_SUBSPESIALIS_ERROR";

export const DETAIL_SUBSPESIALIS_PENDING = "DETAIL_SUBSPESIALIS_PENDING";
export const DETAIL_SUBSPESIALIS_SUCCESS = "DETAIL_SUBSPESIALIS_SUCCESS";
export const DETAIL_SUBSPESIALIS_ERROR = "DETAIL_SUBSPESIALIS_ERROR";

export const ADD_SUBSPESIALIS_PENDING = "ADD_SUBSPESIALIS_PENDING";
export const ADD_SUBSPESIALIS_SUCCESS = "ADD_SUBSPESIALIS_SUCCESS";
export const ADD_SUBSPESIALIS_ERROR = "ADD_SUBSPESIALIS_ERROR";

export const EDIT_SUBSPESIALIS_PENDING = "EDIT_SUBSPESIALIS_PENDING";
export const EDIT_SUBSPESIALIS_SUCCESS = "EDIT_SUBSPESIALIS_SUCCESS";
export const EDIT_SUBSPESIALIS_ERROR = "EDIT_SUBSPESIALIS_ERROR";

export const DELETE_SUBSPESIALIS_PENDING = "DELETE_SUBSPESIALIS_PENDING";
export const DELETE_SUBSPESIALIS_SUCCESS = "DELETE_SUBSPESIALIS_SUCCESS";
export const DELETE_SUBSPESIALIS_ERROR = "DELETE_SUBSPESIALIS_ERROR";

const LIST_SUBSPESIALIS_URL = "master_data/subspecialist/find";
const EDIT_SUBSPESIALIS_URL = "master_data/subspecialist/edit";
const DELETE_SUBSPESIALIS_URL = "master_data/subspecialist/delete";
const ADD_SUBSPESIALIS_URL = "master_data/subspecialist/add";

export function listSubspesialis(param = {}, resolve) {
  return (dispatch) => {
    dispatch(actionPending(LIST_SUBSPESIALIS_PENDING));
    API.post(LIST_SUBSPESIALIS_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_SUBSPESIALIS_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            name: item.name,
            id: item.id,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_SUBSPESIALIS_ERROR, error));
        toastError(error, "Gagal mendapatkan list SUBSPESIALIS, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addSubspesialis(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_SUBSPESIALIS_PENDING));
    API.post(ADD_SUBSPESIALIS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(ADD_SUBSPESIALIS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambahkan SUBSPESIALIS");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_SUBSPESIALIS_ERROR, error));
        toastError(error, "Gagal menambahkan SUBSPESIALIS, ");
      });
  };
}

export function detailSubspesialis(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_SUBSPESIALIS_PENDING));
    API.post(LIST_SUBSPESIALIS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_SUBSPESIALIS_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_SUBSPESIALIS_ERROR, error));
        toastError(error, "Gagal mendapatkan list SUBSPESIALIS, ");
      });
  };
}

export function editSubspesialis(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_SUBSPESIALIS_PENDING));
    API.post(EDIT_SUBSPESIALIS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_SUBSPESIALIS_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah SUBSPESIALIS");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_SUBSPESIALIS_ERROR, error));
        toastError(error, "Gagal mengubah SUBSPESIALIS, ");
      });
  };
}

export function deleteSubspesialis(id, callback) {
  return (dispatch) => {
    dispatch(actionPending(DELETE_SUBSPESIALIS_PENDING));
    API.post(DELETE_SUBSPESIALIS_URL, { id: id }, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_SUBSPESIALIS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus SUBSPESIALIS");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DELETE_SUBSPESIALIS_ERROR, error));
        toastError(error, "Gagal menghapus SUBSPESIALIS, ");
      });
  };
}
