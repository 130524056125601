import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { deleteMasterBenefit } from "../../actions/master-data/master_benefit_action";
import {
    getMasterBenefitDelete,
    getMasterBenefitDeletePending,
    getMasterBenefitDeleteError,
} from "../../reducers/master-data/master_benefit_reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

class ModalDeleteBenefit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    benefitDelete(id) {
        const { deleteMasterBenefit, onHide } = this.props;
        const param_delete = {
            id: id
        };
        const callback = () => {
            onHide();
            this.props.handleRefresh({ forRefresh: true });
        };
        deleteMasterBenefit(param_delete, callback);
    }

    render() {
        const { deleteMasterBenefit_pending, formValue } = this.props;
        let id;
        if (formValue) {
            id = formValue ? formValue : null;
        }
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                dialogClassName="modal-width"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <p style={{ fontSize: 20, fontWeight: 600 }}>Konfirmasi</p>
                </Modal.Header>
                <Modal.Body>
                    <Column className={css(styles.bodyModal)}>
                        <span>Apakah Anda yakin ingin menghapus benefit {this.props.benefit_name}?</span>
                    </Column>
                    <Row
                        horizontal="flex-end"
                        vertical="center"
                        className={css(styles.footerModal)}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={css(styles.approveButton)}
                            startIcon={
                                deleteMasterBenefit_pending ? (
                                    <CircularProgress size={14} color="secondary" />
                                ) : (
                                        <CheckIcon />
                                    )
                            }
                            onClick={() => this.benefitDelete(id)}
                        >
                            YA
                        </Button>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    approveButton: {
        background: "#2CB96A 0% 0% no-repeat padding-box",
        boxShadow: "none",
    },
    rejectButton: {
        background: "#EAEBED 0% 0% no-repeat padding-box",
        marginRight: 10,
        color: "#495057",
        boxShadow: "none",
    },
    footerModal: {
        marginTop: 20,
        backgroundColor: "#FBFBFD",
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
    },
    bodyModal: {
        paddingLeft: 40,
        paddingRight: 40,
    },
});

ModalDeleteBenefit = reduxForm({
    form: "ModalDeleteBenefit",
    shouldError: () => true,
    enableReinitialize: true,
})(ModalDeleteBenefit)

function mapStateToProps(state) {
    const { deleteMasterBenefit, selectedMasterBenefit } = state;
    const selector = formValueSelector("ModalDeleteBenefit");
    const formValue = selector(state, "id");
    let initialValues = {};
    if (selectedMasterBenefit) {
        if (selectedMasterBenefit.isDeleteMasterBenefit) {
            initialValues = {
                id: selectedMasterBenefit.id
            }
        }
    }
    return {
        deleteMasterBenefit_pending: getMasterBenefitDeletePending(deleteMasterBenefit),
        deleteMasterBenefit_data: getMasterBenefitDelete(deleteMasterBenefit),
        deleteMasterBenefit_error: getMasterBenefitDeleteError(deleteMasterBenefit),
        formValue,
        initialValues
    };
}

export default connect(mapStateToProps, { deleteMasterBenefit })(ModalDeleteBenefit);
