import React, { Component } from "react";
import ContentContainer from "../../components/container/ContainerComponent";
import ListP2kb from "./List_p2kb";

class P2kb extends Component {
    render() {
        return (
            <ContentContainer>
                <ListP2kb />
            </ContentContainer>
        );
    }
}

export default P2kb;