import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import ContentContainer from "../../components/container/ContainerComponent";
import MaterialTable from "material-table";
import { registrationList } from "../../actions/registrasi_sp2/registrasi_action";
import {
  getRegistrationListPending,
  getRegistrationList,
  getRegistrationListError,
} from "../../reducers/registrasi_sp2/registrasi_reducer";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { TablePagination } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import TableStatusComponent from "../../components/global-components/TableStatusComponent";
import HeaderRegistrasi from "./Header_registrasi";

class ListRegistrasi extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      search: "",
      status: "",
      fkasal: "",
      fktujuan: "",
      tahun: "",
      id: null,
      columns: [
        {
          title: "No", field: "name",
          render: (rowData) => {
            return <>{rowData.no}</>
          },
          width: 20,
          headerStyle: {
            paddingLeft: 40,
          },
          cellStyle: {
            paddingLeft: 40,
          },
        },
        { title: "Nama", field: "name" },
        { title: "FK Asal", field: "university_before_data.abbreviation" },
        { title: "Tahun", field: "graduated" },
        { title: "Prodi Tujuan", field: "prodi_tujuan" },
        {
          title: "Nomor Kolegium",
          field: "kolegium_no",
          render: ({ kolegium_no }) => {
            return (
              <TableStatusComponent
                status={kolegium_no === null || kolegium_no === "" ? "-": kolegium_no}
              />
            );
          },
        },
        {
          title: "Status",
          field: "status_registration",
          render: ({ status_registration }) => {
            return (
              <TableStatusComponent
                status={status_registration === 0 ? "Baru" : status_registration === 1 ? "Terverifikasi" : "Ditolak"}
              />
            );
          },
        },
        {
          title: "Status PPDS",
          field: "status_ppds",
          render: ({ status_ppds }) => {
            return (
              <TableStatusComponent
                status={status_ppds === 0 ? "-" : status_ppds === 1 ? "Aktif" : "Ditolak"}
              />
            );
          },
        },
        {
          title: "Status Kelulusan",
          field: "status_graduated",
          render: ({ status_graduated }) => {
            return (
              <TableStatusComponent
                status={status_graduated === 0 ? "-" : status_graduated === 1 ? "Lulus" : "Ditolak"}
              />
            );
          },
        },
        {
          title: "",
          render: (rowData) => {
            return (
              <DetailButtonComponent>
                <MenuItem onClick={() => this.detailRecord(rowData.id)}>
                  Detail
                </MenuItem>
              </DetailButtonComponent>
            );
          },
        },
      ]
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleRefresh(state) {
    this.setState(state);
    this.setState({ id: null })
    this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  detailRecord(id) {
    this.props.history.push(`/registrasi-sp2/detail/informasi-umum/${id}`);
  }

  componentDidMount() {
    const url = window.location.href;
    const ID = url.substring(url.lastIndexOf('/') + 1);
    const isID = url.substring(url.lastIndexOf('/') + 1) !== "registrasi-sp2";
    if (isID && this.state.id === null) {
      this.setState({ id: ID })
    }
  }

  // tableRef = React.createRef();
  render() {
    const { registrationList, registrationList_data } = this.props;
    let data = [];
    let records_total = "";
    let length = "";
    if (registrationList_data) {
      data = registrationList_data;
      records_total = data.records_total;
      length = data.data.length;
    }
    return (
      <ContentContainer>
        <HeaderRegistrasi handleRefresh={this.handleRefresh} />
        <MaterialTable
          tableRef={this.tableRef}
          title="Registrasi"
          columns={this.state.columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              let param = {
                page: query.page + 1,
                length: 10,
                search_text: this.state.search,
                status_registration: this.state.status,
                university_before: this.state.fkasal,
                university_to: this.state.fktujuan,
                graduated: this.state.tahun,
                id: this.state.id,
              };
              registrationList(param, resolve, reject);
            })
          }
          options={{
            paginationType: "stepped",
            pageSize: 10,
            pageSizeOptions: [],
            showTitle: false,
            search: false,
            headerStyle: {
              backgroundColor: "#F8F9FB",
            },
          }}
          components={{
            Toolbar: () => <div />,
            Container: (props) => <Paper {...props} elevation={0} />,
            Pagination: (props) => (
              <Row
                // vertical="center"
                horizontal="space-between"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <Row
                  style={{
                    // backgroundColor: "red",
                    width: "50%",
                    borderBottom: "1px solid rgba(224, 224, 224)",
                  }}
                  vertical="center"
                >
                  <p> Menampilkan {length} dari {records_total} data </p>
                </Row>
                <TablePagination
                  {...props}
                  color="primary"
                  labelRowsPerPage={
                    <div style={{ fontSize: 14 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 14, color: "red" }}>
                      {/* {props.labelDisplayedRows(row)} */}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 20,
                    },
                  }}
                />
              </Row>
            ),
          }}
        />
      </ContentContainer>
    );
  }
}

function mapStateToProps({ registrationList }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
  };
}

export default connect(mapStateToProps, { registrationList })(withRouter(ListRegistrasi));
