import React, { Component } from "react";
import { Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";
import ContentContainer from "../../components/container/ContainerComponent";
import LeftContainerComponent from "../../components/container/LeftContainerComponent";
import RightContainerComponent from "../../components/container/RightContainerComponent";
import DetailInfoComponent from "../../components/detail/DetailInfoComponent";
import {
  detailSerkomAdmin,
  editSerkomAdmin,
  setIsEditFile,
} from "../../actions/serkom/serkomAdmin_action";
import { detailP2kbSerkom } from "../../actions/p2kb/p2kb_action";
import { withRouter } from "react-router-dom";
import {
  getSerkomAdminPending,
  getDetailSerkomAdmin,
  getSerkomAdminError,
  getSerkomAdminIsEditFile,
} from "../../reducers/serkom/serkomAdmin_reducer";
import {
  getDetailP2kb,
  getP2kbPending,
} from "../../reducers/p2kb/p2kb_reducer";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import SerkomModalApprove from "./SerkomModalApprove";
import SerkomModalReject from "./SerkomModalReject";
import StatusSquareComponent from "../../components/global-components/StatusSquareComponent";
import MaterialTable from "material-table";
import PaginationComponent from "../../components/table/PaginationComponent";
import { Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DetailButtonComponent from "../../components/global-components/DetailButtonComponent";
import { getUser } from "../../helpers/user";
import moment from "moment";
import localization from "moment/locale/id";

class SerkomDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditFile: false,
      showApproveModal: false,
      showRejectModal: false,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentDidMount() {
    const { detailSerkomAdmin } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    detailSerkomAdmin(id);

    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleTriggerEditFile = this.handleTriggerEditFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentRender() {
    const { registrationList_pending } = this.props;
    if (registrationList_pending === false) {
      return false;
    }
    return true;
  }

  handleTriggerFormEdit() {
    this.setState({ isEdit: !this.state.isEdit });
    this.setState({ isEditFile: false });
    this.props.reset();
  }

  handleTriggerEditFile() {
    const { editSerkom_isEditFile, setIsEditFile } = this.props;
    if (editSerkom_isEditFile) {
      this.props.change("berkas", null);
    }
    setIsEditFile(!editSerkom_isEditFile);
  }

  handleSubmit(values) {
    const { detailSerkom_data, editSerkom, detailSerkom } = this.props;

    const { berkas, institution, serkom_type, year } = values;
    const param = {
      id: detailSerkom_data[0].id,
      filename: detailSerkom_data[0].filename,
      institution,
      serkom_type,
      year: year.value,
    };
    if (berkas) {
      param.file = berkas;
    }
    const callback = () => {
      detailSerkom();
      this.handleTriggerFormEdit();
    };
    editSerkom(param, callback);
  }

  render() {
    const {
      detailSerkom_data,
    } = this.props;
    const { showApproveModal, showRejectModal } = this.state;
    let reference_number,
      status,
      serkom_members,
      date,
      role_next_approve,
      length,
      data = [];

    if (detailSerkom_data && detailSerkom_data.length !== 0) {
      length = detailSerkom_data.length;
      reference_number = detailSerkom_data[0].reference_number;
      status = detailSerkom_data[0].status;
      serkom_members = detailSerkom_data[0].serkom_members.length;
      date = detailSerkom_data[0].date;
      if (detailSerkom_data[0].role_next_approve) {
        role_next_approve = detailSerkom_data[0].role_next_approve.role_code;
      }
      data = detailSerkom_data[0].serkom_members.map((item, i) => ({
        no: i + 1,
        ...item,
      }));
    }

    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <ContentContainer>
        <SerkomModalApprove
          show={showApproveModal}
          onHide={() => this.setState({ showApproveModal: false })}
        />
        <SerkomModalReject
          show={showRejectModal}
          onHide={() => this.setState({ showRejectModal: false })}
        />
        <Row>
          <LeftContainerComponent>
            <div style={{ marginBottom: 12, color: "#495057" }}>No Surat</div>
            <DetailInfoComponent name={reference_number} />
            <StatusSquareComponent
              statusLabel={
                status === 0
                  ? "Menunggu verifikasi"
                  : status === 1
                    ? "Data  Serkom Terverifikasi"
                    : "--"
              }
              statusTitle={
                status === 0 ? "Baru" : status === 1 ? "Terverifikasi" : "--"
              }
              status={status}
            />

            <Row
              horizontal="space-between"
              style={{
                padding: "15px 0",
                // borderBottom: 1,

                width: "100%",
                color: "#495057",
                borderBottom: " 2px solid rgb(237, 237, 238)",
              }}
            >
              <div>Diajukan pada</div>
              <div style={{ fontWeight: 600 }}>{moment(date).locale('id', localization).format('DD MMMM YYYY')}</div>
            </Row>
            <Row
              horizontal="space-between"
              style={{
                padding: "15px 0",
                marginBottom: 20,
                width: "100%",
                color: "#495057",
                // borderBottom: " 2px solid rgb(237, 237, 238)",
              }}
            >
              <div>Jumlah Lulusan</div>
              <div style={{ fontWeight: 600 }}>{serkom_members}</div>
            </Row>
            {getUser().role_codes[0] === role_next_approve && status === 0 && (
              <Row style={{ width: "100%" }} horizontal="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  className={css(styles.approveButton)}
                  onClick={() => this.setState({ showApproveModal: true })}
                >
                  Setuju
                </Button>
              </Row>
            )}
          </LeftContainerComponent>
          <RightContainerComponent>
            <MaterialTable
              tableRef={this.tableRef}
              columns={[
                {
                  title: "No",
                  field: "no",
                  width: 20,
                  headerStyle: {
                    paddingLeft: 40,
                    width: "100%",
                  },
                  cellStyle: {
                    paddingLeft: 40,
                  },
                },
                {
                  title: "Name",
                  field: "user_data.name",
                  headerStyle: {
                    paddingLeft: 40,
                    // width: 100,
                    // minWidth: 240,
                  },
                },
                {
                  title: "Tanggal Lulus",
                  render: ({ user_data: { ppds_graduated_date } }) =>
                    moment(ppds_graduated_date)
                      .locale("id", localization)
                      .format("DD MMMM YYYY"),
                },
                {
                  title: "No ID PABOI",
                  field: "user_data.member_profile.paboi_id",
                },
                {
                  title: "NPA IDI",
                  field: "user_data.member_profile.npa_idi",
                },
                { title: "Total SKP", field: "total_skp" },
                {
                  title: "",
                  render: ({ user_data }) => {
                    const {
                      match: {
                        params: { id: serkom_id },
                      },
                    } = this.props;
                    return (
                      <DetailButtonComponent>
                        <MenuItem
                          onClick={() => {
                            const user_id = user_data.id
                            localStorage.setItem('nama_member', user_data.name)
                            localStorage.setItem('user_id_skp', user_data.id)
                            this.props.history.push(
                              "/admin/serkom/detail/" +
                              user_id +
                              "/" +
                              serkom_id
                            )
                          }
                          }
                        >
                          Detail
                        </MenuItem>
                      </DetailButtonComponent>
                    );
                  },
                },
              ]}
              data={data}
              options={{
                // pageSize: 10,
                paginationType: "stepped",
                pageSizeOptions: [],
                // headerStyle: {
                //   backgroundColor: "#F8F9FB",
                // },
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Tidak ada data",
                },
              }}
              components={{
                Toolbar: () => <div />,
                Container: (props) => <Paper {...props} elevation={0} />,
                Pagination: (props) => (
                  <PaginationComponent
                    records={length}
                    records_total={length}
                    {...props}
                  />
                ),
              }}
            />
          </RightContainerComponent>
        </Row>
      </ContentContainer>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    // marginRight: 10,
    boxShadow: "none",
    width: "45%",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    // marginRight: 10,
    color: "#495057",
    boxShadow: "none",
    width: "45%",
  },
});

function validate(values, props) {
  const { editSerkom_isEditFile } = props;
  const { institution, serkom_type, year, berkas } = values;
  const errors = {};
  if (!institution) {
    errors.institution = "Nama institusi wajib diisi";
  }
  if (!serkom_type) {
    errors.serkom_type = "Tipe penghargaan wajib diisi";
  }
  if (!year) {
    errors.year = "Tipe penghargaan wajib diisi";
  }
  if (editSerkom_isEditFile && !berkas) {
    errors.berkas = "Berkas wajib diisi";
  }

  return errors;
}

SerkomDetail = reduxForm({
  // a unique name for the form
  form: "SerkomModalAdd",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(SerkomDetail);

function mapStateToProps(state) {
  const { detailSerkomAdmin, editSerkomAdmin, detailP2kb } = state;
  let initialValues = {};
  const detailSerkom_data = getDetailSerkomAdmin(detailSerkomAdmin);
  if (detailSerkom_data && detailSerkom_data.length !== 0) {
    initialValues = {
      institution: detailSerkom_data[0].institution,
      city: detailSerkom_data[0].city,
      position: detailSerkom_data[0].position,
      year: {
        label: `${detailSerkom_data[0].year}`,
        value: detailSerkom_data[0].year,
      },
    };
  }

  return {
    detailP2kb_data: getDetailP2kb(detailP2kb),
    detailP2kb_pending: getP2kbPending(detailP2kb),
    detailSerkom_data: getDetailSerkomAdmin(detailSerkomAdmin),
    detailSerkom_error: getSerkomAdminError(detailSerkomAdmin),
    detailSerkom_pending: getSerkomAdminPending(detailSerkomAdmin),
    editSerkom_error: getSerkomAdminError(editSerkomAdmin),
    editSerkom_pending: getSerkomAdminPending(editSerkomAdmin),
    editSerkom_isEditFile: getSerkomAdminIsEditFile(editSerkomAdmin),
    initialValues,
  };
}

export default connect(mapStateToProps, {
  detailSerkomAdmin,
  editSerkomAdmin,
  setIsEditFile,
  detailP2kbSerkom,
})(withRouter(SerkomDetail));
