import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import CheckIcon from "@material-ui/icons/Check";
import { StyleSheet, css } from "aphrodite";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { addStr } from "../../actions/p2kb/str_action";
import {
  renderInput,
  renderDatepicker,
  renderFile,
} from "../../components/form/FormComponent";
import {
  getStrPending,
} from "../../reducers/p2kb/str_reducer";

import LabelInputVerticalComponent from "../../components/global-components/LabelInputVerticalComponent";
import moment from "moment";

class ModalStrAdd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show === true) {
      this.props.reset();
    }
  }

  handleSubmit(values) {
    const { addStr, onHide, id, handleRefresh } = this.props;
    const param = {
      id: id,
      str_no: values.str_no,
      start_date: values.start_date,
      end_date: values.end_date,
      file: values.berkas,
    };

    const callback = () => {
      onHide();
      handleRefresh();
    };

    addStr(param, callback);
  }

  render() {
    const {
      handleSubmit,
      show,
      onHide,
      addStr_pending,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>Tambah STR</p>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <Column
              vertical="center"
              horizontal="stretch"
              className={css(styles.bodyModal)}
            >
              <>
                <LabelInputVerticalComponent
                  label="Nomor STR"
                  marginTop="20px"
                ></LabelInputVerticalComponent>
                <Field
                  name="str_no"
                  type="text"
                  placeholder="Masukkan Nomor STR"
                  component={renderInput}
                />

                <Row horizontal="space-between">
                  <div>
                    <LabelInputVerticalComponent
                      label="Tanggal Pengesahan"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="start_date"
                      type="text"
                      placeholder="Pilih RS"
                      component={renderDatepicker}
                    />
                  </div>

                  <div>
                    <LabelInputVerticalComponent
                      label="Berlaku Sampai"
                      marginTop="20px"
                    ></LabelInputVerticalComponent>
                    <Field
                      name="end_date"
                      type="text"
                      placeholder="Pilih RS"
                      component={renderDatepicker}
                    />
                  </div>
                </Row>

                <Field
                  name="berkas"
                  type="file"
                  fileType="image" //image or pdf
                  title="upload berkas"
                  message="PNG atau JPG maksimal 500 kb"
                  component={renderFile}
                />
              </>
            </Column>
            <Row
              horizontal="flex-end"
              vertical="center"
              className={css(styles.footerModal)}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addStr_pending}
                className={css(styles.approveButton)}
                startIcon={
                  addStr_pending ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                      <CheckIcon />
                    )
                }
              >
                simpan
              </Button>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    backgroundColor: "#FBFBFD",
    padding: "20px 40px",
  },
  bodyModal: {
    padding: "0px 40px 20px",
    width: "100%",
  },
  Selectcontainer: { width: "190px" },
});

function validate(values) {
  const maxFileSize = 500000; //500kb
  const imageType = ["image/png", "image/jpeg"]
  const { str_no, start_date, end_date, berkas } = values;
  const errors = {};
  if (!str_no) {
    errors.str_no = "Nomor STR tidak boleh kosong";
  }
  if (!start_date) {
    errors.start_date = "Tanggal Pengesahan STR tidak boleh kosong";
  }
  if (!end_date) {
    errors.end_date = "Tanggal Akhir STR tidak boleh kosong";
  }
  if (!berkas) {
    errors.berkas = "Berkas Wajib Disertakan";
  }
  if (values.berkas && (values.berkas.type !== imageType[0] && values.berkas.type !== imageType[1])){
    errors.berkas = "Format file hanya bisa PNG/JPG/JPEG"
  }
  else if (values.berkas && (values.berkas.size > maxFileSize)){
    errors.berkas = "File lebih dari 500KB"
  }
  return errors;
}

ModalStrAdd = reduxForm({
  // a unique name for the form
  form: "ModalAddStr",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  shouldError: () => true,
})(ModalStrAdd);

function mapStateToProps({ addStr }) {
  let initialValues = {
    start_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD"),
    end_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD"),
  };
  return {
    initialValues,
    addStr_pending: getStrPending(addStr),
  };
}

export default connect(mapStateToProps, {
  addStr,
})(withRouter(ModalStrAdd));
