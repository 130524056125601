import {
    LIST_FINAL_PAPER_PENDING,
    LIST_FINAL_PAPER_SUCCESS,
    LIST_FINAL_PAPER_ERROR,
    DETAIL_FINAL_PAPER_PENDING,
    DETAIL_FINAL_PAPER_SUCCESS,
    DETAIL_FINAL_PAPER_ERROR,
    ADD_FINAL_PAPER_PENDING,
    ADD_FINAL_PAPER_SUCCESS,
    ADD_FINAL_PAPER_ERROR,
    SET_SELECTED_FINAL_PAPER,
    EDIT_FINAL_PAPER_PENDING,
    EDIT_FINAL_PAPER_SUCCESS,
    EDIT_FINAL_PAPER_ERROR,
    DELETE_FINAL_PAPER_PENDING,
    DELETE_FINAL_PAPER_SUCCESS,
    DELETE_FINAL_PAPER_ERROR,
} from "../../actions/cms-final-paper/cms_final_paper_action";

const initialState = {
    pending: false,
    error: null,
};

export function listFinalPaper(
    state = { ...initialState, list_benefit: null },
    action
) {
    switch (action.type) {
        case LIST_FINAL_PAPER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case LIST_FINAL_PAPER_SUCCESS:
            return {
                ...state,
                pending: false,
                list_benefit: action.data,
            };
        case LIST_FINAL_PAPER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function detailFinalPaper(
    state = { ...initialState, detail_benefit: null },
    action
) {
    switch (action.type) {
        case DETAIL_FINAL_PAPER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DETAIL_FINAL_PAPER_SUCCESS:
            return {
                ...state,
                pending: false,
                detail_benefit: action.data,
            };
        case DETAIL_FINAL_PAPER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function addFinalPaper(state = { ...initialState, addFinalPaper: null }, action) {
    switch (action.type) {
        case ADD_FINAL_PAPER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case ADD_FINAL_PAPER_SUCCESS:
            return {
                ...state,
                pending: false,
                addFinalPaper: action.data
            };
        case ADD_FINAL_PAPER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function selectedFinalPaper(state = null, action) {
    switch (action.type) {
        case SET_SELECTED_FINAL_PAPER:
            return action.value;
        default:
            return state;
    }
}

export function editFinalPaper(
    state = { ...initialState, editFinalPaper: null },
    action
) {
    switch (action.type) {
        case EDIT_FINAL_PAPER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case EDIT_FINAL_PAPER_SUCCESS:
            return {
                ...state,
                pending: false,
                editFinalPaper: action.data
            };
        case EDIT_FINAL_PAPER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export function deleteFinalPaper(
    state = { ...initialState, deleteFinalPaper: null },
    action
) {
    switch (action.type) {
        case DELETE_FINAL_PAPER_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_FINAL_PAPER_SUCCESS:
            return {
                ...state,
                pending: false,
                deleteFinalPaper: action.data
            };
        case DELETE_FINAL_PAPER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
}

export const getListFinalPaper = (state) => state.list_benefit;
export const getListFinalPaperPending = (state) => state.pending;
export const getListFinalPaperError = (state) => state.error;
export const getDetailFinalPaper = (state) => state.detail_benefit;
export const getSelectedFinalPaper = (state) => state.selectedFinalPaper;
export const getFinalPaperAddPending = (state) => state.pending;
export const getFinalPaperAdd = (state) => state.addFinalPaper;
export const getFinalPaperAddError = (state) => state.error;
export const getFinalPaperEditPending = (state) => state.pending;
export const getFinalPaperEdit = (state) => state.editFinalPaper;
export const getFinalPaperEditError = (state) => state.error;
export const getFinalPaperDeletePending = (state) => state.pending;
export const getFinalPaperDelete = (state) => state.deleteFinalPaper;
export const getFinalPaperDeleteError = (state) => state.error;