import React from "react";
import { string } from "prop-types";
import { StyleSheet, css } from "aphrodite";

function TitleComponent(props) {
  const { title } = props;
  return <h3 className={css(styles.detailMemberSubspecialist)}> {title} </h3>;
}

TitleComponent.propTypes = {
  title: string,
};

const styles = StyleSheet.create({
  detailMemberSubspecialist: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#7F8388",
    marginTop: 10,
    marginBottom: 0,
  },
});

export default TitleComponent;
