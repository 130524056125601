import React, { Component } from "react";
import { connect } from "react-redux";
import { Column, Row } from "simple-flexbox";
import { StyleSheet, css } from "aphrodite";

import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import NumberFormat from "react-number-format";
import SelectComponent from "../form/SelectComponent";
import { listMainDeposit } from "../../actions/dashboard/koperasi_action";
import {
  getMainDepositData,
  getMainDepositPending,
  getMainDepositError,
} from "../../reducers/dashboard/koperasi_reducer";
import DatePicker from 'react-date-picker';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

class CardKoperasiPokok extends Component {
  state = {
    year: new Date().getFullYear(),
    start_date: moment(new Date(new Date().getFullYear(), 0, 1)).format('yyyy-MM-DD'),
    end_date: moment(new Date(new Date().getFullYear(), 11, 31)).format('yyyy-MM-DD'),
  };

  onYearChange = (year) => {
    const { listMainDeposit } = this.props;
    const yearSelected = year ? year : new Date().getFullYear();
    this.setState({ year: yearSelected });
    listMainDeposit({ year: yearSelected });
  };

  componentDidMount() {
    const { listMainDeposit } = this.props;
    const { year } = this.state;
    let param = { start_date: this.state.start_date,
                  end_date: this.state.end_date
    };
    // listMainDeposit({ year: year });
    listMainDeposit(param);
  }
  handleStartDateChange = (e) =>{
    const { listMainDeposit } = this.props;
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.setState({ start_date: date });
    } else {
      this.setState({ start_date: null });
    }
    let param = { start_date: date,
      end_date: this.state.end_date
    };
    listMainDeposit(param);
  }

  handleEndDateChange = (e) => {
    const { listMainDeposit } = this.props;
    const date = moment(e).format('yyyy-MM-DD');
    if (e) {
      this.setState({ end_date: date });
    } else {
      this.setState({ end_date: null });
    }
    let param = { start_date: this.state.start_date,
      end_date: date
    };
    listMainDeposit(param);
  }

  render() {
    const { listMainDeposit_data } = this.props;
    const { year } = this.state;
    let total_main_deposit = null;
    let total_member_main_deposit = null;
    if (listMainDeposit_data) {
      total_main_deposit = listMainDeposit_data[0].total_main_deposit || 0;
      total_member_main_deposit = listMainDeposit_data[0].total_member;
    }
    let currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = currentYear; i >= 2019; i--) {
      yearOptions = [...yearOptions, { label: i, value: i }];
    }
    return (
      <div style={{ padding: "0 10px", width: "33.33%" }}>
        <Column
          style={{
            width: "100%",
            boxShadow: "0px 3px 6px #00000029",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <div className={css(styles.cardHeader)}></div>
          <Row
            className={css(styles.wrapperTop, styles.borderBottom)}
            style={{ width: "100%" }}
            vertical="center"
          >
            <Row
              vertical="center"
              horizontal="center"
              style={{
                height: 48,
                width: 48,
                backgroundColor: "#5AB853",
                borderRadius: 8,
                marginRight: 20,
              }}
            >
              <AccountBalanceWalletIcon
                style={{ fontSize: 28, color: "#B8E1C7" }}
              />
            </Row>
            <div style={{}}>
              <label className={css(styles.titleLabel)}>
                {" "}
                Simpanan Pokok
                <br /> Koperasi
              </label>
            </div>
          </Row>
          <Column
            className={css(styles.wrapper, styles.borderBottom)}
            style={{ width: "100%", padding: 20 }}
          >
            <Row style={{ width: "100%" }}>
            <div
                style={{ margin: "auto", padding: "12px 5px", width: "50%" }}
              >
              <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
                    {/* Tanggal Awal */}
                <KeyboardDatePicker
                  margin="dense"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  value={this.state.start_date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(e) => this.handleStartDateChange(e)}
                />
              </MuiPickersUtilsProvider>
              </div>
              <div
                style={{ margin: "auto", padding: "12px 5px", width: "50%" }}
              >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
                {/* Tanggal Awal */}
                    <KeyboardDatePicker
                      
                      margin="dense"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      value={this.state.end_date}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      // onChange={this.handleStartDateChange}
                      onChange={(e) => this.handleEndDateChange(e)}
                      

                    />
                  </MuiPickersUtilsProvider>
              </div>
            </Row>
            {/* <div
              style={{ margin: "auto", padding: "12px 40px", width: "50%" }}
            >
              <SelectComponent
                options={yearOptions}
                isRounded
                placeholder="Pilih tahun"
                defaultValue={{ value: year, label: year }}
                onChange={(e) => this.onYearChange(e?.value)}
              />
            </div> */}
            <div
              style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}
            >
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                prefix="Rp "
                displayType={"text"}
                value={total_main_deposit}
              />
            </div>
            <div
              style={{ color: "#49505799", textAlign: "center", padding: 12 }}
            >
              {" "}
              Dibayar oleh{" "}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                displayType={"text"}
                value={total_member_main_deposit}
              />{" "}
              Anggota
            </div>
          </Column>
        </Column>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  titleLabel: {
    color: "#495057",
    fontSize: 18,
    fontWeight: "bold",
    margin: "5px 0",
  },
  cardLabel: {
    color: "#495057",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  wrapper: {
    padding: 15,
    // height: 180
  },
  wrapperTop: {
    padding: 24,
    // height: 180
  },
  borderBottom: {
    borderBottom: "1px solid #eeee",
  },
  cardHeader: {
    backgroundColor: "#5AB853",
    height: 10,
    width: "100%",
  },
});

function mapStateToProps({ listMainDeposit }) {
  return {
    listMainDeposit_data: getMainDepositData(listMainDeposit),
    listMainDeposit_error: getMainDepositError(listMainDeposit),
    listMainDeposit_pending: getMainDepositPending(listMainDeposit),
  };
}

export default connect(mapStateToProps, { listMainDeposit })(CardKoperasiPokok);
