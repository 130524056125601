import React, { Component } from "react";
import { renderSelect } from "../../components/form/FormComponent";
import { Field } from "redux-form";
import { Row, Column } from "simple-flexbox";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";

import { getUniversityList } from "../../reducers/master-data/university_reducer";
import { getBranchPending } from "../../reducers/master-data/branch_reducer";
import {
  getProvincePending,
  getListProvince,
} from "../../reducers/master-data/province_temp_reducer";
import { formValueSelector } from "redux-form"; // ES6

class RenderArray extends Component {
  render() {
    const {
      fields,
      meta: { submitFailed, error },
      listProvice_data,
      names,
    } = this.props;

    let province_options = [];
    if (listProvice_data) {
      // const names_id = [];
      const names_id = names ? names.map((item) => item && item.value) : [];
      province_options = listProvice_data.data
        .filter((item) => names_id.indexOf(item.province_id) === -1)
        .map((item) => ({
          value: item.province_id,
          label: item.name,
        }));

    }

    return (
      <>
        {submitFailed && error && (
          <Row
            style={{
              height: 38,
              color: "#ED2A2A",
              borderRadius: 3,
              marginTop: 5,
              marginBottom: 10,
              backgroundColor: "#FFDEDE",
            }}
            horizontal="center"
            vertical="center"
          >
            <span>
              <ErrorIcon style={{ marginRight: 10 }} />
              {error}
            </span>
          </Row>
        )}
        {fields.map((province, index) => (
          <Row
            horizontal="space-between"
            key={index}
            style={{ marginBottom: 5 }}
          >
            <div className={css(styles.Selectcontainer)}>
              <Field
                name={province}
                type="text"
                options={province_options}
                component={renderSelect}
              />
            </div>
            <CancelIcon
              className={css(styles.closeButton)}
              onClick={() => fields.remove(index)}
            />
          </Row>
        ))}

        <Column
          horizontal="center"
          vertical="center"
          className={css(styles.buttonContainer)}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "2px solid #EDEDEE",
              top: 18,
              position: "relative",
            }}
          />
          <div
            style={{ padding: "0px 10px", backgroundColor: "white", zIndex: 0 }}
          >
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              // onClick={this.handleSubmit}
              startIcon={<AddIcon />}
              onClick={() => fields.push()}
            >
              Tambah
            </Button>
          </div>
        </Column>
      </>
    );
  }
}

const styles = StyleSheet.create({
  closeButton: {
    color: "#CDCFD1",
    marginTop: 7,
    ":hover": {
      color: "#ED2A2A",
    },
  },
  Selectcontainer: { width: "90%" },
  approveButton: {
    zIndex: 0,
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
});

const selector = formValueSelector("ModalAddBranch");

function mapStateToProps({
  universityList,
  detailBranch,
  listProvincetTemp,
  ...restState
}) {
  const names = selector(restState, "province");

  return {
    universityList_data: getUniversityList(universityList),
    detailBranch_pending: getBranchPending(detailBranch),
    listProvice_pending: getProvincePending(listProvincetTemp),
    listProvice_data: getListProvince(listProvincetTemp),
    names,
  };
}

export default connect(mapStateToProps)(RenderArray);
