import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import {
  detailMember,
  editMember,
  uploadBerkas,
} from "../../actions/member/member_action";

import {
  getDetailMember,
  getMemberPending,
  getBerkasUpload,
  getBerkasUploadPending,
  getBerkasUploadError,
} from "../../reducers/member/member_reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DetailNameComponent from "../../components/detail/DetailNameComponent";
import FormContainer from "../../components/container/FormContainer";
import EditButtonComponent from "../../components/form/EditButtonComponent";
import { reduxForm, Field } from "redux-form";
import { renderFile } from "../../components/form/FormComponent";
import DetailContentLihatBerkas from "../../components/detail/DetailContentLihatBerkas";
import ModalDetailDokumen from "./Modal_document_pdf";
import ModalDetailPhoto from "./Modal_document";
import { getUser } from "../../helpers/user";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import { StyleSheet, css } from "aphrodite";

class ModalBerkasPendukung extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      modalDetailDokumenShow: false,
      modalDetailPhotoShow: false,
      modalShow: this.props.show,
    };
    this.handleTriggerFormEdit = this.handleTriggerFormEdit.bind(this);
    this.handleOpenDocument = this.handleOpenDocument.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTriggerFormEdit() {
    const { reset } = this.props;
    this.setState({ isEdit: !this.state.isEdit });
    reset();
  }

  handleCloseModal(newvalue) {
    const { handleCloseButton } = this.props;
    handleCloseButton(newvalue);
  }

  handleOpenDocument(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDetailDokumenShow: true });
  }

  handleOpenPhoto(imgUrl, modal_title) {
    this.setState({ imgUrl: imgUrl });
    this.setState({ modal_title: modal_title });
    this.setState({ modalDetailPhotoShow: true });
  }

  handleSubmit(values) {
    const callback = () => {
      const id = getUser().id;
      this.props.detailMember(id);
      this.setState({ isEdit: !this.state.isEdit });
      this.props.onHide();
      window.location.reload();
    };
    let formData = new FormData();
    if (values.health_information_file) {
      formData.append(
        "health_information_file",
        values.health_information_file
      );
    }
    if (values.scan_ktp_file) {
      formData.append("scan_ktp_file", values.scan_ktp_file);
    }
    if (values.photo) {
      formData.append("additional_file", values.photo);
    }
    this.props.uploadBerkas(formData, callback);
  }

  render() {
    const { isEdit } = this.state;
    const { editEducation_pending, detailMember_data, handleSubmit } =
      this.props;
    let data,
      health_information_image,
      scan_ktp_image,
      additional_file = "";
    if (detailMember_data && detailMember_data.length > 0) {
      data = detailMember_data[0].member_profile;
      health_information_image = data.health_information_image;
      scan_ktp_image = data.scan_ktp_image;
      additional_file = data.additional_file_image;
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className={css(styles.body)}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            style={{ width: "100%" }}
          >
            <ModalDetailDokumen
              show={this.state.modalDetailDokumenShow}
              onHide={() => this.setState({ modalDetailDokumenShow: false })}
              imgUrl={this.state.imgUrl}
              modal_title={this.state.modal_title}
              folder="member_supporting_files"
            />
            <ModalDetailPhoto
              show={this.state.modalDetailPhotoShow}
              onHide={() => this.setState({ modalDetailPhotoShow: false })}
              imgUrl={this.state.imgUrl}
              modal_title={this.state.modal_title}
              folder="member_supporting_files"
            />
            <Row
              vertical="center"
              horizontal="space-between"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <DetailNameComponent name="Berkas Pendukung" />
              <EditButtonComponent
                isEdit={isEdit}
                triggerIsEdit={this.handleTriggerFormEdit}
                isLoading={editEducation_pending}
                isBerkasPendukung={true}
                closeModal={this.handleCloseModal}
              />
            </Row>
            <FormContainer label="Kartu Identitas">
              {isEdit ? (
                <div>
                  <Field
                    name="scan_ktp_file"
                    type="file"
                    fileType="pdf/image"
                    title="Pilih Berkas"
                    message="JPG/PDF"
                    component={renderFile}
                  />
                  <span style={{ float: "right" }}>(Max File 1 MB)</span>
                </div>
              ) : scan_ktp_image === null ? (
                <span>Belum ada berkas</span>
              ) : scan_ktp_image ? (
                scan_ktp_image.includes(".pdf") ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(scan_ktp_image, "Kartu Identitas")
                    }
                  />
                ) : (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenPhoto(scan_ktp_image, "Kartu Identitas")
                    }
                  />
                )
              ) : (
                ""
              )}
            </FormContainer>
            <FormContainer label="Surat Keterangan Sehat">
              {isEdit ? (
                <div>
                  <Field
                    name="health_information_file"
                    type="file"
                    fileType="pdf/image"
                    title="Pilih Berkas"
                    message="JPG/PDF"
                    component={renderFile}
                  />
                  <span style={{ float: "right" }}>(Max File 1 MB)</span>
                </div>
              ) : health_information_image === null ? (
                <span>Belum ada berkas</span>
              ) : health_information_image ? (
                health_information_image.includes(".pdf") ? (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenDocument(
                        health_information_image,
                        "Surat Keterangan Sehat"
                      )
                    }
                  />
                ) : (
                  <DetailContentLihatBerkas
                    onClick={() =>
                      this.handleOpenPhoto(
                        health_information_image,
                        "Surat Keterangan Sehat"
                      )
                    }
                  />
                )
              ) : (
                ""
              )}
            </FormContainer>
            <FormContainer label="Pas Foto">
              {isEdit ? (
                <div>
                  <Field
                    name="photo"
                    type="image"
                    fileType="image"
                    title="Pilih Foto"
                    message="JPG (Background berwarna merah)"
                    component={renderFile}
                  />
                  <span style={{ float: "right" }}>(Max File 1 MB)</span>
                </div>
              ) : additional_file === null ? (
                <span>Belum ada foto</span>
              ) : (
                <DetailContentLihatBerkas
                  onClick={() =>
                    this.handleOpenPhoto(additional_file, "Pas Foto")
                  }
                />
              )}
            </FormContainer>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    margin: 10,
  },
});

function validate(values, { editEducation_isEditFile }) {
  const fileMaxSize = 1 * 1000 * 1000; // 1MB
  const typeImage = /image/;
  const fileType = "application/pdf";
  const { berkas } = values;
  const errors = {};
  if (
    values.scan_ktp_file &&
    !values.scan_ktp_file.type.match(typeImage) &&
    values.scan_ktp_file.type !== fileType
  ) {
    errors.scan_ktp_file = "File hanya bisa Image atau PDF";
  } else if (values.scan_ktp_file && values.scan_ktp_file.size > fileMaxSize) {
    errors.scan_ktp_file = "File tidak boleh lebih dari 1 MB";
  }
  if (
    values.health_information_file &&
    !values.health_information_file.type.match(typeImage) &&
    values.health_information_file.type !== fileType
  ) {
    errors.health_information_file = "File hanya bisa Image atau PDF";
  } else if (
    values.health_information_file &&
    values.health_information_file.size > fileMaxSize
  ) {
    errors.health_information_file = "File tidak boleh lebih dari 1 MB";
  }
  if (values.photo && !values.photo.type.match(typeImage)) {
    errors.photo = "File hanya bisa Image";
  } else if (values.photo && values.photo.size > fileMaxSize) {
    errors.photo = "File tidak boleh lebih dari 1 MB";
  }
  if (editEducation_isEditFile && !berkas) {
    errors.berkas = "Berkas pendidikan wajib diisi";
  }

  return errors;
}

ModalBerkasPendukung = reduxForm({
  // a unique name for the form
  form: "ModalBerkasPendukung",
  validate: validate,
  // keepDirtyOnReinitialize: true,
  shouldError: () => true,
  enableReinitialize: true,
})(ModalBerkasPendukung);

function mapStateToProps({ detailMember, editMember, uploadBerkas }) {
  return {
    detailMember_data: getDetailMember(detailMember),
    detailMember_pending: getMemberPending(detailMember),
    editMember_data: getDetailMember(detailMember),
    editMember_pending: getMemberPending(editMember),
    uploadBerkas_pending: getBerkasUploadPending(uploadBerkas),
    uploadBerkas_data: getBerkasUpload(uploadBerkas),
    uploadBerkas_error: getBerkasUploadError(uploadBerkas),
  };
}

export default connect(mapStateToProps, {
  detailMember,
  editMember,
  uploadBerkas,
})(withRouter(ModalBerkasPendukung));
