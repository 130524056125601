import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Column, Row } from "simple-flexbox";
import { withRouter } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import { StyleSheet, css } from "aphrodite";
import Button from "@material-ui/core/Button";
import { registrationList, ppdsStatusApproval} from "../../actions/registrasi_sp2/registrasi_action";
import { getRegistrationListPending, getRegistrationList, getRegistrationListError, getppdsStatusApproval, getppdsStatusApprovalPending, getppdsStatusApprovalError} from "../../reducers/registrasi/registrasi_reducer";
import { connect } from "react-redux";

class ModalRejectPPDS extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  rejectPPDSStatus(id) {
    let param_ppdsStatusApproval = {
        id: id,
        is_approve: "false"
    }
    this.props.ppdsStatusApproval(param_ppdsStatusApproval)
    let param_registrationList = {
        page: 1,
        length: 1,
        search_text: "",
        id: id
    };
    this.props.registrationList(param_registrationList);
    this.props.history.push(`/registrasi-sp2`)
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        id={this.props.id}
        dialogClassName="modal-width"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <p style={{ fontSize: 20, fontWeight: 600 }}>
            Konfirmasi
            </p>
        </Modal.Header>
        <Modal.Body>
        <Column className={css(styles.bodyModal)}>
        <span>Apakah Anda yakin peserta ini gagal tes PPDS?</span>
        </Column>
          <Row
            horizontal="flex-end"
            vertical="center"
            className={css(styles.footerModal)}
          >
              <Button
              variant="contained"
              color="primary"
              className={css(styles.rejectButton)}
              startIcon={<ClearIcon />}
              onClick={this.props.onHide}
            >
              Batal
              </Button>
            <Button
              variant="contained"
              color="primary"
              className={css(styles.approveButton)}
              startIcon={<CheckIcon />}
              onClick={() => this.rejectPPDSStatus(this.props.id)}
            >
              Ya
              </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  approveButton: {
    background: "#2CB96A 0% 0% no-repeat padding-box",
    boxShadow: "none",
  },
  rejectButton: {
    background: "#EAEBED 0% 0% no-repeat padding-box",
    marginRight: 10,
    color: "#495057",
    boxShadow: "none",
  },
  footerModal: {
    marginTop: 20,
    backgroundColor: "#FBFBFD",
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
  },
  bodyModal: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  labelMargin: {
    marginTop: 10,
    marginBottom: 10
  },
});

function mapStateToProps({ registrationList, ppdsStatusApproval }) {
  return {
    registrationList_pending: getRegistrationListPending(registrationList),
    registrationList_data: getRegistrationList(registrationList),
    registrationList_error: getRegistrationListError(registrationList),
    ppdsStatusApproval_pending: getppdsStatusApprovalPending(ppdsStatusApproval),
    ppdsStatusApproval_data: getppdsStatusApproval(ppdsStatusApproval),
    ppdsStatusApproval_error: getppdsStatusApprovalError(ppdsStatusApproval),
  };
}


export default connect(mapStateToProps, { registrationList, ppdsStatusApproval })(withRouter(ModalRejectPPDS));
