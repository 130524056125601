import {
  LIST_APPRECIATION_PENDING,
  LIST_APPRECIATION_SUCCESS,
  LIST_APPRECIATION_ERROR,
  DETAIL_APPRECIATION_PENDING,
  DETAIL_APPRECIATION_SUCCESS,
  DETAIL_APPRECIATION_ERROR,
  EDIT_APPRECIATION_PENDING,
  EDIT_APPRECIATION_SUCCESS,
  EDIT_APPRECIATION_ERROR,
  DELETE_APPRECIATION_PENDING,
  DELETE_APPRECIATION_SUCCESS,
  DELETE_APPRECIATION_ERROR,
  ADD_APPRECIATION_PENDING,
  ADD_APPRECIATION_SUCCESS,
  ADD_APPRECIATION_ERROR,
  SET_IS_EDIT_FILE,
} from "../../actions/member/appreciation_action";

const initialState = {
  pending: false,
  error: null,
};

export function listAppreciation(
  state = { ...initialState, list_appreciation: null },
  action
) {
  switch (action.type) {
    case LIST_APPRECIATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_APPRECIATION_SUCCESS:
      return {
        ...state,
        pending: false,
        list_appreciation: action.data,
      };
    case LIST_APPRECIATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function detailAppreciation(
  state = { ...initialState, detail_appreciation: null },
  action
) {
  switch (action.type) {
    case DETAIL_APPRECIATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_APPRECIATION_SUCCESS:
      return {
        ...state,
        pending: false,
        detail_appreciation: action.data,
      };
    case DETAIL_APPRECIATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function editAppreciation(
  state = { ...initialState, isEditFile: false },
  action
) {
  switch (action.type) {
    case EDIT_APPRECIATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_APPRECIATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_APPRECIATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_IS_EDIT_FILE:
      return {
        ...state,
        isEditFile: action.value,
      };
    default:
      return state;
  }
}

export function addAppreciation(state = initialState, action) {
  switch (action.type) {
    case ADD_APPRECIATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_APPRECIATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ADD_APPRECIATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteAppreciation(state = initialState, action) {
  switch (action.type) {
    case DELETE_APPRECIATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_APPRECIATION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_APPRECIATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListAppreciation = (state) => state.list_appreciation;
export const getDetailAppreciation = (state) => state.detail_appreciation;
export const getAppreciationPending = (state) => state.pending;
export const getAppreciationError = (state) => state.error;
export const getAppreciationIsEditFile = (state) => state.isEditFile;
