import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError } from "../../components/toast/Toast";

export const LIST_SUBSPECIALIST_PENDING = "LIST_SUBSPECIALIST_PENDING_ADMIN";
export const LIST_SUBSPECIALIST_SUCCESS = "LIST_SUBSPECIALIST_SUCCESS_ADMIN";
export const LIST_SUBSPECIALIST_ERROR = "LIST_SUBSPECIALIST_ERROR_ADMIN";

export const LIST_TOTALMEMBER_PENDING = "LIST_TOTALMEMBER_PENDING_ADMIN";
export const LIST_TOTALMEMBER_SUCCESS = "LIST_TOTALMEMBER_SUCCESS_ADMIN";
export const LIST_TOTALMEMBER_ERROR = "LIST_TOTALMEMBER_ERROR_ADMIN";

export const LIST_TOTALMEMBER_CABANG_PENDING = "LIST_TOTALMEMBER_PENDING_ADMIN_CABANG";
export const LIST_TOTALMEMBER_CABANG_SUCCESS = "LIST_TOTALMEMBER_SUCCESS_ADMIN_CABANG";
export const LIST_TOTALMEMBER_CABANG_ERROR = "LIST_TOTALMEMBER_ERROR_ADMIN_CABANG";

export const LIST_ESTIMATIONFEE_PENDING = "LIST_ESTIMATIONFEE_PENDING_ADMIN";
export const LIST_ESTIMATIONFEE_SUCCESS = "LIST_ESTIMATIONFEE_SUCCESS_ADMIN";
export const LIST_ESTIMATIONFEE_ERROR = "LIST_ESTIMATIONFEE_ERROR_ADMIN";

export const LIST_TOTALFEEIN_PENDING = "LIST_TOTALFEEIN_PENDING_ADMIN";
export const LIST_TOTALFEEIN_SUCCESS = "LIST_TOTALFEEIN_SUCCESS_ADMIN";
export const LIST_TOTALFEEIN_ERROR = "LIST_TOTALFEEIN_ERROR_ADMIN";

export const LIST_ALLOCATION_PENDING = "LIST_ALLOCATION_PENDING_ADMIN";
export const LIST_ALLOCATION_SUCCESS = "LIST_ALLOCATION_SUCCESS_ADMIN";
export const LIST_ALLOCATION_ERROR = "LIST_ALLOCATION_ERROR_ADMIN";

export const LIST_COOPERATIVE_MEMBERHSIP_PENDING = "LIST_COOPERATIVE_MEMBERHSIP_PENDING_ADMIN";
export const LIST_COOPERATIVE_MEMBERHSIP_SUCCESS = "LIST_COOPERATIVE_MEMBERHSIP_SUCCESS_ADMIN";
export const LIST_COOPERATIVE_MEMBERHSIP_ERROR = "LIST_COOPERATIVE_MEMBERHSIP_ERROR_ADMIN";


const LIST_SUBSPECIALIST_URL = "dashboard/admin/subspecialist_doctor";
const LIST_TOTALMEMBER_URL = "dashboard/admin/total_member";
const LIST_ESTIMATIONFEE_URL = "dashboard/admin/estimation_fee";
const LIST_TOTALFEEIN_URL = "dashboard/admin/total_fee_in";
const LIST_ALLOCATION_URL = "dashboard/admin/total_allocation";
const LIST_COOPERATIVE_URL = "dashboard/admin/cooperative_membership";

export function listCooperative(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_COOPERATIVE_MEMBERHSIP_PENDING));
        API.post(LIST_COOPERATIVE_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_COOPERATIVE_MEMBERHSIP_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_COOPERATIVE_MEMBERHSIP_ERROR, error));
                toastError(error, "Gagal mendapatkan data COOPERATIVE MEMBERSHIP, ");
            });
    };
}

export function listSubspecialist(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_SUBSPECIALIST_PENDING));
        API.post(LIST_SUBSPECIALIST_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_SUBSPECIALIST_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_SUBSPECIALIST_ERROR, error));
                toastError(error, "Gagal mendapatkan data SUBSPECIALIST, ");
            });
    };
}
export function listTotalMember(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALMEMBER_PENDING));
        API.post(LIST_TOTALMEMBER_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALMEMBER_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALMEMBER_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL MEMBER, ");
            });
    };
}

export function listTotalMemberCabang(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALMEMBER_CABANG_PENDING));
        API.post(LIST_TOTALMEMBER_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALMEMBER_CABANG_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALMEMBER_CABANG_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL MEMBER, ");
            });
    };
}
export function listEstimationFee(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ESTIMATIONFEE_PENDING));
        API.post(LIST_ESTIMATIONFEE_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ESTIMATIONFEE_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ESTIMATIONFEE_ERROR, error));
                toastError(error, "Gagal mendapatkan data ESTIMATION FEE, ");
            });
    };
}
export function listTotalFeeIn(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_TOTALFEEIN_PENDING));
        API.post(LIST_TOTALFEEIN_URL, null, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_TOTALFEEIN_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_TOTALFEEIN_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL FEE IN, ");
            });
    };
}
export function listAllocation(param = {}) {
    return (dispatch) => {
        dispatch(actionPending(LIST_ALLOCATION_PENDING));
        API.post(LIST_ALLOCATION_URL, param, getHeader())
            .then(({ data }) => {
                dispatch(actionSuccess(LIST_ALLOCATION_SUCCESS, data));
                return data;
            })
            .catch((error) => {
                dispatch(actionError(LIST_ALLOCATION_ERROR, error));
                toastError(error, "Gagal mendapatkan data TOTAL ALLOCATION, ");
            });
    };
}




