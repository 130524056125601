import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";

export const LIST_PPDS_PENDING = "LIST_PPDS_PENDING";
export const LIST_PPDS_SUCCESS = "LIST_PPDS_SUCCESS";
export const LIST_PPDS_ERROR = "LIST_PPDS_ERROR";

export const DETAIL_PPDS_PENDING = "DETAIL_PPDS_PENDING";
export const DETAIL_PPDS_SUCCESS = "DETAIL_PPDS_SUCCESS";
export const DETAIL_PPDS_ERROR = "DETAIL_PPDS_ERROR";

export const ADD_PPDS_PENDING = "ADD_PPDS_PENDING";
export const ADD_PPDS_SUCCESS = "ADD_PPDS_SUCCESS";
export const ADD_PPDS_ERROR = "ADD_PPDS_ERROR";

export const EDIT_PPDS_PENDING = "EDIT_PPDS_PENDING";
export const EDIT_PPDS_SUCCESS = "EDIT_PPDS_SUCCESS";
export const EDIT_PPDS_ERROR = "EDIT_PPDS_ERROR";

export const APPROVE_PPDS_PENDING = "APPROVE_PPDS_PENDING";
export const APPROVE_PPDS_SUCCESS = "APPROVE_PPDS_SUCCESS";
export const APPROVE_PPDS_ERROR = "APPROVE_PPDS_ERROR";

export const UPLOAD_PPDS_PENDING = "UPLOAD_PPDS_PENDING";
export const UPLOAD_PPDS_SUCCESS = "UPLOAD_PPDS_SUCCESS";
export const UPLOAD_PPDS_ERROR = "UPLOAD_PPDS_ERROR";


export const DELETE_PPDS_PENDING = "DELETE_PPDS_PENDING";
export const DELETE_PPDS_SUCCESS = "DELETE_PPDS_SUCCESS";
export const DELETE_PPDS_ERROR = "DELETE_PPDS_ERROR";


export const SET_IS_EDIT_FILE = "SET_IS_EDIT_FILE_PPDS";

const LIST_PPDS_URL = "member/ppds/education/find";
const LIST_PPDS_URL_ADMIN = "member/ppds/report/find";
const UPLOAD_PPDS_URL = "image/upload/ppds_supporting_files";
const EDIT_PPDS_URL = "member/ppds/education/edit";
const ADD_PPDS_URL = "member/ppds/education/add";
const DELETE_PPDS_URL = "member/ppds/education/delete";

const APPROVE_PPDS_URL = "member/education/approve";

export function listPPDS(param = {}, resolve) {
  // param.user_id = getUser().id;
  return (dispatch) => {
    dispatch(actionPending(LIST_PPDS_PENDING));
    API.post(LIST_PPDS_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_PPDS_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * param.length,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_PPDS_ERROR, error));
        toastError(error, "Gagal mendapatkan list pendidikan, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function listPPDSAdmin(param = {}, resolve) {
  // param.user_id = getUser().id;
  return (dispatch) => {
    dispatch(actionPending(LIST_PPDS_PENDING));
    API.post(LIST_PPDS_URL_ADMIN, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_PPDS_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * param.length,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_PPDS_ERROR, error));
        toastError(error, "Gagal mendapatkan list pendidikan, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function addPPDS(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(ADD_PPDS_PENDING));
    API.post(ADD_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(ADD_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menambah PPDS");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(ADD_PPDS_ERROR, error));
        toastError(error, "Gagal menambah PPDS, ");
      });
  };
}

export function editPPDS(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_PPDS_PENDING));
    API.post(EDIT_PPDS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(EDIT_PPDS_SUCCESS, res.data));
        toastSuccess("Berhasil mengubah data PPDS");

        if (resolve) {
          resolve();
        }
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_PPDS_ERROR, error));
        toastError(error, "Gagal mengubah data PPDS, ");
      });
  };
}


{/*export function editPPDS({ filename, ...restParam }, callback) {
  return (dispatch) => {
    dispatch(actionPending(EDIT_PPDS_PENDING));
    new Promise((resolve, reject) => {
      if (filename && restParam.isEditFile) {
        var formData = new FormData();
        formData.append("file", filename);
        API.post(UPLOAD_PPDS_URL, formData, getHeader())
          .then((res) => {
            resolve(res.data.filename);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // return null;
        resolve(null);
      }
    })
      .then((filename) => {
        if (filename && restParam.isEditFile) {
          restParam.filename = filename;
        }
        else {
          restParam.filename = restParam.oldFilename
        }
        return API.post(EDIT_PPDS_URL, restParam, getHeader());
      })
      .then((res) => {
        dispatch(actionSuccess(EDIT_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah PPDS");
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_PPDS_ERROR, error));
        toastError(error, "Gagal mengubah pendidikan, ");
      });
  };
} */}

export function detailPPDS(id = null) {
  const param = {
    // user_id: getUser().id,
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_PPDS_PENDING));
    API.post(LIST_PPDS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_PPDS_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_PPDS_ERROR, error));
        toastError(error, "Gagal mendapatkan list pendidikan, ");
      });
  };
}

export function approvePPDS(param, callback) {
  return (dispatch) => {
    dispatch(actionPending(APPROVE_PPDS_PENDING));
    API.post(APPROVE_PPDS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(APPROVE_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah status pendidikan");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_PPDS_ERROR, error));
        toastError(error, "Gagal mengubah status pendidikan, ");
      });
  };
}

export function uploadPPDS(file, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_PPDS_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(UPLOAD_PPDS_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_PPDS_SUCCESS, res.data.filename));
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_PPDS_ERROR, error));
        if (error_flag) {
          error_flag();
        }
      });
  };
}

export function deletePPDS(param, callback) {
  const paramId = {
    'id': param
  }
  return (dispatch) => {
    dispatch(actionPending(DELETE_PPDS_PENDING));
    API.post(DELETE_PPDS_URL, paramId, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DELETE_PPDS_SUCCESS, res.data.data));
        toastSuccess("Berhasil menghapus PPDS");
        callback();
        return res;
      })
      .catch((error) => {
        dispatch(actionError(APPROVE_PPDS_ERROR, error));
        toastError(error, "Gagal menghapus PPDS, ");
      });
  };
}

export function setIsEditFile(value) {
  return {
    type: SET_IS_EDIT_FILE,
    value: value,
  };
}
