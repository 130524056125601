import { SET_SELECTED_HEADER } from "../../actions/header/header_action";
import { SET_SELECTED_LINK_HEADER } from "../../actions/header/header_action";

const initialState = [];

export function setSelectedHeader(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_HEADER:
      return action.value;
    default:
      return state;
  }
}


export function setSelectedLinkHeader(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_LINK_HEADER:
      return action.value;
    default:
      return state;
  }
}
