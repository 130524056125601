import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import { toast } from "react-toastify";
import getHeader from "../header";
import { toastSuccess, toastError } from "../../components/toast/Toast";
import { getUser } from "../../helpers/user";
import { history } from "../../helpers/history";

export const MEMBER_LIST_PENDING = "MEMBER_LIST_PENDING";
export const MEMBER_LIST_SUCCESS = "MEMBER_LIST_PENDING_SUCCESS";
export const MEMBER_LIST_ERROR = "MEMBER_LIST_PENDING_ERROR";
export const MEMBER_LIST_ID_PENDING = "MEMBER_LIST_ID_PENDING";
export const MEMBER_LIST_ID_SUCCESS = "MEMBER_LIST_ID_SUCCESS";
export const MEMBER_LIST_ID_ERROR = "MEMBER_LIST_ID_ERROR";
export const CHANGE_MEMBER_STATUS_PENDING = "CHANGE_MEMBER_STATUS_PENDING";
export const CHANGE_MEMBER_STATUS_SUCCESS =
  "CHANGE_MEMBER_STATUS_PENDING_SUCCESS";
export const CHANGE_MEMBER_STATUS_ERROR = "CHANGE_MEMBER_STATUS_PENDING_ERROR";
export const DETAIL_MEMBER_SUCCESS = "DETAIL_MEMBER_SUCCESS";
export const DETAIL_MEMBER_ERROR = "DETAIL_MEMBER_ERROR";
export const DETAIL_MEMBER_PENDING = "DETAIL_MEMBER_PENDING";
export const MEMBER_P2KB_EXISTING_SUCCESS = "MEMBER_P2KB_EXISTING_SUCCESS";
export const MEMBER_P2KB_EXISTING_ERROR = "MEMBER_P2KB_EXISTING_ERROR";
export const MEMBER_P2KB_EXISTING_PENDING = "MEMBER_P2KB_EXISTING_PENDING";

export const SET_SELECTED_MEMBER = "SET_SELECTED_MEMBER";

export const EDIT_MEMBER_SUCCESS = "MEMBER_LIST_PENDING_SUCCESS";
export const EDIT_MEMBER_ERROR = "MEMBER_LIST_PENDING_ERROR";
export const EDIT_MEMBER_PENDING = "MEMBER_LIST_PENDING";
export const UPLOAD_BERKAS_SUCCESS = "UPLOAD_BERKAS_SUCCESS";
export const UPLOAD_BERKAS_ERROR = "UPLOAD_BERKAS_ERROR";
export const UPLOAD_BERKAS_PENDING = "UPLOAD_BERKAS_PENDING";

export const MEMBER_CHANGE_PASSWORD_PENDING = "MEMBER_CHANGE_PASSWORD_PENDING";
export const MEMBER_CHANGE_PASSWORD_SUCCESS = "MEMBER_CHANGE_PASSWORD_SUCCESS";
export const MEMBER_CHANGE_PASSWORD_ERROR = "MEMBER_CHANGE_PASSWORD_ERROR";

const MEMBER_LIST_URL = "member/member/find";
const MEMBER_LIST__ID_URL = "member/member/count_for_message_blast";
const MEMBER_PPDS_URL = "member/ppds/find";

const EDIT_MEMBER_URL = "member/member/edit_profile";
const EDIT_PPDS_URL = "member/ppds/edit";
const CHANGE_MEMBER_STATUS_URL = "member/member/change_status";
const UPLOAD_BERKAS_URL = "member/member/edit_supporting_files";
const MEMBER_P2KB_EXISTING_URL = "member/member/find_str_sip_serkom_existing";

const MEMBER_CHANGE_PASSWORD_URL = "auth/send_email_change_password";

export function memberList(param = {}, resolve, reject, callback, checklist) {
  return (dispatch) => {
    dispatch(actionPending(MEMBER_LIST_PENDING));
    API.post(MEMBER_LIST_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(MEMBER_LIST_SUCCESS, res.data));
        const { records_total } = res.data;
        let data = res.data.data.map((item, i) => ({
          no: i + 1 + (param.page - 1) * param.length,
          ...item,
          status_member_label:
            item.member_profile.status === 0
              ? "NONACTIVE"
              : item.member_profile.status === 1
              ? "ACTIVE"
              : item.member_profile.status === 2
              ? "PENSION"
              : "DEPARTED",
          ...item,
          records_total: res.data.records_total,
          ...item,
          member_status: item.member_profile.status,
          tableData: {
            checked: checklist
              ? checklist.find((id) => id === item.id) != null
              : null,
          },
        }));
        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(MEMBER_LIST_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function memberListId(param = {}, resolve, reject, callback, checklist) {
  return (dispatch) => {
    dispatch(actionPending(MEMBER_LIST_ID_PENDING));
    API.post(MEMBER_LIST__ID_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(MEMBER_LIST_ID_SUCCESS, res.data));
        const { total_data } = res.data;
        let data = res.data.member_ids;
        if (resolve) {
          resolve({
            data: data,
            page: param.page - 1,
            totalCount: total_data,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(MEMBER_LIST_ID_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function changeMemberStatus(param = {}, resolve, callback) {
  return (dispatch) => {
    dispatch(actionPending(CHANGE_MEMBER_STATUS_PENDING));
    API.post(CHANGE_MEMBER_STATUS_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(CHANGE_MEMBER_STATUS_SUCCESS, res.data));
        const { records_total } = res.data;
        if (resolve) {
          resolve({
            data: res.data,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(actionError(CHANGE_MEMBER_STATUS_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}

export function detailMember(id = null, serkom_id = null) {
  const api =
    getUser().role_codes[0] === "PPDS" ? MEMBER_PPDS_URL : MEMBER_LIST_URL;
  const param = {
    // user_id: getUser().id,
    id,
    serkom_id,
  };
  return (dispatch) => {
    dispatch(actionPending(DETAIL_MEMBER_PENDING));
    API.post(api, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(DETAIL_MEMBER_SUCCESS, res.data.data));
        return res;
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_MEMBER_PENDING, error));
        toastError(error, "Gagal mendapatkan list member, ");
      });
  };
}

export function memberP2kbExisting(param = {}, callback) {
  return (dispatch) => {
    dispatch(actionPending(MEMBER_P2KB_EXISTING_PENDING));
    API.post(MEMBER_P2KB_EXISTING_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(MEMBER_P2KB_EXISTING_SUCCESS, res.data));
        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(MEMBER_P2KB_EXISTING_PENDING, error));
        toastError(error, "Gagal mendapatkan list member, ");
      });
  };
}

export function editMember(param, callback) {
  const api = param.isPPDS ? EDIT_PPDS_URL : EDIT_MEMBER_URL;
  return (dispatch) => {
    dispatch(actionPending(EDIT_MEMBER_PENDING));
    API.post(api, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(EDIT_MEMBER_SUCCESS, res.data.data));
        toastSuccess("Berhasil mengubah member");

        if (callback) {
          callback();
        }
        return res;
      })
      .catch((error) => {
        dispatch(actionError(EDIT_MEMBER_ERROR, error));
        toastError(error, "Gagal mengubah member, ");
      });
  };
}

export function setSelectedMember(value) {
  return {
    type: SET_SELECTED_MEMBER,
    value: value,
  };
}

export function uploadBerkas(param, callback, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(UPLOAD_BERKAS_PENDING));
    API.post(UPLOAD_BERKAS_URL, param, getHeader())
      .then((res) => {
        dispatch(actionSuccess(UPLOAD_BERKAS_SUCCESS, res.data.filename));
        toastSuccess("Berhasil mengubah berkas pendukung");
        if (callback) {
          callback();
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(UPLOAD_BERKAS_ERROR, error));
        if (error_flag) {
          error_flag();
        }
      });
  };
}
export function memberChangePassword(param = {}, resolve, reject, callback) {
  return (dispatch) => {
    dispatch(actionPending(MEMBER_CHANGE_PASSWORD_PENDING));
    API.post(MEMBER_CHANGE_PASSWORD_URL, param, getHeader())
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(MEMBER_CHANGE_PASSWORD_SUCCESS, res.data));
        if (resolve) {
          resolve({
            data: res.data.data,
          });
        }
        toastSuccess(
          "Email ubah password telah terkirim, silakan periksa email dan ubah password!"
        );
        if (callback) {
          callback();
        }
        history.push("/login");
      })
      .catch((error) => {
        dispatch(actionError(MEMBER_CHANGE_PASSWORD_ERROR, error));
        if (error.response && error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(error.toString(), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
}
