import API from "../API";
import { actionPending, actionSuccess, actionError } from "../index";
import getHeader from "../header";
import { toastError, toastSuccess, toastWarning } from "../../components/toast/Toast";

export const LIST_REPORT_PENDING = "LIST_REPORT_PENDING";
export const LIST_REPORT_SUCCESS = "LIST_REPORT_SUCCESS";
export const LIST_REPORT_ERROR = "LIST_REPORT_ERROR";
export const LIST_TRANSFER_PENDING = "LIST_TRANSFER_PENDING";
export const LIST_TRANSFER_SUCCESS = "LIST_TRANSFER_SUCCESS";
export const LIST_TRANSFER_ERROR = "LIST_TRANSFER_ERROR";
export const IMPORT_REPORT_PENDING = "IMPORT_REPORT_PENDING";
export const IMPORT_REPORT_SUCCESS = "IMPORT_REPORT_SUCCESS";
export const IMPORT_REPORT_ERROR = "IMPORT_REPORT_ERROR";
export const MASTER_REPORT_PENDING = "MASTER_REPORT_PENDING";
export const MASTER_REPORT_SUCCESS = "MASTER_REPORT_SUCCESS";
export const MASTER_REPORT_ERROR = "MASTER_REPORT_ERROR";

const LIST_REPORT_URL = "report/fee/find";
const LIST_TRANSFER_URL = "report/transfer/find";
const IMPORT_REPORT_URL = "reports/import";
const MASTER_REPORT_URL = "reports/find";

export function listReport(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_REPORT_PENDING));
    API.post(LIST_REPORT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_REPORT_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_REPORT_ERROR, error));
        toastError(error, "Gagal mendapatkan list REPORT, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}



export function listTransfer(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(LIST_TRANSFER_PENDING));
    API.post(LIST_TRANSFER_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(LIST_TRANSFER_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_TRANSFER_ERROR, error));
        toastError(error, "Gagal mendapatkan list TRANSFER, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}

export function importReport(file, callback, callbackError, error_flag) {
  return (dispatch) => {
    dispatch(actionPending(IMPORT_REPORT_PENDING));
    let formData = new FormData();
    formData.append("file", file);
    API.post(IMPORT_REPORT_URL, formData, getHeader())
      .then((res) => {
        dispatch(actionSuccess(IMPORT_REPORT_SUCCESS, res.data.filename));
        const dataError = res.data.data_error;
        let errorList = []
        dataError.map((value) => {
          errorList.push(value.paboi_id)
        })
        let errList = errorList.join();
        toastSuccess("Berhasil melakukan import data report!")
        if (dataError.length > 0) {
          if (callbackError) {
            callbackError(dataError);
          }
        }
        else {
          if (callback) {
            callback();
          }
        }
        return res.data.filename;
      })
      .catch((error) => {
        dispatch(actionError(IMPORT_REPORT_ERROR, error));
        toastError(error, "Gagal Import, ");
        if (error_flag) {
          error_flag();

        }
      });
  };
}

export function masterReport(param = {}, resolve, reject) {
  return (dispatch) => {
    dispatch(actionPending(MASTER_REPORT_PENDING));
    API.post(MASTER_REPORT_URL, param, getHeader())
      .then(({ data }) => {
        dispatch(actionSuccess(MASTER_REPORT_SUCCESS, data));
        if (resolve) {
          const { records_total, data: response } = data;
          const result = response.map((item, i) => ({
            no: i + 1 + (param.page - 1) * 10,
            ...item,
          }));
          resolve({
            data: result,
            page: param.page - 1,
            totalCount: records_total,
          });
        }
        return data;
      })
      .catch((error) => {
        dispatch(actionError(MASTER_REPORT_ERROR, error));
        toastError(error, "Gagal mendapatkan list report, ");
        if (resolve) {
          resolve({ data: [], page: 0, totalCount: 0 });
        }
      });
  };
}